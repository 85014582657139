import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';

import pick from 'lodash.pick';
import deepEqual from 'deep-equal';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import FreeFlowCalculator from 'utils/calculators/FreeFlowCalculator';

import FreeFlowByPeakAggSums from './components/FreeFlowByPeakAggSums';
import FreeFlowSpeed from './components/FreeFlowSpeed';

class FreeFlowBreakdown extends Component {
  state = { loading: false };

  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, falcor }
    } = this;

    return tmc && year
      ? falcor.get(
          queryTmcAttributes(
            tmc,
            FreeFlowCalculator.requiredTmcAttributes,
            year
          ),
          queryNPMRDSDataForTmc(tmc, year),
          queryMeasureRules(measure)
        )
      : Promise.resolve();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['tmc', 'year', 'dataSource', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });

      nextProps.falcor
        .get(
          queryTmcAttributes(
            nextProps.tmc,
            FreeFlowCalculator.requiredTmcAttributes,
            nextProps.year
          ),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { freeFlowCalculator } = this.props;

    return (
      <div>
        <h5>Speeds By Peak Breakdown</h5>
        <FreeFlowByPeakAggSums data={freeFlowCalculator} />
        <h5>Free Flow Speed</h5>
        <FreeFlowSpeed data={freeFlowCalculator} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tmc, year, binMinutes, measure, dataSource } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const freeFlowCalculator = new FreeFlowCalculator({
    tmc,
    year,
    dataSource,
    npmrdsData,
    tmcAttributes,
    binMinutes,
    measureSpec
  });

  return {
    freeFlowCalculator
  };
};

export default connect(mapStateToProps)(reduxFalcor(FreeFlowBreakdown));
