import React from "react";
import { connect } from "react-redux";

import AvlMap from "components/AvlMap";

import NewConFactory from "./layers/conflation.new.layer";
import ConRoutingFactory from "./layers/conflation.route.layer";
import NewConRoutingFactory from "./layers/conflation.route.new.layer";
import CompFactory from "./layers/conflation.route.companion.layer";

import NewMacroFactory from "./layers/macro.new.layer";

import get from "lodash.get";

class NewConflation extends React.Component {
  NewConLayer = NewConFactory({ active: true });
  RoutingLayer = ConRoutingFactory({
    active: false,
    companionLayerName: this.NewConLayer.name,
  });

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <AvlMap
          layers={[this.NewConLayer, this.RoutingLayer]}
          layerProps={{
            [this.RoutingLayer.name]: {
              user: this.props.user,
            },
          }}
          styles={[
            {
              name: "Dark Streets",
              style: "mapbox://styles/am3081/ck3rtxo2116rr1dmoppdnrr3g",
            },
            {
              name: "Light Streets",
              style: "mapbox://styles/am3081/ck3t1g9a91vuy1crzp79ffuac",
            },
          ]}
          sidebarPages={["layers"]}
          header="Conflation View 2.0"
        />
      </div>
    );
  }
}

class NewMacro extends React.Component {
  NewMacro = NewMacroFactory({ active: true });

  render() {
    return (
      <div style={{ height: "100vh" }}>
        <AvlMap
          layers={[this.NewMacro]}
          styles={[
            {
              name: "Dark Streets",
              style: "mapbox://styles/am3081/ck3rtxo2116rr1dmoppdnrr3g",
            },
            {
              name: "Light Streets",
              style: "mapbox://styles/am3081/ck3t1g9a91vuy1crzp79ffuac",
            },
          ]}
          sidebarPages={["layers"]}
          header="Macro View"
        />
      </div>
    );
  }
}

class NewRouting extends React.Component {
  RoutingLayer = NewConRoutingFactory({
    active: true,
  });
  componentDidMount() {
    const routeId = get(this.props, ["match", "params", "routeId"], null);
    if (routeId) {
      this.loadRoute(routeId);
    }
  }
  componentDidUpdate(oldProps) {
    const routeId = get(this.props, ["match", "params", "routeId"], null),
      oldRouteId = get(oldProps, ["match", "params", "routeId"], null);
    if (routeId && routeId !== oldRouteId) {
      this.loadRoute(routeId);
    }
  }
  loadRoute(routeId) {
    // console.log("<NewRouting.loadRoute> ROUTE ID:", routeId)
    this.RoutingLayer.selectUserRoute(routeId);
  }
  render() {
    return (
      <div style={{ height: "100vh" }}>
        <AvlMap
          layers={[this.RoutingLayer]}
          layerProps={{
            [this.RoutingLayer.name]: {
              user: this.props.user,
            },
          }}
          sidebarPages={["layers"]}
          header="Route Creation"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default [
  {
    path: "/maccro-new",
    exact: true,
    class: "fa",
    icon: "fa-map",
    name: "Macro View",
    mainNav: false,
    menuSettings: {
      //display: 'none',
      image: "none",
      scheme: "color-scheme-dark",
      position: "menu-position-side",
      layout: "menu-layout-mini",
      style: "color-style-default",
    },
    auth: true,
    component: connect(mapStateToProps)(NewMacro),
  },
  {
    path: "/conflation",
    exact: true,
    class: "fa",
    icon: "fa-bus",
    name: "Conflation 2.0",
    mainNav: false,
    menuSettings: {
      //display: 'none',
      image: "none",
      scheme: "color-scheme-dark",
      position: "menu-position-side",
      layout: "menu-layout-mini",
      style: "color-style-default",
    },
    auth: true,
    component: connect(mapStateToProps)(NewConflation),
  },

  {
    path: "/routes/create",
    exact: true,
    mainNav: false,
    menuSettings: {
      //display: 'none',
      image: "none",
      scheme: "color-scheme-dark",
      position: "menu-position-side",
      layout: "menu-layout-mini",
      style: "color-style-default",
    },
    name: "Route Creation",
    auth: true,
    component: connect(mapStateToProps)(NewRouting),
  },
  {
    path: "/routes/edit/:routeId",
    exact: true,
    mainNav: false,
    menuSettings: {
      //display: 'none',
      image: "none",
      scheme: "color-scheme-dark",
      position: "menu-position-side",
      layout: "menu-layout-mini",
      style: "color-style-default",
    },
    name: "Route Creation",
    auth: true,
    component: connect(mapStateToProps)(NewRouting),
  },
];
