import React from 'react';
import { Link } from 'react-router-dom'

import LandingNav from 'pages/Landing/components/LandingNav'

export default ({ email, password, update, canSubmit, handleSubmit }) =>
  <div style={{
    height:'100vh',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to bottom right,rgb(110, 176, 194) 0px, rgb(63, 174, 205) 39%, rgb(52, 149, 176) 100%)'}}
  >
    <LandingNav />
    <div style={{height: '100%',
      display: 'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems: 'flex-start' }}>
    	<div className="auth-box-w" style={{
        width: '33%',
        minWidth: '350px',
        maxWidth: '650px'
      }}>

      <h4 className="auth-header" style={{paddingTop: 20}}>NPMRDS Login</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor='email'>Email</label>
          <input
            id="email"
            autoFocus
            type="email"
            value={email}
            onChange={ e => update({ email: e.target.value }) }
            className="login-form-control"
            placeholder="Enter your email"
          />
          <div className="pre-icon os-icon os-icon-email-2-at2" />
        </div>
        <div className="form-group">
          <label htmlFor='password'>Password</label>
          <input
            value={password}
            onChange={e => update({ password: e.target.value })}
            id="password"
            type="password"
            className="login-form-control"
            placeholder="Enter your password"
          />
          <div className="pre-icon os-icon os-icon-fingerprint" />
        </div>
        <div className="buttons-w">
          <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={!canSubmit}>Log me in</button>
        </div>
      </form>
      <div style={{padding: 15, float: 'right'}}>
        <Link to={'/auth/signup'}>Sign Up</Link>
        {' | '}
        <Link to={'/auth/reset-password'}>Forgot Password?</Link>
      </div>
      </div>
    </div>
  </div>
