import { push } from 'connected-react-router';
import deepEqual from 'deep-equal';
import pick from 'lodash.pick';

// ------------------------------------
// Constants
// ------------------------------------
const SET_METRIC = 'MEASURE_VISUALIZER::SET_METRIC';
const SET_BIN_MINUTES = 'MEASURE_VISUALIZER::SET_BIN_MINUTES';
const SET_YEAR = 'MEASURE_VISUALIZER::SET_YEAR';
const SET_ACTIVE_MEASURE = 'MEASURE_VISUALIZER::SET_ACTIVE_MEASURE';
const SET_DATA_SOURCE = 'MEASURE_VISUALIZER::SET_DATA_SOURCE';
const SET_REQUESTED_PERCENTILES =
  'MEASURE_VISUALIZER::SET_REQUESTED_PERCENTILES';
const SET_CALENDAR_METRIC = 'MEASURE_VISUALIZER::SET_CALENDAR_METRIC';
const TOGGLE_SUMMARY_STATS_VISIBILITY =
  'MEASURE_VISUALIZER::TOGGLE_SUMMARY_STATS_VISIBILITY';
const START_DATA_LOADING = 'MEASURE_VISUALIZER::START_DATA_LOADING';
const END_DATA_LOADING = 'MEASURE_VISUALIZER::END_DATA_LOADING';
const INITIALIZE_STORE = 'MEASURE_VISUALIZER::INITIALIZE_STORE';

// ------------------------------------
// Actions
// ------------------------------------
export const setBinMinutes = binMinutes => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_BIN_MINUTES, payload: +binMinutes });
    resolve();
  });

export const setMetric = metric => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_METRIC,
      payload: metric
    });
    resolve();
  });

export const setYear = year => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_YEAR, payload: year });
    resolve();
  });

export const setMeasure = measureId => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_ACTIVE_MEASURE, payload: measureId });
    resolve();
  });

export const setDataSource = dataSource => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_DATA_SOURCE, payload: dataSource });
    resolve();
  });

export const setRequestedPercentiles = requiredPercentiles => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_REQUESTED_PERCENTILES, payload: requiredPercentiles });
    resolve();
  });

export const setCalendarMetric = calendarMetric => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_CALENDAR_METRIC, payload: calendarMetric });
    resolve();
  });

export const toggleSummaryStatsVisibility = () => dispatch =>
  new Promise(resolve => {
    dispatch({ type: TOGGLE_SUMMARY_STATS_VISIBILITY });
    resolve();
  });

export const startDataLoading = () => dispatch => {
  const loadId = Symbol();
  return new Promise(resolve => {
    dispatch({ type: START_DATA_LOADING, payload: loadId });
    resolve(loadId);
  });
};

export const endDataLoading = loadId => dispatch =>
  new Promise(resolve => {
    dispatch({ type: END_DATA_LOADING, payload: loadId });
    resolve();
  });

export const initializeStore = params => dispatch =>
  new Promise(resolve => {
    dispatch({ type: INITIALIZE_STORE, payload: params });
    resolve();
  });

export const navigateToMeasureVisualizer = params => dispatch =>
  new Promise(resolve => {
    const { tmc } = params;
    dispatch(push(`/tmc/${tmc || ''}`));
    dispatch(initializeStore(params)).then(resolve);
  });

// -------------------------------------
// Initial State
// -------------------------------------
let initialState = {
  availableYears: [2017, 2018, 2019],
  year: 2017,
  metric: 'SPEED',
  binMinutes: 15,
  measure: 'tttr',
  dataSource: 'ALL',
  dataLoadIds: new Set(),
  requiredPercentiles: [0, 25, 50, 75, 100],
  calendarMetric: 'SPEED',
  summaryStatsAreVisible: true
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_BIN_MINUTES]: (state = initialState, { payload: binMinutes }) =>
    binMinutes === state.binMinutes
      ? state
      : Object.assign({}, state, { binMinutes }),

  [SET_METRIC]: (state = initialState, { payload: metric }) =>
    metric === state.metric ? state : Object.assign({}, state, { metric }),

  [SET_YEAR]: (state = initialState, { payload: year }) =>
    year === state.year ? state : Object.assign({}, state, { year }),

  [SET_ACTIVE_MEASURE]: (state = initialState, { payload: measure }) =>
    measure === state.measure ? state : Object.assign({}, state, { measure }),

  [SET_DATA_SOURCE]: (state = initialState, { payload: dataSource }) =>
    dataSource === state.dataSource
      ? state
      : Object.assign({}, state, { dataSource }),

  [SET_REQUESTED_PERCENTILES]: (
    state = initialState,
    { payload: requiredPercentiles }
  ) => {
    const a = Array.isArray(requiredPercentiles)
      ? requiredPercentiles.sort((a, b) => +a - +b)
      : null;
    const b = Array.isArray(state.requiredPercentiles)
      ? state.requiredPercentiles.sort((a, b) => +a - +b)
      : null;

    return deepEqual(a, b)
      ? state
      : Object.assign({}, state, { requiredPercentiles: a });
  },

  [SET_CALENDAR_METRIC]: (state = initialState, { payload: calendarMetric }) =>
    state.calendarMetric === calendarMetric
      ? state
      : Object.assign({}, state, { calendarMetric }),

  [TOGGLE_SUMMARY_STATS_VISIBILITY]: (state = initialState) =>
    Object.assign({}, state, {
      summaryStatsAreVisible: !state.summaryStatsAreVisible
    }),

  [START_DATA_LOADING]: (state = initialState, action) => {
    const newState = { ...state };
    newState.dataLoadIds = new Set(state.dataLoadIds);
    newState.dataLoadIds.add(action.payload);
    return newState;
  },
  [END_DATA_LOADING]: (state = initialState, action) => {
    const newState = { ...state };
    newState.dataLoadIds = new Set(state.dataLoadIds);
    newState.dataLoadIds.delete(action.payload);
    return newState;
  },

  [INITIALIZE_STORE]: (state = initialState, { payload: params }) => {
    const storeKeys = Object.keys(state);

    const relevantParams = pick(params, storeKeys);
    const parsedParams = Object.keys(relevantParams).reduce((acc, k) => {
      let v = decodeURIComponent(relevantParams[k]);

      if (k === 'availableYears') {
        return acc;
      }

      v = Number.isNaN(+v) ? v : +v;

      if (k === 'summaryStatsAreVisible') {
        v = /true/i.test(v);
      }

      acc[k] = v;
      return acc;
    }, {});

    return Object.keys(relevantParams).some(k => parsedParams[k] !== state[k])
      ? { ...state, ...parsedParams }
      : state;
  }
};

export const getAvailableYears = ({ measureVisualizer: { availableYears } }) =>
  availableYears;

export const getYear = ({ measureVisualizer: { year } }) => year;

export const getMetric = ({ measureVisualizer: { metric } }) => metric;

export const getBinMinutes = ({ measureVisualizer: { binMinutes } }) =>
  +binMinutes;

export const getMeasure = ({ measureVisualizer: { measure } }) => measure;

export const getDataSource = ({ measureVisualizer: { dataSource } }) =>
  dataSource;

export const isDataLoading = ({ measureVisualizer: { dataLoadIds } }) =>
  !!dataLoadIds.size;

export const getReqPercentiles = ({
  measureVisualizer: { requiredPercentiles }
}) => requiredPercentiles;

export const getCalendarMetric = ({ measureVisualizer: { calendarMetric } }) =>
  calendarMetric;

export const areSummaryStatsVisible = ({
  measureVisualizer: { summaryStatsAreVisible }
}) => summaryStatsAreVisible;

export default function measureVisualizerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
