import React from "react"

import Login from "./components/Login"
import Signup from "./components/Signup"
import ResetPassword from "./components/ResetPassword"
import SetPassword from "./components/SetPassword"
import "./components/Login.css"

// import ComponentFactory from "components/avl-components/ComponentFactory"
import { ComponentFactory } from "@availabs/avl-components"

const AuthConfig = {
  type: "ams-manager",
  props: {
// @props.showHeaders
// Optional props. Defaults to true.
// Set to false to hide new avl-component header so old projects can display their own header.
    showHeaders: false,
    className: null
  },
  wrappers: [
    "ams-redux",
    "ams-router"
  ],
  children: [
    { type: "ams-directory",
      props: { className: "max-w-6xl m-auto" }
    },
    { type: Login,
      wrappers: ["ams-login"]
    },
    { type: ResetPassword,
      wrappers: ["ams-reset-password"]
    },
    { type: "ams-logout",
// @props.redirectTo
// Optional prop. Defaults to "/".
// Redirects user to URL after logging out.
      // props: { redirectTo: "/" }
    },

    { type: SetPassword,
      wrappers: ["ams-set-password"]
    },

    { type: Signup,
      wrappers: ["ams-signup"],
// @props.addToGroup
// Optional prop. Defaults to false.
// Adds user to group (must have auth level 0 in all projects) instead of creating a request that must be accepted by admin.
      props: { addToGroup: "NPMRDS_PM3 New Users" }
    },
    { type: "ams-verify-request" }, // This is only required when addToGroup prop is included with ams-signup
    // { type: "ams-verify-email" }, // This is only required when addToGroup prop is not included with ams-signup

    { type: "ams-profile",
      props: { className: "" }
    },
    { type: "ams-accept-invite" },

    { type: "ams-project-management",
      props: { className: "max-w-6xl m-auto" }
// @props.authLevel
// Optional prop. This prop can be applied to any AMS child.
// If set, users must have equal or higher authLevel to view this page.
// Defaults to 5 for "ams-project-management."
      // props: { authLevel: 5 }
    }
  ]
}

export default {
  path: "/auth",
  mainNav: false,
  // exact: true,
  // auth: true,
  name: 'AMS',
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  class: 'fa',
  layoutSettings: {
    fixed: true,
    nav: 'side',
    headerBar: false,
    theme: 'TEST_THEME'
  },
  component: () => <ComponentFactory config={ AuthConfig }/>
}
