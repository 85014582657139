import {createStore, combineReducers, applyMiddleware} from 'redux';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {reducer as graph} from 'utils/redux-falcor';

import falcorCache from "utils/redux-falcor-new/falcorCache"

// import user from './modules/user';
// import messages from './modules/messages';
import geo from './modules/geo';
import cms from './modules/cms';


import MeasureVisualizerStore from 'pages/auth/MeasureVisualizer/store';
import GeoMeasureVisualizerStore from 'pages/auth/GeoMeasureVisualizer/store';

import transit from 'pages/auth/transit/store/reducer'

import createHistory from 'history/createBrowserHistory';
import thunk from 'redux-thunk';

import {AvlInTheMiddle} from "components/AvlMap/ReduxMiddleware"

// import amsReducers from "components/ams/reducers"
// import messages from "components/avl-components/messages/reducer"
import { messages } from "@availabs/avl-components"
import { Reducers } from "@availabs/ams"

// if (process.env.NODE_ENV === 'development') {
//   const devToolsExtension = window.devToolsExtension;

//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension());
//   }
// }

import report from 'pages/auth/Routes/analysis/reports/store';

const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = [
  routerMiddleware(history),
  thunk,
  AvlInTheMiddle
];

const store = createStore(
  combineReducers({
    // user,
    // ...amsReducers,
    ...Reducers,

    geo,
    cms,
    messages,
    graph,

    falcorCache,

    measureVisualizer: MeasureVisualizerStore,
    geomeasureVisualizer: GeoMeasureVisualizerStore,
    router: connectRouter(history),
    report,
    transit
  }),
  applyMiddleware(...middleware)
);

export default store;
export {history};
