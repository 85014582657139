// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import { precisionRound } from 'utils/calculators/MathUtils';

const formatData = offPeakSortedBySpeed => {
  if (!(Array.isArray(offPeakSortedBySpeed) && offPeakSortedBySpeed.length)) {
    return null;
  }
  const ctsByPeak = offPeakSortedBySpeed.reduce((acc, { peak }) => {
    if (!acc[peak]) {
      acc[peak] = 0;
    }
    acc[peak] += 1;
    return acc;
  }, {});

  console.log('POSTFILTER:', ctsByPeak);

  const byPeakAggSums = {};
  let ct = 1;
  for (let i = 0; i < offPeakSortedBySpeed.length; i += ct) {
    ct = 1;
    const { peak, avgSpeed } = offPeakSortedBySpeed[i];
    const curSpeed = precisionRound(avgSpeed);

    if (!peak) {
      continue;
    }

    while (true) {
      const { avgSpeed: peekedSpeed, peak: peekedPeak } =
        offPeakSortedBySpeed[i + ct] || {};
      if (
        !peekedSpeed ||
        peak !== peekedPeak ||
        curSpeed !== precisionRound(peekedSpeed)
      ) {
        break;
      }
      ++ct;
    }

    if (!byPeakAggSums[peak]) {
      byPeakAggSums[peak] = {
        x: [precisionRound(avgSpeed)],
        y: [ct]
      };
    } else {
      const { x, y } = byPeakAggSums[peak];
      x.push(precisionRound(avgSpeed));
      y.push(y[y.length - 1] + ct);
    }
  }

  return byPeakAggSums;
};

const getViz = ({
  offPeakSortedBySpeed,
  freeFlowSpeed,
  freeFlowSpeedByOffPeakPeriod
}) => {
  const byPeakAggSums = formatData(offPeakSortedBySpeed);
  const ff = precisionRound(freeFlowSpeed);

  const data = Object.keys(byPeakAggSums).map((peak, i) => {
    const { x, y } = byPeakAggSums[peak];

    return {
      x,
      y,
      name: peak,
      type: 'scatter',
      line: { width: 1, shape: 'hv' }
    };
  });

  let yshift = 0;
  const annotations = [
    {
      text: `Free-Flow`,
      xref: 'x',
      x: ff,
      yref: 'paper',
      y: 1
    }
  ].concat(
    Object.keys(freeFlowSpeedByOffPeakPeriod).map(peak => ({
      text: `${peak} Free-Flow`,
      xref: 'x',
      x: precisionRound(freeFlowSpeedByOffPeakPeriod[peak]),
      bgcolor: 'rgba(255,255,255,0.75)',
      yref: 'paper',
      y: 0,
      yshift: (yshift += 50)
    }))
  );

  const shapes = [
    {
      type: 'line',
      xref: 'x',
      x0: ff,
      x1: ff,
      yref: 'paper',
      y0: 0,
      y1: 1
    }
  ].concat(
    Object.keys(freeFlowSpeedByOffPeakPeriod).map(peak => ({
      type: 'line',
      xref: 'x',
      x0: precisionRound(freeFlowSpeedByOffPeakPeriod[peak]),
      x1: precisionRound(freeFlowSpeedByOffPeakPeriod[peak]),
      yref: 'paper',
      y0: 0,
      y1: 1
    }))
  );

  const layout = {
    title: 'Off Peak Speed Percentile Breakdown',
    xaxis: {
      title: 'Speed'
    },
    yaxis: {
      title: 'Aggregated Count of Instances'
    },
    annotations,
    shapes
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '100%' }}
      data={data}
      layout={layout}
      config={config}
    />
  );
};

const FreeFlowByPeakAggSums = ({ data }) => {
  const { offPeakSortedBySpeed } = data;

  return offPeakSortedBySpeed ? (
    <div>
      <div style={{ width: '62%', color: '#333', marginTop: 50 }}>
        {getViz(data)}
      </div>
    </div>
  ) : null;
};

export default FreeFlowByPeakAggSums;
