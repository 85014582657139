import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';
import MathJax from 'react-mathjax';

import pick from 'lodash.pick';
import deepEqual from 'deep-equal';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes,
  queryTrafficDistributionsDOWAdjFactors,
  queryTrafficDistributionsProfiles
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getTrafficDistributionProfiles,
  getTrafficDistributionDOWAdjFactors,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import PHEDCalculator from 'utils/calculators/PHEDCalculator';

import AvgVehicleOccupancy from '../../components/AvgVehicleOccupancy';
import AvgTravelTimeByBin from '../../components/AvgTravelTimeByBin';
import PHEDExcessiveDelayHoursByBin from './components/PHEDExcessiveDelayHoursByBin';
import PHEDExcessiveDelayPersonHoursByBin from './components/PHEDExcessiveDelayPersonHoursByBin';
import PHEDExcessiveDelayVehicleHoursByBin from './components/PHEDExcessiveDelayVehicleHoursByBin';
import PHEDThresholdSpeed from './components/PHEDThresholdSpeed';
import PHEDThresholdTravelTime from './components/PHEDThresholdTravelTime';
import PHEDTotalExcessiveDelay from './components/PHEDTotalExcessiveDelay';
import PHEDTotalExcessiveDelayByOutlierFilter from './components/PHEDTotalExcessiveDelayByOutlierFilter';
import TrafficDistributionProfile from '../../components/TrafficDistributionProfile';

class PHEDBreakdown extends Component {
  state = { loading: false };

  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, falcor }
    } = this;

    //  TODO: Integrate state.loading
    return tmc && year
      ? falcor.get(
          queryTmcAttributes(tmc, PHEDCalculator.requiredTmcAttributes, year),
          queryNPMRDSDataForTmc(tmc, year),
          queryTrafficDistributionsProfiles(),
          queryTrafficDistributionsDOWAdjFactors(),
          queryMeasureRules(measure)
        )
      : Promise.resolve();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsRequiringUpdate = [
      'year',
      'binMinutes',
      'metric',
      'dataSource',
      'tmc',
      'measure'
    ];

    return (
      this.state.loading !== nextState.loading ||
      !deepEqual(
        pick(this.props, propsRequiringUpdate),
        pick(nextProps, propsRequiringUpdate)
      ) ||
      // Makes sure all necessary ingredients for calculation
      //   have been loaded. I.E trafficDist data.
      // CONSIDER: Probably should move
      this.props.phedCalculator.totalExcessiveDelay !==
        nextProps.phedCalculator.totalExcessiveDelay
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['year', 'dataSource', 'tmc', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });
      nextProps.falcor
        .get(
          queryTmcAttributes(
            nextProps.tmc,
            PHEDCalculator.requiredTmcAttributes,
            nextProps.year
          ),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { phedCalculator } = this.props;

    return (
      <div>
        <blockquote
          style={{
            width: '38%',
            marginLeft: 20,
            backgroundColor: '#F2F4F8',
            padding: 12
          }}
        >
          <h5>§ 490.701 Purpose.</h5>
          The purpose of this subpart is to implement the requirements of 23
          U.S.C. 150(c)(5)(A) to establish performance measures for State DOTs
          and the MPOs to use in assessing CMAQ Traffic Congestion for the
          purpose of carrying out the CMAQ program. § 490.703 Applicability. The
          CMAQ Traffic Congestion performance measures are applicable to all
          urbanized areas that include NHS mileage and with a population over 1
          million for the first performance period and in urbanized areas with a
          population over 200,000 for the second and all other performance
          periods, that are, in all or part, designated as nonattainment or
          maintenance areas for ozone (O3), carbon monoxide (CO), or particulate
          matter (PM10 and PM2.5) National Ambient Air Quality Standards
          (NAAQS).
        </blockquote>
        <blockquote
          style={{
            width: '38%',
            marginLeft: 20,
            backgroundColor: '#F2F4F8',
            padding: 12
          }}
        >
          <h5>§ Data Requirements.</h5>
          To calclute the Peak Hours of Excessive Delay for a single TMC you
          need to gather data from a few different sources.
          <br />
          <ul style={{ listStyle: 'square' }}>
            <li>TMC length.</li>
            <li>
              Annual Average Daily Traffic and hourly disagregation method.
            </li>
            <li>TMC posted speed limit.</li>
            <li>
              Travel time data in 15 minute intervals. (NPMRDS or other Probe
              Data set)
            </li>
          </ul>
        </blockquote>
        <div style={{ width: '62%' }} />
        <h5>Step 1 - Calculating 15 Minute Bins</h5>
        NPMRDS and other probe data sets report 5 minutes travel time intervals
        and the Peak Hour Excessive Delay calulation requires that travel time
        be binned in 15 minute increments. The first step in cacluating the PHED
        is to do this binning.
        <h5>Step 2 - Extracting the Peaks</h5>
        Now lets throw out all data that isn't in the peak. Peak Periodis
        defined as weekdays from 6 a.m. to 10 a.m. and either 3 p.m. to 7 p.m.
        or 4 p.m. to 8 p.m. State DOTs and MPOs may choose whether to use 3 p.m.
        to 7 p.m. or 4 p.m. to 8 p.m.
        <h4>Step 3 - Calculating Threshold Travel Time</h4>
        <div id="final-rule-490.711.c">
          The State DOT shall calculate the "excessive delay threshold travel
          time" for all applicable travel time segments as follows:
          <MathJax.Provider>
            <div id="final-rule-excessive-delay-threshold-travel-time-formula">
              <MathJax.Node>
                {`
                      Excessive Delay Threshold Time Value_s =
                      {({{Travel Time Segment Length_s} \\over {Threshold Speed_s}}) \\times 3600}
                  `}
              </MathJax.Node>
              <div>Where</div>
              <table className="var-definitions-table">
                <tbody>
                  <tr>
                    <td>
                      <MathJax.Node inline>
                        {'Excessive Delay Threshold Travel Time_s'}
                      </MathJax.Node>
                    </td>
                    <td>
                      the time of travel, to the nearest whole second, to
                      traverse the Travel Time Segment at which any longer
                      measured travel times would result in excessive delay for
                      the travel time segment "s";
                    </td>
                    <td>
                      <strong>TODO: Add threshold time in sec</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <MathJax.Node inline>
                        {'Travel Time Segment Length_s'}
                      </MathJax.Node>
                    </td>
                    <td>
                      {' '}
                      total length of travel time segment to the nearest
                      thousandth of a mile for travel time reporting segment
                      "s"; and
                    </td>
                    <td>
                      <strong>{'<LENGTH>'} miles</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <MathJax.Node inline>{'Threshold Speed_s'}</MathJax.Node>
                    </td>
                    <td>
                      the speed of travel at which any slower measured speeds
                      would result in excessive delay for travel time reporting
                      segment "s" As defined in § 490.705, the speed threshold
                      is 20 miles per hour (mph) or 60 percent of the posted
                      speed limit travel time reporting segment "s," whichever
                      is greater.
                    </td>
                    <td>
                      <strong>{'<THRESHOLD SPEED>'} mph</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </MathJax.Provider>
        </div>
        <h4>Step 4 - Calculating Excessive Delay</h4>
        <div id="final-rule-490.711.d">
          State DOTs shall determine the "excessive delay" for each 15 minute
          bin of each reporting segment for every hour and every day in a
          calendar year as follows:
          <ol className="decimal-ol">
            <li id="final-rule-490.711.d.1">
              The travel time segment delay (RSD) shall be calculated to the
              nearest whole second as follow:
              <MathJax.Provider>
                <div id="travel-time-segment-delay-formula">
                  <MathJax.Node>
                    {`
              RSD_{s,b}=
              {{Travel Time_{s,b}} - {Excessive Delay Threshold Travel Time_s}}
          `}
                  </MathJax.Node>
                  <div>Where</div>
                  <table className="var-definitions-table">
                    <tbody>
                      <tr>
                        <td>
                          <MathJax.Node inline>{'TravelTime_s'}</MathJax.Node>
                        </td>
                        <td>
                          {' '}
                          a measured travel time, to the nearest second, for
                          15-minute time bin "b" recorded for travel time
                          reporting segment "s"
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <MathJax.Node inline>
                            {'Excessive Delay Threshold Travel Time_s'}
                          </MathJax.Node>
                        </td>
                        <td>
                          The maximum amount of time, to the nearest second, for
                          a vehicle to traverse through travel time segment "s"
                          before excessive delay would occur, as specified in
                          paragraph (c) of this section;
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <MathJax.Node inline>{'b'}</MathJax.Node>
                        </td>
                        <td>
                          a 15-minute bin of a travel time reporting segment "s"
                          and
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <MathJax.Node inline>{'s'}</MathJax.Node>
                        </td>
                        <td>a travel time reporting segment.</td>
                      </tr>
                    </tbody>
                  </table>{' '}
                </div>
              </MathJax.Provider>
            </li>
            <li id="final-rule-490.711.d.2">
              Excessive delay, the additional amount of time to traverse a
              travel time segment in a 15-minute bin as compared to the time
              needed to traverse the travel time segment when traveling at the
              excessive delay travel speed threshold, shall be calculated to the
              nearest thousandths of an hour as follows:
              <MathJax.Provider>
                <div id="excessive-delay-formula">
                  <MathJax.Node>
                    {`
                      ExcessiveDelay_{s,b}=
                      \\begin{cases}
                        RSD_{s,b} \\over 3,600 & \\text{when} RSD_{s,b} \\geq 0.\\\\
                        0 & \\text{when} RSD_{s,b} = 0.
                      \\end{cases}
          `}
                  </MathJax.Node>
                  <div>Where</div>
                  <table className="var-definitions-table">
                    <tbody>
                      <tr>
                        <td>
                          <MathJax.Node inline>
                            {'ExcessiveDelay_{s,b}'}
                          </MathJax.Node>
                        </td>
                        <td>
                          excessive delay, calculated to the nearest thousandths
                          of an hour, for 15-minute bin "b" of travel time
                          reporting segment "s";
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <MathJax.Node inline>{'RSD_{s,b}'}</MathJax.Node>
                        </td>
                        <td>
                          the calculated travel time reporting segment delay for
                          fifteen minute bin "b" of a travel time reporting
                          segment "s," as described in paragraph (d)(1) of this
                          section;
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <MathJax.Node inline>{'b'}</MathJax.Node>
                        </td>
                        <td>
                          a 15-minute bin of a travel time reporting segment "s"
                          and
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <MathJax.Node inline>{'s'}</MathJax.Node>
                        </td>
                        <td>a travel time reporting segment.</td>
                      </tr>
                    </tbody>
                  </table>{' '}
                </div>
              </MathJax.Provider>
            </li>
            {/* end 490.711.d.2 */}
          </ol>
          {/* end 490.711.d.1-2 */}
        </div>
        <h5>Step 1 - Threshold Speed</h5>
        <PHEDThresholdSpeed data={phedCalculator} />
        <h5>Step 2 - Excessive Delay Threshold Travel Time</h5>
        <PHEDThresholdTravelTime data={phedCalculator} />
        <h5>Step 3 - Average Vehicle Occupancy</h5>
        <AvgVehicleOccupancy data={phedCalculator} />
        <h5>Step 4 - Calculating the traffic distribution profile criteria</h5>
        <TrafficDistributionProfile data={phedCalculator} />
        <h5>Step 5 - Travel Time Averages</h5>
        <AvgTravelTimeByBin data={phedCalculator} />
        <h5>Step 6 - Excessive Delay (Hours)</h5>
        <PHEDExcessiveDelayHoursByBin data={phedCalculator} />
        <h5>Step 7 - Excessive Delay (Vehicle Hours)</h5>
        <PHEDExcessiveDelayVehicleHoursByBin data={phedCalculator} />
        <h5>Step 8 - Excessive Delay (Person Hours)</h5>
        <PHEDExcessiveDelayPersonHoursByBin data={phedCalculator} />
        <h5>Total Excessive Delay By Outlier Filter</h5>
        <PHEDTotalExcessiveDelayByOutlierFilter data={phedCalculator} />
        <h5>Total Excessive Delay</h5>
        <PHEDTotalExcessiveDelay data={phedCalculator} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tmc, year, binMinutes, measure, dataSource } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const trafficDistributionProfiles = getTrafficDistributionProfiles(state);
  const trafficDistributionDOWAdjFactors = getTrafficDistributionDOWAdjFactors(
    state
  );

  const phedCalculator = new PHEDCalculator({
    tmc,
    year,
    dataSource,
    npmrdsData,
    tmcAttributes,
    trafficDistributionProfiles,
    trafficDistributionDOWAdjFactors,
    binMinutes,
    measureSpec
  });

  return {
    tmcAttributes,
    phedCalculator
  };
};

export default connect(mapStateToProps)(reduxFalcor(PHEDBreakdown));
