import { LOCATION_CHANGE } from 'connected-react-router';

import uniq from 'lodash.uniq';

import deepEqual from 'deep-equal';
import clone from 'clone';

import { parseUrl } from '../../urlUtils';

import { controlledComponents } from '../registry';

// ------------------------------------
// Constants
// ------------------------------------

import { SET_SELECTED_TMCS, SET_HOVERED_TMC } from './actionCreators';

export const sliceId = controlledComponents;

// TODO: Rename this function
export const handleTmcFocus = (state, { focusedTmc }) => {
  if (state.focusedTmc === focusedTmc) {
    const newState = { ...state };
    newState.focusedTmc = null;
    return newState;
  }

  const newState = { ...state };
  newState.focusedTmc = focusedTmc;

  return newState;
};

// -------------------------------------
// Initial State
// -------------------------------------
const initialState = {
  focusedTmc: null,
  hoveredTmc: null,
  selectedTmcs: null
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOCATION_CHANGE]: (state = initialState, action) => {
    const {
      payload: { location }
    } = action;

    const urlParams = parseUrl(location);

    if (!urlParams) {
      return state;
    }

    let newState = state;

    newState = handleTmcFocus(newState, urlParams);

    return newState;
  },

  [SET_SELECTED_TMCS]: (state = initialState, action) => {
    const selectedTmcs = Array.isArray(action.payload)
      ? uniq(action.payload).sort()
      : null;

    if (state.selectedTmcs && selectedTmcs) {
      if (
        state.selectedTmcs.length === selectedTmcs.length &&
        deepEqual(state.selectedTmcs, selectedTmcs)
      ) {
        return state;
      }
    }

    const newState = { ...state };

    newState.selectedTmcs = clone(selectedTmcs);

    return newState;
  },

  [SET_HOVERED_TMC]: (state = initialState, { payload: hoveredTmc }) =>
    state.hoveredTmc === hoveredTmc ? state : { ...state, hoveredTmc }
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
