import React from "react"
import { connect } from "react-redux"
import { reduxFalcor } from 'utils/redux-falcor'

import { ResponsiveBar } from '@nivo/bar'

import deepequal from "deep-equal"
import get from "lodash.get"
import { format as d3format } from "d3-format"
import styled from "styled-components"
import * as d3array from "d3-array"

import Sidebar from "./StationModal.Sidebar"

import { RESOLUTIONS } from "./StationModal.Sidebar"

const F_CLASSES = [
  "f1", "f2", "f3", "f4", "f5", "f6", "f7",
  "f8", "f9", "f10", "f11", "f12", "f13"
]

const Tooltip = styled.div`
  color: #000;
  > span {
    font-weight: bold;
    margin-left: 10px;
  }
`

class StationModal extends React.Component {
  static defaultProps = {
    sidebarWidth: 300,
    stations: []
  }
  state = {
    transitioning: false,
    open: true,
    dataRequest: {
      startDate: "2015-01-01",
      endDate: "2015-12-31",
      startTime: "00:00",
      endTime: "00:00",
      weekdays: ["2", "3", "4", "5", "6"],
      resolution: "hour",
      dataType: "volume"
    },
    prevRequest: null,
    graphData: []
  }
  componentDidMount() {
    this.sendRequest();
  }
  componentDidUpdate(oldProps) {
    const oldIds = oldProps.stations.sort((a, b) => +a.properties.stationId - +b.properties.stationId),
      ids = this.props.stations.sort((a, b) => +a.properties.stationId - +b.properties.stationId);
    if (!deepequal(oldIds, ids)) {
      this.sendRequest();
    }
  }
  updateRequest(update) {
    const dr = this.state.dataRequest;
    this.setState({
      dataRequest: { ...dr, ...update }
    });
  }
  makeRequest() {
    let {
      startDate,
      endDate,
      startTime,
      endTime,
      weekdays,
      resolution,
      dataType,
    } = this.state.dataRequest;
    if (endTime === "00:00") {
      endTime = "24:00";
    }
    return encodeURI([
      startDate,
      endDate,
      startTime,
      endTime,
      weekdays.join(),
      resolution,
      dataType
    ].join("|"));
  }
  sendRequest() {
    const key = this.makeRequest();
    return this.props.falcor.get(
      ["hds", "continuous", "data", this.props.stations.map(s => s.properties.stationId), key]
    )
    .then(res => this.processResponse(res, key))
    .then(data => this.processData(data))
    .then(() => this.setState({ prevRequest: { ...this.state.dataRequest } }))
  }
  processResponse(res, key) {
    return this.props.stations.reduce((a, c) => {
      a.push(...get(res, ["json", "hds", "continuous", "data", c.properties.stationId, key], []));
      return a;
    }, []);
  }
  processData(data) {
    const graphData = [];
    if (get(this.state, ["dataRequest", "dataType"], "volume") === "volume") {
      const rolled = d3array.rollup(data, v => d3array.sum(v, d => d.value), d => d.resolution);

      rolled.forEach((value, resolution) => {
        graphData.push({
          value,
          resolution: resolution.toString()
        })
      })
    }
    else {
      const rolled = d3array.rollup(data, v => {
        const obj = F_CLASSES.reduce((a, c) => ({ [c]: 0, ...a }), {}),
          counts = { ...obj };
        v.forEach(o => {
          F_CLASSES.forEach(f => {
            obj[f] += o[f];
          })
        })
        return obj;
      }, d => d.resolution);
      rolled.forEach((obj, resolution) => {
        graphData.push({
          ...obj,
          resolution: resolution.toString()
        })
      })
    }
    this.setState({ graphData });
  }
  toggleSidebar() {
    this.setState({ transitioning: true, open: !this.state.open });
    setTimeout(() => {
      this.setState({ transitioning: false });
    }, 250)
  }
  render() {
    const sbWidth = this.state.open ? this.props.sidebarWidth : 0,
      keys = get(this.state, ["prevRequest", "dataType"], "volume") === "volume" ?
        ["value"] : F_CLASSES,
      numBars = this.state.graphData.length,
      mod = Math.floor(numBars / 10) + 1,
      tickValues = this.state.graphData.filter((d, i) => !(i % mod)).map(d => d.resolution),
      axisBottomFormat = RESOLUTIONS
        .reduce((a, c) => c.value === get(this.state, ["prevRequest", "resolution"], "hour") ? c.format : a, d => d),

      format = d3format(",d");

    return (
      <div style={ {
        width: "100%",
        height: "100%",
        position: "relative"
      } }>
        <div style={ {
          marginLeft: `${ sbWidth }px`,
          transition: "margin 0.25s",
          paddingLeft: "20px"
        } }>
          <div style={ { width: "100%", height: "400px" } }>
            <ResponsiveBar
              margin={ {
                top: 15,
                right: 15,
                bottom: 30,
                left: 90
              } }
              tooltip={
                d => (
                  <Tooltip>
                    { axisBottomFormat(d.data.resolution) }<span>Count:</span> { format(d.data[d.id]) }
                  </Tooltip>
                )
              }
              keys={ keys }
              indexBy="resolution"
              data={ this.state.graphData }
              enableLabel={ false }
              axisLeft={ {
                format: ",d",
                legend: "Count",
                legendOffset: -75,
                legendPosition: "middle"
              } }
              axisBottom={ {
                tickValues,
                format: axisBottomFormat
              } }
              theme={ {
                axis: {
                  ticks: {
                    line: {
                      stroke: '#ccc'
                    },
                    text: {
                      fill: "#ccc"
                    }
                  },
                  legend: {
                    text: {
                      fill: "#ccc"
                    }
                  }
                }
              } }/>
          </div>
        </div>
        <Sidebar width={ this.props.sidebarWidth }
          stations={ this.props.stations }
          allStations={ this.props.allStations }
          open={ this.state.open }
          transitioning={ this.state.transitioning }
          toggle={ () => this.toggleSidebar() }
          toggleStation={ this.props.toggleStation }
          dataRequest={ this.state.dataRequest }
          prevRequest={ this.state.prevRequest }
          updateRequest={ u => this.updateRequest(u) }
          sendRequest={ e => this.sendRequest() }/>
      </div>
    )
  }
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(StationModal))
