import React from 'react';
import { reduxFalcor } from 'utils/redux-falcor';
import { connect } from 'react-redux';

import styled from "styled-components"
import get from "lodash.get"
import deepequal from "deep-equal"

import GraphLayout from '../../components/GraphLayout'
import Sidebar from '../../components/Sidebar'

import Modal from "components/AvlStuff/AvlModal"

import AvlTable from "components/AvlStuff/AvlTable"

import { Input } from "components/common/styled-components"
import ItemSelector from 'components/common/item-selector/item-selector';

// import {
//   unregister,
//   needsUpdate
// } from "components/tmc_graphs/utils/DomainManager"
import * as DomainManager from "components/tmc_graphs/utils/DomainManager"

import {
  foldersFalcorRequest,
  getFoldersFromState
} from "pages/auth/MyStuff"

import * as stuff from "../store"
const {
  LOCAL_STORAGE_REPORT_KEY,
  RECENT_REGEX,
  replace,
  ...REDUX_ACTIONS
} = stuff;

class ReportBase extends React.Component {

  state = {
    viewing: true,
    previewing: false,
    isOpen: 0,
    showSaveModal: false,
    showTemplateModal: false,
    showLoadModal: false,
    loadData: {},
    highlightedTmcs: [],
    showTableModal: false,
    tableModalData: { data: [], keys: [] }
  }

  componentDidMount() {
    this.props.getDataDateExtent();
    this.props.resetState();

    const reportId = get(this.props, 'match.params.reportId', ""),
      templateId = get(this.props, 'match.params.templateId', ""),
      routeId = get(this.props, 'match.params.routeId', ""),
      stationId = get(this.props, 'match.params.stationId', ""),
      path = get(this.props, 'match.path', "");

    const query = new URLSearchParams(this.props.location.search);

    if (reportId) {
      let loadReport = true;
      if (window.localStorage) {
        const key = `report-${ reportId }`,
          data = JSON.parse(window.localStorage.getItem(key));
        if (data) {
          loadReport = false;
          this.setState({
            showLoadModal: true,
            loadData: {
              key,
              data,
              onReject: () => this.props.loadReport(reportId)
            }
          });
        }
      }
      // console.log('load report', reportId)
      loadReport && this.props.loadReport(reportId);
    }
    else if (path === "/report/new") {
      const routeId = query.get("routeId");

      if (window.localStorage) {
        const key = LOCAL_STORAGE_REPORT_KEY,
          data = JSON.parse(window.localStorage.getItem(key)),
          onReject = () => routeId && this.props.addRouteComp(routeId.split("_"));
        if (data) {
          this.setState({
            showLoadModal: true,
            loadData: {
              key,
              data,
              onReject
            }
          });
        }
        else {
          onReject();
        }
      }
    }
    else if (templateId && (routeId || stationId)) {
      this.props.loadRoutesAndTemplate(
                  routeId.split("_").filter(Boolean),
                  templateId,
                  stationId.split("_").filter(Boolean)
                );
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const { owner, type, user, redirect, history } = this.props;

    if ((owner === "not-set") &&
      (type === "personal") &&
      user.authed) {

      this.updateReport({ owner: user.id });
    }
    else if ((owner === "not-set") &&
      (type === "group") &&
      user.authed) {

      this.updateReport({ owner: user.groups[0] });
    }

    if (redirect) {
      history.replace(redirect);

      this.updateReport({ redirect: false });
    }

    if (DomainManager.needsUpdate()) {
      this.forceUpdate();
    }
  }

  togglePreviewing() {
    this.setState({ previewing: !this.state.previewing });
  }

  highlightTmcs(tmcs) {
    this.setState((prevState, props) => {
      let highlightedTmcs = [...prevState.highlightedTmcs],
        setState = false;
      for (const tmc of tmcs) {
        if (!highlightedTmcs.includes(tmc)) {
          highlightedTmcs.push(tmc);
          setState = true;
        }
      }
      return setState ? { highlightedTmcs } : null;
    })
  }
  unhighlightTmcs(tmcs) {
    this.setState((prevState, props) => {
      let highlightedTmcs = [...prevState.highlightedTmcs],
        setState = false;
      for (const tmc of tmcs) {
        if (highlightedTmcs.includes(tmc)) {
          highlightedTmcs = highlightedTmcs.filter(_tmc => _tmc !== tmc);
          setState = true;
        }
      }
      return setState ? { highlightedTmcs } : null;
    })
  }
  setHighlightedTmcs(tmcs) {
    if (!deepequal(tmcs, this.state.highlightedTmcs)) {
      this.setState({ highlightedTmcs: tmcs });
    }
  }

  fetchFalcorDeps() {
    if (!this.props.user.authed) return Promise.resolve();

    return this.props.falcor
      .get(
        ['routes', 'length'],
        ['templates', 'length'],
        ['templates', 'defaultTypes'],
        ['folders', 'length'],
        ['hds', 'continuous', 'stations', 'length']
      )
      .then(res => {
        const requests = [],
          routes = get(res, 'json.routes.length', 0),
          templates = get(res, 'json.templates.length', 0),
          folders = get(res, 'json.folders.length', 0),
          stations = get(res, 'json.hds.continuous.stations.length', 0);
        if (routes) {
          requests.push(
            ['routes', 'byIndex', { from: 0, to: routes - 1 },
              ['id', 'name']
            ]
          )
        }
        if (templates) {
          requests.push(
            ['templates', 'byIndex', { from: 0, to: templates - 1 },
              ['id', 'title', 'routes', 'stations']
            ]
          )
        }
        if (folders) {
          requests.push(foldersFalcorRequest(folders));
        }
        if (stations) {
          requests.push(
            ['hds', 'continuous', 'stations', 'byIndex', { from: 0, to: stations - 1 },
              ['stationId', 'muni', 'data_type', 'geom']
            ]
          )
        }
        return requests.length && this.props.falcor.get(...requests)
          // .then(res => (console.log("RES:", res), res))
          .then(res => {
            const requests = [];
            for (let i = 0; i < folders; ++i) {
              const { stuff } = get(res, ["json", "folders", "byIndex", i], {})
              stuff && stuff.forEach(({ stuff_type, stuff_id }) => {
                (stuff_type === "collection") && requests.push(["routes", "byId", stuff_id, "name"]);
              })
            }
            return requests.length && this.props.falcor.get(...requests);
          })
      })
  }

  updateReport(update) {
    return this.props.updateReport(update);
  }
  saveReport(update, reportId = null) {
    return this.props.saveReport(update, reportId);
  }

  addRouteComp(routeId) {
    this.props.addRouteComp(routeId, true);
  }
  removeRouteComp(compId) {
    this.props.removeRouteComp(compId, true);
  }
  updateRouteCompSettings(compId, settings) {
    this.props.updateRouteCompSettings(compId, settings);
  }
  updateRouteComp(compId, update) {
    this.props.updateRouteComp(compId, update);
  }
  updateRouteCompColor(compId, color) {
    this.props.updateRouteCompColor(compId, color);
  }
  updateAllComponents() {
    this.props.updateAllComponents();
  }
  reorderRouteComps(srcIndex, dstIndex) {
    this.props.reorderRouteComps(srcIndex, dstIndex);
  }

  addStationComp(stationId) {
    this.props.addStationComp(stationId, true);
  }
  removeStationComp(compId) {
    this.props.removeStationComp(compId, true);
  }
  updateStationSettings(compId, update) {
    this.props.updateStationSettings(compId, update);
  }
  updateStation(compId, color) {
    this.props.updateStation(compId, color, true);
  }

  addGraphComp(type, layout = null) {
    this.props.addGraphComp(type, layout);
  }
  removeGraphComp(index, graphId) {
    this.props.removeGraphComp(index, true);
  }
  updateGraphComp(index, update) {
    this.props.updateGraphComp(index, update);
  }

  onOpenOrClose(openOrClose) {
    this.setState({ isOpen: this.state.isOpen + openOrClose });
  }

  onLayoutChange(newLayouts) {
    this.props.onLayoutChange(newLayouts);
  }

  showSaveModal() {
    this.setState({ showSaveModal: true });
  }
  hideSaveModal() {
    this.setState({ showSaveModal: false });
  }

  showTemplateModal() {
    this.setState({ showTemplateModal: true });
  }
  hideTemplateModal() {
    this.setState({ showTemplateModal: false });
  }

  showLoadModal() {
    this.setState({ showLoadModal: true });
  }
  hideLoadModal() {
    const { onReject, key } = this.state.loadData;

    (typeof onReject === "function") && onReject();

    key && window.localStorage && window.localStorage.removeItem(key);

    this.setState({
      showLoadModal: false,
      loadData: {}
    });
  }
  loadFromStorage() {
    if (window.localStorage) {
      const { data } = this.state.loadData;
      this.props.loadReport(data);
    }
    this.setState({ loadData: {} });
  }

  loadTemplate(id) {
    this.props.loadTemplate(id);
  }
  saveTemplate(template, templateId) {
    return this.props.saveTemplate(template, templateId);
  }

  needsUpdate() {
    return this.props.route_comps.reduce((a, { compId, settings }) => {
      const SETTINGS = this.props.routeComponentSettings.get(compId);
      return a || this._needsUpdate(SETTINGS, settings);
    }, false) ||
    this.props.station_comps.reduce((a, c) =>
      a || !deepequal(c.settings, c.workingSettings)
    , false);
  }
  _needsUpdate(SETTINGS, settings) {
    return SETTINGS.startDate !== settings.startDate ||
      SETTINGS.endDate !== settings.endDate ||
      SETTINGS.startTime !== settings.startTime ||
      SETTINGS.endTime !== settings.endTime ||
      SETTINGS.resolution !== settings.resolution ||
      SETTINGS.dataColumn !== settings.dataColumn ||
      SETTINGS.compTitle !== settings.compTitle ||
      !deepequal(SETTINGS.weekdays, settings.weekdays) ||
      !deepequal(SETTINGS.overrides, settings.overrides);
  }

  showTableModal(data) {
    this.setState({ showTableModal: true, tableModalData: data });
  }
  hideTableModal() {
    this.setState({ showTableModal: false });
  }

  render () {
    const numRouteIds = [
        ...new Set(this.props.route_comps.map(rc => rc.routeId))
      ].length,
      numStationIds = [
        ...new Set(this.props.station_comps.map(sc => sc.stationId))
      ].length,
      templates = this.props.templates
        .filter(({ routes, stations }) => (routes === numRouteIds) && (stations === numStationIds)),
      isNetwork = this.props.route_comps
        .reduce((a, c) => a || (c.colltype === "network"), false);

    return (
      <div style={ { position: "relative" } }>

        <GraphLayoutContainer isOpen={ this.state.isOpen }>
          <div className="container">

            { this.state.viewing ? null :
              <div style={ { display: "flex" } }>
                <div style={ { width: "70%", alignItems: "flex-end", display: "flex" } }>
                  <TitleContainer>
                    { this.props.title }
                  </TitleContainer>
                </div>

                <div style={ { width: "30%" } }>
                  <HeaderControlBox>
                    <button className="btn btn-sm btn-outline-primary"
                      type="button"
                      onClick={ e=> this.togglePreviewing() }>
                      <span className="fa fa-eye mr-1"/>
                      { this.state.previewing ? "Show Controls" : "Hide Controls" }
                    </button>
                    <div />
                    <button className="btn btn-sm btn-outline-success"
                      onClick={ e => this.showSaveModal() }
                      disabled={
                        ((this.props.route_comps.length === 0) &&
                         (this.props.station_comps.length === 0)) ||
                        (this.props.graphs.length === 0)
                      }>
                      <span className="fa fa-file-text mr-1"/>
                      Save as Report
                    </button>
                    <button className="btn btn-sm btn-outline-success"
                      onClick={ e => this.showTemplateModal() }
                      disabled={
                        ((this.props.route_comps.length === 0) &&
                         (this.props.station_comps.length === 0)) ||
                        (this.props.graphs.length === 0) ||
                        isNetwork
                      }>
                      <span className="fa fa-gear mr-1"/>
                      Save as Template
                    </button>
                  </HeaderControlBox>
                </div>
              </div>
            }

            { !this.state.viewing ? null :
              <div style= { {
                  padding: "10px 20px",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  border: "solid 2px #dde2ec"
                } }>
                <TitleContainer>
                  { this.props.title }
                </TitleContainer>
                <DescriptionContainer>
                  { this.props.description }
                </DescriptionContainer>
              </div>
            }

            <GraphLayout
              viewing={ this.state.viewing }
              previewing={ this.state.previewing }
              routes={ this.props.routes }
              graphs={ this.props.graphs }
              updateGraphComp={ this.updateGraphComp.bind(this) }
              removeGraphComp={ this.removeGraphComp.bind(this) }
              addGraphComp={ this.addGraphComp.bind(this) }
              onLayoutChange={ this.onLayoutChange.bind(this) }
              highlightedTmcs={ this.state.highlightedTmcs }
              highlightTmcs={ this.highlightTmcs.bind(this) }
              unhighlightTmcs={ this.unhighlightTmcs.bind(this) }
              setHighlightedTmcs={ this.setHighlightedTmcs.bind(this) }
              showTableModal={ this.showTableModal.bind(this) }
              hideTableModal={ this.hideTableModal.bind(this) }
              colorRange={ this.props.colorRange }
              station_comps={ this.props.station_comps }/>

          </div>
        </GraphLayoutContainer>

        { this.state.viewing ? null :
          <Sidebar isOpen={ this.state.isOpen }
            graphs={ this.props.graphs }
            route_comps={ this.props.route_comps }
            routes={ this.props.routes }
            routeComponentSettings={ this.props.routeComponentSettings }
            onOpenOrClose={ this.onOpenOrClose.bind(this) }
            addGraphComp={ this.addGraphComp.bind(this) }
            removeGraphComp={ this.removeGraphComp.bind(this) }
            updateRouteCompSettings={ this.updateRouteCompSettings.bind(this) }
            updateRouteComp={ this.updateRouteComp.bind(this) }
            updateRouteCompColor={ this.updateRouteCompColor.bind(this) }
            updateAllComponents={ this.updateAllComponents.bind(this) }
            reorderRouteComps={ this.reorderRouteComps.bind(this) }
            needsUpdate={ this.needsUpdate() }
            removeRouteComp={ this.removeRouteComp.bind(this) }
            addRouteComp={ this.addRouteComp.bind(this) }
            dateExtent={ this.props.dateExtent }
            yearsWithData={ this.props.yearsWithData }
            availableRoutes={ this.props.availableRoutes }
            templates={ templates }
            loadTemplate={ this.loadTemplate.bind(this) }
            folders={ this.props.folders }
            selectColorRange={ this.props.selectColorRange }
            colorRange={ this.props.colorRange }
            availableStations={ this.props.availableStations }
            station_comps={ this.props.station_comps }
            addStationComp={ this.addStationComp.bind(this) }
            removeStationComp={ this.removeStationComp.bind(this) }
            updateStationSettings={ this.updateStationSettings.bind(this) }
            updateStation={ this.updateStation.bind(this) }
            reorderStationComps={ this.props.reorderStationComps }/>
        }

        <Modal show={ this.state.showTableModal }
          onHide={ this.hideTableModal.bind(this) }
          persistChildren={ false }>
          <div style={ { width: "95vw" } }>
            <AvlTable rowsPerPage={ 288 }
              pageSpread={ 6 }
              showHelp={ true }
              { ...this.state.tableModalData }/>
          </div>
        </Modal>

        <ReportSaveModal show={ this.state.showSaveModal }
          onHide={ this.hideSaveModal.bind(this) }
          updateReport={ this.updateReport.bind(this) }
          saveReport={ this.saveReport.bind(this) }
          user={ this.props.user }
          reportId={ this.props.reportId }
          report={ {
            title: this.props.title,
            description: this.props.description,
            type: this.props.type,
            owner: this.props.owner,
            colorRange: this.props.colorRange
          } }/>

        <TemplateModal show={ this.state.showTemplateModal }
          saveTemplate={ this.saveTemplate.bind(this) }
          templateId={ this.props.defaultTypes.includes(this.props.templateId) ? (this.props.user.groups.includes("AVAIL") ? this.props.templateId : null) : this.props.templateId }
          onHide={ this.hideTemplateModal.bind(this) }
          defaultTypes={ this.props.defaultTypes }
          user={ this.props.user }
          report={ {
            title: this.props.title,
            description: this.props.description,
            type: this.props.type,
            owner: this.props.owner,
            route_comps: this.props.route_comps,
            graph_comps: this.props.graphs,
            station_comps: this.props.station_comps,
            saveYearsAsRecent: this.props.saveYearsAsRecent,
            colorRange: this.props.colorRange
          } }/>

        <LoadModal show={ this.state.showLoadModal }
          onHide={ this.hideLoadModal.bind(this) }
          loadFromStorage={ this.loadFromStorage.bind(this) }
          reportId={ this.state.loadData.reportId }/>

      </div>
    )
  }
}
// //
class EditReportClass extends ReportBase {
  constructor(props) {
    super(props);
    this.state.viewing = false;
    this.state.isOpen = 1;
  }
}
class ViewReportClass extends ReportBase {
  constructor(props) {
    super(props);
    this.state.viewing = true;
    this.state.isOpen = 0;
  }
}

const mapStateToProps = (state, props) => ({
  router: state.router,
  user: state.user,
  availableRoutes: getAvailableRoutesFromState(state),
  templates: getTemplatesFromState(state),
  defaultTypes: get(state, 'graph.templates.defaultTypes.value', []),
  folders: getFoldersFromState(state),
  availableStations: getStationsFromState(state),
  ...state.report
})

const mapDispatchToProps = {
  ...REDUX_ACTIONS
}

export const EditReport = connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(EditReportClass));
export const ViewReport = connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(ViewReportClass));

const GraphLayoutContainer = styled.div`
  overflow-y: auto;
  margin-left: ${ props => props.isOpen ? 300 : 0 }px;
  transition: margin-left 250ms;
  padding: 20px 0px;
  min-height: 100vh;
`

const TitleContainer = styled.div`
  font-size: 2rem;
  font-weight: bold;
`
const DescriptionContainer = styled.div`
  font-size: 1rem;
`

const HeaderControlBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: space-between;
  width: calc(100% - 4px);
  margin-left: 4px;

  > * {
    width: calc(50% - 2px);
    margin: 0px;
  }
  > *:nth-child(even) {
    margin-left: 4px;
  }
  > *:first-child,
  > *:nth-child(2) {
    margin-bottom: 4px;
  }
`

const getAvailableRoutesFromState = state => {
  const length = get(state, 'graph.routes.length', 0),
    ids = [];
  for (let i = 0; i < length; ++i) {
    const id = get(state, `graph.routes.byIndex[${ i }].value[2]`, null);
    if (id !== null) {
      ids.push(id);
    }
  }
  return ids.map(id => ({
    id,
    name: get(state, `graph.routes.byId[${ id }].name`, "")
  }))
  .filter(({ name }) => Boolean(name));
}
const getTemplatesFromState = state => {
  const { yearsWithData } = state.report,
    mostRecent = Math.max(...yearsWithData);

  const length = get(state, 'graph.templates.length', 0),
    ids = [];
  for (let i = 0; i < length; ++i) {
    const id = get(state, `graph.templates.byIndex[${ i }].value[2]`, null);
    if (id !== null) {
      ids.push(id);
    }
  }

console.log("TEMPLATES:", get(state, `graph.templates.byId`, {}))

  return ids.map(id => ({
    id,
    title: get(state, `graph.templates.byId[${ id }].title`, ""),
    routes: +get(state, `graph.templates.byId[${ id }].routes`, 0),
    stations: +get(state, `graph.templates.byId[${ id }].stations`, 0)
  }))
  .map(({ title, ...rest }) => ({
    ...rest,
    title: RECENT_REGEX.test(title) ? replace(title, mostRecent) : title
  }))
  // .filter(({ title, routes, stations }) => Boolean(title) && (Boolean(routes) || Boolean(stations)))
}
const getStationsFromState = state => {
  const length = get(state, 'graph.hds.continuous.stations.length', 0),
    stationRefs = [],
    stations = [];

  for (let i = 0; i <= length; ++i) {
    const ref = get(state, ['graph', 'hds', 'continuous', 'stations', 'byIndex', i, 'value'], null);
    if (ref) {
      stationRefs.push(ref);
    }
  }
  return stationRefs.reduce((a, c) => {
    const station = get(state, ['graph', ...c], null);
    if (station) {
      a.push({ ...station });
    }
    return a;
  }, []);
}

const TextArea = styled.textarea`
  ${ props => props.theme.textarea };
`;

class ReportSaveModal extends React.Component {
  state = { ...this.props.report }
  componentDidUpdate() {
    if (!this.props.show && !deepequal(this.state, this.props.report)) {
      this.setState({ ...this.props.report });
    }
  }
  onChange(update) {
    if ((update.type === "personal") && (this.state.type !== "personal")) {
      update = {
        ...update,
        owner: this.props.user.id
      }
    }
    else if ((update.type === "group") && (this.state.type !== "group")) {
      update = {
        ...update,
        owner: this.props.user.groups[0]
      }
    }
    else if ((update.type === "public") && (this.state.type !== "public")) {
      update = {
        ...update,
        owner: this.props.user.id
      }
    }
    this.setState(update);
  }
  onSave() {
    this.props.updateReport({ ...this.state });
    return this.props.saveReport({ ...this.state }, this.props.reportId);
  }
  onSaveAs() {
    this.props.updateReport({ ...this.state });
    return this.props.saveReport({ ...this.state });
  }
  render() {
    return (
      <Modal show={ this.props.show }
        onHide={ this.props.onHide }
        hideOnAction={ false }
        actions={
          [
            { label: this.props.reportId === null ? "Save Report" : "Save Changes",
              action: this.onSave.bind(this),
              type: "success" },
            { label: "Save As New Report",
              action: this.onSaveAs.bind(this),
              type: "success" }
          ].filter(({ label }) => (label !== "Save As New Report") || this.props.reportId)
        }>

        <ModalContainer>
          <div>
            <label>Report Title</label>
            <Input type="text"
              onChange={ e => this.onChange({ title: e.target.value }) }
              value={ this.state.title }/>
          </div>

          <div>
            <label>Report Description</label>
            <TextArea placeholder="enter a description"
              onChange={ e => this.onChange({ description: e.target.value }) }
              value={ this.state.description }
              rows="5"/>
          </div>

          <div>
            <label>Report Type</label>
            <ItemSelector
              multiSelect={ false }
              searchable={ false }
              selectedItems={ this.state.type }
              options={ ["personal", "group", "public"] }
              displayOption={ d => d }
              getOptionValue={ d => d }
              onChange={ type => this.onChange({ type }) }/>
          </div>

          <div>
            <label>Report Owner</label>
            <ItemSelector
              disabled={ this.state.type !== "group" }
              multiSelect={ false }
              searchable={ false }
              selectedItems={ this.state.owner }
              options={ [...this.props.user.groups] }
              displayOption={ d => this.state.type === "group" ? d : "self" }
              getOptionValue={ d => d }
              onChange={ group => this.onChange({ group }) }/>
          </div>
        </ModalContainer>

      </Modal>
    )
  }
}

class TemplateModal extends React.Component {
  state = {
    ...this.props.report,
    defaultType: "none"
  }
  componentDidUpdate(oldProps) {
    const {
      defaultType,
      ...report
    } = this.state;
    if ((!this.props.show && !deepequal(report, this.props.report)) ||
        (oldProps.defaultTypes.length !== this.props.defaultTypes.length)) {
      this.setState({
        ...this.props.report,
        defaultType: this.props.defaultTypes.includes(this.props.templateId) && get(this.props.user, 'groups', []).includes("AVAIL") ? this.props.templateId : "none"
      });
    }
  }
  onChange(update) {
    if ((update.type === "personal") && (this.state.type !== "personal")) {
      update = {
        ...update,
        owner: this.props.user.id
      }
    }
    else if ((update.type === "group") && (this.state.type !== "group")) {
      update = {
        ...update,
        owner: this.props.user.groups[0]
      }
    }
    else if ((update.type === "public") && (this.state.type !== "public")) {
      update = {
        ...update,
        owner: this.props.user.id
      }
    }
    else if (update.defaultType && (update.defaultType !== "none")) {
      update = {
        ...update,
        type: "public",
        owner: this.props.user.id
      }
    }
    this.setState(update);
  }
  onSave() {
    return this.props.saveTemplate(this.state, this.props.templateId);
  }
  onSaveAs() {
    return this.props.saveTemplate(this.state);
  }

  render() {
    return (
      <Modal show={ this.props.show }
        onHide={ this.props.onHide }
        actions={
          [
            { label: this.props.templateId === null ? "Save Template" : "Save Changes",
              action: this.onSave.bind(this),
              type: "success" },
            { label: "Save As New Template",
              action: this.onSaveAs.bind(this),
              type: "success" }
          ].filter(({ label }) => (label !== "Save As New Template") || this.props.templateId)
        }>

        <ModalContainer>
          <div>
            <label>Template Title</label>
            <Input type="text"
              onChange={ e => this.onChange({ title: e.target.value }) }
              value={ this.state.title }/>
          </div>

          <div>
            <label>Template Description</label>
            <TextArea placeholder="enter a description"
              onChange={ e => this.onChange({ description: e.target.value }) }
              value={ this.state.description }
              rows="5"/>
          </div>

          <div>
            <label>Template Type</label>
            <ItemSelector
              disabled={ this.state.defaultType !== "none" }
              multiSelect={ false }
              searchable={ false }
              selectedItems={ this.state.type }
              options={ ["personal", "group", "public"] }
              displayOption={ d => d }
              getOptionValue={ d => d }
              onChange={ type => this.onChange({ type }) }/>
          </div>

          <div>
            <label>Template Owner</label>
            <ItemSelector
              disabled={ (this.state.type === "personal") || (this.state.type === "public") }
              multiSelect={ false }
              searchable={ false }
              selectedItems={ this.state.owner }
              options={ [...this.props.user.groups] }
              displayOption={ d => this.state.type !== "group" ? "self" : d }
              getOptionValue={ d => d }
              onChange={ group => this.onChange({ group }) }/>
          </div>

          <div style={ {
              display: "flex",
              alignItems: "center"
            } }>
            <label style={ { width: "45%" } }>Save Years As Recent</label>
            <Input type="checkbox"
              style={ { height: "1.25rem", width: "1.25rem" } }
              checked={ this.state.saveYearsAsRecent }
              onChange={ e => this.onChange({ saveYearsAsRecent: e.target.checked }) }/>
          </div>

          { !get(this.props.user, 'groups', []).includes("AVAIL") ? null :
            <div>
              <label>Default Type</label>
              <ItemSelector
                multiSelect={ false }
                searchable={ false }
                selectedItems={ this.state.defaultType }
                options={ this.props.defaultTypes }
                displayOption={ d => d }
                getOptionValue={ d => d }
                onChange={ defaultType => this.onChange({ defaultType }) }/>
            </div>
          }
        </ModalContainer>

      </Modal>
    )
  }
}

class LoadModal extends React.Component {
  render() {
    return (
      <Modal show={ this.props.show }
        onHide={ this.props.onHide }
        closeLabel="Cancel"
        actions={
          [
            { label: "Restore",
              action: this.props.loadFromStorage,
              type: "success" }
          ]
        }>

        <ModalContainer>
          <div>
            <H4>{ `You have unsaved data${ this.props.reportId ? ` for this report` : `` }.` }</H4>
            <div>Do you wish to load your unsaved data?</div>
          </div>
          <div>
            { `Selecting <Cancel> will result in the lose of all unsaved data.` }
          </div>
        </ModalContainer>

      </Modal>
    )
  }
}

const H4 = styled.h4`
  color: ${ props => props.theme.textColorHl };
`

const ModalContainer = styled.div`
  min-width: 400px;

  > * {
    margin-bottom: 20px;
  }
  > *:last-child {
    margin-bottom: 0px;
  }
`
