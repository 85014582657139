import React from 'react';

import { precisionRound } from 'utils/calculators/MathUtils';

import {
  HIDE_OUTLIERS,
  HIDE_EXTREME_OUTLIERS,
  SHOW_OUTLIERS,
  outlierInclusionLabels
} from 'utils/calculators/OutlierHandlingUtils';

const outlierFiltersSubset = [
  HIDE_OUTLIERS,
  HIDE_EXTREME_OUTLIERS,
  SHOW_OUTLIERS
];

const MaxPeakTTRByOutlierFilter = ({
  data: { measure, maxPeakTTRByPeakOutlierFilter }
}) =>
  maxPeakTTRByPeakOutlierFilter ? (
    <div>
      <style>{`
        table, th, td { border: 1px solid black; border-collapse: collapse; }
        th { background-color: #EEEEEE; } 
      `}</style>
      <table>
        <tbody>
          <tr>
            <th>Outlier Filter</th>
            <th>Travel Time Reliabilty</th>
          </tr>
          {outlierFiltersSubset.map((outlierFilter, i) => (
            <tr key={`makpeakttr_${i}`}>
              <td>{outlierInclusionLabels[outlierFilter]}</td>
              <td>
                <b>
                  {precisionRound(
                    maxPeakTTRByPeakOutlierFilter[outlierFilter],
                    2
                  )}
                </b>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;

export default MaxPeakTTRByOutlierFilter;
