import React from 'react'
import TableBox from "../../../../components/light-admin/tables/TableBox";
import {BASE_DATA_TYPES} from "../../../../components/tmc_graphs/utils/dataTypes";

class IncidentsTable extends React.Component {
    formatTime(time, format){
        let minutes = `${time.toFixed(2)} Minutes`,
            hours = time/60 > 1 ? `${(time/60).toFixed(2)} Hours` : `${(time/60).toFixed(2)} Hour`,
            days = time/(60*24) > 1 ? `${(time/(60*24)).toFixed(2)} Days` : `${(time/(60*24)).toFixed(2)} Day`;
        if (!time) return '';
        if (format === 'hours'){
            return hours
        }
        if (time < 60){
            return minutes
        }else if (time/60 < 24){
            return hours
        }else{
            return days
        }
    }
    render() {
        if (!this.props.data) return null;
        let reportsInfo = this.props.data;

        let data = Object.keys(reportsInfo)
            .reduce((a, id) => {
                const { title, updated_at } = reportsInfo[id];
                if (!this.props.filter || title.toLowerCase().includes(this.props.filter.toLowerCase())) {
                    a.push({ id, ...reportsInfo[id], sort: new Date(updated_at).valueOf() })
                }
                return a;
            }, []).sort((a, b) => b.sort - a.sort);
        let groupByFacility = {};
        data = data
            .filter(d =>
                (this.props.category ==='all' ? true : d.event_category === this.props.category) &&
                (this.props.type ==='all' ? true : d.event_type === this.props.type) &&
                (this.props.facility ==='all' ? true : d.facility === this.props.facility)
            )
            .map(({ eventid, event_category, event_type, facility, open_time, close_time, duration, congestion_data}) => {
                let maxDelay, maxvDelay;
                if (congestion_data){
                    maxDelay = Math.max(...Object.values(congestion_data)
                        .map(cd => cd.delay));
                    maxvDelay = Math.max(...Object.values(congestion_data)
                        .map(cd => cd.VehicleDelay))
                }
                if(groupByFacility[facility]){
                    groupByFacility[facility].totalIncidents += 1;
                    if (parseFloat(this.formatTime(Math.abs(maxvDelay), 'hours').split(' ')[0])){
                        groupByFacility[facility].totalVDelay += parseFloat(this.formatTime(Math.abs(maxvDelay), 'hours').split(' ')[0]);
                    }
                }else{
                    groupByFacility[facility] = {
                        totalIncidents: 1,
                        totalVDelay:
                            parseFloat(this.formatTime(Math.abs(maxvDelay), 'hours').split(' ')[0]) ?
                                parseFloat(this.formatTime(Math.abs(maxvDelay), 'hours').split(' ')[0]) : null
                    }
                }
                return (
                    eventid ? { eventid, event_category, event_type, facility, open_time, close_time, duration,
                        delay: this.formatTime(Math.abs(maxDelay)),
                        vehicle_delay: this.formatTime(Math.abs(maxvDelay), 'hours')
                    } : null
                );
            })
            .filter(f => f)
            .sort((a,b) => b.vehicle_delay.split(' ')[0] - a.vehicle_delay.split(' ')[0]);
        // group data by facility id
        let groupedData =
            Object.keys(groupByFacility)
                .map(facility =>
                    (
                        {
                            facility, '# of Incidents': groupByFacility[facility].totalIncidents,
                            'Hours of Delay':groupByFacility[facility].totalVDelay ? groupByFacility[facility].totalVDelay + ' Hours' : null,
                            sort: groupByFacility[facility].totalVDelay ? groupByFacility[facility].totalVDelay : null
                        }
                    ))
                .sort((a,b) => b.sort - a.sort);
        groupedData = groupedData.map(f => {
            delete f.sort;
            return f
        })
        return this.props.groupByFacility ?
            (
                <TableBox
                    data={groupedData}
                    filterKey = 'facility'
                    noDataText = {'Please Make / Modify selection.'}
                />
            ) :
        (
            <TableBox
                data={data}
                filterKey = 'eventid'
                links={{'eventid': (row) => {
                    return {
                        pathname: '/incidents/' + row['eventid'],
                        oldState: this.props
                    }
                    }}}
                noDataText = {'Please Make / Modify selection.'}
            />
        )
    }
}

IncidentsTable.defaultProps = {
    data: {}
};

export default IncidentsTable