import React, { useState } from 'react';

import PM3CalculationsVersionSelector from 'components/selectors/PM3CalculationsVersionSelector';
import YearSelector from 'components/selectors/YearSelector';
import Pm3VersionIdExplanationModal from 'components/information/Pm3VersionIdExplanationModal';

const Header = ({
  stateName,
  selectedPm3VersionId,
  availablePm3VersionIds,
  calculatedYears,
  year,
  pm3VersionSelected,
  yearSelected
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="row">
      <div className="col-6">
        <span>
          <h3
            className="inline"
            style={{
              paddingLeft: '6px',
              marginTop: 10,
              verticalAlign: 'middle'
            }}
          >
            {' '}
            MAP 21 PM3 Measures {stateName ? ` — ${stateName}` : ''}{' '}
          </h3>
        </span>
      </div>

      <div className="col-3">
        {availablePm3VersionIds ? (
          <div>
            <PM3CalculationsVersionSelector
              pm3VersionId={selectedPm3VersionId}
              pm3VersionIds={availablePm3VersionIds}
              onChange={pm3VersionSelected}
            />
          </div>
        ) : null}
      </div>
      <div className="col-1">
        <span
          onClick={() => setShowModal(!showModal)}
          style={{
            cursor: 'pointer',
            align: 'left'
          }}
        >
          &#x1F6C8;
        </span>
      </div>

      <div className="col-2">
        {calculatedYears ? (
          <YearSelector
            year={year}
            years={calculatedYears}
            onChange={yearSelected}
          />
        ) : null}
      </div>

      <Pm3VersionIdExplanationModal
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};

export default Header;
