import React from "react"

import { Input } from "components/common/styled-components"
import ItemSelector from "components/common/item-selector/item-selector"

import AvlModal from "components/AvlStuff/AvlModal"
import FolderIcon from "./FolderIcon"

import { CustomPicker } from 'react-color'
import { Saturation, Hue } from 'react-color/lib/components/common';

// import classnames from "classnames"
import deepequal from "deep-equal"
import styled from "styled-components"

const ICONS = [
  "fa fa-map",
  "fa fa-road",
  "fa fa-car",
  "fa fa-exclamation mt-1",
  "fa fa-file-text",
  "fa fa-bar-chart-o",
  "fa fa-tachometer",
  "fa fa-gear"
]

export default class CreateFolderModal extends React.Component {
  static defaultProps = {
    editFolder: null
  }
  state = {
    id: null,
    name: "",
    type: "personal",
    owner: this.props.user.id,
    icon: ICONS[0],
    color: "#303030"
  }
  componentDidUpdate(oldProps) {
    if (this.props.editFolder && (this.state.id !== this.props.editFolder.id)) {
      this.setState({ ...this.props.editFolder });
    }
  }
  onHide() {
    this.props.onHide();
    this.setState({
      id: null,
      name: "",
      type: "personal",
      owner: this.props.user.id,
      icon: ICONS[0],
      color: "#303030"
    });
  }
  doSomething() {
    const {
      id,
      name,
      type,
      owner,
      icon,
      color
    } = this.state;
    if (id === null) {
      return this.props.createFolder(
        [name, type, owner, icon, color]
      )
    }
    else {
      return this.props.updateFolder({ ...this.state })
    }
  }
  validate() {
    return Boolean(this.props.editFolder) ?
      !deepequal(this.props.editFolder, this.state) :
      Boolean(this.state.name);
  }
  update(key, value) {
    const newState = { [key]: value };
    if ((key === "type") && (value === "personal")) {
      newState.owner = this.props.user.id;
    }
    else if ((key === "type") && (value === "group")) {
      newState.owner = this.props.user.groups[0];
    }
    this.setState(newState);
  }
  render() {
    return (
      <AvlModal show={ this.props.show }
        onHide={ e => this.onHide() }
        actions={ [
					{ label: Boolean(this.state.id) ? "Update" : "Create",
						action: e => this.doSomething(),
						type: "success",
            disabled: !this.validate()
					}
        ] }>
        <div style={ { width: "600px" } }>
          <FlexContainer>
            <label htmlFor="name">Name</label>
            <Input type="text" id="name"
              value={ this.state.name }
              onChange={ e => this.update("name", e.target.value) }
              placeholder="Enter name..."/>
          </FlexContainer>
          <FlexContainer>
            <label htmlFor="type">Type</label>
            <ItemSelector id="type"
              selectedItems={ this.state.type }
              searchable={ false }
              placeholder="Select a Value"
              options={ ["personal", "group"] }
              multiSelect={ false }
              displayOption={ d => d }
              getOptionValue={ d => d }
              onChange={ v => this.update("type", v) }
              inputTheme="secondary"/>
          </FlexContainer>
          <FlexContainer>
            <label htmlFor="owner">Owner</label>
            <ItemSelector id="owner"
              disabled={ this.state.type !== "group" }
              selectedItems={ this.state.type === "group" ? this.state.owner : "self" }
              searchable={ false }
              placeholder="Select a Value"
              options={ this.props.user.groups }
              multiSelect={ false }
              displayOption={ d => d }
              getOptionValue={ d => d }
              onChange={ v => this.update("owner", v) }
              inputTheme="secondary"/>
          </FlexContainer>
          <IconPicker selected={ this.state.icon }
            color={ this.state.color }
            setIcon={ i => this.update("icon", i) }/>
          <ColorPickerDiv>
            <div>
    					<MyCustomPicker color={ this.state.color }
    						onChangeComplete={ ({ hex }) => this.update("color", hex) }/>
            </div>
          </ColorPickerDiv>
        </div>
      </AvlModal>
    )
  }
}

const IconDiv = styled.div`
  border-radius: 8px;
  border: 2px solid transparent;
  transition: border-color 0.15s;
  margin: 2px;
  &.selected, :hover {
    border-color: #000000;
  }
`
class IconPicker extends React.Component {
  render() {
    const { selected, setIcon, color } = this.props;
    return (
      <div style={ { borderRadius: "4px", padding: "10px", backgroundColor: "#888", marginBottom: "10px" } }>
        <FlexContainer style={ { margin: 0, justifyContent: "center", padding: "10px", backgroundColor: "#efefef", borderRadius: "4px" } }>
          {
            ICONS.map(i =>
              <IconDiv key={ i }
                onClick={ e => setIcon(i) }
                className={ selected === i ? "selected" : "" }>
                <FolderIcon icon={ i }
                  onClick={ e => setIcon(i) }
                  color={ color }/>
              </IconDiv>
            )
          }
        </FlexContainer>
      </div>
    )
  }
}

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  white-space: nowrap;
  margin-bottom: 10px;
  > label {
    align-self: center;
    margin-right: 10px;
    width: calc(15% - 10px);
    text-align: right;
  }
  > input:nth-child(2),
  > .item-selector:nth-child(2) {
    width: 80%;
  }
`
const ColorPickerDiv = styled.div`
  display: flex;
  justify-content: center;
  > * {
    width: 50%;
    padding: 10px;
    background-color: #888;
    border-radius: 4px;
  }
`
class MyPicker extends React.Component {
	render() {
		return (
			<div style={ { width: "100%" } }>
				<div style={ { position: "relative", height: "200px", cursor: "pointer" } }>
					<Saturation { ...this.props }
						onChange={ this.props.onChange }/>
				</div>
				<div style={ { position: "relative", height: "10px", marginTop: "10px", borderRadius: "4px", cursor: "pointer" } }>
					<Hue { ...this.props }
						direction="horizontal"
						onChange={ this.props.onChange }
						pointer={ () => <div style={ { width: "6px", height: "16px", background: "#fff", marginTop: "-3px", borderRadius: "3px" } }/> }/>
				</div>
			</div>
		)
	}
}
const MyCustomPicker = CustomPicker(MyPicker)
