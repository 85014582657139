import clone from 'clone';

import get from 'lodash.get';

import { getSelectedTmcsBoundingBox as falcorGetSelectedTmcsBoundingBox } from 'store/falcorGraphSelectors';

import { getYear } from '../dataSelection';

import registry, { controlledComponents } from '../registry';

const reduxStorePath = registry[controlledComponents];

const _ = { get };

const getSliceState = state => {
  return _.get(state, reduxStorePath, {});
};

export const getTablePage = state => {
  const { tablePage } = getSliceState(state);

  return tablePage || 0;
};

export const getTableSorted = state => {
  const { tableSorted = null } = getSliceState(state);

  return tableSorted && clone(tableSorted);
};

export const getTableFiltered = state => {
  const { tableFiltered = null } = getSliceState(state);

  return tableFiltered && clone(tableFiltered);
};

export const getTableTmcsBoundingBox = state => {
  const year = getYear(state);
  const { tableSortedData = null } = getSliceState(state);

  const tmcs = tableSortedData && tableSortedData.map(({ tmc }) => tmc);

  return falcorGetSelectedTmcsBoundingBox(state, tmcs, year);
};

export const getHoveredTmcPage = state => {
  const { hoveredTmcTablePage = null } = getSliceState(state);

  return hoveredTmcTablePage;
};

export const getTableData = state => {
  const { tableSortedData = null } = getSliceState(state);

  return tableSortedData;
};

export const getTableDataDomain = state => {
  const { tableDataDomain = null } = getSliceState(state);

  return tableDataDomain;
};

export const getTablePageSize = state => {
  const { tablePageSize = null } = getSliceState(state);

  return tablePageSize;
};

export const getTmcColors = state => {
  const { tmcColors = null } = getSliceState(state);

  return tmcColors;
};

// import pick from 'lodash.pick';
// import omitBy from 'lodash.omitby';
// import deepEqual from 'deep-equal';
// import { createShareableUrl } from 'store/routingUtils';
// import validURLParams from './validURLParams';
// import initialState from './initialState';
