import React from 'react';

import AvlMap from "components/AvlMap"

import ConflationCountsLayerFactory from './layers/conflation.counts.layer'
import ContinuousLayerFactory from "./layers/conflation.continuous.layer"

class Conflation extends React.Component {
  CountsLayer = ConflationCountsLayerFactory({ active: true });
  ContinuousLayer = ContinuousLayerFactory({ active: false });
  render() {
    return (
      <div style={ { height: "100vh" } }>
        <AvlMap
          layers={ [
            this.CountsLayer,
            this.ContinuousLayer
          ] }
          styles={[
            { name: 'Dark Streets', style: 'mapbox://styles/am3081/ck3rtxo2116rr1dmoppdnrr3g'},
            { name: 'Light Streets', style: 'mapbox://styles/am3081/ck3t1g9a91vuy1crzp79ffuac'}
          ]}
          header="Traffic Counts"
        />
      </div>
    )
  }
}

export default {
  class: 'fa',
	icon: 'fa-bus',
	path: '/counts',
	exact: true,
	mainNav: false,
  menuSettings: {
    //display: 'none',
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-mini',
    style: 'color-style-default'
  },
  name: 'Counts',
	auth: true,
	component: Conflation
}
