export const geomeasureVisualizer = 'geomeasureVisualizer';
export const dataSelection = 'dataSelection';
export const userInteraction = 'userInteraction';
export const controlledComponents = 'controlledComponents';

export default {
  [geomeasureVisualizer]: [geomeasureVisualizer],
  [dataSelection]: [geomeasureVisualizer, dataSelection],
  [userInteraction]: [geomeasureVisualizer, userInteraction],
  [controlledComponents]: [geomeasureVisualizer, controlledComponents]
};
