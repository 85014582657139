// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import { precisionRound } from 'utils/calculators/MathUtils';

const hourLabels = [...Array(24)].map(
  (_, hr) => `${hr % 12 || 12} ${hr < 12 ? 'am' : 'pm'}`
);

const formatData = hourlyCongestionValues => {
  if (
    !(Array.isArray(hourlyCongestionValues) && hourlyCongestionValues.length)
  ) {
    return null;
  }

  const x = hourLabels.slice();
  const y = hourlyCongestionValues.map(hfcv => precisionRound(hfcv));

  return { x, y };
};

const getViz = ({ hourlyCongestionValues }) => {
  const { x, y } = formatData(hourlyCongestionValues) || {};

  const data = [
    {
      x,
      y,
      type: 'scatter',
      line: { width: 3, shape: 'hv' }
    }
  ];

  const layout = {
    title: 'ATRI Congestion Values By Hour',
    xaxis: {
      title: 'Hour of Day'
    },
    yaxis: {
      title: 'Congestion Value'
    }
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '62%' }}
      data={data}
      layout={layout}
      config={config}
    />
  );
};

const ATRIHourlyCongestionLineChart = ({ data }) => {
  const { tmcDirectionalAadt, tmcHourlyAvgTrafficVolume } = data;

  return data.hourlyCongestionValues ? (
    <div style={{ width: '100%', color: '#333' }}>
      <blockquote
        style={{
          width: '38%',
          marginLeft: 20,
          backgroundColor: '#F2F4F8',
          padding: 12
        }}
      >
        3. The value derived in Step 2 is multiplied, on an hour-by-hour basis,
        by the number of commercial trucks that were part of the corresponding
        hour block (truck volume data by hour). The product is the “hourly
        freight congestion value.” Thus, commercial trucks not affected by delay
        produce a delay value of zero.
        <sup>1</sup>
      </blockquote>
      <p style={{ width: '62%' }}>
        AVAIL uses a competely even distribution to determine the average number
        of vehicles on the road at a given time of day. We divide the
        directional
        <a
          href="https://en.wikipedia.org/wiki/Annual_average_daily_traffic"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'\u00a0'}Average Annual Daily Traffic
        </a>{' '}
        by 24 to get the number of vehicles by hour.
      </p>
      <p style={{ width: '62%' }}>
        <b>Directional AADT</b>
        {`\u00a0${precisionRound(tmcDirectionalAadt)} vehicles`}
      </p>
      <p style={{ width: '62%' }}>
        <b>Average Number of Vehicles per Hour:</b>
        {`\u00a0${precisionRound(tmcHourlyAvgTrafficVolume)} vehicles`}
      </p>
      <p style={{ width: '62%' }}>
        The following chart shows the result of multiplying the deviations from
        freeflow calculated in the previous step by the average number of
        vehicles per hour:
      </p>
      <div style={{ width: '100%', color: '#333' }}>{getViz(data)}</div>
    </div>
  ) : null;
};

export default ATRIHourlyCongestionLineChart;
