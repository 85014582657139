// https://redux.js.org/recipes/structuring-reducers/beyond-combinereducers#sharing-data-between-slice-reducers
// https://github.com/redux-utilities/reduce-reducers
// https://stackoverflow.com/questions/38652789/correct-usage-of-reduce-reducers/44371190#44371190
import pick from 'lodash.pick';

import {
  geomeasureVisualizer,
  dataSelection,
  userInteraction,
  controlledComponents
} from './registry';

import dataSelectionReducer from './dataSelection';
import userInteractionReducer from './userInteraction';
import controlledComponentsReducer from './controlledComponents';

const _ = { pick };

// NOTE: Here, state is the geomeasureVisualizer Redux Store slice
const handleSuperiorLayerStateUpdate = (state = {}, action) => {
  const {
    [dataSelection]: dataSelectionState,
    [userInteraction]: userInteractionState
  } = state;

  const newDataSelectionState = dataSelectionReducer(
    dataSelectionState,
    action
  );

  const newUserInteractionState = userInteractionReducer(
    userInteractionState,
    action
  );

  const stateChanged =
    dataSelectionState !== newDataSelectionState ||
    userInteractionState !== newUserInteractionState;

  return stateChanged
    ? Object.assign({}, state, {
        [dataSelection]: newDataSelectionState,
        [userInteraction]: newUserInteractionState
      })
    : state;
};

const handleSubordinateLayerStateUpdate = (
  state = {},
  action,
  intermediateState
) => {
  const { [controlledComponents]: controlledComponentsState } = state;

  const context = {
    state: state
      ? {
          [geomeasureVisualizer]: _.pick(state, [
            dataSelection,
            userInteraction
          ])
        }
      : undefined,
    nextState: {
      [geomeasureVisualizer]: _.pick(intermediateState, [
        dataSelection,
        userInteraction
      ])
    }
  };

  const newControlledComponentsState = controlledComponentsReducer(
    controlledComponentsState,
    action,
    context
  );

  return controlledComponentsState !== newControlledComponentsState
    ? Object.assign({}, intermediateState, {
        [controlledComponents]: newControlledComponentsState
      })
    : intermediateState;
};

export default (state, action) => {
  const intermediateState = handleSuperiorLayerStateUpdate(state, action);

  const finalState = handleSubordinateLayerStateUpdate(
    state,
    action,
    intermediateState
  );

  return finalState;
};
