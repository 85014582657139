import React from 'react';

import Select from 'react-select';

const YearSelector = props => {
  const { year, years, onChange } = props;

  if (!years) {
    return <span>Loading</span>;
  }

  return (
    <Select
      value={
        year
          ? {
              value: +year,
              label: year
            }
          : undefined
      }
      isMulti={false}
      options={years.map(y => ({
        value: +y,
        label: +y
      }))}
      onChange={({ value }) => onChange(value)}
    />
  );
};

export default YearSelector;
