// https://iandevlin.com/blog/2012/02/html5/marking-up-a-bibliography-with-html5/
// http://html5doctor.com/cite-and-blockquote-reloaded/

import React from 'react';

const ATRIBibliography = () => (
  <ol>
    <li>
      <cite>
        <a
          href="http://atri-online.org/wp-content/uploads/2018/01/ATRI-2018-Truck-Bottleneck-Methodology-2018.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          ATRI 2018 Truck Bottleneck Methodology
        </a>
      </cite>
    </li>
  </ol>
);

export default ATRIBibliography;
