import React from "react"

import classnames from "classnames"
import deepequal from "deep-equal"
import get from "lodash.get"
import styled from "styled-components"

import { Input } from "components/common/styled-components"
import ItemSelector from 'components/common/item-selector/item-selector';

import {
	Label,
	ControlContainer,
  DropDown
} from "pages/auth/Routes/analysis/components/Sidebar/components/parts"

import {
  HamburgerSelector,
  Tab
} from "components/AvlStuff/HamburgerSelector"

const WEEKDAYS = [
	{ day: "1", key: "Sn", name: "Sunday" },
	{ day: "2", key: "Mn", name: "Monnday" },
	{ day: "3", key: "Tu", name: "Tuesday" },
	{ day: "4", key: "Wd", name: "Wednesday" },
	{ day: "5", key: "Th", name: "Thursday" },
	{ day: "6", key: "Fr", name: "Friday" },
	{ day: "7", key: "St", name: "Saturday" }
]
const hourFormat = hour => {
  const amPm = hour >= 12 ? "PM" : "AM";
  hour = hour > 12 ? hour - 12 : hour == 0 ? 12 : hour;
  return `${ hour }:00 ${ amPm }`;
}
const dowFormat = wd =>
  WEEKDAYS.reduce((a, c) => c.day == wd ? c.name : a, "")
const MONTHS = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
}
const monthFormat = m => {
  const year = m.slice(0, 4),
    month = +m.slice(5)
  return `${ get(MONTHS, month, "").slice(0, 3) }. ${ year }`;
}
export const RESOLUTIONS = [
  { name: "Hour", value: "hour", format: hourFormat },
  { name: "Date", value: "date", format: d => d },
  { name: "Day of Week", value: "dow", format: dowFormat },
  { name: "Month", value: "month", format: monthFormat },
  { name: "Year", value: "year", format: d => d }
]
const DATA_TYPES = [
  { value: "class",
    name: "Vehicle Classification"},
  { value: "volume",
    name: "Vehicle Volume"},
]

const Container = styled.div`
  transition: width 0.25s;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  &.closed {
    border-right: 2px solid ${ props => props.theme.sidePanelHeaderBg };
    border-left: 2px solid ${ props => props.theme.sidePanelHeaderBg };
  }
`
const StyledToggleButton = styled.div`
  position: absolute;
  z-index: 50;
  top: 0px;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s;
  border-radius: 3px;
  &.closed {
    transform: rotate(180deg);
  }
  > div {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: #900;
    transition: all 0.15s;
    opacity: 0.85;
    z-index: 0;
  }
  &.closed > div {
    background-color: #090;
  }
  :hover > div {
    opacity: 1.0;
  }
  > span {
    z-index: 5;
    color: ${ props => props.theme.textColorHl };
    opacity: 0.85;
  }
  :hover > span {
    transition: all 0.15s;
    opacity: 1.0;
  }
`
const ToggleButton = ({ ...props }) =>
  <StyledToggleButton { ...props }>
    <div />
    <span style={ { marginTop: "2px" } } className="fa fa-chevron-left"/>
  </StyledToggleButton>

const StyledStation = styled.div`
  position: relative;
  top: 0px;
  padding: 2px 10px 1px 10px;
  border-radius: 4px;
  transition: all 0.15s;
  :hover {
    background-color: ${ props => props.theme.panelBackgroundHover };
  }
  width: calc(100% + 10px);
  margin: 2px 0px 2px -5px;
  :first-child {
    margin-top: 4px;
  }
  > div {
    opacity: 0.0;
    transition: all 0.15s;
    cursor: pointer;
  }
  :hover > div {
    opacity: 0.8;
  }
  > div:hover {
    opacity: 1.0;
  }
`
const Station = ({ remove, station }) =>
  <StyledStation>
    { station.properties.muni } ({ station.properties.stationId })
    <div onClick={ remove }
      style={ {
        width: "19px",
        height: "19px",
        position: "absolute",
        right: "2px",
        top: "2px",
        backgroundColor: "#900",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      } }>
        <span className="fa fa-lg fa-minus"/>
      </div>
  </StyledStation>

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 5px;
`
export const InputBox = styled.div`
	flex: 0 0 70%;
	max-width: 70%;
`

class Sidebar extends React.Component {
  updateWeekdays(dow) {
    const set = new Set(this.props.dataRequest.weekdays);
    if (set.has(dow)) {
      set.delete(dow);
    }
    else {
      set.add(dow);
    }
    this.props.updateRequest({ weekdays: [...set].sort((a, b) => +a - +b) });
  }
  render() {
    const {
        open,
        transitioning,
        width,
        dataRequest,
        prevRequest,
        updateRequest,
        sendRequest } = this.props,
      {
        startDate,
        endDate,
        startTime,
        endTime,
        weekdays,
        resolution,
        dataType,
      } = dataRequest;
    const stationMap = this.props.stations
      .reduce((a, c) =>
        ({ ...a, [c.properties.stationId]: true })
      , {})
    const availableStations = this.props.allStations
      .filter(s => !stationMap[s.properties.stationId])
      .sort((a, b) => {
        const aMuni = a.properties.muni,
          bMuni = b.properties.muni;
        if (aMuni === bMuni) {
          const aId = a.properties.stationId,
            bId = b.properties.stationId;
          return +aId - +bId;
        }
        return aMuni < bMuni ? -1 : 1;
      })
    return (
      <Container style={ { width: `${ open ? width : 0 }px`} }
        className={ classnames({ closed: !(open || transitioning) }) }>
        <ToggleButton onClick={ e => this.props.toggle() }
          className={ classnames({ closed: !open }) }/>
        <div style={ {
          width: "100%",
          height: "100%",
          paddingTop: "10px",
          overflow: open && !transitioning ? "auto" : "hidden"
        } }>
          <div style={ {
            width: `${ width }px`,
            height: "100%"
          } }>
            <HamburgerSelector>
              <Tab label="Stations">
                <div style={ { marginTop: "5px" } }>
      						<DropDown
      	  					searchable={ true }
      	  					displayOption={ d => `${ d.properties.muni } (${ d.properties.stationId })` }
      	  					getOptionValue={ d => d }
      							onChange={ d => this.props.toggleStation(d) }
      							options={ availableStations }>
      							<span className="os-icon os-icon-git-commit"/>
      							<span>Available Stations</span>
      						</DropDown>
                </div>
                <div>
                  { this.props.stations.map(s =>
                      <Station key={ s.properties.stationId } station={ s }
                        remove={ e => this.props.toggleStation(s) }/>
                    )
                  }
                </div>
              </Tab>
              <Tab label="Settings">
                <div style={ { marginTop: "5px", position: "relative", height: "calc(100% - 5px)" } }>
          				<Row>
          					<Label>Start Date</Label>
          					<InputBox>
          						<Input type="date"
          							onChange={ e => updateRequest({ startDate: e.target.value }) }
          							value={ startDate }/>
          					</InputBox>
          				</Row>
          				<Row>
          					<Label>End Date</Label>
          					<InputBox>
          						<Input type="date"
          							onChange={ e => updateRequest({ endDate: e.target.value }) }
          							value={ endDate }/>
          					</InputBox>
          				</Row>

          				<Row>
          					<Label>Start Time</Label>
          					<InputBox>
          						<Input type="time"
          							onChange={ e => updateRequest({ startTime: e.target.value }) }
          							value={ startTime }/>
          					</InputBox>
          				</Row>
          				<Row>
          					<Label>End Time</Label>
          					<InputBox>
          						<Input type="time"
          							onChange={ e => updateRequest({ endTime: e.target.value }) }
          							value={ endTime }/>
          					</InputBox>
          				</Row>

          				<Row>
          					<div style={ { width: "100%" } }>
          						Weekday Selector
          					</div>
          					<div className="btn-group" style={ { width: "100%", display: "flex", justifyContent: "center" } }>
          						{
          							WEEKDAYS.map(({ day, key }) =>
          								<button key={ day } style={ { flex: `0 0 ${ 100 / 7 }%` } }
          									className={ `btn btn-sm ${ weekdays.includes(day) ? 'btn-success' : 'btn-danger' }` }
          									onClick={ e => this.updateWeekdays(day) }>
          									{ key }
          								</button>
          							)
          						}
          					</div>
          				</Row>

          				<Row>
          					<Label>Resolution</Label>
          					<InputBox>
          						<ItemSelector
          							selectedItems={ RESOLUTIONS.filter(({ value }) => value === resolution) }
              					multiSelect={ false }
              					searchable={ false }
              					displayOption={ d => d.name }
              					getOptionValue={ d => d }
          							onChange={ v => updateRequest({ resolution: v.value })}
          							options={ RESOLUTIONS }/>
          					</InputBox>
          				</Row>

          				<Row>
          					<Label>Data Type</Label>
          					<InputBox>
          						<ItemSelector
          							selectedItems={ DATA_TYPES.filter(({ value }) => value === dataType) }
              					multiSelect={ false }
              					searchable={ false }
              					displayOption={ d => d.name }
              					getOptionValue={ d => d }
          							onChange={ v => updateRequest({ dataType: v.value }) }
          							options={ DATA_TYPES }/>
          					</InputBox>
          				</Row>

                  <Row>
                    <button className="btn btn-block btn-outline-primary"
                      disabled={ deepequal(dataRequest, prevRequest) }
                      onClick={ e => sendRequest() }>
                      Send Request
                    </button>
                  </Row>

                </div>

              </Tab>
              { /*
                <Tab label="Graphs">
                  <div>Graph 1</div>
                  <div>Graph 2</div>
                  <div>Graph 3</div>
                  <div>Graph 4</div>
                </Tab>
                <Tab label="Label 4">
                  <div>STUFF 1</div>
                  <div>STUFF 2</div>
                  <div>STUFF 3</div>
                  <div>STUFF 4</div>
                </Tab>
              */ }
            </HamburgerSelector>
          </div>
        </div>
      </Container>
    )
  }
}

export default Sidebar;
