import React from 'react';

import ReactTable from 'react-table';

// import Table from 'components/avl-components/components/Table'
import { Table } from "@availabs/avl-components"

import { STATE, DEFAULT_GEOLEVEL, DEFAULT_MEASURE } from '../constants';
import get from 'lodash.get'




export const measureAccessors = ['lottr_noninterstate', 'lottr_noninterstate', 'tttr_interstate', 'phed']

const Pm3MeasuresTable = ({
  selectedPm3VersionId: pm3VersionId,
  geolevel,
  data,
  updatePm3ViewUrl,
  navigateToGeoMeasureVisualizer
}) => {
  const tableCols = [
  {
    Header: () => (<div style={{fontSize: 10}}>Name</div>),
    accessor: 'geoname',
    Cell: (data) => {
      return (
        <div
          onClick={() => {
            const {
              original: { geoInfo }
            } = data.row;
            return geolevel === STATE
            ? updatePm3ViewUrl({
                stateCode: geoInfo.geoid,
                geolevel: DEFAULT_GEOLEVEL
              })
            : navigateToGeoMeasureVisualizer({
                geolevel,
                geoid: geoInfo && geoInfo.geoid,
                pm3VersionId,
                DEFAULT_MEASURE
              });
            }}
          style={{cursor: 'pointer'}}>
          {data.row.values.geoname}
        </div>
      )
     }
  },
  {
    Header: () => (<div style={{fontSize: 8,textAlign: 'right'}}>Interstate Miles</div>),
    accessor: 'interstate_miles',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{get(data, 'row.values.interstate_miles', '').toLocaleString()}</div>
     }
  },
  {
    Header: () => (<div style={{fontSize: 8,textAlign: 'right'}}>Interstate TMCs</div>),
    accessor: 'interstate_tmcs',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{data.row.values.interstate_tmcs}</div>
     }

  },
  {
    Header: () => (<div style={{fontSize: 8,textAlign: 'right'}}>Non-Interstate Miles</div>),
    accessor: 'noninterstate_miles',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{data.row.values.noninterstate_miles}</div>
     }
  },
  {
    Header: () => (<div style={{fontSize: 8, textAlign: 'right'}}>Non-Interstate TMCs</div>),
    accessor: 'noninterstate_tmcs',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{data.row.values.noninterstate_tmcs}</div>
     }
  },
  {
    Header: () => (<div style={{fontSize: 8,textAlign: 'right'}}>Interstate LOTTR</div>),
    accessor: 'lottr_interstate',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{data.row.values.lottr_interstate}</div>
     }
  },
  {
    Header: () => (<div style={{fontSize: 8,textAlign: 'right'}}>Non-Interstate TTTR</div>),
    accessor: 'lottr_noninterstate',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{data.row.values.lottr_noninterstate}</div>
     }
  },
  {
    Header: () => (<div style={{fontSize: 8, textAlign: 'right'}}>Interstate TTTR</div>),
    accessor: 'tttr_interstate',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{data.row.values.tttr_interstate}</div>
     }
  },
  {
    Header: () => (<div style={{fontSize: 8, textAlign: 'right'}}>PHED (person hours)</div>),
    accessor: 'phed',
    disableFilters: true,
    Cell: (data) => {
      return <div style = {{ textAlign: 'right'}}>{get(data,'row.values.phed', '').toLocaleString()}</div>
     }
  }
];

  return (
    <Table
      data={data}
      columns={tableCols}
      sortBy='geoname'
      initialPageSize={ data.length }
    />
  );
}
export default Pm3MeasuresTable;
