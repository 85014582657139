import React from "react"

import GeneralGraphComp, {getRequestKey} from "components/tmc_graphs/graphClasses/GeneralGraphComp"
import get from "lodash.get"

import _ from 'lodash'
import * as d3array from "d3-array"
import {getResolutionFormat, getResolutionLabel, getResolutionName} from "components/tmc_graphs/utils/resolutionFormats"

import {disaggregateAADT, getDist2} from "pages/auth/NAT/analysis/disaggregateAADT"

import TmcGridGraph from "./TmcGridGraph_2_0"

import {register} from "components/tmc_graphs/utils/DomainManager"

import COLOR_RANGES from "constants/color-ranges"
import {INDICES, INDICES_BY_DATE_RANGE, TMC_ATTRIBUTES} from "../../../../components/tmc_graphs/utils/dataTypes";
import * as d3format from "d3-format";
import {falcorGraph} from "../../../../store/falcorGraph";

const COLOR_RANGE = COLOR_RANGES[5].reduce((a, c) => c.name === "RdYlGn" ? c.colors : a).slice();

const getResolutionNumber = resolution => {
    switch (resolution) {
        case "hour":
            return 12;
        case "15-minutes":
            return 3;
        default:
            return 1;
    }
};

class Graph extends GeneralGraphComp {
    constructor(props) {
        super(props);
        this.state = {
            yearlySettingRoute: {},
            dailyRoute: {},
            currentTMCs: [],
            TMCToIdMapping: this.props.TMCToIdMapping,
            congestionEpochs: {},
            displayData: 'travelTime',
            EpochDelayData: {} // tmc: delay, VDelay for congested epochs
        }
    }

    fetchFalcorDeps() {
        const routes = this.getActiveRouteComponents().filter(r => get(r, 'tmcArray.length', 0));
        if (!routes.length) return Promise.resolve();
        if (this.state.currentTMCs.length > 0) {
            routes[0].tmcArray = this.state.currentTMCs;
        } else {
            this.setState({currentTMCs: routes[0].tmcArray})
        }
        let yearlySettingRoute = _.cloneDeep(routes[0]);
        yearlySettingRoute.settings.startDate = yearlySettingRoute.settings.yearlyStartDate;
        yearlySettingRoute.settings.endDate = yearlySettingRoute.settings.yearlyEndDate;
        yearlySettingRoute.settings.weekdays = {[yearlySettingRoute.settings.yearlyDay]: true};
        routes.push(yearlySettingRoute);
        this.setState({yearlySettingRoute: yearlySettingRoute, dailyRoute: routes[0]});

        const displayData = this.getDisplayData();
        console.log('display data', displayData);
        if (!displayData.length) return Promise.resolve();
        this.setState({loading: true});

        return routes.reduce((promise, route) => {
            const {tmcArray} = route,
                year = this.getMaxYear(route),
                requestKeys = displayData.map(dd => ({
                    dd: dd.key,
                    alias: dd.alias,
                    group: dd.group,
                    key: getRequestKey(route, dd)
                }))
                    .filter(({key}) => Boolean(key));
            return promise.then(() => {
                return this.props.falcor.get(
                    ['tmc', tmcArray, 'meta', year, ['miles', 'aadt', 'bounding_box', 'avg_speedlimit', 'congestion_level', 'directionality', 'f_system', 'tmclinear', 'avg_vehicle_occupancy']]
                )
                    .then(() => {
                        return requestKeys.reduce((promise, {dd, alias, group, key}) => {
                            return promise.then(() => {
                                return this.props.falcor.get(
                                    ['routes', 'data', [key]]
                                )
                                    .then(res => {
                                        console.log(key, get(res, `json.routes.data`, []));
                                        if (group === "indices") {
                                            INDICES.forEach(index => {
                                                route.data[index.key] = get(res, `json.routes.data.${key}`, [])
                                                    .map(d => ({
                                                        tmc: d.tmc,
                                                        resolution: d.resolution,
                                                        value: d[index.key]
                                                    }))
                                                    .filter(({value}) => value !== undefined)
                                            })
                                        } else if (group === "indices-byDateRange") {
                                            route.data["byDateRange"] = {};
                                            INDICES_BY_DATE_RANGE.forEach(index => {
                                                route.data.byDateRange[index.key] = get(res, `json.routes.data.${key}`, [])
                                                    .map(d => ({tmc: d.tmc, value: d[index.key]}))
                                                    .filter(({value}) => value !== undefined)
                                            })
                                        } else {
                                            route.data[dd] = get(res, `json.routes.data.${key}`, []);
                                            /*if (alias) {
                                                route.data[alias] = get(res, `json.routes.data.${key}`, []);
                                            }*/
                                        }
                                    })
                            })
                        }, Promise.resolve())
                    })
            })
        }, Promise.resolve())
            .then(() => {
                // get disagg AADT

                let resolution = "5-minutes";

                if (this.state.yearlySettingRoute) {
                    let data = this.generateGraphData([this.state.yearlySettingRoute], displayData, resolution);
                    this.setState({yearlySettingRouteData: data})
                }
                if (this.state.dailyRoute) {
                    let data = this.generateGraphData([this.state.dailyRoute], displayData, resolution);
                    this.setState({dailyRouteData: data})
                }
                // get disagg AADT
                const tmcgraph = falcorGraph.getCache(),
                    overrideAADT = get(this.state.dailyRoute, ["settings", "overrides", "aadt"], false),
                    resNum = getResolutionNumber(resolution),
                    AADT = TMC_ATTRIBUTES.filter(ta => ta.key === 'aadt').pop(),
                    year = this.getMaxYear(this.state.dailyRoute),
                    tmcArray = this.state.dailyRoute.tmcArray;

                const dataDisaggAADT = tmcArray.reduce((data, tmc) => {
                    const graph = get(tmcgraph, `tmc.${tmc}.meta.${year}`, {}),
                        aadt = overrideAADT || get(graph, `aadt`, 0),
                        dist = getDist2(graph, tmc),
                        disagg = disaggregateAADT(aadt, dist, 7, resNum * 5),
                        length = this.getTmcLength(year, tmc);
                    return [...data, ...disagg.map((v, i) => ({tmc, value: AADT.transform(v, length), resolution: i}))]
                }, [])
                    .filter(({resolution}) => (resolution >= 0) && (resolution < 288));

                // get diff here only
                const formatP = d3format.format("+.2%");
                let diffData = [],
                    diffMin, diffMax;
                let congestionEpochs = {}, tolerance = 2;
                let delayData = {};
                if (this.state.yearlySettingRouteData) {
                    this.state.dailyRouteData.forEach(gData => {
                        let tmpObj = {}, tmpTolarence = tolerance;
                        let yData = this.state.yearlySettingRouteData.filter(f => f.tmc === gData.tmc)[0];
                        if (yData) {
                            tmpObj.tmc = gData.tmc;
                            tmpObj.length = gData.length;
                            tmpObj.data = [];
                            gData.data.forEach((gd, gdI) => {
                                let diff = (gd.value - yData.data.filter(f => f.resolution === gd.resolution)[0].value) /
                                    yData.data.filter(f => f.resolution === gd.resolution)[0].value;

                                let diffTT = (yData.data.filter(f => f.resolution === gd.resolution)[0].valueTT - gd.valueTT);

                                if (diff < -0.2) {
                                    congestionEpochs[gData.tmc] ?
                                        congestionEpochs[gData.tmc].push(gd.resolution) :
                                        congestionEpochs[gData.tmc] = [gd.resolution]
                                } else {
                                    if (tmpTolarence > 0 && congestionEpochs[gData.tmc] && congestionEpochs[gData.tmc].includes(gd.resolution - 1)
                                    ) {
                                        let futureDiff, i = tmpTolarence;
                                        while (i > 0) {
                                            let currFuture = gData.data.filter(f => f.resolution === gd.resolution + i);
                                            if (
                                                // get current resolution + i from gData, store all the diffs
                                                currFuture.length > 0 &&
                                                (currFuture[0].value - yData.data.filter(f => f.resolution === currFuture[0].resolution)[0].value) /
                                                yData.data.filter(f => f.resolution === currFuture[0].resolution)[0].value < -0.2
                                            ) {
                                                congestionEpochs[gData.tmc].push(gd.resolution);
                                                tmpTolarence -= 1;
                                                if (tmpTolarence === 0) tmpTolarence = tolerance; // resetting for future congestion in this tmc
                                                i = 0;
                                            }
                                            i--;
                                        }
                                    }
                                }

                                // get vehicle delay
                                tmpObj.data.push(
                                    {
                                        tmc: gd.tmc,
                                        resolution: gd.resolution,
                                        difference: Math.abs(gd.value - yData.data.filter(f => f.resolution === gd.resolution)[0].value),
                                        valueP: formatP(diff),
                                        value: diff * 100,
                                        valueTT: diffTT,
                                        vDelay: diffTT * dataDisaggAADT.filter(f => f.tmc === gd.tmc && f.resolution === gd.resolution).pop().value
                                    }
                                );
                            });
                            diffData.push(tmpObj)
                        }

                    })
                }

                // correct congestionEpochs to be contiguous
                if (get(this.props.incidents, `[0].incidentEpoch`, null)) {
                    Object.keys(congestionEpochs)
                        .forEach(tmc => {
                            let blanks = [];
                            _.range(0, 288 + 1)
                                .forEach(inBetRes => {
                                    if (diffData.filter(f => f.tmc === tmc)[0].data.filter(f => f.resolution === inBetRes).length === 0) {
                                        blanks.push(inBetRes)
                                    }
                                });
                            congestionEpochs[tmc] =
                                congestionEpochs[tmc]
                                    .filter((res, resI) => {
                                        let tmpDiff = Math.abs(Math.abs(res - get(this.props.incidents, `[0].incidentEpoch`, null)) -
                                            Math.abs(resI - congestionEpochs[tmc].indexOf(get(this.props.incidents, `[0].incidentEpoch`, null))));

                                        return tmpDiff === 0 || tmpDiff === _.intersection(blanks, _.range(res, get(this.props.incidents, `[0].incidentEpoch`, null) + 1)).length
                                    });

                            // use corrected data to sum delay
                            delayData[tmc] = {delay: 0, vDelay: 0};
                            congestionEpochs[tmc].forEach(epoch => {
                                let tmpDiffData = diffData
                                    .filter(f => f.tmc === tmc).pop().data
                                    .filter(f => f.resolution === epoch).pop();
                                delayData[tmc]['delay'] += tmpDiffData.valueTT;
                                delayData[tmc]['vDelay'] += tmpDiffData.vDelay;
                            })

                        })
                }

                if (diffData.length > 0) {
                    [diffMin, diffMax] = register(this.props.type,
                        displayData,
                        resolution,
                        this.props.id,
                        diffData.reduce((a, c) => [...a, ...c.data.map(({value}) => value)], []));
                    this.setState({
                        congestionEpochs: congestionEpochs,
                        diffData: diffData,
                        EpochDelayData: delayData,
                        diffMax: diffMax,
                        diffMin: diffMin
                    });
                    this.setState({loading: false});
                }
                // got congestion data; now get child if there is congestion in the last TMC

                /*                if (this.state.dailyRoute.tmcArray &&
                                    congestionEpochs[this.state.dailyRoute.tmcArray.slice(-1).pop()] &&
                                    congestionEpochs[this.state.dailyRoute.tmcArray.slice(-1).pop()].length > 1 &&
                                    this.state.currentTMCs.length < 10
                                ){

                                    let newId = this.state.TMCToIdMapping[this.state.dailyRoute.tmcArray.slice(-1).pop()],
                                        amount = 1;
                                    return this.props.falcor.get(
                                        ["conflation","getTmcChain","forId", newId, "downstream", amount]
                                    ).then(newChildRes =>{
                                        let downstream = get(newChildRes, `json.conflation.getTmcChain.forId.${newId}.downstream.${amount}`, []);
                                        if (downstream && downstream.length === 1 &&
                                            get(downstream, `[0][0].tmc19id`, null)
                                        ){
                                            this.setState({
                                                currentTMCs: [...this.state.dailyRoute.tmcArray, get(downstream, `[0][0].tmc19id`, null)],
                                                TMCToIdMapping: Object.assign(this.state.TMCToIdMapping,
                                                    {[get(downstream, `[0][0].tmc19id`, null)]: get(downstream, `[0][0].id`, null)})
                                            });
                                        }else{
                                            // if you get multiple children, pick the one with same tmclinear as the last one.
                                            let allSegments = downstream.reduce( (a,c) => {a.push(...c); return a}, []);
                                            let allTMCIds = allSegments.map(segment => segment.tmc19id);
                                            return this.props.falcor.get(
                                                ['tmc', allTMCIds, 'meta', year, ['tmclinear']]
                                            ).then(tmcLinearRes => {
                                                Object.keys(get(tmcLinearRes, `json.tmc`, {}))
                                                    .forEach(tmcId => {
                                                        if (get(tmcLinearRes, `json.tmc.${tmcId}.meta.${year}.tmclinear`, null) ===
                                                            get(tmcgraph, `tmc.${ this.state.currentTMCs[this.state.currentTMCs.length - 1] }.meta.${ year }.tmclinear`, {})
                                                        ){
                                                            this.setState({
                                                                currentTMCs: [...this.state.dailyRoute.tmcArray, tmcId],
                                                                TMCToIdMapping: Object.assign(this.state.TMCToIdMapping,
                                                                    {[tmcId]: allSegments.filter(s => s.tmc19id === tmcId).pop().id})
                                                            });
                                                        }
                                                    })
                                            })
                                        }
                                        this.setState({loading: false});
                                    })
                                }else{
                                    this.setState({loading: false});
                                }*/
            })
    }

    generateHeaderData(graphData, [route], [displayData], resolution) {
        return [
            {type: "single-select-route"},
            {type: "single-select-data"}
        ];
    }

    generateGraphData([route], [displayData], resolution) {
        displayData = this.getDisplayData();

        const year = this.getMaxYear(route),
            {key, transform} = displayData[0],
            keyTT = displayData[1].key,
            transformTT = displayData[1].transform,
            tmcArray = route.tmcArray,
            routeData = get(route, `data.${key}`, []),
            routeDataTT = get(route, `data.${keyTT}`, []),
            grouped = d3array.group(routeData, d => d.tmc),
            groupedTT = d3array.group(routeDataTT, d => d.tmc);

        return tmcArray.reduce((a, tmc) => {
            const length = this.getTmcLength(year, tmc),
                data = grouped.get(tmc) || [],
                dataTT = groupedTT.get(tmc) || [];

            if (length) {
                a.push({
                    tmc,
                    length,
                    data: data.map((d, dI) => ({
                        ...d,
                        value: transform(d.value, length),
                        valueTT: transformTT(dataTT[dI].value, length)
                    }))
                })
            }
            return a;
        }, []);
    }

    hoverTmc(tmc) {
        this.props.setHighlightedTmcs(tmc ? [tmc] : [])
    }

    generateTableData(graphData, [route], [displayData], resolution) {
        const data = graphData.reduce((a, c) => {
            const base = {
                "TMC": c.tmc,
                "Length": c.length,
                "Resolution Type": getResolutionName(resolution),
                "Data Type": displayData.name,
                "Route Name": route.name
            };
            c.data.forEach(d => {
                a.push({
                    ...base,
                    "Value": d.value,
                    "Resolution": d.resolution
                })
            });
            return a;
        }, []);
        return {data, keys: ["Route Name", "TMC", "Length", "Data Type", "Value", "Resolution Type", "Resolution"]};
    }

    minutesToHoursAndMinutes(v) {
        const isNegative = v < 0;
        v = Math.abs(v / 60);
        const hours = Math.trunc(v),
            minutes = v - hours;
        return `${isNegative ? "-" : ""}${hours}:${`0${Math.round(minutes * 60)}`.slice(-2)}`;
    }

    formatValue(value){
        return  Math.abs(value.split(':')[0]) > 60 ?
            this.minutesToHoursAndMinutes(value.split(':')[0]) + ' Hours' : value + ' Minutes'
    }
    renderGraph(graphData, [route], [displayData], resolution) {
        // if props say year avg, use your own data instead of graphData
        if (!this.state.dailyRouteData) return null;
        displayData = this.getDisplayData();
        const {
            name,
            label,
            reverseColors,
            format
        } = displayData[0];
        const TTFormat = displayData[1].format;
        const TTLabel = displayData[1].label;

        const resFormat = getResolutionFormat(resolution),
            resLabel = getResolutionLabel(resolution);

        const [min, max] = register(this.props.type,
            displayData,
            resolution,
            this.props.id,
            this.state.dailyRouteData.reduce((a, c) => [...a, ...c.data.map(({value}) => value)], []));
        let totalDelay, totalVDlay, totalPersonDelay,
            year = this.getMaxYear(this.state.dailyRoute),
            graph = falcorGraph.getCache();
        if (Object.keys(this.state.EpochDelayData).length > 0) {
            totalDelay = Object.keys(this.state.EpochDelayData).reduce((a, c) => a + this.state.EpochDelayData[c]['delay'], 0);
            totalVDlay = Object.keys(this.state.EpochDelayData).reduce((a, c) => a + this.state.EpochDelayData[c]['vDelay'], 0);
            totalPersonDelay = Object.keys(this.state.EpochDelayData).reduce((a, c) =>
                a + (this.state.EpochDelayData[c]['vDelay']*get(graph, `tmc.${c}.meta.${year}.avg_vehicle_occupancy`, null)), 0);
        }

        return (
            <React.Fragment>
                <h4>TMCs {this.state.currentTMCs.join(', ')}</h4>
                <div className="comp-title">{this.props.title} - Incident Day</div>
                <TmcGridGraph data={this.state.dailyRouteData}
                              incidents={this.props.incidents}
                              highlightedTmcs={this.props.highlightedTmcs}
                              hoverTmc={tmc => this.hoverTmc(tmc)}
                              colorScale={{
                                  domain: [min, max],
                                  range: reverseColors ? COLOR_RANGE.slice().reverse() : COLOR_RANGE.slice()
                              }}
                              resolution={resolution}
                              colorRange={reverseColors ? COLOR_RANGE.slice().reverse() : COLOR_RANGE.slice()}
                              returnHeight={(h) => {
                                  this.setState({height1: h});
                                  this.props.setHeight([h, this.state.height2, this.state.height3]);
                              }}
                              hoverData={
                                  d => [
                                      ["TMC", d.tmc],
                                      [name, `${format(d.value)} ${label}`],
                                      ['TT', `${TTFormat(d.valueTT)} ${TTLabel}`],
                                      [resLabel, resFormat(d.resolution)]
                                  ]
                              }
                />
                {this.state.yearlySettingRouteData ?
                    <React.Fragment>
                        <div className="comp-title">{this.props.title} - Yearly Average</div>
                        <TmcGridGraph data={this.state.yearlySettingRouteData}
                                      incidents={this.props.incidents}
                                      highlightedTmcs={this.props.highlightedTmcs}
                                      hoverTmc={tmc => this.hoverTmc(tmc)}
                                      colorScale={{
                                          domain: [min, max],
                                          range: reverseColors ? COLOR_RANGE.slice().reverse() : COLOR_RANGE.slice()
                                      }}
                                      resolution={resolution}
                                      colorRange={reverseColors ? COLOR_RANGE.slice().reverse() : COLOR_RANGE.slice()}
                                      returnHeight={(h) => {
                                          this.setState({height2: h});
                                          this.props.setHeight([this.state.height1, h, this.state.height3]);
                                      }}
                                      hoverData={
                                          d => [
                                              ["TMC", d.tmc],
                                              [name, `${format(d.value)} ${label}`],
                                              ['TT', `${TTFormat(d.valueTT)} ${TTLabel}`],
                                              [resLabel, resFormat(d.resolution)]
                                          ]
                                      }
                        />
                    </React.Fragment>
                    : null}

                {this.state.diffData ?
                    <React.Fragment>
                        <div className="comp-title">{this.props.title} - Difference</div>
                        <TmcGridGraph data={this.state.diffData}
                                      incidents={this.props.incidents}
                                      congestionEpochs={this.state.congestionEpochs}
                                      highlightedTmcs={this.props.highlightedTmcs}
                                      hoverTmc={tmc => this.hoverTmc(tmc)}
                                      colorScale={{
                                          domain: [this.state.diffMin, 0, this.state.diffMax],
                                          range: reverseColors ? COLOR_RANGE.slice().reverse() : COLOR_RANGE.slice()
                                      }}
                                      resolution={resolution}
                                      colorRange={reverseColors ? COLOR_RANGE.slice().reverse() : COLOR_RANGE.slice()}
                                      returnHeight={(h) => {
                                          this.setState({height3: h});
                                          this.props.setHeight([this.state.height1, this.state.height2, h]);
                                      }}
                                      hoverData={
                                          d => [
                                              ["TMC", d.tmc],
                                              ['Percent', `${d.valueP}`],
                                              ['Diff TT', `${TTFormat(d.valueTT)} ${TTLabel}`],
                                              ['VDlay', `${TTFormat(d.vDelay)} ${TTLabel}`],
                                              [resLabel, resFormat(d.resolution)]
                                          ]
                                      }
                        />
                    </React.Fragment>
                    : null}

                {this.state.EpochDelayData && Object.keys(this.state.EpochDelayData).length > 0 ?
                    (<div className='table-responsive'>
                        <table className='table table-lightborder'>
                            <thead>
                            <tr>
                                <th> TMC</th>
                                <th> DELAY</th>
                                <th> VEHICLE DELAY</th>
                                <th> PERSON DELAY</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.state.EpochDelayData)
                                .map(tmc => {

                                        return (<tr>
                                            <td> {tmc} </td>
                                            <td> {this.formatValue(TTFormat(this.state.EpochDelayData[tmc]['delay']))} </td>
                                            <td> {this.formatValue(TTFormat(this.state.EpochDelayData[tmc]['vDelay']))} </td>
                                            <td> {this.formatValue(
                                                TTFormat(this.state.EpochDelayData[tmc]['vDelay'] *
                                                    get(graph, `tmc.${tmc}.meta.${year}.avg_vehicle_occupancy`, null))
                                            )} </td>
                                        </tr>)
                                    }
                                )}
                            <tr>
                                <td> Total</td>
                                <td> {this.formatValue(TTFormat(totalDelay))} </td>
                                <td> {this.formatValue(TTFormat(totalVDlay))} </td>
                                <td> {this.formatValue(TTFormat(totalPersonDelay))} </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>) : null
                }
            </React.Fragment>
        )
    }
}

Graph.defaultProps = {
    colorRange: COLOR_RANGE
};
export default GeneralGraphComp.connect(Graph)
