import React from "react"
// import { connect } from "react-redux"
// import { reduxFalcor, UPDATE as REDUX_UPDATE } from "utils/redux-falcor"

import { falcorGraph, falcorChunkerNice } from "store/falcorGraph"
import { listen, unlisten } from "components/AvlMap/LayerMessageSystem"
// import { register, unregister } from "components/AvlMap/ReduxMiddleware"

import geoJsonExtent from "@mapbox/geojson-extent"
import get from "lodash.get";
import flatten from 'lodash.flatten'
import mapboxgl from "mapbox-gl"

import MapLayer from "components/AvlMap/MapLayer"

import {
  ConflationSource,
  ConflationStyle
} from './conflation.style.js'

import { npmrdsPaint } from 'pages/auth/NAT/layers/npmrds/layer-styles'
import {  mb_tmc_metadata_2019 } from 'constants/mapboxTilesets'


import * as d3scale from "d3-scale"
// import { getColorRange } from "constants/color-ranges"

import RouteInfoBox from "../components/RouteInfoBox"

const atts = ['id', 'name', 'type', 'owner', "updatedAt", "tmcArray", "points"];

class RouteLayer extends MapLayer {
  onAdd(map) {

    this.markers.forEach(m => m.addTo(map));

    return falcorGraph.get(['geo', '36', 'geoLevels'])
      .then(res => {
        this.filters.geography.domain = flatten(get(res, ["json", "geo", '36', "geoLevels"], []))
          .filter(({ geolevel }) => geolevel !== "STATE")
          .map(geo => ({
            name: `${ geo.geolevel === "STATE" ? geo.geoname.toUpperCase() : geo.geoname } ${ geo.geolevel === "COUNTY" ? "County" : geo.geolevel === "STATE" ? "State" : geo.geolevel }`,
            geolevel: geo.geolevel,
            value: geo.geoid,
            bounds: geo.bounding_box
          }));
      })
      .then(() => this.loadUserRoutes(false))
      .then(() => {
        this.doAction(["updateFilter", "geography", this.loadFromLocalStorage()])
      })
  }
  loadFromLocalStorage() {
    return window.localStorage ?
      JSON.parse(window.localStorage.getItem("macro-view-geographies") || "[]")
      : [];
  }
  saveToLocalStorage(geographies = this.filters.geography.value) {
    if (window.localStorage) {
      if (geographies.length) {
        window.localStorage.setItem("macro-view-geographies", JSON.stringify(geographies));
      }
      else {
        window.localStorage.removeItem("macro-view-geographies")
      }
    }
  }
  loadUserRoutes(forceUpdate = true) {
    return falcorGraph.get(["routes", "length"])
      .then(res => {
        const num = get(res, ["json", "routes", "length"], 0);
        if (num) {
          return falcorGraph.get([
            'routes',
            'byIndex',
            { from: 0, to: num - 1 },
            atts
          ])
          .then(res => {
            // console.log("RES:", res);
            const routes = [];
            for (let i = 0; i < num; ++i) {
              routes.push(atts.reduce((a, c) => {
                a[c] = get(res, ["json", "routes", "byIndex", i, c], null);
                return a;
              }, {}))
            }
            this.filters.userRoutes.domain = routes;
          })
        }
        else {
          this.filters.userRoutes.domain = [];
        }
      })
      .then(() => {
        if (this.routeToLoad) {
          return falcorGraph.get(["routes", "byId", this.routeToLoad, atts])
            .then(() => this.selectUserRoute(this.routeToLoad))
        }
      })
  }

  getGeomRequest(selection) {
    if (this.mode === "click") {
      return ['tmc', selection, 'year', this.year, 'geometries'];
    }
    else if (this.mode === "markers") {
      return ['conflation', 'con', selection, 'meta', 'geometries'];
    }
    return false;
  }
  getBounds(geographies) {
    const cache = falcorGraph.getCache(),

      bounds = this.data[this.mode].reduce((a, c) => {
        const data = get(cache, [...this.getGeomRequest(c), "value"], null);
        return data ? a.extend(new mapboxgl.LngLatBounds(geoJsonExtent(data))) : a;
      }, new mapboxgl.LngLatBounds());

    return !bounds.isEmpty() ? bounds :
      this.filters.geography.domain
        .filter(d => geographies.includes(d.value))
        .reduce((a, c) => a.extend(c.bounds), new mapboxgl.LngLatBounds())
  }
  zoomToGeography() {
    if (!this.map) return;

    const geographies = this.filters.geography.value,
      bounds = this.getBounds(geographies);

    if (bounds.isEmpty()) return;

    const options = {
      padding: {
        top: 25,
        right: 200,
        bottom: 25,
        left: 200
      },
      bearing: 0,
      pitch: 0,
      duration: 2000
    };

    options.offset = [
      (options.padding.left - options.padding.right) * 0.5,
      (options.padding.top - options.padding.bottom) * 0.5
    ];

    const tr = this.map.transform,
      nw = tr.project(bounds.getNorthWest()),
      se = tr.project(bounds.getSouthEast()),
      size = se.sub(nw);

    const scaleX = (tr.width - (options.padding.left + options.padding.right)) / size.x,
      scaleY = (tr.height - (options.padding.top + options.padding.bottom)) / size.y;

    options.center = tr.unproject(nw.add(se).div(2));
    options.zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), tr.maxZoom);

    this.map.flyTo(options);
    this.doZoom = false;
  }

  getSelectionForGeography() {
    const year = 2019,
      geoids = this.filters.geography.value,
      filtered = this.filters.geography.domain
        .filter(({ value }) => geoids.includes(value));

    const cache = falcorGraph.getCache();

    return [...new Set([
      ...filtered.reduce((a, c) => {
        get(cache,
          ['tmc', 'identification',
            'type', c.geolevel,
            'geoid', c.value,
            'year', year, 'value']
        , []).forEach(d => a.add(d))
        return a;
      }, new Set()),
      ...this.tmcArray
    ])]
  }
  fetchRequestsForGeography() {
    const geoids = this.filters.geography.value,
      filtered = this.filters.geography.domain
        .filter(({ value }) => geoids.includes(value));

    return filtered.reduce((a, c) => {
      a.push(['tmc', 'identification',
        'type', c.geolevel, 'geoid', c.value, 'year', this.year]
      )
      return a;
    }, [])
  }
  loadRoute() {
    return Promise.resolve()
      .then(() => {
        if (this.mode === "click") {
          return { mode: "click", data: [...this.tmcArray] };
        }
        if (this.markers.length < 2) {
          return { mode: "markers", data: [] };
        }

        const locations = this.markers.map(m => ({
          lon: m.getLngLat().lng,
          lat: m.getLngLat().lat
        }))
        //const url = `http://ares.availabs.org:7185/route`;
        const url = `https://routing.availabs.org/0_4_2/route`;

        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({ locations })
        })
        .then((res, error) => {
          if (error) return { ways: [] };
          return res.json()
        })
        .then(res => {
          return { mode: "markers", data: get(res, "ways", []) };
        })
      })
      .then(({ mode, data }) => {
        this.data[mode] = data;
      })
  }
  fetchData() {
    return falcorChunkerNice(...this.fetchRequestsForGeography())
      .then(() => {
        const selection = this.getSelectionForGeography();
        // return falcorChunkerNice(['tmc', selection, 'year', this.year, 'geometries'])
        //   .then(() => {
        //     if (this.mode === "click") {
        //       const cache = falcorGraph.getCache(),
        //         collection = {
        //           type: "FeatureCollection",
        //           features: selection.map(tmc => ({
        //             id: tmc.replace(/([-+npNP])/g, (m, p1) => p1.charCodeAt(0)),
        //             type: "Feature",
        //             properties: {
        //               tmc
        //             },
        //             geometry: get(cache, ["tmc", tmc, "year", this.year, "geometries", "value"], null)
        //           })).filter(f => Boolean(f.geometry))
        //         }
        //       this.map.getSource("tmc-geometries-source").setData(collection);
        //     }
        //   })
      })
      .then(() => this.loadRoute())
      .then(() => {
        return this.data[this.mode].length && falcorChunkerNice(this.getGeomRequest(this.data[this.mode]));
      })
      .then(() => {
        if (this.data[this.mode].length) {
          return falcorChunkerNice(this.getGeomRequest(this.data[this.mode]))
        }
      })
      .then(() => {
        if (this.mode === "markers" && this.data.markers.length) {
          return falcorChunkerNice([
            "conflation", "con", this.data.markers, "meta", ['tmc17id','tmc19id']
          ])
          .then(() => {
            const cache = falcorGraph.getCache();

            this.tmcArray = this.data.markers.reduce((a, c) => {
              const tmc = get(cache, ["conflation", "con", c, "meta", this.getTmcId()], null);
              if (tmc && !a.includes(tmc)) {
                a.push(tmc);
              }
              return a;
            }, []);
          });
        }
      });
  }
  onFilterFetch(filterName, oldValue, value) {
    (filterName === "userRoutes") && this.loadUserRoute(value);
    return this.fetchData();
  }

  // getMapFilter() {
  //   switch (this.mode) {
  //     case "markers":
  //       return ["match",
  //         ["to-string", ["get", "id"]],
  //         [...new Set(this.data[this.mode]), "none"],
  //         true, false
  //       ]
  //     case "click":
  //       return ["match",
  //         ["to-string", ["get", this.getTmcId()]],
  //         [...new Set(this.data[this.mode]), "none"],
  //         true, false
  //       ]
  //   }
  // }

  render(map) {
    const layerName = this.mode === "click" ? "tmc-geometries" : "conflation",
      offLayer = this.mode !== "click" ? "tmc-geometries" : "conflation",
      data = [...new Set(this.data[this.mode])],
      id = this.mode === "click" ? "tmc" : "id";


     map.setLayoutProperty(layerName, 'visibility', 'visible')
     map.setLayoutProperty(offLayer, 'visibility', 'none')

      map.setFilter("conflation",
        ["any",
          ["in", ["get", this.getTmcId()],
            ["literal", this.getSelectionForGeography()]
          ],
          ["in", ["to-string", ["get", "id"]], ["literal", data]]
        ]
      );
      // map.setFilter("tmc-geometries", ["boolean", false]);
    
    
    // else {
    //   map.setFilter("conflation", ["boolean", false]);
    //   map.setFilter("tmc-geometries",
    //     ["in", ["get", this.getTmcId()],
    //       ["literal", this.getSelectionForGeography()]
    //     ]
    //   );
    // }


    map.setPaintProperty(layerName, "line-color",
      [ "case",
        ["all",
          ["any",
            ["boolean", ["feature-state", "hover"], false],
            ["in", ["to-string", ["get", this.getTmcId()]], ["literal", this.highlightedTmcs]]
          ],
          ["in", ["to-string", ["get", id]], ["literal", data]]
        ], "#e0e",

        ["in", ["to-string", ["get", id]], ["literal", data]], "#a0a",

        ["boolean", ["feature-state", "hover"], false], "#0a0",

        "#888"
      ]
    )

    this.doZoom && this.zoomToGeography();
  }

  getTmcId() {
    if (this.mode === "click") return "tmc";

    const year = this.year < 2019 ? 2017 : 2019;
    return "tmc" + year.toString().slice(2) + "id";
  }
  loadUserRoute(route) {
    this.mapActions.modeToggle.disabled = true;
    // this.year = new Date(route.updatedAt).getFullYear();

    this.doZoom = true;

    if (get(route, "points", []).length) {
      this.mode = "markers";
      this.generateMapMarkers(route.points);
    }
    else {
      this.mode = "click";
      this.markers.forEach(m => m.remove());

      this.tmcArray = [...route.tmcArray];
    }
  }
  selectUserRoute(routeId) {
    const data = get(falcorGraph.getCache(), ["routes", "byId", routeId], null);
    if (data) {
      const route = atts.reduce((a, c) => {
        switch (c) {
          case "tmcArray":
          case "points":
            a[c] = get(data, [c, "value"], null);
            break;
          default:
            a[c] = get(data, c, null);
        }
        return a;
      }, {})
      this.routeToLoad = null;
      this.doAction(["updateFilter", "userRoutes", route]);
    }
    else {
      this.routeToLoad = routeId;
    }
  }
  getRouteName() {
    return get(this.filters, ["userRoutes", "value", "name"], "Route");
  }
  generateMapMarkers(lngLat = null) {
    this.markers.forEach(m => m.remove());

    let points = this.markers.map(m => m.getLngLat());

    if (lngLat) {
      if (Array.isArray(lngLat)) {
        points = lngLat;
      }
      else {
        points.push(lngLat);
      }
    }

    const num = Math.max(points.length - 1, 1)

    const scale = d3scale.scaleLinear()
      .domain([0, num * 0.5, num])
      .range(["#1a9641", "#ffffbf", "#d7191c"])

    this.markers = points.map((p, i) => {
      return new mapboxgl.Marker({
          draggable: true,
          color: scale(i)
        })
        .setLngLat(p)
        .addTo(this.map)
        .on("dragend", e => this.calcRoute());
    })
  }
  calcRoute() {
    this.doAction(["fetchLayerData"]);
  }
  removeLast() {
    switch (this.mode) {
      case "markers":
        this.markers.pop().remove();
        this.generateMapMarkers();
        break;
      case "click":
        this.data["click"].pop();
        break;
    }
    this.calcRoute();
  }
  clearRoute() {
    switch (this.mode) {
      case "markers":
        while (this.markers.length) {
          this.markers.pop().remove();
        }
        break;
      case "click":
        this.data.click = [];
        break;
    }
    this.filters.userRoutes.value = null;
    this.mapActions.modeToggle.disabled = false;
    this.tmcArray = [];
    this.calcRoute();
  }
  toggleCreationMode() {
    switch (this.mode) {
      case "markers":
        this.mode = "click";
        this.markers.forEach(m => m.remove());

        break;
      case "click":
        this.mode = "markers";
        this.generateMapMarkers();
        break;
    }
    this.calcRoute();
    this.doAction(["sendMessage", {
      message: `You have entered ${ this.mode } mode.`
    }])
  }
  highlightTmcs(tmcs) {
    const set = new Set(this.highlightedTmcs);
    for (const tmc of tmcs) {
      if (set.has(tmc)) {
        set.delete(tmc);
      }
      else {
        set.add(tmc);
      }
    }
    this.highlightedTmcs = [...set];
    // this.map && this.render(this.map);
    this.doAction(["renderLayer"]);
  }
  handleMapClick(data) {
    switch (this.mode) {
      case "markers":
        this.generateMapMarkers(data);
        break;
      case "click":
        const newData = new Set([...data]),
          set = new Set([...this.data.click]);
        newData.forEach(tmc => {
          if (!set.has(tmc)) {
            set.add(tmc);
          }
          else {
            set.delete(tmc);
          }
        })
        this.tmcArray = [...set];
        break;
    }
    this.calcRoute();
  }
  reorderTmcs(tmcArray) {
    if (this.mode === "click") {
      this.data.click = [...tmcArray];
      this.tmcArray = [...tmcArray];
      this.forceUpdate();
    }
  }
}

export default (props = {}) =>
  new RouteLayer("Conflation Routing 2.0", {
    version: 2.0,

    sources: [
      ConflationSource,
      {
        id: 'tmc_extended_2019_with_mpo',
        source: {
          type: 'vector',
          url: mb_tmc_metadata_2019.url
        }
      },
      { id: "tmc-geometries-source",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      }
    ],
    layers: [
      { ...ConflationStyle,
        filter: ["boolean", false],
        paint: {
          ...ConflationStyle.paint,
          /*"line-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#0a0",
            "#888"
          ],
          "line-opacity": 1.0,
          "line-width": 6*/
        },
        beneath: 'waterway-label'
      },
      {
        id: 'tmc-geometries',
        type: 'line',
        source: 'tmc_extended_2019_with_mpo',
        beneath: 'waterway-label',
        'source-layer': mb_tmc_metadata_2019.layer,
        layout: {
          'visibility': 'none',
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: npmrdsPaint
      },
      // { id: "tmc-geometries",
      //   type: "line",
      //   source: "tmc-geometries-source",
      //   filter: ["boolean", false],
      //   paint: {
      //     ...ConflationStyle.paint,
      //     "line-color": [
      //       "case",
      //       ["boolean", ["feature-state", "hover"], false],
      //       "#0a0",
      //       "#888"
      //     ],
      //     "line-opacity": 1.0,
      //     "line-width": 6
      //   }
      // }
    ],

    ...props,

    onHover: {
      layers: ["conflation", "tmc-geometries"],
      filterFunc: function(features) {
        return [
          "in", this.getTmcId(),
          ...features.map(f => f.properties[this.getTmcId()])
            .filter(Boolean)
        ]
      }
    },

    onClick: {
      layers: ["map", "conflation", "tmc-geometries"],
      dataFunc: function(features, point, lngLat, layer) {
        switch (this.mode) {
          case "markers":
            layer === "map" && this.handleMapClick(lngLat);
            break;
          case "click":
            (layer === "tmc-geometries") &&
              this.handleMapClick(
                (features || [])
                  .map(f => f.properties[this.getTmcId()])
                  .filter(Boolean)
              );
            break;
        }
      }
    },

    mode: "markers",
    markers: [],
    tmcArray: [],
    data: {
      markers: [],  // array of conlation ids
      click: []     // array of tmc ids
    },
    year: 2019,
    doZoom: true,
    highlightedTmcs: [],

    filters: {
      userRoutes: {
        name: "Routes",
        type: "single",
        domain: [],
        value: null,
        searchable: true,
        // onChange: function(prev, route, domain) {
        //   this.loadUserRoute(route);
        // }
      },
      geography: {
        name: 'Geography',
        type: 'multi',
        domain: [],
        value: [],
        onChange: function(oldValue, newValue, domain) {
          // this.zoomToGeography();
          this.doZoom = true;
          this.saveToLocalStorage();
        }
      },
    },

    infoBoxes: {
      router: {
        title: ({ layer}) => layer.getRouteName(),
        comp: ({ layer }) => (
          <RouteInfoBox layer={ layer }
            userRoute={ layer.filters.userRoutes.value }
            tmcArray={ layer.tmcArray }
            mode={ layer.mode }/>
        ),
        show: true
      }
    },

    mapActions: {
      modeToggle: {
        Icon: ({ layer }) => <span className={ `fa fa-2x fa-${ layer.mode === "markers" ? "map-marker" : "road" }` }/>,
        tooltip: "Toggle Creation Mode",
        action: function() {
          this.toggleCreationMode();
        }
      }
    }
  })
