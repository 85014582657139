const ATTRIBUTES = [
    "eventid",
    "event_type",
    "facility",
    "creation",
    "open_time",
    "close_time",
    "duration",
    "description",
    "from_city",
    "from_mile_marker",
    "to_mile_marker",
    "latitude",
    "longitude",
    "event_category",
    'point_geom',
    'congestion_data'
];

const ATTRIBUTES_TO_DISPLAY = [
    'eventid', 'event_category', 'event_type', 'facility', 'open_time', 'close_time', 'duration'
]

const format = {
    sameDay: 'YYYY-MM-DD',
    nextDay: 'YYYY-MM-DD',
    nextWeek: 'YYYY-MM-DD',
    lastDay: 'YYYY-MM-DD',
    lastWeek: 'YYYY-MM-DD',
    sameElse: 'YYYY-MM-DD'
};

const format_with_time = {
    sameDay: 'YYYY-MM-DD HH:mm:ss',
    nextDay: 'YYYY-MM-DD HH:mm:ss',
    nextWeek: 'YYYY-MM-DD HH:mm:ss',
    lastDay: 'YYYY-MM-DD HH:mm:ss',
    lastWeek: 'YYYY-MM-DD HH:mm:ss',
    sameElse: 'YYYY-MM-DD HH:mm:ss'
};

const format_month = {
    sameDay: 'YYYY-MM',
    nextDay: 'YYYY-MM',
    nextWeek: 'YYYY-MM',
    lastDay: 'YYYY-MM',
    lastWeek: 'YYYY-MM',
    sameElse: 'YYYY-MM'
};

const format_MM_DD = {
    sameDay: 'MM-DD',
    nextDay: 'MM-DD',
    nextWeek: 'MM-DD',
    lastDay: 'MM-DD',
    lastWeek: 'MM-DD',
    sameElse: 'MM-DD'
};

const lastDate = '2016-01-01';

const catColors = {
    'accident': 'red', 'construction': 'blue', 'other': 'gray'
}

export {
    ATTRIBUTES,
    ATTRIBUTES_TO_DISPLAY,
    format,
    format_with_time,
    format_month,
    format_MM_DD,
    lastDate,
    catColors
}