import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';
import get from "lodash.get"

import { push } from "connected-react-router"

import SaveModal from 'pages/auth/Routes/create/components/RouteSaveModal'

import styled from 'styled-components';

const RouteSaved = styled.div`
  color: ${ props => props.theme.textColor };
  font-size: 1rem;
`

const measureIdsSelector = ({
  graph: { pm3: { measureIds: { value } = {} } = {} } = {}
}) => value;

const Button = styled.button`
  background-color: rgb(50, 50, 70);
  color: ${ props => props.theme.textColorHl };
  border-radius: 4px;
  padding: 6px;
  font-size: 1.2em;
  border: none;
  margin: 5px;
  cursor: pointer;
  fontWeight: 400;
  width: 46%;

  :hover {
    background-color: rgba(60, 70, 80);
  }
  :disabled {
    cursor: not-allowed;
    background-color: rgb(40, 50, 60);
    color: ${ props => props.theme.textColor };
  }
`

class RouteEditor extends Component {
  state = {
    showSaveModal: false,
    name: this.props.routeInfo.name || '',
    type: this.props.routeInfo.type || 'personal',
    owner: this.props.routeInfo.owner || this.props.user.id,
    routeId: this.props.routeId
  }

  saveRoute (update, routeId = null) {
    let args = {
      routeId,
      ...update,
      tmcArray: this.props.tmcArray,
      risArray: this.props.risArray || [],
      conflationIdArray: this.props.conflationIdArray || [],
      points: this.props.points
    }
    return this.props.falcor.call(
      ["routes", "save"],
      [args], [], []
    )
    .then(res => {
      const newRouteId = get(res, 'json.routes.recentlySaved', routeId);
      if ((newRouteId !== null) && (newRouteId != routeId) && this.props.layer.redirectOnSave) {
        return newRouteId;
      }
      return null;
    });
  }

  updateRoute (update) {
    this.setState({...update})
  }

  showModal (show = true) {
    this.setState({showSaveModal: show})
  }

  render() {
    const { activeTmcs } = this.props;
    return (
      <div>
        <Button onClick={ () => this.showModal() }>Save</Button>
        <Button onClick={ () => this.props.layer.clearMap() }>{ this.state.routeId ? 'Reset Map' : 'Clear Map' }</Button>

        <SaveModal
          show={ this.state.showSaveModal }
          onHide={ () => this.showModal(false) }
          updateRoute={ this.updateRoute.bind(this) }
          saveRoute={ this.saveRoute.bind(this) }
          user={ this.props.user }
          routeId={ this.state.routeId }
          onResolve={ ({ res }) => <RouteSaved>{ "Your route has been saved." }</RouteSaved> }
          // onResolveHide={ ({ res }) => Boolean(res) && this.props.push(`/routes/edit/${ res }`) }
          route={ {
            name: this.state.name,
            type: this.state.type,
            owner: this.state.owner,
          } }/>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  graph: state.graph
});
const mapDispatchToProps = {
  push
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(RouteEditor));
