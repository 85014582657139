import React from "react"

import get from "lodash.get"
import throttle from "lodash.throttle"

import deepequal from "deep-equal"

import * as d3array from "d3-array"
import * as d3selection from "d3-selection"
import * as d3multi from "d3-selection-multi"
import * as d3scale from "d3-scale"
import * as d3axis from "d3-axis"
import * as d3transition from "d3-transition"
import * as d3format from "d3-format"
import * as d3shape from "d3-shape"
import * as d3ease from "d3-ease"

import COLOR_RANGES from "constants/color-ranges"
const KEY_COLORS = COLOR_RANGES[12].reduce((a, c) => c.name === "Set3" ? c.colors : a, []).slice()

const d3 = {
	...d3array,
	...d3selection,
	...d3multi,
	...d3scale,
	...d3axis,
	...d3transition,
	...d3format,
	...d3shape,
	...d3ease
}

class LineGraph extends React.Component {
	static defaultProps = {
		data: [],
		margin: {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		},
		hoverComp: () => <div>Default Hover Comp</div>
	}

	constructor(props) {
		super(props);

		this.div = React.createRef();
		this.svg = React.createRef();
		this.hoverComp = React.createRef();

		this.timeout = null;

		this.state = {
			lineGraph: d3LineGraph(),
			width: null,
			height: null,
			hoverData: null
		}
		this.mouseenter = this.mouseenter.bind(this);
		this.mousemove = this.mousemove.bind(this);
		this.mouseleave = this.mouseleave.bind(this);
	}

	componentDidMount() {
		this.resize(false);
		this.updateGraph();
	}
	componentWillUnmount() {
		window.clearTimeout(this.timeout);
	}
	componentDidUpdate(oldProps) {
		if (!deepequal(oldProps, this.props)) {
			this.updateGraph();
		}
	}

	resize(updateGraph = true) {
		const div = this.div.current;
		if (!div) return;

		const width = div.clientWidth,
			height = div.clientHeight;

		if ((width !== this.state.width) || (height !== this.state.height)) {
			this.setState({ width, height });
			updateGraph && this.updateGraph(true);
		}

		this.timeout = setTimeout(this.resize.bind(this), 50);
	}

	mouseenter(d, time) {
		this.setState({ hoverData: d });

		const hoverComp = this.hoverComp.current;
		if (!hoverComp) return;

		d3.select(hoverComp)
			.style("transition", `left ${ time }s`)
	}
	mousemove([left, top], right) {
		const hoverComp = this.hoverComp.current;
		if (!hoverComp) return;

		const width = hoverComp.clientWidth;
		if ((left + 10 + width) > right) {
			left -= 10 + width;
		}
		else {
			left += 10;
		}

		d3.select(hoverComp)
			.style("left", `${ left }px`)
	}
	mouseleave() {
		const hoverComp = this.hoverComp.current;
		if (!hoverComp) return;

		this.setState({ hoverData: null });
	}

	updateGraph(resizing = false) {
		const div = this.div.current;
		if (!div) return;

		const { lineGraph } = this.state;

		const {
			data,
			margin,
			xScale = {},
			leftScale = {},
			rightScale = {},
			axisBottom = {},
			axisLeft = {},
			axisRight = {}
		} = this.props;

		lineGraph
			.resizing(resizing)
			.margin(margin)
			.xScale(xScale)
			.leftScale(leftScale)
			.rightScale(rightScale)
			.axisBottom(axisBottom)
			.axisLeft(axisLeft)
			.axisRight(axisRight)
			.mouseenter(this.mouseenter)
			.mousemove(this.mousemove)
			.mouseleave(this.mouseleave)
			.data(data);

		d3selection.select(div)
			.call(lineGraph)
	}

	render() {
		return (
			<div style={ {
					width: "100%",
					height: "100%",
					position: "relative"
				} }
				ref={ this.div }>
				<svg style={ { width: "100%", height: "100%" } }
					ref={ this.svg }/>
				{ !this.state.hoverData ? null :
					<div style={ {
							top: `${ this.props.margin.top }px`,
							position: "absolute",
							background: "#fff",
							padding: "10px",
							pointerEvents: "none"
						} }
						ref={ this.hoverComp }>
						<this.props.hoverComp data={ this.state.hoverData }/>
					</div>
				}
			</div>
		)
	}
}
export default LineGraph;
// //
const DEFAULT_X_SCALE = {
	type: "linear"
}
const DEFAULT_LEFT_SCALE = {

}
const DEFAULT_RIGHT_SCALE = {

}
const DEFAULT_AXIS_LEFT = {

}
const DEFAULT_AXIS_RIGHT = {

}
const DEFAULT_AXIS_BOTTOM = {
	tickDensity: null
}

function d3LineGraph() {
	let data = [],
		margin = {
			top: 0,
			right: 0,
			bottom: 0,
			left: 0
		},
		resizing = false,
		_xScale = { ...DEFAULT_X_SCALE },
		_leftScale = { ...DEFAULT_LEFT_SCALE },
		_rightScale = { ...DEFAULT_RIGHT_SCALE },
		_axisBottom = { ...DEFAULT_AXIS_BOTTOM },
		_axisLeft = { ...DEFAULT_AXIS_LEFT },
		_axisRight = { ...DEFAULT_AXIS_RIGHT },
		mouseenter = null,
		mousemove = null,
		mouseleave = null;

	const LINE_STYLES = {
		'line-group-left': {
			"stroke-width": 2
		},
		'line-group-right': {
			"stroke-width": 2,
			'stroke-dasharray': '10, 6'
		}
	}

	function graph(selection) {
		if (!selection.size()) return;

		const node = selection.node(),
			width = node.clientWidth - margin.left - margin.right,
			height = node.clientHeight - margin.top - margin.bottom;

		const xScale = getXscale()
			.range([0, width]);

		const xDomain = xScale.domain();

		const sliceScale = d3.scalePoint()
			.domain(_xScale.type === "point" ? xDomain : d3.range(xDomain[0], xDomain[1] + 1))
			.range([0, width]);
		const sliceWidth = sliceScale.step(),
			sliceMod = sliceScale.domain().length - 1;

		const sliceTime = d3.scaleLinear()
			.domain([5, 250])
			.range([0.1, 0.25])

		const sliceGroup = selection.select("svg")
			.selectAll("g.slice-group")
			.data(["slice-group"])

		sliceGroup.enter()
			.append("g")
				.attr("class", "slice-group")
				.merge(sliceGroup)
				.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`)
				.on("mouseleave", function() {
					d3.select(this)
						.selectAll("line")
							.remove();
					mouseleave && mouseleave()
				});

		const rects = sliceGroup
			.selectAll("rect")
			.data(sliceScale.domain(), d => d);

		let timer = 0;

		rects.enter()
			.append("rect")
				.attr("fill", "transparent")
				.attr("y", 0)
				.merge(rects)
					.attr("width", (d, i) => i % sliceMod === 0 ? sliceWidth * 0.5 : sliceWidth)
					.attr("height", height)
					.attr("x", (d, i) => i === 0 ? sliceScale(d) : sliceScale(d) - sliceWidth * 0.5)
					.on("mouseenter", d => {
						const now = performance.now();
						if ((now - timer) < 50) return;
						timer = now;

						const sliceLine = sliceGroup
							.selectAll("line")
								.data(["slice-line"]);

						sliceLine.enter()
							.append("line")
								.attr("y1", 0)
								.attr("y2", height)
								.attr("x1", 0.5)
								.attr("x2", 0.5)
								.style("stroke", "#000")
								.style("pointer-events", "none")
								.style("transform", `translate(${ sliceScale(d) }px, 0px)`)

						sliceLine
							.style("transition", `transform ${ sliceTime(sliceWidth) }s`)
							.style("transform", `translate(${ sliceScale(d) }px, 0px)`);

						const hoverData = {
								x: d,
								data: data.reduce((a, c) => [...a, { ...c, data: c.data.reduce((a, c) => c.x == d ? c : a, null) }], [])
												.filter(({ data }) => Boolean(data))
							},
							x = margin.left + sliceScale(d) + 0.5,
							[, y] = d3.mouse(selection.node()),
							right = margin.left + width;
						mouseenter && mouseenter(hoverData, sliceTime(sliceWidth));
						mousemove && mousemove([x, y], right)
					})
					// .on("mousemove", function(d) {
					// 	const [, y] = d3.mouse(selection.node());
					// 	mousemove && mousemove([margin.left + sliceScale(d) + 0.5, y], margin.left + width);
					// })

		rects.exit()
			.remove();


		const data1 = data.filter(d => d.yAxis !== 'right');
		drawLines(selection, data1, xScale, width, height, 'left');

		const data2 = data.filter(d => d.yAxis === 'right');
		drawLines(selection, data2, xScale, width, height, 'right');

		let xAxisGroup = selection.select("svg")
			.selectAll("g.x-axis-group")
			.data(data.length ? ["x-axis-group"] : []);

		const transition = d3.transition().duration(2000);

		xAxisGroup.enter()
			.append("g")
			.attr("class", "x-axis-group")
			.style("transform", `translate(${ margin.left }px, ${ margin.top + height + margin.bottom }px)`)
			.transition(transition)
				.style("transform", `translate(${ margin.left }px, ${ margin.top + height }px)`)

		let tickValues = null;
		if (_xScale.type === "point") {
			const domain = xScale.domain(),
				mod = Math.max(1, Math.round((domain.length / width) * 60));
			tickValues = domain
				.filter((d, i) => {
					if ((i === 0)) return mod === 1;
					if ((i + mod) > domain.length) return false;
					if ((i % mod) === 0) return true;
					return false;
				}, []);
		}

		const xAxis = d3.axisBottom(xScale)
			.tickFormat(get(_axisBottom, "format", null))
			.tickValues(tickValues);

		if (resizing) {
			xAxisGroup
				.style("transform", `translate(${ margin.left }px, ${ margin.top + height }px)`)
				.call(xAxis);
		}
		else {
			xAxisGroup
				.transition(transition)
				.style("transform", `translate(${ margin.left }px, ${ margin.top + height }px)`)
				.call(xAxis);
		}

		xAxisGroup.exit()
			.transition(transition)
				.style("transform", `translate(${ margin.left }px, ${ margin.top + height + margin.bottom }px)`)
			.remove()

		selection.selectAll("path.domain")
			.style("stroke-width", "2px")
		selection.selectAll(".axis-right-group path.domain")
			.style("stroke-dasharray", "10, 6")
	}

	graph.axisBottom = function(d) {
		if (!arguments.length) {
			return _axisBottom;
		}
		_axisBottom = {
			...DEFAULT_AXIS_BOTTOM,
			..._axisBottom,
			...d
		}
		return graph;
	}
	graph.axisLeft = function(d) {
		if (!arguments.length) {
			return _axisLeft;
		}
		_axisLeft = {
			...DEFAULT_AXIS_LEFT,
			..._axisLeft,
			...d
		}
		return graph;
	}
	graph.axisRight = function(d) {
		if (!arguments.length) {
			return _axisRight;
		}
		_axisRight = {
			...DEFAULT_AXIS_RIGHT,
			..._axisRight,
			...d
		}
		return graph;
	}

	graph.mouseenter = function(d) {
		if (!arguments.length) {
			return mouseenter;
		}
		mouseenter = d;
		return graph;
	}
	graph.mousemove = function(d) {
		if (!arguments.length) {
			return mousemove;
		}
		mousemove = d;
		return graph;
	}
	graph.mouseleave = function(d) {
		if (!arguments.length) {
			return mouseleave;
		}
		mouseleave = d;
		return graph;
	}

	graph.data = function(d) {
		if (!arguments.length) {
			return data;
		}
		data = d;
		return graph;
	}
	graph.resizing = function(d) {
		if (!arguments.length) {
			return resizing;
		}
		resizing = d;
		return graph;
	}
	graph.margin = function(m) {
		if (!arguments.length) {
			return margin;
		}
		margin = {
			...margin,
			...m
		}
		return graph;
	}
	graph.xScale = function(x) {
		if (!arguments.length) {
			return _xScale;
		}
		_xScale = {
			...DEFAULT_X_SCALE,
			..._xScale,
			...x
		}
		return graph;
	}
	graph.leftScale = function(l) {
		if (!arguments.length) {
			return _leftScale;
		}
		_leftScale = {
			...DEFAULT_LEFT_SCALE,
			..._leftScale,
			...l
		}
		return graph;
	}
	graph.rightScale = function(l) {
		if (!arguments.length) {
			return _rightScale;
		}
		_rightScale = {
			...DEFAULT_RIGHT_SCALE,
			..._rightScale,
			...l
		}
		return graph;
	}
	return graph;

	function getXscale() {
		switch (_xScale.type) {
			case "point":
				return d3.scalePoint()
					// .domain(get(data, [0, "data"], []).map(d => d.x))
					.domain(
						data.reduce((a, c) =>
							c.data.length > a.length ? c.data.map(d => d.x) : a
						, [])
					)
			case "linear":
				return d3.scaleLinear()
					.domain(d3.extent(data.reduce((a, c) => [...a, ...c.data.map(d => +d.x)], [])))
		}
	}

	function drawLines(selection, data, xScale, width, height, group) {


		const yDomain = [0, d3.max(data.reduce((a, c) => [...a, ...c.data.map(d => d.y)], []))]

		if ((group === "left") && _leftScale.min) {
			yDomain[0] = _leftScale.min;
		}if ((group === "left") && _leftScale.max) {
			yDomain[1] = _leftScale.max;
		}

		if ((group === "right") && _rightScale.min) {
			yDomain[0] = _rightScale.min;
		}if ((group === "right") && _rightScale.max) {
			yDomain[1] = _rightScale.max;
		}
		const yScale = d3.scaleLinear()
			.domain(yDomain)
			.range([height, 0])

		const svg = selection.select("svg");

		const transition = d3.transition().duration(2000);

		let lineGroup = svg.select(`g.line-group-${ group }`);
		if (!lineGroup.size()) {
			lineGroup = svg.append("g")
				.attr("class", `line-group-${ group }`)
		}
		if (resizing) {
			lineGroup
				.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`);
		}
		else {
			lineGroup
				.transition(transition)
				.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`);
		}

		const lineEnter = d3.line()
			.x(d => xScale(d.x))
			.y(d => yScale(yScale.domain()[0]))

		const lineGenerator = d3.line()
			.x(d => xScale(d.x))
			.y(d => yScale(d.y));

		const lines = lineGroup.selectAll("path")
			.data(data, d => d.id)

		const entering = lines.enter()
			.append("path")
				.attr("fill", "none")
				.attr('stroke-opacity', 0)
				.attr('stroke', d => d.color)
				.attr("d", d => lineEnter(d.data))
				.style("pointer-events", "none");

		const merged = entering.merge(lines);

		merged.each(function(d) {
			d3.select(this)
				.datum({ ...d, exitPath: lineEnter(d.data) })
		})

		const getStrokeColor = (d, i) => get(d, 'color', KEY_COLORS[i % KEY_COLORS.length])

		if (resizing) {
			merged
				.attrs(LINE_STYLES[`line-group-${ group }`])
				.attr('stroke-opacity', 1)
				.attr("d", d => lineGenerator(d.data))
				.attr('stroke', getStrokeColor);
		}
		else {
			merged
				.transition(transition)
					.attrs(LINE_STYLES[`line-group-${ group }`])
					.attr('stroke-opacity', 1)
					.attr("d", d => lineGenerator(d.data))
					.attr('stroke', getStrokeColor);
		}

		lines.exit()
			.transition(transition)
				.attr('stroke-opacity', 0)
				.attr("d", d => d.exitPath)
			.remove();

		if (group === "left") {
			const axisLeftGroup = svg.selectAll("g.axis-left-group")
				.data(data.length ? ["axis-left-group"] : []);

			axisLeftGroup.enter()
				.append("g")
					.attr("class", "axis-left-group")
					.style("transform", `translate(${ margin.left }px, ${ height + margin.top + margin.bottom }px)`)
					.style("opacity", 0)
					.transition(transition)
						.style("opacity", 1)
						.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`);

			axisLeftGroup
				.selectAll("text.legend")
				.data([get(_axisLeft, "legend", null)].filter(Boolean))
				.join("text")
					.attr("class", "legend")
					.attr("transform", `translate(${ -margin.left + get(_axisLeft, "legendOffset", 20) }, ${ height * 0.5 }) rotate(-90)`)
					.attr("text-anchor", "middle")
					.attr("font-size", "0.9rem")
					.attr("fill", "#000")
					.text(d => d);

			const axisLeft = d3.axisLeft(yScale)
				.tickFormat(get(_axisLeft, "format", null));

			if (resizing) {
				axisLeftGroup
					.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`)
					.style("opacity", 1)
					.call(axisLeft);
			}
			else {
				axisLeftGroup
					.transition(transition)
						.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`)
						.style("opacity", 1)
					.call(axisLeft);
			}

			axisLeftGroup.exit()
				.transition(transition)
					.style("transform", `translate(${ margin.left }px, ${ height + margin.top + margin.bottom }px)`)
					.style("opacity", 0)
				.remove();
		}
		else if (group === "right") {
			const axisRightGroup = svg.selectAll("g.axis-right-group")
				.data(data.length ? ["axis-right-group"] : []);

			axisRightGroup.enter()
				.append("g")
					.attr("class", "axis-right-group")
					.style("transform", `translate(${ width + margin.left }px, ${ height + margin.top + margin.bottom }px)`)
					.style("opacity", 0)
					.transition(transition)
						.style("opacity", 1)
						.style("transform", `translate(${ width + margin.left }px, ${ margin.top }px)`);

			axisRightGroup
				.selectAll("text.legend")
				.data([get(_axisRight, "legend", null)].filter(Boolean))
				.join("text")
					.attr("class", "legend")
					.attr("transform", `translate(${ margin.right - get(_axisRight, "legendOffset", 20) }, ${ height * 0.5 }) rotate(90)`)
					.attr("text-anchor", "middle")
					.attr("fill", "#000")
					.attr("font-size", "0.9rem")
					.text(d => d);

			const axisRight = d3.axisRight(yScale)
				.tickFormat(get(_axisRight, "format", null));

			if (resizing) {
				axisRightGroup
					.style("transform", `translate(${ width + margin.left }px, ${ margin.top }px)`)
					.style("opacity", 1)
					.call(axisRight);
			}
			else {
				axisRightGroup
					.transition(transition)
						.style("transform", `translate(${ width + margin.left }px, ${ margin.top }px)`)
						.style("opacity", 1)
					.call(axisRight);
			}

			axisRightGroup.exit()
				.transition(transition)
					.style("transform", `translate(${ width + margin.left }px, ${ height + margin.top + margin.bottom }px)`)
					.style("opacity", 0)
				.remove();
		}
	}
}
