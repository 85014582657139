import debounce from 'lodash.debounce';

import registry, { controlledComponents } from '../registry';

const actionNameScope = registry[controlledComponents].join(':');

export const SET_TABLE_PAGE = `${actionNameScope}:SET_TABLE_PAGE`;
export const SET_TABLE_PAGE_SIZE = `${actionNameScope}:SET_TABLE_PAGE_SIZE`;
export const SET_TABLE_SORTED = `${actionNameScope}:SET_TABLE_SORTED`;
export const SET_TABLE_FILTERED = `${actionNameScope}:SET_TABLE_FILTERED`;
export const COPY_MEASURE_TABLE_STATE = `${actionNameScope}:COPY_MEASURE_TABLE_STATE`;

// ------------------------------------
// Actions
// ------------------------------------
export const setTablePage = tablePage => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_TABLE_PAGE, payload: tablePage });
    resolve();
  });

export const setTablePageSize = (pageSize, pageIndex) => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_TABLE_PAGE_SIZE, payload: { pageSize, pageIndex } });
    resolve();
  });

export const setTableSorted = tableSorted => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_TABLE_SORTED, payload: tableSorted });
    resolve();
  });

// Cannot debounce because it then hides typed characters
export const setTableFiltered = tableFiltered => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_TABLE_FILTERED, payload: tableFiltered });
    resolve();
  });

export const copyMeasureTableState = measureTableState =>
  debounce(
    dispatch =>
      new Promise(resolve => {
        dispatch({
          type: COPY_MEASURE_TABLE_STATE,
          payload: measureTableState
        });
        resolve();
      }),
    500,
    { trailing: true }
  );
