import React from 'react';
import { reduxFalcor } from 'utils/redux-falcor';
import { connect } from 'react-redux';
import styled from 'styled-components';

import MapLayer from "components/AvlMap/MapLayer"

import { falcorGraph } from "store/falcorGraph"

import mapboxgl from 'mapbox-gl/dist/mapbox-gl'

import { Delete } from "components/common/icons"

import {ButtonGroup} from 'components/common/styled-components'

import {routesLayers, sources, BASE_ROUTE_LAYER_LINE_COLOR} from './npmrds/layer-styles'

import RouteEditor from 'pages/auth/NAT/components/RouteEdit'
import get from "lodash.get"
import clonedeep from "lodash.clonedeep"
import classnames from "classnames"

import Loading from "components/loading/loadingPage"

class RouteLayer extends MapLayer {
	constructor(options) {
		super("Route Layer", options);

		this.markers = [];
		this.route = [];
	}

	tmcFalcorGet(tmcs) {
		return [
			["tmc", tmcs, "downstream", "byDepth", 1],
			["tmc", tmcs, "upstream", "byDepth", 1],
			["tmc", tmcs, "meta", 2019,
				['roadnumber', 'roadname', 'firstname', 'tmclinear']
			]
		]
	}

	receiveProps(oldProps, newProps) {
		this.history = newProps.history;
	}

	clearMap(reloadRoute=true) {
		this.markers.forEach(m => m.remove());
		this.markers = [];
		this.route = [];
		this.highlightTmcsOnMap();

		if(reloadRoute && this.routeId && this.creationMode === "markers") {
			this.loadRouteData(this.map);
		}
		this.forceUpdate();
	}

	toggleCreationMode() {
		this.creationMode = this.creationMode === "markers" ? "click" : "markers";

		if (this.creationMode === "click") {
			this.savedRoute = {
				points: this.markers.map(m => m.getLngLat()),
				route: [...this.route]
			};
			this.markers.forEach(m => m.remove());
			this.markers = [];
			const tmcs = [...this.route];
			this.route = [];
			this.modifyRoute(tmcs);
		}

		this.highlightTmcsOnMap();
		this.forceUpdate();
	}
	modifyRoute(_tmcs) {
		let tmcs = [];
		const tmcSet = new Set([..._tmcs]);
		tmcSet.forEach(tmc => {
			if (this.route.includes(tmc)) {
				this.route = this.route.filter(d => d !== tmc);
			}
			else {
				tmcs.push(tmc);
			}
		})

		if (!tmcs.length) {
			this.highlightTmcsOnMap();
			this.forceUpdate();
			return;
		}

		++this.loading;

		falcorGraph.get(...this.tmcFalcorGet(tmcs))
		.then(res => {
			tmcs = [...this.route, ...tmcs];

			const graph = falcorGraph.getCache().tmc;

			const buildRoute = (route, tmcs, unconnected, retries) => {
				const maxRetries = tmcs.length + unconnected.length;

				const tmc = tmcs.pop();

				let index = route.length ? -1 : 0;

				const upTmcs = get(graph, `[${ tmc }].upstream.byDepth[${ 1 }].value`, []),
					downTmcs = get(graph, `[${ tmc }].downstream.byDepth[${ 1 }].value`, []);

				for (const i in route) {
					const _tmc = route[i];

					if (upTmcs.includes(_tmc)) {
						index = +i + 1;
						break;
					}
					if (downTmcs.includes(_tmc)) {
						index = +i;
						break;
					}
				}

				if ((index === -1) && (retries < maxRetries)) {
					unconnected.push(tmc);
				}
				else if ((index === -1) && (retries >= maxRetries)) {
					route.push(tmc);
				}
				else {
					route.splice(index, 0, tmc);
					retries = 0;
				}

				if (tmcs.length){
					return buildRoute(route, tmcs, unconnected, retries);
				}
				if (unconnected.length) {
					return buildRoute(route, unconnected, [], ++retries);
				}

				return route;
			}
			this.route = buildRoute([], tmcs, [], 0);

			--this.loading;

			this.highlightTmcsOnMap();
			this.forceUpdate();
		})
	}
	highlightTmcsOnMap(map = this.map) {
		if (!map) return;

		if (!this.route.length) {
			map.setPaintProperty(
	  		'npmrds-routing',
	  		'line-color',
	  		["case",["boolean", ["feature-state", "hover"], false],
	  			"#2ca25f",
	  			BASE_ROUTE_LAYER_LINE_COLOR
	  		]
			)
		}
		else {
			const colors = this.route.reduce((a, c) => {
				a[c] = "#4264fb";
				return a;
			}, {})
	    // map.setPaintProperty(
	    //     'npmrds-routing',
	    //     'line-color',
		  // 		["case",
		  // 			["boolean", ["feature-state", "hover"], false],
		  // 			"#2ca25f",
			// 			["get", ["to-string", ["get", "tmc"]], ["literal", colors]]
		  // 		]
	    // );
	  	map.setPaintProperty(
	  		'npmrds-routing',
	  		'line-color',
	  		["case",
	  			["boolean", ["feature-state", "hover"], false],
	  			["match", ["get", "tmc"], [...this.route], "#8da1fd", "#2ca25f"],
	  			["match", ["get", "tmc"], [...this.route], "#4264fb", BASE_ROUTE_LAYER_LINE_COLOR]
	  		]
	  	)
		}
	}

	loadSavedRoute() {
		this.markers = this.savedRoute.points.map(lngLat =>
			new mapboxgl.Marker({ draggable: true })
				.setLngLat(lngLat)
				.addTo(this.map)
		);
		this.route = [...this.savedRoute.route];
		this.highlightTmcsOnMap();
	}

	loadRouteData(map = this.map) {
		if (!this.routeId) return Promise.resolve();

		return falcorGraph.get(
			['routes', 'byId', this.routeId, ['name', 'type', 'owner', 'tmcArray','risArray','conflationIdArray', 'points']]
		)
		.then(res => {
			const routeData =  get(res, `json.routes.byId[${ this.routeId }]`, null),
				tmcArray = get(routeData, "tmcArray", false),
				risArray = get(routeData, "risArray", false),
				conflationIdArray = get(routeData, "conflationIdArray", false);
			if (tmcArray) {
				return falcorGraph.get(...this.tmcFalcorGet(tmcArray))
				.then(res => routeData)
			}
			return routeData;
		})
		.then(routeData => {
			if (routeData) {
				this.route = routeData.tmcArray

				this.route_name = routeData.name;
				this.route_type = routeData.type;
				this.route_owner = routeData.owner;

				routeData.points.forEach(point=>{
					const marker = new mapboxgl.Marker().setLngLat([point.lng, point.lat]).addTo(map);
					this.markers.push(marker)
				})

				if (!this.markers.length) {
					this.creationMode = "click";
					this.mapActions.toggle.disabled = true;
				}

				this.highlightTmcsOnMap(map);
			}
		})
		.then(() => this.infoBoxes.routeList.show = true)
	}
	onAdd(map) {
		super.onAdd(map);

		if (this.routeId) {
			return this.loadRouteData(map);
		}
		else {
			this.route = [];
			this.route_name =  null;
			this.route_type =  null;
			this.route_owner = null;
			this.infoBoxes.routeList.show = true;
		}
	}
	onRemove(map) {
		this.clearMap(false);

		super.onRemove(map);
	}
	onStyleChange(map) {
		super.onStyleChange(map);

		this.highlightTmcsOnMap(map);
	}
	fetchData() {
		if (this.markers.length < 2) return Promise.resolve({ route: [] })
		return falcorGraph.call(
			["routing", "call"],
			this.markers.map(m => [m.getLngLat().lng, m.getLngLat().lat]), [], []
		)
		.then(res => {
			const route = get(res, "json.routing.route", false);
			if (route) {
				return falcorGraph.get(...this.tmcFalcorGet(route))
				.then(() => ({ route }));
			}
			return { route };
		})
	}
	receiveData(map, { route }) {

		if (route) {
			this.route = route;
			this.highlightTmcsOnMap(map);
		}
	}
}

const RouteListHeader = styled.div`
	font-size: 1.5rem;
	font-weight: bold;

	*:nth-child(2) {
		margin-top: 5px;
	}
`

const Button = styled.button`
	background-color: rgb(50, 50, 70);
	color: ${ props => props.theme.textColorHl };
	border-radius: 4px;
	padding: 6px;
	font-size: 1.2em;
	border: none;
	margin: 5px;
	cursor: pointer;
	font-weight: 400;
	width: 46%;

	:hover {
		background-color: rgba(60, 70, 80);
	}
	:disabled {
		cursor: not-allowed;
		background-color: rgb(40, 50, 60);
		color: ${ props => props.theme.textColor };
	}
`

const TmcContainerOuter = styled.div`
	margin: ${ props => props.route ? "5px 0px" : "0px" };
	max-height: 300px;
	border-top: ${ props => props.route ? '1px solid white' : 'none' };
	overflow: hidden;
	padding-top: ${ props => props.route ? 10 : 0 }px;
`
const TmcContainerInner = styled.div`
	${ props => props.theme.scrollBar };
	max-height: 290px;
	overflow: auto;
`
const TmcTable = styled.table`
	tbody > tr.active.tmc {
		cursor: pointer;
	}
	tbody > tr.tmc:hover {
		background-color: rgba(200, 200, 200, 0.25);
	}
	tbody > tr > td {
		border: none;
		padding: 0px 0px 0px 10px;
	}
`

class RouteList extends React.Component {
	render() {
		const { layer } = this.props;

		const loadData = () => {
			layer.doAction(["fetchLayerData"]);
		}
		const removeLast = () => {
			const marker = layer.markers.pop();
			if (marker) {
				marker.remove();
			}
			layer.forceUpdate();
		}
		const points = layer.markers.map(m => ({ ...m.getLngLat() }));

		const data = [];
		let currentRoadname = null,
			currentFirstname = null;

		const cache = falcorGraph.getCache();
		const routeData =  get(cache, `routes.byId[${ layer.routeId }]`, null),
			risArray = get(routeData, "risArray.value", false),
			conflationIdArray = get(routeData, "conflationIdArray.value", false);

		for (const tmc of layer.route) {
			const roadname = get(cache, `tmc.${ tmc }.meta.2019.roadname`, null);
			if (roadname && (roadname !== currentRoadname)) {
				currentRoadname = roadname;
				data.push({ type: "roadname", value: currentRoadname });
			}

			data.push({ type: "tmc", value: tmc });

			const firstname = get(cache, `tmc.${ tmc }.meta.2019.firstname`, null);
			if (firstname && (firstname !== currentFirstname)) {
				currentFirstname = firstname;
				data.push({ type: "firstname", value: currentFirstname });
			}
		}

		return (
			<div>
				<div><i>{ layer.route_name }</i></div>

				{ layer.creationMode === "click" && !layer.route.length ?
					<div style={ { fontSize: "1rem" } }><i>Waiting for input...</i></div> :
				  layer.creationMode === "click" && layer.route.length ? null :
					<div>
						<Button onClick={ loadData }
							disabled={ layer.markers.length < 2 }>
							Calculate Route
						</Button>
						<Button onClick={ removeLast }
							disabled={ !layer.markers.length }>
							Clear Marker
						</Button>
					</div>
				}
				{ layer.loading ? null :
					<TmcContainerOuter route={ Boolean(layer.route.length) }>
						<TmcContainerInner>
							<TmcTable>
								<tbody>
									{ data.map(({ type, value }, i) =>
											<tr key={ `${ value }-${ i }` } className={ classnames(type, { active: layer.creationMode === "click" }) }
												onClick={ () => (layer.creationMode === "click") && (type === "tmc") && layer.modifyRoute([value]) }>
												{ type === "roadname" ?
														<td style={ { fontSize: "1.25rem" } }>{ value }</td> :
													type === "firstname" ?
														<td style={ { paddingLeft: "60px", fontSize: "0.75rem" } }><i>{ value }</i></td> :
														<td style={ { paddingLeft: "20px", fontSize: "1rem" } }>{ value }</td>
												}
											</tr>
										)
									}
								</tbody>
							</TmcTable>
						</TmcContainerInner>
					</TmcContainerOuter>
				}
				{ layer.loading || !layer.route.length ? null :
					<RouteEditor layer={ layer }
						history={ layer.history }
						tmcArray={ layer.route }
						risArray={ risArray }
						conflationIdArray={ conflationIdArray }
						points={ points }
						routeId={ layer.routeId }
						routeInfo={ {
							name: layer.route_name,
							type: layer.route_type,
							owner: layer.route_owner
						} }/>
				}
				{ !layer.loading ? null :
					<div style={ { height: "100px", position: "relative" } }>
						<Loading height="100%" width="100%"/>
					</div>
				}
			</div>
		)
	}
}

const ToggleIcon = ({ layer }) =>
	<span className={ `fa fa-2x fa-${ layer.creationMode === "markers" ? "map-marker" : "hand-pointer-o" }` }/>

const ROUTE_LAYER_PROPERTIES = {
	name: 'Routing',

	markers: [],
	route: [],
	savedRoute: {
		points: [],
		route: []
	},

	routeId: null,
	route_name: null,
	route_type: null,
	route_owner: null,

	creationMode: "markers",
	redirectOnSave: true,

	sources: sources,
  layers: routesLayers,

  // removeableIfSingle: false,

	onClick: {
		layers: ['map', 'npmrds-routing'],
		dataFunc: function(features, point, lngLat, layerName) {
			if ((layerName === 'map') && (this.creationMode === 'markers')) {
				const marker = new mapboxgl.Marker({ draggable: true })
								.setLngLat(lngLat)
								.addTo(this.map);
				this.markers = [...this.markers, marker];
				this.forceUpdate();
			}
			else if ((layerName === 'npmrds-routing') && (this.creationMode === "click")) {
				this.modifyRoute(features.map(({ properties: { tmc } }) => tmc));
			}
		}
	},
	onHover: {
		layers: ['npmrds-routing'],
		minZoom: 9
		// dataFunc: function(features, point, lngLat, layerName) {
		// 	// DO SOME STUFF
		// }
	},
	infoBoxes: {
		routeList: {
			title: ({ layer }) => layer.routeId ? "Edit Route" : "Create Route",
			comp: RouteList,
			show: false
		}
	},
	popover: {
    layers: ['npmrds-routing'],
    dataFunc: (topFeature, features) => ["NPMRDS", ...features.map(({ properties: { tmc } }) => ["TMC", tmc])]
  },
  mapActions: {
		toggle: {
			Icon: ToggleIcon,
			tooltip: "Toggle Creation Mode",
			action: function() {
				if (this.creationMode === "markers") {
					this.doAction([
						"sendMessage",
						{ Message: "You have entered click mode" }
					]);
					this.toggleCreationMode();
				}
				else if (this.creationMode === "click") {
					const tmcSet = new Set(this.savedRoute.route),
						changesMade = this.route.reduce((a, c) => a || !tmcSet.has(c), this.route.length !== this.savedRoute.route.length);

					if (changesMade) {
						this.doAction([
							"sendMessage",
							{ Message: () =>
									<div>
										<div style={ { marginBottom: "5px" } }>
											You are about to leave click mode. All progress made while in "click" mode will be lost.
										</div>
										<div style={ { marginBottom: "10px" } }>
											Any route previously created in "markers" mode will be loaded instead.
										</div>
										<div className="clearfix"><span className="float-right">Do you wish to continue?</span></div>
									</div>,
								onConfirm: () => {
									this.toggleCreationMode();
									if (this.routeId) {
										this.loadRouteData();
									}
									else {
										this.loadSavedRoute();
									}
								},
								id: "route-layer-toggle-confirm"
							}
						])
					}
					else {
						this.doAction([
							"sendMessage",
							{ Message: "You have entered markers mode" }
						]);
						this.loadSavedRoute();
						this.toggleCreationMode();
					}
				}
			}
		},
	}
}

export default new RouteLayer(ROUTE_LAYER_PROPERTIES);

export const RouteLayerFactory = (props={}, merge = false) => {
	if (!merge) return new RouteLayer({ ...clonedeep(ROUTE_LAYER_PROPERTIES), ...props });
	const PROPS = {
		...clonedeep(ROUTE_LAYER_PROPERTIES)
	}
	for (const key in PROPS) {
		if (Array.isArray(PROPS[key]) && (key in props)) {
			PROPS[key] = [ ...PROPS[key], ...props[key]]
			delete props[key];
		}
		else if ((PROPS[key] !== null) && (typeof PROPS[key] === "object") && (key in props)) {
			PROPS[key] = { ...PROPS[key], ...props[key] };
			delete props[key];
		}
	}
	for (const key in props) {
		PROPS[key] = props[key];
	}
	return new RouteLayer(PROPS);
}
