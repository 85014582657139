import MapLayer from "components/AvlMap/MapLayer"

import get from "lodash.get"
import _ from 'lodash'
import { falcorGraph } from "store/falcorGraph"

import {ATTRIBUTES} from "./constants";
import {ConflationSource, ConflationStyle} from "../../conflation/layers/conflation.style";

class IncidentLayer extends MapLayer {

    constructor(options) {
        super("Incident", options);
    }

    receiveProps(oldProps, newProps){
        if ((newProps.incidentId !== this.incidentId) || !_.isEqual(newProps.chainForFalcor, this.chainForFalcor)
            || !_.isEqual(newProps.fullChain, this.fullChain)) {
            this.incidentId = newProps.incidentId;
            this.incidentInfo = newProps.incidentInfo;
            this.incidentConflationInfo = newProps.incidentConflationInfo;
            this.chainForFalcor = newProps.chainForFalcor;
            this.fullChain = newProps.fullChain;
            this.doAction(["fetchLayerData"]);
        }
    }
    onPropsChange(oldProps, newProps){
        if ((newProps.incidentId !== this.incidentId) || !_.isEqual(newProps.chainForFalcor, this.chainForFalcor)
            || !_.isEqual(newProps.fullChain, this.fullChain)) {
            this.incidentId = newProps.incidentId;
            this.incidentInfo = newProps.incidentInfo;
            this.incidentConflationInfo = newProps.incidentConflationInfo;
            this.chainForFalcor = newProps.chainForFalcor;
            this.fullChain = newProps.fullChain;
            this.doAction(["fetchLayerData"]);
        }
    }

    onAdd(map, props) {
        super.onAdd(map);
        if (!this.incidentId) return Promise.resolve();
        return this.fetchData(map)
            .then(d => this.receiveData(map, d))
    }

    fetchData(map) {
        if (!this.incidentId) return Promise.resolve();
        let year = get(this.incidentInfo, `open_time`, '').split(' ')[0].split('-')[0];
        return falcorGraph.get(
            ['tmc', this.chainForFalcor,'year',year,'geometries'],
            ['incidents', this.incidentId, ATTRIBUTES],
            ['conflation','byEventId', this.incidentId, ['id', 'tmc19id', 'ris19id', 'geom']]
        ).then(d => {

            this.map.flyTo({ center: JSON.parse(get(d, `json.incidents.${this.incidentId}.point_geom`, {})).coordinates });
            /*if (this.map.getSource('incidentTMC')){
                this.map.removeLayer('incidentTMCLayer');
                this.map.removeSource('incidentTMC');
            }*/
            /*this.map.addSource('incidentTMC', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features:
                        this.chainForFalcor.length > 0 ?
                            this.chainForFalcor.map(t => {
                                return {
                                    type: 'feature',
                                    properties: {
                                        primary: t === get(d, `json.conflation.byEventId.${this.incidentId}.tmc19id`, {}) ? 'true' : 'false',
                                        tmc: t
                                    },
                                    geometry: get(d, `json.tmc.${t}.year.${year}.geometries`, {})
                                }
                            }) :
                        [
                        {
                            type: 'feature',
                            properties: {primary:'true'},
                            geometry: JSON.parse(get(d, `json.conflation.byEventId.${this.incidentId}.geom`, {}))
                        }
                    ]
                }
            });*/
            /*this.map.addLayer({
                id: 'incidentTMCLayer',
                source: 'incidentTMC',
                type: 'line',
                paint: {
                    "line-color": [
                        'match',
                        ['get', 'primary'],
                        'true',
                        '#ff1912',
                        '#cc6c14'
                    ],
                    "line-width": 7
                }
            });*/

            if (this.map.getSource('incidentPoint')){
                this.map.removeLayer('incidentPointLayer');
                this.map.removeSource('incidentPoint');
            }
            this.map.addSource('incidentPoint', {
                type: 'geojson',
                data: {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: 'feature',
                            geometry: JSON.parse(get(d, `json.incidents.${this.incidentId}.point_geom`, {}))
                        }
                    ]
                }
            });
            this.map.addLayer({
                id: 'incidentPointLayer',
                source: 'incidentPoint',
                type: 'circle',
                paint: {
                    "circle-color": "#ffa019",
                    "circle-radius": 12
                }
            });

/*            let colors = {}
            this.fullChain.forEach(c => this.chainForFalcor.includes(c) ? colors[c] = '#feff22' : '#2eff42')
            this.map.setPaintProperty("conflation", "line-color",
                ["get", ["get", "tmc19id"],['literal',colors]
                ]
            )*/
            if(this.chainForFalcor.length > 0){
                let notActive = this.fullChain.filter(f => !this.chainForFalcor.includes(f))
                notActive.length > 0 ?
                    this.map.setPaintProperty("conflation", "line-color",
                        ["match",
                            ["get", "tmc19id"],
                            notActive,
                            '#2eff42',
                            this.chainForFalcor,
                            '#feff22',
                            'rgba(200, 200, 200, 0.05)'
                        ]
                    ) :
                    this.map.setPaintProperty("conflation", "line-color",
                        ["match",
                            ["get", "tmc19id"],
                            this.chainForFalcor,
                            '#feff22',
                            'rgba(200, 200, 200, 0.05)'
                        ]
                    )
            }
            this.selection = ["120P27846"]
            const tmcs = this.selection,
                date = get(this.incidentInfo, `open_time`, '').split(' ')[0];

            if (!date) return Promise.resolve(null);
            return falcorGraph.get(
                ["tmc", tmcs, "incidents", "byDate", date, "length"]
            )
                .then(res => {
                    let max = 0;
                    tmcs.forEach(tmc => {
                        const length = get(res, `json.tmc[${ tmc }].incidents.byDate[${ date }].length`, 0)
                        max = Math.max(max, length)
                    });

                    return max;
                })
                .then(max => {
                    if (!max) return Promise.resolve(null);
                    return falcorGraph.get(
                        ["tmc", tmcs, "incidents", "byDate", date, "byIndex", { from: 0, to: max - 1 },
                            ["event_type",
                                "event_category",
                                "latitude",
                                "longitude",
                                "open_time",
                                "close_time",
                                "description"]
                        ]
                    )
                        .then(res => ({ data: res.json.tmc, max }))
                })
        });

    }
    receiveData(map, _data) {
        const featureCollection = {
            type: "FeatureCollection",
            features: []
        }
        if (_data === null) {
            /*map.getSource("transcom-source")
                .setData(featureCollection);*/
            return;
        }
        const tmcs = this.selection,
            date = this.filters.date.value,
            category = this.filters.category.value,

            type = this.filters.type.value,
            typeDomain = {},

            { data, max } = _data;

        tmcs.forEach(tmc => {
            const graph = data[tmc].incidents.byDate[date].byIndex;
            for (let i = 0; i < max; ++i) {
                if (graph[i] && (category === "all" || (category === graph[i].event_category))) {

                    typeDomain[graph[i].event_type] = true;

                    if (type === "all" || (type === graph[i].event_type)) {
                        featureCollection.features.push({
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: [graph[i].longitude, graph[i].latitude]
                            },
                            properties: {
                                tmc,
                                category: graph[i].event_category,
                                type: graph[i].event_type,
                                open_time: graph[i].open_time,
                                close_time: graph[i].close_time,
                                description: graph[i].description
                            }
                        })
                    }
                }
            }
        })

        const domain = [{ value: "all", name: "Show All" }]
        Object.keys(typeDomain).forEach(type => {
            domain.push({ value: type, name: type });
        })
        this.filters.type.domain = domain;

        /*map.getSource("transcom-source")
            .setData(featureCollection);*/
    }
}

const incidentLayer = new IncidentLayer({
    name: 'Incident',
    active: true,
    incidentId: null,
    sources: [ConflationSource],
    layers: [{
        ...ConflationStyle
    }],

    filters: {
        date: {
            name: "Date",
            type: "date",
            value: "2017-02-08"
        },
        category: {
            name: "Category",
            type: "dropdown",
            domain: [
                { value: "all", name: "Show All" },
                { value: "accident", name: "Accident" },
                { value: "construction", name: "Construction" },
                { value: "other", name: "Other" }
            ],
            value: "all",
            onChange: (map, layer, value, oldValue) => {
                if (value !== oldValue) {
                    layer.filters.type.value = "all";
                }
            }
        },
        type: {
            name: "Type",
            type: "dropdown",
            domain: [{ value: "all", name: "Show All" }],
            value: "all"
        }
    },
    popover: {
        layers: ["incidentTMCLayer", 'conflation'],
        dataFunc: function(feature) {
            return get(feature, `layer.id`, 'N/A') === 'incidentTMCLayer' ?
                ['Incident', [get(feature, `properties.tmc`, 'N/A')]] :
                ['Conflation',
                    ['ID', get(feature, `properties.id`, 'N/A')],
                    ['TMC', get(feature, `properties.tmc19id`, 'N/A')]
                ] ;
        }
    }
})

export default incidentLayer;