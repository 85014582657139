import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { FalcorProvider } from 'utils/redux-falcor'
import { falcorGraph } from 'store/falcorGraph'

import store, { history } from './store'
import App from './App';

import {
  API_HOST, PROJECT_THEME,
  PROJECT_NAME, CLIENT_HOST, AUTH_HOST
} from 'config'
import get from "lodash.get"

import {
  addComponents,
  addWrappers,
  ThemeContext,
  Themes,
  falcorGraph as falcorGraphNew,
  FalcorProvider as FalcorProviderNew
} from "@availabs/avl-components"

import {
  Components as AmsComponents,
  Wrappers as AmsWrappers,
  enableAuth
} from "@availabs/ams"

// import registerServiceWorker from './registerServiceWorker';
// https://github.com/facebook/create-react-app/issues/2715#issuecomment-313171863
import register from './registerServiceWorker';

addComponents(AmsComponents);
addWrappers(AmsWrappers);

register();

const AuthEnabledApp = enableAuth(App, { AUTH_HOST, PROJECT_NAME, CLIENT_HOST });


ReactDOM.render(
  <Provider store={store}>
    <FalcorProvider store={store} falcor={falcorGraph}>
      <FalcorProviderNew falcor={ falcorGraphNew(API_HOST) }>
        <ConnectedRouter history={history}>
          <ThemeContext.Provider value={ get(Themes, PROJECT_THEME, Themes["light"]) }>
             <AuthEnabledApp />
          </ThemeContext.Provider>
        </ConnectedRouter>
      </FalcorProviderNew>
    </FalcorProvider>
  </Provider>,
  document.getElementById('root')
)
// registerServiceWorker();
