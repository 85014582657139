import React from 'react';

const PHEDAvgVehicleOccupancy = ({ data }) => {
  const { tmcAvgVehicleOccupancy } = data;

  return tmcAvgVehicleOccupancy ? (
    <p>{`${tmcAvgVehicleOccupancy} persons`} </p>
  ) : null;
};

export default PHEDAvgVehicleOccupancy;
