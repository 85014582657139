import { LOCATION_CHANGE } from 'connected-react-router';

import { parseUrl } from '../../urlUtils';

// ------------------------------------
// Constants
// ------------------------------------
import { START_PM3_DATA_LOAD, END_PM3_DATA_LOAD } from './actionCreators';

// -------------------------------------
// Initial State
// -------------------------------------
export const initialState = {
  geography: { geolevel: null, geoid: null },
  measure: null,
  pm3DataLoadIds: new Set(),
  selectedPm3VersionId: null
};

const handlePm3VersionChange = (
  state = initialState,
  { pm3VersionId = null }
) =>
  state.selectedPm3VersionId === pm3VersionId
    ? state
    : { ...state, selectedPm3VersionId: pm3VersionId };

const handleMeasureChange = (state = initialState, { measure = null }) =>
  state.measure === measure ? state : { ...state, measure };

const handleGeographyChange = (
  state = initialState,
  { geolevel = null, geoid = null }
) => {
  const { geography } = state;

  // If geolevel is null, set geoid to null also
  const _geoid = geolevel && geoid;

  const geoLevelChanged = geography.geolevel !== geolevel;
  const geoIdChanged = geography.geoid !== _geoid;

  return geoLevelChanged || geoIdChanged
    ? { ...state, geography: { geolevel, geoid: _geoid } }
    : state;
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOCATION_CHANGE]: (state = initialState, action) => {
    const {
      payload: { location }
    } = action;

    const urlParams = parseUrl(location);

    if (!urlParams) {
      return state;
    }

    let newState = state;

    newState = handlePm3VersionChange(newState, urlParams);
    newState = handleMeasureChange(newState, urlParams);
    newState = handleGeographyChange(newState, urlParams);

    return newState;
  },

  [START_PM3_DATA_LOAD]: (state = initialState, action) => {
    const newState = { ...state };
    newState.pm3DataLoadIds = new Set(state.pm3DataLoadIds);
    newState.pm3DataLoadIds.add(action.payload);
    return newState;
  },

  [END_PM3_DATA_LOAD]: (state = initialState, action) => {
    const newState = { ...state };
    newState.pm3DataLoadIds = new Set(state.pm3DataLoadIds);
    newState.pm3DataLoadIds.delete(action.payload);
    return newState;
  }
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
