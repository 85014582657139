import React from "react"
import { connect } from "react-redux"
import { reduxFalcor } from 'utils/redux-falcor';
import { Link } from 'react-router-dom'

import deepequal from "deep-equal"
import get from "lodash.get"
import styled from "styled-components"

import FolderIcon from "./FolderIcon"
import { FlexContainer } from "./CreateFolderModal"

import { RouteControlBox } from "pages/auth/Routes/routes"
import { ReportControlBox } from "pages/auth/Routes/reports"
import {
  TemplateControlBox,
  RouteSelectModal
} from "pages/auth/Routes/templates"

import {
  replace
} from "pages/auth/Routes/analysis/reports/store";

const StuffTableContainer = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

const StuffTable = ({ type, icon, children }) =>
  <StuffTableContainer>
    <h5><span className={ icon }/> { type }</h5>
    <table className="table table-sm">
      <thead>
        <tr><th colSpan={ 10 }>Name</th></tr>
      </thead>
      <tbody>
        { children }
      </tbody>
    </table>
  </StuffTableContainer>

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: border-color 0.15s, background-color 0.15s;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  :hover {
    border-color: currentColor;
  }
  &.warning:hover {
    background-color: #ffd0d0;
  }
`

const StuffSelector = styled.div`
  > a {
    position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    color: currentColor;
    align-items: center;
    border-radius: 8px;
    border: 2px solid transparent;
    width: 10rem;
    height: 10rem;
    margin: 0.5rem;
    padding-bottom: 2rem;
    transition: border-color 0.15s;
    :link,
    :visited,
    :link:active,
    :link:visited,
    :link:focus {
      border: 2px solid transparent;
      box-shadow: none;
    }
    :hover
    :link:hover,
    :visited:hover,
    :link:active:hover,
    :link:visited:hover,
    :link:focus:hover {
      color: currentColor;
      border-color: currentColor;
      text-decoration: none;
    }
    > span {
      font-size: 5rem;
    }
    > div {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
    }
  }
`
const NoStuff = () =>
  <FlexContainer style={ { justifyContent: "center" } }>
    <FlexContainer style={ { justifyContent: "center" } }>
      <h5>Add Some Stuff</h5>
    </FlexContainer>
    <StuffSelector>
      <Link to="/routes">
        <span className="fa fa-road"/>
        <div>Add Routes</div>
      </Link>
    </StuffSelector>
    <StuffSelector>
      <Link to="/reports">
        <span className="fa fa-file-text"/>
        <div>Add Reports</div>
      </Link>
    </StuffSelector>
    <StuffSelector>
      <Link to="/templates">
        <span className="fa fa-gear"/>
        <div>Add Templates</div>
      </Link>
    </StuffSelector>
  </FlexContainer>

const NO_OP = () => {};

class FolderContents extends React.Component {
  state = {
    confirmRemove: [],
    openModalAs: null,
    template: {},
  }
  componentDidUpdate(oldProps) {
    if (get(oldProps, ["folder", "id"], null) !== get(this.props, ["folder", "id"], null)) {
      this.fetchFalcorDeps();
      this.setState({ confirmRemove: [] });
    }
  }
  fetchFalcorDeps() {
    if (this.props.folder) {
      const { id } = this.props.folder;
      return this.props.falcor.get(
        ["folders", "byId", id, "stuff"],
        ["routes", "length"]
      ).then(res => {
        const stuff = get(res, ["json", "folders", "byId", id, "stuff"], []),
          routes = get(res, ["json", "routes", "length"], 0),
          requests = [];

        stuff.forEach(({ stuff_id, stuff_type }) => {
          switch (stuff_type) {
            case "collection":
              requests.push(
                ["routes", "byId", stuff_id, ["name", "type", "id", "colltype", "tmcArray"]]
              )
              break;
            case "report":
              requests.push(
                ["reports", "byId", stuff_id, ['id', 'title', 'type', 'description', 'created_at', 'updated_at']]
              )
              break;
            case "template":
              requests.push(
                ["templates", "byId", stuff_id, ['id', 'type', 'owner', 'title', 'description', 'routes', 'default_type', 'created_at', 'updated_at']]
              )
              break;
          }
        })
        if (routes) {
          requests.push(
            ["routes", "byIndex", { from: 0, to: routes - 1 }, ['id', 'name']]
          )
        }

        if (requests.length) {
          return this.props.falcor.get(...requests);
        }
      }).then(NO_OP)
    }
    return Promise.resolve();
  }
  removeFromFolder(stuff_id, stuff_type) {
    this.setState({ confirmRemove: [stuff_id, stuff_type] })
  }
  confirmRemove() {
    this.props.falcor.call(
      ["folders", "remove"], [this.props.folder.id, ...this.state.confirmRemove]
    ).then(NO_OP)
    this.setState({ confirmRemove: [] });
  }
  cancelRemove() {
    this.setState({ confirmRemove: [] });
  }

  openModal(openModalAs, template) {
    this.setState({ openModalAs, template });
  }
  closeModal() {
    this.setState({ openModalAs: null, template: {} });
  }

  render() {
    if (!this.props.folder) return null;

    const noStuff = !this.props.routes.length &&
                    !this.props.reports.length &&
                    !this.props.templates.length;

    return (
      <div>
        <FlexContainer style={ { alignItems: "flex-end" } }>
          <FolderIcon { ...this.props.folder } size={ 3 }/>
          <h3 style={ { margin: "0 0 0.2rem 0.5rem" } }>Contents of { this.props.folder.name }</h3>
        </FlexContainer>

        { !noStuff ? null : <NoStuff /> }

        { !this.props.routes.length ? null :
          <StuffTable type="Routes" icon="fa fa-road">
            { this.props.routes.map(route =>
                <tr key={ route.id }>
                  <td style={ { width: "65%" } }>
                    <b>{ route.name }</b><br />
                    <i>{ route.type } route</i>
                  </td>
                  <td style={ { width: "25%" } }>
                    <div style={ { display: "flex", justifyContent: "flex-end" } }>
                      {
                        deepequal(this.state.confirmRemove, [route.id, "collection"]) ?
                          <div style={ { display: "flex", width: "100%", justifyContent: "flex-end" } }>
                            <Button onClick={ e => this.confirmRemove() }
                              style={ { marginRight: "2%", width: "49%" } }
                              className="warning">
                              <div style={ { marginRight: "0.25rem" } }><span className="fa fa-lg fa-remove"/></div>
                              <div style={ { marginTop: "0.1rem" } }>Remove</div>
                            </Button>
                            <Button onClick={ e => this.cancelRemove() }
                              style={ { width: "49%" } }>
                              Cancel
                            </Button>
                          </div>
                        :
                          <Button onClick={ e => this.removeFromFolder(route.id, "collection") }
                            style={ { width: "49%" } }
                            className="warning">
                            <div style={ { marginRight: "0.25rem" } }><span className="fa fa-lg fa-remove"/></div>
                            <div style={ { marginTop: "0.1rem" } }>Remove</div>
                          </Button>
                      }
                    </div>
                  </td>
                  <td style={ { width: "10%" } }>
                    <RouteControlBox id={ route.id } colltype={ route.colltype }/>
                  </td>
                </tr>
              )
            }
          </StuffTable>
        }

        { !this.props.reports.length ? null :
          <StuffTable type="Reports" icon="fa fa-file-text">
            { this.props.reports.map(report =>
                <tr key={ report.id }>
                  <td style={ { width: "65%" } }>
                    <b>{ report.title }</b>
                    { report.description === "" ? null :
                      <>
                        <br />
                        <i>{ report.description }</i>
                      </>
                    }
                    <br />
                    <i>Updated: { new Date(report.updated_at).toLocaleString() }</i>
                  </td>
                  <td style={ { width: "25%" } }>
                    <div style={ { display: "flex", justifyContent: "flex-end" } }>
                      {
                        deepequal(this.state.confirmRemove, [report.id, "report"]) ?
                          <div style={ { display: "flex", width: "100%", justifyContent: "flex-end" } }>
                            <Button onClick={ e => this.confirmRemove() }
                              style={ { marginRight: "2%", width: "49%" } }
                              className="warning">
                              <div style={ { marginRight: "0.2rem" } }><span className="fa fa-lg fa-remove"/></div>
                              Remove
                            </Button>
                            <Button onClick={ e => this.cancelRemove() }
                              style={ { width: "49%" } }>
                              Cancel
                            </Button>
                          </div>
                        :
                          <Button onClick={ e => this.removeFromFolder(report.id, "report") }
                            style={ { width: "49%" } }
                            className="warning">
                            <div style={ { marginRight: "0.2rem" } }><span className="fa fa-lg fa-remove"/></div>
                            <div>Remove</div>
                          </Button>
                      }
                    </div>
                  </td>
                  <td style={ { width: "10%" } }>
                    <ReportControlBox id={ report.id }/>
                  </td>
                </tr>
              )
            }
          </StuffTable>
        }

        { !this.props.templates.length ? null :
          <>
            <StuffTable type="Templates" icon="fa fa-gear">
              { this.props.templates.map(template =>
                  <tr key={ template.id }
                    style={ {
                      backgroundColor: template.default_type !== 'none' ? "#efefef" : "transparent"
                    } }>
                    <td style={ { width: "65%" } }>
                      <b>{ template.title }</b>
                      <br />
                      <i>Updated: { new Date(template.updated_at).toLocaleString() }</i>
                      { template.description === "" ? null :
                        <>
                          <br />
                          <i>{ template.description }</i>
                        </>
                      }
                      { template.default_type === "none" ? null :
                        <>
                          <br />
                          <i>Default: { template.default_type }</i>
                        </>
                      }
                    </td>
                    <td style={ { width: "25%" } }>
                      <div style={ { display: "flex", justifyContent: "flex-end" } }>
                        {
                          deepequal(this.state.confirmRemove, [template.id, "template"]) ?
                            <div style={ { display: "flex", width: "100%", justifyContent: "flex-end" } }>
                              <Button onClick={ e => this.confirmRemove() }
                                style={ { marginRight: "2%", width: "49%" } }
                                className="warning">
                                <div style={ { marginRight: "0.2rem" } }><span className="fa fa-lg fa-remove"/></div>
                                Remove
                              </Button>
                              <Button onClick={ e => this.cancelRemove() }
                                style={ { width: "49%" } }>
                                Cancel
                              </Button>
                            </div>
                          :
                            <Button onClick={ e => this.removeFromFolder(template.id, "template") }
                              style={ { width: "49%" } }
                              className="warning">
                              <div style={ { marginRight: "0.2rem" } }><span className="fa fa-lg fa-remove"/></div>
                              <div>Remove</div>
                            </Button>
                        }
                      </div>
                    </td>
                    <td style={ { width: "10%" } }>
                      <TemplateControlBox
                        template={ template }
                        openModal={ (...args) => this.openModal(...args) }/>
                    </td>
                  </tr>
                )
              }
            </StuffTable>

            <RouteSelectModal
              openModalAs={ this.state.openModalAs }
              template={ this.state.template }
              routes={ this.props.userRoutes }
              onHide={ e => this.closeModal() }/>
          </>
        }

      </div>
    )
  }
}
const mapStateToProps = (state, props) => ({
  userRoutes: getRoutesFromState(state),
  routes: getRoutes(state, props),
  reports: getReports(state, props),
  templates: getTemplates(state, props)
})

export default connect(mapStateToProps, null)(reduxFalcor(FolderContents));

const getRoutesFromState = state => {
  const length = get(state, 'graph.routes.length', 0),
    ids = [];
  for (let i = 0; i < length; ++i) {
    const id = get(state, `graph.routes.byIndex[${ i }].value[2]`, null);
    if (id !== null) {
      ids.push(id);
    }
  }
  return ids.map(id => ({
    id,
    name: get(state, `graph.routes.byId[${ id }].name`, false)
  }))
  .filter(({ name }) => Boolean(name));
}

const getStuff = (state, id, stuff_type) =>
  get(state, ["graph", "folders", "byId", id, "stuff", "value"], [])
    .filter(stuff => stuff.stuff_type === stuff_type);

const processData = data => {
  for (const key in data) {
    data[key] = get(data, [key, "value"], data[key]);
  }
  return data;
}

const getRoutes = (state, props) => {
  const { folder } = props;

  if (folder) {
    const { id } = folder;
    return getStuff(state, id, "collection")
      .reduce((a, c) => {
        const data = get(state, ["graph", "routes", "byId", c.stuff_id], null);
        data && a.push(processData(data));
        return a;
      }, [])
  }
  return [];
}

const getReports = (state, props) => {
  const { folder } = props;

  if (folder) {
    const { id } = folder;
    return getStuff(state, id, "report")
      .reduce((a, c) => {
        const data = get(state, ["graph", "reports", "byId", c.stuff_id], null);
        data && a.push(processData(data));
        return a;
      }, [])
  }
  return [];
}

const getTemplates = (state, props) => {
  const { folder } = props;

  if (folder) {
    const { id } = folder,
      { yearsWithData } = state.report,
      mostRecent = Math.max(...yearsWithData);

    return getStuff(state, id, "template")
      .reduce((a, c) => {
        const data = get(state, ["graph", "templates", "byId", c.stuff_id], null);
        data && a.push(processData(data));
        return a;
      }, [])
      .map(template => ({
        ...template,
        title: replace(template.title, mostRecent),
        description: replace(template.description, mostRecent)
      }))
  }
  return [];
}
