import React from "react"
import { Link } from 'react-router-dom'

import LandingNav from 'pages/Landing/components/LandingNav'

export default ({ email, verify, update, canSubmit, handleSubmit }) =>
  <div style={{
    height:'100vh',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to bottom right,rgb(110, 176, 194) 0px, rgb(63, 174, 205) 39%, rgb(52, 149, 176) 100%)'}}
  >
    <LandingNav />
    <div style={{height: '100%',
      display: 'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems: 'flex-start' }}>
    	<div className="auth-box-w" style={{
        width: '33%',
        minWidth: '350px',
        maxWidth: '650px'
      }}>

      <h4 className="auth-header" style={{paddingTop: 20}}>NPMRDS Sign Up</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor='email'>Email</label>
          <input
            id="email"
            autoFocus
            type="email"

            value={email}
            onChange={e => update({ email: e.target.value })}
            className="login-form-control"
            placeholder="Enter your email"
          />
          <div className="pre-icon os-icon os-icon-email-2-at2" />
        </div>
        <div className="form-group">
          <label htmlFor='email_verify'>Verify Email</label>
          <input
            value={verify}
            onChange={e => update({ verify: e.target.value })}
            id="email_verify"
            type="email"
            className="login-form-control"
            placeholder="Verify your email"
          />
          <div className="pre-icon os-icon os-icon-email-2-at2" />
        </div>
        <div className="buttons-w">
          <button className="btn btn-primary btn-lg btn-block" disabled={!canSubmit}>Sign Up</button>
        </div>
      </form>
      <div style={{padding: 15, float: 'right'}}>
        <Link to={'/auth/login'}>Login</Link>
      </div>
      </div>
    </div>
  </div>
