// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import { precisionRound } from 'utils/calculators/MathUtils';

const hourLabels = [...Array(24)].map(
  (_, hr) => `${hr % 12 || 12} ${hr < 12 ? 'am' : 'pm'}`
);

const getViz = (speedsByHour, atriFreeFlowSpeed) => {
  if (!speedsByHour) {
    return null;
  }

  const ff = precisionRound(atriFreeFlowSpeed);

  const data = [
    {
      type: 'violin',
      name: hourLabels[0],
      ids: speedsByHour[0].map((_, i) => `${i}`),
      hoveron: 'violins+points',
      x: speedsByHour[0].map(() => hourLabels[0]),
      y: speedsByHour[0].map(s => precisionRound(s, 2)),
      points: 'none',
      box: {
        visible: true
      },
      line: {
        color: 'green'
      },
      meanline: {
        visible: true
      }
    }
  ];

  const annotations = [
    {
      text: `Free-Flow (${ff} mph)`,
      xref: 'paper',
      x: 1,
      yref: 'y',
      y: ff
    }
  ];

  const shapes = [
    {
      type: 'line',
      xref: 'paper',
      x0: 0,
      x1: 1,
      yref: 'y',
      y0: ff,
      y1: ff
    }
  ];

  const frames = speedsByHour.map((speeds, hr) => ({
    name: hourLabels[hr],
    data: [
      {
        y: speeds.map(s => precisionRound(s, 2)),
        x: speeds.map(() => hourLabels[hr]),
        id: speeds.map((_, i) => `${i}`)
      }
    ]
  }));

  var sliderSteps = speedsByHour.map((_, hr) => ({
    method: 'animate',
    label: hourLabels[hr],
    args: [
      [hourLabels[hr]],
      {
        mode: 'immediate',
        transition: { duration: 300 },
        frame: { duration: 300, redraw: true }
      }
    ]
  }));

  const layout = {
    yaxis: {
      title: 'Speed',
      zeroline: false
    },
    hovermode: 'closest',
    updatemenus: [
      {
        x: 0,
        y: 0,
        yanchor: 'top',
        xanchor: 'left',
        showactive: false,
        direction: 'left',
        type: 'buttons',
        pad: { t: 87, r: 10 },
        buttons: [
          {
            method: 'animate',
            args: [
              null,
              {
                mode: 'immediate',
                fromcurrent: true,
                transition: { duration: 300 },
                frame: { duration: 500, redraw: true }
              }
            ],
            label: 'Play'
          },
          {
            method: 'animate',
            args: [
              [null],
              {
                mode: 'immediate',
                transition: { duration: 0 },
                frame: { duration: 0, redraw: false }
              }
            ],
            label: 'Pause'
          }
        ]
      }
    ],
    sliders: [
      {
        pad: { l: 130, t: 55 },
        currentvalue: {
          visible: true,
          prefix: 'Hour:',
          xanchor: 'right',
          font: { size: 20, color: '#666' }
        },
        steps: sliderSteps
      }
    ],
    shapes,
    annotations
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '62%', height: 600 }}
      data={data}
      layout={layout}
      frames={frames}
      config={config}
    />
  );
};

const ATRIHourlyMeanSpeeds = ({ data }) => {
  const { speedsByHour, atriFreeFlowSpeed } = data;

  return speedsByHour ? (
    <div style={{ width: '100%', color: '#333' }}>
      {getViz(speedsByHour, atriFreeFlowSpeed)}
    </div>
  ) : null;
};

export default ATRIHourlyMeanSpeeds;
