import React from "react";
import get from "lodash.get";
import { AvlMap } from "modules/avl-map/src";
import { MAPBOX_TOKEN } from "store/config";
import { ThemeContext } from "@availabs/avl-components";
import { TransitLayerFactory } from "./layers/Transit";

import { baseMaps } from "./map-styles/basemaps";

const theme = {
  menuBg: "bg-npmrds-600",
  bg: "bg-npmrds-600",
  sidebarBg: "bg-npmrds-800",
  accent1: "bg-npmrds-600",
  accent2: "bg-cool-gray-800",
  accent3: "bg-cool-gray-700",
  accent4: "bg-cool-gray-500",
  menuText: "text-npmrds-100",
  rounded: "",

  inputBg: "bg-npmrds-800 hover:bg-cool-gray-700",
  inputBgFocus: "bg-cool-gray-700",
  itemText: "text-npmrds-100 hover:text-white text-sm",
  menuBgActive: "bg-transparent",

  input: "bg-npmrds-600 w-full text-npmrds-100 text-sm p-2",
};

const Map = () => {
  return (
    <div className="h-screen  h-full flex-1 flex flex-col text-white">
      <ThemeContext.Provider value={theme}>
        <AvlMap
          accessToken={MAPBOX_TOKEN}
          mapOptions={{
            zoom: 6.6,
            styles: baseMaps,
          }}
          layers={[TransitLayerFactory()]}
          showSidebar={false}
          sidebar={{
            title: "Average Day Bus Trips",
            tabs: ["layers", "styles"],
            open: true,
          }}
        />
      </ThemeContext.Provider>
    </div>
  );
};

const AdminMapPage = {
  icon: "fa-bus",
  class: "fa",
  path: "/transit",
  exact: true,
  mainNav: true,
  menuSettings: {
    display: "none",
    image: "none",
    scheme: "color-scheme-dark",
    position: "menu-position-side",
    layout: "menu-layout-mini",
    style: "color-style-default",
  },
  name: "Transit",
  //authLevel: 0,
  component: Map,
};

export default AdminMapPage;
