import React from "react"
import { connect } from "react-redux"
import { reduxFalcor } from 'utils/redux-falcor';

import get from "lodash.get"
import styled from "styled-components"

import CreateFolderModal, { FlexContainer } from "./components/CreateFolderModal"
import FolderIcon from "./components/FolderIcon"
import FolderContents from "./components/FolderContents"

import AvlModal from "components/AvlStuff/AvlModal"

import { Tooltip } from "components/common/styled-components"

export const foldersFalcorRequest = length =>
  ["folders", "byIndex", { from: 0, to: length - 1 }, ["id", "name", "owner", "type", "icon", "color", "stuff"]]

class MyStuff extends React.Component {
  state = {
    showCreateFolderModal: false,
    openedFolder: null,
    editFolder: null,
    toDelete: null
  }
  toggleCreateFolderModal() {
    const show = !this.state.showCreateFolderModal;
    this.setState({ showCreateFolderModal: show });
    if (!show) {
      this.setState({ editFolder: null });
    }
  }
  fetchFalcorDeps() {
    return this.props.falcor.get(
      ["folders", "length"]
    ).then(res => {
      const folders = get(res, ["json", "folders", "length"], 0);

      if (folders) {
        return this.props.falcor.get(foldersFalcorRequest(folders));
      }
    })
  }
  createFolder(args) {
    return this.props.falcor.call(
      ["folders", "create"], args
    );
  }
  updateFolder(args) {
    const jsonGraph = {
      folders: {
        byId: {
          [args.id]: { ...args }
        }
      }
    }
    return this.props.falcor.set({
      paths: [["folders", "byId", args.id, Object.keys(args)]],
      jsonGraph
    });
  }
  openFolder({ id }) {
    this.setState({ openedFolder: id });
  }
  editFolder(folder) {
    const { stuff, ...rest } = folder;
    this.setState({
      editFolder: { ...rest },
      showCreateFolderModal: true
    });
  }
  confirmDelete(toDelete) {
    this.setState({ toDelete });
  }
  deleteFolder() {
    return this.props.falcor.call(
      ["folders", "delete"], [this.state.toDelete.id]
    );
  }
  closeDeleteModal() {
    this.setState({ toDelete: null });
  }
  mapFolders(folders, type) {
    return !folders.length ? null : (
      <>
        <h5>{ type } Folders</h5>
        <FlexContainer>
          { folders.map(f =>
              <FolderContainer key={ f.id }
                onClick={ e => this.openFolder(f) }
                className={ this.state.openedFolder == f.id ? "selected" : null }>
                <FolderIcon icon={ f.icon } color={ f.color } size={ 8 }/>
                <div className="folder-text">{ f.name }</div>
                <RemoveIcon id={ f.id }
                  onClick={ e => { e.stopPropagation(); this.confirmDelete(f); } }/>
                <EditIcon id={ f.id }
                  onClick={ e => { e.stopPropagation(); this.editFolder(f); } }/>
              </FolderContainer>
            )
          }
        </FlexContainer>
      </>
    )
  }
  render() {
    const groupFolders = this.props.folders.filter(({ type }) => type === "group"),
      personelFolders = this.props.folders.filter(({ type }) => type === "personal");

    return (
      <div className='container' style={ { marginTop: "30px", marginBottom: "50px" } }>
        <div className="clearfix mb-2">
          <div className='fill-red dot pad1 contain fa fa-lg fa-folder icon-white inline'/>
          <h3 className='inline' style={{paddingLeft: '6px', marginTop: 10, verticalAlign: 'middle' }}>My Stuff</h3>
          <button className="btn btn-outline-danger btn-lg float-right ml-1"
            style={ { marginTop: "0.1rem" } }
            onClick={ e => this.toggleCreateFolderModal() }>
            Create New Folder
          </button>
        </div>

        { this.mapFolders(personelFolders, "Personal") }

        { this.mapFolders(groupFolders, "Group") }

        <FolderContents folder={ this.props.folders.find(f => f.id === this.state.openedFolder) }/>

        <AvlModal show={ Boolean(this.state.toDelete) }
          onHide={ e => this.closeDeleteModal() }
          closeLabel="Cancel"
          actions={ [
            { label: "Delete",
              type: "success",
              action: e => this.deleteFolder() }
          ] }>
          <div style={ { width: "600px" } }>
            <div style={ { fontWeight: "bold", fontSize: "2rem" } }>
              { get(this.state, ["toDelete", "name"], null) }
            </div>
            Are you sure you wish to delete this folder?
          </div>
        </AvlModal>

        <CreateFolderModal editFolder={ this.state.editFolder }
          show={ this.state.showCreateFolderModal }
          onHide={ e => this.toggleCreateFolderModal() }
          createFolder={ args => this.createFolder(args) }
          updateFolder={ args => this.updateFolder(args) }
          user={ this.props.user }/>

      </div>
    )
  }
}

const FolderContainer = styled.div`
  position: relative;
  margin: 5px;
  transition: border-color 0.15s, transform 0.15s;
  border: 2px solid transparent;
  border-radius: 8px;
  width: 10rem;
  height: 10rem;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.selected,
  :hover {
    border-color: #303030;
  }
  .folder-text {
    position: absolute;
    top: 6.75rem;
    text-align: center;
    width: 9rem;
    height: 3rem;
    line-height: 1rem;
    white-space: normal;
    overflow: hidden;
  }
  .folder-remove {
    display: none;
  }
  :hover .folder-remove {
    display: flex;
  }
`
const StyledIcon = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #303030;
  border-radius: 8px;
  transition: color 0.15s, background-color 0.15s;
  cursor: pointer;
  :hover {
    color: #f2f4f8;
  }
  > span {
    font-size: 1.5rem;
  }
`
const StyledRemoveIcon = styled(StyledIcon)`
  top: 0px;
  right: 0px;
  padding: 0px 0px 0.15rem 0.15rem;
  :hover {
    background-color: #e75e39;
  }
  ${ Tooltip } {
    background-color: #e75e39 !important;
    color: #f2f4f8 !important;
  }
  ${ Tooltip }:after {
    border-top-color: #e75e39 !important;
  }
`
const RemoveIcon = ({ id, ...props }) =>
  <StyledRemoveIcon { ...props }
    data-tip data-for={ `remove-${ id }` }
    className="folder-remove">
    <span className="fa fa-remove"/>
    <Tooltip id={ `remove-${ id }` }
      effect="solid"
      type="info"
      place="top"
      delayShow={ 500 }>
      Delete
    </Tooltip>
  </StyledRemoveIcon>
const StyledEditIcon = styled(StyledIcon)`
  top: 0px;
  left: 0px;
  padding-bottom: 0.15rem;
  :hover {
    background-color: #54bb75;
  }
  ${ Tooltip } {
    background-color: #54bb75 !important;
    color: #f2f4f8 !important;
  }
  ${ Tooltip }:after {
    border-top-color: #54bb75 !important;
  }
`
const EditIcon = ({ id, ...props }) =>
  <StyledEditIcon { ...props }
    data-tip data-for={ `edit-${ id }` }
    className="folder-remove">
    <span className="fa fa-pencil"/>
    <Tooltip id={ `edit-${ id }` }
      effect="solid"
      place="top"
      delayShow={ 500 }>
      Edit
    </Tooltip>
  </StyledEditIcon>

const mapStateToProps = state => ({
  user: state.user,
  folders: getFoldersFromState(state)
})
const mapDispatchToProps = {

}
const component = connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(MyStuff))

function has(stuff_type, stuff_id) {
  return this.stuff.reduce((a, c) =>
    a || (c.stuff_type === stuff_type && c.stuff_id === stuff_id)
  , false)
}

export const getFoldersFromState = state => {
  const folders = [],
    length = get(state, ["graph", "folders", "length"], 0);
  for (let i = 0; i < length; ++i) {
    const id = get(state, ["graph", "folders", "byIndex", i, "value", 2], null);
    if (id !== null) {
      folders.push(get(state, ["graph", "folders", "byId", id], null));
    }
  }
  return folders.filter(Boolean)
    .map(({ stuff, ...rest }) => ({ stuff: get(stuff, "value", []), ...rest }))
    .map(folder => ({ ...folder, has: has.bind(folder) }));
}

export default {
	icon: 'fa-folder',
	path: '/my-stuff',
	name: 'My Stuff',
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  class: 'fa',
	mainNav: true,
  auth: true,
  exact: true,
  component
}
