import React from 'react';

import AvlModal from 'components/AvlStuff/AvlModal';

import Pm3VersionIdExplanation from './Pm3VersionIdExplanation';

export default ({ show, onHide }) => (
  <AvlModal show={show} onHide={onHide}>
    <Pm3VersionIdExplanation />{' '}
  </AvlModal>
);
