import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ArrowRight} from 'components/common/icons';

import onClickOutside from 'react-onclickoutside';

import get from "lodash.get"

const StyledSidePanelContainer = styled.div`
  height: 100%;
  width: ${ props => Boolean(props.isOpen) ? props.width * props.isOpen : 50 }px;
  transition: width 250ms;
  display: flex;
  position: fixed;
  z-index: 5000;
  left: 60px;
  top: 0px;
`

const SideBarContainer = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: left 250ms, right 250ms;
  left: ${props => props.left}px;
  align-items: stretch;
  flex-grow: 1;
`;

const SideBarInner = styled.div`
  background-color: ${props => props.theme.sidePanelBg};
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: ${ props => props.isOpen ? props.width : 0 }px;
  transition: width 250ms;
  white-space: nowrap;
  overflow-x: ${ props => props.hideInner ? "hidden" : "visible" };
`;

const CollapseButton = styled.div`
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  justify-content: center;
  background-color: ${props => props.theme.sideBarCloseBtnBgd};
  border-radius: 1px;
  color: ${props => props.theme.sideBarCloseBtnColor};
  display: flex;
  height: 20px;
  position: absolute;
  right: -5px;
  top: 5px;
  width: 20px;

  :hover {
    cursor: pointer;
    box-shadow: none;
    background-color: ${props => props.theme.sideBarCloseBtnBgdHover};
  }
`;

const SidebarExtension = styled.div`
  background-color: ${props => props.theme.sidePanelBg};
  width: ${ props => props.isOpen ? props.width : 0 }px;
  transition: width 250ms;
  height: 100%;
  position: absolute;
  top: 0px;
  left: ${ props => props.width }px;
  white-space: nowrap;
  overflow-x: ${ props => props.hideExtended ? "hidden" : "visible" };
`

class SideBar extends Component {
  static defaultProps = {
    width: 300,
    minifiedWidth: 0,
    isOpen: 1,
    onOpenOrClose: () => {},
    extendedComp: null
  };

  state = {
    transitioning: false,
    from: null,
    to: null
  };
  componentDidUpdate(oldProps, oldState) {
    if (oldProps.isOpen !== this.props.isOpen) {
      this.setState({
        transitioning: true,
        from: oldProps.isOpen,
        to: this.props.isOpen
      });
      setTimeout(() => this.setState({ transitioning: false }), 250);
    }
  }

  static propTypes = {
    width: PropTypes.number,
    isOpen: PropTypes.number,
    minifiedWidth: PropTypes.number,
    onOpenOrClose: PropTypes.func
  };

  _onOpenOrClose = () => {
    const openOrClose = this.props.isOpen === 0 ? 1 : -1;
    this.props.onOpenOrClose(openOrClose);
  };

  handleClickOutside(e) {
    if (this.props.isOpen == 2) {
     this.props.onOpenOrClose(-1);
    }
  }

  render() {
    const { isOpen, minifiedWidth, width, theme } = this.props;
    const horizontalOffset = isOpen ? 0 : (minifiedWidth - width);

    const {
      transitioning,
      to,
      from
    } = this.state

    const hideInner = (isOpen < 1) || (transitioning && (to === 1) && (from === 0));

    const hideExtended = isOpen < 2 || (transitioning && (to === 2));

    return (
      <StyledSidePanelContainer
        isOpen={ isOpen }
        width={ width }
        className="side-panel--container">

        <SideBarContainer theme={ theme }
          className="side-bar"
          style={ { width: `${ width }px` } }
          left={ horizontalOffset }>

            <SideBarInner theme={ theme }
              className="side-bar__inner"
              width={ width }
              isOpen={ isOpen > 0 }
              hideInner={ hideInner }>

              <div style={ { width: `100%` } }>
                { this.props.children }
              </div>

            </SideBarInner>

            <SidebarExtension
              width={ width }
              isOpen={ isOpen == 2 }
              hideExtended={ hideExtended }>

              <div style={ { width: `${ width }px` } }>
                { this.props.extendedComp }
              </div>

            </SidebarExtension>

          <CollapseButton
            className="side-bar__close"
            onClick={ e => { e.stopPropagation(); this._onOpenOrClose(); } }>

            <ArrowRight
              height="12px"
              style={ { transform: `rotate(${isOpen ? 180 : 0}deg)` } }/>
          </CollapseButton>

        </SideBarContainer>
      </StyledSidePanelContainer>
    );
  }
}

export default onClickOutside(SideBar)
