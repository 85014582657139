import React from "react"
import get from "lodash.get";
import styled from "styled-components"

export const StyledControlBox = styled.div`
  display: flex;
  justify-content: flex-end;

  .control-box {
    padding: 0px 10px;
    z-index: 50;
    display: none;
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f8f8f8;
    box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.25);
    ul {
      padding: 10px 0px;
      margin: 0;
      li > * {
        cursor: pointer;
        padding: 0px 20px;
        white-space: nowrap;
        color: currentColor;
        border: 2px solid transparent;
        transition: border-color 0.15s;
        border-radius: 4px;
        :hover {
          border-color: currentColor;
        }
        text-decoration: none;
        display: flex;
        align-items: center;
        span {
          margin-right: 0.5rem;
          margin-bottom: 0.1rem;
        }
      }
    }
  }
  > div.control-selector {
    display: inline-block;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
  }
  > div.control-selector:hover .control-box {
    display: block;
  }
`
export const ControlBox = ({ children }) =>
  <StyledControlBox>
    <div className="control-selector">
      <span className="fa fa-lg fa-list"/>
      { children }
    </div>
  </StyledControlBox>

const StyledDropLeft = styled.div`
  position: relative;
  z-index: 50;
`
const StyledDropLeftList = styled.div`
  position: absolute;
  background-color: #f8f8f8;
  top: 50%;
  right: calc(100% - 5px);
  padding: 10px;
  box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.25);
`
export const FolderContainer = styled.div`
  display: flex;
  color: currentColor;
  align-items: center;
  max-width: 200px;
  overflow: hidden;
  padding: 0px 5px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: border-color 0.15s;
  :hover {
    border-color: currentColor;
  }
`
export class DropLeft extends React.Component {
  dropdown = null;
  dropdownList = null;
  state = {
    height: 0,
    hovered: false
  }
  componentDidMount() {
    this.setHeight();
  }
  componentDidUpdate() {
    this.setHeight();
  }
  setHeight() {
    if (!this.dropdownList) return;

    const height = this.dropdownList.clientHeight;

    if (height !== this.state.height) {
      this.setState({ height });
    }
  }
  getPosition() {
    if (!this.state.height) return "-50%";

    const rect = this.dropdownList.getBoundingClientRect();

    if (rect.bottom > window.innerHeight) {
      const shift = rect.bottom - window.innerHeight + 10,
        percent = 100 * shift / rect.height;
      return `${ -50 - percent }%`;
    }

    return "-50%"
  }
  render() {
    const children = React.Children.toArray(this.props.children)
      .sort((a, b) => {
        const aIsList = get(a, ["props", "className"], null) === "dropdown-list",
          bIsList = get(b, ["props", "className"], null) === "dropdown-list";
        return aIsList ? 1 : bIsList ? -1 : 0;
      })
    return (
      <StyledDropLeft ref={ comp => this.dropdown = comp }
        onMouseOver={ e => this.setState({ hovered: true }) }
        onMouseOut={ e => this.setState({ hovered: false }) }>
        { children.slice(0, children.length - 1) }
        <StyledDropLeftList ref={ comp => this.dropdownList = comp }
          style={ {
            display: this.state.hovered ? "block" : "none",
            transform: `translateY(${ this.getPosition() })`
          } }>
          { children.slice(children.length - 1) }
        </StyledDropLeftList>
      </StyledDropLeft>
    )
  }
}
