// https://plot.ly/javascript/webgl-vs-svg/

import React, { Component } from 'react';
import Plot from 'react-plotly.js';

import Select from 'react-select';

import { precisionRound } from 'utils/calculators/MathUtils';
import BinnedTimeUtils from 'utils/calculators/BinnedTimeUtils';
import { daysOfWeek } from 'utils/constants';

const getLink = id => (
  <a
    href={`https://simplestatistics.org/docs/#${id}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    link
  </a>
);

const statDescriptions = {
  mean: (
    <p>
      The mean, also known as average, is the sum of all values over the number
      of values. This is a measure of central tendency: a method of finding a
      typical or central value of a set of numbers. {getLink('mean')}
    </p>
  ),
  mode: (
    <p>
      The mode is the number that appears in a list the highest number of times.
      There can be multiple modes in a list: in the event of a tie, this
      algorithm will return the most recently seen mode. This is a measure of
      central tendency: a method of finding a typical or central value of a set
      of numbers. {getLink('mode')}
    </p>
  ),
  median: (
    <p>
      The median is the middle number of a list. This is often a good indicator
      of 'the middle' when there are outliers that skew the mean() value. This
      is a measure of central tendency: a method of finding a typical or central
      value of a set of numbers. {getLink('median')}
    </p>
  ),
  harmonicMean: (
    <p>
      The Harmonic Mean is a mean function typically used to find the average of
      rates. This mean is calculated by taking the reciprocal of the arithmetic
      mean of the reciprocals of the input numbers. This is a measure of central
      tendency: a method of finding a typical or central value of a set of
      numbers. {getLink('harmonicmean')}
    </p>
  ),
  skewness: (
    <p>
      Skewness is a measure of the extent to which a probability distribution of
      a real-valued random variable "leans" to one side of the mean. The
      skewness value can be positive or negative, or even undefined.
      Implementation is based on the adjusted Fisher-Pearson standardized moment
      coefficient, which is the version found in Excel and several statistical
      packages including Minitab, SAS and SPSS.
      {getLink('sampleskewness')}
    </p>
  ),
  variance: (
    <p>
      The variance is the sum of squared deviations from the mean.{' '}
      {getLink('variance')}
    </p>
  ),
  standardDeviation: (
    <p>
      The standard deviation is the square root of the variance. This is also
      known as the population standard deviation. It's useful for measuring the
      amount of variation or dispersion in a set of values.
      {getLink('standarddeviation')}
    </p>
  ),
  medianAbsoluteDeviation: (
    <p>
      The Median Absolute Deviation is a robust measure of statistical
      dispersion. It is more resilient to outliers than the standard deviation.
      {getLink('medianabsolutedeviation')}
    </p>
  ),
  interquartileRange: (
    <p>
      The Interquartile range is a measure of statistical dispersion, or how
      scattered, spread, or concentrated a distribution is. It's computed as the
      difference between the third quartile and first quartile.{' '}
      {getLink('interquartilerange')}
    </p>
  )
};

const formatData = (statsByBinByDOW, stat, metric, binMinutes) => {
  if (!statsByBinByDOW) {
    return null;
  }

  const k = metric === 'SPEED' ? 'speed' : 'tt';

  const d = statsByBinByDOW.map(binsArr =>
    binsArr.map(({ [stat]: { [k]: v } }) => precisionRound(v, 3))
  );

  // each time bin gets an array of dows.
  const z = [...Array(d[0].length)].map(() => Array(7));
  for (let binNum = 0; binNum < z.length; ++binNum) {
    for (let dow = 0; dow < 7; ++dow) {
      z[binNum][dow] = d[dow][binNum];
    }
  }

  const x = daysOfWeek;
  const y = z.map((_, i) => BinnedTimeUtils.binNumToTime(binMinutes, i));

  return { x, y, z };
};

const getViz = ({ statsByBinByDOW, metric, stat, binMinutes }) => {
  const { x, y, z } = formatData(statsByBinByDOW, stat, metric, binMinutes);

  const metricName = metric === 'SPEED' ? 'Speeds' : 'Travel Times';

  const layout = {
    title: `${stat} ${metricName}`,
    xaxis: {
      title: 'Day of Week'
    },
    yaxis: {
      title: 'Time Bin'
    },
    autosize: true
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '62%' }}
      data={[
        {
          x,
          y,
          z,
          type: 'contour',
          colorbar: {
            title: metricName.slice(0, -1),
            titleside: 'right',
            titlefont: {
              size: 14,
              family: 'Arial, sans-serif'
            }
          },
          reversescale:
            metric === 'SPEED' &&
            !(
              stat === 'variance' ||
              stat === 'standardDeviation' ||
              stat === 'medianAbsoluteDeviation'
            )
        }
      ]}
      layout={layout}
      config={config}
    />
  );
};

class StatsByBinByDowContour extends Component {
  state = {
    stat: 'mean'
  };

  render() {
    const { data } = this.props;

    const { statsByBinByDOW, metric, binMinutes } = data;

    const statsOptions = Array.isArray(statsByBinByDOW)
      ? Object.keys(statsByBinByDOW[0][0]).map(stat => ({
          value: stat,
          label: stat
        }))
      : null;

    if (!statsOptions) {
      return null;
    }

    return (
      <div>
        <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
          <div style={{ width: '33%' }}>
            <Select
              value={{ value: this.state.stat, label: this.state.stat }}
              isMulti={false}
              options={statsOptions}
              onChange={({ value }) => {
                this.setState({ stat: value });
              }}
            />
            <div>{statDescriptions[this.state.stat] || null}</div>
          </div>
          {getViz({
            statsByBinByDOW,
            metric,
            binMinutes,
            stat: this.state.stat
          })}
        </div>
      </div>
    );
  }
}

export default StatsByBinByDowContour;
