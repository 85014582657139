import get from 'lodash.get';

import {
  getPM3MeasuresForGeosByVersion,
  getGeoAttributes2
} from 'store/falcorGraphSelectors';

import { UA } from '../constants';

const getEnrichedGeoLevelPm3MeasuresByVersion = (
  state,
  geoInfoArr,
  versionsMetadata
) => {
  const pm3MeasuresForGeosByVersion = getPM3MeasuresForGeosByVersion(state);

  const fetchedP3VersionIds =
    pm3MeasuresForGeosByVersion && Object.keys(pm3MeasuresForGeosByVersion);

  const ready = Array.isArray(fetchedP3VersionIds) && Array.isArray(geoInfoArr);
  // && fetchedP3VersionIds.length && geoInfoArr.length;

  return ready
    ? fetchedP3VersionIds.reduce((acc, versionId) => {
        const pm3MeasuresForGeoByGeoLevelByGeoId =
          pm3MeasuresForGeosByVersion[versionId];

        const year = versionsMetadata.getYearForVersion(versionId);

        if (!year) {
          return acc;
        }

        for (let i = 0; i < geoInfoArr.length; ++i) {
          const { geolevel, geoid } = geoInfoArr[i];

          if (geolevel === UA && (geoid === '99998' || geoid === '99999')) {
            return acc;
          }

          const geoAttrs = getGeoAttributes2(state, geolevel, geoid, year);

          const pm3 = get(pm3MeasuresForGeoByGeoLevelByGeoId, [
            geolevel,
            geoid
          ]);

          const pm3Row =
            pm3 &&
            !(
              pm3.lottr_interstate ||
              pm3.lottr_noninterstate ||
              pm3.tttr_interstate ||
              pm3.phed
            )
              ? { ...pm3, phed: null }
              : pm3;

          if (geoAttrs && pm3) {
            acc[versionId] = acc[versionId] || [];

            acc[versionId].push({
              geoInfo: geoInfoArr[i],
              year,
              geoname: geoAttrs.geoname,
              ...pm3Row
            });
          }
        }

        acc[versionId] =
          acc[versionId] &&
          acc[versionId].sort((a, b) =>
            a.geoname && a.geoname.localeCompare
              ? a.geoname.localeCompare(b.geoname)
              : -1
          );

        return acc;
      }, {})
    : null;
};

export default getEnrichedGeoLevelPm3MeasuresByVersion;
