import along from '@turf/along'
import length from '@turf/length'
import * as d3scale from "d3-scale"

import {
  mb_tmc_metadata_2017,
  mb_tmc_metadata_2019,
  mb_tmc_metadata_2020,
  
} from 'constants/mapboxTilesets'

export const yearToLayer = {
  2016: {id: 'npmrds-2017_pm3', source: 'tmc_extended_2017_with_mpo', sourceLayer: mb_tmc_metadata_2017.layer},
  2017: {id: 'npmrds-2017_pm3', source: 'tmc_extended_2017_with_mpo', sourceLayer: mb_tmc_metadata_2017.layer},
  2018: {id: 'npmrds-2017_pm3', source: 'tmc_extended_2017_with_mpo', sourceLayer: mb_tmc_metadata_2017.layer},
  2019: {id: 'npmrds-2019_pm3', source: 'tmc_extended_2019_with_mpo', sourceLayer: mb_tmc_metadata_2019.layer},
  2020: {id: 'npmrds-2020_pm3', source: 'tmc_extended_2020_with_mpo', sourceLayer: mb_tmc_metadata_2020.layer},
  2021: {id: 'npmrds-2020_pm3', source: 'tmc_extended_2020_with_mpo', sourceLayer: mb_tmc_metadata_2020.layer},
  2022: {id: 'npmrds-2020_pm3', source: 'tmc_extended_2020_with_mpo', sourceLayer: mb_tmc_metadata_2020.layer}
}
/*export const yearToLayer = {
  2016: {id: 'npmrds-2017_pm3', source: 'tmc_extended_2019', sourceLayer: mb_tmc_metadata_2019.layer},
  2017: {id: 'npmrds-2017_pm3', source: 'tmc_extended_2019', sourceLayer: mb_tmc_metadata_2019.layer},
  2018: {id: 'npmrds-2017_pm3', source: 'tmc_extended_2019', sourceLayer: mb_tmc_metadata_2019.layer},
  2019: {id: 'npmrds-2019_pm3', source: 'tmc_extended_2019', sourceLayer: mb_tmc_metadata_2019.layer}
}*/


export const mapBottlenecks = (map, year, tmcData, domain) => {
  let bottlnecksSource = {type: 'FeatureCollection', features: []}

  let tmcs = tmcData
    .map(d => d.tmc)

  let tmcFeatures = map.querySourceFeatures(yearToLayer[year].source, {
    filter: ['in', 'tmc', ...tmcs],
    sourceLayer: yearToLayer[year].sourceLayer
  })

  let scale = d3scale.scaleLinear()
    .domain(domain)
    .range([5,25])

  tmcFeatures.forEach((tmc,i) => {
    let tmcFilter = tmcData.filter(d => d.tmc === tmc.properties.tmc)[0]
    let tmcValue = tmcFilter.value ? tmcFilter.value : 0
    if(tmc.geometry.type === 'LineString') {
      let tmclength = length(tmc.geometry)
      let bottleneck = along(tmc.geometry, (tmclength/2))
      bottleneck.id = i;
      bottleneck.properties = {
        tmc: tmc.properties.tmc,
        value: tmcValue,
        radius: scale(tmcValue),
        color: tmcFilter.color,
        type: 'bottleneck'

      }
      bottlnecksSource.features.push(bottleneck)
    }

  })

  let newLayer = {
    id: 'bottlnecks',
    type: 'circle',
    source: {
      type: 'geojson',
      data: bottlnecksSource
    },
    paint: {
      'circle-radius': ['number', ['get', 'radius']],
      'circle-opacity': 0.8,
      'circle-color': ['string', ['get', 'color']]
    }
  }

  if( map.getLayer('bottlnecks') ) {
    // console.log('get bottlnecks layer', map.getLayer('bottlnecks').id   )
    let layerId = map.getLayer('bottlnecks').id
    let sourceId = map.getLayer('bottlnecks').source
    map.removeLayer(layerId)
    map.removeSource(sourceId)
  }
  map.addLayer(newLayer)

  // console.log('bottleneck Source',bottlnecksSource)

}

export const getTmcs = (map, filter, layer, sourceLayer) => {
  // console.log(layer, sourceLayer)
  return new Promise(resolve => {
    const listener = () => {
      if (
        !(map.isMoving() || map.isZooming() || map.isRotating()) &&
        map.areTilesLoaded()
      ) {
        map.off('render', listener);
        const d = map.querySourceFeatures(layer, {
          filter: filter,
          sourceLayer: sourceLayer
        });
        // console.log('got d', d)
        return resolve(d)
      }
    };
    map.on('render', listener);
  })
}

export const getGeoFilter = (map, currentValues) => {
  let bb = currentValues.reduce(
      (
        acc,
        { bounding_box: [[a, b], [c, d]] }
        ) => {
          if (+a < acc[0][0]) {
            acc[0][0] = +a;
          }
          if (+b < acc[0][1]) {
            acc[0][1] = +b;
          }
          if (+c > acc[1][0]) {
            acc[1][0] = +c;
          }
          if (+d > acc[1][1]) {
            acc[1][1] = +d;
          }
          return acc;
        },
        [
          [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
          [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY]
        ]
      )
      if(bb[0][0] < Number.POSITIVE_INFINITY) {
        map.fitBounds(bb)
      }

      let geoFilter = currentValues.reduce((acc, current) => {
          acc[`${current.geolevel.toLowerCase()}_code`].push(current.value)
          return acc;
        },
        {"state_code":[],"ua_code":[],"mpo_code":[],"county_code":[]}
      )

      let mapboxClauses = Object.keys(geoFilter).reduce((acc, geoLevel) => {
        if (!(Array.isArray(geoFilter[geoLevel]) && geoFilter[geoLevel].length)) {
          return acc;
        }

        acc.push(...geoFilter[geoLevel].map(geoid => ['in', geoLevel, geoid]));
        return acc;
      }, []);
  return ['any', ...mapboxClauses];
}




export const geoFilter = function (map, layer, value) {
  if (window.localStorage && (layer.name === "Performance Measures")) {
    if (value.length) {
      window.localStorage.setItem("macro-view-geographies", JSON.stringify(value));
    }
    else {
      window.localStorage.removeItem("macro-view-geographies");
    }
  }
  let currentValues = layer.filters.geography.domain.filter(d => layer.filters.geography.value.includes(d.value))
  if(currentValues) {

    let mapboxFilter = getGeoFilter(map,currentValues)
    // console.log('mapboxFilter', mapboxFilter)

    // let compareYear =  let compareYear =  isNaN(+this.filters.compare.value) ? null : +this.filters.compare.value
    let year = layer.filters.year.value

    getTmcs(map, mapboxFilter, yearToLayer[year].source, yearToLayer[year].sourceLayer).then(d => {
      if(layer.filters.network.value === 'Interstate'){
        d = d.filter(d => d.properties.f_system == 1)
      }
      let tmcs = d.map(({ properties: { tmc } }) => tmc)
      layer.component.onSelect(layer.name, tmcs)
      layer.selectedFeatures = d
    })
    map.resize()
  }
}

export const yearChange = function(map, layer, value, prev) {



  map.setLayoutProperty(yearToLayer[prev].id, 'visibility', 'none')
  map.setLayoutProperty(yearToLayer[value].id, 'visibility', 'visible')
  geoFilter(map,layer, layer.filters.geography.value)
}

export const networkFilter = function (map, layer, value) {

  let currentValues = layer.filters.geography.domain.filter(d => layer.filters.geography.value.includes(d.value))
  if(currentValues) {

    let mapboxFilter = getGeoFilter(map,currentValues)


      // console.log('test', layer.filters.network.value)
      let d = map.querySourceFeatures(yearToLayer[layer.filters.year.value].source, {
        filter: mapboxFilter,
        sourceLayer:  yearToLayer[layer.filters.year.value].sourceLayer
      });

      if(layer.filters.network.value === 'Interstate'){
        d = d.filter(d => d.properties.f_system == 1)
      }
      if(layer.filters.network.value === 'Non-Interstate'){
        d = d.filter(d => d.properties.f_system != 1)
      }

      let tmcs = d.map(({ properties: { tmc } }) => tmc)
      layer.component.onSelect(layer.name, tmcs)


  }
}
