import React from "react"
import { connect } from "react-redux"
import { reduxFalcor } from "utils/redux-falcor"

import get from "lodash.get"
import styled from "styled-components"

import { Button, Input } from "components/common/styled-components"
import ItemSelector from 'components/common/item-selector/item-selector';

import { VertDots } from "components/common/icons"

import {
	DragDropContext,
	Droppable,
	Draggable
} from 'react-beautiful-dnd';

import AvlModal from "components/AvlStuff/AvlModal"

const DEFAULT_ROUTE_DATA = {
  name: "",
  type: "personal",
  id: null
}

class RouteInfoBox extends React.Component {
  state = {
    showSaveModal: false,
    route: { ...DEFAULT_ROUTE_DATA }
  }
  componentDidMount() {
    this.setState({ route: {
        ...this.state.route,
        owner: this.state.route.owner === "group" ? this.props.user.groups[0] : this.props.user.id
      }
    })
  }
  componentDidUpdate(oldProps, oldState) {
    if (oldProps.userRoute !== this.props.userRoute) {
      if (this.props.userRoute === null) {
        this.setState({
          route: {
            ...DEFAULT_ROUTE_DATA,
            owner: this.props.user.id
          }
        });
      }
      else {
        this.setState({
          route: {
            name: this.props.userRoute.name,
            type: this.props.userRoute.type,
            owner: this.props.userRoute.owner,
            id: this.props.userRoute.id
          }
        })
      }
    }
  }
  okToSave(saveType) {
    const {
      name, type, owner
    } = this.state.route;

    let nameChanged = true;
    if ((saveType === "new") && this.props.userRoute) {
      nameChanged = this.state.route.name !== this.props.userRoute.name;
    }
    return name.length && type && owner && nameChanged;
  }
  updateRoute(key, value) {
    const route = {
      ...this.state.route,
      [key]: value
    }
    if (key === "type" && value !== this.state.route.type) {
      route.owner = value === "group" ? this.props.user.groups[0] : this.props.user.id;
    }
    this.setState({ route })
  }
  saveRoute(id = null) {
    const { layer, tmcArray } = this.props,
      DATA = {
        ...this.state.route,
        id,
        tmcArray,
        points: layer.mode === "markers" ? layer.markers.map(m => m.getLngLat()) : [],
        conflationArray: layer.mode === "markers" ? layer.data.markers : []
      }
    return this.props.falcor.call(["routes", "save"], [DATA])
      .then(res =>
        layer.loadUserRoutes()
          .then(() => layer.selectUserRoute(res.json.routes.recentlySaved))
          // .then(() => {
          //     const data = get(res, ["json", "routes", "byId", res.json.routes.recentlySaved], {}),
          //       route = {
          //         id: data.id,
          //         name: data.name,
          //         type: data.type,
          //         owner: data.owner,
          //         tmcArray: data.tmcArray,
          //         points: data.points
          //       }
          //     layer.doAction(["updateFilter", "userRoutes", route]);
          // })
      );
  }
  render() {
    const { layer, tmcArray } = this.props;
    let somethingToRemove = false;
    if ((layer.mode === "markers") && (layer.markers.length)) {
      somethingToRemove = true;
    }
    else if ((layer.mode === "click") && (layer.data.click.length)) {
      somethingToRemove = true;
    }
		const route = layer.filters.userRoutes.value;
    return (
      <div>
        { !route ? null :
					<>
						<i>{ route.type === "group" ? `${ route.owner } ` : "" }{ route.type } route</i>
	          <div style={ { fontSize: "18px", paddingTop: "5px" } }>
	            Created: { new Date(route.updatedAt).getFullYear() }
	          </div>
					</>
        }
        <div style={ { position: "relative", paddingTop: "10px" } }>
          <Button style={ { width: "calc(50% - 5px)" } }
            onClick={ e => layer.removeLast() }
            disabled={ !somethingToRemove }
            secondary>
            Remove Last
          </Button>
          <Button style={ { width: "calc(50% - 5px)", position: "absolute", right: 0 } }
            onClick={ e => layer.clearRoute() }
            disabled={ !somethingToRemove }
            secondary>
            Clear Route
          </Button>
        </div>
        { !tmcArray.length ? null :
          <div style={ { paddingTop: "10px" } }>
            <div style={ { fontSize: "18px", paddingBottom: "5px" } }>TMC  List:</div>
            <ScrollingDiv>
              { this.props.mode === "click" ?
                <DraggableTmcList tmcArray={ tmcArray }
                  highlight={ tmc => this.props.layer.highlightTmcs([tmc]) }
                  onClick={ tmc => this.props.layer.handleMapClick([tmc]) }
                  mode={ this.props.mode }
                  reorder={ tmcs => this.props.layer.reorderTmcs(tmcs) }/>
                :
                <TmcList tmcArray={ tmcArray }
                  highlight={ tmc => this.props.layer.highlightTmcs([tmc]) }
                  onClick={ tmc => this.props.layer.handleMapClick([tmc]) }
                  mode={ this.props.mode }/>
              }
            </ScrollingDiv>
          </div>
        }
        <div style={ { position: "relative", paddingTop: "10px" } }>
          <Button style={ { width: "calc(50% - 5px)" } }
            disabled={ !tmcArray.length }
            onClick={ e => this.setState({ showSaveModal: true }) }
            primary>
            Save Route
          </Button>
        </div>
        <AvlModal show={ this.state.showSaveModal }
          onHide={ e => this.setState({ showSaveModal: false }) }
          actions={ [
            { label: this.state.route.id === null ? "Save Route" : "Save As New Route",
              action: this.saveRoute.bind(this, null),
              type: "success",
              disabled: !this.okToSave("new")
            },
            { label: "Update Route",
              action: this.saveRoute.bind(this, this.state.route.id),
              type: "success",
              disabled: !this.okToSave("update"),
              show: Boolean(this.state.route.id)
            }
          ] }>
          <ModalContent>
            <Row>
              <label>Route Name</label>
              <div>
                <Input type="text"
                  value={ this.state.route.name }
                  onChange={ e => this.updateRoute("name", e.target.value) }/>
              </div>
            </Row>
            <Row>
              <label>Route Type</label>
              <div>
                <ItemSelector
                  selectedItems={ this.state.route.type }
                  // placeholder={"Select a Value"}
                  options={ ["personal", "group", "public"] }
                  multiSelect={ false }
                  searchable={ false }
                  displayOption={ d => d }
                  getOptionValue={ d => d }
                  onChange={ v => this.updateRoute("type", v) }
                  inputTheme="secondary"/>
              </div>
            </Row>
            <Row>
              <label>Route Owner</label>
              <div>
                <ItemSelector
                  selectedItems={ this.state.route.type == "group" ? this.state.route.owner : "self" }
                  // placeholder={"Select a Value"}
                  options={ [...this.props.user.groups] }
                  multiSelect={ false }
                  searchable={ false }
                  displayOption={ d => d }
                  getOptionValue={ d => d }
                  onChange={ v => this.updateRoute("owner", v) }
                  disabled={ this.state.route.type !== "group" }
                  inputTheme="secondary"/>
              </div>
            </Row>
          </ModalContent>
        </AvlModal>
      </div>
    )
  }
}
const mapStateToProps = (state, props) => ({
  user: state.user,
  graph: state.graph
})
export default connect(mapStateToProps)(reduxFalcor(RouteInfoBox))

const TmcList = ({ tmcArray, highlight, onClick, mode }) =>
  tmcArray.map(tmc =>
    <TmcListItem key={ tmc }
      onMouseEnter={ e => highlight(tmc) }
      onMouseLeave={ e => highlight(tmc) }>
      { tmc }
      { mode !== "click" ? null :
        <RemoveTmcButton
          onClick={ e => onClick(tmc) }>
          <span className="fa fa-minus"/>
        </RemoveTmcButton>
      }
    </TmcListItem>
  )
class DraggableTmcList extends React.Component {
  onDragEnd({ source, destination }) {
    console.log("DRAG END:", source, destination);
    const tmcArray = [...this.props.tmcArray],
      [src] = tmcArray.splice(source.index, 1);
    tmcArray.splice(destination.index, 0, src);
    this.props.reorder(tmcArray);
  }
  render() {
    const { tmcArray, highlight, onClick, mode } = this.props;
    return (
      <DragDropContext onDragEnd={ this.onDragEnd.bind(this) }>
        <Droppable droppableId="drop-area">
          { (provided, snapshot) =>
            <div ref={ provided.innerRef }
              { ...provided.droppableProps }>

              { tmcArray.map((tmc, i) =>
                  <Draggable key={ tmc } index={ i } draggableId={ tmc }>
                    { (provided, snapshot) =>
                      <div ref={ provided.innerRef }
                        { ...provided.draggableProps }>
                        <TmcListItem
                          onMouseEnter={ e => highlight(tmc) }
                          onMouseLeave={ e => highlight(tmc) }>

                          <div { ...provided.dragHandleProps }
                            style={ {
                              width: "20px",
                              height: "20px",
                              position: "absolute",
                              left: "1px",
                              top: "1px"
                            } }>
                            <VertDots height="20px"/>
                          </div>
                          { tmc }
                          <RemoveTmcButton
                            onClick={ e => onClick(tmc) }>
                            <span className="fa fa-minus"/>
                          </RemoveTmcButton>
													
                        </TmcListItem>
                      </div>
                    }
                  </Draggable>
                )
              }

              { provided.placeholder }

            </div>
          }
        </Droppable>
      </DragDropContext>
    )
  }
}

const TmcListItem = styled.div`
  padding: 1px 10px 1px 22px;
  border: 2px solid transparent;
  border-radius: 3px;
  transition: border-color 0.15s;
  position: relative;
  :hover {
    border-color: ${ props => props.theme.textColorHl };
  }
`
const RemoveTmcButton = styled.div`
  position: absolute;
  right: 10px;
  top: 1px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.15s, background-color 0.15s;

  border-radius: 3px;
  :hover {
    background-color: ${ props => props.theme.textColor };
    color: ${ props => props.theme.sidePanelBg };
  }
`

const ScrollingDiv = styled.div`
  ${ props => props.theme.scrollBar };
  padding: 0px 10px;
  max-height: 300px;
  overflow: auto;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  :first-child {
    margin-top: 0px;
  }
`
const ModalContent = styled.div`
  display: flex;
  align-items: center;
  width: 500px;
  flex-direction: column;

  > ${ Row } > label {
    width: 25%;
    padding-right: 10px;
    text-align: right;
    font-size: 15px;
  }
  > ${ Row } > div {
    width: 75%;
  }
`
