import React from 'react';

import ScatterPlot from 'components/charts/scatterPlot/canvas';

import { precisionRound } from 'utils/calculators/MathUtils';

import { standardColors } from 'utils/constants';

export const formatAvgTTByBin = ({ avgsByBin, binMinutes }) => {
  if (!(Array.isArray(avgsByBin) && avgsByBin.length)) {
    return null;
  }

  const peaks = [
    ...avgsByBin.reduce(
      (acc, { peak }) => (peak ? acc.add(peak) : acc),
      new Set()
    )
  ];

  const isTotalPeakOnly = !peaks.length;

  const partitionedByPeak = avgsByBin.reduce(
    (acc, { peak, year, month, date, hour, minute, avgTT }) => {
      const i = !isTotalPeakOnly && peak ? peaks.indexOf(peak) + 1 : 0;
      if (!acc[i]) {
        acc[i] = [];
      }

      const Y = year;
      const m = `0${month}`.slice(-2);
      const d = `0${date}`.slice(-2);
      const H = `0${hour}`.slice(-2);
      const M = `0${minute}`.slice(-2);

      const timestamp = `${Y}/${m}/${d}T${H}:${M}:00Z`;

      acc[i].push({
        x: timestamp,
        y: precisionRound(avgTT, 2)
      });

      return acc;
    },
    []
  );

  return partitionedByPeak.map((data, i) => ({
    id: peaks[i - 1] || (isTotalPeakOnly ? 'TOTAL' : 'NON-PEAK'),
    data,
    color: standardColors[i % standardColors.length]
  }));
};

// NOTE: Browser will remove whitespaces when rendering.
const description = `
  NPMRDS and other probe data sets report 5 minutes travel time intervals
    and the Peak Hour Excessive Delay calulation requires that
    travel time be binned in 15 minute increments.

  The first step in calcuating the PHED is to do this binning.
`;

const getViz = data => {
  const { binMinutes } = data;

  const formattedData = formatAvgTTByBin(data);

  return formattedData ? (
    <ScatterPlot
      data={formattedData}
      includedTicksFilter={null}
      bottomLegend={`${binMinutes} min bins`}
      leftLegend="Average Travel Time"
      animate={false}
      tooltipFormat={str => str.replace(/:00 GMT.*,/, ',')}
      bottomFormat={d =>
        d.getDate() === 1 && d.getHours() === 0 && d.getMinutes() === 0
          ? d.toLocaleString('en-US', {
              month: 'short',
              timeZone: 'America/New_York'
            })
          : ''
      }
      xScale={{
        type: 'time',
        format: '%Y/%m/%dT%H:%M:%SZ',
        precision: 'minute'
      }}
    />
  ) : null;
};

const AvgTravelTimeByBin = ({ data }) => {
  const { binMinutes } = data;

  return (
    <div>
      <p>{description}</p>
      <div style={{ width: '100%', height: 300, color: '#333' }}>
        <h6>Average Travel Time ({binMinutes} minute bins)</h6>
        {getViz(data)}
      </div>
    </div>
  );
};

export default AvgTravelTimeByBin;
