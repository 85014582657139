import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux';

import get from "lodash.get"

import LandingNav from './components/LandingNav'

import {
  authWithToken,
  passwordSet
} from 'store/modules/user';

import './Login.css'

class PasswordSet extends Component {
  state = {
      password: '',
      password_confirm: '',
      redirect: false
  }

  componentDidMount() {
    const token = get(this.props, ["match", "params", "token"], false);
    this.props.authWithToken(token);
  }

  validateForm() {
    return this.state.password.length &&
      this.state.password_confirm.length &&
      this.state.password === this.state.password_confirm &&
      this.props.user.authed;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.props.passwordSet(this.state.password)
      .then(() => this.setState({ redirect: true }));
  };

  render () {
    if (this.state.redirect) {
      return <Redirect to="/"/>
    }
    return (
      <div style={{
        height:'100vh',
        overflow: 'hidden',
        backgroundImage: 'linear-gradient(to bottom right,rgb(110, 176, 194) 0px, rgb(63, 174, 205) 39%, rgb(52, 149, 176) 100%)'}}
      >
        <LandingNav />
        <div style={ {
          height: '100%',
          display: 'flex',
          flexDirection:'column',
          justifyContent:'center',
          alignItems: 'flex-start'
        } }>
        	<div className="auth-box-w" style={ {
            width: '33%',
            minWidth: '350px',
            maxWidth: '650px'
          } }>

          <h4 className="auth-header" style={ { paddingTop: 20 } }>
            NPMRDS Set Password
          </h4>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor='password'>New Password</label>
              <input
                id="password"
                autoFocus
                type="password"
                value={ this.state.password }
                onChange={ this.handleChange }
                className="login-form-control"
                placeholder="Enter new password..."
              />
              <div className="pre-icon os-icon os-icon-fingerprint"/>
            </div>
            <div className="form-group">
              <label htmlFor='password_confirm'>Password Confirm</label>
              <input
                value={ this.state.password_confirm }
                onChange={ this.handleChange }
                id="password_confirm"
                type="password"
                className="login-form-control"
                placeholder="Confirm password..."
              />
              <div className="pre-icon os-icon os-icon-fingerprint"/>
            </div>
            <div className="buttons-w">
              <button className="btn btn-primary btn-lg btn-block"
                disabled={ !this.validateForm() }>
                Set Password
              </button>
            </div>
          </form>
          <div style={ { padding: 15, float: 'right' } }>
            <Link to={'/login'}>Login</Link>
          </div>
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  authWithToken,
  passwordSet
};

export default {
  icon: 'icon-map',
  path: '/password/set/:token',
  mainNav: false,
  component: connect(mapStateToProps, mapDispatchToProps)(PasswordSet),
  menuSettings: {hide: true}
}
