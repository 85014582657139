import React from 'react';

import { precisionRound } from 'utils/calculators/MathUtils';

const PlanningTimeIndexByPeak = ({ data: { ptiByPeak } }) =>
  ptiByPeak ? (
    <dl>
      {Object.keys(ptiByPeak).map(peak => (
        <div>
          <dt>{peak}</dt>
          <dd>{precisionRound(ptiByPeak[peak], 3)}</dd>
        </div>
      ))}
    </dl>
  ) : null;

export default PlanningTimeIndexByPeak;
