export const baseMaps = [
  { name: "Dark",
    style: 'mapbox://styles/am3081/ckm85o7hq6d8817nr0y6ute5v' },
  { name: "Macro",
    style: 'mapbox://styles/am3081/cjqqukuqs29222sqwaabcjy29' },
 
  // {
  // 	name: "Blue Dark",
  // 	style: "mapbox://styles/am3081/cjqqukuqs29222sqwaabcjy29"
  // },
  { name: "Light",
    style: 'mapbox://styles/am3081/ckm86j4bw11tj18o5zf8y9pou' },
  // { name: "Satellite",
  //   style: 'mapbox://styles/am3081/cjya6wla3011q1ct52qjcatxg' },
  { name: "Satellite Streets",
    style: 'mapbox://styles/am3081/cjya70364016g1cpmbetipc8u' }
]