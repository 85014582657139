import React from "react"

import get from "lodash.get"
import throttle from "lodash.throttle"

import deepequal from "deep-equal"

import * as d3array from "d3-array"
import * as d3selection from "d3-selection"
import * as d3scale from "d3-scale"
import * as d3axis from "d3-axis"
// import * as d3transition from "d3-transition"
// import * as d3format from "d3-format"

import COLOR_RANGES from "constants/color-ranges"
const KEY_COLORS = COLOR_RANGES[12].reduce((a, c) => c.name === "Set3" ? c.colors : a, []).slice()

const HoverComp = ({ data, format }) =>
	<div>
		{ data.k } { format(data.v) }
	</div>
// //
class BarGraph extends React.Component {
	static defaultProps = {
		data: [],
		indexBy: "id",
		keys: [],
		margin: {
			top: 0,
			bottom: 0,
			right: 0,
			left: 0
		},
		tooltipFormat: v => v,
		axisLeft: null,
		axisBottom: null,
		xScale: null,
		yScale: null,
		colorBy: null,
		tooltip: HoverComp,
		highlightedKeys: [],
		attr: {},
		highlightAttr: {},
		onMouseEnter: null,
		onMouseLeave: null
	}
	constructor(props) {
		super(props);
		this.state = {
			bargraph: d3BarGraph(),
			hoverData: null
		}

		this.div = React.createRef();
		this.svg = React.createRef();
		this.hover = React.createRef();

		this.throttled = throttle(this.resize.bind(this), 50);
	}
	componentDidMount() {
		this.resize();
		this.updateHighlight();
		d3selection.select(this.hover.current)
			.style("display", `none`);
	}
	componentWillUnmount() {
		cancelAnimationFrame(this.animation);
	}
	componentDidUpdate(oldProps) {
		if (!deepequal(oldProps.data, this.props.data) ||
				!deepequal(oldProps.yScale, this.props.yScale) ||
				!deepequal(oldProps.xScale, this.props.xScale) ||
				!deepequal(oldProps.colorRange, this.props.colorRange)) {
			this.updateGraph();
		}
		if (!deepequal(oldProps.highlightedKeys, this.props.highlightedKeys)) {
			this.updateHighlight();
		}
	}
	resize() {
		const div = this.div.current;
		if (!div) return;

		const width = div.scrollWidth,
			height = div.scrollHeight;

		if ((width !== this.state.width) || (height !== this.state.height)) {
			this.setState({ width, height });
			this.updateGraph();
		}
		this.animation = requestAnimationFrame(this.throttled)
	}
	updateHighlight() {
		const div = this.div.current;
		if (!div) return;

		const { bargraph } = this.state,
			{ highlightedKeys } = this.props;

		bargraph
			.highlightedKeys(highlightedKeys)

		d3selection.select(div)
			.call(bargraph.updateHighlight)
	}
	updateGraph() {
		const div = this.div.current;
		if (!div) return;

		const { bargraph } = this.state,
			{ data,
				indexBy,
				keys,
				margin,
				// tooltipFormat,
				axisLeft,
				axisBottom,
				xScale,
				yScale,
				colorBy,
				// highlightedKeys,
				attr,
				highlightAttr,
				colorRange = KEY_COLORS,
				colorScale = null
			} = this.props;

		bargraph
			.attr(attr)
			.highlightAttr(highlightAttr)
			.colorRange(colorRange)
			.colorScale(colorScale)
			.indexBy(indexBy)
			.keys(keys)
			.data(data)
			.margin(margin)
			.axisLeft(axisLeft)
			.axisBottom(axisBottom)
			.xScale(xScale)
			.yScale(yScale)
			.colorBy(colorBy)
			.mouseenter(this.mouseenter.bind(this))
			.mousemove(this.mousemove.bind(this))
			.mouseleave(this.mouseleave.bind(this));

		d3selection.select(div)
			.call(bargraph);
	}

	mouseenter(hoverData) {
		this.setState({ hoverData });

		this.props.onMouseEnter && this.props.onMouseEnter(hoverData);

		d3selection.select(this.hover.current)
			.style("display", `block`);
	}
	mousemove(d) {
		const div = this.svg.current;
		if (!div) return;

		const hover = this.hover.current;
		if (!hover) return;

		const [x, y] = d3selection.mouse(div),

			divWidth = div.scrollWidth,
			// divHeight = div.scrollHeight,

			hoverWidth = hover.scrollWidth,
			hoverHeight = hover.scrollHeight;

		const left = x + 10 + hoverWidth > divWidth ? x - 10 - hoverWidth : x + 10,
			top = y - 10 - hoverHeight < 0 ? 0 : y - 10 - hoverHeight;

		d3selection.select(this.hover.current)
			.style("left", `${ left }px`)
			.style("top", `${ top }px`);
	}
	mouseleave(d) {
		d3selection.select(this.hover.current)
			.style("display", `none`);

		this.props.onMouseLeave && this.props.onMouseLeave(d);
	}

	render() {
		return (
			<div style={ { width: "100%", height: "100%", position: "relative" } }
				ref={ this.div }>
				<svg style={ { width: "100%", height: "100%", display: "block" } }
					ref={ this.svg }/>
				<div style={ {
						position: "absolute",
						background: "#fff",
						padding: "5px",
						borderRadius: "4px",
						pointerEvents: "none",
						whiteSpace: "nowrap"
					} }
					ref={ this.hover }>
					{ !this.state.hoverData ? null :
						<this.props.tooltip
							data={ this.state.hoverData }
							indexBy={ this.props.indexBy }
							format={ this.props.tooltipFormat }/>
					}
				</div>
			</div>
		)
	}
}
export default BarGraph

function d3BarGraph() {
	let data = [],
		indexBy = "id",
		keys = [],
		highlightedKeys = [],
		attr = {},
		highlightAttr = {},
		margin = {
			top: 0,
			bottom: 0,
			right: 0,
			left: 0
		},
		// tooltip = null,
		axisLeft = null,
		axisBottom = null,
		enableGridY = true,
		_xScale = null,
		_yScale = null,
		colorBy = null,

		colorRange = KEY_COLORS,
		_colorScale = null,

		mouseenter = null,
		mousemove = null,
		mouseleave = null;

	function graph(selection) {
		if (!selection.size()) return;

		const node = selection.node(),
			width = node.scrollWidth - margin.left - margin.right,
			height = node.scrollHeight - margin.top - margin.bottom;

		const xScale = d3scale.scaleBand()
			.domain(data.map(d => d[indexBy]))
			.range([0, width])
		if (_xScale) {
			const domain = get(_xScale, 'domain', false);
			if (domain) {
				xScale.domain(domain);
			}
		}

		let showAxisLeft = true;
		const yScale = d3scale.scaleLinear()
			.domain([0, data.length ? d3array.max(data.map(d => d3array.sum(keys.map(k => d[k])))) : 0])
			.range([0, height])
		if (_yScale) {
			const { min, max } = _yScale,
				[dMin, dMax] = yScale.domain(),
				domain = [isNaN(min) ? dMin : min, isNaN(max) ? dMax : max];
			yScale.domain(domain);
			if ((domain[0] === 0) && (domain[1] === 0)) {
				showAxisLeft = false;
				yScale.domain([0, 1]);
			}
		}

		let colorScale = d3scale.scaleOrdinal()
			.domain(keys)
			.range(colorRange)
		if (_colorScale) {
			const { type } = _colorScale;
			switch (type) {
				case "quantize": {
					colorScale = d3scale.scaleQuantize();
					const { min, max } = _colorScale,
						[dMin, dMax] = yScale.domain(),
						domain = [isNaN(min) ? dMin : min, isNaN(max) ? dMax : max];
						colorScale.domain(domain)
							.range(colorRange);
					break;
				}
			}
		}

		const svg = selection.select("svg");

		svg.on("mousemove", mousemove)

		let gridLinesGroup = svg.select("g.grid-lines");
		if (!gridLinesGroup.size()) {
			gridLinesGroup = svg.append("g")
				.attr("class", "grid-lines");
		}
		gridLinesGroup.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`);

		if (enableGridY && showAxisLeft) {
			const gridLines = gridLinesGroup.selectAll("line.y.grid-line")
				.data(yScale.ticks());

			gridLines.enter().append("line")
					.attr("class", "y grid-line")
					.merge(gridLines)
					.attr("x1", 0)
					.attr("x2", width)
					.attr("y1", d => height - yScale(d))
					.attr("y2", d => height - yScale(d))
					.style("stroke-width", 2)
					.style("stroke", "rgba(200, 200, 200, 0.3)");

			gridLines.exit().remove();
		}
		else {
			gridLinesGroup.selectAll("line.y.grid-line").remove();
		}

		let graph = svg.select("g.graph");
		if (!graph.size()) {
			graph = svg.append("g")
				.attr("class", "graph");
		}
		graph.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`)
			.on("mouseleave", mouseleave);

		const stacks = graph.selectAll("g.bar")
			.data(data, d => d[indexBy]);

		stacks.enter().append("g")
				.attr("class", "bar")
				.merge(stacks)
				.style("transform", d => `translate(${ xScale(d[indexBy]) }px, 0px)`)
				.each(function(d, i) {
					let current = 0;
					const rects = d3selection.select(this)
						.selectAll('rect.stack')
						.data(keys.map(k => ({ bar: d, k, v: get(d, k, 0), [indexBy]: get(d, indexBy, 0), c: colorBy ? colorBy(d) : colorScale(k) })), d => d.k);

					rects.exit().remove();

					rects.enter().append("rect")
							.attr("class", "stack")
							.merge(rects)
							.on("mouseenter", mouseenter)
							// .on("mouseleave", mouseleave)
							.attr("fill", ({ c }) => c)
							.attr("width", xScale.bandwidth())
							.each(function({ k, v }) {
								const h = yScale(v),
									rect = d3selection.select(this);

								rect.attr("height", h)
									.attr("y", height - current - h)

								for (const key in attr) {
									rect.attr(key, attr[key]);
								}

								current += h;
							})
				})
		stacks.exit().remove();

		if (showAxisLeft && axisLeft) {
			let axisLeftGroup = svg.select("g.axis.left")
			if (!axisLeftGroup.size()) {
				axisLeftGroup = svg.append("g")
					.attr("class", "axis left");
			}
			axisLeftGroup.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`)

			let axisLeftLegend = axisLeftGroup.select("text.legend");
			if (!axisLeftLegend.size()) {
				axisLeftLegend = axisLeftGroup.append("text")
					.attr("class", "legend");
			}
			axisLeftLegend
				.attr("transform", `translate(${ -margin.left + get(axisLeft, "legendOffset", 20) }, ${ height * 0.5 }) rotate(-90)`)
				.attr("text-anchor", "middle")
				.attr("font-size", "0.9rem")
				.text(get(axisLeft, "legend", ""))

			let axisLeftAxis = axisLeftGroup.select("g")
			if (!axisLeftAxis.size()) {
				axisLeftAxis = axisLeftGroup.append("g");
			}
			const scale = d3scale.scaleLinear()
							.domain(yScale.domain())
							.range(yScale.range().slice().reverse()),
				axis = d3axis.axisLeft(scale)
					.tickFormat(get(axisLeft, "format", null));
			axisLeftAxis.call(axis)
		}
		else {
			svg.select("g.axis.left").remove();
		}

		if (axisBottom) {
			let axisBottomGroup = svg.select("g.axis.bottom")
			if (!axisBottomGroup.size()) {
				axisBottomGroup = svg.append("g")
					.attr("class", "axis bottom");
			}
			axisBottomGroup.style("transform", `translate(${ margin.left }px, ${ height + margin.top }px)`)

			let axisBottomLegend = axisBottomGroup.select("text.legend");
			if (!axisBottomLegend.size()) {
				axisBottomLegend = axisBottomGroup.append("text")
					.attr("class", "legend");
			}
			axisBottomLegend
				.attr("transform", `translate(${ width * 0.5 }, ${ get(axisBottom, "legendOffset", 0) })`)
				.attr("text-anchor", "middle")
				.attr("font-size", "0.9rem")
				.text(get(axisBottom, "legend", ""))

			let axisBottomAxis = axisBottomGroup.select("g")
			if (!axisBottomAxis.size()) {
				axisBottomAxis = axisBottomGroup.append("g");
			}
			const domain = xScale.domain(),
				mod = Math.max(1, Math.round((domain.length / width) * 60)),
				tickValues = domain
					.filter((d, i) => {
						if ((i === 0)) return mod === 1;
						if ((i + mod) > domain.length) return false;
						if ((i % mod) === 0) return true;
						return false;
					}, []);
			const axis = d3axis.axisBottom(xScale)
				.tickFormat(get(axisBottom, "format", null))
				// .tickValues(get(axisBottom, "tickValues", null))
				.tickValues(tickValues);

			axisBottomAxis.call(axis)
		}
		else {
			svg.select("g.axis.bottom").remove();
		}

		svg.selectAll("path.domain")
			.style("stroke-width", "2px")
	}
	graph.colorScale = function(cs) {
		if (!arguments.length) {
			return _colorScale;
		}
		_colorScale = { ...cs };
		return graph;
	}
	graph.colorRange = function(cr) {
		if (!arguments.length) {
			return colorRange;
		}
		colorRange = cr;
		return graph;
	}
	graph.updateHighlight = function(selection) {
		selection.select("svg g.graph")
			.selectAll("rect.stack")
				.each(function({ k, c }) {
					const rect = d3selection.select(this);
					if (highlightedKeys.includes(k)) {
						for (const key in highlightAttr) {
							rect.attr(key, highlightAttr[key]);
						}
					}
					else {
						rect.attr("fill", c)
						for (const key in attr) {
							rect.attr(key, attr[key]);
						}
					}
				})
	}
	graph.attr = function(k) {
		if (!arguments.length) {
			return attr;
		}
		attr = k;
		return graph;
	}
	graph.highlightAttr = function(k) {
		if (!arguments.length) {
			return highlightAttr;
		}
		highlightAttr = k;
		return graph;
	}
	graph.highlightedKeys = function(k) {
		if (!arguments.length) {
			return highlightedKeys;
		}
		highlightedKeys = k;
		return graph;
	}
	graph.colorBy = function(d) {
		if (!arguments.length) {
			return colorBy;
		}
		colorBy = d;
		return graph;
	}
	graph.xScale = function(d) {
		if (!arguments.length) {
			return _xScale;
		}
		_xScale = d;
		return graph;
	}
	graph.yScale = function(d) {
		if (!arguments.length) {
			return _yScale;
		}
		_yScale = d;
		return graph;
	}
	graph.axisBottom = function(d) {
		if (!arguments.length) {
			return axisBottom;
		}
		axisBottom = d;
		return graph;
	}
	graph.axisLeft = function(d) {
		if (!arguments.length) {
			return axisLeft;
		}
		axisLeft = d;
		return graph;
	}
	graph.margin = function(d) {
		if (!arguments.length) {
			return margin;
		}
		margin = d;
		return graph;
	}
	graph.data = function(d) {
		if (!arguments.length) {
			return data;
		}
		data = d;
		return graph;
	}
	graph.indexBy = function(d) {
		if (!arguments.length) {
			return indexBy;
		}
		indexBy = d;
		return graph;
	}
	graph.keys = function(d) {
		if (!arguments.length) {
			return keys;
		}
		keys = d;
		return graph;
	}

	graph.mouseenter = function(d) {
		if (!arguments.length) {
			return mouseenter;
		}
		mouseenter = d;
		return graph;
	}
	graph.mousemove = function(d) {
		if (!arguments.length) {
			return mousemove;
		}
		mousemove = d;
		return graph;
	}
	graph.mouseleave = function(d) {
		if (!arguments.length) {
			return mouseleave;
		}
		mouseleave = d;
		return graph;
	}

	return graph;
}
