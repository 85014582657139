import React from 'react';

import ScatterPlot from 'components/charts/scatterPlot/canvas';

import { precisionRound } from 'utils/calculators/MathUtils';

import { monthAbbrs, monthColors } from 'utils/constants';

export const formatExcessiveDelayVehicleHoursByBin = ({
  binMinutes,
  excessiveDelayVehicleHoursByBin
}) => {
  if (
    !(
      Array.isArray(excessiveDelayVehicleHoursByBin) &&
      excessiveDelayVehicleHoursByBin.length
    )
  ) {
    return null;
  }

  const partitionedByMonth = excessiveDelayVehicleHoursByBin.reduce(
    (acc, { year, month, date, binNum, excessiveDelayVehicleHrs }) => {
      const i = +month - 1;
      if (!acc[i]) {
        acc[i] = [];
      }

      acc[i].push({
        x: `${date}.${binNum}`,
        y: precisionRound(excessiveDelayVehicleHrs, 2)
      });

      return acc;
    },
    []
  );

  return partitionedByMonth.map((data, i) => ({
    id: monthAbbrs[i],
    data,
    color: monthColors[i]
  }));
};

const getViz = data => {
  const { binMinutes } = data;

  const formattedData = formatExcessiveDelayVehicleHoursByBin(data);

  return formattedData ? (
    <ScatterPlot
      data={formattedData}
      includedTicksFilter={null}
      bottomLegend={`${binMinutes} min bins`}
      leftLegend="Average Travel Time"
      animate={false}
    />
  ) : null;
};

const PHEDExcessiveDelayVehicleHoursByBin = ({ data }) => {
  const { binMinutes } = data;
  return (
    <div>
      <div style={{ width: '100%', height: 300, color: '#333' }}>
        <h6>Average Travel Time ({binMinutes} minute bins)</h6>
        {getViz(data)}
      </div>
    </div>
  );
};

export default PHEDExcessiveDelayVehicleHoursByBin;
