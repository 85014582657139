// TODO: https://plot.ly/javascript/time-series/#time-series-with-rangeslider
// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';
import { precisionRound } from 'utils/calculators/MathUtils';
import BinnedTimeUtils from 'utils/calculators/BinnedTimeUtils';

import { monthAbbrs } from 'utils/constants';

const formatData = ({ year, binMinutes, avgsByBin, metric }) => {
  if (!(Array.isArray(avgsByBin) && avgsByBin.length)) {
    return null;
  }

  const {
    dlsStartMonth,
    dlsStartDate
  } = BinnedTimeUtils.DaylightSavingsStartDate(year);

  const numDaysPerMonth = BinnedTimeUtils.getNumDaysPerMonthInYear(year);
  const numBinsInDay = BinnedTimeUtils.numBinsInDay(binMinutes);

  let cur = 0;

  const dataCompletenessMatrix = Array(12);

  for (let m = 0; m < 12; ++m) {
    const numDays = numDaysPerMonth[m];
    dataCompletenessMatrix[m] = Array(numDays);

    for (let d = 0; d < numDays; ++d) {
      dataCompletenessMatrix[m][d] = Array(numBinsInDay);

      for (let b = 0; b < numBinsInDay; ++b) {
        const { month, date, binNum, hour } = avgsByBin[cur] || {};

        const binHasData = month === m + 1 && date === d + 1 && binNum === b;

        if (binHasData) {
          dataCompletenessMatrix[m][d][b] = 1;
          ++cur;
        } else {
          dataCompletenessMatrix[m][d][b] =
            month === dlsStartMonth && date === dlsStartDate && hour === 2
              ? 1
              : 0;
        }
      }
    }
  }

  return dataCompletenessMatrix;
};

const getViz = ({ year, binMinutes, avgsByBin, metric }) => {
  const dataCompletenessMatrix = formatData({
    year,
    binMinutes,
    avgsByBin,
    metric
  });

  if (!dataCompletenessMatrix) {
    return null;
  }

  const numBinsInDay = BinnedTimeUtils.numBinsInDay(binMinutes);
  const timesOfDayForBins = BinnedTimeUtils.timesOfDayForBins(binMinutes);

  // For each month
  //   For each binNum
  //      the percentage of bins with data
  const byBinByMonth = {
    x: monthAbbrs.map((_, m) => `${year}-${`0${m + 1}`.slice(-2)}`),
    y: timesOfDayForBins,
    z: [...Array(numBinsInDay)].map((_, binNum) => {
      return dataCompletenessMatrix.map(monthData => {
        const ct = monthData.reduce((acc, dayData) => acc + dayData[binNum], 0);
        return precisionRound(ct / monthData.length, 3);
      });
    })
  };

  const monthlyByBinByDay = dataCompletenessMatrix.map((monthData, m) => ({
    x: [...Array(monthData.length)].map(
      (_, d) => `${year}-${`0${m + 1}`.slice(-2)}-${`0${d + 1}`.slice(-2)}`
    ),
    y: timesOfDayForBins,
    z: [...Array(numBinsInDay)].map((_, binNum) =>
      monthData.map(dayData => dayData[binNum])
    )
  }));

  const sliders = [
    {
      pad: { t: 50 },
      x: 0.38,
      len: 0.62,
      currentvalue: {
        xanchor: 'right',
        font: {
          color: '#888',
          size: 20
        }
      },
      transition: { duration: 500 },
      // By default, animate commands are bound to the most recently animated frame:
      steps: [
        {
          label: 'Yearly',
          method: 'animate',
          args: [
            ['Yearly'],
            {
              mode: 'immediate',
              frame: { redraw: true, duration: 0 },
              transition: { duration: 0 }
            }
          ]
        }
      ].concat(
        monthAbbrs.map(monthAbbr => ({
          label: monthAbbr,
          method: 'animate',
          args: [
            [monthAbbr],
            {
              mode: 'immediate',
              frame: { redraw: true, duration: 0 },
              transition: { duration: 0 }
            }
          ]
        }))
      )
    }
  ];

  const layout = {
    title: `Data Completeness`,
    xaxis: {
      title: 'Month',
      autorange: true,
      type: 'date'
    },
    yaxis: {
      autorange: true,
      title: 'Time of Day'
    },
    legend: {
      x: 1.05
    },
    sliders
  };

  const frames = Array.prototype.concat(
    {
      name: 'Yearly',
      data: [
        {
          ...byBinByMonth,
          colorscale: 'YIGnBu',
          showscale: true,
          reversescale: true
        }
      ],
      layout: {
        xaxis: {
          title: 'Month',
          range: [`${year}-01`, `${year}-12`]
        }
      }
    },
    monthAbbrs.map((monthAbbr, m) => {
      const hasAllData = Array.prototype
        .concat(...dataCompletenessMatrix[m])
        .every(v => v);

      return {
        name: monthAbbr,
        data: [
          {
            ...monthlyByBinByDay[m],
            colorscale: [
              hasAllData ? [0, '#000000'] : [0, '#FFFFFF'],
              [1, '#000000']
            ],
            showscale: false,
            reversescale: false
          }
        ],
        layout: {
          xaxis: {
            title: 'Day Of Month',
            range: [
              `${year}-${`0${m + 1}`.slice(-2)}-01`,
              `${year}-${`0${m + 1}`.slice(-2)}-${
                dataCompletenessMatrix[m].length
              }`
            ]
          }
        }
      };
    })
  );

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '100%', height: 750 }}
      data={[
        {
          ...byBinByMonth,
          colorscale: 'YIGnBu',
          type: 'heatmap',
          reversescale: true
        }
      ]}
      frames={frames}
      layout={layout}
      config={config}
    />
  );
};

const DataCompleteness = ({ data }) =>
  Array.isArray(data.avgsByBin) && data.avgsByBin.length ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        {getViz(data)}
      </div>
    </div>
  ) : null;

export default DataCompleteness;
