const distributions = {
    "WEEKDAY_NO2LOW_CONGESTION_AM_PEAK_FREEWAY": [
        0.06266418751637981,
        0.06184445848224624,
        0.06076458399258836,
        0.05947387818477077,
        0.05802165519615806,
        0.05645722916411481,
        0.05482991422600562,
        0.053189024519195056,
        0.05158387418104772,
        0.050063777348928216,
        0.048678048160201125,
        0.04747600075223103,
        0.04650694926238252,
        0.04570849131988445,
        0.044987119084393866,
        0.044345488488917506,
        0.04378625546646219,
        0.043312075950034695,
        0.04292560587264179,
        0.04262950116729029,
        0.042426417766986996,
        0.04231901160473864,
        0.04230993861355206,
        0.04240185472643402,
        0.042597415876391306,
        0.04279848900350921,
        0.04293972841547091,
        0.043072971096680165,
        0.04325005403154067,
        0.04352281420445616,
        0.04394308859983031,
        0.04456271420206687,
        0.04543352799556955,
        0.04660736696474208,
        0.048136068093988155,
        0.05007146836771149,
        0.05246540477031582,
        0.05491142962229535,
        0.05709434496279695,
        0.05920286235420317,
        0.06142569335889664,
        0.06395154953925992,
        0.06696914245767566,
        0.07066718367652644,
        0.07523438475819481,
        0.08085945726506344,
        0.0877311127595149,
        0.0960380628039317,
        0.10596901896069663,
        0.11736715991023197,
        0.12992682310838946,
        0.14361345828069483,
        0.15839251515267433,
        0.17422944344985428,
        0.19108969289776032,
        0.20893871322191876,
        0.22774195414785592,
        0.24746486540109736,
        0.2680728967071695,
        0.2895314977915985,
        0.3118061183799101,
        0.3361553165447013,
        0.363417684086142,
        0.39292872132111667,
        0.42402392856651067,
        0.45603880613920966,
        0.488308854356098,
        0.5201695735340611,
        0.5509564639899847,
        0.5800050260407529,
        0.6066507600032514,
        0.6302291661943653,
        0.6500757449309799,
        0.6670607697065005,
        0.6824629596710384,
        0.6962304836265358,
        0.7083115103749356,
        0.7186542087181805,
        0.7272067474582133,
        0.7339172953969766,
        0.7387340213364133,
        0.7416050940784655,
        0.7424786824250766,
        0.7413029551781889,
        0.7380260811397455,
        0.7315877470463565,
        0.7213697512192003,
        0.7079834298370716,
        0.6920401190787643,
        0.6741511551230727,
        0.6549278741487916,
        0.6349816123347153,
        0.6149237058596376,
        0.5953654909023536,
        0.5769183036416571,
        0.5601934802563429,
        0.5458023569252048,
        0.5329001227311608,
        0.5202822585319291,
        0.5080205381685026,
        0.49618673548187553,
        0.484852624313042,
        0.4740899785029956,
        0.4639705718927303,
        0.45456617832324003,
        0.4459485716355186,
        0.43818952567055985,
        0.4313608142693579,
        0.42553421127290647,
        0.4209256915791371,
        0.4175943950238312,
        0.4153768428547572,
        0.4141095563196835,
        0.4136290566663787,
        0.41377186514261133,
        0.41437450299614986,
        0.4152734914747626,
        0.41630535182621825,
        0.41730660529828517,
        0.418113773138732,
        0.4185633765953271,
        0.4189070298790918,
        0.41948319158129216,
        0.420258649520064,
        0.4212001915135429,
        0.4222746053798646,
        0.4234486789371649,
        0.42468920000357946,
        0.4259629563972441,
        0.42723673593629413,
        0.42847732643886577,
        0.4296515157230945,
        0.430726091607116,
        0.4317178901891328,
        0.43267385682094345,
        0.43360594320107865,
        0.4345261010280691,
        0.4354462820004453,
        0.43637843781673763,
        0.43733452017547675,
        0.43832648077519315,
        0.43936627131441747,
        0.4404658434916801,
        0.4416371490055117,
        0.4428921395544428,
        0.4441388951680272,
        0.4453058831030384,
        0.4464356358988369,
        0.4475706860947828,
        0.44875356623023654,
        0.4500268088445586,
        0.4514329464771091,
        0.4530145116672484,
        0.4548140369543368,
        0.45687405487773486,
        0.4592370979768027,
        0.4619456987909008,
        0.4650598781270528,
        0.46857143191042605,
        0.47242055535759675,
        0.47654744368514074,
        0.4808922921096338,
        0.48539529584765206,
        0.48999665011577137,
        0.4946365501305674,
        0.49925519110861644,
        0.5037927682664942,
        0.5081894768207768,
        0.5123855119880399,
        0.5165237451710337,
        0.5207603361170614,
        0.5250554125595295,
        0.5293691022318442,
        0.5336615328674116,
        0.5378928321996382,
        0.5420231279619303,
        0.5460125478876939,
        0.5498212197103357,
        0.5534092711632616,
        0.556736829979878,
        0.5597640238935911,
        0.5627030536578802,
        0.5657324531339704,
        0.5687618497168873,
        0.5717008708016568,
        0.574459143783304,
        0.576946296056855,
        0.579071955017335,
        0.5807457480597696,
        0.5818773025791847,
        0.5823762459706054,
        0.5821522056290576,
        0.5811148089495669,
        0.5793621682576399,
        0.5770553069658437,
        0.5741982190997952,
        0.5707948986851106,
        0.5668493397474059,
        0.5623655363122984,
        0.5573474824054038,
        0.5517991720523384,
        0.5457245992787193,
        0.5391277581101626,
        0.5320126425722842,
        0.5243832466907015,
        0.5158743160271699,
        0.5062490588203161,
        0.49570416311106363,
        0.4844363169403343,
        0.47264220834905063,
        0.46051852537813587,
        0.4482619560685122,
        0.4360691884611018,
        0.4241369105968282,
        0.4126618105166134,
        0.4018405762613797,
        0.39186989587205046,
        0.3824935166576551,
        0.37333379995202365,
        0.3644053548332812,
        0.35572279037955107,
        0.34730071566895687,
        0.33915373977962326,
        0.33129647178967375,
        0.32374352077723206,
        0.31650949582042276,
        0.30960900599736935,
        0.3030566603861956,
        0.29686706806502605,
        0.29111819040795933,
        0.2858437020223775,
        0.28099178183633017,
        0.27651060877786593,
        0.27234836177503347,
        0.2684532197558822,
        0.2647733616484608,
        0.26125696638081825,
        0.2578522128810035,
        0.2545072800770656,
        0.25117034689705314,
        0.24778959226901548,
        0.24453024965473572,
        0.24155399969929547,
        0.23880369210569083,
        0.23622217657691785,
        0.23375230281597262,
        0.2313369205258514,
        0.22891887940955014,
        0.22644102917006492,
        0.223846219510392,
        0.22107730013352742,
        0.21807712074246713,
        0.21478853104020743,
        0.21126357752511196,
        0.20759772647468894,
        0.2037989572606519,
        0.19987524925471384,
        0.19583458182858784,
        0.19168493435398742,
        0.1874342862026256,
        0.18309061674621543,
        0.17866190535647047,
        0.17415613140510375,
        0.16958127426382824,
        0.16494531330435755,
        0.16013026516428516,
        0.15505234475293778,
        0.14977082884962914,
        0.14434499423367228,
        0.13883411768438017,
        0.13329747598106662,
        0.12779434590304456,
        0.12238400422962709,
        0.11712572774012786,
        0.11207879321385986,
        0.10730247743013623,
        0.10285605716827058,
        0.09861214721731489,
        0.09442501868377864,
        0.09032643282316165,
        0.08634815089096322,
        0.08252193414268265,
        0.07887954383381975,
        0.07545274121987382,
        0.07227328755634417,
        0.06937294409873059,
        0.06678347210253237,
        0.06453663282324888
    ],
    "WEEKDAY_NO2LOW_CONGESTION_AM_PEAK_NONFREEWAY": [
        0.04217382668528118,
        0.04241713341505981,
        0.04275551478264631,
        0.04316776257581583,
        0.04363266858234351,
        0.044129024590004465,
        0.044635622386573856,
        0.0451312537598268,
        0.045594710497538465,
        0.04600478438748395,
        0.04634026721743842,
        0.046579950775177,
        0.04670262684847482,
        0.046681651261733556,
        0.04652320486199961,
        0.04625531697101511,
        0.045906016910522277,
        0.04550333400226328,
        0.0450752975679803,
        0.044649936929415525,
        0.04425528140831113,
        0.0439193603264093,
        0.043670203005452234,
        0.04353583876718209,
        0.04354429693334106,
        0.04361884797692765,
        0.043680086575222175,
        0.0437520283563895,
        0.043858688948594515,
        0.0440240839800021,
        0.044272229078777134,
        0.04462713987308449,
        0.04511283199108904,
        0.04575332106095568,
        0.04657262271084929,
        0.047594752568934716,
        0.048843726263376894,
        0.05001993422442067,
        0.050906516949678376,
        0.05165161516836609,
        0.05240336960969981,
        0.053309921002895624,
        0.05451941007716959,
        0.056179977561737725,
        0.05843976418581607,
        0.0614469106786207,
        0.06534955776936766,
        0.07029584618727296,
        0.07643391666155272,
        0.08344169595580343,
        0.09095262030589639,
        0.0990480946494595,
        0.1078095239241209,
        0.11731831306750863,
        0.12765586701725057,
        0.13890359071097483,
        0.15114288908630955,
        0.16445516708088256,
        0.17892182963232192,
        0.1946242816782558,
        0.21164392815631208,
        0.23075436812551745,
        0.2523590865096587,
        0.2759843170435028,
        0.3011562934618177,
        0.3274012494993713,
        0.35424541889093064,
        0.3812150353712635,
        0.4078363326751377,
        0.43363554453732045,
        0.45813890469257973,
        0.4808726468756829,
        0.5013630048213976,
        0.5207450274492427,
        0.5402578545428023,
        0.5595838561329406,
        0.578405402250523,
        0.5964048629264141,
        0.6132646081914788,
        0.6286670080765819,
        0.6422944326125886,
        0.6538292518303631,
        0.6629538357607707,
        0.6693505544346761,
        0.6727017778829442,
        0.6723088522276122,
        0.668073684967215,
        0.6605794881973637,
        0.6504094740136694,
        0.638146854511743,
        0.6243748417871963,
        0.6096766479356398,
        0.5946354850526846,
        0.5798345652339423,
        0.5658571005750236,
        0.5532863031715398,
        0.542705385119102,
        0.5331941718494313,
        0.523530866351138,
        0.5138462470551669,
        0.5042710923924635,
        0.49493618079397295,
        0.48597229069063996,
        0.4775102005134097,
        0.4696806886932274,
        0.4626145336610379,
        0.45644251384778645,
        0.4512954076844181,
        0.4473039936018779,
        0.4445970569248948,
        0.443113884571811,
        0.44270100736399115,
        0.4432049561227995,
        0.44447226166960074,
        0.44634945482575955,
        0.44868306641264055,
        0.45131962725160796,
        0.4541056681640267,
        0.45688771997126126,
        0.4595123134946761,
        0.46182597955563587,
        0.4640983021621226,
        0.4666682556959092,
        0.4694864565390458,
        0.47250352107358323,
        0.4756700656815719,
        0.4789367067450623,
        0.4822540606461048,
        0.4855727437667501,
        0.48884337248904863,
        0.4920165631950509,
        0.4950429322668073,
        0.4978730960863685,
        0.5006356201293017,
        0.5034661918269129,
        0.5063261104948605,
        0.5091766754488029,
        0.5119791860043984,
        0.5146949414773058,
        0.517285241183183,
        0.5197113844376887,
        0.5219346705564814,
        0.5239163988552195,
        0.525617868649561,
        0.5270003792551647,
        0.5279427091709277,
        0.5284099363699751,
        0.5284928093793081,
        0.5282820767259272,
        0.5278684869368327,
        0.5273427885390264,
        0.5267957300595083,
        0.5263180600252795,
        0.5260005269633407,
        0.5259338794006925,
        0.526208865864336,
        0.5269162348812717,
        0.5280186010051255,
        0.5293931751042039,
        0.531002600177529,
        0.5328095192241221,
        0.5347765752430051,
        0.5368664112332,
        0.5390416701937285,
        0.5412649951236121,
        0.5434990290218729,
        0.5457064148875325,
        0.5478497957196127,
        0.5498918145171352,
        0.5519739468673511,
        0.5542269757813962,
        0.55659750539412,
        0.5590321398403714,
        0.5614774832549999,
        0.5638801397728549,
        0.5661867135287854,
        0.5683438086576407,
        0.5702980292942701,
        0.5719959795735231,
        0.5733842636302485,
        0.5744094855992958,
        0.575209773671579,
        0.5759312776396617,
        0.5765326340408691,
        0.5769724794125256,
        0.5772094502919562,
        0.577202183216486,
        0.5769093147234394,
        0.5762894813501411,
        0.5753013196339163,
        0.5739034661120893,
        0.5720545573219854,
        0.569713229800929,
        0.5668910494951153,
        0.5636405003295862,
        0.5599815958099386,
        0.555934349441768,
        0.5515187747306707,
        0.5467548851822428,
        0.5416626943020804,
        0.5362622155957792,
        0.530573462568936,
        0.5246164487271463,
        0.5184111875760063,
        0.5119776926211125,
        0.5051164550873393,
        0.4976786819298656,
        0.48976046024974457,
        0.4814578771480286,
        0.4728670197257699,
        0.46408397508402177,
        0.4552048303238364,
        0.4463256725462662,
        0.43754258885236436,
        0.42895166634318316,
        0.42064899211977486,
        0.4127306532831927,
        0.4050429432080638,
        0.39738322629527506,
        0.38977419618526987,
        0.38223854651849015,
        0.3747989709353782,
        0.36747816307637693,
        0.3602988165819287,
        0.3532836250924757,
        0.34645528224846095,
        0.33983648169032654,
        0.3334499170585149,
        0.3273182819934689,
        0.32147137473485654,
        0.3159034011001656,
        0.31058366609656896,
        0.30548147473123816,
        0.300566132011345,
        0.2958069429440621,
        0.29117321253656125,
        0.2866342457960144,
        0.28215934772959395,
        0.27771782334447176,
        0.2732789776478196,
        0.2688121156468102,
        0.2644191150090675,
        0.26019829242434644,
        0.25611361143301564,
        0.2521290355754429,
        0.24820852839199625,
        0.24431605342304402,
        0.2404155742089542,
        0.23647105429009477,
        0.23244645720683418,
        0.22830574649954025,
        0.22401288570858097,
        0.21953183837432483,
        0.21488997258359,
        0.2101440168930408,
        0.20530197554781526,
        0.20037185279305078,
        0.1953616528738847,
        0.19027938003545508,
        0.18513303852289922,
        0.17993063258135458,
        0.17468016645595913,
        0.16938964439185014,
        0.16406707063416512,
        0.15872044942804203,
        0.15325820299086995,
        0.14761332264810978,
        0.1418306663070077,
        0.135955091874809,
        0.13003145725875906,
        0.12410462036610388,
        0.11821943910408882,
        0.11242077137995919,
        0.1067534751009611,
        0.10126240817433986,
        0.09599242850734079,
        0.09098839400720998,
        0.08610281192120775,
        0.08119288732604996,
        0.07631052487316595,
        0.07150762921398432,
        0.06683610499993378,
        0.062347856882443584,
        0.05809478951294236,
        0.054128807542858835,
        0.05050181562362222,
        0.04726571840666117,
        0.04447242054340446
    ],
    "WEEKDAY_NO2LOW_CONGESTION_PM_PEAK_FREEWAY": [
        0.0830390872337165,
        0.08144610391987235,
        0.07934488437220583,
        0.07683225230049055,
        0.07400503141450025,
        0.07096004542400852,
        0.06779411803878907,
        0.06460407296861553,
        0.06148673392326159,
        0.05853892461250087,
        0.055857468746107065,
        0.05353919003385381,
        0.05168091218551478,
        0.05021583129686855,
        0.048995809927293896,
        0.04799567148196469,
        0.04719023936605482,
        0.046554336984738195,
        0.0460627877431887,
        0.04569041504658024,
        0.0454120423000867,
        0.045202492908882,
        0.04503659027814002,
        0.044889157813034646,
        0.04473501891873981,
        0.04457922921481524,
        0.04445247128988198,
        0.044368009002705905,
        0.0443391062120528,
        0.04437902677668854,
        0.044501034555378925,
        0.04471839340688981,
        0.045044367189987014,
        0.04549221976343638,
        0.04607521498600372,
        0.04680661671645488,
        0.047699688813555706,
        0.04853747699108793,
        0.049167449766440864,
        0.049699505203791655,
        0.05024354136731743,
        0.05090945632119539,
        0.051807148129602645,
        0.05304651485671634,
        0.05473745456671366,
        0.05698986532377172,
        0.0599136451920677,
        0.0636186922357787,
        0.06821490451908195,
        0.07355464102563354,
        0.07942405958411243,
        0.08583375652622965,
        0.0927943281836965,
        0.10031637088822414,
        0.10841048097152367,
        0.11708725476530626,
        0.12635728860128317,
        0.1362311788111655,
        0.14671952172666441,
        0.15783291367949118,
        0.16958195100135678,
        0.1825029476735715,
        0.19692563477692068,
        0.2125507342923277,
        0.22907896820071638,
        0.24621105848301073,
        0.26364772712013407,
        0.2810896960930102,
        0.2982376873825632,
        0.3147924229697162,
        0.3304546248353933,
        0.34492501496051825,
        0.35790431532601447,
        0.3699650564631731,
        0.3817980610945358,
        0.3932814894879007,
        0.4042935019110672,
        0.41471225863183414,
        0.42441591991800004,
        0.43328264603736394,
        0.4411905972577248,
        0.4480179338468813,
        0.4536428160726325,
        0.4579434042027771,
        0.4607978585051142,
        0.46178992107497135,
        0.4607876165899584,
        0.4580955291912976,
        0.45401824302021065,
        0.4488603422179195,
        0.4429264109256458,
        0.43652103328461184,
        0.429948793436039,
        0.4235142755211498,
        0.4175220636811655,
        0.41227674205730847,
        0.40808289479080045,
        0.40445649133416267,
        0.400757238639485,
        0.3970553270973418,
        0.3934209470983074,
        0.3899242890329564,
        0.386635543291863,
        0.3836249002656019,
        0.38096255034474746,
        0.37871868391987396,
        0.3769634913815559,
        0.37576716312036784,
        0.375199889526884,
        0.3753570293891153,
        0.37622269423698745,
        0.37770020357394724,
        0.3796928769034413,
        0.38210403372891627,
        0.3848369935538192,
        0.38779507588159656,
        0.39088160021569507,
        0.3939998860595617,
        0.3970532529166431,
        0.3999450202903857,
        0.40257850768423664,
        0.40511505033626577,
        0.4077627857578482,
        0.4104952368623891,
        0.4132859265632931,
        0.416108377773965,
        0.4189361134078098,
        0.4217426563782322,
        0.4245015295986371,
        0.4271862559824295,
        0.42977035844301387,
        0.43222735989379524,
        0.4345307832481786,
        0.4366435425506732,
        0.4385709217422524,
        0.44034999858101725,
        0.44201785082506845,
        0.443611556232507,
        0.44516819256143386,
        0.4467248375699497,
        0.44831856901615563,
        0.44998646465815245,
        0.4517656022540411,
        0.45369305956192224,
        0.4558059143398971,
        0.45791767211424766,
        0.4598692061201344,
        0.46173864567897555,
        0.4636041201121894,
        0.46554375874119414,
        0.467635690887408,
        0.46995804587224915,
        0.472588953017136,
        0.4756065416434867,
        0.4790889410727195,
        0.4831142806262525,
        0.48776068962550423,
        0.49293459052702987,
        0.49849120199799907,
        0.5044318476757522,
        0.5107578511976293,
        0.5174705362009699,
        0.5245712263231147,
        0.5320612452014033,
        0.5399419164731757,
        0.5482145637757723,
        0.5568805107465329,
        0.5659410810227973,
        0.575397598241906,
        0.5856078268697873,
        0.5967979912349338,
        0.608772104768532,
        0.6213341809017683,
        0.634288233065828,
        0.6474382746918982,
        0.6605883192111648,
        0.6735423800548138,
        0.6861044706540316,
        0.6980786044400045,
        0.7092687948439182,
        0.7194790552969598,
        0.7293702039053986,
        0.7395765648119299,
        0.7498624105023802,
        0.7599920134625741,
        0.7697296461783365,
        0.7788395811354929,
        0.7870860908198684,
        0.7942334477172883,
        0.8000459243135781,
        0.8042877930945626,
        0.8067233265460669,
        0.8071167971539165,
        0.8055271222101233,
        0.8022489518250675,
        0.7974001577121267,
        0.7910986115846776,
        0.7834621851560982,
        0.774608750139766,
        0.7646561782490582,
        0.7537223411973519,
        0.7419251106980255,
        0.7293823584644555,
        0.7162119562100192,
        0.702531775648095,
        0.6874905463060583,
        0.6704179632867358,
        0.6517033466666835,
        0.6317360165224558,
        0.6109052929306065,
        0.5896004959676923,
        0.5682109457102666,
        0.547125962234884,
        0.5267348656181007,
        0.5074269759364706,
        0.4895916132665477,
        0.47361809768488855,
        0.45914445970573703,
        0.4455156423000978,
        0.43268133422966737,
        0.42059122425614015,
        0.4091950011412109,
        0.39844235364657576,
        0.38828297053392935,
        0.37866654056496657,
        0.3695427525013834,
        0.36086129510487464,
        0.352571857137135,
        0.3446241273598605,
        0.33722490855649995,
        0.3305606874855804,
        0.32455067887141525,
        0.3191140974383166,
        0.314170157910597,
        0.30963807501256935,
        0.30543706346854604,
        0.3014863380028395,
        0.29770511333976274,
        0.294012604203628,
        0.2903280253187478,
        0.286570591409435,
        0.2830190875026661,
        0.27993905164363014,
        0.2772298280839598,
        0.2747907610752868,
        0.27252119486924326,
        0.2703204737174615,
        0.26808794187157337,
        0.2657229435832109,
        0.2631248231040065,
        0.260192924685592,
        0.2568265925795995,
        0.25292517103766116,
        0.2484409191853488,
        0.24345326273715442,
        0.23805229582809176,
        0.2323281125931738,
        0.22637080716741342,
        0.22027047368582442,
        0.21411720628341968,
        0.20800109909521217,
        0.20201224625621558,
        0.19624074190144286,
        0.19077668016590704,
        0.1857101551846217,
        0.1810099471988393,
        0.1765579045784331,
        0.17231372365134845,
        0.16823710074552972,
        0.1642877321889215,
        0.16042531430946885,
        0.15660954343511635,
        0.15280011589380849,
        0.14895672801349039,
        0.14503907612210654,
        0.14100685654760153,
        0.13681976561792047,
        0.132338082200687,
        0.12751686049026728,
        0.12245795980472035,
        0.11726323946210444,
        0.11203455878047791,
        0.10687377707789965,
        0.10188275367242798,
        0.09716334788212126,
        0.09281741902503839,
        0.08894682641923761,
        0.08565342938277738
    ],
    "WEEKDAY_NO2LOW_CONGESTION_PM_PEAK_NONFREEWAY": [
        0.06664764756272741,
        0.06541639222231987,
        0.06383349760272723,
        0.06195864672136246,
        0.05985152259563853,
        0.057571808242968356,
        0.05517918668076492,
        0.052733340926441157,
        0.05029395399741003,
        0.04792070891108449,
        0.04567328868487751,
        0.04361137633620201,
        0.041794654882470965,
        0.040129215077311216,
        0.0384901877449439,
        0.036890816008673416,
        0.035344342991804126,
        0.03386401181764042,
        0.032463065609486697,
        0.031154747490647332,
        0.0299523005844267,
        0.0288689680141292,
        0.027917992903059203,
        0.027112618374521107,
        0.02646608755181929,
        0.025969031160279536,
        0.025601039890595263,
        0.025358796814122373,
        0.025238985002216736,
        0.025238287526234224,
        0.025353387457530706,
        0.02558096786746207,
        0.02591771182738419,
        0.02636030240865294,
        0.026905422682624202,
        0.02754975572065384,
        0.028289984594097737,
        0.029032947679473096,
        0.02972297877490263,
        0.030413004084150493,
        0.031155949810980826,
        0.0320047421591578,
        0.03301230733244555,
        0.03423157153460824,
        0.03571546096941001,
        0.03751690184061502,
        0.039688820351987425,
        0.042284142707291354,
        0.045355795110291,
        0.04871641569441038,
        0.052189036504085816,
        0.05584217460959964,
        0.059744347081234286,
        0.0639640709892722,
        0.06856986340399573,
        0.07363024139568732,
        0.07921372203462942,
        0.08538882239110436,
        0.09222405953539456,
        0.09978795053778255,
        0.10814901246855059,
        0.11757316666503258,
        0.12815488990158758,
        0.13970553240403538,
        0.152036444398196,
        0.16495897610988966,
        0.17828447776493606,
        0.19182429958915537,
        0.20538979180836764,
        0.21879230464839258,
        0.23184318833505047,
        0.2443537930941612,
        0.25613546915154467,
        0.2677196633106059,
        0.27965036874534455,
        0.2917577552374722,
        0.30387199256870084,
        0.3158232505207425,
        0.32744169887530866,
        0.3385575074141114,
        0.34900084591886277,
        0.35860188417127425,
        0.367190791953058,
        0.37459773904592564,
        0.38065289523158913,
        0.38513675881518733,
        0.38809491622903214,
        0.3897551694455946,
        0.39034532043734615,
        0.3900931711767579,
        0.3892265236363013,
        0.3879731797884475,
        0.3865609416056676,
        0.38521761106043334,
        0.3841709901252155,
        0.38364888077248566,
        0.38387908497471485,
        0.38450457844630764,
        0.3850496526719165,
        0.38556408327938435,
        0.3860976458965543,
        0.38670011615126976,
        0.3874212696713737,
        0.38831088208470926,
        0.38941872901911945,
        0.39079458610244755,
        0.3924882289625366,
        0.39454943322722985,
        0.3970279745243704,
        0.39993247378246943,
        0.40321411636202303,
        0.4068365245388528,
        0.41076332058877923,
        0.41495812678762356,
        0.4193845654112068,
        0.42400625873535,
        0.42878682903587384,
        0.4336898985885998,
        0.4386790896693488,
        0.4437180245539415,
        0.44877032551819923,
        0.4540609093997565,
        0.45977258988530284,
        0.46580583452424296,
        0.47206111086598085,
        0.47843888645992055,
        0.4848396288554664,
        0.4911638056020225,
        0.497311884248993,
        0.5031843323457821,
        0.5086816174417941,
        0.5137042070864328,
        0.5181525688291029,
        0.5221112650629569,
        0.5257426890751968,
        0.5290640547563004,
        0.5320925759967449,
        0.5348454666870079,
        0.5373399407175673,
        0.5395932119789003,
        0.5416224943614847,
        0.5434450017557979,
        0.5450779480523177,
        0.5465385471415214,
        0.547844012913887,
        0.548682945608493,
        0.5488384029515049,
        0.5484692850640308,
        0.5477344920671778,
        0.5467929240820538,
        0.5458034812297663,
        0.544925063631423,
        0.5443165714081312,
        0.5441369046809988,
        0.5445449635711331,
        0.545699648199642,
        0.5477598586876329,
        0.5505998868629844,
        0.5539663910295016,
        0.5578268210221827,
        0.5621486266760254,
        0.5668992578260271,
        0.5720461643071859,
        0.5775567959544998,
        0.5833986026029659,
        0.5895390340875827,
        0.5959455402433478,
        0.6025855709052587,
        0.6094265759083134,
        0.6167311362753842,
        0.6247018582919371,
        0.6332162296868622,
        0.642151738189048,
        0.6513858715273845,
        0.6607961174307605,
        0.6702599636280657,
        0.6796548978481892,
        0.6888584078200208,
        0.6977479812724492,
        0.7062011059343639,
        0.7140952695346544,
        0.7220436981164937,
        0.7305702761849306,
        0.7394184791616689,
        0.7483317824684117,
        0.7570536615268616,
        0.7653275917587231,
        0.7728970485856987,
        0.779505507429492,
        0.7848964437118064,
        0.7888133328543447,
        0.7909996502788108,
        0.7911988714069076,
        0.7895172544021533,
        0.7863022630903831,
        0.7816591813867764,
        0.7756932932065115,
        0.7685098824647674,
        0.7602142330767231,
        0.7509116289575579,
        0.7407073540224498,
        0.729706692186579,
        0.7180149273651236,
        0.705737343473262,
        0.6929792244261743,
        0.678915789083081,
        0.6629073215378365,
        0.6453367035575708,
        0.6265868169094125,
        0.6070405433604898,
        0.587080764677934,
        0.567090362628873,
        0.5474522189804354,
        0.5285492154997519,
        0.5107642339539507,
        0.4944801561101605,
        0.48007986373551165,
        0.46730971907418,
        0.4555906270810456,
        0.44481728358929434,
        0.4348843844321111,
        0.4256866254426808,
        0.4171187024541894,
        0.4090753112998218,
        0.40145114781276264,
        0.39414090782619815,
        0.3870392871733129,
        0.3800409816872921,
        0.3730406872013215,
        0.36629070269343966,
        0.360066459331651,
        0.3542973512340896,
        0.3489127725188888,
        0.34384211730418196,
        0.3390147797081031,
        0.33436015384878565,
        0.3298076338443629,
        0.32528661381296886,
        0.32072648787273694,
        0.31605665014180057,
        0.3112064947382937,
        0.30630032596200923,
        0.3014942711475785,
        0.29676545896539336,
        0.2920910180858453,
        0.28744807717932563,
        0.2828137649162262,
        0.2781652099669383,
        0.27347954100185334,
        0.26873388669136317,
        0.2639053757058592,
        0.2589711367157327,
        0.2539082983913755,
        0.24871849315929215,
        0.24342771037773983,
        0.23804961411474015,
        0.23259786843831395,
        0.2270861374164822,
        0.2215280851172664,
        0.21593737560868748,
        0.21032767295876617,
        0.2047126412355242,
        0.19910594450698238,
        0.19352124684116165,
        0.18797221230608338,
        0.18240200034608153,
        0.17675953344243614,
        0.1710671202185885,
        0.16534706929797913,
        0.1596216893040486,
        0.1539132888602381,
        0.1482441765899882,
        0.14263666111673937,
        0.13711305106393293,
        0.13169565505500933,
        0.12640678171340913,
        0.1212687396625736,
        0.11612010697054899,
        0.11083382390961113,
        0.10548374240954556,
        0.10014371440013703,
        0.0948875918111704,
        0.08978922657243114,
        0.08492247061370406,
        0.080361175864774,
        0.07617919425542642,
        0.0724503777154461,
        0.06924857817461799
    ],
    "WEEKDAY_MODERATE_CONGESTION_AM_PEAK_FREEWAY": [
        0.07070149195338099,
        0.06930196736581554,
        0.06746016207985925,
        0.06525958363996354,
        0.06278373959057978,
        0.06011613747615945,
        0.05734028484115394,
        0.0545396892300147,
        0.05179785818719311,
        0.049198299257140594,
        0.0468245199843086,
        0.04476002791314851,
        0.043088330588111776,
        0.04172178361581783,
        0.04051351415198659,
        0.03945818706472021,
        0.03855046722212077,
        0.03778501949229042,
        0.037156508743331276,
        0.036659599843345464,
        0.03628895766043511,
        0.03603924706270233,
        0.035905132918249254,
        0.03588128009517799,
        0.035962353461590685,
        0.03607633138900133,
        0.03618320207589835,
        0.03632564513084547,
        0.036546340162406425,
        0.03688796677914497,
        0.03739320458962482,
        0.03810473320240973,
        0.039065232226063416,
        0.040317381269149626,
        0.04190385994023208,
        0.04386734784787453,
        0.04625052460064072,
        0.048693859739399076,
        0.05092200158081455,
        0.053103897897145626,
        0.05540849646065074,
        0.05800474504358838,
        0.061061591418216994,
        0.06474798335679509,
        0.0692328686315811,
        0.07468519501483348,
        0.08127391027881076,
        0.08916796219577129,
        0.0985362985379737,
        0.10918181622325164,
        0.12079477935685393,
        0.13337518649216107,
        0.14692303618255378,
        0.1614383269814131,
        0.17692105744211942,
        0.1933712261180535,
        0.21078883156259634,
        0.22917387232912825,
        0.24852634697103027,
        0.268846254041683,
        0.29013359209446715,
        0.31354252968434587,
        0.33980753928806245,
        0.36829907534166695,
        0.39838759228121023,
        0.4294435445427431,
        0.4608373865623155,
        0.4919395727759782,
        0.522120557619782,
        0.5507507955297772,
        0.5772007409420145,
        0.600840848292544,
        0.6210415720174169,
        0.6386514927165214,
        0.6548973932050047,
        0.6697170312418043,
        0.6830481645858573,
        0.6948285509961022,
        0.7049959482314757,
        0.7134881140509163,
        0.7202428062133611,
        0.7251977824777479,
        0.7282908006030142,
        0.7294596183480976,
        0.728641993471936,
        0.7250204614320042,
        0.7181427181460363,
        0.7084942620633577,
        0.6965605916332955,
        0.6828272053051749,
        0.6677796015283225,
        0.6519032787520642,
        0.635683735425726,
        0.6196064699986339,
        0.604156980920114,
        0.5898207666394927,
        0.5770833256060955,
        0.5650978592138817,
        0.5527958609506507,
        0.5403302674064906,
        0.5278540151714889,
        0.5155200408357336,
        0.503481280989312,
        0.4918906722223119,
        0.48090115112482157,
        0.470665654286928,
        0.4613371182987194,
        0.45306847975028336,
        0.4460126752317075,
        0.4402260245247295,
        0.4355775275739958,
        0.43193914121395716,
        0.42918282227906485,
        0.42718052760376946,
        0.4258042140225222,
        0.424925838369774,
        0.4244173574799756,
        0.4241507281875783,
        0.42399790732703285,
        0.4238308517327902,
        0.4235215182393014,
        0.42332687440990807,
        0.423550128510972,
        0.42413259843147477,
        0.425015602060397,
        0.42614045728672023,
        0.4274484819994256,
        0.4288809940874943,
        0.43037931143990743,
        0.43188475194564635,
        0.43333863349369217,
        0.43468227397302595,
        0.43585699127262906,
        0.43691820995800895,
        0.43796521705560026,
        0.4390051241457742,
        0.4400450428089024,
        0.4410920846253564,
        0.44215336117550735,
        0.4432359840397271,
        0.44434706479838687,
        0.4454937150318582,
        0.44668304632051253,
        0.4479221702447213,
        0.4492181983848561,
        0.45051066639494736,
        0.4517533388762454,
        0.4529746708299521,
        0.4542031172572688,
        0.4554671331593969,
        0.45679517353753835,
        0.4582156933928942,
        0.45975714772666626,
        0.461447991540056,
        0.46331667983426483,
        0.4653916676104944,
        0.46770140986994624,
        0.47027792777144584,
        0.4731105565965711,
        0.47616372253065287,
        0.4794018517590216,
        0.48278937046700815,
        0.48629070483994286,
        0.48987028106315644,
        0.49349252532197957,
        0.49712186380174284,
        0.5007227226877768,
        0.5042595281654123,
        0.5076967064199797,
        0.5111702982517075,
        0.5148056828767437,
        0.5185512941042993,
        0.5223555657435843,
        0.5261669316038096,
        0.5299338254941856,
        0.5336046812239227,
        0.5371279326022311,
        0.5404520134383219,
        0.5435253575414051,
        0.5462963987206914,
        0.5487135707853913,
        0.5509381098214945,
        0.5531288746373102,
        0.5552307331255287,
        0.5571885531788393,
        0.5589472026899319,
        0.5604515495514965,
        0.5616464616562228,
        0.5624768068968006,
        0.5628874531659198,
        0.56282326835627,
        0.5622291203605412,
        0.5610498770714232,
        0.5593791972019454,
        0.5573368258683741,
        0.5549067605682545,
        0.552072998799131,
        0.548819538058549,
        0.5451303758440533,
        0.5409895096531888,
        0.5363809369835001,
        0.5312886553325327,
        0.5256966621978313,
        0.5195889550769407,
        0.5129495314674059,
        0.5055003771831105,
        0.49708203138102175,
        0.4878563215733116,
        0.47798507527215134,
        0.4676301199897116,
        0.4569532832381647,
        0.4461163925296815,
        0.4352812753764328,
        0.424609759290591,
        0.41426367178432694,
        0.40440484036981134,
        0.3951950925592167,
        0.38638693518105977,
        0.37764633347657345,
        0.3690053025770038,
        0.3604958576135954,
        0.35215001371759297,
        0.34399978602024245,
        0.3360771896527885,
        0.3284142397464757,
        0.32104295143255007,
        0.31399533984225625,
        0.30730342010683914,
        0.30099920735754426,
        0.2950942586357847,
        0.2895610028247376,
        0.2843727628182948,
        0.2795028615103475,
        0.27492462179478666,
        0.2706113665655041,
        0.266536418716391,
        0.26267310114133846,
        0.25899473673423823,
        0.25547464838898143,
        0.25208615899945913,
        0.24880259145956318,
        0.2457887499417327,
        0.2431786121260021,
        0.2408992611676557,
        0.23887778022197725,
        0.23704125244425087,
        0.23531676098976073,
        0.23363138901379063,
        0.23191221967162481,
        0.23008633611854723,
        0.228080821509842,
        0.22582275900079288,
        0.22323923174668428,
        0.2204413798901093,
        0.21757947462513555,
        0.21463929568425894,
        0.211606622799975,
        0.2084672357047791,
        0.2052069141311672,
        0.20181143781163483,
        0.19826658647867737,
        0.19455813986479087,
        0.19067187770247074,
        0.1865935797242124,
        0.1823090256625119,
        0.17770454354993573,
        0.1727233096990308,
        0.1674363762622641,
        0.16191479539210168,
        0.15622961924100978,
        0.15045189996145533,
        0.1446526897059044,
        0.1389030406268232,
        0.13327400487667862,
        0.12783663460793684,
        0.12266198197306405,
        0.11782109912452704,
        0.11313639739201552,
        0.10841735436445935,
        0.10371562158318595,
        0.09908285058952199,
        0.09457069292479421,
        0.09023080013032996,
        0.08611482374745592,
        0.08227441531749884,
        0.07876122638178601,
        0.07562690848164416,
        0.0729231131584001
    ],
    "WEEKDAY_MODERATE_CONGESTION_AM_PEAK_NONFREEWAY": [
        0.052357478700195366,
        0.05141489776161895,
        0.05016290756120834,
        0.04866199371764351,
        0.04697264184960439,
        0.04515533757577095,
        0.04327056651482311,
        0.04137881428544082,
        0.03954056650630404,
        0.037816308796092706,
        0.03626652677348676,
        0.03495170605716616,
        0.03393233226581085,
        0.03316067567449449,
        0.0325403785106131,
        0.03205898432133019,
        0.03170403665380925,
        0.03146307905521375,
        0.03132365507270717,
        0.031273308253453036,
        0.03129958214461481,
        0.031390020293356,
        0.03153216624684008,
        0.031713563552230546,
        0.03192175575669088,
        0.03211249528116086,
        0.03227121343296923,
        0.032429972088863016,
        0.03262083312558921,
        0.0328758584198948,
        0.033227109848526795,
        0.03370664928823221,
        0.03434653861575802,
        0.035178839707851246,
        0.036235614441258876,
        0.03754892469272791,
        0.03915083233900536,
        0.04076223433577834,
        0.04217054306203281,
        0.0435075935294641,
        0.0449052207497675,
        0.046495259734638306,
        0.04840954549577181,
        0.05077991304486333,
        0.05373819739360815,
        0.05741623355370158,
        0.061945856536838935,
        0.06745890135471547,
        0.07408720301902655,
        0.08154955203192239,
        0.08952317688201304,
        0.0980775695606829,
        0.10728222205931674,
        0.11720662636929928,
        0.127920274482015,
        0.13949265838884856,
        0.15199327008118477,
        0.16549160155040807,
        0.18005714478790316,
        0.19575939178505472,
        0.21266783453324736,
        0.23161270287101648,
        0.25304651543228335,
        0.27647219740151074,
        0.30139267396316216,
        0.327310870301701,
        0.35372971160158995,
        0.38015212304729235,
        0.4060810298232718,
        0.431019357113991,
        0.45447003010391335,
        0.4759359739775021,
        0.4949201139192203,
        0.5122800033896453,
        0.5291036216624573,
        0.5452395326417738,
        0.5605363002317129,
        0.5748424883363918,
        0.5880066608599283,
        0.5998773817064399,
        0.6103032147800446,
        0.6191327239848596,
        0.6262144732250028,
        0.6313970264045919,
        0.6345289474277445,
        0.6350846560055325,
        0.6328948949677833,
        0.6283427128967456,
        0.6218111583746689,
        0.6136832799838029,
        0.6043421263063968,
        0.5941707459247,
        0.5835521874209619,
        0.5728694993774319,
        0.5625057303763593,
        0.5528439289999936,
        0.5442671438305842,
        0.5359234738973294,
        0.5268529292199928,
        0.5172764248666708,
        0.5074148759054601,
        0.4974891974044577,
        0.4877203044317599,
        0.47832911205546363,
        0.4695365353436656,
        0.4615634893644621,
        0.45463088918595024,
        0.4489596498762267,
        0.4447706865033879,
        0.44212635968773417,
        0.44082535520426014,
        0.4406930758531041,
        0.4415549244344045,
        0.4432363037482998,
        0.4455626165949282,
        0.4483592657744283,
        0.4514516540869383,
        0.4546651843325968,
        0.45782525931154194,
        0.46075728182391235,
        0.46328665466984625,
        0.46570198537017354,
        0.4683774029289075,
        0.4712665923709621,
        0.47432323872125126,
        0.47750102700468866,
        0.4807536422461884,
        0.48403476947066437,
        0.4872980937030302,
        0.4904972999682001,
        0.4935860732910878,
        0.49651809869660707,
        0.499247061209672,
        0.501872737581667,
        0.5045091475362942,
        0.5071313405569444,
        0.5097143661270077,
        0.5122332737298753,
        0.5146631128489372,
        0.516978932967584,
        0.5191557835692063,
        0.5211687141371947,
        0.5229927741549396,
        0.5246030131058318,
        0.5259744804732613,
        0.5270252251609543,
        0.5277303152166737,
        0.5281503288398658,
        0.5283458442299772,
        0.528377439586455,
        0.5283056931087451,
        0.5281911829962946,
        0.5280944874485498,
        0.5280761846649575,
        0.5281968528449641,
        0.5285170701880163,
        0.5290974148935604,
        0.5298639530221796,
        0.5307035503948478,
        0.5316179848515997,
        0.5326090342324696,
        0.533678476377492,
        0.534828089126701,
        0.5360596503201313,
        0.537374937797817,
        0.5387757293997927,
        0.5402638029660926,
        0.5418409363367512,
        0.5435089073518029,
        0.5453538146776484,
        0.5474285089128686,
        0.5496848957957688,
        0.5520748810646541,
        0.554550370457829,
        0.5570632697135988,
        0.5595654845702687,
        0.5620089207661436,
        0.5643454840395284,
        0.5665270801287284,
        0.5685056147720483,
        0.5702329937077933,
        0.5719123275653756,
        0.5737253350308156,
        0.5755918339273309,
        0.5774316420781387,
        0.5791645773064565,
        0.5807104574355019,
        0.581989100288492,
        0.5829203236886442,
        0.5834239454591761,
        0.5834197834233049,
        0.5828276554042483,
        0.5815673792252235,
        0.5796998274635835,
        0.5773512086795883,
        0.5745393446708166,
        0.5712820572348462,
        0.5675971681692553,
        0.5635024992716224,
        0.5590158723395254,
        0.5541551091705427,
        0.5489380315622523,
        0.5433824613122324,
        0.5375062202180612,
        0.5313271300773171,
        0.5246382220053574,
        0.5172883465691415,
        0.509379054743653,
        0.5010118975038741,
        0.49228842582478727,
        0.48331019068137593,
        0.47417874304862223,
        0.4649956339015086,
        0.4558624142150185,
        0.44688063496413416,
        0.438151847123838,
        0.4297776016691133,
        0.4215479743636568,
        0.41321859417529444,
        0.40483934188520304,
        0.3964600982745585,
        0.38813074412453674,
        0.37990116021631465,
        0.37182122733106815,
        0.36394082624997315,
        0.35630983775420644,
        0.34897814262494414,
        0.34199562164336195,
        0.3354121555906368,
        0.3292577336433831,
        0.32349702442543593,
        0.3180819278888028,
        0.3129643439854906,
        0.3080961726675063,
        0.30342931388685734,
        0.29891566759555055,
        0.29450713374559295,
        0.2901556122889921,
        0.2858130031777548,
        0.28143120636388796,
        0.2769621217993991,
        0.2725402502916519,
        0.26830365604314277,
        0.2642095840985781,
        0.26021527950266377,
        0.25627798730010576,
        0.2523549525356105,
        0.24840342025388393,
        0.24438063549963182,
        0.2402438433175607,
        0.2359502887523765,
        0.2314572168487851,
        0.22672187265149293,
        0.2217320868526973,
        0.2165267571168445,
        0.21113972894701397,
        0.20560484784628505,
        0.1999559593177367,
        0.19422690886444866,
        0.1884515419895,
        0.18266370419596986,
        0.17689724098693796,
        0.1711859978654833,
        0.16556382033468503,
        0.16006455389762292,
        0.15459659663638733,
        0.1490647340544038,
        0.14349739278675472,
        0.13792299946852168,
        0.1323699807347865,
        0.12686676322063142,
        0.12144177356113811,
        0.1161234383913883,
        0.11094018434646434,
        0.10592043806144792,
        0.10109262617142066,
        0.09648517531146496,
        0.09198638051814005,
        0.08749414594007608,
        0.08305131188046158,
        0.07870071864248454,
        0.07448520652933294,
        0.07044761584419529,
        0.06663078689025954,
        0.06307755997071378,
        0.05983077538874643,
        0.05693327344754547,
        0.054427894450299004
    ],
    "WEEKDAY_MODERATE_CONGESTION_PM_PEAK_FREEWAY": [
        0.08177336524469803,
        0.0803902235106144,
        0.07858715737701019,
        0.0764403762889496,
        0.07402608969149677,
        0.07142050702971582,
        0.0686998377486709,
        0.06594029129342616,
        0.06321807710904573,
        0.06060940464059372,
        0.058190483333134334,
        0.05603752263173169,
        0.054226731981449894,
        0.05267895967750157,
        0.051260698876770226,
        0.049966626316826336,
        0.048791418735240406,
        0.047729752869582934,
        0.0467763054574244,
        0.04592575323633532,
        0.04517277294388615,
        0.04451204131764744,
        0.04393823509518964,
        0.04344603101408327,
        0.04303010581189881,
        0.04264136541157243,
        0.04225437532307747,
        0.041899301664540305,
        0.04160631055408723,
        0.04140556810984459,
        0.0413272404499387,
        0.04140149369249591,
        0.04165849395564254,
        0.04212840735750493,
        0.04284140001620941,
        0.04382763804988229,
        0.04511728757664993,
        0.04656132883124787,
        0.048032022561460906,
        0.04959145565498929,
        0.05130171499953322,
        0.0532248874827929,
        0.05542305999246854,
        0.05795831941626037,
        0.06089275264186857,
        0.06428844655699338,
        0.06820748804933503,
        0.07271196400659367,
        0.07786396131646958,
        0.0834739441111472,
        0.0893535675063437,
        0.09556670486505829,
        0.10217722955029024,
        0.10924901492503881,
        0.11684593435230321,
        0.12503186119508267,
        0.1338706688163766,
        0.14342623057918397,
        0.1537624198465042,
        0.16494310998133654,
        0.1770321743466802,
        0.19057633803174195,
        0.20585383697001805,
        0.22252581079094128,
        0.24025339912394503,
        0.2586977415984626,
        0.2775199778439265,
        0.29638124748977046,
        0.31494269016542736,
        0.3328654455003303,
        0.3498106531239125,
        0.3654394526656068,
        0.3794129837548467,
        0.3923835345714574,
        0.40513586345231,
        0.41752981526240557,
        0.42942523486674594,
        0.4406819671303323,
        0.45115985691816635,
        0.460718749095249,
        0.4692184885265824,
        0.4765189200771673,
        0.4824798886120055,
        0.4869612389960984,
        0.4898228160944474,
        0.49061991147774237,
        0.48922242538369354,
        0.48596211567783276,
        0.481170740225692,
        0.47518005689280296,
        0.4683218235446976,
        0.4609277980469078,
        0.45332973826496525,
        0.4458594020644021,
        0.43884854731074985,
        0.4326289318695407,
        0.42753231360630634,
        0.4230220138842266,
        0.41839459226292697,
        0.41373166390260707,
        0.4091148439634666,
        0.4046257476057054,
        0.4003459899895231,
        0.39635718627511946,
        0.3927409516226945,
        0.38957890119244776,
        0.38695265014457897,
        0.3849438136392879,
        0.3836340068367745,
        0.38308739120232954,
        0.3832557620391024,
        0.3840521852640797,
        0.38538972679424843,
        0.38718145254659536,
        0.38934042843810757,
        0.3917797203857716,
        0.39441239430657443,
        0.3971515161175029,
        0.39991015173554395,
        0.40260136707768424,
        0.4051382280609108,
        0.40772949167715394,
        0.41060284046638357,
        0.41369972866764604,
        0.416961610519988,
        0.4203299402624554,
        0.42374617213409504,
        0.4271517603739529,
        0.4304881592210755,
        0.4336968229145094,
        0.43671920569330064,
        0.43949676179649594,
        0.4419709454631414,
        0.44410598282673625,
        0.44594297680236566,
        0.4475386968014552,
        0.44894991223543035,
        0.45023339251571665,
        0.45144590705373944,
        0.45264422526092457,
        0.4538851165486974,
        0.4552253503284835,
        0.45672169601170826,
        0.4584309230097973,
        0.46040980073417626,
        0.4625010156899231,
        0.4645425290223503,
        0.4665840221032357,
        0.468675176304357,
        0.4708656729974914,
        0.47320519355441654,
        0.4757434193469101,
        0.4785300317467494,
        0.4816147121257121,
        0.4850471418555758,
        0.48887700230811787,
        0.493153974855116,
        0.49783962713302166,
        0.5028553043230921,
        0.5081903541143138,
        0.5138341241956725,
        0.5197759622561547,
        0.5260052159847465,
        0.532511233070434,
        0.5392833612022034,
        0.5463109480690409,
        0.5535833413599328,
        0.5610898887638649,
        0.5688199379698238,
        0.5770753837750732,
        0.5860599617535233,
        0.5956157807591302,
        0.6055849496458496,
        0.6158095772676367,
        0.6261317724784481,
        0.6363936441322389,
        0.6464373010829647,
        0.6561048521845817,
        0.6652384062910457,
        0.673680072256312,
        0.6812719589343366,
        0.6884714910350836,
        0.6957420018969422,
        0.7029344633165058,
        0.7098998470903674,
        0.7164891250151197,
        0.7225532688873564,
        0.7279432505036703,
        0.7325100416606544,
        0.7361046141549021,
        0.7385779397830066,
        0.7397809903415608,
        0.7395647376271578,
        0.7379859599715454,
        0.735243365262279,
        0.7314008196296649,
        0.7265221892040087,
        0.7206713401156155,
        0.713912138494792,
        0.7063084504718435,
        0.6979241421770753,
        0.6888230797407939,
        0.6790691292933047,
        0.6687261569649127,
        0.6578580288859249,
        0.6458763244533599,
        0.6323369220504408,
        0.6175201362867823,
        0.6017062817719969,
        0.5851756731156973,
        0.568208624927498,
        0.5510854518170117,
        0.5340864683938508,
        0.5174919892676301,
        0.501582329047962,
        0.48663780234445914,
        0.4729387237667361,
        0.46010602230243053,
        0.44759267318773355,
        0.43542706040508966,
        0.42363756793694185,
        0.4122525797657329,
        0.4013004798739074,
        0.390809652243908,
        0.3808084808581778,
        0.3713253496991611,
        0.3623886427493009,
        0.3540267439910401,
        0.34626803740682305,
        0.3392550441867874,
        0.3330474952892644,
        0.3275495893489881,
        0.3226655250006914,
        0.31829950087910763,
        0.31435571561897047,
        0.31073836785501296,
        0.3073516562219685,
        0.3040997793545707,
        0.3008869358875526,
        0.29761732445564765,
        0.29419514369358934,
        0.2908557577440436,
        0.2878534543689978,
        0.2851208176321677,
        0.28259043159726854,
        0.2801948803280158,
        0.2778667478881251,
        0.27553861834131177,
        0.27314307575129126,
        0.2706127041817792,
        0.2678800876964909,
        0.2648778103591418,
        0.2615384562334475,
        0.25793417191943313,
        0.2541844135476067,
        0.25029272947740844,
        0.24626266806827843,
        0.24209777767965668,
        0.23780160667098352,
        0.2333777034016989,
        0.2288296162312427,
        0.22416089351905552,
        0.21937508362457717,
        0.21447573490724756,
        0.20946639572650722,
        0.20425200558599532,
        0.1987682005050021,
        0.19306457361644466,
        0.1871907180532394,
        0.18119622694830262,
        0.17513069343455143,
        0.1690437106449022,
        0.16298487171227138,
        0.15700376976957597,
        0.15114999794973244,
        0.14547314938565709,
        0.14002281721026705,
        0.13459137176746147,
        0.1289925834947216,
        0.1233081456653377,
        0.11761975155259924,
        0.11200909442979579,
        0.10655786757021753,
        0.10134776424715396,
        0.0964604777338947,
        0.09197770130372984,
        0.08798112822994897,
        0.08455245178584168
    ],
    "WEEKDAY_MODERATE_CONGESTION_PM_PEAK_NONFREEWAY": [
        0.06536586876435262,
        0.06403330087876113,
        0.062293590198126726,
        0.0602211061723845,
        0.057890218251469644,
        0.0553752958853173,
        0.05275070852386261,
        0.05009082561704074,
        0.047470016614786836,
        0.04496265096703604,
        0.042643098123723536,
        0.040585727534784455,
        0.03886490865015395,
        0.03741012849218985,
        0.036095384857008805,
        0.03491181335518418,
        0.03385054959728932,
        0.032902729193897556,
        0.03205948775558229,
        0.03131196089291685,
        0.0306512842164746,
        0.03006859333682889,
        0.029555023864553085,
        0.029101711410220537,
        0.028699791584404603,
        0.028330354750456845,
        0.02799103774624826,
        0.027697795894131294,
        0.027466584516458413,
        0.027313358935582073,
        0.02725407447385474,
        0.027304686453628872,
        0.027481150197256932,
        0.027799421027091386,
        0.02827545426548469,
        0.028925205234789298,
        0.02976462925735769,
        0.030687362821482177,
        0.03160831424987336,
        0.03257534661651115,
        0.03363632299537543,
        0.034839106460446106,
        0.036231560085703066,
        0.0378615469451262,
        0.039776930112695404,
        0.042025572662390584,
        0.044655337668191616,
        0.04771408820407839,
        0.05124968734403086,
        0.05510555643036271,
        0.05912984023656838,
        0.0633774869833985,
        0.06790344489160385,
        0.07276266218193522,
        0.07801008707514326,
        0.08370066779197871,
        0.08988935255319241,
        0.09663108957953492,
        0.10398082709175709,
        0.11199351331060967,
        0.12072409645684329,
        0.13042694747561082,
        0.14120399527300884,
        0.15288152501120328,
        0.16528582185236046,
        0.1782431709586467,
        0.19157985749222778,
        0.20512216661527025,
        0.21869638348994014,
        0.23212879327840366,
        0.2452456811428269,
        0.2578733322453762,
        0.2698380317482175,
        0.2817064269697809,
        0.2940286187539767,
        0.3066060725511918,
        0.3192402538118136,
        0.33173262798622893,
        0.343884660524825,
        0.3554978168779888,
        0.3663735624961074,
        0.37631336282956795,
        0.3851186833287574,
        0.39259098944406295,
        0.39853174662587165,
        0.4026981034403229,
        0.40515210121090145,
        0.40615254452401206,
        0.4059582379660596,
        0.40482798612344917,
        0.40302059358258546,
        0.40079486492987343,
        0.39840960475171794,
        0.396123617634524,
        0.3941957081646963,
        0.39288468092863976,
        0.39244934051275937,
        0.3925065098937525,
        0.3925300168716208,
        0.39256417326772663,
        0.3926532909034319,
        0.39284168160009864,
        0.39317365717908886,
        0.3936935294617644,
        0.3944456102694875,
        0.39547421142362005,
        0.3968236447455242,
        0.39853822205656175,
        0.40066225517809473,
        0.4032155339655977,
        0.40616231179470497,
        0.4094635957670184,
        0.41308039298413946,
        0.4169737105476699,
        0.42110455555921184,
        0.4254339351203664,
        0.42992285633273575,
        0.43453232629792143,
        0.4392233521175252,
        0.44395694089314885,
        0.4486940997263941,
        0.4536493182481366,
        0.45900162756718593,
        0.46465884700204546,
        0.4705287958712184,
        0.47651929349320743,
        0.4825381591865162,
        0.4884932122696475,
        0.4942922720611045,
        0.4998431578793905,
        0.5050536890430083,
        0.5098316848704614,
        0.5140849646802527,
        0.5179015698832615,
        0.5214380875753166,
        0.5247051556023459,
        0.5277134118102764,
        0.5304734940450357,
        0.5329960401525515,
        0.535291687978751,
        0.537371075369562,
        0.5392448401709115,
        0.5409236202287272,
        0.5424180533889367,
        0.5437387774974674,
        0.5446036480652707,
        0.5448215136764863,
        0.544539501786823,
        0.5439047398519894,
        0.5430643553276947,
        0.5421654756696476,
        0.5413552283335569,
        0.5407807407751316,
        0.5405891404500806,
        0.5409275548141124,
        0.5419431113229365,
        0.5437829374322612,
        0.5463631307607353,
        0.5494697962049068,
        0.5530550721373352,
        0.5570710969305788,
        0.5614700089571966,
        0.5662039465897475,
        0.5712250482007902,
        0.5764854521628837,
        0.581937296848587,
        0.5875327206304588,
        0.5932238618810575,
        0.5989628589729428,
        0.6049615403878794,
        0.6114069187532898,
        0.61821390866022,
        0.6252974246997144,
        0.6325723814628182,
        0.6399536935405771,
        0.6473562755240359,
        0.6546950420042397,
        0.6618849075722338,
        0.6688407868190636,
        0.6754775943357738,
        0.68171024471341,
        0.6879954815962066,
        0.694716780476627,
        0.7016791537180608,
        0.708687613683896,
        0.7155471727375213,
        0.7220628432423257,
        0.728039637561698,
        0.7332825680590266,
        0.7375966470977006,
        0.7407868870411085,
        0.742658300252639,
        0.7430158990956809,
        0.7419766714268411,
        0.7398277778493543,
        0.7366294898465512,
        0.7324420789017629,
        0.7273258164983196,
        0.7213409741195522,
        0.7145478232487914,
        0.7070066353693679,
        0.6987776819646127,
        0.689921234517856,
        0.6804975645124286,
        0.6705669434316616,
        0.6595358412400062,
        0.6469608062966613,
        0.6331272276770803,
        0.6183204944567149,
        0.6028259957110169,
        0.58692912051544,
        0.5709152579454357,
        0.555069797076456,
        0.5396781269839542,
        0.5250256367433822,
        0.5113977154301916,
        0.49907975211983624,
        0.48782004449005645,
        0.47714708479892054,
        0.46701655399237413,
        0.4573841330163608,
        0.44820550281682464,
        0.43943634433971046,
        0.4310323385309622,
        0.4229491663365238,
        0.4151425087023403,
        0.40756804657435536,
        0.4001814608985134,
        0.39293843262075895,
        0.3860141465033798,
        0.379568431817711,
        0.3735339362732674,
        0.3678433075795636,
        0.3624291934461142,
        0.357224241582434,
        0.3521610996980378,
        0.34717241550244005,
        0.34219083670515565,
        0.3371490110156992,
        0.3319795861435852,
        0.3266152097983288,
        0.3211604756514835,
        0.3157554185382034,
        0.31038584791340507,
        0.30503757323200403,
        0.29969640394891617,
        0.29434814951905763,
        0.2889786193973441,
        0.2835736230386913,
        0.27811896989801566,
        0.27260046943023275,
        0.2670039310902583,
        0.2613151643330085,
        0.25550845476249073,
        0.24958291926680107,
        0.24356161422698958,
        0.23746759602410542,
        0.23132392103919786,
        0.2251536456533169,
        0.21897982624751175,
        0.21282551920283171,
        0.20671378090032674,
        0.20066766772104605,
        0.19471023604603893,
        0.18886454225635535,
        0.18309409765325338,
        0.17735052974002508,
        0.17163906920077118,
        0.16596494671959172,
        0.16033339298058688,
        0.15474963866785743,
        0.14921891446550345,
        0.14374645105762496,
        0.13833747912832284,
        0.13299722936169706,
        0.1277309324418478,
        0.12254381905287581,
        0.11727689698719933,
        0.11182328318461139,
        0.10626637205027488,
        0.10068955798935202,
        0.095176235407005,
        0.08980979870839674,
        0.08467364229868943,
        0.07985116058304535,
        0.07542574796662732,
        0.07148079885459759,
        0.06809970765211844
    ],
    "WEEKDAY_SEVERE_CONGESTION_AM_PEAK_FREEWAY": [
        0.06757373259388348,
        0.06630225164032011,
        0.06465480124493943,
        0.06269773916726494,
        0.060497423166820205,
        0.05812021100312878,
        0.0556324604357142,
        0.05310052922410003,
        0.0505907751278098,
        0.04816955590636709,
        0.0459032293192954,
        0.043858153126118314,
        0.042100685086359356,
        0.04053719074106769,
        0.03903569424233978,
        0.03760504126627024,
        0.0362540774889536,
        0.034991648586484465,
        0.033826600234957406,
        0.032767778110466994,
        0.0318240278891078,
        0.031004195246974415,
        0.030317125860161418,
        0.029771665404763357,
        0.02937665955687484,
        0.029028448034694267,
        0.028651415375881097,
        0.02829647448572146,
        0.02801453826950149,
        0.02785651963250733,
        0.027873331480025097,
        0.02811588671734094,
        0.028635098249740983,
        0.029481878982511353,
        0.030707141820938207,
        0.032361799670307646,
        0.03449676543590584,
        0.03677268339752292,
        0.038918058563804106,
        0.041085594933318155,
        0.043427996504633797,
        0.04609796727631976,
        0.049248211246944826,
        0.05303143241507771,
        0.05760033477928712,
        0.06310762233814185,
        0.06970599909021064,
        0.07754816903406213,
        0.08678683616826521,
        0.09738586389285278,
        0.10915567000940746,
        0.12201879011882175,
        0.1358977598219885,
        0.15071511471980079,
        0.1663933904131509,
        0.18285512250293195,
        0.20002284659003677,
        0.2178190982753578,
        0.23616641315978815,
        0.25498732684422043,
        0.27420437492954747,
        0.29476810836842504,
        0.3173679298388425,
        0.3415346525296914,
        0.36679908962986457,
        0.39269205432825427,
        0.4187443598137525,
        0.4444868192752517,
        0.46945024590164447,
        0.4931654528818226,
        0.5151632534046787,
        0.534974460659105,
        0.5521298878339936,
        0.5671485244157464,
        0.5808724765987365,
        0.5933172326338129,
        0.6044982807718257,
        0.6144311092636239,
        0.6231312063600571,
        0.6306140603119751,
        0.636895159370227,
        0.6419899917856625,
        0.645914045809131,
        0.6486828096914822,
        0.6503117716835652,
        0.6503040752944138,
        0.6483266428470315,
        0.644640630813223,
        0.6395071956647932,
        0.633187493873547,
        0.6259426819112891,
        0.6180339162498245,
        0.6097223533609578,
        0.6012691497164941,
        0.5929354617882381,
        0.5849824460479945,
        0.5776712589675683,
        0.5704415955571243,
        0.5626520001266169,
        0.5544419030975346,
        0.5459507348913664,
        0.5373179259296011,
        0.5286829066337273,
        0.520185107425234,
        0.5119639587256102,
        0.5041588909563441,
        0.496909334538925,
        0.49035471989484175,
        0.48463447744558275,
        0.4796589801682303,
        0.47521908677021213,
        0.4712749562685363,
        0.46778674768021067,
        0.46471462002224323,
        0.4620187323116415,
        0.4596592435654138,
        0.4575963128005677,
        0.4557900990341111,
        0.45420076128305203,
        0.45278845856439826,
        0.4515133498951578,
        0.4505233453948187,
        0.4499560325754104,
        0.4497560865427524,
        0.4498681824026645,
        0.45023699526096617,
        0.450807200223477,
        0.45152347239601653,
        0.4523304868844045,
        0.4531729187944605,
        0.4539954432320038,
        0.4547427353028545,
        0.4553594701128319,
        0.45590208350938555,
        0.4564668436436347,
        0.4570581740769035,
        0.45768049837051605,
        0.45833824008579666,
        0.45903582278406946,
        0.4597776700266583,
        0.46056820537488774,
        0.46141185239008176,
        0.46231303463356455,
        0.46327617566666013,
        0.4643056990506929,
        0.4653720958127288,
        0.4664517618121831,
        0.46755797785890507,
        0.4687040247627429,
        0.4699031833335453,
        0.47116873438116097,
        0.47251395871543844,
        0.47395213714622625,
        0.47549655048337325,
        0.477160479536728,
        0.4789572051161389,
        0.4809000080314547,
        0.48303831533618263,
        0.48538909245362866,
        0.4879169277483398,
        0.49058640958486316,
        0.4933621263277455,
        0.4962086663415339,
        0.4990906179907752,
        0.5019725696400166,
        0.504819109653805,
        0.5075948263966874,
        0.5102643082332105,
        0.5127921435279218,
        0.5152642739670046,
        0.5177740205894338,
        0.5202970407889431,
        0.5228089919592657,
        0.5252855314941353,
        0.5277023167872855,
        0.5300350052324496,
        0.5322592542233613,
        0.534350721153754,
        0.5362850634173614,
        0.5380379384079167,
        0.5395850035191537,
        0.5410114672756045,
        0.5423981088542614,
        0.5437139416275498,
        0.5449279789678944,
        0.5460092342477201,
        0.5469267208394515,
        0.5476494521155137,
        0.5481464414483318,
        0.5483867022103304,
        0.5483392477739343,
        0.5479730915115685,
        0.5472572467956579,
        0.5463130765915307,
        0.5452516154708414,
        0.5440263842155046,
        0.542590903607434,
        0.540898694428544,
        0.5389032774607486,
        0.5365581734859621,
        0.5338169032860989,
        0.5306329876430727,
        0.526959947338798,
        0.5227513031551886,
        0.5179605758741593,
        0.5124715701025103,
        0.5062610479188445,
        0.49942196631278035,
        0.49204728227393557,
        0.4842299527919277,
        0.4760629348563754,
        0.4676391854568961,
        0.4590516615831074,
        0.45039332022462797,
        0.4417571183710754,
        0.4332360130120671,
        0.4249229611372219,
        0.41653098721928833,
        0.40777606284315954,
        0.39875556566667075,
        0.38956687334765616,
        0.38030736354395017,
        0.3710744139133882,
        0.3619654021138041,
        0.3530777058030325,
        0.34450870263890854,
        0.33635577027926644,
        0.3287162863819405,
        0.32168762860476613,
        0.3152008151397424,
        0.309105718300504,
        0.30337799692733863,
        0.29799330986053324,
        0.2929273159403751,
        0.2881556740071516,
        0.28365404290115004,
        0.27939808146265727,
        0.27536344853196104,
        0.27152580294934836,
        0.2678608035551064,
        0.2643441091895226,
        0.26117454167190984,
        0.2585051779706083,
        0.25624305964944644,
        0.25429522827225254,
        0.2525687254028549,
        0.2509705926050821,
        0.24940787144276222,
        0.2477876034797237,
        0.24601683027979496,
        0.24400259340680422,
        0.24165193442457983,
        0.23887189489695035,
        0.235774975959966,
        0.23253417844875493,
        0.22914729347576304,
        0.2256121121534354,
        0.2219264255942174,
        0.21808802491055465,
        0.21409470121489235,
        0.20994424561967584,
        0.20563444923735075,
        0.20116310318036237,
        0.19652799856115585,
        0.19172692649217704,
        0.18663842130278213,
        0.18118759635262086,
        0.1754421112995795,
        0.16946962580154373,
        0.16333779951639896,
        0.15711429210203154,
        0.15086676321632694,
        0.1446628725171708,
        0.13857027966244936,
        0.13265664431004817,
        0.12698962611785278,
        0.12163688474374944,
        0.11639841942713032,
        0.1110731464208241,
        0.10572710090287162,
        0.10042631805131311,
        0.09523683304418885,
        0.09022468105953975,
        0.08545589727540598,
        0.08099651686982787,
        0.07691257502084624,
        0.07327010690650133,
        0.0701351477048335
    ],
    "WEEKDAY_SEVERE_CONGESTION_AM_PEAK_NONFREEWAY": [
        0.05395543267159442,
        0.052984667745535265,
        0.05171314131983243,
        0.050196561969294826,
        0.04849063826873144,
        0.046651078792951245,
        0.04473359211676317,
        0.04279388681497617,
        0.04088767146239922,
        0.03907065463384124,
        0.03739854490411122,
        0.03592705084801811,
        0.034711881040370846,
        0.03367987144684185,
        0.032723690145794675,
        0.03184779388107506,
        0.031056639396528724,
        0.030354683436001374,
        0.02974638274333873,
        0.029236194062386527,
        0.028828574136990474,
        0.028527979710996305,
        0.028338867528249725,
        0.028265694332596458,
        0.028312916867882235,
        0.02838948095946702,
        0.028432581139293634,
        0.028489047211082107,
        0.02860570897855243,
        0.02882939624542464,
        0.029206938815418727,
        0.02978516649225473,
        0.03061090907965263,
        0.03173099638133246,
        0.03319225820101424,
        0.03504152434241796,
        0.037325624609263655,
        0.039724200220884846,
        0.04198229944007191,
        0.044244862635730235,
        0.04665683017676514,
        0.049363142432081966,
        0.05250873977058606,
        0.05623856256118279,
        0.060697551172777434,
        0.0660306459742754,
        0.07238278733458202,
        0.07989891562260255,
        0.0887239712072425,
        0.09888099964428512,
        0.11022654952133241,
        0.12265581575501709,
        0.1360639932619721,
        0.1503462769588306,
        0.16539786176222512,
        0.1811139425887888,
        0.19738971435515468,
        0.21412037197795533,
        0.23120111037382385,
        0.24852712445939323,
        0.26599360915129616,
        0.28446277441698004,
        0.30459763214485736,
        0.32599458013300814,
        0.3482500161795134,
        0.37096033808245377,
        0.39372194363990937,
        0.41613123064996116,
        0.4377845969106899,
        0.45827844022017566,
        0.47720915837649924,
        0.49417314917774136,
        0.5087668104219824,
        0.5214323913634354,
        0.5328916166568757,
        0.5431600972012263,
        0.5522534438954106,
        0.5601872676383524,
        0.5669771793289747,
        0.5726387898662011,
        0.5771877101489551,
        0.5806395510761598,
        0.583009923546739,
        0.5843144384596157,
        0.5845687067137136,
        0.5831821928733485,
        0.5797620715897138,
        0.5746205188920497,
        0.5680697108095966,
        0.5604218233715942,
        0.5519890326072829,
        0.5430835145459026,
        0.5340174452166936,
        0.5251030006488961,
        0.5166523568717499,
        0.5089776899144954,
        0.5023911758063726,
        0.4963650865084547,
        0.49022303941861706,
        0.4840542287213036,
        0.4779478486009576,
        0.47199309324202315,
        0.4662791568289436,
        0.4608952335461629,
        0.45593051757812464,
        0.4514742031092724,
        0.44761548432405,
        0.444443555406901,
        0.4420476105422691,
        0.4405116405227111,
        0.43979179128773355,
        0.4397854897422051,
        0.4403901627909942,
        0.4415032373389693,
        0.44302214029099923,
        0.4448442985519521,
        0.4468671390266968,
        0.44898808862010164,
        0.45110457423703526,
        0.4531140227823663,
        0.454913861160963,
        0.4566809846867179,
        0.45863986102679716,
        0.460759275615979,
        0.46300801388904256,
        0.46535486128076603,
        0.46776860322592867,
        0.47021802515930894,
        0.4726719125156855,
        0.4750990507298371,
        0.47746822523654253,
        0.4797482214705804,
        0.4819078248667294,
        0.48398754439617975,
        0.4860472107481119,
        0.4880845919342905,
        0.4900974559664798,
        0.49208357085644394,
        0.49404070461594773,
        0.4959666252567553,
        0.497859100790631,
        0.4997158992293393,
        0.5015347885846445,
        0.503313536868311,
        0.5050499120921033,
        0.5067015489109475,
        0.5082439224560538,
        0.5097015614536125,
        0.5110989946298133,
        0.5124607507108468,
        0.5138113584229032,
        0.5151753464921722,
        0.5165772436448446,
        0.5180415786071099,
        0.5195928801051587,
        0.521255676865181,
        0.5230544976133671,
        0.5249934321583613,
        0.5270483244952747,
        0.5292013346299993,
        0.531434622568426,
        0.5337303483164458,
        0.53607067187995,
        0.5384377532648297,
        0.5408137524769763,
        0.5431808295222808,
        0.5455211444066344,
        0.5478168571359281,
        0.5500501277160535,
        0.5523235294832294,
        0.5547217981904408,
        0.557200337468729,
        0.5597145509491342,
        0.5622198422626975,
        0.5646716150404594,
        0.5670252729134607,
        0.5692362195127421,
        0.5712598584693441,
        0.5730515934143077,
        0.5745668279786735,
        0.5757609657934823,
        0.5767432679626842,
        0.5776319148640213,
        0.5783956890392222,
        0.5790033730300157,
        0.5794237493781296,
        0.579625600625293,
        0.579577709313234,
        0.5792488579836814,
        0.5786078291783636,
        0.5776234054390091,
        0.5762643693073461,
        0.5744995033251037,
        0.5723734065132035,
        0.5699485154758009,
        0.5672203691294754,
        0.5641845063908056,
        0.5608364661763706,
        0.5571717874027495,
        0.5531860089865209,
        0.5488746698442638,
        0.5442333088925578,
        0.5392574650479812,
        0.5339426772271131,
        0.5282844843465326,
        0.5221000376771939,
        0.5152778455734358,
        0.5079204825769156,
        0.5001305232292894,
        0.4920105420722135,
        0.4836631136473452,
        0.47519081249634065,
        0.4666962131608561,
        0.45828189018254895,
        0.45005041810307517,
        0.4421043714640911,
        0.4345463248072541,
        0.4272320824829994,
        0.4199579841234333,
        0.4127374071927191,
        0.4055837291550191,
        0.3985103274744958,
        0.39153057961531235,
        0.38465786304163113,
        0.3779055552176147,
        0.37128703360742615,
        0.36481567567522805,
        0.3585048588851826,
        0.35236796070145304,
        0.3464220727824942,
        0.34066347804096336,
        0.33507434082232745,
        0.3296368254720526,
        0.32433309633560525,
        0.3191453177584522,
        0.31405565408605984,
        0.3090462696638944,
        0.3040993288374228,
        0.29919699595211136,
        0.29432143535342625,
        0.28945481138683454,
        0.28467108295220817,
        0.28003677188168413,
        0.2755228869191266,
        0.27110043680839885,
        0.2667404302933646,
        0.2624138761178879,
        0.2580917830258322,
        0.25374515976106105,
        0.24934501506743856,
        0.24486235768882816,
        0.2402681963690934,
        0.23553353985209843,
        0.23068811929157007,
        0.22578248133748302,
        0.2208188579780734,
        0.21579948120157655,
        0.210726582996228,
        0.20560239535026373,
        0.20042915025191912,
        0.19520907968942952,
        0.18994441565103118,
        0.18463739012495925,
        0.17929023509944939,
        0.17390518256273743,
        0.16840778685925348,
        0.16274823858465026,
        0.1569657241039983,
        0.1510994297823673,
        0.14518854198482695,
        0.13927224707644772,
        0.1333897314222994,
        0.1275801813874516,
        0.12188278333697486,
        0.1163367236359389,
        0.1109811886494134,
        0.10585536474246886,
        0.10079974672601404,
        0.0956700825360054,
        0.09052843822508484,
        0.08543687984589365,
        0.08045747345107301,
        0.07565228509326484,
        0.07108338082511038,
        0.0668128266992509,
        0.06290268876832823,
        0.05941503308498365,
        0.05641192570185847
    ],
    "WEEKDAY_SEVERE_CONGESTION_PM_PEAK_FREEWAY": [
        0.07404466699578743,
        0.07284129860528199,
        0.07129027029838349,
        0.06945137949392485,
        0.06738442361073911,
        0.06514920006765929,
        0.0628055062835184,
        0.06041313967714944,
        0.05803189766738539,
        0.05572157767305932,
        0.05354197711300418,
        0.051552893406052985,
        0.04981412397103879,
        0.048235597568709626,
        0.04669476258990555,
        0.0452026958985975,
        0.04377047435875646,
        0.04240917483435335,
        0.04112987418935913,
        0.03994364928774478,
        0.038861576993481216,
        0.03789473417053942,
        0.03705419768289033,
        0.0363510443945049,
        0.0357963511693541,
        0.0353121858038,
        0.034836871225741126,
        0.03440586699645296,
        0.03405463267721103,
        0.03381862782929086,
        0.03373331201396797,
        0.033834144792517874,
        0.03415658572621608,
        0.03473609437633812,
        0.0356081303041595,
        0.03680815307095575,
        0.03837162223800239,
        0.04012716318486606,
        0.041927036177152266,
        0.043842153105194764,
        0.04594342585932732,
        0.04830176632988373,
        0.05098808640719771,
        0.05407329798160307,
        0.05762831294343353,
        0.06172404318302291,
        0.06643140059070493,
        0.07182129705681335,
        0.07796464447168201,
        0.08465682701571263,
        0.09169027619606959,
        0.09913147489323032,
        0.10704690598767246,
        0.11550305235987354,
        0.12456639689031096,
        0.1343034224594623,
        0.14478061194780528,
        0.1560644482358171,
        0.16822141420397557,
        0.18131799273275806,
        0.19542066670264213,
        0.2112400552199012,
        0.22915584366437025,
        0.24873811432686868,
        0.2695569494982164,
        0.29118243146923345,
        0.31318464253073885,
        0.33513366497355274,
        0.35659958108849515,
        0.3771524731663851,
        0.39636242349804274,
        0.4137995143742878,
        0.4290338280859398,
        0.44266036927291785,
        0.4555267783888215,
        0.46758209144499163,
        0.4787753444527688,
        0.489055573423494,
        0.49837181436850775,
        0.5066731032991509,
        0.5139084762267642,
        0.5200269691626883,
        0.5249776181182642,
        0.5287094591048322,
        0.5311715281337335,
        0.53189106697464,
        0.5306228265595171,
        0.5276571066431446,
        0.523284206980303,
        0.5177944273257722,
        0.5114780674343324,
        0.5046254270607639,
        0.4975268059598466,
        0.49047250388636077,
        0.4837528205950865,
        0.47765805584080395,
        0.4724785093782933,
        0.46771705233361766,
        0.4627406393272387,
        0.4576356953555348,
        0.45248864541488387,
        0.4473859145016647,
        0.44241392761225506,
        0.43765910974303346,
        0.43320788589037784,
        0.4291466810506666,
        0.42556192022027783,
        0.42254002839559,
        0.42016743057298117,
        0.4184408021021723,
        0.4172637454823912,
        0.41658307643427694,
        0.41634561067846826,
        0.41649816393560385,
        0.4169875519263226,
        0.41776059037126334,
        0.41876409499106493,
        0.419944881506366,
        0.42124976563780553,
        0.4226255631060223,
        0.424019089631655,
        0.42561427351787473,
        0.4275861792623658,
        0.4298683268775378,
        0.43239423637580005,
        0.43509742776956173,
        0.4379114210712325,
        0.44076973629322175,
        0.44360589344793844,
        0.4463534125477924,
        0.44894581360519287,
        0.4513166166325492,
        0.45339934164227075,
        0.45521466988286324,
        0.4568482857716564,
        0.45833121407429583,
        0.4596944795564269,
        0.4609691069836951,
        0.46218612112174573,
        0.4633765467362245,
        0.46457140859277674,
        0.4658017314570481,
        0.4670985400946837,
        0.4684928592713294,
        0.4700157137526303,
        0.4715130905789319,
        0.47285313630429543,
        0.47410011496494,
        0.4753182905970842,
        0.4765719272369475,
        0.47792528892074876,
        0.47944263968470713,
        0.4811882435650416,
        0.48322636459797136,
        0.4856212668197151,
        0.48843721426649234,
        0.4917384709745218,
        0.4955963203290311,
        0.4999860182629261,
        0.5048277876339433,
        0.5100418512998193,
        0.5155484321182902,
        0.5212677529470929,
        0.5271200366439639,
        0.5330255060666392,
        0.5389043840728559,
        0.5446768935203503,
        0.5502632572668587,
        0.5555836981701177,
        0.5608409897629129,
        0.5662511993576678,
        0.5717544904815767,
        0.5772910266618329,
        0.58280097142563,
        0.5882244883001623,
        0.5935017408126232,
        0.5985728924902061,
        0.6033781068601054,
        0.6078575474495144,
        0.6119513777856269,
        0.6155997613956367,
        0.6189618762476987,
        0.6222042935646095,
        0.6252782667555249,
        0.6281350492296005,
        0.6307258943959924,
        0.6330020556638558,
        0.634914786442347,
        0.6364153401406215,
        0.6374549701678353,
        0.6379849299331439,
        0.6379564728457032,
        0.6373208523146691,
        0.6361430751249741,
        0.634517687329769,
        0.6324402512405373,
        0.6299063291687613,
        0.6269114834259247,
        0.6234512763235103,
        0.6195212701730012,
        0.6151170272858801,
        0.6102341099736307,
        0.6048680805477356,
        0.5990145013196775,
        0.5926689346009402,
        0.5856334182118136,
        0.5777868173345008,
        0.5692399353233547,
        0.5601035755327275,
        0.5504885413169714,
        0.5405056360304392,
        0.5302656630274832,
        0.5198794256624555,
        0.5094577272897093,
        0.4991113712635965,
        0.4889511609384691,
        0.47908789966868043,
        0.4691891773911729,
        0.45892258404288844,
        0.4483989229781809,
        0.4377289975514018,
        0.4270236111169033,
        0.41639356702903874,
        0.40594966864216,
        0.39580271931061917,
        0.3860635223887695,
        0.37684288123096304,
        0.36825159919155176,
        0.36040047962488886,
        0.35325775381124536,
        0.34668232890604544,
        0.34062102207637207,
        0.3350206504893071,
        0.3298280313119327,
        0.32498998171133153,
        0.3204533188545856,
        0.31616485990877696,
        0.3120714220409884,
        0.3081198224183019,
        0.30425687820779956,
        0.30042940657656403,
        0.2968538453676169,
        0.29372594538970054,
        0.2909614932584781,
        0.28847627558961253,
        0.2861860789987666,
        0.2840066901016037,
        0.2818538955137864,
        0.2796434818509778,
        0.2772912357288411,
        0.2747129437630391,
        0.27182439256923474,
        0.2685413687630912,
        0.26496618387051607,
        0.26125581437272727,
        0.25740804431835346,
        0.253420657756023,
        0.24929143873436413,
        0.24501817130200557,
        0.24059863950757562,
        0.23603062739970254,
        0.2313119190270151,
        0.22644029843814156,
        0.22141354968171006,
        0.21622945680634956,
        0.21080294940997624,
        0.20508230918366935,
        0.19911534831580238,
        0.19294987899474791,
        0.18663371340887863,
        0.180214663746568,
        0.17374054219618862,
        0.1672591609461133,
        0.16081833218471533,
        0.15446586810036747,
        0.14824958088144236,
        0.14221728271631343,
        0.13611479467720816,
        0.12972820778640998,
        0.1231657391573759,
        0.1165356059035624,
        0.10994602513842573,
        0.10350521397542314,
        0.09732138952801098,
        0.09150276890964555,
        0.08615756923378405,
        0.0813940076138828,
        0.07732030116339826
    ],
    "WEEKDAY_SEVERE_CONGESTION_PM_PEAK_NONFREEWAY": [
        0.06622557136679608,
        0.06507450942391219,
        0.06357221308214905,
        0.061782755642440526,
        0.05977021040572045,
        0.057598650672922656,
        0.05533214974498096,
        0.05303478092282923,
        0.05077061750740129,
        0.04860373279963099,
        0.04659820010045216,
        0.04481809271079864,
        0.04332748393160426,
        0.04205973633414062,
        0.04090254613547088,
        0.039851487105216306,
        0.038902133012998115,
        0.03805005762843757,
        0.03729083472115588,
        0.03662003806077431,
        0.036033241416914094,
        0.03552601855919648,
        0.035093943257242674,
        0.034732589280673964,
        0.03443753039911157,
        0.03416123502172179,
        0.03387828438747818,
        0.03361742151071291,
        0.03340738940575809,
        0.03327693108694587,
        0.03325478956860837,
        0.03336970786507774,
        0.033650428990686106,
        0.03412569595976561,
        0.03482425178664841,
        0.03577483948566659,
        0.037006202071152336,
        0.03835106106276028,
        0.03967161050281743,
        0.04104080218966443,
        0.04253158792164192,
        0.04421691949709055,
        0.046169748714350964,
        0.0484630273717638,
        0.05116970726766971,
        0.05436274020040935,
        0.058115077968323346,
        0.06249967236975232,
        0.06758947520303699,
        0.07327911036259145,
        0.07942193654667136,
        0.08602900775937937,
        0.09311137800481835,
        0.1006801012870911,
        0.10874623161030027,
        0.11732082297854869,
        0.12641492939593926,
        0.13603960486657452,
        0.14620590339455738,
        0.15692487898399066,
        0.16820758563897698,
        0.18047479441623818,
        0.19398220980193973,
        0.20849328594434965,
        0.22377147699173616,
        0.2395802370923676,
        0.2556830203945119,
        0.2718432810464373,
        0.28782447319641236,
        0.3033900509927047,
        0.3183034685835829,
        0.33232818011731496,
        0.34522763974216913,
        0.35752909659752596,
        0.3698261228830627,
        0.38198165733749156,
        0.39385863869952553,
        0.40532000570787685,
        0.41622869710125854,
        0.4264476516183828,
        0.4358398079979627,
        0.44426810497871033,
        0.4515954812993388,
        0.4576848756985605,
        0.46239922691508806,
        0.4654647803145579,
        0.46685684977832503,
        0.46681197681867964,
        0.46556670294791197,
        0.4633575696783124,
        0.46042111852217105,
        0.4569938909917782,
        0.4533124285994243,
        0.4496132728573994,
        0.4461329652779938,
        0.4431080473734979,
        0.4407750606562019,
        0.43867159953853363,
        0.4362453606331762,
        0.43359803875280223,
        0.43083132871008467,
        0.4280469253176961,
        0.4253465233883095,
        0.4228318177345975,
        0.42060450316923287,
        0.4187662745048885,
        0.4174188265542371,
        0.41666385412995155,
        0.41660305204470444,
        0.41724341981771257,
        0.4184784718928341,
        0.4202396754697052,
        0.4224584977479607,
        0.42506640592723643,
        0.42799486720716795,
        0.4311753487873908,
        0.43453931786754024,
        0.4380182416472523,
        0.4415435873261621,
        0.44504682210390534,
        0.44845941318011756,
        0.4519869510002493,
        0.4558350289027121,
        0.45993511842658324,
        0.46421869111093944,
        0.46861721849485816,
        0.47306217211741625,
        0.47748502351769084,
        0.481817244234759,
        0.485990305807698,
        0.48993567977558466,
        0.4935848376774961,
        0.4968692510525096,
        0.4998364558309002,
        0.5025914604711227,
        0.5051519453045232,
        0.5075355906624485,
        0.5097600768762443,
        0.5118430842772569,
        0.5138022931968329,
        0.5156553839663183,
        0.5174200369170595,
        0.5191139323804027,
        0.5207547506876942,
        0.5223601721702802,
        0.5237636579815194,
        0.5248399412211711,
        0.5256686101401828,
        0.5263292529895027,
        0.5269014580200784,
        0.5274648134828577,
        0.528098907628788,
        0.5288833287088174,
        0.5298976649738937,
        0.5312215046749643,
        0.5329344360629773,
        0.5351160473888804,
        0.5377626525381587,
        0.540785082255087,
        0.5441347000787978,
        0.5477628695484232,
        0.5516209542030952,
        0.5556603175819462,
        0.5598323232241086,
        0.5640883346687142,
        0.5683797154548955,
        0.5726578291217849,
        0.5768740392085139,
        0.5809797092542155,
        0.5851774856343298,
        0.589649383090538,
        0.5943158177113347,
        0.5990972055852131,
        0.6039139628006671,
        0.6086865054461906,
        0.6133352496102772,
        0.6177806113814208,
        0.6219430068481154,
        0.6257428520988545,
        0.6291005632221319,
        0.6319365563064416,
        0.6344004215451398,
        0.6366741715101871,
        0.638726855857984,
        0.6405275242449313,
        0.6420452263274294,
        0.6432490117618789,
        0.6441079302046803,
        0.644591031312234,
        0.6446673647409407,
        0.6443059801472013,
        0.6434759271874156,
        0.6421462555179848,
        0.6403733395472964,
        0.6382268179100691,
        0.6356956366022005,
        0.6327687416195877,
        0.6294350789581279,
        0.6256835946137184,
        0.6215032345822562,
        0.6168829448596388,
        0.6118116714417633,
        0.606278360324527,
        0.6002719575038268,
        0.5937814089755604,
        0.5865237460244777,
        0.5783309565635311,
        0.5693644215309233,
        0.5597855218648555,
        0.5497556385035292,
        0.5394361523851473,
        0.5289884444479106,
        0.5185738956300209,
        0.5083538868696806,
        0.49848979910509117,
        0.48914301327445403,
        0.4804749103159718,
        0.47232558229076754,
        0.4644172176687904,
        0.4567343420014816,
        0.4492614808402813,
        0.4419831597366298,
        0.4348839042419682,
        0.42794823990773684,
        0.4211606922853758,
        0.4145057869263262,
        0.40796804938202835,
        0.40153200520392257,
        0.39518217994344973,
        0.38897310374633975,
        0.38295635883103063,
        0.37711204885802657,
        0.3714202774878302,
        0.36586114838094475,
        0.36041476519787413,
        0.35506123159912134,
        0.34978065124518953,
        0.3445531277965823,
        0.33935876491380307,
        0.3341776662573546,
        0.32898993548774086,
        0.3238957929718284,
        0.3189807204043468,
        0.3142027134375944,
        0.30951976772386874,
        0.30488987891546737,
        0.3002710426646884,
        0.29562125462382943,
        0.2908985104451881,
        0.28606080578106263,
        0.28106613628375043,
        0.27587249760554927,
        0.27043788539875707,
        0.2647342941130248,
        0.2587897249592596,
        0.2526461837316448,
        0.24634567622436274,
        0.2399302082315961,
        0.23344178554752806,
        0.226922413966341,
        0.2204140992822175,
        0.21395884728934086,
        0.20759866378189348,
        0.2013755545540579,
        0.19533152540001728,
        0.18942335066035365,
        0.18357769859451042,
        0.17779141587496353,
        0.17206134917418792,
        0.16638434516465858,
        0.16075725051885129,
        0.15517691190924113,
        0.14964017600830298,
        0.14414388948851273,
        0.1386848990223453,
        0.13326005128227578,
        0.12786619294077986,
        0.12232934807164311,
        0.11654224471548326,
        0.11060178549502427,
        0.10460487303298938,
        0.09864840995210185,
        0.09282929887508562,
        0.08724444242466398,
        0.08199074322356019,
        0.07716510389449815,
        0.07286442706020116,
        0.06918561534339254
    ],
    "WEEKEND_FREEWAY": [
        0.15736009572604887,
        0.15498112762989397,
        0.151929335373271,
        0.14831760889656986,
        0.1442588381401803,
        0.1398659130444923,
        0.1352517235498957,
        0.1305291595967803,
        0.12581111112553606,
        0.12121046807655274,
        0.11684012039022025,
        0.11281295800692848,
        0.10924187086706724,
        0.10600343242361208,
        0.10289594584803123,
        0.09991489565845356,
        0.09705576637300797,
        0.09431404250982334,
        0.09168520858702855,
        0.08916474912275256,
        0.08674814863512419,
        0.08443089164227237,
        0.082208462662326,
        0.08007634621341395,
        0.07803002681366514,
        0.07603751847358696,
        0.07408339245281623,
        0.07218796914317598,
        0.07037156893648924,
        0.06865451222457908,
        0.06705711939926845,
        0.06559971085238046,
        0.0643026069757381,
        0.0631861281611644,
        0.062270594800482415,
        0.061576327285515146,
        0.06112364600808564,
        0.060868149212063405,
        0.06075941353428572,
        0.060808726956027265,
        0.06102737745856277,
        0.061426653023166966,
        0.06201784163111452,
        0.06281223126368017,
        0.06382110990213861,
        0.06505576552776457,
        0.06652748612183274,
        0.06824755966561781,
        0.07022727414039452,
        0.072432760297383,
        0.07483015081687823,
        0.07743073657376706,
        0.08024580844293647,
        0.08328665729927341,
        0.08656457401766476,
        0.09009084947299748,
        0.09387677454015853,
        0.09793364009403475,
        0.10227273700951314,
        0.10690535616148063,
        0.11184278842482413,
        0.11722915570230814,
        0.12314385461399711,
        0.1295010881107277,
        0.13621505914333673,
        0.14319997066266107,
        0.15037002561953733,
        0.15763942696480238,
        0.16492237764929302,
        0.17213308062384583,
        0.17918573883929784,
        0.1859945552464857,
        0.19247373279624613,
        0.19866541936192378,
        0.20469003309837058,
        0.21057918237868414,
        0.21636447557596222,
        0.22207752106330272,
        0.22774992721380322,
        0.23341330240056143,
        0.2390992549966753,
        0.24483939337524244,
        0.2506653259093605,
        0.2566086609721273,
        0.26270100693664067,
        0.26893446303586493,
        0.2752695196473983,
        0.28169037186128876,
        0.28818121476758446,
        0.2947262434563334,
        0.3013096530175837,
        0.3079156385413834,
        0.31452839511778063,
        0.3211321178368233,
        0.3277110017885597,
        0.3342492420630379,
        0.3407310337503058,
        0.3471706727415422,
        0.35359449814198973,
        0.3600047698627936,
        0.3664037478150984,
        0.3727936919100486,
        0.3791768620587892,
        0.3855555181724647,
        0.3919319201622199,
        0.39830832793919974,
        0.4046870014145486,
        0.41107020049941123,
        0.41746018510493266,
        0.42390813479160255,
        0.43044415301302896,
        0.43703888552003506,
        0.443662978063443,
        0.45028707639407534,
        0.45688182626275464,
        0.4634178734203035,
        0.46986586361754434,
        0.4761964426052998,
        0.4823802561343925,
        0.48838794995564466,
        0.4941901698198792,
        0.49985841375033396,
        0.505470203306354,
        0.5110052195429224,
        0.5164431435150225,
        0.5217636562776371,
        0.5269464388857494,
        0.5319711723943426,
        0.5368175378583993,
        0.541465216332903,
        0.5458938888728369,
        0.5500832365331836,
        0.5540129403689266,
        0.5577108436813952,
        0.5612168310569078,
        0.5645286454779325,
        0.5676440299269367,
        0.5705607273863876,
        0.5732764808387532,
        0.5757890332665007,
        0.5780961276520974,
        0.5801955069780111,
        0.5820849142267093,
        0.5837620923806592,
        0.5852247844223286,
        0.5863597261708139,
        0.5870973026210293,
        0.587500730519171,
        0.587633226611434,
        0.5875580076440138,
        0.587338290363106,
        0.5870372915149061,
        0.5867182278456097,
        0.586444316101412,
        0.5862787730285088,
        0.5862848153730952,
        0.586525659881367,
        0.5869565266985299,
        0.5874799558302292,
        0.5880801438133187,
        0.5887412871846531,
        0.5894475824810863,
        0.5901832262394725,
        0.5909324149966659,
        0.5916793452895207,
        0.5924082136548913,
        0.5931032166296313,
        0.5937485507505954,
        0.5943284125546375,
        0.5948943529491494,
        0.5954964171986301,
        0.5961165433755955,
        0.5967366695525607,
        0.5973387338020413,
        0.5979046741965532,
        0.5984164288086119,
        0.5988559357107329,
        0.5992051329754318,
        0.599445958675224,
        0.5995603508826254,
        0.5995302476701513,
        0.5994181158561636,
        0.5992834109732383,
        0.5991035541652143,
        0.5988559665759299,
        0.5985180693492234,
        0.598067283628933,
        0.5974810305588973,
        0.5967367312829547,
        0.5958118069449433,
        0.5946836786887015,
        0.5933297676580679,
        0.5917274949968807,
        0.5899487377915789,
        0.5880638674857092,
        0.5860480467587713,
        0.583876438290265,
        0.58152420475969,
        0.5789665088465461,
        0.5761785132303331,
        0.5731353805905506,
        0.5698122736066984,
        0.5661843549582765,
        0.5622267873247843,
        0.5579147333857216,
        0.5531860998398382,
        0.5480284680268832,
        0.5424915125878579,
        0.5366249081637626,
        0.5304783293955971,
        0.5241014509243629,
        0.51754394739106,
        0.5108554934366886,
        0.5040857637022498,
        0.4972844328287438,
        0.4905011754571709,
        0.4837856662285321,
        0.47695163512558364,
        0.4698263561653515,
        0.46247982004024174,
        0.45498201744265904,
        0.44740293906500833,
        0.4398125755996955,
        0.43228091773912536,
        0.42487795617570284,
        0.4176736816018336,
        0.4107380847099228,
        0.4041411561923751,
        0.3979528867415963,
        0.39213301511940996,
        0.3865795670552612,
        0.38126996369298904,
        0.37618162617643175,
        0.37129197564942723,
        0.3665784332558144,
        0.36201842013943136,
        0.35758935744411635,
        0.353268666313708,
        0.3490337678920446,
        0.3448620833229643,
        0.3407310337503058,
        0.33674560448927354,
        0.3329972358562652,
        0.3294430314969083,
        0.32604009505683035,
        0.3227455301816589,
        0.31951644051702166,
        0.31630992970854604,
        0.3130831014018594,
        0.30979305924258976,
        0.3063969068763644,
        0.30285174794881065,
        0.2991146861055565,
        0.295202656212126,
        0.29117323102407716,
        0.28704447102208863,
        0.28283443668683855,
        0.27856118849900496,
        0.2742427869392667,
        0.26989729248830174,
        0.26554276562678825,
        0.261197266835405,
        0.25687885659482995,
        0.25260559538574145,
        0.24839554368881803,
        0.2442069336584533,
        0.23999348341397594,
        0.23576648238346715,
        0.23153721999500745,
        0.2273169856766776,
        0.22311706885655877,
        0.21894875896273155,
        0.2148233454232766,
        0.21075211766627508,
        0.20674636511980762,
        0.20281737721195497,
        0.19897644337079815,
        0.1951027683073591,
        0.1911071665525633,
        0.1870483422643477,
        0.18298499960064885,
        0.178975842719403,
        0.17507957577854733,
        0.17135490293601796,
        0.16786052834975157,
        0.16465515617768497,
        0.1617974905777546,
        0.15934623570789697
    ],
    "WEEKEND_NONFREEWAY": [
        0.14379827121153438,
        0.1415499074239997,
        0.13868422582644677,
        0.13530108690817283,
        0.13150035115847503,
        0.12738187906665058,
        0.12304553112199673,
        0.11859116781381066,
        0.11411864963138954,
        0.10972783706403058,
        0.10551859060103105,
        0.1015907707316881,
        0.09804423794529891,
        0.09474584636963469,
        0.09150192809768011,
        0.08832156056655271,
        0.08521382121336997,
        0.0821877874752494,
        0.07925253678930852,
        0.07641714659266485,
        0.0736906943224359,
        0.07108225741573918,
        0.06860091330969217,
        0.06625573944141243,
        0.06405581324801747,
        0.06197390241815471,
        0.05998277464047166,
        0.05809150735208582,
        0.05630917799011468,
        0.05464486399167578,
        0.0531076427938866,
        0.05170659183386468,
        0.050450788548727525,
        0.049349310375592634,
        0.04841123475157753,
        0.047645639113799744,
        0.04706160089937675,
        0.04662356150787328,
        0.04629899008033874,
        0.04610150566611752,
        0.04604472731455413,
        0.04614227407499302,
        0.04640776499677865,
        0.046854819129255446,
        0.0474970555217679,
        0.048348093223660495,
        0.04942155128427763,
        0.0507310487529638,
        0.05229020467906347,
        0.05407480918765037,
        0.056054598849941115,
        0.05823411238449446,
        0.06061788850986915,
        0.06321046594462397,
        0.06601638340731764,
        0.06904017961650895,
        0.07228639329075663,
        0.07575956314861944,
        0.07946422790865611,
        0.0834049262894255,
        0.08758619700948624,
        0.09207764171099349,
        0.09691860101870921,
        0.10206822212510216,
        0.10748565222264135,
        0.11313003850379576,
        0.11896052816103413,
        0.12493626838682546,
        0.1310164063736387,
        0.13716008931394266,
        0.14332646440020633,
        0.14947467882489862,
        0.15556387978048836,
        0.16162092508943632,
        0.16770747279243334,
        0.17383487040929316,
        0.1800144654598298,
        0.18625760546385733,
        0.19257563794118948,
        0.1989799104116403,
        0.2054817703950237,
        0.2120925654111536,
        0.21882364297984397,
        0.22568635062090878,
        0.2326920358541619,
        0.23984409922200667,
        0.2471323255934483,
        0.25454763897820315,
        0.262080963385988,
        0.2697232228265194,
        0.2774653413095139,
        0.2852982428446881,
        0.29321285144175857,
        0.3012000911104419,
        0.3092508858604546,
        0.31735615970151343,
        0.32550683664333485,
        0.33377176018700777,
        0.3422046409135342,
        0.3507737034525008,
        0.3594471724334938,
        0.36819327248609945,
        0.3769802282399047,
        0.3857762643244955,
        0.3945496053694584,
        0.4032684760043798,
        0.4119011008588463,
        0.42041570456244404,
        0.4287805117447598,
        0.43708252488048044,
        0.4454117732212217,
        0.4537410215619631,
        0.4620430346976837,
        0.47029057742336283,
        0.47845641453397986,
        0.48651331082451404,
        0.4944340310899443,
        0.5021913401252505,
        0.5097580027254115,
        0.5171067836854065,
        0.5242104478002153,
        0.5311215706094913,
        0.5379033204075824,
        0.5445443511215089,
        0.5510333166782909,
        0.5573588710049485,
        0.5635096680285019,
        0.5694743616759712,
        0.5752416058743766,
        0.5808000545507385,
        0.5861383616320767,
        0.5912451810454113,
        0.5961091667177629,
        0.6007810041411779,
        0.6053053252538456,
        0.6096617036520007,
        0.6138297129318777,
        0.617788926689711,
        0.6215189185217349,
        0.6249992620241842,
        0.6282095307932929,
        0.6311292984252959,
        0.6337381385164272,
        0.6360156246629217,
        0.6379413304610136,
        0.6394142613446137,
        0.6403924313939545,
        0.6409439271747542,
        0.6411368352527301,
        0.6410392421936002,
        0.640719234563082,
        0.6402448989268934,
        0.6396843218507522,
        0.6391055899003757,
        0.638576789641482,
        0.6381660076397887,
        0.6379413304610136,
        0.6378274855967627,
        0.637702296106366,
        0.6375634919071274,
        0.63740880291635,
        0.6372359590513376,
        0.6370426902293936,
        0.636826726367822,
        0.636585797383926,
        0.6363176331950091,
        0.6360199637183751,
        0.6356905188713278,
        0.6353270285711702,
        0.6349862316226677,
        0.6347067109917933,
        0.6344589628376643,
        0.6342134833193979,
        0.6339407685961104,
        0.6336113148269193,
        0.6331956181709415,
        0.6326641747872936,
        0.6319874808350929,
        0.6311360324734561,
        0.6300803258615003,
        0.6287908571583423,
        0.627318692132257,
        0.6257285131894735,
        0.6240112414460404,
        0.6221577980180061,
        0.6201591040214186,
        0.6180060805723266,
        0.6156896487867785,
        0.6132007297808226,
        0.6105302446705071,
        0.6076691145718809,
        0.6046082606009919,
        0.6013386038738888,
        0.5978582509670182,
        0.59417590121152,
        0.590298364855484,
        0.5862324521469995,
        0.5819849733341554,
        0.5775627386650415,
        0.5729725583877465,
        0.5682212427503598,
        0.5633156020009712,
        0.5582624463876694,
        0.5530685861585439,
        0.547740831561684,
        0.5422005076171805,
        0.5363901190671748,
        0.5303482457428342,
        0.524113467475325,
        0.5177243640958131,
        0.5112195154354658,
        0.5046375013254492,
        0.49801690159692935,
        0.4913962960810735,
        0.48481426460904764,
        0.47830938701201803,
        0.47192024312115183,
        0.46555188927168717,
        0.45910181967932145,
        0.45259727099591,
        0.44606547987330725,
        0.439533682963368,
        0.4330291169179474,
        0.4265790183889,
        0.42021062402808046,
        0.413951170487344,
        0.4078278944185454,
        0.40186803247353914,
        0.39609882130418034,
        0.3905176095869884,
        0.38509299740499714,
        0.37980909851983424,
        0.37465002669312714,
        0.3695998956865027,
        0.3646428192615886,
        0.359762911180012,
        0.3549442852034001,
        0.35017105509338053,
        0.3454273346115805,
        0.3406972375196269,
        0.33596487757914756,
        0.33128624244687227,
        0.32671429107349015,
        0.3222285941615678,
        0.3178087224136713,
        0.31343424653236684,
        0.30908473722022095,
        0.3047397651797996,
        0.3003789011136691,
        0.29598171572439597,
        0.29152777971454624,
        0.28699666378668603,
        0.282367938643382,
        0.2776030191484092,
        0.272699637630461,
        0.267691840989482,
        0.26261367612541603,
        0.2574991899382071,
        0.2523824293277997,
        0.24729744119413766,
        0.24227827243716485,
        0.23735896995682587,
        0.23257358065306458,
        0.22795615142582487,
        0.22354072917505127,
        0.21930423979058253,
        0.21519485767510435,
        0.2112035024978313,
        0.2073210939279775,
        0.2035385516347572,
        0.19984679528738494,
        0.19623674455507484,
        0.19269931910704105,
        0.18922543861249824,
        0.18580602274066046,
        0.18243199116074188,
        0.17909426354195715,
        0.17572021580572467,
        0.17227353844471324,
        0.1687996229850131,
        0.1653438609527138,
        0.16195164387390523,
        0.15866836327467734,
        0.15553941068111998,
        0.15261017761932275,
        0.14992605561537584,
        0.1475324361953689,
        0.14547471088539177
    ],
    "WEEKDAY_NO2LOW_CONGESTION_EVEN_DIST_FREEWAY": [
        0.07285163737504817,
        0.07164528120105931,
        0.0700547341823971,
        0.06815306524263068,
        0.06601334330532917,
        0.06370863729406168,
        0.061312016132397354,
        0.058896548743905305,
        0.056535304052154664,
        0.054301350980714556,
        0.05226775845315411,
        0.050507595393042434,
        0.04909393072394866,
        0.047962161308376515,
        0.04699146450584389,
        0.0461705799854411,
        0.045488247416258516,
        0.04493320646738645,
        0.044494196807915255,
        0.04415995810693527,
        0.04391923003353685,
        0.04376075225681033,
        0.04367326444584605,
        0.04364550626973434,
        0.043666217397565564,
        0.04368885910916223,
        0.04369609985267645,
        0.04372049004969304,
        0.043794580121796746,
        0.04395092049057236,
        0.044222061577604624,
        0.04464055380447835,
        0.04523894759277829,
        0.046049793364089237,
        0.047105641539995954,
        0.0484390425420832,
        0.05008254679193577,
        0.051724453306691655,
        0.05313089736461892,
        0.05445118377899742,
        0.05583461736310705,
        0.05743050293022767,
        0.059388145293639165,
        0.061856849266621404,
        0.06498591966245425,
        0.06892466129441759,
        0.07382237897579132,
        0.07982837751985522,
        0.0870919617398893,
        0.09546090046793279,
        0.10467544134625098,
        0.11472360740346227,
        0.12559342166818543,
        0.13727290716903923,
        0.149750086934642,
        0.16301298399361255,
        0.17704962137456956,
        0.1918480221061315,
        0.207396209216917,
        0.22368220573554487,
        0.2406940346906335,
        0.2593291321091365,
        0.2801716594315314,
        0.30273972780672226,
        0.32655144838361355,
        0.35112493231111025,
        0.37597829073811617,
        0.40062963481353575,
        0.42459707568627403,
        0.44739872450523466,
        0.4685526924193224,
        0.4875770905774419,
        0.5039900301284973,
        0.5185129130848369,
        0.5321305103827872,
        0.5447559865572184,
        0.5563025061430015,
        0.5666832336750075,
        0.5758113336881068,
        0.5835999707171704,
        0.5899623092970692,
        0.5948115139626735,
        0.5980607492488547,
        0.5996231796904831,
        0.59941196982243,
        0.5966888340606641,
        0.5910786839045795,
        0.5830394795141848,
        0.5730291810494875,
        0.5615057486704962,
        0.5489271425372187,
        0.5357513228096636,
        0.5224362496478384,
        0.5094398832117518,
        0.4972201836614114,
        0.48623511115682577,
        0.47694262585800273,
        0.4686783070326619,
        0.46051974858570716,
        0.4525379326329223,
        0.44480384129009154,
        0.4373884566729993,
        0.4303627608974294,
        0.4237977360791662,
        0.4177643643339939,
        0.4123336277776964,
        0.407576508526058,
        0.403563988694863,
        0.40036705039989534,
        0.3981413604841263,
        0.3969085446304095,
        0.39653852321435235,
        0.3969012166115625,
        0.39786654519764764,
        0.39930442934821536,
        0.4010847894388733,
        0.4030775458452289,
        0.4051526189428901,
        0.40717992910746426,
        0.40902939671455896,
        0.41057094213978196,
        0.41201104010767886,
        0.41362298866957026,
        0.41537694319122664,
        0.4172430590384181,
        0.41919149157691493,
        0.4211923961724875,
        0.4232159281909059,
        0.4252322429979407,
        0.4272114959593619,
        0.42912384244093993,
        0.430939437808445,
        0.43262843742764745,
        0.43418071636990313,
        0.435622389281598,
        0.43697797089104806,
        0.43827197592656886,
        0.43952891911647624,
        0.44077331518908586,
        0.4420296788727133,
        0.4433225248956745,
        0.44467636798628507,
        0.4461157228728607,
        0.4476651042837171,
        0.44934902694717005,
        0.45102828364113756,
        0.4525875446115865,
        0.45408714078890633,
        0.4555874031034862,
        0.45714866248571545,
        0.4588312498659834,
        0.4606954961746792,
        0.4628017323421923,
        0.46521028929891184,
        0.46798149797522726,
        0.4711756893015277,
        0.4748531942082026,
        0.47899723432704144,
        0.4835313169542127,
        0.4884262015166746,
        0.49365264744138515,
        0.499181414155302,
        0.5049832610853835,
        0.5110289476585874,
        0.5172892333018716,
        0.5237348774421945,
        0.5303366395065137,
        0.5370652789217871,
        0.5438915551149731,
        0.5510657860204106,
        0.5587791636759977,
        0.5669137586640308,
        0.5753516415668064,
        0.5839748829666199,
        0.5926655534457683,
        0.6013057235865477,
        0.609777463971254,
        0.6179628451821838,
        0.6257439378016331,
        0.6330028124118982,
        0.6396215395952756,
        0.6460366287816396,
        0.6526545089729502,
        0.6593121301096339,
        0.6658464421321155,
        0.6720943949808204,
        0.677892938596174,
        0.6830790229186018,
        0.687489597888529,
        0.6909616134463815,
        0.6933320195325842,
        0.6944377660875624,
        0.6941158030517418,
        0.6924446452338817,
        0.6896521293954557,
        0.685799188405961,
        0.6809467551348942,
        0.6751557624517522,
        0.6684871432260323,
        0.661001830327231,
        0.6527607566248453,
        0.6438248549883725,
        0.6342550582873091,
        0.6241122993911518,
        0.6134575111693984,
        0.6016824311666142,
        0.588333511053526,
        0.5737037548888737,
        0.5580861667313951,
        0.5417737506398287,
        0.5250595106729142,
        0.5082364508893895,
        0.491597575347993,
        0.4754358881074645,
        0.4600443932265421,
        0.4457160947639638,
        0.43274399677846964,
        0.42081898818169616,
        0.4094247211260609,
        0.3985433445314744,
        0.38815700731784575,
        0.378247858405084,
        0.36879804671309957,
        0.3597897211618016,
        0.35120503067109937,
        0.3430261241609031,
        0.335235150551122,
        0.32781425876166537,
        0.3207455977124433,
        0.3141715494822297,
        0.308202194753979,
        0.30277123035387277,
        0.29781235310809134,
        0.29325925984281526,
        0.28904564738422583,
        0.28510521255850346,
        0.28137165219182897,
        0.2777786631103832,
        0.2742599421403468,
        0.2707491861079005,
        0.2671800918392253,
        0.26377466857870097,
        0.2607465256714629,
        0.2580167600948254,
        0.2555064688261024,
        0.253136748842608,
        0.2508286971216565,
        0.2485034106405618,
        0.24608198637663797,
        0.2434855213071993,
        0.24063511240955976,
        0.23745185666103336,
        0.23385685103893433,
        0.22985224835523044,
        0.22552549460592175,
        0.22092562654437187,
        0.21610168092394388,
        0.21110269449800068,
        0.20597770401990598,
        0.2007757462430227,
        0.19554585792071386,
        0.19033707580634307,
        0.18519843665327332,
        0.18017897721486767,
        0.17532773424448964,
        0.17057010618156226,
        0.16580512466568545,
        0.1610422762504888,
        0.15629104748960101,
        0.15156092493665088,
        0.14686139514526778,
        0.14220194466908048,
        0.1375920600617178,
        0.13304122787680914,
        0.1285589346679832,
        0.1241546669888689,
        0.11983791139309555,
        0.11547511470900096,
        0.11097093958702299,
        0.10639219631394103,
        0.10180569517653386,
        0.09727824646158031,
        0.09287666045585971,
        0.08866774744615091,
        0.08471831771923273,
        0.0810951815618845,
        0.077865149260885,
        0.07509503110301315
    ],
    "WEEKDAY_NO2LOW_CONGESTION_EVEN_DIST_NONFREEWAY": [
        0.05441073712400431,
        0.05391676281868986,
        0.05329450619268679,
        0.05256320464858916,
        0.05174209558899104,
        0.050850416416486424,
        0.0499074045336694,
        0.04893229734313399,
        0.04794433224747426,
        0.04696274664928424,
        0.04600677795115797,
        0.04509566355568951,
        0.0442486408654729,
        0.043405433169522396,
        0.042506696303471765,
        0.041573066489844275,
        0.04062517995116321,
        0.03968367290995186,
        0.038769181588733506,
        0.037902342210031435,
        0.03710379099636892,
        0.03639416417026926,
        0.03579409795425573,
        0.03532422857085161,
        0.03500519224258018,
        0.0347939395686036,
        0.034640563232908726,
        0.03455541258525594,
        0.03454883697540563,
        0.03463118575311817,
        0.03481280826815392,
        0.03510405387027329,
        0.035515271909236625,
        0.036056811734804316,
        0.036739022696736755,
        0.03757225414479429,
        0.03856685542873732,
        0.03952644095194689,
        0.04031474786229051,
        0.0410323096262583,
        0.041779659710340325,
        0.04265733158102672,
        0.04376585870480757,
        0.04520577454817299,
        0.04707761257761305,
        0.049481906259617875,
        0.052519189060677554,
        0.056289994447282166,
        0.060894855885921875,
        0.06607905582510692,
        0.07157082840499111,
        0.07744513462952958,
        0.0837769355026776,
        0.09064119202839042,
        0.09811286521062318,
        0.1062669160533311,
        0.11517830556046951,
        0.1249219947359935,
        0.13557294458385827,
        0.1472061161080192,
        0.15989647031243137,
        0.17416376739527503,
        0.1902569882056232,
        0.20784492472376914,
        0.2265963689300069,
        0.24618011280463054,
        0.2662649483279334,
        0.28651966748020946,
        0.3066130622417527,
        0.3262139245928566,
        0.3449910465138151,
        0.3626132199849221,
        0.3787492369864712,
        0.3942323453799244,
        0.4099541116440735,
        0.42567080568520654,
        0.441138697409612,
        0.4561140567235784,
        0.4703531535333938,
        0.4836122577453468,
        0.49564763926572575,
        0.5062155680008188,
        0.5150723138569144,
        0.521974146740301,
        0.5266773365572668,
        0.5287228055213998,
        0.5280843005981236,
        0.5251673288214792,
        0.5203773972255079,
        0.5141200128442506,
        0.5068006827117489,
        0.49882491386204375,
        0.4905982133291762,
        0.4825260881471879,
        0.47501404535011965,
        0.4684675919720128,
        0.46329223504690853,
        0.4588493751478696,
        0.45429025951152735,
        0.44970516516727577,
        0.445184369144509,
        0.44081814847262146,
        0.43669678018100694,
        0.4329105412990596,
        0.4295497088561735,
        0.42670455988174283,
        0.4244653714051616,
        0.42292242045582407,
        0.4221659840631242,
        0.42226476535368224,
        0.42316400046691716,
        0.4247687659514221,
        0.42698413835578947,
        0.42971519422861226,
        0.4328670101184833,
        0.4363446625739954,
        0.44005322814374104,
        0.44389778337631336,
        0.44778340482030515,
        0.45161516902430887,
        0.45529815253691763,
        0.45907960578093965,
        0.46322042279060616,
        0.4676461455316445,
        0.47228231596978215,
        0.47705447607074636,
        0.48188816780026444,
        0.48670893312406377,
        0.49144231400787164,
        0.49601385241741547,
        0.5003490903184226,
        0.5043735696766202,
        0.5080128324577358,
        0.5113734425961295,
        0.5146044404510549,
        0.5176950826255805,
        0.520634625722774,
        0.5234123263457032,
        0.5260174410974366,
        0.5284392265810417,
        0.5306669393995868,
        0.5326898361561397,
        0.5344971734537687,
        0.5360782078955413,
        0.5374221960845259,
        0.5383128273897104,
        0.5386241696607401,
        0.5384810472216696,
        0.5380082843965527,
        0.5373307055094434,
        0.5365731348843964,
        0.5358603968454657,
        0.5353173157167054,
        0.5350687158221699,
        0.535239421485913,
        0.5359542570319892,
        0.5373380467844524,
        0.5393092439340551,
        0.5416797830668528,
        0.5444147105998559,
        0.5474790729500739,
        0.5508379165345162,
        0.5544562877701931,
        0.5582992330741142,
        0.5623317988632891,
        0.5665190315547278,
        0.5708259775654402,
        0.5752176833124357,
        0.5796591952127245,
        0.5843525415713678,
        0.5894644170366669,
        0.5949068675404913,
        0.6005919390147098,
        0.6064316773911923,
        0.6123381286018078,
        0.6182233385784256,
        0.623999353252915,
        0.6295782185571456,
        0.6348719804229862,
        0.6397926847823063,
        0.6442523775669752,
        0.6486267358940364,
        0.6532507769122963,
        0.6579755566012692,
        0.6626521309404688,
        0.667131555909409,
        0.6712648874876047,
        0.6749031816545692,
        0.6778974943898167,
        0.6800988816728615,
        0.6813583994832172,
        0.6815271038003982,
        0.6804560506039184,
        0.6782041519486344,
        0.6749713817099848,
        0.6708203885983576,
        0.6658138213241399,
        0.6600143285977191,
        0.653484559129483,
        0.6462871616298193,
        0.6384847848091146,
        0.6301400773777576,
        0.6213156880461351,
        0.6120742655246343,
        0.6024784585236435,
        0.5920161220852103,
        0.5802930017338511,
        0.5675485819036578,
        0.5540223470287207,
        0.53995378154313,
        0.525582369880978,
        0.5111475964763549,
        0.4968889457633509,
        0.48304590217605825,
        0.469857950148567,
        0.45756457411496776,
        0.4464052585093523,
        0.436176331141122,
        0.42648692668816046,
        0.4172957398872822,
        0.4085614654753007,
        0.40024279818902964,
        0.3922984327652833,
        0.3846870639408754,
        0.37736738645261925,
        0.37029809503732963,
        0.36343788443181974,
        0.3567454493729036,
        0.3501794845973953,
        0.34388103871414816,
        0.33798493021590836,
        0.3324405086653293,
        0.32719712362506354,
        0.32220412465776355,
        0.3174108613260827,
        0.3127666831926735,
        0.30822093982018867,
        0.30372298077128146,
        0.2992221556086044,
        0.29466781389481017,
        0.290009305192552,
        0.2853597204855384,
        0.28084628178596255,
        0.27643953519920456,
        0.27211002683064417,
        0.267828302785661,
        0.26356490916963515,
        0.2592903920879463,
        0.2549752976459741,
        0.2505901719490987,
        0.24610556110269977,
        0.24149201121215688,
        0.23672006838285023,
        0.23180423287144114,
        0.22678586363539038,
        0.22167579483127775,
        0.21648486061568242,
        0.21122389514518353,
        0.2059037325763608,
        0.20053520706579342,
        0.19512915277006043,
        0.1896964038457417,
        0.18424779444941627,
        0.1787941587376634,
        0.17334633086706272,
        0.16783010166847576,
        0.16218642804527297,
        0.15644889326279812,
        0.1506510805863941,
        0.14482657328140386,
        0.13900895461317103,
        0.13323180784703853,
        0.1275287162483493,
        0.12193326308244704,
        0.11647903161467463,
        0.111199605110375,
        0.10612856683489182,
        0.1011114594458784,
        0.09601335561783057,
        0.09089713364135577,
        0.08582567180706069,
        0.0808618484055521,
        0.07606854172743738,
        0.07150863006332323,
        0.06724499170381643,
        0.06334050493952434,
        0.059858048061053645,
        0.05686049935901124
    ],
    "WEEKDAY_MODERATE_CONGESTION_EVEN_DIST_FREEWAY": [
        0.07623742859903951,
        0.07484609543821497,
        0.07302365972843472,
        0.07084997996445658,
        0.06840491464103828,
        0.06576832225293763,
        0.06302006129491242,
        0.06023999026172043,
        0.05750796764811942,
        0.054903851948867155,
        0.052507501658721464,
        0.0503987752724401,
        0.048657531284780835,
        0.0472003716466597,
        0.04588710651437841,
        0.04471240669077327,
        0.043670942978680585,
        0.04275738618093668,
        0.04196640710037784,
        0.041292676539840395,
        0.040730865302160635,
        0.04027564419017489,
        0.03992168400671944,
        0.03966365555463063,
        0.03949622963674475,
        0.039358848400286875,
        0.03921878869948791,
        0.03911247339769289,
        0.03907632535824683,
        0.03914676744449478,
        0.03936022251978176,
        0.03975311344745282,
        0.040361863090852976,
        0.04122289431332728,
        0.04237262997822075,
        0.04384749294887841,
        0.045683906088645324,
        0.04762759428532347,
        0.04947701207113773,
        0.051347676776067455,
        0.05335510573009198,
        0.05561481626319064,
        0.05824232570534277,
        0.06135315138652773,
        0.06506281063672484,
        0.06948682078591342,
        0.07474069916407289,
        0.08093996310118248,
        0.08820012992722165,
        0.09632788016719943,
        0.1050741734315988,
        0.11447094567860969,
        0.12455013286642201,
        0.13534367095322597,
        0.1468834958972113,
        0.15920154365656808,
        0.17232975018948649,
        0.18630005145415612,
        0.20114438340876722,
        0.21689468201150977,
        0.2335828832205737,
        0.2520594338580439,
        0.27283068812904027,
        0.2954124430663041,
        0.31932049570257764,
        0.34407064307060287,
        0.369178682203121,
        0.39416041013287434,
        0.4185316238926047,
        0.4418081205150538,
        0.4635056970329635,
        0.48314015047907544,
        0.5002272778861319,
        0.5155175136439895,
        0.5300166283286574,
        0.5436234232521049,
        0.5562366997263016,
        0.5677552590632172,
        0.5780779025748211,
        0.5871034315730826,
        0.5947306473699717,
        0.6008583512774576,
        0.6053853446075099,
        0.608210428672098,
        0.6092324047831917,
        0.6078201864548732,
        0.6036825717648648,
        0.5972281888705953,
        0.5888656659294937,
        0.579003631098989,
        0.5680507125365101,
        0.556415538399486,
        0.5445067368453456,
        0.5327329360315181,
        0.521502764115432,
        0.5112248492545167,
        0.502307819606201,
        0.4940599365490541,
        0.48559522660678883,
        0.47703096565454883,
        0.4684844295674777,
        0.4600728942207195,
        0.45191363548941754,
        0.4441239292487157,
        0.43682105137375804,
        0.4301222777396879,
        0.4241448842216492,
        0.41900614669478564,
        0.414823341034241,
        0.4116567078635295,
        0.4094166448065491,
        0.4079956632390184,
        0.40728627453665667,
        0.4071809900751824,
        0.4075723212303149,
        0.4083527793777728,
        0.409414875893275,
        0.4106511221525406,
        0.4119540295312884,
        0.41321610940523723,
        0.4143298731501061,
        0.415528183043531,
        0.4170764844886778,
        0.4189161635495604,
        0.4209886062901925,
        0.4232351987745878,
        0.42559732706676034,
        0.4280163772307236,
        0.43043373533049145,
        0.4327907874300779,
        0.43502891959349643,
        0.4370895178847609,
        0.4389139683678852,
        0.4405120963923726,
        0.44195409692898296,
        0.4432719104736147,
        0.4444974775221664,
        0.4456627385705365,
        0.4467996341146234,
        0.44794010465032585,
        0.44911609067354213,
        0.4503595326801708,
        0.4517023711661104,
        0.4531765466272593,
        0.4548139995595162,
        0.4565058410424352,
        0.45814793394929787,
        0.4597793464665939,
        0.4614391467808129,
        0.46316640307844414,
        0.46500018354597744,
        0.4669795563699022,
        0.46914358973670783,
        0.4715313518328841,
        0.47418191084492034,
        0.47713433495930613,
        0.48042769236253113,
        0.48405877745223375,
        0.4879829304598316,
        0.49217703832248333,
        0.4966179879773471,
        0.5012826663615815,
        0.5061479604123447,
        0.5111907570667953,
        0.5163879432620915,
        0.5217164059353918,
        0.5271530320238549,
        0.5326747084646386,
        0.5382583221949018,
        0.5441228410133903,
        0.5504328223151335,
        0.5570835374317148,
        0.563970257694717,
        0.5709882544357232,
        0.5780327989863168,
        0.5849991626780808,
        0.5917826168425979,
        0.5982784328114519,
        0.6043818819162254,
        0.6099882354885017,
        0.6149927648598639,
        0.619704800428289,
        0.6244354382671262,
        0.6290825982210173,
        0.6335442001346033,
        0.6377181638525258,
        0.6415024092194264,
        0.6447948560799466,
        0.6474934242787275,
        0.649496033660411,
        0.6507006040696383,
        0.6510050553510509,
        0.6503073073492904,
        0.6486825785867454,
        0.6462900955653266,
        0.6431537900989597,
        0.6392975940015698,
        0.6347454390870823,
        0.6295212571694226,
        0.6236489800625162,
        0.6171525395802877,
        0.6100558675366633,
        0.6023828957455679,
        0.5941575560209267,
        0.5854037801766654,
        0.5756883508182351,
        0.5647094767157312,
        0.552688228930047,
        0.5398456785220741,
        0.5264028965527044,
        0.5125809540828313,
        0.4986009221733466,
        0.4846838718851418,
        0.4710508742791105,
        0.4579230004161444,
        0.44552132135713524,
        0.43406690816297644,
        0.42324647874174515,
        0.41261950333215347,
        0.40221618149104676,
        0.3920667127752686,
        0.3822012967416629,
        0.37265013294707494,
        0.36344342094834825,
        0.35461136030232676,
        0.3461841505658556,
        0.3381919912957786,
        0.33066508204893963,
        0.32363362238218363,
        0.3171746514112861,
        0.31130424905700105,
        0.3059611760836415,
        0.30108419325551944,
        0.2966120613369472,
        0.2924835410922373,
        0.288637393285702,
        0.2850123786816535,
        0.2815472580444045,
        0.278180792138267,
        0.2748517417275534,
        0.27149886757657626,
        0.26832225384288816,
        0.26551603324749995,
        0.2630100393999117,
        0.26073410590962287,
        0.2586180663861333,
        0.2565917544389429,
        0.2545850036775512,
        0.252527647711458,
        0.2503495201501632,
        0.24798045460316645,
        0.24535028467996733,
        0.24238884399006588,
        0.23918777590477122,
        0.23588194408637111,
        0.2324660125808337,
        0.22893464543412673,
        0.2252825066922179,
        0.22150426040107535,
        0.21759457060666687,
        0.21354810135496005,
        0.2093595166919232,
        0.20502348066352394,
        0.20053465731572997,
        0.19588771069450955,
        0.1909782745679655,
        0.18574575510201646,
        0.18025047493935437,
        0.17455275672267054,
        0.1687129230946562,
        0.16279129669800338,
        0.1568482001754033,
        0.1509439561695473,
        0.1451388873231273,
        0.13949331627883466,
        0.13406756567936057,
        0.12892195816739704,
        0.12386388457973849,
        0.11870496892959047,
        0.11351188362426182,
        0.10835130107106061,
        0.10328989367729499,
        0.09839433385027374,
        0.09373129399730494,
        0.08936744652569677,
        0.08536946384275793,
        0.08180401835579656,
        0.07873778247212089
    ],
    "WEEKDAY_MODERATE_CONGESTION_EVEN_DIST_NONFREEWAY": [
        0.05886167373227396,
        0.05772409932019001,
        0.05622824887966751,
        0.054441549945013976,
        0.05243143005053699,
        0.050265316730544095,
        0.048010637519342834,
        0.04573481995124075,
        0.04350529156054542,
        0.04138947988156435,
        0.039454812448605124,
        0.037768716795975286,
        0.036398620457982374,
        0.03528540208334215,
        0.03431788168381093,
        0.033485398838257165,
        0.032777293125549264,
        0.032182904124555635,
        0.03169157141414471,
        0.03129263457318492,
        0.03097543318054469,
        0.030729306815092425,
        0.03054359505569657,
        0.030407637481225522,
        0.030310773670547724,
        0.030221425015808837,
        0.030131125589608728,
        0.030063883991497137,
        0.030043708821023793,
        0.03009460867773842,
        0.03024059216119075,
        0.030505667870930522,
        0.03091384440650746,
        0.031489130367471294,
        0.03225553435337176,
        0.033237064963758584,
        0.034457730798181506,
        0.035724798578630236,
        0.036889428655953065,
        0.0380414700729876,
        0.03927077187257145,
        0.040667183097542185,
        0.04232055279073742,
        0.04432072999499474,
        0.04675756375315175,
        0.049720903108046055,
        0.05330059710251525,
        0.05758649477939689,
        0.06266844518152866,
        0.0683275542311425,
        0.07432650855929067,
        0.08072752827204066,
        0.08759283347546024,
        0.0949846442756172,
        0.10296518077857908,
        0.11159666309041358,
        0.12094131131718852,
        0.13106134556497143,
        0.14201898593983003,
        0.1538764525478321,
        0.16669596549504526,
        0.18101982517331355,
        0.19712525535264597,
        0.2146768612063569,
        0.23333924790776117,
        0.25277702063017365,
        0.27265478454690867,
        0.2926371448312811,
        0.31238870665660584,
        0.33157407519619714,
        0.34985785562336996,
        0.36690465311143894,
        0.3823790728337187,
        0.39699321517971287,
        0.41156612020821676,
        0.42592280259648263,
        0.43988827702176303,
        0.4532875581613101,
        0.46594566069237636,
        0.4776875992922141,
        0.4883383886380757,
        0.4977230434072135,
        0.5056665782768798,
        0.5119940079243271,
        0.5165303470268078,
        0.5188913797229274,
        0.519023498089342,
        0.5172476287103785,
        0.513884698170364,
        0.5092556330536256,
        0.5036813599444908,
        0.49748280542728646,
        0.49098089608633966,
        0.48449655850597767,
        0.4783507192705275,
        0.47286430496431636,
        0.4683582421716715,
        0.46421499189554066,
        0.45969147304580654,
        0.45492029906719844,
        0.4500340834044457,
        0.44516543950227794,
        0.44044698080542416,
        0.4360113207586138,
        0.43199107280657634,
        0.42851885039404086,
        0.425727266965737,
        0.423748935966394,
        0.4227164708407411,
        0.4226709468266657,
        0.42349383349948233,
        0.42507833581006105,
        0.42731765870927174,
        0.4301050071479846,
        0.4333335860770698,
        0.43689660044739714,
        0.4406872552098368,
        0.4445987553152589,
        0.4485243057145334,
        0.45235711135853035,
        0.4559903771981199,
        0.45967565180915476,
        0.4636895152480464,
        0.4679627196865035,
        0.4724260172962345,
        0.47701016024894777,
        0.481645900716352,
        0.48626399087015565,
        0.4907951828820671,
        0.495170228923795,
        0.4993198811670478,
        0.5031748917835339,
        0.5066660129449619,
        0.509887153732464,
        0.5129736175558051,
        0.5159182480796448,
        0.5187138889686417,
        0.5213533838874552,
        0.523829576500744,
        0.5261353104731672,
        0.5282634294693839,
        0.5302067771540527,
        0.5319581971918331,
        0.5335105332473838,
        0.534856628985364,
        0.5358144366131122,
        0.5362759144465797,
        0.536344915313344,
        0.536125292040983,
        0.5357208974570745,
        0.5352355843891959,
        0.5347732056649254,
        0.5344376141118404,
        0.5343326625575188,
        0.5345622038295379,
        0.535230090755476,
        0.5364401761629105,
        0.5381135418914571,
        0.5400866732998769,
        0.5423365284944671,
        0.5448400655815239,
        0.5475742426673439,
        0.5505160178582239,
        0.5536423492604604,
        0.55693019498035,
        0.5603565131241895,
        0.5638982617982754,
        0.567532399108904,
        0.5712358831623725,
        0.5751576775327636,
        0.5794177138330788,
        0.5839494022279941,
        0.588686152882184,
        0.5935613759603232,
        0.5985084816270876,
        0.6034608800471519,
        0.6083519813851913,
        0.6131151958058808,
        0.6176839334738956,
        0.6219916045539108,
        0.6259716192106014,
        0.6299539045807908,
        0.634221057753721,
        0.6386354938226956,
        0.643059627881017,
        0.6473558750219887,
        0.6513866503389135,
        0.6550143689250947,
        0.6581014458738351,
        0.660510296278438,
        0.6621033352322064,
        0.6627429778284434,
        0.6622916391604519,
        0.660838249445212,
        0.658589493264471,
        0.6555844172586835,
        0.6518620680683042,
        0.6474614923337871,
        0.642421736695587,
        0.6367818477941581,
        0.630580872269955,
        0.6238578567634322,
        0.6166518479150439,
        0.6090018923652446,
        0.600947036754489,
        0.5920870316226815,
        0.582124576432901,
        0.5712531412103663,
        0.5596661959802941,
        0.5475572107679018,
        0.5351196555984076,
        0.5225470004970286,
        0.510032715488982,
        0.4977702705994861,
        0.48595313585375793,
        0.4747747812770145,
        0.4644286768944745,
        0.45468400942685633,
        0.44518283948710724,
        0.43592794793878836,
        0.42692211564545945,
        0.41816812347068044,
        0.40966875227801236,
        0.40142678293101497,
        0.39344499629324825,
        0.3857261732282732,
        0.37827309459964953,
        0.3710885412709375,
        0.36417529410569766,
        0.35763594007338123,
        0.3515327281215732,
        0.34580793208103494,
        0.340403825782527,
        0.33526268305681006,
        0.3303267777346455,
        0.32553838364679405,
        0.3208397746240163,
        0.3161732244970737,
        0.3114810070967268,
        0.30670539625373644,
        0.3017886657988638,
        0.2968503629715675,
        0.2920295372906729,
        0.2872977160059914,
        0.28262642636733376,
        0.2779871956245108,
        0.27335155102733394,
        0.26869101982561383,
        0.2639771292691614,
        0.25918140660778805,
        0.25427537909130443,
        0.24923057396952156,
        0.24401851849225056,
        0.23862027080759388,
        0.23305483819182263,
        0.22735067158700165,
        0.22153622193519512,
        0.21563994017846716,
        0.20969027725888267,
        0.20371568411850577,
        0.19774461169940066,
        0.19180551094363224,
        0.18592683279326455,
        0.18013702819036187,
        0.17446454807698905,
        0.16884534714482027,
        0.16320763189721435,
        0.15756823099376285,
        0.15194397309405663,
        0.1463516868576866,
        0.14080820094424434,
        0.1353303440133207,
        0.12993494472450653,
        0.12463883173739353,
        0.11945883371157241,
        0.11441177930663417,
        0.10951449718217032,
        0.10463163875266963,
        0.09965871456234368,
        0.09465884196536817,
        0.08969513831591822,
        0.08483072096816892,
        0.08012870727629597,
        0.07565221459447445,
        0.07146436027687952,
        0.06762826167768683,
        0.06420703615107148,
        0.06126380105120869
    ],
    "WEEKDAY_SEVERE_CONGESTION_EVEN_DIST_FREEWAY": [
        0.07080919979483537,
        0.06957177512280095,
        0.06797253577166136,
        0.0660745593305948,
        0.06394092338877957,
        0.06163470553539395,
        0.05921898335961622,
        0.05675683445062466,
        0.054311336397597526,
        0.051945566789713134,
        0.04972260321614972,
        0.04770552326608559,
        0.045957404528699015,
        0.04438639415488859,
        0.042865228416122614,
        0.04140386858243382,
        0.04001227592385498,
        0.03870041171041886,
        0.03747823721215822,
        0.036355713699105834,
        0.03534280244129446,
        0.03444946470875687,
        0.033685661771525835,
        0.03306135489963409,
        0.03258650536311443,
        0.03217031691924709,
        0.03174414330081107,
        0.03135117074108717,
        0.03103458547335622,
        0.030837573730899057,
        0.03080332174699649,
        0.030975015754929364,
        0.031395841987978484,
        0.032108986679424696,
        0.03315763606254881,
        0.03458497637063165,
        0.036434193836954065,
        0.03844992329119444,
        0.04042254737047813,
        0.0424638740192564,
        0.044685711181980496,
        0.04719986680310168,
        0.0501181488270712,
        0.05355236519834032,
        0.05761432386136025,
        0.062415832760582296,
        0.06806869984045769,
        0.07468473304543764,
        0.0823757403199735,
        0.09102134545428257,
        0.10042297310273839,
        0.11057513250602588,
        0.12147233290483031,
        0.133109083539837,
        0.14547989365173072,
        0.1585792724811969,
        0.1724017292689208,
        0.18694177325558722,
        0.20219391368188158,
        0.21815265978848897,
        0.2348125208160945,
        0.2530040817941628,
        0.27326188675160595,
        0.29513638342827964,
        0.31817801956404,
        0.3419372428987434,
        0.36596450117224516,
        0.3898102421244017,
        0.41302491349506926,
        0.4351589630241033,
        0.4557628384513601,
        0.4743869875166958,
        0.49058185795996606,
        0.5049044468443314,
        0.5181996274937783,
        0.5304496620394016,
        0.5416368126122966,
        0.5517433413435582,
        0.5607515103642816,
        0.5686435818055623,
        0.5754018177984949,
        0.5810084804741746,
        0.5854458319636968,
        0.5886961343981564,
        0.5907416499086485,
        0.5910975711345261,
        0.5894747347032735,
        0.586148868728183,
        0.5813957013225474,
        0.5754909605996589,
        0.56871037467281,
        0.5613296716552935,
        0.5536245796604015,
        0.5458708268014266,
        0.5383441411916616,
        0.5313202509443986,
        0.5250748841729301,
        0.5190793239453703,
        0.5126963197269271,
        0.506038799226534,
        0.49921969015312445,
        0.49235192021563223,
        0.4855484171229905,
        0.47892210858413314,
        0.4725859223079934,
        0.46665278600350474,
        0.46123562737960083,
        0.45644737414521525,
        0.4524009540092813,
        0.4490498911352007,
        0.446241416126301,
        0.443929016351406,
        0.4420661791793388,
        0.44060639197892293,
        0.4395031421189814,
        0.438709916968338,
        0.4381802038958157,
        0.4378674902702379,
        0.43772526346042817,
        0.43770701083520963,
        0.4377662197634058,
        0.43806880945634613,
        0.4387711059188875,
        0.4398122067101445,
        0.44113120938923167,
        0.44266721151526334,
        0.4443593106473541,
        0.44614660434461856,
        0.4479681901661709,
        0.44976316567112584,
        0.4514706284185977,
        0.4530296759677012,
        0.4543794058775507,
        0.4555583766961238,
        0.45665756470764496,
        0.4576946940755991,
        0.45868748896347084,
        0.45965367353474523,
        0.46061097195290696,
        0.4615771083814408,
        0.4625698069838316,
        0.4636067919235643,
        0.46470578736412355,
        0.4658845174689941,
        0.467160706401661,
        0.46844259319582976,
        0.46965244905823866,
        0.4708290464119219,
        0.4720111576799129,
        0.4732375552852458,
        0.47454701165095425,
        0.4759782992000722,
        0.4775701903556333,
        0.4793614575406716,
        0.48139087317822093,
        0.4836972096913149,
        0.4863192395029876,
        0.48931731783260624,
        0.4926875553582767,
        0.49637235769114085,
        0.5003141304423405,
        0.5044552792230171,
        0.5087382096443127,
        0.5131053273173689,
        0.5174990378533273,
        0.5218617468633298,
        0.5261358599585182,
        0.5302637827500338,
        0.534187920849019,
        0.5380526318649581,
        0.5420126099735499,
        0.5460257656352591,
        0.5500500093105485,
        0.5540432514598819,
        0.5579634025437231,
        0.5617683730225357,
        0.5654160733567829,
        0.5688644140069289,
        0.5720713054334371,
        0.574994658096771,
        0.5775923824573944,
        0.5799866717616508,
        0.5823012012094347,
        0.5844961041915365,
        0.5865315140987467,
        0.5883675643218554,
        0.5899643882516529,
        0.5912821192789296,
        0.5922808907944759,
        0.592920836189082,
        0.5931620888535384,
        0.5929647821786351,
        0.5922890495551627,
        0.5912280758582517,
        0.5898846514003044,
        0.5882333177280202,
        0.5862486163880969,
        0.5839050889272336,
        0.5811772768921287,
        0.5780397218294809,
        0.5744669652859887,
        0.5704335488083508,
        0.565914013943266,
        0.5608829022374323,
        0.5553147552375489,
        0.5490524941571612,
        0.5420239326266719,
        0.5343309508180669,
        0.5260754289033309,
        0.5173592470544489,
        0.5082842854434066,
        0.498952424242189,
        0.4894655436227808,
        0.47992552375716796,
        0.47043424481733537,
        0.4610935869752675,
        0.45200543040295055,
        0.44286008230523,
        0.43334932344302346,
        0.4235772443224253,
        0.41364793544952844,
        0.4036654873304262,
        0.393733990471213,
        0.38395753537798155,
        0.3744402125568253,
        0.3652861125138385,
        0.35659932575511427,
        0.34848394278674566,
        0.3410440541148271,
        0.33422928447549344,
        0.32789402360327424,
        0.3219995095018549,
        0.31650698017491974,
        0.3113776736261535,
        0.30657282785924117,
        0.30205368087786744,
        0.29778147068571675,
        0.29371743528647437,
        0.28982281268382476,
        0.28605884088145256,
        0.28238675788304296,
        0.279014193519763,
        0.276115561680154,
        0.2736022764539619,
        0.2713857519309321,
        0.2693774022008104,
        0.26748864135334255,
        0.265630883478274,
        0.2637155426653504,
        0.2616540330043177,
        0.2593577685849213,
        0.2567381634969069,
        0.25370663183002046,
        0.2503705799152407,
        0.24689499641074075,
        0.24327766889705793,
        0.2395163849547289,
        0.23560893216429046,
        0.2315530981062798,
        0.22734667036123368,
        0.22298743650968889,
        0.21847318413218264,
        0.2138017008092517,
        0.20897077412143267,
        0.203978191649263,
        0.1987206853563789,
        0.19313495276814485,
        0.1872787298076907,
        0.18120975239814557,
        0.1749857564626386,
        0.16866447792429956,
        0.16230365270625757,
        0.15596101673164184,
        0.14969430592358215,
        0.14356125620520763,
        0.13761960349964739,
        0.13192708373003126,
        0.12625660705216907,
        0.12040067710361688,
        0.1144464200301236,
        0.10848096197743762,
        0.10259142909130715,
        0.09686494751748133,
        0.09138864340170835,
        0.0862496428897366,
        0.08153507212731503,
        0.07733205726019197,
        0.07372772443411578
    ],
    "WEEKDAY_SEVERE_CONGESTION_EVEN_DIST_NONFREEWAY": [
        0.0600905020191953,
        0.05902958858472378,
        0.05764267720099079,
        0.055989658805867724,
        0.054130424337225994,
        0.052124864732937,
        0.05003287093087211,
        0.047914333868902745,
        0.045829144484900296,
        0.04383719371673615,
        0.04199837250228173,
        0.04037257177940841,
        0.03901968248598759,
        0.037869803890491265,
        0.03681311814063281,
        0.035849640493145714,
        0.034979386204763444,
        0.0342023705322195,
        0.03351860873224733,
        0.03292811606158044,
        0.03243090777695231,
        0.03202699913509642,
        0.03171640539274623,
        0.03149914180663524,
        0.03137522363349693,
        0.03127535799059443,
        0.031155432763385935,
        0.031053234360897537,
        0.031006549192155286,
        0.031053163666185282,
        0.031230864192013576,
        0.03157743717866626,
        0.032130669035169396,
        0.032928346170549065,
        0.03400825499383135,
        0.03540818191404231,
        0.03716591334020803,
        0.0390376306418226,
        0.04082695497144471,
        0.04264283241269737,
        0.04459420904920357,
        0.046790030964586296,
        0.04933924424246855,
        0.052350794966473345,
        0.05593362922022362,
        0.06019669308734243,
        0.06524893265145273,
        0.07119929399617751,
        0.07815672320513982,
        0.08608005500343838,
        0.09482424303400197,
        0.10434241175719833,
        0.11458768563339532,
        0.12551318912296094,
        0.1370720466862628,
        0.1492173827836689,
        0.16190232187554712,
        0.17507998842226508,
        0.1887035068841908,
        0.2027260017216921,
        0.21710059739513676,
        0.23246878441660931,
        0.24928992097339875,
        0.2672439330386791,
        0.28601074658562503,
        0.305270287587411,
        0.3247024820172109,
        0.34398725584819956,
        0.3628045350535515,
        0.3808342456064405,
        0.39775631348004137,
        0.4132506646475285,
        0.4269972250820761,
        0.4394807439804811,
        0.4513588697699696,
        0.4625708772693593,
        0.47305604129746853,
        0.48275363667311505,
        0.4916029382151171,
        0.4995432207422924,
        0.5065137590734593,
        0.5124538280274356,
        0.5173027024230393,
        0.5209996570790886,
        0.5234839668144013,
        0.5243234865939537,
        0.5233094606840198,
        0.5207162478553651,
        0.5168182068787547,
        0.5118896965249538,
        0.5062050755647274,
        0.5000387027688409,
        0.49366493690805935,
        0.4873581367531482,
        0.48139266107487233,
        0.47604286864399714,
        0.47158311823128773,
        0.46751834302349454,
        0.46323420002589705,
        0.45882613373705333,
        0.45438958865552154,
        0.45002000927986,
        0.44581284010862693,
        0.4418635256403806,
        0.4382675103736791,
        0.43512023880708084,
        0.432517155439144,
        0.43055370476842664,
        0.42932533129348716,
        0.4288775301702122,
        0.4291351315902842,
        0.43001258260595554,
        0.43142433026947785,
        0.4332848216331033,
        0.43550850374908395,
        0.43800982366967184,
        0.4407032284471189,
        0.4435031651336773,
        0.4463240807815991,
        0.44908042244313623,
        0.45168663717054064,
        0.454333967843484,
        0.457237444964755,
        0.4603471970212815,
        0.4636133524999914,
        0.46698603988781245,
        0.4704153876716729,
        0.4738515243385003,
        0.4772445783752227,
        0.480544678268768,
        0.48370195250606407,
        0.48666652957403866,
        0.48938853795961995,
        0.4919120001135404,
        0.4943193356096177,
        0.4966182686194073,
        0.4988165233144646,
        0.5009218238663445,
        0.5029418944466028,
        0.5048844592267945,
        0.5067572423784751,
        0.5085679680731998,
        0.510324360482524,
        0.512034143778003,
        0.5137050421311922,
        0.5152326034462339,
        0.5165419318386129,
        0.5176850857968981,
        0.5187141238096584,
        0.519681104365463,
        0.5206380859528809,
        0.5216371270604805,
        0.5227302861768315,
        0.5239696217905022,
        0.525407192390062,
        0.5270950564640796,
        0.5290852725011241,
        0.5313780423482604,
        0.5339167033751813,
        0.536668017354399,
        0.5395987460584251,
        0.542675651259771,
        0.5458654947309485,
        0.5491350382444695,
        0.5524510435728457,
        0.5557802724885885,
        0.55908948676421,
        0.5623454481722214,
        0.565514918485135,
        0.5687505075587801,
        0.5721855906404899,
        0.5757580775900324,
        0.5794058782671742,
        0.5830669025316829,
        0.5866790602433255,
        0.5901802612618695,
        0.593508415447082,
        0.5966014326587303,
        0.5993972227565817,
        0.6018336956004032,
        0.6038487610499625,
        0.6055718447539126,
        0.6071530431871047,
        0.6085612724486037,
        0.6097654486374741,
        0.6107344878527801,
        0.6114373061935865,
        0.6118428197589577,
        0.6119199446479583,
        0.6116375969596527,
        0.6109646927931057,
        0.6098701482473815,
        0.6083228794215448,
        0.6063733730302504,
        0.6040876666929356,
        0.6014580028658385,
        0.5984766240051972,
        0.5951357725672498,
        0.5914276910082344,
        0.5873446217843891,
        0.5828788073519519,
        0.5780224901671611,
        0.5727679126862547,
        0.5671073173654705,
        0.561032946661047,
        0.5543118918508363,
        0.5468044010684838,
        0.5386424520539199,
        0.5299580225470729,
        0.5208830902878718,
        0.5115496330162467,
        0.5020896284721261,
        0.4926350543954389,
        0.48331788852611524,
        0.4742701086040836,
        0.465623692369273,
        0.4575106175616133,
        0.4497788323868838,
        0.44218760089611225,
        0.4347358745971008,
        0.42742260499765056,
        0.4202467436055632,
        0.41320724192864067,
        0.4063030514746844,
        0.3995331237514956,
        0.3928964102668765,
        0.38639186252862856,
        0.3800184320445529,
        0.3737750703224517,
        0.36769758826441734,
        0.3618099184359973,
        0.3560931948401773,
        0.3505285514799417,
        0.34509712235827533,
        0.33978004147816343,
        0.3345584428425909,
        0.3294134604545423,
        0.3243262283170028,
        0.3192778804329575,
        0.3142495508053907,
        0.309222373437288,
        0.30428343796201857,
        0.29950874614301576,
        0.2948628001783608,
        0.2903101022661341,
        0.28581515460441625,
        0.28134245939128844,
        0.27685651882483103,
        0.2723218351031248,
        0.2677029104242508,
        0.2629642469862895,
        0.25807034698732156,
        0.25298571262542796,
        0.24771120670229765,
        0.24228610314837154,
        0.23673252085485935,
        0.23107257871296985,
        0.22532839561391224,
        0.2195220904488961,
        0.21367578210913027,
        0.2078115894858237,
        0.2019516314701862,
        0.19611802695342653,
        0.1903328948267538,
        0.18461835398137752,
        0.17891556875980374,
        0.17316296858958052,
        0.16737856998948106,
        0.16158038947827774,
        0.1557864435747429,
        0.15001474879764964,
        0.1442833216657704,
        0.1386101786978774,
        0.1330133364127439,
        0.12751081132914222,
        0.1221206199658447,
        0.11686077884162445,
        0.11156454739882866,
        0.10610616362574442,
        0.10056511186005464,
        0.0950208764394416,
        0.08955294170158751,
        0.08424079198417529,
        0.07916391162488724,
        0.0744017849614056,
        0.07003389633141324,
        0.06613973007259247,
        0.06279877052262556
    ]
}

export default distributions