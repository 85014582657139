// TODO: https://plot.ly/javascript/time-series/#time-series-with-rangeslider
// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import { precisionRound } from 'utils/calculators/MathUtils';
import BinnedTimeUtils from 'utils/calculators/BinnedTimeUtils';

const formatData = ({ avgsByBin, metric, binMinutes, startDate, endDate }) => {
  if (!(Array.isArray(avgsByBin) && avgsByBin.length)) {
    return null;
  }

  const firstBin = avgsByBin[0];
  const lastBin = avgsByBin[avgsByBin.length - 1];

  const k = metric === 'SPEED' ? 'avgSpeed' : 'avgTT';

  const start =
    startDate ||
    BinnedTimeUtils.getDateString(firstBin.year, firstBin.month, firstBin.date);
  const end =
    endDate ||
    BinnedTimeUtils.getDateString(lastBin.year, lastBin.month, lastBin.date);

  const x = BinnedTimeUtils.getTimestampsForDateRange(start, end, binMinutes);

  const y = [];

  let xCur = 0;

  avgsByBin
    .map(({ year, month, date, hour, minute, [k]: metricValue }) => {
      const yyyy = year;
      const mm = `0${month}`.slice(-2);
      const dd = `0${date}`.slice(-2);
      const HH = `0${hour}`.slice(-2);
      const MM = `0${minute}`.slice(-2);

      const timestamp = `${yyyy}-${mm}-${dd} ${HH}:${MM}`;
      return { timestamp, metricValue };
    })
    .sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1))
    .forEach(({ timestamp, metricValue }) => {
      while (timestamp > x[xCur++]) {
        y.push(null);
      }

      y.push(precisionRound(metricValue));
    });

  while (xCur++ < x.length) {
    y.push(null);
  }
  return { x, y };
};

const getViz = ({ x, y, metric, binMinutes }) => {
  const metricName = metric === 'SPEED' ? 'Speeds' : 'Travel Times';

  const layout = {
    title: `${metricName} (${binMinutes} minute bins)`,
    xaxis: {
      title: 'Time',
      showticklabels: true,
      tickangle: 'auto',
      type: 'date',
      connectgaps: false,
      autorange: true,
      rangeselector: {
        buttons: [
          {
            count: 7,
            label: '1w',
            step: 'day',
            stepmode: 'backward'
          },
          {
            count: 1,
            label: '1m',
            step: 'month',
            stepmode: 'backward'
          },
          { step: 'all' }
        ]
      },
      rangeslider: { range: [x[0], x[x.length - 1]] }
    },
    yaxis: {
      autorange: true,
      type: 'linear',
      title: metric === 'SPEED' ? 'Speed (mph)' : 'Travel Time (sec)'
    }
    // autosize: true
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '100%' }}
      data={[
        {
          x,
          y,
          type: 'scatter',
          mode: 'lines'
        }
      ]}
      layout={layout}
      config={config}
    />
  );
};

const AvgsByBinLineChart = ({ data }) => {
  const { avgsByBin, metric, binMinutes, startDate, endDate } = data;

  const { x, y } =
    formatData({ avgsByBin, metric, binMinutes, startDate, endDate }) || {};

  return x ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        {getViz({ x, y, metric, binMinutes })}
      </div>
    </div>
  ) : null;
};

export default AvgsByBinLineChart;
