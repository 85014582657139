import registry, { dataSelection } from '../registry';

import { updateGeoMeasureVisualizerUrl } from '../actionCreators';
import { parseUrl } from '../../urlUtils';

const actionNameScope = registry[dataSelection].join(':');

export const SET_SELECTED_TMCS = `${actionNameScope}:SET_SELECTED_TMCS`;
export const SET_HOVERED_TMC = `${actionNameScope}:SET_HOVERED_TMC`;

// ------------------------------------
// Actions
// ------------------------------------
// NOTE: Works like a toggle.
//       If currently focused TMC is "focused", if becomes unfocused.
export const focusTmc = (focusedTmc = null) => (dispatch, getState) => {
  const {
    router: { location }
  } = getState();

  const curUrlParams = parseUrl(location);

  const { focusedTmc: curFocusedTmc } = curUrlParams || {};

  dispatch(
    updateGeoMeasureVisualizerUrl({
      // TOGGLE focus
      focusedTmc: focusedTmc !== curFocusedTmc ? focusedTmc : null
    })
  );
};

export const setSelectedTmcs = selectedTmcs => (dispatch, getState) => {
  const {
    router: { location }
  } = getState();

  const curUrlParams = parseUrl(location);

  const { focusedTmc } = curUrlParams || {};

  const mustUnfocusTmc =
    focusedTmc &&
    Array.isArray(selectedTmcs) &&
    selectedTmcs.length &&
    !selectedTmcs.includes(focusedTmc);

  return new Promise(resolve => {
    dispatch({ type: SET_SELECTED_TMCS, payload: selectedTmcs });
    resolve();
  }).then(() => {
    mustUnfocusTmc
      ? dispatch(updateGeoMeasureVisualizerUrl({ focusedTmc: null }))
      : Promise.resolve();
  });
};

export const setHoveredTmc = hoveredTmc => dispatch =>
  new Promise(resolve => {
    dispatch({ type: SET_HOVERED_TMC, payload: hoveredTmc });
    resolve();
  });
