import get from 'lodash.get';

const _ = { get };

const FHWA = 'FHWA';
const partialYearPreReleaseLabelRegExp = /^partial_year_\d\d$/;

export default class Pm3VersionsMetadataParser {
  static extractYearFromPm3VersionId(pm3VersionId) {
    if (typeof pm3VersionId !== 'string') {
      return null;
    }

    const [measureClassWithYear] = pm3VersionId.split(':');
    const [, year] =
      (measureClassWithYear && measureClassWithYear.split('_')) || [];

    return year || null;
  }

  constructor(versionsMetadata) {
    this.pm3VersionsMetadata = versionsMetadata || null;
  }

  get isInitialized() {
    return !!this.pm3VersionsMetadata;
  }
  get pm3VersionIds() {
    if (!this.pm3VersionsMetadata) {
      return null;
    }

    return Object.keys(this.pm3VersionsMetadata).sort();
  }

  isValidVersion(pm3VersionId) {
    const { pm3VersionIds } = this;

    const included = pm3VersionIds && pm3VersionIds.includes(pm3VersionId);

    return !!included;
  }

  get authoritativeVersionsByYear() {
    if (!this.pm3VersionsMetadata) {
      return null;
    }

    const authoritativeVersionsByYear = Object.keys(
      this.pm3VersionsMetadata
    ).reduce((acc, versionId) => {
      const { year, is_authoritative } = this.pm3VersionsMetadata[versionId];
      if (year && is_authoritative) {
        acc[year] = versionId;
      }

      return acc;
    }, {});

    return authoritativeVersionsByYear;
  }

  get calculatedYears() {
    const { pm3VersionIds, pm3VersionsMetadata } = this;

    if (!pm3VersionsMetadata) {
      return null;
    }

    const years = [];

    for (let i = 0; i < pm3VersionIds.length; ++i) {
      const versionId = pm3VersionIds[i];
      const { year } = pm3VersionsMetadata[versionId];
      years.push(year);
    }

    return [...new Set(years)].sort();
  }

  get calculatedStates() {
    const { pm3VersionIds, pm3VersionsMetadata } = this;

    if (!pm3VersionsMetadata) {
      return null;
    }

    const stateCodes = [];

    for (let i = 0; i < pm3VersionIds.length; ++i) {
      const versionId = pm3VersionIds[i];

      const { state_codes } = pm3VersionsMetadata[versionId];

      if (Array.isArray(state_codes)) {
        stateCodes.push(...state_codes);
      }
    }

    return [...new Set(stateCodes)].sort();
  }

  getAuthoritativeVersionForYear(year) {
    return _.get(this, ['authoritativeVersionsByYear', year], null);
  }

  getAvailableStateCodesForVersion(versionId) {
    return _.get(this, ['pm3VersionsMetadata', versionId, 'state_codes'], null);
  }

  getAvailableMeasuresForVersion(versionId) {
    return _.get(
      this,
      ['pm3VersionsMetadata', versionId, 'available_measures'],
      null
    );
  }

  getYearForVersion(versionId) {
    return _.get(this, ['pm3VersionsMetadata', versionId, 'year'], null);
  }

  getAvailableVersionIdsForYear(year) {
    const { pm3VersionsMetadata } = this;

    return pm3VersionsMetadata
      ? Object.keys(pm3VersionsMetadata)
          .filter(
            versionId =>
              _.get(pm3VersionsMetadata, [versionId, 'year']) === year
          )
          .sort()
      : null;
  }

  get latestFullYearAuthoritativeFhwaVersion() {
    const { pm3VersionIds, pm3VersionsMetadata } = this;

    if (!pm3VersionsMetadata) {
      return null;
    }

    let latestYear = Number.NEGATIVE_INFINITY;
    let latestFullYrAuthVer = null;

    for (let i = 0; i < pm3VersionIds.length; ++i) {
      const versionId = pm3VersionIds[i];
      const {
        is_authoritative,
        measure_class,
        partial_year_calculation,
        year
      } = pm3VersionsMetadata[versionId];

      if (
        measure_class === FHWA &&
        is_authoritative &&
        !partial_year_calculation &&
        year > latestYear
      ) {
        latestYear = year;
        latestFullYrAuthVer = versionId;
      }
    }

    return latestFullYrAuthVer;
  }

  getCrossYearComparableVersions(versionId) {
    const { pm3VersionsMetadata } = this;
    const versionMetadata =
      (pm3VersionsMetadata && pm3VersionsMetadata[versionId]) || null;

    if (!versionMetadata) {
      return null;
    }

    const {
      year,
      measure_class,
      major_version,
      minor_version
    } = versionMetadata;

    const comparableVersionsByYear = Object.keys(pm3VersionsMetadata).reduce(
      (acc, candidate_a_version_id) => {
        // Always use the specified versionId for it's respective year.
        if (versionId === candidate_a_version_id) {
          acc[year] = versionId;
          return acc;
        }

        // Need to fill in the other years

        const {
          year: candidate_a_year,
          measure_class: candidate_a_measure_class,
          major_version: candidate_a_major_version,
          minor_version: candidate_a_minor_version,
          fix_version: candidate_a_fix_version,
          prerelease_label: candidate_a_prerelease_label
        } = pm3VersionsMetadata[candidate_a_version_id];

        // If this candidate_a_ version is for the specified version's year, continue iterating...
        if (candidate_a_year === year) {
          return acc;
        }

        // Has to be same group of measures
        if (measure_class !== candidate_a_measure_class) {
          return acc;
        }

        // Has to be the same computation procedures
        if (
          major_version !== candidate_a_major_version ||
          minor_version !== candidate_a_minor_version
        ) {
          return acc;
        }

        // If this is the first match we found for the year, take it.
        if (!acc[candidate_a_year]) {
          acc[candidate_a_year] = candidate_a_version_id;
          return acc;
        }

        // We need to decide between multiple candidate versions for this year.

        const candidateYear = candidate_a_year;

        // Get the versionId we earlier chose for this year.
        const candidate_b_version_id = acc[candidateYear];

        // We already know that candidate_a and candidate_b have the same
        //   measure_class, major_version, and minor_version.
        const {
          fix_version: candidate_b_fix_version,
          prerelease_label: candidate_b_prerelease_label
        } = pm3VersionsMetadata[candidate_b_version_id];

        //   We prefer the highest fix version label since that means
        //     the calculations were run on the latest version of the
        //     NPMRDS and TMC metadata. For cross-year comparisons,
        //     we are not interested in early versions of the source data.
        if (candidate_a_fix_version !== candidate_b_fix_version) {
          acc[year] =
            candidate_a_fix_version > candidate_b_fix_version
              ? candidate_a_version_id
              : candidate_b_version_id;
          return acc;
        }

        // If the fix_versions are the same, we compare the prerelease_labels

        //   1st Prefer a version without a prerelease label to a version with one

        if (!candidate_a_prerelease_label && candidate_b_prerelease_label) {
          acc[candidateYear] = candidate_a_version_id;
          return acc;
        }

        if (candidate_a_prerelease_label && !candidate_b_prerelease_label) {
          acc[candidateYear] = candidate_a_version_id;
          return acc;
        }

        const aMatchesPreReleaseLabelPattern = partialYearPreReleaseLabelRegExp.test(
          candidate_a_prerelease_label
        );
        const bMatchesPreReleaseLabelPattern = partialYearPreReleaseLabelRegExp.test(
          candidate_b_prerelease_label
        );

        if (
          !aMatchesPreReleaseLabelPattern &&
          !bMatchesPreReleaseLabelPattern
        ) {
          console.warn('INVARIANT BROKEN. Unexpected prerelease_labels');
          return acc;
        }

        if (aMatchesPreReleaseLabelPattern && !bMatchesPreReleaseLabelPattern) {
          acc[candidateYear] = candidate_a_version_id;
          return acc;
        }

        if (!aMatchesPreReleaseLabelPattern && bMatchesPreReleaseLabelPattern) {
          acc[candidateYear] = candidate_b_version_id;
          return acc;
        }

        // Take the "latest" prerelease label
        acc[candidate_a_year] =
          candidate_a_prerelease_label.localeCompare(
            candidate_b_prerelease_label
          ) > 0
            ? candidate_a_version_id
            : candidate_b_version_id;

        return acc;
      },
      {}
    );

    return comparableVersionsByYear;
  }
}
