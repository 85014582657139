import React from 'react'
import { connect } from "react-redux"
import { Link } from 'react-router-dom'

import 'styles/mb.css'
import './homeview.css'

// import RouteComparisonStore from 'react/stores/RouteComparisonStore'
// import RouteComparisonActions from 'react/actions/RouteComparisonActions'

// import RouteDataStore from 'react/stores/RouteDataStore'

// import NotificationsStore from 'react/stores/NotificationsStore'
// import NotificationsActions from 'react/actions/NotificationsActions'

// import UserActions from 'react/actions/UserActions'
// import { HomeViewTemplateModal } from './ReportLauncher'

import HomeNav from './components/HomeNav'
// import QuickReports from './QuickReports'
// import HomeActivity from './HomeActivity'
// import Guide from './Guide'

// const $ = window.$

const PANELS = [
  {
    title: 'Create A Custom Route',
    text: 'Use route creation tool to create routes for analysis',
    tag: 'Create A Route',
    fill: 'fill-yellow',
    icon: 'fa-long-arrow-right',
    link: '/routes/create',
    // onClick: () => {
    //   browserHistory.push('')
    // }
  },
  {
    title: 'Design A Report',
    text: 'Use route comparison tool to create custom report',
    tag: 'Create A Report',
    fill: 'fill-green',
    icon: 'fa-file-text',
    link: '/report/new'
  },
  {
    title: 'View Performance Measures',
    text: 'See custom reports on Map-21 performance measures',
    tag: 'View Measures',
    fill: 'fill-blue',
    icon: 'fa-bar-chart',
    link: '/pm3/36/COUNTY'
  },
  {
    title: 'Macro View',
    text: 'View your PM3 measures on an interactive map.',
    tag: 'View Performance',
    fill: 'fill-red',
    icon: 'fa-bar-chart',
    link: '/macro'
  }
]

const PanelLayout = ({ title, text, tag, fill, icon, link, onClick }) => {
  return (
    <div className='col6 space-bottom1'>
      <Link to={link || null} onClick={onClick || null}>
        <div
          style={{ height: 150 }}
          className='pointer shadow-on-hover hover-parent animate round fill-light space-right1 contain flex flex-ms clearfix space-none-ms no-events-mobile'
        >
          <div>
            <div className='col30 space-top3 style-block pin-right'>
              <i
                className={
                  fill + ' dot pad2 fa ' + icon + ' fa-5x inline fl basic'
                }
                style={{ color: 'white' }}
              />
            </div>
            <div className='col70 pad2 flex flex-ms flex-wrap flex-align-end'>
              <p
                className='space-top1_5 space-bottom0'
                style={{ fontSize: 18 }}
              >
                {title}
              </p>
              <p className='small quiet space-bottom1_5'>{text}</p>
              <span className='small link-www2 strong animate hide-mobile'>
                {tag}
                <span className='icon next' />
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

class HomeDash extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    let panels = PANELS
      .slice(this.props.user.authLevel === 0 ? 2 : 0)
      .map(d => <PanelLayout key={d.link} {...d} />)
    return (
      <div>
        <div className='col12'>
          <div className='flex flex-wrap flex-ms'>{panels}</div>
          {/*
          <QuickReports
            header={'Quick Reports'}
            selectHomeViewTemplate={this.props.selectHomeViewTemplate}
            templates={this.props.templates}
          />
          */}
        </div>
      </div>
    )
  }
}

class HomeView extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      page: 'dashboard'
    }
    // this.onChange = this.onChange.bind(this)
    // this.changePage = this.changePage.bind(this)
    // this.selectHomeViewTemplate = this.selectHomeViewTemplate.bind(this)
  }
  render () {
    const title = 'Home', subheader = 'Dashboard',
      { user } = this.props;

    return (
      <div className='container homeView'>
        <div className='limiter clearfix'>
          <div className='col12 clearfix pad4y'>
            <div
              className='col12 pad2 fill-light round clearfix space-bottom5'
              data-test='home-header'
            >
              <div className='fl'>
                <div>
                  <h1 className='fill-lime dot pad1 fa fa-home icon-white inline fl space-right1_5 basic' />
                  <div className='inline'>
                    <h4>{title}</h4>
                    <h6>{subheader}</h6>
                  </div>
                </div>
              </div>
              <HomeNav
                page={ this.state.page }
                changePage={ this.changePage }
                numUnreadNotifications={ 0 }
              />
            </div>
            <HomeDash user={ user }
              templates={this.state.specialTemplates}
              selectHomeViewTemplate={this.selectHomeViewTemplate}
              selectedHomeViewTemplate={this.state.selectedHomeViewTemplate}
            />
          </div>
        </div>
        {/*
        <HomeViewTemplateModal
          routes={this.state.routes}
          selectHomeViewTemplate={this.selectHomeViewTemplate}
          selectedHomeViewTemplate={this.props.selectedHomeViewTemplate}
        />
        */}
      </div>
    )
  }
}

export default {
  icon: 'fa-home',
  class: "fa",
  path: '/',
  exact: true,
  mainNav: true,
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  name: 'Home',
  authLevel: 0,
  component: connect(state => ({ user: state.user }), null)(HomeView)
};
