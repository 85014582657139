import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';

import pick from 'lodash.pick';
import deepEqual from 'deep-equal';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import TTRCalculator from 'utils/calculators/TTRCalculator';

import AvgTravelTimeByBin from '../../components/AvgTravelTimeByBin';
import TTRByPeakByOutlierFilter from './components/TTRByPeakByOutlierFilter';
import MaxPeakTTRByOutlierFilter from './components/MaxPeakTTRByOutlierFilter';
// import TTRPieCharts from './components/TTRPieCharts';

const measureTravelTimeType = {
  lottr: 'All Vehicle',
  tttr: 'Freight Truck'
};

class TTRBreakdown extends Component {
  state = { loading: false };

  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, falcor }
    } = this;

    return tmc && year
      ? falcor.get(
          queryTmcAttributes(tmc, TTRCalculator.requiredTmcAttributes, year),
          queryNPMRDSDataForTmc(tmc, year),
          queryMeasureRules(measure)
        )
      : Promise.resolve();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['year', 'dataSource', 'tmc', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });
      nextProps.falcor
        .get(
          queryTmcAttributes(
            nextProps.tmc,
            TTRCalculator.requiredTmcAttributes,
            nextProps.year
          ),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { ttrCalculator } = this.props;

    const timeTypeLabel = measureTravelTimeType[ttrCalculator.measure] || '';

    return (
      <div>
        <h5>Travel Time Averages</h5>
        <AvgTravelTimeByBin data={ttrCalculator} />
        {/*
        <h5>{timeTypeLabel} Travel Time Percentiles Summary</h5>
        <TTRPieCharts data={ttrCalculator} />
      */}

        <h5>{timeTypeLabel} Travel Time Reliability By Peak</h5>
        <TTRByPeakByOutlierFilter data={ttrCalculator} />

        <h5>{timeTypeLabel} Travel Time Reliability</h5>
        <MaxPeakTTRByOutlierFilter data={ttrCalculator} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tmc, year, binMinutes, measure, dataSource } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const ttrCalculator = new TTRCalculator({
    tmc,
    year,
    dataSource,
    npmrdsData,
    tmcAttributes,
    binMinutes,
    measure,
    measureSpec
  });

  return {
    tmcAttributes,
    ttrCalculator
  };
};

export default connect(mapStateToProps)(reduxFalcor(TTRBreakdown));
