import React from 'react';

import { STATE, COUNTY, MPO, UA } from '../constants';

const NOOP = () => {};

const GeoLevelNavBar = ({
  stateCode,
  geolevel,
  selectedPm3VersionId,
  updatePm3ViewUrl
}) =>
  geolevel === STATE ? (
    <div className="row">
      <span
        className="col-2"
        style={{
          color: 'black',
          textDecorationLine: 'underline'
        }}
      >
        STATE
      </span>
    </div>
  ) : (
    <div className="row">
      <span
        className="col-1"
        onClick={() =>
          updatePm3ViewUrl({
            stateCode: null,
            geolevel: STATE
          })
        }
        style={{
          cursor: 'pointer',
          color: 'blue',
          textDecorationLine: 'none'
        }}
      >
        States
      </span>

      <span
        className="col-1"
        onClick={
          geolevel === COUNTY
            ? NOOP
            : () =>
                updatePm3ViewUrl({
                  geolevel: COUNTY
                })
        }
        style={
          geolevel === COUNTY
            ? {
                color: 'black',
                textDecorationLine: 'underline'
              }
            : {
                cursor: 'pointer',
                color: 'blue',
                textDecorationLine: 'none'
              }
        }
      >
        Counties
      </span>

      <span
        className="col-1"
        onClick={
          geolevel === MPO
            ? NOOP
            : () =>
                updatePm3ViewUrl({
                  geolevel: MPO
                })
        }
        style={
          geolevel === MPO
            ? {
                color: 'black',
                textDecorationLine: 'underline'
              }
            : {
                cursor: 'pointer',
                color: 'blue',
                textDecorationLine: 'none'
              }
        }
      >
        MPOs
      </span>

      <span
        className="col-2"
        onClick={
          geolevel === UA
            ? NOOP
            : () =>
                updatePm3ViewUrl({
                  geolevel: UA
                })
        }
        style={
          geolevel === UA
            ? {
                color: 'black',
                textDecorationLine: 'underline'
              }
            : {
                cursor: 'pointer',
                color: 'blue',
                textDecorationLine: 'none'
              }
        }
      >
        Urbanized Areas
      </span>
    </div>
  );

export default GeoLevelNavBar;
