import get from 'lodash.get';
import flatten from 'lodash.flatten';
import memoizeOne from 'memoize-one';

import {
  getGeographyInformation as falcorGetGeographyInformation,
  getGeographyBoundingBox as falcorGeographyBoundingBox,
  getPM3TmcLevelMeasureHierarchy
} from 'store/falcorGraphSelectors';

import TmcLevelPm3VersionsMetadataParser from 'utils/pm3/TmcLevelPm3VersionsMetadataParser';

import registry, { dataSelection } from '../registry';

const reduxStorePath = registry[dataSelection];

const _ = { get, flatten };

const getVersionsMetadataParser = memoizeOne(
  state => new TmcLevelPm3VersionsMetadataParser(state)
);

const getSliceState = state => {
  return _.get(state, reduxStorePath, {});
};

// === Selectors ===
export const getSelectedPm3Version = state => {
  const { selectedPm3VersionId } = getSliceState(state);
  return selectedPm3VersionId || null;
};

export const getAvailableYears = state => {
  const { calculatedYears } = getVersionsMetadataParser(state);
  return calculatedYears;
};

export const getAvailableVersions = state => {
  const { pm3VersionIds } = getVersionsMetadataParser(state);

  return pm3VersionIds;
};

export const getAvailableSubMeasuresForVersionId = (state, pm3VersionId) => {
  if (!pm3VersionId) {
    return null;
  }

  const availableMeasures = getVersionsMetadataParser(
    state
  ).getAvailableMeasuresForVersion(pm3VersionId);

  const pm3TmcLevelMeasureHierarchy = getPM3TmcLevelMeasureHierarchy(state);

  const availableSubMeasures =
    availableMeasures && pm3TmcLevelMeasureHierarchy
      ? _.flatten(
          availableMeasures
            .map(measure => pm3TmcLevelMeasureHierarchy[measure])
            .filter(submeasure => submeasure)
        )
      : [];

  return availableSubMeasures.length ? availableSubMeasures : null;
};

export const getAvailableSubMeasures = state => {
  const { selectedPm3VersionId } = getSliceState(state);

  return getAvailableSubMeasuresForVersionId(state, selectedPm3VersionId);
};

export const getAvailableStates = state => {
  const { selectedPm3VersionId } = getSliceState(state);

  const stateCodes = getVersionsMetadataParser(
    state
  ).getAvailableStateCodesForVersion(selectedPm3VersionId);

  return stateCodes;
};

export const getYear = state => {
  const pm3VersionId = getSelectedPm3Version(state);
  const year = getVersionsMetadataParser(state).getYearForVersion(pm3VersionId);

  return year || null;
};

// NOTE: This should only be used by reducers within the Redux store's geomeasureViz slice
//       It cannot access the pm3Version's available measures because they require
//       access to falcor graph, which is outside of the geomeasureViz slice.
export const getMeasure = state => {
  const { measure } = getSliceState(state);

  return measure || null;
};

// NOTE: Connected components should get the selected measure using this selector.
export const getSelectedAvailableMeasure = state => {
  const measure = getMeasure(state);

  if (!measure) {
    return null;
  }

  const availableSubMeasures = getAvailableSubMeasures(state);

  return measure &&
    availableSubMeasures &&
    availableSubMeasures.includes(measure)
    ? measure
    : null;
};

export const isPM3DataLoading = state => {
  const { pm3DataLoadIds } = getSliceState(state);
  return !!(pm3DataLoadIds && pm3DataLoadIds.size);
};

export const getGeoInfo = state => {
  const { geography } = getSliceState(state);
  const { geolevel = null, geoid = null } = geography || {};

  if (!(geolevel && geoid)) {
    return null;
  }

  const geoInfo = falcorGetGeographyInformation(state, null, geolevel, geoid);

  return geoInfo;
};

export const getGeoFilter = state => {
  const { geolevel = null, geoid = null } = getGeoInfo(state) || {};

  const geoFilter = {
    state_code: [],
    ua_code: [],
    mpo_code: [],
    county_code: []
  };

  if (geolevel && geoid) {
    geoFilter[`${geolevel.toLowerCase()}_code`] = [geoid];
  }

  return geoFilter;
};

export const getGeographyBoundingBox = state => {
  const { geolevel = null, geoid = null, states = null } =
    getGeoInfo(state) || {};

  return geolevel && geoid
    ? falcorGeographyBoundingBox(state, states, geolevel, geoid)
    : null;
};

export const getGeoName = state => {
  const { geoname = null } = getGeoInfo(state) || {};

  return geoname;
};

export const getGeoLevel = state => {
  const { geography } = getSliceState(state);
  const { geolevel = null } = geography || {};

  return geolevel;
};

export const getGeoId = state => {
  const { geography } = getSliceState(state);
  const { geoid = null } = geography || {};

  return geoid || null;
};

export const getGeoKey = state => {
  const geolevel = getGeoLevel(state);
  const geoid = getGeoId(state);

  return geolevel && geoid ? `${geolevel}|${geoid}` : null;
};

export const getGeoStates = state => {
  const { states } = getGeoInfo(state) || {};

  return states || null;
};
