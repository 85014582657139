import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { reduxFalcor } from 'utils/redux-falcor';

import get from "lodash.get"

import DeleteModal from "./components/DeleteModal"

import {
  queryNumReportsForUser,
  queryBasicReportsInfo
} from 'store/falcorGraphQueryBuilders';

import { foldersFalcorRequest } from "pages/auth/MyStuff"

import { getReportsInfoArr } from 'store/falcorGraphSelectors';

import FolderIcon from "pages/auth/MyStuff/components/FolderIcon"
import { getFoldersFromState } from "pages/auth/MyStuff"

import {
  ControlBox,
  FolderContainer,
  DropLeft
} from "./components/ControlBox"

import {
  Checkbox,
  ClearBox,
  // Dropdown,
  // DropdownList,
  // LinkDiv,
  ActionMenu
} from "./components/Parts"

export const ReportControlBox = ({ id, title, onDelete=null, addToFolder=null, folders=[] }) =>
  <ControlBox>
    <div className="control-box">
      <ul>
        <li>
          <Link to={`/report/view/${id}`}>
            <span className="fa fa-eye"/>
            View
          </Link>
        </li>
        <li>
          <Link to={`/report/edit/${id}`}>
            <span className="fa fa-pencil"/>
            Edit
          </Link>
        </li>
        { (addToFolder === null) || (!folders.length) ? null :
          <li>
            <DropLeft>
              <span className="fa fa-folder"/>
              Add to Folder
              <div className="dropdown-list">
                {
                  folders
                    .filter(({ stuff }) => stuff.reduce((a, c) => a && c.stuff_id != id, true))
                    .map(f =>
                      <FolderContainer key={ f.id }
                        onClick={ e => addToFolder(f.id) }>
                        <FolderIcon { ...f } size={ 1.5 }/>
                        { f.name }
                      </FolderContainer>
                    )
                }
              </div>
            </DropLeft>
          </li>
        }
        { onDelete === null ? null :
          <li>
            <a onClick={ onDelete }>
              <span className="fa fa-trash"/>
              Delete
            </a>
          </li>
        }
      </ul>
    </div>
  </ControlBox>

class ReportsView extends Component {
  state = {
    filter: "",
    showDeleteModal: false,
    toDelete: {},
    selectedReports: new Map()
  };

  fetchFalcorDeps() {
    return this.props.falcor
      .get(queryNumReportsForUser(), ["folders", "length"])
      .then(res => {
        const reports = get(res, 'json.reports.length', 0),
          folders = get(res, ["json", "folders", "length"], 0),
          requests = [];

        if (reports) {
          requests.push(queryBasicReportsInfo(reports));

        }
        if (folders) {
          requests.push(foldersFalcorRequest(folders))
        }
        if (requests.length) {
          return this.props.falcor.get(...requests);
        }
      })
  }

  filter(filter) {
    this.setState({ filter });
  }

  checkReport(id, title) {
    const map = this.state.selectedReports;
    if (map.has(id)) {
      map.delete(id);
    }
    else {
      map.set(id, title);
    }
    this.setState({
      selectedReports: new Map(map)
    })
  }

  asTable(reportsInfo) {
    const { filter } = this.state;
    const data = Object.keys(reportsInfo)
        .reduce((a, id) => {
          const { title, updated_at } = reportsInfo[id];
          if (!filter || title.toLowerCase().includes(filter.toLowerCase())) {
            a.push({ id, ...reportsInfo[id], sort: new Date(updated_at).valueOf() })
          }
          return a;
        }, []).sort((a, b) => b.sort - a.sort)

    return (
      <table className="table table-sm table-hover">
        <thead>
          <tr>
            <th style={ { height: "28px" } }>Report Name</th>
            <th style={ { height: "28px", width: "50px" } }>
              { !this.state.selectedReports.size ? null :
                <ClearBox onClick={ e => this.setState({ selectedReports: new Map() })}/>
              }
            </th>
            <th style={ { width: "50px" } }/>
          </tr>
        </thead>
        <tbody>
          { data.map(({ id, title, description, updated_at }) => {
              return (
                <tr key={`report_list_elem_${id}`}>
                  <td>
                    <b>{ title }</b>
                    { description === "" ? null :
                      <>
                        <br />
                        <i>{ description }</i>
                      </>
                    }
                    <br />
                    <i>Updated: { new Date(updated_at).toLocaleString() }</i>
                  </td>
                  <td style={ { width: "50px" } }>
                    <Checkbox checked={ this.state.selectedReports.has(id) }
                      onChange={ e => this.checkReport(id, title) }/>
                  </td>
                  <td style={ { width: "50px" } }>
                    <ReportControlBox id={ id }
                      title={ title }
                      onDelete={ e => this.showDeleteModal({ id: [id], title }) }
                      addToFolder={ fid => this.addToFolder(fid, id) }
                      folders={ this.props.folders }/>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  };
// //
  showDeleteModal(toDelete) {
    this.setState({ showDeleteModal: true, toDelete });
  }
  hideDeleteModal() {
    this.setState({ showDeleteModal: false, toDelete: {} });
  }
  deleteReport() {
    const { id } = this.state.toDelete;
    if (id === undefined) return;

    const map = this.state.selectedReports;
    id.forEach(k => map.delete(k));
    this.setState({ selectedReports: new Map(map) });
    return this.props.falcor.call(
      ["reports", "delete"],
      id, [], []
    )
  }

  addToFolder(folderId, reportId) {
    this.props.falcor.call(
      ["folders", "add"],
      [folderId, reportId, "report"]
    ).then(() => {})
  }
  addReportsToFolder(folder) {
    return [...this.state.selectedReports.keys()].reduce((a, c) =>
      a.then(() => !folder.has("report", c) && this.addToFolder(folder.id, c))
    , Promise.resolve())
  }

  render() {
    const selected = this.state.selectedReports,
      ids = [...selected.keys()],
      titles = [...selected.values()],
      message = [
        `Delete template${ selected.size > 1 ? "s" : "" }?`,
        ...titles
      ];
    return (
      <div className='container' style={ { marginTop: "30px", marginBottom: "50px" } }>
        <div className="clearfix py-3">
          <h1 className='fill-green dot pad1 text-xl contain fa fa-file-alt icon-white inline '></h1>
          <h3 className='inline' style={{paddingLeft: '6px', marginTop: 10, verticalAlign: 'middle' }}>Reports</h3>
          <Link to={'/templates'} style={{lineHeight: 1.5}} className="btn btn-success ml-1 br float-right">Templates</Link>
        </div>
        <div style={ {
            display: "flex"
          } }>
          <input type="text" value={ this.state.filter }
            className="form-control form-control-sm"
            placeholder="search..."
            onChange={ e => this.filter(e.target.value) }/>
          <Link to={'/report/new'} style={{lineHeight: 1.5}} className="btn btn-success ml-1">New Report</Link>
        </div>

        <ActionMenu active={ selected.size }
          folders={ this.props.folders.filter(f => ids.reduce((a, c) => a || !f.has("report", c), false)) }
          addToFolder={ folder => this.addReportsToFolder(folder) }
          showDelete={ e => this.showDeleteModal({ id: ids, message }) }/>

        <div style={ {
          backgroundColor:'#fefefe',
          padding: "15px",
          boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
          borderRadius: "4px"
        } }>
          { this.asTable(this.props.reportsInfo) }
        </div>

        <DeleteModal show={ this.state.showDeleteModal }
          onHide={ this.hideDeleteModal.bind(this) }
          type="report"
          name={ this.state.toDelete.title }
          message={ this.state.toDelete.message }
          onDelete={ this.deleteReport.bind(this) }>
        </DeleteModal>

      </div>
    );
  }
}
// //
const mapStateToProps = (state, ownProps) => ({
  reportsInfo: getReportsInfoArr(state),
  folders: getFoldersFromState(state)
});

export default {
	icon: 'fa-file-alt',
	path: '/reports',
	name: 'Reports',
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  class: 'fa',
	mainNav: true,
  auth: true,
  exact: true,
  component: connect(
    mapStateToProps,
    null
  )(reduxFalcor(ReportsView))
}
