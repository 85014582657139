import React from 'react';

import Calendar from 'components/charts/calendar/simple';

const formatData = dailyAvgs =>
  dailyAvgs &&
  Object.keys(dailyAvgs).map(dateStr => ({
    day: dateStr,
    value: dailyAvgs[dateStr]
  }));

const getViz = (calendarData, year) => {
  const data = formatData(calendarData);

  if (!data) {
    return null;
  }

  const from = `01/01/${year}`;
  const to = `12/31/${year}`;

  return (
    <div style={{ width: '100%', height: 300, color: '#333' }}>
      <Calendar data={data} animate={false} from={from} to={to} />
    </div>
  );
};

const TmcAvgsByDayCalendar = ({ calendarData, year }) =>
  calendarData && Object.keys(calendarData).length ? (
    <div style={{ width: '100%', height: 300, color: '#333' }}>
      {getViz(calendarData, year)}
    </div>
  ) : null;

export default TmcAvgsByDayCalendar;
