import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxFalcor} from 'utils/redux-falcor';
import properties from "d3-selection-multi/src/selection/properties";
import ShpDownloaderModal from './ShpDownloaderModal'

var shpwrite = require('utils/shp-write');

const measureIdsSelector = ({
                                graph: {pm3: {measureIds: {value} = {}} = {}} = {}
                            }) => value;

let checkDuplicateTmcs = [];

function nameShortener(f) {
    let short;
    f === 'am' ? short = 'a' :
        f === 'pm' ? short = 'p' :
            f === 'overnight' ? short = 'ov' :
                f === 'reporting' ? short = 'r' :
                    f === 'weekend' ? short = 'wkd' :
                        f === 'freeflow' ? short = 'ff' :
                            f === 'speed' ? short = 'spd' :
                                f === 'vhrs' ? short = 'vh' :
                                    f === 'phed' ? short = 'ph' :
                                        f === 'bins' ? short = 'bin' :
                                            f === 'pct' ? short = 'pc' :
                                                f.includes('pctl') ? short = f.split('pctl')[0] + 'pl' :
                                                    f === 'off' ? short = 'of' :
                                                        f === 'ted' ? short = 'te' :
                                                            f === 'total' ? short = 't' :
                                                            short = f;
    return short;
}

class ShpDownloader extends Component {
    downloadShp(tmcs, measures) {
        let downloadBtn = document.getElementById('ShpdownloadBtn');
        let downloadBtnOriginalInnerHtml = downloadBtn.innerHTML;
        downloadBtn.innerHTML = "<i class='fa fa-spinner fa-spin '></i> Downloading...";
        downloadBtn.disabled = true;
        tmcs = tmcs.filter((f, f_i) => {
            if (!checkDuplicateTmcs.includes(f.properties.tmc)) {
                checkDuplicateTmcs.push(f.properties.tmc);
                return true;
            } else {
                return false;
            }
        });
        const measureIds = measures.length > 0 ? measures : this.props.measureIds;
        if (!measureIds) {
            return;
        }

        checkDuplicateTmcs = [];

        const {falcor, activePM3Year, geoName, tmcMetaVars} = this.props;

        let tmcArray = tmcs.map(d => d.properties.tmc);

        let requests = [];
        let chunkSize = 50;
        for (let i = 0; i < tmcArray.length; i += chunkSize) {
            requests.push(tmcArray.slice(i, i + chunkSize))
        }
        return requests
            .reduce((a, c) => a.then(() => falcor.get(
                ['tmc', c, 'meta', activePM3Year, tmcMetaVars],
                ['tmc', c, 'year', activePM3Year, 'geometries'],
                ['pm3', 'measuresByTmc', c, activePM3Year, measureIds])
            ), Promise.resolve())
            .then(d => {
                d = falcor.getCache();
                let geoJSON = {
                    type: 'FeatureCollection',
                    features: tmcArray
                    //.filter(d => geometries.json.tmc[d.properties.tmc].year[2019].geometries.type === 'LineString')
                    //.filter((d,i) => tmcs.map(tmc => tmc.properties.id).indexOf(d.properties.id) >= i)
                        .map(tmcId => {
                            return {
                                type: "MultiLineString",
                                //type: d.type,
                                //type: geometries.json.tmc[d.properties.tmc].year[2019].geometries.type,
                                properties: {tmc: tmcId},
                                geometry: d.tmc[tmcId].year[activePM3Year].geometries.value
                            }
                        })
                };
                const filename = `${geoName.join('_')}_${activePM3Year}`;
                let aliasList = [];
                let aliasString = '>> This file was downloaded on ' + new Date() + '\n\n';
                let tmcMetaString = '>> This file was downloaded on ' + new Date() + '\n\n';
                let boolAliasListReady = false;
                geoJSON.features.forEach(feature => {
                    let measures = Object.keys(d.pm3.measuresByTmc[feature.properties.tmc][activePM3Year])
                        .filter(f => measureIds.includes(f))
                        .reduce((out, curr) => {
                            let alias = curr.split('_')
                                .filter(f => f !== 'per')
                                .map(nameShortener)
                                .map(f => f.charAt(0).toUpperCase() + f.slice(1)).join('');
                            if (!boolAliasListReady) aliasList.push([curr, alias]);
                            out[alias] = d.pm3.measuresByTmc[feature.properties.tmc][activePM3Year][curr];
                            return out
                        }, {});
                    boolAliasListReady = true;
                    // delete measures.$__path
                    let meta = d.tmc[feature.properties.tmc].meta[activePM3Year];
                    // delete meta.$__path
                    feature.properties = {
                        'tmc': feature['properties']['tmc'],
                        'year': activePM3Year,
                        ...meta,
                        ...measures,
                        ...feature.properties
                    };
                    delete feature.properties.$__path;
                });

                let keyToPass = [];
                aliasList.forEach(pair => keyToPass.push(pair[0]));

                falcor.get(['pm3', 'measureInfo', keyToPass, ['fullname', 'definition', 'equation', 'source']])
                    .then(info => {
                        aliasList.forEach((pair) => {
                            aliasString += pair[1] + ': (' + pair[0] + ') \n'
                                + 'Full name: ' + info.json.pm3.measureInfo[pair[0]]['fullname'] + '\n'
                                + 'Definition: ' + info.json.pm3.measureInfo[pair[0]]['definition'] + '\n\n';
                        });
                        falcor.get(['tmc', 'tmcMetaInfo'])
                            .then(res_tmcMetaInfo => {
                                Object.keys(res_tmcMetaInfo.json.tmc.tmcMetaInfo).forEach(label => {
                                    tmcMetaString += label + ':\n' +
                                        'Attribute Name : ' + res_tmcMetaInfo.json.tmc.tmcMetaInfo[label].attrname + '\n' +
                                        'Attribute Description : ' + res_tmcMetaInfo.json.tmc.tmcMetaInfo[label].attrdesc + '\n\n';

                                });
                                shpwrite.download(
                                    geoJSON,
                                    {
                                        file: filename,
                                        folder: filename,
                                        types: {
                                            line: filename,
                                            polyline: filename
                                        }
                                    },
                                    aliasString,
                                    tmcMetaString
                                );
                                downloadBtn.innerHTML = downloadBtnOriginalInnerHtml;
                                downloadBtn.disabled = false;
                            })

                    })

            });

    }

    render() {
        const {activeTmcs} = this.props;
        let type = 'Shapefile'
        let peaks = ['am', 'pm','max', 'overnight', 'weekend', 'off', 'total'];
        let measureListRaw = []; //this.props.measureIds.filter(f => f.split('_').includes('am') || f.split('_').includes('pm'));
        this.props.measureIds.forEach(measure => {
            let tmpMeasure = measure.split('_');
            if (peaks.includes(tmpMeasure[tmpMeasure.length - 1].toLowerCase()) &&
                !measureListRaw.includes(tmpMeasure.slice(0, tmpMeasure.length - 1).join('_')))
                measureListRaw.push(tmpMeasure.slice(0, tmpMeasure.length - 1).join('_'))
        });

        return (
            <div>
                <button style={{
                    backgroundColor: 'rgb(41, 50, 60)',
                    color: '#efefef',
                    padding: 10,
                    border: 'none',
                    cursor: 'pointer',
                    fontWeight: 400
                }}
                        onClick=/*this.downloadShp(activeTmcs)*/
                            {
                                (e) => document.getElementById('closeMe' + type).style.display =
                                    document.getElementById('closeMe' + type).style.display === 'block' ? 'none' : 'block'
                            }

                >Download Shapefile
                </button>
                <ShpDownloaderModal
                    activeTmcs={activeTmcs}
                    peaks={peaks}
                    measureListRaw={measureListRaw}
                    measureIds={this.props.measureIds}
                    onSubmit={this.downloadShp.bind(this)}
                    type={type}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(reduxFalcor(ShpDownloader));
