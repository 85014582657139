import flatten from 'lodash.flatten';

const assertNoNameCollisions = objects => {
  if (!Array.isArray(objects) || objects.length < 2) {
    return;
  }

  const allKeys = flatten(objects.map(obj => Object.keys(obj)));

  const keyNameCounts = allKeys.reduce((acc, selectorName) => {
    acc[selectorName] = acc[selectorName] || 0;
    ++acc[selectorName];

    return acc;
  }, {});

  const repeatedKeyNames = Object.keys(keyNameCounts).reduce(
    (acc, selectorName) => {
      const ct = keyNameCounts[selectorName];

      if (ct > 1) {
        acc.push(selectorName);
      }

      return acc;
    },
    []
  );

  // This should only throw during development.
  if (repeatedKeyNames.length) {
    const plural = repeatedKeyNames.length > 1 ? 's' : '';
    throw new Error(
      `The following key name${plural} are not distinct across the objects: ${repeatedKeyNames}`
    );
  }
};

export default assertNoNameCollisions;
