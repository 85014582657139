import React from 'react';
import { useParams } from "react-router-dom"
import { Select, useFalcor } from "@availabs/avl-components"
//import {  } from 'modules/avl-components/src'
import get from "lodash.get"

const pm3Controller = Component =>
  ({ ...props }) => {
    const {falcor, falcorCache} = useFalcor()
    const [geoid, setGeoid] = React.useState(36)
    const [geoLevel, setGeoLevel] = React.useState("MPO")
    const [years, setYears] = React.useState([])
    const [year, setYear] = React.useState(null)
    const params = useParams();

    React.useEffect(() => {
      const { geoid } = params;
      if (geoid) setGeoid(geoid);
    }, [params]);

    React.useEffect(() => {
      const pm3VersionsRoute = ['pm3','versionedCalculations','geoLevelVersionsMetadata']
      falcor.get(pm3VersionsRoute, [ 'geo', geoid, 'geoLevels'])
      .then(res => {
        const metadata = get(res.json, pm3VersionsRoute, {})
        
        const versionsByYear = Object.keys(metadata)
          .reduce((acc, versionId) => {
            const { year, is_authoritative } = metadata[versionId];
            if (year && is_authoritative) acc.push({year,versionId});
            return acc;
          }, []);
          setYear(versionsByYear[versionsByYear.length-1].year)
          setYears(versionsByYear)
          console.log('list', res.json)
          
      });
    }, [falcor,setYear,setYears]);

    React.useEffect(() => {
      if (!geoid || years.length === 0) return;
      return falcor.get([ 'geo', geoid, 'geoLevels'])
        .then(d => {
          const geographies = get(d.json, [ 'geo', geoid, 'geoLevels'], [])
            .map(d => `${d.geolevel}|${d.geoid}`)
          
          return falcor.get(
          ['geoAttributes', geographies, years.map(d => d.year)],
          [
            'pm3',
            'versionedCalculations',
            'version',
            years.map(d => d.versionId),
            'geolevel',
            geographies
          ]
        ).then(geodata => console.log('geodata', geodata))
      })
        
    }, [falcor, geoid, geoLevel, years]);

    const pm3Data =  React.useMemo(() => {
      const geographies = get(falcorCache, [ 'geo', geoid, 'geoLevels', 'value'], [])
        .map(d => `${d.geolevel}|${d.geoid}`)
          
      return years.reduce((out,d) => {
        out[d.year]={}
        geographies.forEach(geo => {
          out[d.year][geo] = get(falcorCache, [
            'pm3',
            'versionedCalculations',
            'version',
            d.versionId,
            'geolevel',
            geo,
            'value'
          ],{})
          out[d.year][geo]['geoname'] = get(falcorCache,[ 'geoAttributes', geo, d.year, 'value', 'geoname'], '')
        })

        return out
      },{})
    },[years, geoLevel, geoid, falcorCache])
    
    return (
      <Component 
        { ...props } 
        geoLevel={geoLevel}
        setGeoLevel={setGeoLevel}
        years={years}
        year={year}
        setYear={ setYear }
        data = { pm3Data }
      />
    )
  }

const measures = {
  'lottr_interstate' : {name: 'Level of Travel Time Reliablity - Interstate', abbr: 'LOTTR - Interstate', trend: 1},
  'lottr_noninterstate': {name: 'Level of Travel Time Reliablity - Non-Interstate',  abbr: 'LOTTR - Non-Interstate', trend: 1},
  'tttr_interstate': {name: "Truck Travel Time Reliablity - Interstate", abbr: 'TTTR', trend: 0},
  'phed': {name: 'Peak Hours of Excesive Delay', abbr:'PHED', trend: 0}
}

const PM3 = ({years,year,setYear,geoLevel,setGeoLevel,data}) =>
  <div>
    <div className='flex'>
      <div className={`px-6 py-4 cursor-pointer ${geoLevel === 'MPO' ? 'border-b-2 border-blue-500' : ''}`} onClick={() => setGeoLevel('MPO')}>MPOs</div>
      <div className={`px-6 py-4 cursor-pointer ${geoLevel === 'COUNTY' ? 'border-b-2 border-blue-500' : ''}` } onClick={() => setGeoLevel('COUNTY')}>Counties</div>
      <div className={`px-6 py-4 cursor-pointer ${geoLevel === 'UA' ? 'border-b-2 border-blue-500' : ''}`} onClick={() => setGeoLevel('UA')}>Urbanized Areas</div>
      <div className="flex-1 flex items-center justify-end">
        <span className="mr-2">Year:</span>
        <div className="flex pr-4">
          <Select 
            options={  years }
            accessor={ v => v.year }
            displayAccessor={ v => v }
            onChange={ v => setYear(v.year) }
            value={ year }
            multi={ false } 
            searchable={ false } 
            removable={ false }
          />
        </div>
      </div>
    </div>

    {Object.keys(get(data, [year], {}))
      .filter(g => g.split('|')[0] === geoLevel)
      .filter(g => !['MPO|09197504', 'MPO|09198100', 'MPO|34198200', 'UA|10162', 'UA|99998', 'UA|99999'].includes(g))
      .map(geo => {
      return (
        <div key={geo} className={`border-t border-gray-200 bg-white grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-6 sm:divide-y-0 sm:divide-x`}>
          <div className={`px-6 py-4 text-sm font-medium text-center`}>
            {get(data, `[${year}][${geo}]['geoname']`,geo)}
            
          </div>
          <div className="px-6 py-4 text-sm font-medium text-center bg-gray-50">
            <div className='flex'>
              <div className='flex-1'>
                <div className="text-gray-400 text-xs">Interstate</div>
                <div className="text-gray-900 ">
                  {get(data, `[${year}][${geo}]['interstate_miles']`, '').toLocaleString()} mi
                </div>
                <span className="text-gray-400 text-xs">{get(data, `[${year}][${geo}]['interstate_tmcs']`, '').toLocaleString()} TMCs</span>
              </div>
              <div className='flex-1'>
                <div className="text-gray-400 text-xs">NHS</div>
                <div className="text-gray-900 ">
                  {get(data, `[${year}][${geo}]['noninterstate_miles']`, '').toLocaleString()} mi
                </div>
                <span className="text-gray-400 text-xs">{get(data, `[${year}][${geo}]['noninterstate_tmcs']`, '').toLocaleString()} TMCs</span>
              </div>
            </div>
            
          </div>
          {Object.keys(measures).map(m => {
            const val = ((get(data, `[${year}][${geo}][${m}]`, 0) - get(data, `[${year-1}][${geo}][${m}]`, 0))/ get(data, `[${year}][${geo}][${m}]`, 1)*100).toFixed(1)  
            return (
              <div key={m} className="px-6 py-4 text-sm font-medium text-center">
                <div className="text-gray-900 text-2xl">{get(data, `[${year}][${geo}][${m}]`, '') ? get(data, `[${year}][${geo}][${m}]`, '').toLocaleString() : ''}
                  {get(data, `[${year-1}][${geo}][${m}]`, false) ? (
                    <span className={`text-sm pl-1 text-${val > 0  ?  (measures[m].trend ? 'green' : 'red') : (measures[m].trend ? 'red' : 'green')}-300`}>{val}%</span>
                  ) : ''}
                </div>
                <span className="text-gray-400 text-xs"> {measures[m].abbr}</span>
                 
              </div>
            )}
          )}
        </div>)
    }) }
  </div>


export default pm3Controller(PM3);
