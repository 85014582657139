import React from 'react';

import { precisionRound } from 'utils/calculators/MathUtils';

import {
  outlierFilters,
  outlierInclusionLabels
} from 'utils/calculators/OutlierHandlingUtils';

const PHEDTotalExcessiveDelayByOutlierFilter = ({
  data: { totalExcessiveDelayByOutlierFilter }
}) =>
  totalExcessiveDelayByOutlierFilter ? (
    <div>
      <style>{`
        table, th, td { border: 1px solid black; border-collapse: collapse; }
        th { background-color: #EEEEEE; } 
      `}</style>
      <table>
        <tbody>
          <tr>
            <th>Outlier Filter</th>
            <th>Total Excessive Delay (person hours)</th>
          </tr>
          {outlierFilters.map((outlierFilter, i) => (
            <tr key={`phed_xdelay_by_filter_${i}`}>
              <td>{outlierInclusionLabels[outlierFilter]}</td>
              <td>
                {precisionRound(
                  totalExcessiveDelayByOutlierFilter[outlierFilter],
                  2
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;

export default PHEDTotalExcessiveDelayByOutlierFilter;
