import React, { Component } from 'react';
import { connect } from 'react-redux';

import { reduxFalcor } from 'utils/redux-falcor';

import Select from 'react-select';

import { getMeasureInfo } from 'store/falcorGraphSelectors';

import MeasureInfoBox from 'components/information/MeasureInfoBox';

const PEAKS_REGEXP = /_am$|_off$|_pm$|_overnight$|_weekend$/;

const measureOptionsSelector = ({
  measureInfo,
  excludePeakBreakdowns,
  availableMeasures
}) => {
  const options =
    measureInfo &&
    Object.keys(measureInfo)
      .filter(mId => {
        if (!mId) {
          return false;
        }

        if (availableMeasures && !availableMeasures.includes(mId)) {
          return false;
        }

        if (excludePeakBreakdowns) {
          return !(
            mId.match(PEAKS_REGEXP) ||
            (measureInfo[mId].fullname &&
              measureInfo[mId].fullname.match(/ Peak$/i))
          );
        }

        return true;
      })
      .map(mId => ({
        value: mId,
        label: (measureInfo[mId] && measureInfo[mId].fullname) || mId
      }))
      .sort(({ value: a }, { value: b }) => a.localeCompare(b));

  return Array.isArray(options) && options.length ? options : null;
};

class MeasureSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMeasureInfo: false
    };
  }

  UNSAFE_componentWillReceiveProps({ measure, measureInfo }) {
    if (measure && !(measureInfo && measureInfo.definition)) {
      return this.props.falcor.get([
        'pm3',
        measure,
        ['fullname', 'definition', 'equation', 'source']
      ]);
    }
  }

  fetchFalcorDeps() {
    return this.props.falcor
      .get(['pm3', 'measureIds'])
      .then(({ json: { pm3: { measureIds } } }) => {
        return this.props.falcor.get([
          'pm3',
          'measureInfo',
          measureIds,
          'fullname'
        ]);
      });
  }

  render() {
    const {
      selectedMeasure,
      measureOptions,
      excludeFullNameInInfoBox
    } = this.props;

    if (!measureOptions) {
      return <div>Loading</div>;
    }

    const currentValue =
      measureOptions.find(({ value }) => value === selectedMeasure) || null;

    return (
      <div>
        <div>
          <div style={{ width: '10%', float: 'left' }}>
            {this.props.popoutMeasureInfoBox ? (
              <i
                style={{
                  height: '10px',
                  width: '10px'
                }}
                className="os-icon os-icon-info"
                onClick={() =>
                  this.setState({
                    showMeasureInfo: !this.state.showMeasureInfo
                  })
                }
              />
            ) : null}
          </div>
          <div style={{ width: '90%', float: 'left' }}>
            <Select
              value={currentValue}
              isMulti={false}
              options={measureOptions}
              onChange={({ value }) => {
                this.setState({ selectedMeasure: value });
                if (this.props.autoApply) {
                  this.props.setMeasure(value);
                }
              }}
            />
          </div>
        </div>
        {this.props.autoApply ? null : (
          <button onClick={() => this.props.setMeasure(selectedMeasure)}>
            Apply
          </button>
        )}
        {this.props.popoutMeasureInfoBox && this.state.showMeasureInfo ? (
          <div
            style={{
              position: 'absolute',
              left: '-300px',
              color: 'black',
              width: '300px',
              backgroundColor: 'white',
              top: '0%'
            }}
          >
            <MeasureInfoBox
              measure={selectedMeasure}
              excludeFullNameInInfoBox={excludeFullNameInInfoBox}
            />
          </div>
        ) : null}
        {!this.props.popoutMeasureInfoBox ? (
          <MeasureInfoBox
            measure={selectedMeasure}
            excludeFullNameInInfoBox={excludeFullNameInInfoBox}
          />
        ) : null}
      </div>
    );
  }
}

MeasureSelector.defaultProps = {
  autoApply: false,
  popoutMeasureInfoBox: true,
  excludePeakBreakdowns: false
};

const mapStateToProps = (state, ownProps) => {
  const measureInfo = getMeasureInfo(state);
  const {
    selectedMeasure,
    excludePeakBreakdowns,
    availableMeasures
  } = ownProps;
  return {
    selectedMeasure,
    measureOptions: measureOptionsSelector({
      measureInfo,
      excludePeakBreakdowns,
      availableMeasures
    })
  };
};

export default connect(mapStateToProps)(reduxFalcor(MeasureSelector));
