import registry, { dataSelection } from '../registry';

import { updateGeoMeasureVisualizerUrl } from '../actionCreators';

const actionNameScope = registry[dataSelection].join(':');

export const START_PM3_DATA_LOAD = `${actionNameScope}:START_PM3_DATA_LOAD`;
export const END_PM3_DATA_LOAD = `${actionNameScope}:END_PM3_DATA_LOAD`;
export const PM3_VERSION_SELECTED = `${actionNameScope}:PM3_VERSION_SELECTED`;
export const RECEIVE_PM3_VERSIONS_METADATA = `${actionNameScope}:RECEIVE_PM3_VERSIONS_METADATA`;

export const geographySelected = ({
  geolevel = null,
  geoid = null
} = {}) => dispatch =>
  dispatch(updateGeoMeasureVisualizerUrl({ geolevel, geoid }));

export const pm3VersionSelected = (pm3VersionId = null) => dispatch => {
  dispatch(updateGeoMeasureVisualizerUrl({ pm3VersionId }));
};

export const measureSelected = (measure = null) => dispatch =>
  dispatch(updateGeoMeasureVisualizerUrl({ measure }));

export const startPM3DataLoad = loadId => dispatch =>
  new Promise(resolve => {
    dispatch({ type: START_PM3_DATA_LOAD, payload: loadId });
    resolve();
  });

export const endPM3DataLoad = loadId => dispatch =>
  new Promise(resolve => {
    dispatch({ type: END_PM3_DATA_LOAD, payload: loadId });
    resolve();
  });

export const receivedPm3MeasuresMetadata = pm3VersionsMetadataParser => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: RECEIVE_PM3_VERSIONS_METADATA,
      payload: pm3VersionsMetadataParser
    });
    resolve();
  });
