import React from 'react';
import { Input } from "components/common/styled-components"
import ItemSelector from 'components/common/item-selector/item-selector';
import Modal from "components/AvlStuff/AvlModal";
import deepequal from "deep-equal"

class SaveModal extends React.Component {
  state = { ...this.props.route }

  componentDidUpdate() {
    if (!this.props.show && !deepequal(this.state, this.props.route)) {
      this.setState({ ...this.props.route });
    }
  }

  onChange(update) {
    if ((update.type === "personal") && (this.state.type === "group")) {
      update = {
        ...update,
        owner: this.props.user.id
      }
    }
    else if ((update.type === "group") && (this.state.type === "personal")) {
      update = {
        ...update,
        owner: this.props.user.groups[0]
      }
    }
    this.setState(update);
  }

  onSave() {
    this.props.updateRoute({ ...this.state });
    return this.props.saveRoute({ ...this.state }, this.props.routeId);
  }

  onSaveAs() {
    this.props.updateRoute({ ...this.state });
    return this.props.saveRoute({ ...this.state });
  }
  render() {
    return (
      <Modal show={ this.props.show }
        onHide={ this.props.onHide }
        hideOnAction={ false }
        onResolve={ this.props.onResolve }
        onResolveHide={ this.props.onResolveHide }
        actions={
          [
            { label: this.props.routeId === null ? "Save Route" : "Save Changes",
              disabled: !this.state.name.length,
              action: this.onSave.bind(this),
              type: "success" },
            { label: "Save As New Route",
              disabled: !this.state.name.length,
              action: this.onSaveAs.bind(this),
              type: "success" }
          ].filter(({ label }) => (label !== "Save As New Route") || this.props.routeId)
        }>

        <div style={ { minWidth: "400px" } }>
          <div style={ { marginBottom: "20px" } }>
            <label>Route Name</label>
            <Input type="text"
              onChange={ e => this.onChange({ name: e.target.value }) }
              placeholder="enter a name..."
              value={ this.state.name }/>
          </div>


          <div style={ { marginBottom: "20px" } }>
            <label>Route Type</label>
            <ItemSelector
              multiSelect={ false }
              searchable={ false }
              selectedItems={ this.state.type }
              options={ ["personal", "group"] }
              displayOption={ d => d }
              getOptionValue={ d => d }
              onChange={ type => this.onChange({ type }) }/>
          </div>

          <div>
            <label>Route Owner</label>
            <ItemSelector
              disabled={ this.state.type === "personal" }
              multiSelect={ false }
              searchable={ false }
              selectedItems={ this.state.owner }
              options={ [...this.props.user.groups] }
              displayOption={ d => /\d+/.test(d) ? "self" : d }
              getOptionValue={ d => d }
              onChange={ group => this.onChange({ group }) }/>
          </div>
        </div>

      </Modal>
    )
  }
}

export default SaveModal
