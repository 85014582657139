import React from "react"
import { connect } from "react-redux"
import { reduxFalcor, UPDATE as REDUX_UPDATE } from 'utils/redux-falcor'

import { ResponsiveBar } from "@nivo/bar"

import get from "lodash.get"
import { format as d3format } from "d3-format"

import { getColorRange } from "constants/color-ranges";
const DEFAULT_COLOR_RANGE = getColorRange(8, "Set3");

class AverageSpeedGraph extends React.Component {
  static defaultProps = {
    height: 400
  }
  fetchFalcorDeps() {
    if (!this.props.countId) return Promise.resolve();

    return this.props.falcor.get(
      ["hds", "average", "speed", "byId", this.props.countId,
        ["count_id", "count_data", "month", "year", "data_type"]
      ]
    )
  }
  render() {
    const format = d3format(",d"),
      format1f = d3format(",.1f");
    return (
      <div style={ { width: "100%", height: `${ this.props.height }px` } }>
        <table style={ { marginBottom: "10px" } }>
          <thead>
            <tr>
              <th colSpan={ 2 }>Direction</th>
              <th>Speed Limit</th>
              <th>Average Speed</th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.graphKeys.map(k =>
                <tr key={ k }>
                  <td style={ { width: "30px" } }>
                    <div style={ { width: "20px", height: "20px", background: this.props.metaData[k].color, marginRight: "5px" } }/>
                  </td>
                  <td>{ k }</td>
                  <td>{ this.props.metaData[k].speed_limit ? `${ this.props.metaData[k].speed_limit } MPH` : "unknown" }</td>
                  <td>{ format1f(this.props.metaData[k].avg_speed) } MPH</td>
                </tr>
              )
            }
          </tbody>
        </table>
        <ResponsiveBar data={ this.props.graphData }
          keys={ this.props.graphKeys }
          indexBy="id"
          groupMode="grouped"
          enableLabel={ false }
          colors={ d => this.props.metaData[d.id].color }
          margin={ {
            top: 20,
            right: 20,
            bottom: 50,
            left: 50
          } }
          tooltipFormat={ format }
          axisLeft={ {
            format
          } }
          axisBottom={ {
            legend: "Miles Per Hour",
            legendPosition: "middle",
            legendOffset: 35
          } }
          theme={ {
            axis: {
              ticks: {
                text: {
                  fill: "currentColor"
                }
              },
              legend: {
                text: {
                  fill: "currentColor"
                }
              }
            }
          } }/>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  graphData: getGraphData(state, props),
  graphKeys: getGraphKeys(state, props),
  metaData: getMetaData(state, props)
})

export default connect(mapStateToProps, null)(reduxFalcor(AverageSpeedGraph));

const DIRECTIONS = {
  1: "Northbound",
  3: "Eastbound",
  5: "Southbound",
  7: "Westbound"
}
const SPEED_BINS = {
  1: "20 and Under",
  2: "20.1 - 25",
  3: "25.1 - 30",
  4: "30.1 - 35",
  5: "35.1 - 40",
  6: "40.1 - 45",
  7: "45.1 - 50",
  8: "50.1 - 55",
  9: "55.1 - 60",
  10: "60.1 - 65",
  11: "65.1 - 70",
  12: "70.1 - 75",
  13: "75.1 - 80",
  14: "80.1 - 85",
  15: "Over 85"
}

const getGraphData = (state, props) => {
  if (!props.countId) return [];

  const graphData = [];

  const count = get(state, ["graph", "hds", "average", "speed", "byId", props.countId], {}),
    count_data = get(count, ["count_data", "value"], [])
      .filter(d => (d.federal_direction != 0) && (d.federal_direction != 9));

  for (let i = 1; i <= 15; ++i) {
    const barData = {
      id: SPEED_BINS[i]
    }
    count_data.forEach(d => {
      const key = get(DIRECTIONS, d.federal_direction, d.federal_direction.toString());
      barData[key] = d.count_data[i] || 0;
    })
    graphData.push(barData);
  }

  return graphData;
}
const getGraphKeys = (state, props) => {
  if (!props.countId) return [];

  const count = get(state, ["graph", "hds", "average", "speed", "byId", props.countId], {});

  return get(count, ["count_data", "value"], [])
    .filter(d => (d.federal_direction != 0) && (d.federal_direction != 9))
    .map(d => +d.federal_direction)
    .sort((a, b) => a - b)
    .map(d => get(DIRECTIONS, d, d.toString()))
}
const getMetaData = (state, props) => {
  if (!props.countId) return {};

  const count = get(state, ["graph", "hds", "average", "speed", "byId", props.countId], {}),
    count_data = get(count, ["count_data", "value"], [])
      .filter(d => (d.federal_direction !== 0) && (d.federal_direction !== 9));

  return count_data
    .sort((a, b) => +a.federal_direction - +b.federal_direction)
    .reduce((a, c, i) => {
      a[get(DIRECTIONS, c.federal_direction, c.federal_direction.toString())] = {
        speed_limit: c.speed_limit,
        avg_speed: c.avg_speed,
        fiftyth_percentile_speed: c.fiftyth_percentile_speed,
        eightyfifth_percentile_speed: c.eightyfifth_percentile_speed,
        color: DEFAULT_COLOR_RANGE[(i + 3) % (DEFAULT_COLOR_RANGE.length)]
      }
      return a;
    }, {})
}
