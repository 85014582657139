import React from 'react';

import Select from 'react-select';

const PM3CalculationsVersionSelector = props => {
  const { pm3VersionId, pm3VersionIds, onChange } = props;

  if (!pm3VersionIds) {
    return <span>Loading</span>;
  }

  return (
    <Select
      value={
        pm3VersionId
          ? {
              value: pm3VersionId,
              label: pm3VersionId
            }
          : undefined
      }
      isMulti={false}
      options={pm3VersionIds.sort().map(v => ({
        value: v,
        label: v
      }))}
      onChange={({ value }) => onChange(value)}
    />
  );
};

export default PM3CalculationsVersionSelector;
