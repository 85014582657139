// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';
import ATRIHourlySpeedsViolinPlots from './ATRIHourlySpeedsViolinPlots';

import { precisionRound } from 'utils/calculators/MathUtils';

const formatData = sortedSpeeds => {
  if (!(Array.isArray(sortedSpeeds) && sortedSpeeds.length)) {
    return null;
  }

  const x = [];
  const y = [];
  const text = [];

  let ct = 1;
  for (let i = 0; i < sortedSpeeds.length; i += ct) {
    ct = 1;
    const avgSpeed = sortedSpeeds[i];
    const curSpeed = precisionRound(avgSpeed);

    while (true) {
      const peekedSpeed = sortedSpeeds[i + ct];

      if (!peekedSpeed || curSpeed !== precisionRound(peekedSpeed)) {
        break;
      }
      ++ct;
    }

    x.push(curSpeed);
    y.push((y[y.length - 1] || 0) + ct / sortedSpeeds.length);
    text.push(`${ct} occurrance${ct > 1 ? 's' : ''}`);
  }

  return { x, y, text };
};

const getViz = ({ sortedSpeeds, atriFreeFlowSpeed }) => {
  const { x, y, text } = formatData(sortedSpeeds) || {};
  const ff = precisionRound(atriFreeFlowSpeed);

  const data = [
    {
      x,
      y,
      text,
      type: 'scatter',
      line: { width: 1, shape: 'hv' }
    }
  ];

  const annotations = [
    {
      text: `Free-Flow (${ff} mph)`,
      xref: 'x',
      x: ff,
      yref: 'paper',
      y: 1
    }
  ];

  const shapes = [
    {
      type: 'line',
      xref: 'x',
      x0: ff,
      x1: ff,
      yref: 'paper',
      y0: 0,
      y1: 1
    }
  ];

  const layout = {
    title: 'Distribution of Speeds Across Year',
    xaxis: {
      title: 'Speed (mph)'
    },
    yaxis: {
      title: 'Probability',
      hoverformat: '.5f'
    },
    annotations,
    shapes
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '62%', height: 600 }}
      data={data}
      layout={layout}
      config={config}
    />
  );
};

const AtriFreeFlow = ({ data }) => {
  const { atriFreeFlowSpeed, sortedSpeeds } = data;
  return sortedSpeeds ? (
    <div>
      <div style={{ width: '100%', color: '#333' }}>
        <blockquote
          style={{
            width: '38%',
            marginLeft: 20,
            backgroundColor: '#F2F4F8',
            padding: 12
          }}
        >
          1. Determine Free Flow Speed. The first step sets a free flow speed –
          a value at which congestion has no constraint on mobility. This
          research assumes a value of 55 MPH to signify free flow at all 300
          locations. However, this assumption is limited as posted speed is not
          identified for all locations.<sup>1</sup>
        </blockquote>
        <p style={{ width: '62%' }}>
          For this measure AVAIL defines the Free-Flow Speed as the 70
          <sup>th</sup> percentile speed observed across all days of the week
          and times of day for the entire year. This yields the following:
        </p>
        <p>
          <b>ATRI Free-Flow Speed:</b>
          {`\u00a0${precisionRound(atriFreeFlowSpeed)} mph`}
        </p>
        <p style={{ width: '62%' }}>
          The following{' '}
          <a
            href="https://en.wikipedia.org/wiki/Cumulative_distribution_function"
            target="_blank"
            rel="noopener noreferrer"
          >
            cumulative distribution function
          </a>{' '}
          shows the relation of the distribution of the observed speeds to the
          free-flow value used in the ATRI measure calculation. (The following
          link provides a good explanation of how to read these charts: 
          <a
            href="https://www.andata.at/en/software-blog-reader/why-we-love-the-cdf-and-do-not-like-histograms-that-much.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
          .)
        </p>
        {getViz(data)}
        <p style={{ width: '62%' }}>
          For additional insight, the following{' '}
          <a
            href="https://en.wikipedia.org/wiki/Violin_plot"
            target="_blank"
            rel="noopener noreferrer"
          >
            violin plot
          </a>{' '}
          provides a deeper look at the distribution of observed speeds for each
          hour of day. (The following link provides a good explanation of how to
          read these charts: 
          <a
            href="https://blog.bioturing.com/2018/05/16/5-reasons-you-should-use-a-violin-graph/"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
          .)
        </p>
        <ATRIHourlySpeedsViolinPlots data={data} />
      </div>
    </div>
  ) : null;
};

export default AtriFreeFlow;
