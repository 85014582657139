import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  ${props => props.theme.scrollBar};
  overflow: auto;
  height: 100%;
`;

export default () => (
  <div
    style={{
      height: '80vh',
      width: '80vw',
      padding: '25px'
    }}
  >
    <Container className="container-fluid">
      <div className="row">
        <h4>Measure Version Naming Conventions</h4>
      </div>

      <div className="row">
        <p> &nbsp;</p>
      </div>

      <div className="row">
        <h5>Measure Classes</h5>
      </div>
      <div className="row">
        <p className="col-12">
          <span style={{ fontWeight: 'bolder', color: 'red' }}>FHWA</span>
          <span style={{ fontWeight: 'lighter' }}>_2019:1.2.1</span>
        </p>
        <p className="col-12">
          The first part of a measure version name is the measure class. There
          are currently three measure classes:
        </p>
        <dl style={{ paddingLeft: 20 }}>
          <dt>FHWA</dt>
          <dd style={{ paddingLeft: 20 }}>
            <span>
              The canonical PM3 measures as defined in the CFR Part 490 and the
              official FHWA guidance documents. These are
            </span>

            <ul style={{ listStyleType: 'disc' }}>
              <li>
                Level of Travel Time Reliability <i>(LOTTR)</i>
              </li>
              <li>
                Truck Travel Time Reliability <i>(TTTR)</i>
              </li>
              <li>
                Peak-Hour Excessive Delay <i>(PHED)</i>
              </li>
            </ul>

            <span>See</span>
            <ul style={{ listStyleType: 'disc' }}>
              <li>
                <a
                  href="https://www.govinfo.gov/content/pkg/FR-2017-01-18/pdf/2017-00681.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Code of Federal Regulations Title 23 Part 490
                </a>
              </li>
              <li>
                <a
                  href="https://www.fhwa.dot.gov/tpm/guidance/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FHWA Transportation Performance Management Guidance
                </a>
              </li>
            </ul>
          </dd>

          <dt>AUX</dt>
          <dd style={{ paddingLeft: 20 }}>
            <p>
              Variations on the FHWA measures and other common traffic
              performance metrics.
            </p>
            <p>
              {' '}
              An example variation is the Total Excessive Delay <i>
                (TED)
              </i>{' '}
              measure which follows the same formulas as PHED, except all travel
              times are included in the calculation. PHED limits the included
              travel times to those occuring for peak periods.
            </p>
          </dd>

          <dt>RIS</dt>
          <dd style={{ paddingLeft: 20 }}>
            <span>
              A subset of the measures from the FHWA and AUX measure classes
              where road segment metadata such as AADT comes from AVAIL's
              conflation of
            </span>
            <ul style={{ listStyleType: 'disc' }}>
              <li>The NYS Road Inventory System Geodatabase</li>
              <li>
                <a
                  href="https://npmrds.ritis.org/analytics/shapefiles"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The NPMRDS Shapefile provided by RITIS
                </a>
              </li>
              <li>
                <a
                  href="https://www.openstreetmap.org/about"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OpenStreetMap
                </a>
              </li>
            </ul>
          </dd>
        </dl>

        <p className="col-12">
          <b>Note</b> that currently only the <b>FHWA</b> measure class is
          available for geography level aggregation measures, such as Interstate
          and Non-Interstate LOTTR across an MPO.
        </p>
      </div>

      <div className="row">
        <p> &nbsp;</p>
      </div>

      <div className="row">
        <h5>Year</h5>
      </div>
      <div className="row">
        <p className="col-12">
          <span style={{ fontWeight: 'lighter' }}>FHWA_</span>
          <span style={{ fontWeight: 'bolder', color: 'red' }}>2019</span>
          <span style={{ fontWeight: 'lighter' }}>:1.2.1</span>
        </p>
        <p className="col-12">
          The second part of a measure version name is the year for which the
          measures are calculated.
        </p>
      </div>

      <div className="row">
        <p> &nbsp;</p>
      </div>

      <div className="row">
        <h5>Calculation Version Number</h5>
      </div>
      <div className="row">
        <p className="col-12">
          <span style={{ fontWeight: 'lighter' }}>FHWA_2019:</span>
          <span style={{ fontWeight: 'bolder', color: 'red' }}>1.2.1</span>
        </p>
        <p className="col-12">
          The third part of a measure version name is the calculation semantic
          version number. This number is based on the{' '}
          <a
            href="https://semver.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Semantic Versioning 2.0.0
          </a>{' '}
          convention.
        </p>

        <p className="col-12">
          The calculation version number consists of three parts
        </p>

        <dl style={{ paddingLeft: 20 }}>
          <dt>MAJOR version</dt>
          <dd style={{ paddingLeft: 20 }}>
            <p className="col-12">
              <span style={{ fontWeight: 'lighter' }}>FHWA_2019:</span>
              <span style={{ fontWeight: 'bolder', color: 'red' }}>1</span>
              <span style={{ fontWeight: 'lighter' }}>:.2.1</span>
            </p>
            <p>
              The MAJOR version will increment ONLY when the official FHWA
              measure definitions changed.
            </p>
          </dd>

          <dt>MINOR version</dt>
          <dd style={{ paddingLeft: 20 }}>
            <p className="col-12">
              <span style={{ fontWeight: 'lighter' }}>FHWA_2019:1.</span>
              <span style={{ fontWeight: 'bolder', color: 'red' }}>2</span>
              <span style={{ fontWeight: 'lighter' }}>.1</span>
            </p>

            <p>
              The MINOR version will increment ONLY when AVAIL's computation
              procedure changed for a measure in the measure class.
            </p>
          </dd>

          <dt>PATCH version</dt>
          <dd style={{ paddingLeft: 20 }}>
            <p className="col-12">
              <span style={{ fontWeight: 'lighter' }}>FHWA_2019:1.2.</span>
              <span style={{ fontWeight: 'bolder', color: 'red' }}>1</span>
            </p>

            <p>The PATCH version increments when</p>
            <ul style={{ listStyleType: 'disc' }}>
              <li>RITIS releases revised NPMRDS data.</li>
              <li>RITIS releases revised TMC metadata.</li>
              <li>
                For the RIS measure class, AVAIL revises the NPMRDS/RIS/OSM
                conflation output.
              </li>
              <li>
                For the AUX and RIS measure classes, a new measure was added to
                the measure class.
              </li>
            </ul>
          </dd>
        </dl>
      </div>

      <div className="row">
        <h5>Pre-release Label</h5>
      </div>
      <div className="row">
        <p className="col-12">
          <span style={{ fontWeight: 'lighter' }}>FHWA_2019:1.2.1:</span>
          <span style={{ fontWeight: 'bolder', color: 'red' }}>draft</span>
        </p>
        <p className="col-12">
          This optional label indicates that the measure calculations are not
          official. A common example is the <i>"partial_year_*"</i> label that
          indicates that the measures were computed using a partial year of
          NPMRDS data.
        </p>
      </div>
    </Container>
  </div>
);
