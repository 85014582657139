import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxFalcor} from 'utils/redux-falcor';
import ShpDownloaderModal from "./ShpDownloaderModal";


const measureIdsSelector = ({
                                graph: {pm3: {measureIds: {value} = {}} = {}} = {}
                            }) => value;


class CSVDownloader extends Component {
    downloadCSV(tmcs,measures) {
        const {falcor, activePM3Year, geoName, tmcMetaVars} = this.props;
        const measureIds = measures.length > 0 ? measures : this.props.measureIds;

        if (!measureIds) {
            return;
        }
        // console.log(tmcs, activePM3Year)
        return falcor
            .get(
                ['pm3', 'measuresByTmc', tmcs, [activePM3Year], measureIds],
                ['tmc', tmcs, 'meta', activePM3Year, tmcMetaVars]
            )
            .then(d => {
                const filename = `${geoName.join('_')}_${activePM3Year}.csv`;
                const {json: {pm3: {measuresByTmc} = {}} = {}} = d;

                const cols = Array.prototype.concat('tmc', 'year', tmcMetaVars, measureIds);
                let checkDuplicateTmcs = [];
                const csvContent = Object.keys(measuresByTmc)
                    .reduce(
                        (acc, tmc) => {
                            if (!checkDuplicateTmcs.includes(tmc)) {
                                checkDuplicateTmcs.push(tmc);
                                if (/_path/.test(tmc)) {
                                    return acc;
                                }
                                acc.push(
                                    cols
                                        .map((c, i) => {
                                            if (c === 'tmc') {
                                                return tmc;
                                            }

                                            if (c === 'year') {
                                                return activePM3Year;
                                            }

                                            if (measureIds.includes(c)) {
                                                return measuresByTmc[tmc][activePM3Year][c]
                                            }

                                            if (tmcMetaVars.includes(c)) {
                                                return d.json.tmc[tmc].meta[activePM3Year][c]
                                            }
                                        })
                                        .join()
                                );
                                return acc;
                            }
                        },
                        [cols.join()]
                    )
                    .join('\n');

                const blob = new Blob([csvContent], {
                    type: 'text/csv;charset=utf-8;'
                });
                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, filename);
                } else {
                    var link = document.createElement('a');
                    if (link.download !== undefined) {
                        // feature detection
                        // Browsers that support HTML5 download attribute
                        var url = URL.createObjectURL(blob);
                        link.setAttribute('href', url);
                        link.setAttribute('download', filename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            });
    }

    render() {
        const {activeTmcs} = this.props;
        let type = 'CSV'
        let peaks = ['am', 'pm','max', 'overnight', 'weekend', 'off', 'total'];
        let measureListRaw = []; //this.props.measureIds.filter(f => f.split('_').includes('am') || f.split('_').includes('pm'));
        this.props.measureIds.forEach(measure => {
            let tmpMeasure = measure.split('_');
            if (peaks.includes(tmpMeasure[tmpMeasure.length - 1].toLowerCase()) &&
                !measureListRaw.includes(tmpMeasure.slice(0, tmpMeasure.length - 1).join('_')))
                measureListRaw.push(tmpMeasure.slice(0, tmpMeasure.length - 1).join('_'))
        });
        return (
            <div>
                <button style={{
                    backgroundColor: 'rgb(41, 50, 60)',
                    color: '#efefef',
                    padding: 10,
                    border: 'none',
                    cursor: 'pointer',
                    fontWeight: 400
                }}
                        onClick=/*this.downloadShp(activeTmcs)*/
                            {
                                (e) => document.getElementById('closeMe' + type).style.display =
                                    document.getElementById('closeMe' + type).style.display === 'block' ? 'none' : 'block'
                            }>Download CSV
                </button>
                <ShpDownloaderModal
                    activeTmcs={activeTmcs}
                    peaks={peaks}
                    measureListRaw={measureListRaw}
                    measureIds={this.props.measureIds}
                    onSubmit={this.downloadCSV.bind(this)}
                    type={type}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(reduxFalcor(CSVDownloader));
