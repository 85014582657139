import React, { Component } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { reduxFalcor } from 'utils/redux-falcor';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Element from 'components/light-admin/containers/Element';
import ElementBox from 'components/light-admin/containers/ElementBox';

import TmcInfo from 'components/information/TmcInfo';

import ATRIBreakdown from './views/ATRIBreakdown';
import PHEDBreakdown from './views/PHEDBreakdown';
import TTRBreakdown from './views/TTRBreakdown';
import FreeFlowBreakdown from './views/FreeFlowBreakdown';
import PlanningTimeIndexBreakdown from './views/PlanningTimeIndexBreakdown';
import TravelTimeIndexBreakdown from './views/TravelTimeIndexBreakdown';
import SummaryStatsBreakdown from './views/SummaryStatsBreakdown';

import NPMRDSAdditionalResources from 'components/information/NPMRDSAdditionalResources';

import MeasureSelector from 'components/selectors/MeasureSelector';
import DataSourceSelector from 'components/selectors/DataSourceSelector';
import YearSelector from 'components/selectors/YearSelector';

import {
  getMeasure,
  getDataSource,
  getBinMinutes,
  getMetric,
  getYear,
  getAvailableYears,
  setMeasure,
  setDataSource,
  setBinMinutes,
  setYear,
  setMetric,
  setCalendarMetric,
  getCalendarMetric,
  toggleSummaryStatsVisibility,
  areSummaryStatsVisible,
  initializeStore
} from './store';

import { navigateToPm3View as navigateToPm3ViewActionCreator } from '../PM3';
import { navigateToGeoMeasureVisualizer as navigateToGeoMeasureVisualizerActionCreator } from '../GeoMeasureVisualizer/store';

import {
  getUrlQueryParams,
  clearUrlQueryString,
  createShareableUrl
} from 'store/routingUtils';

const DEFAULT_TMC = '120+05837';

const getMeasureBreakdown = props => {
  switch (props.activeMeasure) {
    case 'atri':
      return (
        <ElementBox>
          <h5>ATRI Total Congestion Value</h5>
          <ATRIBreakdown {...props} />
        </ElementBox>
      );
    case 'freeflow':
      return (
        <ElementBox>
          <h5>FreeFlow Speed Calculation Breakdown</h5>
          <FreeFlowBreakdown {...props} />
        </ElementBox>
      );
    case 'phed':
      return (
        <ElementBox>
          <h5>PHED Measure Calculation Breakdown</h5>
          <PHEDBreakdown {...props} />
        </ElementBox>
      );
    case 'ted':
      return (
        <ElementBox>
          <h5>TED Measure Calculation Breakdown</h5>
          <PHEDBreakdown {...props} />
        </ElementBox>
      );
    case 'lottr':
      return (
        <ElementBox>
          <h5>LOTTR Measure Calculation Breakdown</h5>
          <TTRBreakdown {...props} />
        </ElementBox>
      );
    case 'tttr':
      return (
        <ElementBox>
          <h5>TTTR Measure Calculation Breakdown</h5>
          <TTRBreakdown {...props} />
        </ElementBox>
      );
    case 'pti':
      return (
        <ElementBox>
          <h5>Planning Time Index Calculation Breakdown</h5>
          <PlanningTimeIndexBreakdown {...props} />
        </ElementBox>
      );
    case 'tti':
      return (
        <ElementBox>
          <h5>Travel Time Index Calculation Breakdown</h5>
          <TravelTimeIndexBreakdown {...props} />
        </ElementBox>
      );
    default:
      return (
        <ElementBox>
          <h6>Measure Breakdown Not Yet Implemented.</h6>;
        </ElementBox>
      );
  }
};

const handleRouting = props => {
  const {
    location,
    match: {
      params: { tmc: routeParamTmc }
    }
  } = props;

  const urlQueryParams = getUrlQueryParams(location);

  const tmc =
    routeParamTmc || (urlQueryParams && urlQueryParams.tmc) || DEFAULT_TMC;

  if (urlQueryParams && Object.keys(urlQueryParams).length) {
    props.initializeStore(urlQueryParams).then(() => {
      if (tmc !== routeParamTmc) {
        return props.reactRouterReplace(`/tmc/${tmc}`);
      } else {
        return props.clearUrlQueryString();
      }
    });
  } else if (tmc !== routeParamTmc) {
    return props.reactRouterReplace(`/tmc/${tmc}`);
  }
};

class MeasureVisualizer extends Component {
  fetchFalcorDeps() {
    const { activeMeasure } = this.props;

    return this.props.falcor.get(['pm3', 'measureSpec', activeMeasure]);
  }

  componentDidMount() {
    handleRouting(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    handleRouting(nextProps);
  }

  render() {
    const {
      availableYears,
      tmc,
      metric,
      binMinutes,
      activeMeasure,
      measure,
      year,
      dataSource,
      summaryStatsAreVisible,
      initializing,
      shareableUrl,
      navigateToPm3View,
      navigateToGeoMeasureVisualizer
    } = this.props;

    if (initializing) {
      return null;
    }

    return tmc ? (
      <div>
        <Element>
          <div className="row">
            <div className="col-8">
              <ElementBox>
                <h5>TMC Information</h5>
                <TmcInfo
                  year={year}
                  tmc={tmc}
                  onStateClick={geoInfo =>
                    navigateToPm3View({
                      stateCode: geoInfo && geoInfo.geoid,
                      year
                    })
                  }
                  onCountyClick={geoInfo =>
                    navigateToGeoMeasureVisualizer({
                      geolevel: geoInfo && geoInfo.geolevel,
                      geoid: geoInfo && geoInfo.geoid,
                      measure,
                      year,
                      focusedTmc: tmc
                    })
                  }
                  onMpoClick={geoInfo =>
                    navigateToGeoMeasureVisualizer({
                      geolevel: geoInfo && geoInfo.geolevel,
                      geoid: geoInfo && geoInfo.geoid,
                      measure,
                      year,
                      focusedTmc: tmc
                    })
                  }
                  onUAClick={geoInfo =>
                    navigateToGeoMeasureVisualizer({
                      geolevel: geoInfo && geoInfo.geolevel,
                      geoid: geoInfo && geoInfo.geoid,
                      measure,
                      year,
                      focusedTmc: tmc
                    })
                  }
                />
              </ElementBox>
              <Element>
                {summaryStatsAreVisible ? (
                  <ElementBox>
                    <h5>Data Summary Statistics Breakdown</h5>
                    <SummaryStatsBreakdown {...this.props} />
                  </ElementBox>
                ) : null}
              </Element>
              <Element>{getMeasureBreakdown(this.props)}</Element>
              <ElementBox>
                <h5 style={{ marginTop: 50 }}>Additional Resources</h5>
                <NPMRDSAdditionalResources />
              </ElementBox>
            </div>

            <div className="col-4">
              <div
                id="measure_visualizer_controls"
                style={{ position: 'fixed' }}
              >
                <h4 className="element-header">Measure Visualizer</h4>
                <h5>Metric</h5>
                <div onChange={e => this.props.setMetric(e.target.value)}>
                  <input
                    type="radio"
                    value="SPEED"
                    defaultChecked={metric === 'SPEED'}
                    name="metric"
                  />
                  Speed
                  {'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                  <input
                    type="radio"
                    value="TRAVEL_TIME"
                    defaultChecked={metric === 'TRAVEL_TIME'}
                    name="metric"
                  />
                  Travel Time
                </div>
                <div>
                  <br />
                  <h5>Time Bin Size</h5>
                  <div onChange={e => this.props.setBinMinutes(e.target.value)}>
                    <input
                      type="radio"
                      value={5}
                      defaultChecked={binMinutes === 5}
                      name="binMinutes"
                    />
                    5 min
                    {'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                    <input
                      type="radio"
                      value={15}
                      defaultChecked={binMinutes === 15}
                      name="binMinutes"
                    />
                    15 min
                    {'\u00A0'}
                    {'\u00A0'}
                    {'\u00A0'}
                    <input
                      type="radio"
                      value={60}
                      defaultChecked={binMinutes === 60}
                      name="binMinutes"
                    />
                    1 hour
                  </div>
                </div>
                <div>
                  <br />
                  <h5>Measure</h5>
                  <MeasureSelector
                    {...{
                      selectedMeasure: activeMeasure,
                      setMeasure: this.props.setMeasure,
                      autoApply: true,
                      popoutMeasureInfoBox: true,
                      excludeFullNameInInfoBox: true,
                      excludePeakBreakdowns: false
                    }}
                  />
                </div>
                <div>
                  <br />
                  <h5>Year</h5>
                  <YearSelector
                    year={year}
                    years={availableYears}
                    onChange={this.props.setYear}
                  />
                </div>
                <div>
                  <br />
                  <h5>Travel Time Data Source</h5>
                  <DataSourceSelector
                    dataSource={dataSource}
                    onChange={this.props.setDataSource}
                    autoApply={true}
                  />
                </div>
                <br />
                <div>
                  <span style={{ fontWeight: 500, fontSize: '1.5em' }}>
                    Show Summary Statistics{' '}
                  </span>
                  {'\u00A0'}
                  {'\u00A0'}
                  {'\u00A0'}
                  <input
                    type="checkbox"
                    checked={summaryStatsAreVisible}
                    name="summaryStatsAreVisible"
                    onChange={() => {
                      this.props.toggleSummaryStatsVisibility();
                    }}
                  />{' '}
                </div>
                <div>
                  <br />
                  <h5>Shareable Link</h5>
                  <CopyToClipboard text={shareableUrl}>
                    <button>Copy to clipboard</button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </Element>
      </div>
    ) : null;
  }
}

const mapDispatchToProps = {
  setMetric,
  setMeasure,
  setYear,
  setDataSource,
  setBinMinutes,
  setCalendarMetric,
  toggleSummaryStatsVisibility,
  initializeStore,
  clearUrlQueryString,
  navigateToPm3View: navigateToPm3ViewActionCreator,
  navigateToGeoMeasureVisualizer: navigateToGeoMeasureVisualizerActionCreator,
  reactRouterReplace: replace
};

const mapStateToProps = (state, ownProps) => {
  const {
    location,
    match: {
      params: { tmc }
    }
  } = ownProps;

  if (location.search) {
    return { initializing: true };
  }
  const activeMeasure = getMeasure(state);

  const props = {
    tmc,
    metric: getMetric(state),
    year: getYear(state),
    availableYears: getAvailableYears(state),
    binMinutes: getBinMinutes(state),
    activeMeasure,
    measure: activeMeasure,
    dataSource: getDataSource(state),
    calendarMetric: getCalendarMetric(state),
    summaryStatsAreVisible: areSummaryStatsVisible(state)
  };

  props.shareableUrl = createShareableUrl(props);

  return props;
};

const component = connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxFalcor(MeasureVisualizer));

export default [
  {
    icon: 'os-icon-bar-chart-stats-up',
    path: '/tmc',
    exact: true,
    mainNav: false,
    menuSettings: {
      image: 'none',
      scheme: 'color-scheme-dark',
      position: 'menu-position-side',
      layout: 'menu-layout-compact',
      style: 'color-style-default'
    },
    name: 'Measure Visualizer',
    auth: true,
    component
  },
  {
    icon: 'os-icon-bar-chart-stats-up',
    path: '/tmc/:tmc',
    exact: true,
    mainNav: false,
    menuSettings: {
      image: 'none',
      scheme: 'color-scheme-dark',
      position: 'menu-position-side',
      layout: 'menu-layout-compact',
      style: 'color-style-default'
    },
    name: 'Measure Visualizer',
    auth: true,
    component
  }
];
