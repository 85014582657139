import React from "react"
import { Link } from 'react-router-dom'

import LandingNav from 'pages/Landing/components/LandingNav'

export default ({ password, verify, update, canSubmit, handleSubmit }) =>
  <div style={ {
      height: '100vh',
      overflow: 'hidden',
      backgroundImage:
        'linear-gradient(to bottom right,rgb(110, 176, 194) 0px, rgb(63, 174, 205) 39%, rgb(52, 149, 176) 100%)'
    } }>
    <LandingNav />
    <div style={ {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      } }>
      <div className="auth-box-w"
        style={ {
          width: '33%',
          minWidth: '350px',
          maxWidth: '650px'
        } }>
        <h4 className="auth-header" style={ { paddingTop: 20 } }>
          NPMRDS Set Password
        </h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input required autoFocus
              id="password"
              type="password"
              value={ password }
              onChange={ e => update({ password: e.target.value }) }
              className="login-form-control"
              placeholder="Enter your password"/>
            <div className="pre-icon os-icon os-icon-fingerprint" />
          </div>
          <div className="form-group">
            <label htmlFor='verify'>Verify Password</label>
            <input
              value={verify}
              onChange={e => update({ verify: e.target.value })}
              id="verify"
              type="password"
              className="login-form-control"
              placeholder="Verify your password"
            />
            <div className="pre-icon os-icon os-icon-fingerprint" />
          </div>
          <div className="buttons-w">
            <button className="btn btn-primary btn-lg btn-block" disabled={!canSubmit}>Set Password</button>
          </div>
        </form>
        <div style={ { padding: 15, float: 'right' } }>
          <Link to={ '/auth/login' }>Login</Link>
        </div>
      </div>
    </div>
  </div>
