import React from 'react';

import LineChart from 'components/charts/line/simple';

import { precisionRound } from 'utils/calculators/MathUtils';
import BinnedTimeUtils from 'utils/calculators/BinnedTimeUtils';

import { daysOfWeek } from 'utils/constants';

export const formatTrafficDistProfiles = ({
  tmcTrafficDistributionProfile
}) => {
  const { weekdayProfile, weekendProfile } =
    tmcTrafficDistributionProfile || {};
  return weekdayProfile && weekendProfile
    ? [
        {
          id: 'Weekday Traffic Dist Percentage',
          data: weekdayProfile.map((pct, i) => ({
            x: BinnedTimeUtils.binNumToTime(60, i)
              .replace(/^0/, '')
              .replace(/:00/, ''),
            y: precisionRound(pct * 100, 2)
          })),
          color: 'green'
        },
        {
          id: 'Weekend Traffic Dist Percentage',
          data: weekendProfile.map((pct, i) => ({
            x: BinnedTimeUtils.binNumToTime(60, i)
              .replace(/^0/, '')
              .replace(/:00/, ''),
            y: precisionRound(pct * 100, 2)
          })),
          color: 'yellow'
        }
      ]
    : null;
};

export const formatDOWAdjFactors = ({ trafficDistributionDOWAdjFactors }) =>
  trafficDistributionDOWAdjFactors
    ? [
        {
          id: 'Weekday Traffic Dist Percentage',
          data: trafficDistributionDOWAdjFactors.map((pct, i) => ({
            x: daysOfWeek[i],
            y: precisionRound(pct * 100, 2)
          })),
          color: 'green'
        }
      ]
    : null;

export const formatTrafficVolumesByDowByHour = ({
  tmcTrafficVolumesByDowByHour
}) =>
  tmcTrafficVolumesByDowByHour
    ? [
        {
          id: 'Vehicles',
          data: tmcTrafficVolumesByDowByHour.reduce((acc, dailyVols, i) => {
            for (let j = 0; j < dailyVols.length; ++j) {
              acc.push({
                x: j
                  ? `${daysOfWeek[i]} ${BinnedTimeUtils.binNumToTime(
                      60,
                      j
                    ).replace(/:00/, '')}`
                  : daysOfWeek[i],
                y: precisionRound(dailyVols[j], 3)
              });
            }
            return acc;
          }, []),
          color: 'orange'
        }
      ]
    : null;

// NOTE: Browser will remove whitespaces when rendering.
const description = (
  <a href="https://www.fhwa.dot.gov/tpm/guidance/hif18040.pdf">
    National Performance Measures for Congestion, Reliability, and Freight, and
    CMAQ Traffic Congestion General Guidance and Step-by-Step Metric Calculation
    Procedures
  </a>
);

const getTrafficDistCriteriaViz = ({
  tmcCongestionLevel,
  tmcDirectionality
}) => (
  <dl>
    <dt>congestion level</dt>
    <dd>{tmcCongestionLevel}</dd>
    <dt>directionality</dt>
    <dd>{tmcDirectionality}</dd>
  </dl>
);

const getTrafficDistViz = data => {
  const formattedData = formatTrafficDistProfiles(data);

  return formattedData ? (
    <LineChart
      data={formattedData}
      bottomLegend="Hour of Day"
      leftLegend="Percentage of Daily Traffic"
      animate={false}
    />
  ) : null;
};

const getDOWAdjFactViz = data => {
  const formattedData = formatDOWAdjFactors(data);

  return formattedData ? (
    <LineChart
      data={formattedData}
      bottomLegend={'Day of Week'}
      leftLegend={'Traffic Adjustment Factor'}
      animate={false}
    />
  ) : null;
};

const getTrafficVolViz = data => {
  const formattedData = formatTrafficVolumesByDowByHour(data);

  return formattedData ? (
    <LineChart
      data={formattedData}
      includedTicksFilter={t => !t.match(/\d/)}
      bottomLegend="Hours Throughout Week"
      leftLegend="Percentage of Avg Daily AADT"
      animate={false}
    />
  ) : null;
};

const TrafficDistributionProfile = ({ data }) => (
  <div>
    <p>{description}</p>

    {getTrafficDistCriteriaViz(data)}

    <div style={{ width: '100%', height: 300, color: '#333' }}>
      <h6>Intra-day Traffic Distributions</h6>
      {getTrafficDistViz(data)}
    </div>

    <div style={{ width: '100%', height: 300, color: '#333', marginTop: 50 }}>
      <h6>Day of Week Traffic Volume Adjustment Factors</h6>
      {getDOWAdjFactViz(data)}
    </div>

    <div style={{ width: '100%', height: 300, color: '#333', marginTop: 50 }}>
      <h5>Traffic Volumes Throughout Week</h5>
      {getTrafficVolViz(data)}
    </div>
  </div>
);

export default TrafficDistributionProfile;
