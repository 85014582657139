import get from 'lodash.get';

import {
  queryPM3MeasuresVersionForGeo,
  queryGeoAttributes,
  queryGeoLevelsForStateCodes,
  queryPM3MeasuresGeoLevelVersionsMetadata
} from 'store/falcorGraphQueryBuilders';

import GeoLevelPm3VersionsMetadataParser from 'utils/pm3/GeoLevelPm3VersionsMetadataParser';

import getNewPm3VersionId from '../utils/getNewPm3VersionId';

import { STATE } from '../constants';

// Default to the selected, fallback to the latest authoritative full year
const fetchStateLevel = props => {
  const { falcor, selectedPm3VersionId } = props;

  const pm3MeasuresGeoLevelVersionsMetadataFalcorPath = queryPM3MeasuresGeoLevelVersionsMetadata();

  return falcor
    .get(pm3MeasuresGeoLevelVersionsMetadataFalcorPath)
    .then(resp => {
      const pm3VersionsMetadataParser = new GeoLevelPm3VersionsMetadataParser(
        resp
      );

      const newPm3VersionId = getNewPm3VersionId(
        selectedPm3VersionId,
        pm3VersionsMetadataParser
      );

      const calculatedStates = pm3VersionsMetadataParser.getAvailableStateCodesForVersion(
        newPm3VersionId
      );

      const year = pm3VersionsMetadataParser.getYearForVersion(newPm3VersionId);

      const falcorQueries = [];

      for (let j = 0; j < calculatedStates.length; ++j) {
        const geoid = calculatedStates[j];

        falcorQueries.push(queryGeoAttributes(STATE, geoid, year));
        falcorQueries.push(
          queryPM3MeasuresVersionForGeo(STATE, geoid, newPm3VersionId)
        );
      }

      return falcor.get(...falcorQueries).then(() => ({ newPm3VersionId }));
    });
};

const fetchSubStateLevel = props => {
  const { falcor, geolevel, stateCode, selectedPm3VersionId } = props;

  const geoLevelsForStateFalcorPath = queryGeoLevelsForStateCodes(stateCode);
  const pm3MeasuresGeoLevelVersionsMetadataFalcorPath = queryPM3MeasuresGeoLevelVersionsMetadata();

  return falcor
    .get(
      geoLevelsForStateFalcorPath,
      pm3MeasuresGeoLevelVersionsMetadataFalcorPath
    )
    .then(resp => {
      const { json = {} } = resp;

      const pm3VersionsMetadataParser = new GeoLevelPm3VersionsMetadataParser(
        resp
      );

      const newPm3VersionId = getNewPm3VersionId(
        selectedPm3VersionId,
        pm3VersionsMetadataParser
      );

      const year = pm3VersionsMetadataParser.getYearForVersion(newPm3VersionId);

      const geoLevelsForState = get(json, geoLevelsForStateFalcorPath);

      if (Array.isArray(geoLevelsForState) && geoLevelsForState.length) {
        const falcorQueries = [];

        falcorQueries.push(queryGeoAttributes(STATE, stateCode, year));

        for (let j = 0; j < geoLevelsForState.length; ++j) {
          const { geolevel: stateGeoLevel, geoid } = geoLevelsForState[j];

          // Only fetch the geographies at the geolevel
          if (geolevel === stateGeoLevel) {
            falcorQueries.push(queryGeoAttributes(stateGeoLevel, geoid, year));
            falcorQueries.push(
              queryPM3MeasuresVersionForGeo(
                stateGeoLevel,
                geoid,
                newPm3VersionId
              )
            );
          }
        }

        return falcor.get(...falcorQueries).then(() => ({ newPm3VersionId }));
      }
    });
};

// Returns { selectedPm3VersionId }
//   where, if API returned sufficient data,
//   selectedPm3VersionId is guaranteed to have a value
export default (props, state) =>
  props.geolevel === STATE
    ? fetchStateLevel(props, state)
    : fetchSubStateLevel(props, state);
