import React from 'react';
import * as ss from 'simple-statistics';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';

import AvgsByBinLineChart from '../AvgsByBinLineChart';

import { precisionRound, numbersComparator } from 'utils/calculators/MathUtils';
import BinnedTimeUtils from 'utils/calculators/BinnedTimeUtils';

import rangeFilterMethod from 'utils/rangeFilterMethod';
import textSearch from 'utils/textSearch';

const columns = [
  {
    Header: 'Travel Time',
    id: 'avgTT',
    accessor: 'avgTT',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Speed',
    id: 'avgSpeed',
    accessor: 'avgSpeed',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Data Density',
    id: 'dataDensity',
    accessor: 'dataDensity',
    sortable: true,
    filterable: true,
    filterMethod: textSearch
  },
  { Header: 'Peak', accessor: 'peak', sortable: true, filterable: true },
  {
    Header: 'Travel Time Percentile',
    id: 'ttPercentile',
    accessor: 'ttPercentile',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Speed Percentile',
    id: 'speedPercentile',
    accessor: 'speedPercentile',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Travel Time Percentile (in peak)',
    id: 'ttPercentileInPeak',
    accessor: 'ttPercentileInPeak',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Speed Percentile (in peak)',
    id: 'speedPercentileInPeak',
    accessor: 'speedPercentileInPeak',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Day of Week',
    accessor: 'dow',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Bin Num',
    accessor: 'binNum',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  { Header: 'Year', accessor: 'year' },
  {
    Header: 'Month',
    accessor: 'month',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Date',
    accessor: 'date',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Hour',
    accessor: 'hour',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  },
  {
    Header: 'Minute',
    accessor: 'minute',
    sortable: true,
    filterable: true,
    filterMethod: rangeFilterMethod
  }
];

const getViz = (avgsByBin, tmc, metric, binMinutes) => {
  const metricName = metric === 'SPEED' ? 'Speeds' : 'Travel Times';
  if (!(Array.isArray(avgsByBin) && avgsByBin.length)) {
    return null;
  }

  const firstBin = avgsByBin[0];
  const lastBin = avgsByBin[avgsByBin.length - 1];

  const startDate = BinnedTimeUtils.getDateString(
    firstBin.year,
    firstBin.month,
    firstBin.date
  );
  const endDate = BinnedTimeUtils.getDateString(
    lastBin.year,
    lastBin.month,
    lastBin.date
  );

  const peaks = [
    ...avgsByBin.reduce((acc, { peak }) => acc.add(peak), new Set([]))
  ].filter(p => p);

  const sortedTTs = avgsByBin.map(({ avgTT }) => avgTT).sort(numbersComparator);
  const sortedTTsByPeak = peaks.length
    ? avgsByBin.reduce((acc, { avgTT, peak }) => {
        if (!peak) {
          return acc;
        }
        if (!acc[peak]) {
          acc[peak] = [];
        }
        acc[peak].push(avgTT);
        return acc;
      }, {})
    : null;

  if (sortedTTsByPeak) {
    Object.values(sortedTTsByPeak).forEach(tts => tts.sort(numbersComparator));
  }

  const sortedSpeeds = avgsByBin
    .map(({ avgSpeed }) => avgSpeed)
    .sort(numbersComparator);

  const sortedSpeedsByPeak = peaks.length
    ? avgsByBin.reduce((acc, { avgSpeed, peak }) => {
        if (!peak) {
          return acc;
        }
        if (!acc[peak]) {
          acc[peak] = [];
        }
        acc[peak].push(avgSpeed);
        return acc;
      }, {})
    : null;

  if (sortedSpeedsByPeak) {
    Object.values(sortedSpeedsByPeak).forEach(speeds =>
      speeds.sort(numbersComparator)
    );
  }

  const rounded = avgsByBin.map(row =>
    Object.keys(row).reduce((acc, col) => {
      const r = Object.assign(acc, {
        [col]:
          col === 'avgTT' || col === 'avgSpeed'
            ? precisionRound(row[col], 2)
            : row[col]
      });

      r.ttPercentile = precisionRound(
        ss.quantileRankSorted(sortedTTs, r.avgTT) * 100,
        2
      );

      r.speedPercentile = precisionRound(
        ss.quantileRankSorted(sortedSpeeds, r.avgSpeed) * 100,
        2
      );

      if (sortedTTsByPeak) {
        r.ttPercentileInPeak =
          r.peak &&
          precisionRound(
            ss.quantileRankSorted(sortedTTsByPeak[r.peak], r.avgTT) * 100,
            2
          );

        r.speedPercentileInPeak =
          r.peak &&
          precisionRound(
            ss.quantileRankSorted(sortedSpeedsByPeak[r.peak], r.avgSpeed) * 100,
            2
          );
      }

      return r;
    }, {})
  );

  //https://github.com/react-tools/react-table/wiki/FAQ#how-do-i-get-at-the-internal-data-so-i-can-do-things-like-exporting-to-a-file
  const that = { reactTable: null };

  return avgsByBin ? (
    <div>
      <div
        style={{
          display: 'inline-block',
          border: '1px solid ',
          borderRadius: 3,
          padding: '2 1',
          backgroundColor: '#F2F2F2',
          cursor: 'pointer'
        }}
        onClick={() => {
          const fileName = `${tmc}.summary-stats.csv`;
          const tableData = that.reactTable.getResolvedState().sortedData;
          const cols = Object.keys(tableData[0]).filter(c => !c.match(/^_/));

          const csvContent = `${cols.join(',')}\n${tableData
            .map(row => cols.map(col => row[col]).join(','))
            .join('\n')}`;

          // https://stackoverflow.com/a/24922761
          var blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, fileName);
          } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', fileName);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }}
      >
        Download as CSV
      </div>
      <ReactTable
        ref={r => (that.reactTable = r)}
        defaultPageSize={10}
        data={rounded}
        columns={columns}
      >
        {(state, makeTable, instance) => {
          // https://react-table.js.org/#functional-rendering
          const sortedData = state.sortedData;
          return (
            <div style={{ width: '100%' }}>
              {makeTable()}
              <h5>Average {metricName} for Year</h5>{' '}
              <AvgsByBinLineChart
                style={{ width: '100%' }}
                data={{
                  avgsByBin: sortedData,
                  metric,
                  binMinutes,
                  startDate,
                  endDate
                }}
              />
            </div>
          );
        }}
      </ReactTable>
    </div>
  ) : null;
};

const AvgsByBinTable = ({ data }) => {
  const { tmc, avgsByBin, metric, binMinutes } = data;

  return (
    <div style={{ width: '100%', color: '#333' }}>
      <h6>NPMRDS Data ({binMinutes} minute bins)</h6>
      {getViz(avgsByBin, tmc, metric, binMinutes)}
    </div>
  );
};

export default AvgsByBinTable;
