import uniq from 'lodash.uniq';
import deepEqual from 'deep-equal';

import {
  ACTIVE_IDS_UPDATED,
} from "./constants";

const initialState = {
  activeYear: 2018,
  activeGtfsTransitAgency: 'CDTA',
  activeConflationMapIds: [],
  activeGtfsRoute: null
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ACTIVE_IDS_UPDATED]: (state = initialState, action) => {
    const {
      payload
    } = action;

    const {activeConflationMapIds} = state
    const newActiveIds = uniq(payload).sort((a, b) => +a - +b)

    return deepEqual(activeConflationMapIds, newActiveIds)
      ? state
      : {...state, activeConflationMapIds: newActiveIds};
  },
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
