import {tmcAttributesMap} from 'utils/constants';

export const queryTmcAttributes = (tmc, attributes, year) => [
  'tmc',
  tmc,
  'meta',
  year,
  Array.isArray(attributes)
    ? attributes.map(attr => tmcAttributesMap[attr] || attr)
    : tmcAttributesMap[attributes] || attributes
];

export const queryNPMRDSDataForTmc = (tmc, year, dataSource = 'ALL') => [
  'tmc',
  tmc,
  'year',
  year,
  'npmrds',
  dataSource
];

export const queryTrafficDistributionsProfiles = () => [
  'trafficDistributions',
  'profiles'
];

export const queryTrafficDistributionsDOWAdjFactors = () => [
  'trafficDistributions',
  'dowAdjFactors'
];

export const queryMeasureRules = measure => ['pm3', 'measureSpec', measure];

export const queryGeoLevelsForStateCodes = stateCodes => [
  'geo',
  stateCodes,
  'geoLevels'
];

export const queryGeoAttributes = (geoLevel, geoId, year) => {
  const syntheticGeoKey = `${geoLevel}|${geoId}`;
  return ['geoAttributes', syntheticGeoKey, year];
};

export const queryNumRoutesForUser = () => ['routes', 'length'];

export const queryBasicRoutesInfo = numRoutes => [
  'routes',
  'byIndex',
  {from: 0, to: numRoutes - 1},
  ['id', 'name', 'type', 'updatedAt']
];

export const queryNumReportsForUser = () => ['reports', 'length'];

export const queryBasicReportsInfo = numRoutes => [
  'reports',
  'byIndex',
  {from: 0, to: numRoutes - 1},
  ['id', 'title', 'type', 'description', 'created_at', 'updated_at']
];

export const queryPM3TmcLevelMeasureHierarchy = () => [
  'pm3',
  'versionedCalculations',
  'tmcLevelMeasureHierarchy'
];

export const queryPM3MeasuresTmcLevelVersionsMetadata = () => [
  'pm3',
  'versionedCalculations',
  'tmcLevelVersionsMetadata'
];

export const queryPM3MeasuresGeoLevelVersionsMetadata = () => [
  'pm3',
  'versionedCalculations',
  'geoLevelVersionsMetadata'
];

export const queryPM3MeasureVersionsForTmcs = (
  versionIds,
  tmcIds,
  measures
) => [
    'pm3',
    'versionedCalculations',
    'version',
    versionIds,
    'tmc',
    tmcIds,
    measures
  ];

export const queryTmcsInGeographyForPm3Versions = (
  versionIds,
  geolevel,
  geoid
) =>
  versionIds && geolevel && geoid
    ? [
      'pm3',
      'versionedTmcMetadata',
      'version',
      versionIds,
      'tmcsInGeography',
      `${geolevel.toUpperCase()}|${geoid}`
    ]
    : null;

export const queryVersionedTmcMetadata = (versionIds, tmcs, metadataCols) =>
  versionIds && tmcs && metadataCols
    ? [
      'pm3',
      'versionedTmcMetadata',
      'version',
      versionIds,
      'tmc',
      tmcs,
      metadataCols
    ]
    : null;

export const queryPM3MeasuresVersionForGeo = (
  geoLevel,
  geoId,
  pm3VersionId
) => {
  const syntheticGeoKey = `${geoLevel}|${geoId}`.toUpperCase();
  return [
    'pm3',
    'versionedCalculations',
    'version',
    pm3VersionId,
    'geolevel',
    syntheticGeoKey
  ];
};

export const queryVersionedGeoMetadataAggregateRoadStats = (
  pm3VersionIds,
  geolevel,
  geoid
) => {
  const syntheticGeoKey = `${geolevel}|${geoid}`.toUpperCase();

  return [
    'pm3',
    'versionedGeoMetadata',
    'version',
    pm3VersionIds,
    'aggregateRoadStats',
    syntheticGeoKey
  ];
};

export const queryGtfsTransitAgencyIds = () => [
  'gtfsConflation', 'transitAgencies', 'agencyIds'
]

export const queryGtfsConflationYears = () => [
  'gtfsConflation', 'transitAgencies', 'years'
]

export const queryConflationMapIdsForGtfsTransitAgency = (gtfsAgency, year) => [
  'gtfsConflation',
  'transitAgencies',
  'conflationMapIds',
  gtfsAgency,
  year
]

export const queryGtfsConflationMetadata = (ids, years, columns) => [
  'conflation',
  ids,
  'meta',
  years,
  columns
]
