import React from 'react';

import { precisionRound } from 'utils/calculators/MathUtils';

const PHEDTotalExcessiveDelay = ({ data: { totalExcessiveDelay } }) =>
  totalExcessiveDelay ? (
    <p>{`${precisionRound(totalExcessiveDelay, 2)} person hours`} </p>
  ) : null;

export default PHEDTotalExcessiveDelay;
