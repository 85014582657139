import React from "react"
//import store from "store"
import {falcorGraph,falcorChunkerNice} from "store/falcorGraph"
import get from 'lodash.get'
import flatten from 'lodash.flatten'
import MapLayer from "components/AvlMap/MapLayer"

import * as d3scale from "d3-scale"


import COLOR_RANGES from "constants/color-ranges"
import MeasureInfoBox from 'components/information/MeasureInfoBox'
import CsvDownloader from '../../components/CsvDownloader'
import ShpDownloader from '../../components/ShpDownloader'
import TopMeasures from '../../components/TopMeasures'

import DataDownloader from "pages/auth/conflation/components/DataDownloader"

import {geoFilter, mapBottlenecks, networkFilter, yearToLayer} from './npmrds_utils'

import {pm3Layers, sources} from './layer-styles'
import clone from 'clone';

// import { update } from "utils/redux-falcor/components/duck"

let layers = clone(pm3Layers).map(layer => {
    layer.id += '_pm3';
    return layer
});

console.log("LAYERS:", layers)

const tmcMetaVars = [
  "tmctype" ,
  "roadnumber" ,
  "roadname" ,
  "firstname" ,
  "tmclinear" ,
  "country" ,
  "state" ,
  "county" ,
  "zip" ,
  "direction" ,
  "startlat" ,
  "endlat" ,
  "startlong" ,
  "endlong" ,
  "miles" ,
  "frc" ,
  "border_set" ,
  "note" ,
  "f_system" ,
  "urban_code" ,
  "faciltype" ,
  "structype" ,
  "thrulanes" ,
  "route_numb" ,
  "route_sign" ,
  "route_qual" ,
  "altrtename" ,
  "aadt" ,
  "aadt_singl" ,
  "aadt_combi" ,
  "nhs" ,
  "nhs_pct" ,
  "strhnt_typ" ,
  "strhnt_pct" ,
  "truck"
];


// const QUANTILE_RANGE = COLOR_RANGES[5].reduce((a, c) => c.name === "RdYlBu" ? c.colors : a).reverse()

const DEFAULT_COLORS = {
    lottr: COLOR_RANGES[5].reduce((a, c) => c.name === "RdYlBu" ? c.colors : a).slice().reverse(),
    tttr: COLOR_RANGES[5].reduce((a, c) => c.name === "Purples" ? c.colors : a).slice(),
    default: COLOR_RANGES[5].reduce((a, c) => c.name === "Reds" ? c.colors : a).slice(),
    compare: COLOR_RANGES[8].reduce((a, c) => c.name === "PiYG" ? c.colors : a).slice().reverse()

};
const updateLegend = (baseKey, layer) => {
    switch (baseKey) {
        case 'lottr':
            layer.legend.type = 'threshold';
            layer.legend.domain = [1.1, 1.25, 1.5, 1.75, 2];
            layer.legend.range = DEFAULT_COLORS[baseKey];
            layer.legend.format = d => d.toLocaleString(undefined, {maximumFractionDigits: 2});
            break;
        case 'tttr':
            layer.legend.type = 'threshold';
            layer.legend.domain = [1.1, 1.25, 1.5, 1.75, 2];
            layer.legend.range = DEFAULT_COLORS[baseKey];
            layer.legend.format = d => d.toLocaleString(undefined, {maximumFractionDigits: 2});
            break;
        case 'compare':
            layer.legend.type = 'threshold';
            layer.legend.domain = [-25, -12, -5, 0, 5, 12, 25];
            layer.legend.range = DEFAULT_COLORS[baseKey];
            layer.legend.format = d => d.toLocaleString(undefined, {maximumFractionDigits: 0});
            break;
        default:
            layer.legend.type = 'quantile';
            layer.legend.range = DEFAULT_COLORS['default'];
            layer.legend.format = d => d.toLocaleString(undefined, {maximumFractionDigits: 0});
            break;
    }
};
// import ParcelLayerModal from "./modals/ParcelLayerModal"
// -----------------------------
//------------------------------

//------------------------------------
//-----------------------------------
class PM3Layer extends MapLayer {

    onAdd(map) {
        super.onAdd(map);

        // console.log("<PM3Layer.onAdd> USER:", this.user);
        let calculatedStates;

        return (
            falcorGraph
                .get(['pm3', 'calculatedStates'], ['pm3', 'measureIds'])
                .then(({json: {pm3}}) => {
                    ({calculatedStates} = pm3);
                    const {measureIds} = pm3;

                    this.allMeasures = [...measureIds];

                    return falcorGraph.get(
                        ['geo', calculatedStates, 'geoLevels'],
                        [
                            'pm3',
                            'measureInfo',
                            measureIds,
                            ['fullname', 'definition', 'equation', 'source', 'version']
                        ]
                    )
                    .then(({json: {pm3: {measureInfo}, geo}}) => {
                        this.filters.measure.domain = measureIds
                            .map(measureId => ({
                                name: measureInfo[measureId].fullname,
                                value: measureId,
                                version: measureInfo[measureId].version
                            })).filter(({ version }) => version !== 2);
console.log("this.filters.measure.domain",this.filters.measure.domain)

                        this.filters.measure.meta = measureInfo;

                        const geographies = flatten(calculatedStates.map(s => geo[s].geoLevels));

                        this.filters.geography.domain = geographies.map(geo => ({
                            name: `${geo.geoname.toUpperCase()} ${geo.geolevel}`,
                            geolevel: geo.geolevel,
                            value: geo.geoid,
                            bounding_box: geo.bounding_box
                        }));
                    })
                    // .then(() => this.component.forceUpdate())
                    //.then(() => this.setFilterFromUserMetaOrLocalStorage())
                })
        );
    }


    setFilterFromUserMetaOrLocalStorage() {
        let geographies = [];
        if (window.localStorage && Boolean(window.localStorage.getItem("macro-view-geographies"))) {
            geographies = JSON.parse(window.localStorage.getItem("macro-view-geographies"));
        }
        if (!geographies.length) {
            const meta = get(this, 'user.meta', []);
            geographies = meta.reduce((a, c) => [...a, get(c, "meta.geography", null)], [])
                .filter(m => Boolean(m))
                .reduce((a, c) => [...a, ...Object.keys(c).reduce((a, k) => [...a, ...c[k]], [])], []);
        }
        if (geographies.length) {
            this.doAction(["updateFilter", "geography", geographies])
        }
    }

    onStyleChange(map) {
        super.onStyleChange(map);

        this.doAction(["fetchLayerData"]);
    }

    onFilterFetch() {
        return this.fetchData()
    }

    fetchData() {
        let selection = this.selection.slice();
        if (!selection || selection.length === 0) {
            return Promise.resolve({})
        }
        let years = [+this.filters.year.value];
        let compareYear = isNaN(+this.filters.compare.value) ? null : +this.filters.compare.value;
        if (compareYear) {
            years.push(compareYear)
        }
        const geoids = this.filters.geography.value,
          filtered = this.filters.geography.domain
            .filter(({ value }) => geoids.includes(value));

        return falcorChunkerNice(
          ['tmc', selection, 'meta', years, tmcMetaVars],
          ['pm3', 'measuresByTmc', selection, years,
            [this.filters.measure.value, "pct_bins_reporting_am", "pct_bins_reporting_pm"]
          ],
          ...this.fetchRequestsForGeography(),
          { callback: (curr, total) => {
              this.LoadingIndicator({ progress: curr / total });
            }
          }
        )

        // let requests = [];
        // let chunkSize = 100;
        // for (let i = 0; i < selection.length; i += chunkSize) {
        //     requests.push(selection.slice(i, i + chunkSize))
        // }
        // return requests
        //     .reduce((a, c) => a.then(() => falcorGraph.get(
        //         ['tmc', c, 'meta', years, tmcMetaVars],
        //         // figure out a measure value to pass
        //         //pct_bins_reporting_am
        //         //pct_bins_reporting_pm
        //         ['pm3', 'measuresByTmc', c, years, [this.filters.measure.value, "pct_bins_reporting_am", "pct_bins_reporting_pm"]])),
        //         Promise.resolve())
        //     .then(() => {
        //       return falcorGraph.get(...this.fetchRequestsForGeography())
        //     })

    }

  	toggleVisibility(map) {
  		this._isVisible = !this._isVisible;
  		this.layers.forEach(layer => {
        if (this._isVisible) {
          const year = this.filters.year.value;
          map.setLayoutProperty(yearToLayer[year].id, 'visibility', 'visible');
        }
        else {
          map.setLayoutProperty(layer.id, 'visibility', "none");
        }
  		})
  	}
    fetchRequestsForGeography() {
      const year = +this.filters.year.value,
        geoids = this.filters.geography.value,
        filtered = this.filters.geography.domain
          .filter(({ value }) => geoids.includes(value));

      return filtered.reduce((a, c) => {
        a.push(['tmc', 'identification',
          'type', c.geolevel, 'geoid', c.value, 'year', year]
        )
        a.push(["geo", c.geolevel.toLowerCase(), c.value, "geometry"]);
        return a;
      }, [])
    }
    getSelectionForGeography() {
      const year = +this.filters.year.value,
        geoids = this.filters.geography.value,
        filtered = this.filters.geography.domain
          .filter(({ value }) => geoids.includes(value));

      return [...filtered.reduce((a, c) => {
        get(falcorGraph.getCache(),
          ['tmc', 'identification',
            'type', c.geolevel,
            'geoid', c.value,
            'year', year, 'value']
        , []).forEach(d => a.add(d))
        return a;
      }, new Set())]
    }
    getGeomRequest(selection) {
      return ['tmc', selection, 'year', this.filters.year.value, 'geometries'];
    }
    receiveData(map, data) {
        data = falcorGraph.getCache();
        // console.log('receiveData', data)
        if (!data.tmc || this.selection.length === 0) return;
        let selection = this.selection;

        let year = +this.filters.year.value;
        let compareYear = isNaN(+this.filters.compare.value) ? null : +this.filters.compare.value;
        let measure = this.filters.measure.value;
        let measureName = this.filters.measure.domain.filter(d => d.value === measure)[0].name;


        const getMeasure = (year, compareYear, data, tmc) => {
            if (!compareYear) {
                return get(data.pm3.measuresByTmc, `${tmc}.${year}.${measure}`, NaN)
            }

            return ((get(data.pm3.measuresByTmc, `${tmc}.${year}.${measure}`, 0) -
                get(data.pm3.measuresByTmc, `${tmc}.${compareYear}.${measure}`, 0)) /
                get(data.pm3.measuresByTmc, `${tmc}.${compareYear}.${measure}`, 0)) * 100
        };
        let checkDuplicate = [];
        let lowestMin = null;
        let highestMin = null;
        let tmcData = selection
            .filter(f => {
                if (!checkDuplicate.includes(f)) {
                    checkDuplicate.push(f);
                    return true;
                }
                return false
            })
            .map(tmc => {
                let valueAm = data.pm3.measuresByTmc[tmc][year]["pct_bins_reporting_am"];
                let valuePm = data.pm3.measuresByTmc[tmc][year]["pct_bins_reporting_pm"];
                let min = valueAm < valuePm ? valueAm : valuePm;
                if (min && (lowestMin === null || lowestMin > min)) lowestMin = min;
                if (min && (highestMin === null || highestMin < min)) highestMin = min;
                return {tmc: tmc, value: getMeasure(year, compareYear, data, tmc), min: min}

            })
            .sort((a, b) => +b.value - +a.value);

        // console.log('tmcData', tmcData)
        this.tmcData = tmcData;
        this.minRange = {'lowestMin': lowestMin, 'highestMin': highestMin};
        let allValues = tmcData.map(d => d.value);

        this.legend.active = true;
        this.legend.title = measureName + ' ' + year;

        let type = this.legend.type,
            range = this.legend.range,
            scale = null,
            min = Math.min(...allValues),
            max = Math.max(...allValues);

        switch (type) {
            case "quantile":
                scale = d3scale.scaleQuantile()
                    .domain(allValues)
                    .range(range);
                this.legend.domain = allValues;
                break;
            case "quantize":
                scale = d3scale.scaleQuantize()
                    .domain([min, max])
                    .range(range);
                this.legend.domain = [min, max];
                break;
            case "threshold":
                scale = d3scale.scaleThreshold()
                    .domain(this.legend.domain)
                    .range(range);
                break;
            case "linear":
                scale = d3scale.scaleLinear()
                    .domain(this.legend.domain)
                    .range(range);
                break;
        }

        if (this.filters.network.value === 'Interstate') {
            selection = selection.filter(d => data.tmc[d].meta[year].f_system == 1)
        }
        if (this.filters.network.value === 'Non-Interstate') {
            selection = selection.filter(d => data.tmc[d].meta[year].f_system != 1)
        }
        if (this.filters.network.value === 'NHS') {
            selection = selection.filter(d => data.tmc[d].meta[year].nhs_pct == 100)
        }
        if (this.filters.network.value === 'Designated Freight') {
            selection = selection.filter(d => data.tmc[d].meta[year].frc == 1)
        }

        let colors = selection.reduce((out, tmc) => {
            //console.log(getMeasure(year,compareYear,data, tmc), !isNaN(getMeasure(year,compareYear,data, tmc)) )
            out[tmc] = getMeasure(year, compareYear, data, tmc) ?
                scale(getMeasure(year, compareYear, data, tmc)) : '#333';
            return out;
        }, {});

        if (this.filters.bottleneck.value) {
            let topX = tmcData
                .filter(d => !isNaN(d.value) && d.value < Infinity)
                .filter((d, i) => d.min > this.filters.dataQuality_bottlenecks.value)
                .filter((d, i) => i < this.filters.num_bottlenecks.value)
                .map((d, i) => {
                    d.color = colors[d.tmc];
                    d.rank = i + 1;
                    return d
                });
            mapBottlenecks(map, year, topX, [min, max])
        }


        // console.log(Object.values(colors).filter(d => !d))

        let paintYear = year;
        // console.log('tmcData colors', colors,yearToLayer[paintYear].id)

        map.setPaintProperty(
            yearToLayer[paintYear].id,
            'line-color',
            ["get", ["to-string", ["get", "tmc"]], ["literal", colors]],
            '#ccc'
        );

      const geoids = this.filters.geography.value,
        filtered = this.filters.geography.domain
          .filter(({ value }) => geoids.includes(value));

      const collection = {
        type: "FeatureCollection",
        features: filtered.map(f => ({
          type: "Feature",
          properties: { geoid: f.value },
          geometry: get(data, ["geo", f.geolevel.toLowerCase(), f.value, "geometry", "value"], null)
        })).filter(f => Boolean(f.geometry))
      }
      map.getSource("geo-boundaries-source").setData(collection);

      // map.setPaintProperty(
      //     'primary-symbology',
      //     'line-color',
      //     ["get", ["to-string", ["get", "tmc"]], ["literal", colors]]
      // );
      this.infoBoxes.measureInfo.show = Boolean(tmcData.length);
      this.infoBoxes.dataDownloader.show = Boolean(tmcData.length);
      // this.infoBoxes.download.show = true;
      // this.component.forceUpdate();
    }


}


const pm3Layer = new PM3Layer("Performance Measures ", {
    sources: [
      ...sources,
      { id: "geo-boundaries-source",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      }
    ],
    name: 'Performance Measures',
    allMeasures: [],
    layers: [
      ...layers,
      { id: "geo-boundaries",
        type: "line",
        source: "geo-boundaries-source",
        paint: {
          "line-color": "#fff"
        }
      }
    ],
    active: false,
    selection: [],
    tmcData: [],
    filters: {
        geography: {
            name: 'Geography',
            type: 'multi',
            domain: [],
            onChange: geoFilter,
            value: [] //year-mo-da
        },
        year: {
            name: 'year',
            type: 'dropdown',
            domain: [2016, 2017, 2018, 2019, 2020,2021,2022],
            onChange: function (map, layer, value, prev) {

                if (layer.filters.year.value > Math.min(...layer.filters.year.domain)) {
                    layer.filters.compare.type = 'dropdown';
                    layer.filters.compare.domain = ['none', ...layer.filters.year.domain.filter(year => year < layer.filters.year.value)]

                } else {
                    layer.filters.compare.type = 'hidden';
                    layer.filters.compare.value = 'none';
                    let baseKey = layer.filters.measure.value.split('_')[0];
                    updateLegend(baseKey, layer)
                }
                map.setLayoutProperty(yearToLayer[prev].id, 'visibility', 'none');
                map.setLayoutProperty(yearToLayer[value].id, 'visibility', 'visible');
                geoFilter(map, layer, layer.filters.geography.value)
            },
            value: 2022
        },
        compare: {
            name: 'compare',
            type: 'dropdown',
            domain: ['none', 2016, 2017, 2018,2019,2020,2021],
            value: 'none',
            onChange: function (map, layer, value, prev) {
                // if comparing, show comparison year network
                // else show current year network
                if (value !== 'none') {
                    updateLegend('compare', layer)
                } else if (prev !== 'none') {
                    let baseKey = layer.filters.measure.value.split('_')[0];
                    updateLegend(baseKey, layer)

                }
                geoFilter(map, layer, layer.filters.geography.value)

            }
        },
        network: {
            name: 'Network',
            type: 'dropdown',
            domain: ['NPMRDS', 'NHS', 'Designated Freight', 'Interstate', 'Non-Interstate'],
            value: 'NPMRDS',
            onChange: networkFilter
        },
        measure: {
            name: 'Performance Measure',
            type: 'dropdown',
            domain: [{name: 'Level of Travel Time Reliability', value: 'lottr'}],
            value: 'lottr',
            onChange: (map, layer, value) => {

                let compareYear = isNaN(+layer.filters.compare.value) ? null : +layer.filters.compare.value;
                let basekey = compareYear ? 'compare' : value.split('_')[0];

                updateLegend(basekey, layer)

            },
            searchable: true
        },
        bottleneck: {
            name: 'Bottlnecks',
            'type': 'checkbox',
            id: 'bottleneckSwitch',
            domain: [true, false],
            value: false,
            onChange: (map, layer, value) => {
                layer.infoBoxes.bottlenecks.show = value;
                if (!value && map.getLayer('bottlnecks')) {
                    let layerId = map.getLayer('bottlnecks').id;
                    let sourceId = map.getLayer('bottlnecks').source;
                    map.removeLayer(layerId);
                    map.removeSource(sourceId)
                }
                //layer.component.forceUpdate()
            }
        },
        num_bottlenecks: {
            name: 'num_bottlenecks',
            type: 'hidden',
            value: 10
        },
        dataQuality_bottlenecks: {
            name: 'dataQuality_bottlenecks',
            type: 'hidden',
            value: 0.001
        }
    },
    update: function (data) {
        // console.log('data to update: ',data)
        // console.log(data)
        this.component.updateFilter(this.name, 'num_bottlenecks', Math.round(data))
    },
    updateDataQuality: function (data) {
        // console.log('dataQuality to update: ',data)
        // console.log(data)
        this.component.updateFilter(this.name, 'dataQuality_bottlenecks', data)
    },
    legend: {
        active: false,

        type: "threshold",

        types: ["threshold", "linear", "quantile", "quantize"],

        domain: [1.1, 1.25, 1.5, 1.75, 2],
        range: DEFAULT_COLORS['lottr'],

        title: "",
        format: d => d.toLocaleString(undefined, {maximumFractionDigits: 2}),
        vertical: false
    },
    onHover: {
        layers: ['npmrds-2019_pm3', 'npmrds-2017_pm3'],
    },
    infoBoxes: {
        measureInfo: {
            title: "Measure Info",
            comp: (props = {}) =>
                <MeasureInfoBox
                    layer={pm3Layer}
                    measure={pm3Layer.filters.measure.value}
                    excludeFullNameInInfoBox={true}
                    peaks={true}/>,
            show: false
        },
        dataDownloader: {
          title: "Download Data",
          comp: ({ layer }) => (
            <DataDownloader layer={ layer }
              network={ "tmc" }
              measures={
                layer.filters.measure.domain
                  .filter(({ value }) => value.includes("aadt") || !value.includes("_"))
              }
              allMeasures={ layer.allMeasures }
              measure={ layer.filters.measure.value }
              year={ layer.filters.year.value }
              compareYear={ layer.filters.compare.value }
              loading={ layer.loading }
              geoids={ layer.filters.geography.value }
            />
          ),
          show: false
        },
        // download: {
        //     title: "Data Download",
        //     comp: (props = {}) => {
        //         return (
        //             <div style={{display: 'flex', justifyContent: 'space-around'}}>
        //                 <CsvDownloader
        //                     geoName={pm3Layer.filters.geography.value}
        //                     activeTmcs={pm3Layer.selection}
        //                     measureIds={pm3Layer.filters.measure.domain.map(d => d.value)}
        //                     activePM3Year={pm3Layer.filters.year.value}
        //                     tmcMetaVars={tmcMetaVars}
        //                 />
        //                 <ShpDownloader
        //                     geoName={pm3Layer.filters.geography.value}
        //                     activeTmcs={pm3Layer.selectedFeatures}
        //                     measureIds={pm3Layer.filters.measure.domain.map(d => d.value)}
        //                     activePM3Year={pm3Layer.filters.year.value}
        //                     tmcMetaVars={tmcMetaVars}
        //                 />
        //             </div>
        //         )
        //
        //     },
        //     show: false
        // },
        bottlenecks: {
            comp: (props = {}) => {
                return (
                    <TopMeasures
                        layer={pm3Layer}
                        updateFilter={pm3Layer.component.updateFilter.bind(pm3Layer)}
                        tmcData={pm3Layer.tmcData}
                        num_bottlenecks={pm3Layer.filters.num_bottlenecks.value}
                        dataQuality_bottlenecks={pm3Layer.filters.dataQuality_bottlenecks.value}
                        minRange={pm3Layer.minRange}
                        activePM3Year={pm3Layer.filters.year.value}

                        {...props}
                    />
                )
            },
            show: false
        }
    },
    popover: {
        layers: ['npmrds-2019_pm3', 'npmrds-2017_pm3', 'bottlnecks'],
        dataFunc: function (feature) {
            const tmc = feature.properties.tmc;
            if (feature.layer.id === 'bottlnecks') {
                try {
                    let year = this.filters.year.value;
                    const pm3 = falcorGraph.getCache().pm3.measuresByTmc[tmc];
                    const measureName = this.filters.measure.domain.filter(d => d.value === this.filters.measure.value)[0].name;
                    const measurMeta = falcorGraph.getCache().tmc[tmc].meta[year];
                    let measureData = pm3[year][this.filters.measure.value];
                    let dataQuality = 'N/A';
                    let compareYear = isNaN(+this.filters.compare.value) ? null : +this.filters.compare.value;
                    pm3Layer.tmcData.forEach(f => {
                        if (f.tmc === tmc && f.min) dataQuality = f.min
                    });
                    let output = ['Bottleneck',
                        [measurMeta.roadname, ''],
                        [measurMeta.firstname, ''],
                        ['TMC', feature.properties.tmc],
                        ['Data Quality', dataQuality],
                        [measureName + ' ' + year, measureData]

                    ];

                    if (compareYear) {
                        let compareData = pm3[compareYear][this.filters.measure.value];
                        let change = (measureData - compareData) / compareData * 100;
                        output.push(
                            [measureName + ' ' + compareYear, compareData],
                            ['% Change', change.toFixed(2)]
                        )
                    }
                    return output
                } catch (e) {
                    return ['Bottleneck',
                        ['TMC', feature.properties.tmc],
                        ['Value', feature.properties.value],

                    ]
                }

            } else {
                try {
                    const meta = falcorGraph.getCache().tmc[tmc].meta[this.filters.year.value];
                    const pm3 = falcorGraph.getCache().pm3.measuresByTmc[tmc];
                    let year = this.filters.year.value;
                    let compareYear = isNaN(+this.filters.compare.value) ? null : +this.filters.compare.value;


                    const measureName = this.filters.measure.domain.filter(d => d.value === this.filters.measure.value)[0].name;
                    let measureData = pm3[year][this.filters.measure.value];

                    let output = [meta.roadname,
                        [meta.firstname, ''],
                        ["TMC", <a href={`/tmc/${tmc}`} style={{color: 'white'}}>{tmc}</a>],
                        // ["Length", `${ graph.attributes.length.toFixed(2) } miles`],
                        // ["AADT", graph.attributes.aadt.toLocaleString()],
                        // ["Speed Limit", `${graph.attributes.avg_speedlimit.toLocaleString()} mph`],
                        [measureName + ' ' + year, measureData]
                    ];
                    if (compareYear) {
                        let compareData = pm3[compareYear][this.filters.measure.value];
                        let change = (measureData - compareData) / compareData * 100;
                        output.push(
                            [measureName + ' ' + compareYear, compareData],
                            ['% Change', change.toFixed(2)]
                        )
                    }
                    return output
                } catch (e) {
                    // console.log('e', e)
                    return ["NPMRDS",
                        ["TMC", tmc]
                    ]
                }
            }

        }
    },
});

export default pm3Layer
