export const getSharableUrl = state => null;

// import pick from 'lodash.pick';
// import omitBy from 'lodash.omitby';
// import deepEqual from 'deep-equal';

// import { createShareableUrl } from 'store/routingUtils';

// import validURLParams from './validURLParams';

// export const getSharableUrl = state => {
// const relevantParams = pick(storeState, validURLParams);
// const nondefaultRelevantParams = omitBy(relevantParams, (v, k) =>
// deepEqual(v, initialState[k])
// );

// if (nondefaultRelevantParams.tableFiltered) {
// nondefaultRelevantParams.tableFiltered = JSON.stringify(
// nondefaultRelevantParams.tableFiltered
// );
// }

// if (nondefaultRelevantParams.tableSorted) {
// nondefaultRelevantParams.tableSorted = JSON.stringify(
// nondefaultRelevantParams.tableSorted
// );
// }

// if (nondefaultRelevantParams.geoInfo) {
// nondefaultRelevantParams.geoInfo = JSON.stringify(
// nondefaultRelevantParams.geoInfo
// );
// }

// nondefaultRelevantParams.version = '0.0.3';

// return createShareableUrl(nondefaultRelevantParams);
// };
