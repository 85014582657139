import React from "react"

import GeneralGraphComp from "./graphClasses/GeneralGraphComp"

import get from "lodash.get"

import * as d3array from "d3-array"

import {
	getResolutionFormat,
	getResolutionLabel,
	getResolutionName
} from "./utils/resolutionFormats"

import TmcGridGraph from "./components/TmcGridGraph"

import {
	register
} from "./utils/DomainManager"

class Graph extends GeneralGraphComp {
	setReverseTMCs(reverseTMCs) {
		this.props.updateGraphComp(this.props.index, { state: { reverseTMCs } });
	}
	getReverseTMCs() {
		return get(this.props, 'state.reverseTMCs', false);
	}
	generateHeaderData(graphData, [route], [displayData], resolution) {
		const reverse = this.getReverseTMCs();
		return [
			{ type: "single-select-route" },
			{ type: "single-select-data" },
			{
				title: reverse ? "Normalize TMCs" : "Reverse TMCs",
				value: reverse,
				onChange: this.setReverseTMCs.bind(this),
				type: 'boolean-toggle'
			}
		];
	}
	generateGraphData([route], [displayData], resolution) {
		if (!route) return [];
		
		const { key, transform } = displayData,
			tmcArray = [...route.tmcArray],
			routeData = get(route, `data.${ key }`, []),
			grouped = d3array.group(routeData, d => d.tmc),
			year = this.getMaxYear(route);
		return tmcArray.reduce((a, tmc) => {
			const length = this.getTmcLength(year, tmc),
				data = grouped.get(tmc) || [];
			if (length) {
				a.push({
					tmc,
					length,
					data: data.map(d => ({ ...d, value: transform(d.value, length) }))
				})
			}
			return a;
		}, []);
	}
	hoverTmc(tmc) {
		this.props.setHighlightedTmcs(tmc ? [tmc] : [])
	}
	generateTableData(graphData, [route], [displayData], resolution) {
		const data = graphData.reduce((a, c) => {
			const base = {
				"TMC": c.tmc,
				"Length": c.length,
				"Resolution Type": getResolutionName(resolution),
				"Data Type": displayData.name,
				"Route Name": route.name
			}
			c.data.forEach(d => {
				a.push({
					...base,
					"Value": d.value,
					"Resolution": d.resolution
				})
			})
			return a;
		}, []);
		return { data, keys: ["Route Name", "TMC", "Length", "Data Type", "Value", "Resolution Type", "Resolution"] };
	}
	renderGraph(graphData, [route], [displayData], resolution, colorRange) {
		const {
			name,
			label,
			format
		} = displayData;

		const resFormat = getResolutionFormat(resolution),
			resLabel = getResolutionLabel(resolution);

		const [min, max] = register(this.props.type,
												displayData,
												resolution,
												this.props.id,
												graphData.reduce((a, c) => [...a, ...c.data.map(({ value }) => value)], []));

		return (
			<TmcGridGraph data={ graphData }
				showDirection={ true }
				reverseTMCs={ this.getReverseTMCs() }
				highlightedTmcs={ this.props.highlightedTmcs }
				hoverTmc={ tmc => this.hoverTmc(tmc) }
				colorScale={ {
					domain: [min, max]
				} }
				resolution={ resolution }
				colorRange={ colorRange }
				hoverData={
					d => [
						["TMC", d.tmc],
						[name, `${ format(d.value) } ${ label }`],
						[resLabel, resFormat(d.resolution)]
					]
				}/>
		)
	}
}
export default GeneralGraphComp.connect(Graph)
