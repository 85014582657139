import get from 'lodash.get';
import memoizeOne from 'memoize-one';
import deepEqual from 'deep-equal';

import { precisionRound, toNumeric } from 'utils/calculators/MathUtils';
import { tmcAttributesMap } from 'utils/constants';

// ===== TMC-LEVEL =====

const tmcAttrGetterBuilder = (attr, isNumeric) =>
  isNumeric
    ? (state, tmc, year) =>
        toNumeric(get(state, ['graph', 'tmc', tmc, 'meta', year, attr]))
    : (state, tmc, year) =>
        get(state, ['graph', 'tmc', tmc, 'meta', year, attr], null);

export const getTmc = (state, tmc) => tmc;

export const getTmcRoadname = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcRoadname,
  false
);

export const getTmcAvgSpeedlimit = (state, tmc, year) => {
  const avgSpeedlimit = get(state, [
    'graph',
    'tmc',
    tmc,
    'meta',
    year,
    'avg_speedlimit'
  ]);

  return +avgSpeedlimit || null;
};

export const getTmcLength = (state, tmc, year) => {
  const length = +get(state, [
    'graph',
    'tmc',
    tmc,
    'meta',
    year,
    tmcAttributesMap.tmcLength
  ]);

  return length ? precisionRound(+length, 4) : null;
};

export const getTmcDirection = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcDirection,
  false
);

export const getTmcAadt = tmcAttrGetterBuilder(tmcAttributesMap.tmcAadt, true);

export const getTmcAadtSingl = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcAadtSingl,
  true
);

export const getTmcAadtCombi = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcAadtCombi,
  true
);

export const getTmcFSystem = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcFSystem,
  true
);

export const getTmcFacilType = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcFacilType,
  true
);

export const getTmcStrucType = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcStrucType,
  true
);

export const getTmcThruLanes = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcThruLanes,
  true
);

export const getTmcRouteNumb = (state, tmc, year) => {
  const routenumb = get(state, [
    'graph',
    'tmc',
    tmc,
    'meta',
    year,
    'route_numb'
  ]);

  return routenumb ? +routenumb : null;
};

export const getTmcRouteSign = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcRouteSign,
  true
);

export const getTmcRouteQual = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcRouteQual,
  true
);

export const getTmcAltRteName = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcAltRteName,
  false
);

export const getTmcNHS = tmcAttrGetterBuilder(tmcAttributesMap.tmcNHS, true);

export const getTmcNHSPct = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcNHSPct,
  true
);

export const getTmcStrhntTyp = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcStrhntTyp,
  true
);

export const getTmcStrhntPct = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcStrhntPct,
  true
);

export const getTmcTruck = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcTruck,
  true
);

export const getTmcLinear = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcLinear,
  true
);

export const isTmcControlledAccess = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcIsControlledAccess,
  false
);

export const getTmcCountyName = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcCountyName,
  false
);

export const getTmcCountyCode = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcCountyCode,
  false
);

export const getTmcMpoName = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcMpoName,
  false
);

export const getTmcMpoCode = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcMpoCode,
  false
);

export const getTmcUAName = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcUAName,
  false
);

export const getTmcUACode = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcUACode,
  false
);

export const getTmcStateCode = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcStateCode,
  false
);

export const getTmcStateName = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcStateName,
  false
);

export const getTmcState = tmcAttrGetterBuilder(
  tmcAttributesMap.tmcState,
  false
);

export const getTmcBoundingBox = (state, tmc, year) =>
  get(
    state,
    [
      'graph',
      'tmc',
      tmc,
      'meta',
      year,
      tmcAttributesMap.tmcBoundingBox,
      'value'
    ],
    null
  );

const attr2getter = {
  tmc: getTmc,
  tmcAadt: getTmcAadt,
  tmcAadtCombi: getTmcAadtCombi,
  tmcAadtSingl: getTmcAadtSingl,
  tmcAltRteName: getTmcAltRteName,
  tmcAvgSpeedlimit: getTmcAvgSpeedlimit,
  tmcBoundingBox: getTmcBoundingBox,
  tmcCountyCode: getTmcCountyCode,
  tmcCountyName: getTmcCountyName,
  tmcDirection: getTmcDirection,
  tmcFSystem: getTmcFSystem,
  tmcFacilType: getTmcFacilType,
  tmcIsControlledAccess: isTmcControlledAccess,
  tmcLength: getTmcLength,
  tmcLinear: getTmcLinear,
  tmcMpoCode: getTmcMpoCode,
  tmcMpoName: getTmcMpoName,
  tmcNHS: getTmcNHS,
  tmcNHSPct: getTmcNHSPct,
  tmcRoadname: getTmcRoadname,
  tmcRouteNumb: getTmcRouteNumb,
  tmcRouteQual: getTmcRouteQual,
  tmcRouteSign: getTmcRouteSign,
  tmcState: getTmcState,
  tmcStateCode: getTmcStateCode,
  tmcStateName: getTmcStateName,
  tmcStrhntPct: getTmcStrhntPct,
  tmcStrhntTyp: getTmcStrhntTyp,
  tmcStrucType: getTmcStrucType,
  tmcThruLanes: getTmcThruLanes,
  tmcTruck: getTmcTruck,
  tmcUACode: getTmcUACode,
  tmcUAName: getTmcUAName
};

// Quick & dirty way to keep tmcAttributes from causing rerenders.
const _tmcAttrsIdentityMemoizer = memoizeOne(attrs => attrs, deepEqual);

export const getTmcAttributes = (state, tmcs, year) => {
  const isArr = Array.isArray(tmcs);
  const tmcsArr = isArr ? tmcs : [tmcs];

  const tmcAttributes = tmcsArr.map(tmc =>
    Object.keys(attr2getter).reduce(
      (acc2, attr) => {
        const v = attr2getter[attr](state, tmc, year);
        if (v !== null && Number.isNaN(v)) {
          return acc2;
        }
        acc2[attr] = v;
        return acc2;
      },
      { tmc }
    )
  );

  return _tmcAttrsIdentityMemoizer(isArr ? tmcAttributes : tmcAttributes[0]);
};

// Quick & dirty way to keep the boundingBoxes from causing rerenders.
const _selectedTmcsBoundingBoxIdentityMemoizer = memoizeOne(
  bb => bb,
  deepEqual
);

export const getSelectedTmcsBoundingBox = (state, tmcs, year) => {
  if (!tmcs) {
    return null;
  }

  const tmcsArr = Array.isArray(tmcs) ? tmcs : [tmcs];

  const boundingBoxes = tmcsArr
    .map(tmc => getTmcBoundingBox(state, tmc, year))
    .filter(bb => bb);

  const bb = boundingBoxes.length
    ? boundingBoxes.reduce(
        (acc, bb) => {
          if (!bb) {
            return acc;
          }

          const [[a, b], [c, d]] = bb;
          if (+a < acc[0][0]) {
            acc[0][0] = +a;
          }
          if (+b < acc[0][1]) {
            acc[0][1] = +b;
          }
          if (+c > acc[1][0]) {
            acc[1][0] = +c;
          }
          if (+d > acc[1][1]) {
            acc[1][1] = +d;
          }
          return acc;
        },
        [
          [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
          [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY]
        ]
      )
    : null;

  return _selectedTmcsBoundingBoxIdentityMemoizer(bb);
};
