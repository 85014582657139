import { getGeoLevelsForStateCodes } from 'store/falcorGraphSelectors';

import GeoLevelPm3VersionsMetadataParser from 'utils/pm3/GeoLevelPm3VersionsMetadataParser';

import { STATE, COUNTY, MPO, UA } from '../constants';

const stateIdsToGeoInfoArr = geoIds =>
  Array.isArray(geoIds)
    ? geoIds.sort().map(geoid => ({
        geolevel: STATE,
        geoid
      }))
    : null;

export default (state, geolevel, stateCode) => {
  if (geolevel === STATE) {
    const versionsMetadata = new GeoLevelPm3VersionsMetadataParser(state);

    const { calculatedStates } = versionsMetadata;
    return stateIdsToGeoInfoArr(calculatedStates);
  }

  const geoLevelsForState = getGeoLevelsForStateCodes(state, stateCode);

  if (geolevel === COUNTY) {
    return geoLevelsForState
      ? geoLevelsForState.filter(
          ({ geolevel: stateGeoLevel }) => stateGeoLevel === COUNTY
        )
      : null;
  }

  if (geolevel === MPO) {
    return geoLevelsForState
      ? geoLevelsForState.filter(
          ({ geolevel: stateGeoLevel }) => stateGeoLevel === MPO
        )
      : null;
  }

  if (geolevel === UA) {
    return geoLevelsForState
      ? geoLevelsForState.filter(
          ({ geolevel: stateGeoLevel }) => stateGeoLevel === UA
        )
      : null;
  }

  console.error('WARNING: Unrecognized geolevel', geolevel);

  return null;
};
