import React, { Component } from "react";
import { Link } from "react-router-dom";

class Logo extends Component {
  render() {
    return (
      <div className="logo-w">
        <Link className="logo" to="/">
          <div className="logo-label">NPMRDS</div>
        </Link>
      </div>
    );
  }
}

class AvatarUser extends Component {
  render() {
    return (
      <div className="logged-user-w avatar-inline">
        <div className="logged-user-i">
          <div className="avatar-w" style={{ border: "none" }}>
            <i className="pre-icon os-icon os-icon-user-male-circle" />
          </div>
          <div className="logged-user-info-w">
            <div className="logged-user-name">
              {this.props.user.email ? this.props.user.email : ""}
            </div>
            <div className="logged-user-role" style={{ color: "#cdcdcd" }}>
              {this.props.user.groups ? this.props.user.groups[0] : ""}
            </div>
            <div className="logged-user-role" style={{ color: "#666" }}>
              v1.0.39
            </div>
          </div>
          <div className="logged-user-toggler-arrow">
            <div className="os-icon os-icon-chevron-down" />
          </div>
          <div className="logged-user-menu">
            <ul style={{ whiteSpace: "nowrap" }}>
              <li style={{ display: "block" }}>
                <Link to="/auth/profile">
                  <i className="fa fa-user" />
                  <span>Profile</span>
                </Link>
              </li>
              {this.props.user.authLevel < 5 ? null : (
                <li style={{ display: "block" }}>
                  <Link to="/auth/project-management">
                    <i className="fa fa-cog" />
                    <span>Project Management</span>
                  </Link>
                </li>
              )}
              {this.props.user.authLevel < 5 ? null : (
                <li style={{ display: "block" }}>
                  <Link to="/docs">
                    <i className="fa fa-edit" />
                    <span>Edit Docs</span>
                  </Link>
                </li>
              )}
              <li style={{ display: "block" }}>
                <Link to="/auth/logout">
                  <i className="fa fa-sign-out-alt" />
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
            <div
              className="logged-user-avatar-info"
              style={{ display: "flex" }}
            >
              <div className="avatar-w">
                <i className="os-icon os-icon-user-male-circle" />
              </div>
              <div className="logged-user-info-w">
                <div className="logged-user-name">
                  {this.props.user.email ? this.props.user.email : ""}
                </div>
                <div className="logged-user-role">
                  {this.props.user.groups ? this.props.user.groups[0] : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class LoginMenu extends Component {
  render() {
    return (
      <div className="menu-actions">
        <Link to={"/auth/login"}>
          <div className="top-icon top-settings os-dropdown-trigger os-dropdown-position-right">
            <span className="loginMenu">LOGIN </span>
            <i className="pre-icon os-icon os-icon-user-male-circle" />
          </div>
        </Link>
      </div>
    );
  }
}

class TopSearch extends Component {
  render() {
    return (
      <div className="element-search autosuggest-search-activator">
        <input placeholder="Start typing to search..." type="text" />
      </div>
    );
  }
}

class TopNav extends Component {
  render() {
    return (
      <span style={{ width: "100%" }}>
        <Logo />
        <TopSearch />
        <LoginMenu />
        <AvatarUser />
      </span>
    );
  }
}

export default TopNav;
export { Logo, TopSearch, LoginMenu, AvatarUser };
