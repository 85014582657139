import React from 'react';
import Plot from 'react-plotly.js';
import * as ss from 'simple-statistics';
import { precisionRound, numbersComparator } from 'utils/calculators/MathUtils';

import {
  HIDE_OUTLIERS,
  outlierFilters,
  outlierFilterRanges
} from 'utils/calculators/OutlierHandlingUtils';

const annotationOffset = 50;
const stats = [
  'mean',
  'median',
  'q1',
  'q3',
  'lowerInnerFence',
  'lowerOuterFence',
  'upperInnerFence',
  'upperOuterFence'
];

const dataDensityDescriptions = {
  A: 'A: 1-4 vehicles',
  B: 'B: 5-9 vehicles',
  C: 'C: 10 or more vehicles'
};

const formatData = ({ avgsByBin, metric }, doPeakBreakdown) => {
  if (!(Array.isArray(avgsByBin) && avgsByBin.length)) {
    return null;
  }

  const k = metric === 'SPEED' ? 'avgSpeed' : 'avgTT';

  const dataBySubclass = avgsByBin.reduce(
    (acc, { dataDensity, peak, [k]: metricValue }) => {
      const p = doPeakBreakdown
        ? peak || 'Non-peak'
        : dataDensity || 'Data Density Not Available';

      const m = precisionRound(metricValue);

      acc[p] = acc[p] || { values: [], distro: {} };
      acc[p].values.push(m);

      acc[p].distro[m] = acc[p].distro[m] || 0;
      ++acc[p].distro[m];

      return acc;
    },
    {}
  );

  const subclasses = Object.keys(dataBySubclass);

  const bySubclassTraces = subclasses.reduce((acc, subclass) => {
    const d = dataBySubclass[subclass];

    d.values.sort(numbersComparator);

    const { x, y } = Object.keys(d.distro)
      .sort(numbersComparator)
      .reduce(
        (dAcc, m) => {
          dAcc.x.push(+m);
          dAcc.y.push(d.distro[m]);
          return dAcc;
        },
        { x: [], y: [] }
      );

    const a = {
      x,
      y,
      min: x[0],
      max: x[x.length - 1],

      q1: ss.quantileSorted(d.values, 0.25),
      median: ss.medianSorted(d.values),
      q3: ss.quantileSorted(d.values, 0.75),

      mean: ss.mean(d.values)
    };

    a.interquartileRange = a.q3 - a.q1;
    a.lowerInnerFence = a.q1 - 1.5 * a.interquartileRange;
    a.upperInnerFence = a.q3 + 1.5 * a.interquartileRange;
    a.lowerOuterFence = a.q1 - 3 * a.interquartileRange;
    a.upperOuterFence = a.q3 + 3 * a.interquartileRange;

    a.cdf = a.x.reduce(
      (acc, m, i) => {
        acc.x.push(m);
        acc.y.push(ss.quantileRankSorted(d.values, m));
        return acc;
      },
      { x: [], y: [] }
    );

    acc[subclass] = a;
    return acc;
  }, {});

  return bySubclassTraces;
};

const getViz = ({ avgsByBin, metric, binMinutes }, doPeakBreakdown) => {
  const bySubclassTraces = formatData(
    { avgsByBin, metric, binMinutes },
    doPeakBreakdown
  );

  if (!bySubclassTraces) {
    return null;
  }

  const subclasses = Object.keys(bySubclassTraces);
  if (!doPeakBreakdown) {
    subclasses.sort();
  }

  const metricName = metric === 'SPEED' ? 'Speed' : 'Travel Time';

  var updatemenus = [
    {
      buttons: outlierFilters.map(label => ({
        label,
        method: 'animate',
        args: [
          [label],
          {
            mode: 'immediate',
            transition: { duration: 0 },
            frame: { duration: 0, redraw: true }
          }
        ]
      })),
      direction: 'up',
      margin: { r: 10, t: 10, b: 10 },
      pad: { r: 10, t: 10, b: 10 },
      showactive: true,
      type: 'dropdown',
      xanchor: 'left',
      x: 0,
      y: -0.4,
      yanchor: 'bottom'
    }
  ];

  const filterData = outlierFilterLabel => {
    const [leftCutoff, rightCutoff] = outlierFilterRanges.get(
      outlierFilterLabel
    );

    return Array.prototype.concat(
      subclasses.map(subclass => {
        const {
          x,
          y,
          [leftCutoff]: lco,
          [rightCutoff]: rco
        } = bySubclassTraces[subclass];

        return Object.assign(
          {
            name:
              (!doPeakBreakdown && dataDensityDescriptions[subclass]) ||
              subclass,
            type: 'bar',
            visible: subclass !== 'Non-subclass' || 'legendonly'
          },
          // FIXME: Use OutlierHandlingUtils functions
          x.reduce(
            (acc, m, i) => {
              if (+m >= +lco && +m <= +rco) {
                acc.x.push(m);
                acc.y.push(precisionRound(y[i], 3));
              }
              return acc;
            },
            { x: [], y: [] }
          )
        );
      }),

      subclasses.map(subclass => {
        const { cdf, [leftCutoff]: lco, [rightCutoff]: rco } = bySubclassTraces[
          subclass
        ];
        return Object.assign(
          {
            name: `${subclass} CDF`,
            mode: 'lines',
            line: {
              width: 2,
              bordercolor: 'white'
            },
            // type: 'scatter',
            yaxis: 'y2',
            visible: 'legendonly'
          },
          // FIXME: Use OutlierHandlingUtils functions
          cdf.x.reduce(
            (acc, m, i) => {
              if (+m >= +lco && +m <= +rco) {
                acc.x.push(m);
                acc.y.push(precisionRound(cdf.y[i], 5));
              }
              return acc;
            },
            { x: [], y: [] }
          )
        );
      })
    );
  };

  const data = filterData(HIDE_OUTLIERS);

  const sliders = [
    {
      pad: { t: 50 },
      x: 0.38,
      len: 0.62,
      currentvalue: {
        xanchor: 'right',
        prefix: 'Annotate: ',
        font: {
          color: '#888',
          size: 20
        }
      },
      transition: { duration: 500 },
      // By default, animate commands are bound to the most recently animated frame:
      steps: [
        {
          label: 'None',
          method: 'animate',
          args: [
            ['annotate-none'],
            {
              mode: 'immediate',
              frame: { redraw: true, duration: 0 },
              transition: { duration: 0 }
            }
          ]
        }
      ].concat(
        subclasses.map(subclass => ({
          label:
            (!doPeakBreakdown && dataDensityDescriptions[subclass]) || subclass,
          method: 'animate',
          args: [
            [`annotate-${subclass}`],
            {
              mode: 'immediate',
              frame: { redraw: true, duration: 0 },
              transition: { duration: 0 }
            }
          ]
        }))
        // subclasses.length > 1
        // ? {
        // label: 'All',
        // method: 'animate',
        // args: [
        // ['annotate-all'],
        // {
        // mode: 'immediate',
        // frame: { redraw: true, duration: 0 },
        // transition: { duration: 0 }
        // }
        // ]
        // }
        // : []
      )
    }
  ];

  const getAnnotationLayoutFrames = selectedPeak => {
    let offset = annotationOffset;
    return {
      annotations: subclasses.reduce((acc, subclass) => {
        const d = bySubclassTraces[subclass];
        const x =
          !selectedPeak || selectedPeak === subclass
            ? stats.map(stat => ({
                text: `${subclass} ${stat} (${precisionRound(d[stat], 1)} mph)`,
                bgcolor: 'rgba(255,255,255,0.75)',
                xref: 'x',
                x: d[stat],
                yref: 'paper',
                y: 0,
                yshift: (offset += annotationOffset)
              }))
            : Array(stats.length).fill(null);

        acc.push(...x);
        return acc;
      }, []),
      shapes: subclasses.reduce((acc, subclass) => {
        const d = bySubclassTraces[subclass];

        const x =
          !selectedPeak || selectedPeak === subclass
            ? stats.map(stat => ({
                type: 'line',
                xref: 'x',
                x0: d[stat],
                x1: d[stat],
                yref: 'paper',
                y0: 0,
                y1: 1
              }))
            : Array(stats.length).fill(null);

        acc.push(...x);
        return acc;
      }, [])
    };
  };

  const frames = Array.prototype.concat(
    // Map the data filtering options to a frame with the respective filtering applied.
    outlierFilters.map(outlierFilterLabel => {
      const data = filterData(outlierFilterLabel).map(({ x, y }) => ({ x, y }));

      // What is the new x-axis range after the filter is applied?
      const xaxisRange = data.reduce(
        (acc, { x }) => [
          Math.min(acc[0], x[0]),
          Math.max(acc[1], x[x.length - 1])
        ],
        [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
      );
      return {
        name: outlierFilterLabel,
        data,
        layout: {
          xaxis: { range: xaxisRange }
        }
      };
    }),
    {
      name: 'annotate-none',
      // https://community.plot.ly/t/shapes-and-annotations-are-not-pruned-cleared-when-new-figure-returned-to-graph/11268/6
      layout: {
        annotations: null,
        shapes: null
      }
    },
    subclasses.map(subclass => ({
      name: `annotate-${subclass}`,
      layout: getAnnotationLayoutFrames(subclass)
    })),
    subclasses.length > 1
      ? {
          name: 'annotate-all',
          layout: getAnnotationLayoutFrames(null)
        }
      : []
  );

  const layout = {
    title: `${metricName} Distribution By ${
      doPeakBreakdown ? 'Peak' : 'Data Density'
    } (${binMinutes} minute bins)`,
    xaxis: {
      title: metricName,
      autorange: true
    },
    yaxis: {
      autorange: true,
      title: 'Count'
    },
    //CDF Axis
    yaxis2: {
      title: 'Probability',
      overlaying: 'y',
      side: 'right',
      range: [0, 1.2]
    },
    legend: {
      x: 1.05
    },
    barmode: 'stack',
    bargap: 0.1,
    sliders,
    updatemenus
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '100%', height: 750 }}
      data={data}
      frames={frames}
      layout={layout}
      config={config}
    />
  );
};

const AvgsByBinHistogram = ({ data, doPeakBreakdown }) =>
  Array.isArray(data.avgsByBin) && data.avgsByBin.length ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        <p>
          Definition of "outliers":
          <cite>
            <a
              href="https://www.statisticshowto.datasciencecentral.com/upper-and-lower-fences/"
              target="_blank"
              rel="noopener noreferrer"
            >
              link
            </a>
          </cite>
        </p>
        <p>
          Explanation of Cumulative Distribution Functions
          <cite>
            <a
              href="https://en.wikipedia.org/wiki/Cumulative_distribution_function"
              target="_blank"
              rel="noopener noreferrer"
            >
              link
            </a>
          </cite>
        </p>
        {getViz(data, doPeakBreakdown)}
      </div>
    </div>
  ) : null;

export default AvgsByBinHistogram;
