import React from "react"
// import { connect } from "react-redux"
// import { reduxFalcor, UPDATE as REDUX_UPDATE } from "utils/redux-falcor"

import { falcorGraph, falcorChunkerNice } from "store/falcorGraph"
import { listen, unlisten } from "components/AvlMap/LayerMessageSystem"
// import { register, unregister } from "components/AvlMap/ReduxMiddleware"

import geoJsonExtent from "@mapbox/geojson-extent"
import get from "lodash.get";
import mapboxgl from "mapbox-gl"
// import styled from "styled-components"

import MapLayer from "components/AvlMap/MapLayer"

import {
  ConflationSource,
  ConflationStyle
} from './conflation.style.js'

import * as d3scale from "d3-scale"
// import { getColorRange } from "constants/color-ranges"

import RouteInfoBox from "../components/RouteInfoBox"

const atts = ['id', 'name', 'type', 'owner', "updatedAt", "tmcArray", "points"];

class RouteLayer extends MapLayer {
  onAdd(map) {
console.log("companionLayerName", this.companionLayerName)
    // register(this, REDUX_UPDATE, ["graph"]);
    listen(this, this.companionLayerName);

    this.markers.forEach(m => m.addTo(map));

    return this.loadUserRoutes(false)
      .then(() => falcorGraph.get(["conflation", "latestVersion"]))
      .then(() => this.calcRoute());
  }
  onRemove(map) {
    // unregister(this);
    unlisten(this);
    this.markers.forEach(m => m.remove());
  }
  loadUserRoutes(forceUpdate = true) {

    return falcorGraph.get(["routes", "length"])
      .then(res => {
        const num = get(res, ["json", "routes", "length"], 0);
        if (num) {
          return falcorGraph.get([
            'routes',
            'byIndex',
            { from: 0, to: num - 1 },
            atts
          ])
          .then(res => {
            // console.log("RES:", res);
            const routes = [];
            for (let i = 0; i < num; ++i) {
              routes.push(atts.reduce((a, c) => {
                a[c] = get(res, ["json", "routes", "byIndex", i, c], null);
                return a;
              }, {}))
            }
            this.filters.userRoutes.domain = routes;
          })
        }
        else {
          this.filters.userRoutes.domain = [];
        }
      })
      .then(() => {
        if (this.routeToLoad) {
          return falcorGraph.get(["routes", "byId", this.routeToLoad, atts])
            .then(() => this.selectUserRoute(this.routeToLoad))
        }
      })
      // .then(() => forceUpdate && this.forceUpdate());
  }
  // receiveMessage(action, data) {
  // }
  // receiveLayerMessage(msg) {
  //   if (msg.type === "FilterMessage" && msg.filterName == "year") {
  //     this.year = msg.newValue;
  //     this.map && this.render(this.map);
  //   }
  // }
  receiveLayerMessage(msg) {
console.log("RECEIVED:", msg)
    if ((msg.type === "FilterMessage") &&
        (msg.layerName === this.companionLayerName) &&
        (msg.filterName === "geography")) {
console.log("SELECTION:", msg.data)
    }
  }
  getNetworkId() {
    const year = this.year < 2019 ? 2017 : 2019;
    return "tmc" + year.toString().slice(2) + "id";
  }
  handleMapClick(data) {
    switch (this.mode) {
      case "markers":
        this.generateMapMarkers(data);
        break;
      case "click":
        const newData = new Set([...data]),
          set = new Set([...this.data.click]);
        newData.forEach(tmc => {
          if (!set.has(tmc)) {
            set.add(tmc);
          }
          else {
            set.delete(tmc);
          }
        })
        this.data.click = [...set];
        break;
    }
    this.calcRoute();
  }

  getGeomRequest(selection) {
    if (this.mode === "click") {
      return ['tmc', selection, 'year', this.year, 'geometries'];
    }
    else if (this.mode === "markers") {
      return ['conflation', 'con', selection, 'meta', 'geometries'];
    }
    return false;
  }

  fetchData() {
    return Promise.resolve()
      .then(() => {
        if (this.mode === "click") {
          return { mode: "click", data: [...this.data.click] };
        }
        if (this.markers.length < 2) {
          return { mode: "markers", data: [] };
        }

        const locations = this.markers.map(m => ({
          lon: m.getLngLat().lng,
          lat: m.getLngLat().lat
        }))
        //const url = `http://ares.availabs.org:7185/route`;
        const url = `https://routing.availabs.org/0_4_2/route`;

        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrer: 'no-referrer',
            body: JSON.stringify({ locations })
        })
        .then((res, error) => {
          if (error) return { ways: [] };
          return res.json()
        })
        .then(res => {
          return { mode: "markers", data: get(res, "ways", []) };
        })
      })
      .then(({ mode, data }) => {
        this.data[mode] = data;
      })
      .then(() => {
        if (this.data[this.mode].length) {
          return falcorChunkerNice(this.getGeomRequest(this.data[this.mode]))
        }
      })
      .then(() => {
        if (this.mode === "markers" && this.data.markers.length) {
          const request = ["conflation", "con", this.data.markers, "meta", ['tmc17id','tmc19id']];
          return falcorChunkerNice(request)
        }
      })
      .then(() => {
        if (this.mode === "markers") {
          const falcorCache = falcorGraph.getCache();

          this.tmcArray = this.data.markers.reduce((a, c) => {
            const tmc = get(falcorCache, ["conflation", "con", c, "meta", this.getNetworkId()], null);
            if (tmc && !a.includes(tmc)) {
              a.push(tmc);
            }
            return a;
          }, []);
        }
        else {
          this.tmcArray = [...this.data.click];
        }
      })
  }
  reorderTmcs(tmcArray) {
    if (this.mode === "click") {
      this.data.click = [...tmcArray];
      this.tmcArray = [...tmcArray];
      this.forceUpdate();
    }
  }
  getMapFilter() {
    switch (this.mode) {
      case "markers":
        return ["match",
          ["to-string", ["get", "id"]],
          [...new Set(this.data[this.mode]), "none"],
          true, false
        ]
      case "click":
        return ["match",
          ["to-string", ["get", this.getNetworkId()]],
          [...new Set(this.data[this.mode]), "none"],
          true, false
        ]
    }
  }
  render(map) {
    map.setFilter("conflation-route", this.getMapFilter());

    map.setPaintProperty("conflation-route", "line-color",
      [ "case",
        ["any",
          ["boolean", ["feature-state", "hover"], false],
          ["in", ["get", this.getNetworkId()], ["literal", this.highlightedTmcs]]
        ], "#e0e",
        "#a0a"
      ]
    )

    this.zoomToBounds();
  }
  highlightTmcs(tmcs) {
    const set = new Set(this.highlightedTmcs);
    for (const tmc of tmcs) {
      if (set.has(tmc)) {
        set.delete(tmc);
      }
      else {
        set.add(tmc);
      }
    }
    this.highlightedTmcs = [...set];
    this.map && this.render(this.map);
  }
  generateMapMarkers(lngLat = null) {
    this.markers.forEach(m => m.remove());

    let points = this.markers.map(m => m.getLngLat());

    if (lngLat) {
      if (Array.isArray(lngLat)) {
        points = lngLat;
      }
      else {
        points.push(lngLat);
      }
    }

    const num = Math.max(points.length - 1, 1)

    const scale = d3scale.scaleLinear()
      .domain([0, num * 0.5, num])
      .range(["#1a9641", "#ffffbf", "#d7191c"])

    this.markers = points.map((p, i) => {
      return new mapboxgl.Marker({
          draggable: true,
          color: scale(i)
        })
        .setLngLat(p)
        .addTo(this.map)
        .on("dragend", e => this.calcRoute());
    })
  }
  calcRoute() {
    this.doAction(["fetchLayerData"]);
  }
  removeLast() {
    switch (this.mode) {
      case "markers":
        this.markers.pop().remove();
        this.generateMapMarkers();
        break;
      case "click":
        this.data["click"].pop();
        break;
    }
    this.calcRoute();
  }
  clearRoute() {
    switch (this.mode) {
      case "markers":
        while (this.markers.length) {
          this.markers.pop().remove();
        }
        break;
      case "click":
        this.data.click = [];
        break;
    }
    this.filters.userRoutes.value = null;
    this.mapActions.modeToggle.disabled = false;
    this.calcRoute();
  }
  toggleCreationMode() {
    switch (this.mode) {
      case "markers":
        this.mode = "click";
        this.markers.forEach(m => m.remove());
        break;
      case "click":
        this.mode = "markers";
        this.generateMapMarkers();
        break;
    }
    this.calcRoute();
  }
  loadUserRoute(route) {
    this.mapActions.modeToggle.disabled = true;
    // this.year = new Date(route.updatedAt).getFullYear();

    this.doZoom = true;

    if (get(route, "points", []).length) {
      this.mode = "markers";
      this.generateMapMarkers(route.points);
    }
    else {
      this.mode = "click";
      this.markers.forEach(m => m.remove());
      this.data.click = [...route.tmcArray];
    }
  }
  selectUserRoute(routeId) {
    const data = get(falcorGraph.getCache(), ["routes", "byId", routeId], null);
    if (data) {
      const route = atts.reduce((a, c) => {
        switch (c) {
          case "tmcArray":
          case "points":
            a[c] = get(data, [c, "value"], null);
            break;
          default:
            a[c] = get(data, c, null);
        }
        return a;
      }, {})
      this.routeToLoad = null;
      this.doAction(["updateFilter", "userRoutes", route]);
    }
    else {
      this.routeToLoad = routeId;
    }
  }
  zoomToBounds() {
    if (Boolean(this.map) && this.doZoom) {
      this.doZoom = false;

      const cache = falcorGraph.getCache();

      const bounds = this.data[this.mode].reduce((a, c) => {
        const data = get(cache, [...this.getGeomRequest(c), "value"], null);
        return data ? a.extend(new mapboxgl.LngLatBounds(geoJsonExtent(data))) : a;
      }, new mapboxgl.LngLatBounds());

      !bounds.isEmpty() &&
        this.map.fitBounds(bounds, { padding: { left: 375, top: 50, right: 425, bottom: 50 } });
    }
  }
  getRouteName() {
    return get(this.filters, ["userRoutes", "value", "name"], "Route");
  }
}

export default (props = {}) =>
  new RouteLayer("Conflation Routing", {
    sources: [ConflationSource],
    layers: [{
      ...ConflationStyle,
      id: "conflation-route",
      filter: ["boolean", false],
      paint: {
        ...ConflationStyle.paint,
        "line-color": [
          "case",
          ["boolean", ["feature-state", "hover"], false], "#e0e",
          "#a0a"
        ],
        "line-opacity": 1.0,
        "line-width": 6
      }
    }],

    highlightedTmcs: [],

    onHover: {
      layers: ["conflation-route"],
      filterFunc: function(features) {
        return [
          "in",
          this.getNetworkId(),
          ...features.map(f => f.properties[this.getNetworkId()])
            .filter(Boolean)
        ]
      }
    },

    active: false,
    companionLayerName: "Routing Companion",

    ...props,
    version: 2.0,

    mode: "markers",
    markers: [],
    tmcArray: [],
    data: {
      markers: [], // array of conlation ids
      click: [] // array of tmc ids
    },
    year: 2019,
    doZoom: false,

    routeToLoad: null,

    onClick: {
      layers: ["map", "conflation"],
      dataFunc: function(features, point, lngLat, layer) {
        switch (this.mode) {
          case "markers":
            layer === "map" && this.handleMapClick(lngLat);
            break;
          case "click":
            (layer === "conflation") && features &&
              this.handleMapClick(features.map(f => f.properties[this.getNetworkId()]).filter(Boolean));
            break;
        }

      }
    },

    popover: {
      layers: ["conflation-route", "conflation"],
      dataFunc: function(feature, features, layer) {
        const nId = this.getNetworkId(),
          tmcs = features.reduce((a, c) => {
            const tmc = get(c, ["properties", nId], null);
            if (tmc) {
              a.push(tmc);
            }
            return a;
          }, [])
        return tmcs.map(tmc => ["TMC", tmc]);
      }
    },

    filters: {
      userRoutes: {
        name: "Routes",
        type: "single",
        domain: [],
        value: null,
        searchable: true,
        onChange: function(prev, route, domain) {
          return this.loadUserRoute(route);
        }
      }
    },

    infoBoxes: {
      router: {
        title: ({ layer}) => layer.getRouteName(),
        comp: ({ layer }) => (
          <RouteInfoBox layer={ layer }
            userRoute={ layer.filters.userRoutes.value }
            tmcArray={ layer.tmcArray }
            mode={ layer.mode }/>
        ),
        show: true
      }
    },

    mapActions: {
      modeToggle: {
        Icon: ({ layer }) => <span className={ `fa fa-2x fa-${ layer.mode === "markers" ? "map-marker" : "road" }` }/>,
        tooltip: "Toggle Creation Mode",
        action: function() {
          this.toggleCreationMode();
        }
      }
    }

  })
