import {
  mb_tmc_metadata_2017,
  mb_tmc_metadata_2018,
  mb_tmc_metadata_2019,
  mb_tmc_metadata_2020,
} from 'constants/mapboxTilesets'

export const sources =  [
  {
    id: 'npmrds',
    source: {
      type: 'vector',
      url: mb_tmc_metadata_2017.url
    }
  },
	{
	  id: 'tmc_metadata_2018',
	  source: {
	    type: 'vector',
	    url: mb_tmc_metadata_2018.url
	  }
	},
  {
    id: 'tmc_metadata_2017',
    source: {
      type: 'vector',
      url: mb_tmc_metadata_2017.url
    }
  },

  { id: "transcom-source",
      source: {
        type: "geojson",
        generateId: true,
        data: {
          type: "FeatureCollection",
          features: []
        }
      }
    },
    { id: 'extrusion-source',
      source: {
        type: 'vector',
        url: 'mapbox://am3081.15kbbw0s'
      }
    },
  {
    id: 'tmc_extended_2019',
    source: {
      type: 'vector',
      url: mb_tmc_metadata_2019.url
    }
  },
  {
    id: 'tmc_extended_2017_with_mpo',
    source: {
      type: 'vector',
      url: mb_tmc_metadata_2017.url
    }
  },
  {
    id: 'tmc_extended_2019_with_mpo',
    source: {
      type: 'vector',
      url: mb_tmc_metadata_2019.url
    }
  },
  {
    id: 'tmc_extended_2020_with_mpo',
    source: {
      type: 'vector',
      url: mb_tmc_metadata_2020.url
    }
  }
]

export const npmrdsPaint = {
  'line-color': '#ccc',
  'line-width': [
    "interpolate",
    ["linear"],
    ["zoom"],
    0,
    [
      "match",
      ["get", "n"],
      [1, 2],
      2,
      0
    ],
    13,
    [
      "match",
      ["get", "n"],
      [1, 2],
      3,
      1
    ],
    18,
    [
      "match",
      ["get", "n"],
      [1, 2],
      8,
      5
    ]
  ],
  'line-opacity': [
    "case",
    ["boolean", ["feature-state", "hover"], false],
    0.4,
    1
  ],
  'line-offset': {
    base: 1.5,
    stops: [[5, 0], [9, 1], [15, 3], [18, 7]]
  }
}

export const pm3Layers = [
/*  {
    id: 'npmrds-2019',
    type: 'line',
    source: 'tmc_extended_2019',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'visibility': 'visible',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: npmrdsPaint
  },{
    id: 'npmrds-2017',
    type: 'line',
    source: 'tmc_extended_2019',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'visibility': 'none',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: npmrdsPaint
  },
  {
    id: 'npmrds-2019',
    type: 'line',
    source: 'tmc_extended_2019_with_mpo',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'visibility': 'visible',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: npmrdsPaint
  },
  {
    id: 'npmrds-2017',
    type: 'line',
    source: 'tmc_extended_2017_with_mpo',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2017.layer,
    layout: {
      'visibility': 'none',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: npmrdsPaint
  }*/
  {
    id: 'npmrds-2019',
    type: 'line',
    source: 'tmc_extended_2019_with_mpo',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'visibility': 'visible',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: npmrdsPaint
  },
  {
    id: 'npmrds-2017',
    type: 'line',
    source: 'tmc_extended_2017_with_mpo',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2017.layer,
    layout: {
      'visibility': 'none',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: npmrdsPaint
  }
]
export const BASE_ROUTE_LAYER_LINE_COLOR = "#bbb"
const DEFAULT_ARGS = {
  'line-color': BASE_ROUTE_LAYER_LINE_COLOR,
  'line-width': 2,
  'width-diff': 2
}
const generateRouteLayerPaint = args => {
  args = {
    ...DEFAULT_ARGS,
    ...args
  }
  const bWidth = args['line-width'],
    dif = args['width-diff'];
  return {
    'line-color': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      "#2ca25f",
      args['line-color']
    ],
    'line-width': [
      "case",
      ["boolean", ["feature-state", "hover"], false],
      ["match", ["get", "f_system"], [1, 2], 14, 8],
      ["match", ["get", "f_system"], [1, 2], 7, 4]
    ],
    'line-offset': ["interpolate", ["linear"], ["zoom"], 5, 0, 18, 7]
  }
}
const BASE_WIDTH = 6;
export const  routesLayers = [
  {
    id: 'npmrds-routing',
    type: 'line',
    source: 'tmc_extended_2019_with_mpo',
    beneath: 'waterway-label',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'visibility': 'visible',
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: generateRouteLayerPaint({
      'line-width': BASE_WIDTH
    })
  },
  // {
  //   id: 'npmrds-2018-routing',
  //   type: 'line',
  //   source: 'tmc_extended_2019',
  //   beneath: 'waterway-label',
  //   'source-layer': mb_tmc_metadata_2019.layer,
  //   layout: {
  //     'visibility': 'visible',
  //     'line-join': 'round',
  //     'line-cap': 'round'
  //   },
  //   paint: generateRouteLayerPaint({
  //     'line-width': BASE_WIDTH
  //   })
  // },
  // {
  //   id: 'npmrds-2017-routing',
  //   type: 'line',
  //   source: 'tmc_extended_2019',
  //   beneath: 'waterway-label',
  //   'source-layer': mb_tmc_metadata_2019.layer,
  //   layout: {
  //     'visibility': 'visible',
  //     'line-join': 'round',
  //     'line-cap': 'round'
  //   },
  //   paint: generateRouteLayerPaint({
  //     'line-width': BASE_WIDTH
  //   })
  // }
]


export const layers = [
  {
    id: 'interstate-symbology-select',
    type: 'line',
    source: 'npmrds',
    'source-layer': 'tmcsAttrs',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#ccc',
      'line-width': {
        base: 1.5,
        stops: [[3, 7], [13, 13], [18, 30]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[5, 0], [9, 1], [15, 3], [18, 7]]
      }
    },
    filter: ['all', ['in', 'f_system', 1, 2], ['in', 'tmc', '']]
  },
  {
    id: 'interstate-symbology-select-2019',
    type: 'line',
    source: 'tmc_extended_2019',
    'source-layer': 'tmc_metadata_2019_shpver20190606',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#ccc',
      'line-width': {
        base: 1.5,
        stops: [[3, 7], [13, 13], [18, 30]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[5, 0], [9, 1], [15, 3], [18, 7]]
      }
    },
    filter: ['all', ['in', 'f_system', 1, 2], ['in', 'tmc', '']]
  },
  {
    id: 'primary-symbology-select',
    type: 'line',
    source: 'npmrds',
    'source-layer': 'tmcsAttrs',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#ccc',
      'line-width': {
        base: 1.5,
        stops: [[5, 7], [18, 18]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[10, 0.5], [18, 10]]
      }
    },
    filter: ['all', ['!in', 'f_system', 1, 2], ['in', 'tmc', '']]
  },
  {
    id: 'primary-symbology-select-2019',
    type: 'line',
    source: 'tmc_extended_2019',
    'source-layer': 'tmc_metadata_2019_shpver20190606',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#ccc',
      'line-width': {
        base: 1.5,
        stops: [[5, 7], [18, 18]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[10, 0.5], [18, 10]]
      }
    },
    filter: ['all', ['!in', 'f_system', 1, 2], ['in', 'tmc', '']]
  },
  {
    id: 'interstate-symbology',
    type: 'line',
    source: 'npmrds',
    'source-layer': 'tmcsAttrs',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#566881',
      'line-width': {
        base: 1.5,
        stops: [[3, 1], [13, 8], [18, 19]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[5, 0], [9, 1], [15, 2], [18, 4]]
      }
    },
    filter: ['all', ['in', 'f_system', 1, 2]]
  },
  {
    id: 'interstate-symbology-2019',
    type: 'line',
    source: 'tmc_extended_2019',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#566881',
      'line-width': {
        base: 1.5,
        stops: [[3, 1], [13, 8], [18, 19]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[5, 0], [9, 1], [15, 2], [18, 4]]
      }
    },
    filter: ['all', ['in', 'f_system', 1, 2]]
  },
  {
    id: 'primary-symbology',
    type: 'line',
    source: 'npmrds',
    'source-layer': 'tmcsAttrs',
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#303339',
      'line-width': {
        base: 1.5,
        stops: [[5, 1], [18, 10]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[10, 0.5], [18, 10]]
      }
    },
    filter: ['all', ['!in', 'f_system', 1, 2]]
  },
  {
    id: 'primary-symbology-2019',
    type: 'line',
    source: 'tmc_extended_2019',
    'source-layer': mb_tmc_metadata_2019.layer,
    layout: {
      'line-join': 'round',
      'line-cap': 'round'
    },
    paint: {
      'line-color': '#303339',
      'line-width': {
        base: 1.5,
        stops: [[5, 1], [18, 10]]
      },
      'line-offset': {
        base: 1.5,
        stops: [[10, 0.5], [18, 10]]
      }
    },
    filter: ['all', ['!in', 'f_system', 1, 2]]
  },
    { id: "transcom-layer",
      source: "transcom-source",
      type: "circle",
      paint: {
        "circle-color": "#900",
        "circle-radius": {
          stops: [[5, 2], [15, 22]]
        },
        "circle-pitch-alignment": "map"
      }
    },
]
