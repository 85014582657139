import get from 'lodash.get';
import memoizeOne from 'memoize-one';
import deepEqual from 'deep-equal';

import { precisionRound, toNumeric } from 'utils/calculators/MathUtils';
import { versionedTmcMetadataMap } from 'utils/constants';

import { queryVersionedTmcMetadata } from '../falcorGraphQueryBuilders';

// ===== TMC-LEVEL =====

const tmcAttrGetterBuilder = (metaCol, isNumeric) => (
  state,
  pm3VersionId,
  tmc
) => {
  const metadataFalcorPath = queryVersionedTmcMetadata(
    pm3VersionId,
    tmc,
    metaCol
  );

  const p = metadataFalcorPath && ['graph', ...metadataFalcorPath];
  const v = get(state, p, null);

  if (v === null) {
    return null;
  }

  return isNumeric ? toNumeric(v) : v;
};

export const getVersionedTmc = (state, pm3VersionId, tmc) => tmc;

export const getVersionedTmcRoadname = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcRoadname,
  false
);

export const getVersionedTmcAvgSpeedlimit = (state, pm3VersionId, tmc) => {
  const metadataFalcorPath = queryVersionedTmcMetadata(
    pm3VersionId,
    tmc,
    versionedTmcMetadataMap.tmcAvgSpeedlimit
  );

  const p = metadataFalcorPath && ['graph', ...metadataFalcorPath];
  const avgSpeedlimit = get(state, p);

  return +avgSpeedlimit || null;
};

export const getVersionedTmcLength = (state, pm3VersionId, tmc) => {
  const metadataFalcorPath = queryVersionedTmcMetadata(
    pm3VersionId,
    tmc,
    versionedTmcMetadataMap.tmcLength
  );

  const p = metadataFalcorPath && ['graph', ...metadataFalcorPath];

  const length = p && +get(state, p);

  return length ? precisionRound(+length, 4) : null;
};

export const getVersionedTmcDirection = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcDirection,
  false
);

export const getVersionedTmcAadt = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcAadt,
  true
);

export const getVersionedTmcAadtSingl = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcAadtSingl,
  true
);

export const getVersionedTmcAadtCombi = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcAadtCombi,
  true
);

export const getVersionedTmcFSystem = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcFSystem,
  true
);

export const getVersionedTmcFacilType = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcFacilType,
  true
);

export const getVersionedTmcStrucType = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcStrucType,
  true
);

export const getVersionedTmcThruLanes = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcThruLanes,
  true
);

export const getVersionedTmcRouteNumb = (state, pm3VersionId, tmc) => {
  const metadataFalcorPath = queryVersionedTmcMetadata(
    pm3VersionId,
    tmc,
    versionedTmcMetadataMap.tmcRouteNumb
  );

  const p = metadataFalcorPath && ['graph', ...metadataFalcorPath];
  const routenumb = get(state, p, null);

  return routenumb ? +routenumb : null;
};

export const getVersionedTmcRouteSign = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcRouteSign,
  true
);

export const getVersionedTmcRouteQual = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcRouteQual,
  true
);

export const getVersionedTmcAltRteName = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcAltRteName,
  false
);

export const getVersionedTmcNHS = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcNHS,
  true
);

export const getVersionedTmcNHSPct = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcNHSPct,
  true
);

export const getVersionedTmcStrhntTyp = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcStrhntTyp,
  true
);

export const getVersionedTmcStrhntPct = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcStrhntPct,
  true
);

export const getVersionedTmcTruck = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcTruck,
  true
);

export const getVersionedTmcLinear = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcLinear,
  true
);

export const isTmcControlledAccess = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcIsControlledAccess,
  false
);

export const getVersionedTmcCountyName = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcCountyName,
  false
);

export const getVersionedTmcCountyCode = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcCountyCode,
  false
);

export const getVersionedTmcMpoName = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcMpoName,
  false
);

export const getVersionedTmcMpoCode = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcMpoCode,
  false
);

export const getVersionedTmcUAName = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcUAName,
  false
);

export const getVersionedTmcUACode = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcUACode,
  false
);

export const getVersionedTmcStateCode = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcStateCode,
  false
);

export const getVersionedTmcStateName = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcStateName,
  false
);

export const getVersionedTmcState = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcState,
  false
);

export const getVersionedTmcIsPrimary = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcIsPrimary,
  true
);

export const getVersionedTmcIsNhsInterstate = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcIsNhsInterstate,
  true
);

export const getVersionedTmcIsNhsNonInterstate = tmcAttrGetterBuilder(
  versionedTmcMetadataMap.tmcIsNhsNonInterstate,
  true
);

export const getVersionedTmcBoundingBox = (state, pm3VersionId, tmc) => {
  const metadataFalcorPath = queryVersionedTmcMetadata(
    pm3VersionId,
    tmc,
    versionedTmcMetadataMap.tmcBoundingBox
  );

  const p = metadataFalcorPath && ['graph', ...metadataFalcorPath, 'value'];

  const bb = get(state, p, null);

  return bb;
};

const attr2getter = {
  tmc: getVersionedTmc,
  tmcAadt: getVersionedTmcAadt,
  tmcAadtCombi: getVersionedTmcAadtCombi,
  tmcAadtSingl: getVersionedTmcAadtSingl,
  tmcAltRteName: getVersionedTmcAltRteName,
  tmcAvgSpeedlimit: getVersionedTmcAvgSpeedlimit,
  tmcBoundingBox: getVersionedTmcBoundingBox,
  tmcCountyCode: getVersionedTmcCountyCode,
  tmcCountyName: getVersionedTmcCountyName,
  tmcDirection: getVersionedTmcDirection,
  tmcFSystem: getVersionedTmcFSystem,
  tmcFacilType: getVersionedTmcFacilType,
  tmcIsControlledAccess: isTmcControlledAccess,
  tmcLength: getVersionedTmcLength,
  tmcLinear: getVersionedTmcLinear,
  tmcMpoCode: getVersionedTmcMpoCode,
  tmcMpoName: getVersionedTmcMpoName,
  tmcNHS: getVersionedTmcNHS,
  tmcNHSPct: getVersionedTmcNHSPct,
  tmcRoadname: getVersionedTmcRoadname,
  tmcRouteNumb: getVersionedTmcRouteNumb,
  tmcRouteQual: getVersionedTmcRouteQual,
  tmcRouteSign: getVersionedTmcRouteSign,
  tmcState: getVersionedTmcState,
  tmcStateCode: getVersionedTmcStateCode,
  tmcStateName: getVersionedTmcStateName,
  tmcStrhntPct: getVersionedTmcStrhntPct,
  tmcStrhntTyp: getVersionedTmcStrhntTyp,
  tmcStrucType: getVersionedTmcStrucType,
  tmcThruLanes: getVersionedTmcThruLanes,
  tmcTruck: getVersionedTmcTruck,
  tmcUACode: getVersionedTmcUACode,
  tmcUAName: getVersionedTmcUAName,
  tmcIsPrimary: getVersionedTmcIsPrimary,
  tmcIsNhsInterstate: getVersionedTmcIsNhsInterstate,
  tmcIsNhsNonInterstate: getVersionedTmcIsNhsNonInterstate
};

// Quick & dirty way to keep versionedTmcMetadata from causing rerenders.
const _versionedTmcMetadataIdentityMemoizer = memoizeOne(
  attrs => attrs,
  deepEqual
);

export const getVersionedTmcMetadata = (state, pm3VersionId, tmcs) => {
  if (!(pm3VersionId && tmcs)) {
    return null;
  }

  const isArr = Array.isArray(tmcs);
  const tmcsArr = isArr ? tmcs : [tmcs];

  const versionedTmcMetadata = tmcsArr.map(tmc =>
    Object.keys(attr2getter).reduce(
      (acc, metaCol) => {
        const v = attr2getter[metaCol](state, pm3VersionId, tmc);
        if (v !== null && Number.isNaN(v)) {
          return acc;
        }
        acc[metaCol] = v;
        return acc;
      },
      { tmc }
    )
  );

  return _versionedTmcMetadataIdentityMemoizer(
    isArr ? versionedTmcMetadata : versionedTmcMetadata[0]
  );
};
