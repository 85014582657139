import React from "react"

import styled from "styled-components"

const StyledFolderIcon = styled.div`
  width: ${ props => props.size }rem;
  height: ${ props => props.size }rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: ${ props => props.size * 0.9 }rem;

  > span:first-child {
    color: #f1d592;
  }

  > div:nth-child(2) {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: ${ props => props.size * 0.06 }rem;
    font-size: ${ props => props.size * 0.4 }rem;
  }
`
export default ({ icon, color, size = 6 }) =>
  <StyledFolderIcon size={ size }>
    <span className="fa fa-folder"/>
    <div>
      <span className={ icon } style={ { color: color } }/>
    </div>
  </StyledFolderIcon>
