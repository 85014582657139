import React from "react"
import { connect } from "react-redux"

import get from "lodash.get"

import styled from "styled-components"

import { VertDots } from "components/common/icons"

import { hexColorToRgb } from "components/tmc_graphs/utils"

import {
	ControlBox,
	Control,
	DropDown,
	DoubleDropdown
} from "./components/parts"

import {
	DragDropContext,
	Droppable,
	Draggable
} from 'react-beautiful-dnd';

export const Header = styled.div`
	flex-grow: 1;
	display: flex;
	border-bottom: 2px solid ${ props => props.theme.textColorHl };
	h4 {
		margin-bottom: 0px;
		color: ${ props => props.theme.textColorHl };
		font-size: 1.5rem;
		font-weight: bold;
	}
	span.fa {
		color: ${ props => props.theme.textColorHl };
		font-size: 18px;
		border-radius: 4px;
		padding: 5px 4px 3px 5px;
		transition: background-color 0.15s;
	}
`
const OpenCloseButton = styled.div`
	width: 30px;
	span.fa {
		padding: 5px;
	}
	:hover span.fa {
		color: ${ props => props.theme.sidePanelBg };
		background-color: ${ props => props.theme.textColorHl };
	}
`

const ActiveRouteItem = styled.div`
	color: ${ props => props.color || props.theme.textColor };
	padding: 3px 10px 3px 26px;
	border-radius: 4px;
	cursor: pointer;
	float: left;
	width: 100%;
	transition: background-color 0.15s;
	position: relative;

	:hover {
		background-color: ${ props => props.hoverColor };
		font-weight: bold;
	}
`

const Icon = styled.span`
	color: ${ props => props.theme.textColor };
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
	transition: color 0.15s,
		background-color 0.15s;

	:hover {
		color: ${ props => props.theme.sidePanelBg };
		background-color: ${ props => props.theme.textColor };
	}
`

// const RouteSelectorContainer = styled.div`
// 	border-bottom: 2px solid ${ props => props.theme.textColorHl };
//
// 	.item-selector__dropdown .list__item__anchor {
// 		color: ${ props => props.theme.textColorHl };
// 		font-size: 1rem;
// 	}
// `

class ActiveRouteComponents extends React.Component {
	state = {
		openCompId: null,
		open: true
	}
	extendSidebar(openCompId) {
		this.props.extendSidebar(openCompId)
		this.setState({ openCompId })
	}
	add(e, routeId) {
		e.stopPropagation();
		this.props.add(routeId);
	}
	remove(e, compId) {
		e.stopPropagation();
		if (compId === this.state.openCompId) {
			this.setState({ openCompId: null });
		}
		this.props.remove(compId);
	}
	onDragEnd({ source, destination }) {
		if (destination === null) return;
		if (source.index === destination.index) return;

		this.props.reorderRouteComps(source.index, destination.index);
	}
	render() {
		const foldersWithRoutes = this.props.folders.filter(f =>
			f.stuff.reduce((a, c) => a || c.stuff_type === "collection", false)
		)
		const folderData = foldersWithRoutes.map(f =>
			[f.name,
				f.stuff.filter(s => s.stuff_type === "collection")
					.map(s => ({ id: s.stuff_id, name: get(this.props.routesGraph, ["byId", s.stuff_id, "name"], s.stuff_id) }))
			]
		)

// console.log("availableRoutes", this.props.availableRoutes)
		return (
			<div style={ {
				padding: "10px",
				whiteSpace: "nowrap",
				display: "flex",
				flexDirection: "column"
			} }>

				<Header>
					<OpenCloseButton>
						<span onClick={ e => {
							e.stopPropagation();
							this.setState(state => ({ open: !state.open }));
						} } className={ `fa fa-${ this.state.open ? "minus" : "plus" }` }/>
					</OpenCloseButton>
					<h4>Routes</h4>
				</Header>

				<div style={ {
					height: this.state.open ? "auto" : "0px",
					overflow: this.state.open ? "visible" : "hidden",
					flexGrow: 1
				} }>

					<div style={ {
						borderBottom: `2px solid currentColor`
					} }>
						<ControlBox>
							<DropDown className="control"
		  					searchable={ true }
		  					displayOption={ d => d.name }
		  					getOptionValue={ d => d.id }
								onChange={ id => this.props.add(id) }
								options={ this.props.availableRoutes.sort((a, b) => a.name < b.name ? -1 : 1) }>
								<span className="fa fa-road"/>
								<span>Routes</span>
							</DropDown>
							<DoubleDropdown className="control"
								data={ folderData }
								disabled={ !folderData.length }
								onSelect={ ids => this.props.add(ids) }>
								<span className="fa fa-folder"/>
								<span>Folders</span>
							</DoubleDropdown>
						</ControlBox>
					</div>

					<div style={ { position: "relative" } }>
						<DragDropContext onDragEnd={ this.onDragEnd.bind(this) }>
							<Droppable droppableId="drop-area">
								{ (provided, snapshot) => (

									<div ref={ provided.innerRef }
										{ ...provided.droppableProps }
										style={ { background: snapshot.isDraggingOver ? "#555" : "none" } }>

			    					{
											this.props.route_comps.map((route, i) =>
												<Draggable key={ route.compId } index={ i } draggableId={ route.compId }>
													{ (provided, snapshot) => (
														<div ref={ provided.innerRef }
															{ ...provided.draggableProps }>
															<div style={ { height: "27px", position: "relative" } }>
																<ActiveRouteItem
																	color={ route.color }
																	hoverColor={ hexColorToRgb(route.color, 0.5) }
																	onClick={ e => route.isValid && this.extendSidebar(route.compId) }>
																	<div style={ { width: "200px", overflow: "hidden" } }>
																		{ route.name }
																	</div>
																</ActiveRouteItem>
																<DragHandle { ...provided.dragHandleProps }
																	className={ snapshot.isDragging ? "isDragging" : "" }
																	color={ route.color }>
																	<VertDots height="24px"/>
																</DragHandle>
																<div style={ { position: "absolute", top: "2px", right: "10px" } }>
																	<Icon className="fa fa-plus"
																		onClick={ e => route.isValid && this.add(e, route.routeId) }/>
																	<Icon className="fa fa-minus"
																		onClick={ e => this.remove(e, route.compId) }/>
																</div>
															</div>

														</div>
													) }
												</Draggable>
											)
										}

										{ provided.placeholder }

									</div>
								) }
							</Droppable>
						</DragDropContext>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	routesGraph: state.graph.routes
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(ActiveRouteComponents)

const DragHandle = styled.div`
	position: absolute;
	top: 0px;
	left: 0px;
	color: ${ props => props.color };
	height: 24px;
	border-radius: 4px;
	transition: color 0.15s, background-color 0.15s;

	:hover,
	&.isDragging {
		background-color: ${ props => props.color };
		color: ${ props => props.theme.sidePanelBg };
	}
`
