// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import BinnedTimeUtils from 'utils/calculators/BinnedTimeUtils';
import { precisionRound } from 'utils/calculators/MathUtils';
import { daysOfWeek } from 'utils/constants';

const formatData = (ttAvgsByBinByDow, binMinutes) => {
  const months = ttAvgsByBinByDow && Object.keys(ttAvgsByBinByDow);
  if (!(months && months.length)) {
    return null;
  }

  const binNumSet = new Set();
  const dowSet = new Set();

  Object.keys(ttAvgsByBinByDow).forEach(dow => {
    dowSet.add(+dow);
    Object.keys(ttAvgsByBinByDow[dow]).forEach(binNum =>
      binNumSet.add(+binNum)
    );
  });

  const seenBinNums = [...binNumSet].sort();
  const seenDows = [...dowSet].sort();

  const x = [];
  for (let i = 0; i < seenDows.length; ++i) {
    const dow = seenDows[i];
    const ddd = daysOfWeek[dow];

    for (let j = 0; j < seenBinNums.length; ++j) {
      const hhmm = BinnedTimeUtils.binNumToTime(binMinutes, seenBinNums[j]);
      x.push(`${ddd} ${hhmm}`);
    }
  }

  const y = Array(x.length).fill(null);
  const dows = Object.keys(ttAvgsByBinByDow);
  for (let i = 0; i < dows.length; ++i) {
    const dow = +dows[i];
    const binNums = Object.keys(ttAvgsByBinByDow[dow]);
    for (let j = 0; j < binNums.length; ++j) {
      const binNum = +binNums[j];
      const idx =
        seenDows.indexOf(dow) * seenBinNums.length +
        seenBinNums.indexOf(binNum);
      y[idx] = ttAvgsByBinByDow[dow][binNum];
    }
  }

  return { x, y };
};

const getViz = (ttAvgsByBinByDow, freeFlowTravelTime, binMinutes) => {
  const { x, y } = formatData(ttAvgsByBinByDow, binMinutes) || {};

  if (!x) {
    return null;
  }

  const ffTT = precisionRound(freeFlowTravelTime);

  const data = [
    {
      x,
      y,
      name: 'avg travel time',
      type: 'scatter',
      line: { width: 3 }
    }
  ];

  const annotations = [
    {
      text: `Free-Flow (${ffTT} sec)`,
      xref: 'paper',
      x: 1,
      yref: 'y',
      y: ffTT
    }
  ];

  const shapes = [
    {
      type: 'line',
      xref: 'paper',
      x0: 0,
      x1: 1,
      yref: 'y',
      y0: ffTT,
      y1: ffTT
    }
  ];

  const layout = {
    title: 'Average Travel Times Across Week By Month',
    xaxis: {
      title: 'Time of Week'
    },
    yaxis: {
      title: 'Average Travel Time'
    },
    annotations,
    shapes
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return <Plot data={data} layout={layout} config={config} />;
};

const TravelTimeIndex95PercentilesByBinByDow = ({ data }) => {
  const { freeFlowTravelTime, ttAvgsByBinByDow, binMinutes } = data;

  return ttAvgsByBinByDow ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        {getViz(ttAvgsByBinByDow, freeFlowTravelTime, binMinutes)}
      </div>
    </div>
  ) : null;
};

export default TravelTimeIndex95PercentilesByBinByDow;
