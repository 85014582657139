import {push, replace} from 'connected-react-router';

import {getAvailableSubMeasuresForVersionId} from '../store';

import {createUrl, parseUrl} from '../urlUtils';

export const navigateToGeoMeasureVisualizer = params => (
  dispatch,
  getState
) => {
  const {
    router: {
      location: {pathname, search}
    }
  } = getState();

  const curUrl = `${pathname}${search ? `?${search}` : ''}`;
  const newUrl = createUrl(params);

  return curUrl !== newUrl
    ? new Promise(resolve => {
      dispatch(push(newUrl));
      resolve();
    })
    : Promise.resolve();
};

export const updateGeoMeasureVisualizerUrl = updatedParams => (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    router: {location}
  } = state;
  // TODO: This logic belongs in a state chart.
  const curUrlParams = parseUrl(location);

  const newUrlParams = {
    ...curUrlParams,
    ...updatedParams
  };

  const {pm3VersionId: curPm3VersionId = null} = curUrlParams || {};
  const {pm3VersionId: newPm3VersionId = null} = newUrlParams || {};

  if (curPm3VersionId !== newPm3VersionId && newUrlParams.measure) {
    const availableSubMeasures = getAvailableSubMeasuresForVersionId(
      state,
      newPm3VersionId
    );

    if (
      !(
        availableSubMeasures &&
        availableSubMeasures.includes(newUrlParams.measure)
      )
    ) {
      newUrlParams.measure = null;
    }
  }

  const curUrl = createUrl(curUrlParams);
  const newUrl = createUrl(newUrlParams);

  return curUrl !== newUrl
    ? new Promise(resolve => {
      dispatch(replace(newUrl));
      resolve();
    })
    : Promise.resolve();
};
