// -- Landing Routes
import Landing from "pages/Landing";

// import Login from 'pages/Landing/Login';
// import ResetPassword from 'pages/Landing/ResetPassword';
// import SignUp from 'pages/Landing/SignUp';
// import Logout from 'pages/Logout';

import Auth from "pages/ams";

import NoMatch from "pages/404.js";
// import ResetPassword from 'pages/Landing/ResetPassword';

// IMPORTS
// import NAT from "pages/auth/NAT";
// import Freight from 'pages/auth/Freight';
import Home from "pages/auth/Home";
import MacroView from "pages/auth/NAT/macroview";
// import OsmView from 'pages/auth/NAT/osmView';

// import GeoMeasureVisualizer from "pages/auth/GeoMeasureVisualizer";
import MeasureVisualizer from "pages/auth/MeasureVisualizer";
// import MacroOld from 'pages/auth/MacroView'

import PM4 from "pages/auth/pm3";
import Reports from "pages/auth/Routes/reports";
import RouteAnalysis from "pages/auth/Routes/analysis";
import Routes from "pages/auth/Routes/routes";
import Templates from "pages/auth/Routes/templates";

import Conflation from "pages/auth/conflation"; // Route Creation

import Counts from "pages/auth/conflation/counts";
import Map from "pages/auth/Map";
import TransitMap from "pages/auth/Map/TransitMap";
import ReplicaMap from "pages/auth/Map/ReplicaTest";

//import TestPage from 'pages/test';

import PasswordSet from "pages/Landing/PasswordSet";

import MyStuff from "pages/auth/MyStuff";
import Incidents from "pages/auth/IncidentsNew";

import DocsView from "pages/auth/Docs/view";
// import ResetPassword from 'pages/Landing/ResetPassword';
// import MacroOld from 'pages/auth/MacroView'

//import ConflationRouting from "pages/auth/conflation_routing";
//import Incidents3 from "pages/auth/incidents3";

import NHTS from "pages/auth/NHTS";

//import Isochrone from "pages/auth/isochrone";


//import ExcessiveDelay from "pages/auth/excessive_delay"
// import RecurrentDelay from "pages/auth/recurrent_delay"

//import GraphWalk from "pages/auth/graph_walk"

// import HERE from "pages/auth/here_data"

const routes = [
    // ...TestPage,

    Landing,
    Home,
    MyStuff,
    ReplicaMap,

    //ExcessiveDelay,
    //RecurrentDelay,
    //GraphWalk,

    Auth,
    Routes,
    Reports,
    ...RouteAnalysis,
    Templates,

    //...PM3,
    PM4,
    ...MeasureVisualizer,
    //...GeoMeasureVisualizer,

    PasswordSet,

    //ConflationRouting,
    //...Incidents3,

    // HERE,

    //NAT,
    MacroView,
    Map,
    TransitMap,
    //Isochrone,
    // ReplicaMap,

    // Freight,
    // OsmView,
    ...Conflation,
    Counts,
    //...Transit,
    //...Incidents,
    NHTS,
    ...DocsView,

    NoMatch,
];

export default routes;
