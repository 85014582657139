import get from 'lodash.get';
import uniq from 'lodash.uniq';
import memoizeOne from 'memoize-one';
import deepEqual from 'deep-equal';

import {numbersComparator} from 'utils/calculators/MathUtils';

import {
  queryPM3TmcLevelMeasureHierarchy,
  queryPM3MeasuresTmcLevelVersionsMetadata,
  queryPM3MeasuresGeoLevelVersionsMetadata,
  queryTmcsInGeographyForPm3Versions,
  queryVersionedGeoMetadataAggregateRoadStats,
  queryGtfsTransitAgencyIds,
  queryGtfsConflationYears,
  queryConflationMapIdsForGtfsTransitAgency,
} from '../falcorGraphQueryBuilders';

export * from './tmcAttributesSelectors';
export * from './versionedTmcMetadataSelectors';

export const npmrdsDataSelector = (state, tmc, year, dataSource) => {
  if (!(state && tmc && year && dataSource)) {
    console.error(
      'ERROR: state, tmc, year, and dataSource are required parameters. Given:',
      {state, tmc, year, dataSource}
    );
  }

  return get(
    state,
    ['graph', 'tmc', tmc, 'year', year, 'npmrds', dataSource, 'value'],
    null
  );
};

export const getMeasureInfo = state =>
  get(state, ['graph', 'pm3', 'measureInfo'], null);

export const measureInfoSelector = (state, measure) =>
  get(state, ['graph', 'pm3', 'measureInfo', measure], {});

export const getMeasureSpec = (state, measure) =>
  get(state, ['graph', 'pm3', 'measureSpec', measure, 'value']);

export const getTrafficDistributionProfiles = state =>
  get(state, ['graph', 'trafficDistributions', 'profiles', 'value']);

export const getTrafficDistributionDOWAdjFactors = state =>
  get(state, ['graph', 'trafficDistributions', 'dowAdjFactors', 'value']);

// ===== GEO-LEVEL =====

// Row cols: geolevel, geoid, geoname, bounding_box, states
// NOTE: stateCode can be an array of stateCodes
export const getGeoLevelsForStateCodes = (state, stateCodes) => {
  if (!stateCodes) {
    return null;
  }

  const stateCodeArr = Array.isArray(stateCodes) ? stateCodes : [stateCodes];

  return Array.prototype.concat(
    ...stateCodeArr
      .map(sc => get(state, ['graph', 'geo', sc, 'geoLevels', 'value']))
      .filter(g => g)
  );
};

export const getGeographyBoundingBox = (state, stateCode, geolevel, geoid) => {
  if (!stateCode) {
    return null;
  }

  const stateCodeArr = Array.isArray(stateCode) ? stateCode : [stateCode];

  for (let i = 0; i < stateCodeArr.length; ++i) {
    const sc = stateCodeArr[i];
    const geoLevels = get(
      state,
      ['graph', 'geo', sc, 'geoLevels', 'value'],
      null
    );

    if (!geoLevels) {
      return null;
    }

    const geoLevelInfo = geoLevels.find(
      ({geolevel: gl, geoid: gid}) => gl === geolevel && gid === geoid
    );

    const {bounding_box} = geoLevelInfo || {};

    if (bounding_box) {
      return bounding_box;
    }
  }

  return null;
};

export const getGeographyInformation = memoizeOne(
  (state, stateCode, geolevel, geoid) => {
    if (!(geolevel && geoid)) {
      return null;
    }

    const geoInfosByStateCode = get(state, ['graph', 'geo'], {});

    const stateCodeArr =
      stateCode && (Array.isArray(stateCode) ? stateCode : [stateCode]);

    const statesToSearch = stateCodeArr || Object.keys(geoInfosByStateCode);

    for (let i = 0; i < statesToSearch.length; ++i) {
      const sc = statesToSearch[i];

      const geoLevels = get(
        geoInfosByStateCode,
        [sc, 'geoLevels', 'value'],
        null
      );

      if (geoLevels) {
        // Get the full interstate geography
        const [geoInfo] = geoLevels
          .filter(
            ({geolevel: gl, geoid: gid}) => gl === geolevel && gid === geoid
          )
          // sort in descending order by length of states array
          .sort((a, b) => {
            const aNumStateCodes = (a && a.states && a.states.length) || 0;
            const bNumStateCodes = (b && b.states && b.states.length) || 0;

            return bNumStateCodes - aNumStateCodes;
          });

        if (geoInfo) {
          return geoInfo;
        }
      }
    }

    return null;
  }
);

export const getGeographyName = memoizeOne(
  (state, stateCode, geolevel, geoid) => {
    if (!stateCode) {
      return null;
    }

    const geoInfo = getGeographyInformation(state, stateCode, geolevel, geoid);

    return (geoInfo && geoInfo.geoname) || null;
  }
);

export const getGeoAttributes = (state, geoLevel, geoId, geoStates, year) => {
  const attrsArr = get(
    state,
    ['graph', 'geoAttributes', `${geoLevel}|${geoId}`, year, 'value'],
    null
  );

  if (!Array.isArray(attrsArr)) {
    return null;
  }

  const toMatch = geoStates.slice().sort(numbersComparator);

  return attrsArr.find(({states}) =>
    deepEqual(states.slice().sort(numbersComparator), toMatch)
  );
};

export const getGeoAttributes2 = (state, geoLevel, geoId, year) =>
  get(
    state,
    ['graph', 'geoAttributes', `${geoLevel}|${geoId}`, year, 'value'],
    null
  );

export const getPM3TmcLevelMeasureHierarchy = state =>
  get(state, ['graph', ...queryPM3TmcLevelMeasureHierarchy(), 'value'], null);



export const getPM3MeasuresTmcLevelVersionsMetadata = state =>
  get(
    state,
    ['graph', ...queryPM3MeasuresTmcLevelVersionsMetadata(), 'value'],
    null
  );

export const getPM3MeasuresGeoLevelVersionsMetadata = state =>
  get(
    state,
    ['graph', ...queryPM3MeasuresGeoLevelVersionsMetadata(), 'value'],
    null
  );

export const getTmcsInGeographyForPm3Versions = (
  state,
  versionId,
  geolevel,
  geoid
) =>
  versionId && geolevel && geoid
    ? get(
      state,
      [
        'graph',
        ...queryTmcsInGeographyForPm3Versions(versionId, geolevel, geoid),
        'value'
      ],
      null
    )
    : null;

export const getVersionedGeoMetadataAggregateRoadStats = (
  state,
  versionId,
  geolevel,
  geoid
) =>
  versionId && geolevel && geoid
    ? get(
      state,
      [
        'graph',
        ...queryVersionedGeoMetadataAggregateRoadStats(
          versionId,
          geolevel,
          geoid
        ),
        'value'
      ],
      null
    )
    : null;

// Send memoizeOne the deepest possible node in the falcor graph to maximize memoization.
const _pm3MeasureVersionForTmcs = memoizeOne((measuresByTmc, tmcs, measure) =>
  (Array.isArray(tmcs) ? tmcs : [tmcs]).reduce((acc, tmc) => {
    const m = get(measuresByTmc, [tmc, measure]);
    acc[tmc] = m === null || Number.isNaN(+m) ? m : +m;
    return acc;
  }, {})
);

export const getPM3MeasureVersionForTmcs = (
  state,
  versionId,
  tmcs,
  measure
) => {
  const measuresByTmc = get(state, [
    'graph',
    'pm3',
    'versionedCalculations',
    'version',
    versionId,
    'tmc'
  ]);

  if (!measuresByTmc) {
    return null;
  }

  return _pm3MeasureVersionForTmcs(measuresByTmc, tmcs, measure);
};

export const getPM3MeasuresForGeosByVersion = state => {
  // `pm3.versionedCalculations.version[{keys:versionIds}].geolevel[{keys:syntheticGeoKeys}]

  const measuresByVersion = get(state, [
    'graph',
    'pm3',
    'versionedCalculations',
    'version'
  ]);

  if (!measuresByVersion) {
    return null;
  }

  return Object.keys(measuresByVersion).reduce((acc, pm3VersionId) => {
    const versionedMeasuresByGeoKey = get(measuresByVersion, [
      pm3VersionId,
      'geolevel'
    ]);

    if (!versionedMeasuresByGeoKey) {
      return acc;
    }

    const syntheticGeoKeys = Object.keys(versionedMeasuresByGeoKey);

    for (let i = 0; i < syntheticGeoKeys.length; ++i) {
      const syntheticGeoKey = syntheticGeoKeys[i];

      const {value: measures} = versionedMeasuresByGeoKey[syntheticGeoKey];

      const [geoLevel, geoId] = syntheticGeoKey.split('|');

      if (!(measures && geoLevel && geoId)) {
        return acc;
      }

      acc[pm3VersionId] = acc[pm3VersionId] || {};
      acc[pm3VersionId][geoLevel] = acc[pm3VersionId][geoLevel] || {};
      acc[pm3VersionId][geoLevel][geoId] = measures;
    }

    return acc;
  }, {});
};

// Remove the Falcoresque details such as $atom types and errors and return a clean POJO
export const getRoutesInfoArr = state => {
  const routesInfo = {};
  const routesById = get(state, ['graph', 'routes', 'byId'], {});

  const ids = Object.keys(routesById);

  for (const id of ids) {
    const route = routesById[id];

    routesInfo[id] = {id};

    const fields = Object.keys(route);

    for (let j = 0; j < fields.length; ++j) {
      const k = fields[j];
      const v = route[k];

      if (v && v.type) {
        routesInfo[id][k] = v.type === 'error' ? null : v.value;
      } else {
        routesInfo[id][k] = v;
      }
    }
  }

  return routesInfo;
};

// Remove the Falcoresque details such as $atom types and errors and return a clean POJO
export const getReportsInfoArr = state => {
  const reportsInfo = {};
  const reportsById = get(state, ['graph', 'reports', 'byId'], {});

  const ids = Object.keys(reportsById);

  for (let i = 0; i < ids.length; ++i) {
    const id = ids[i];
    const report = reportsById[id];

    if (!report) continue;

    reportsInfo[id] = {id};

    const fields = Object.keys(report);

    for (let j = 0; j < fields.length; ++j) {
      const k = fields[j];
      const v = report[k];

      if (v && v.type) {
        reportsInfo[id][k] = v.type === 'error' ? null : v.value;
      } else {
        reportsInfo[id][k] = v;
      }
    }
  }

  return reportsInfo;
};

export const getGtfsTransitAgencyIds = (state) =>
  get(
    state,
    ['graph', ...queryGtfsTransitAgencyIds(), 'value'],
    null
  )

export const getGtfsConflationYears = (state) =>
  get(
    state,
    ['graph', ...queryGtfsConflationYears(), 'value'],
    null
  )

export const getConflationMapIdsForGtfsTransitAgency = (state, gtfsAgency, year) =>
  get(
    state,
    ['graph', ...queryConflationMapIdsForGtfsTransitAgency(gtfsAgency, year), 'value'],
    null
  )

export const getGtfsConflationMetadata = memoizeOne(
  (state, ids, year, columns) => {

    if (
      !Array.isArray(ids) ||
      ids.length === 0 ||

      !year ||

      !Array.isArray(columns) ||
      columns.length === 0
    ) {
      return null
    }

    return ids.reduce((acc, id) => {
      for (let i = 0; i < columns.length; ++i) {
        const col = columns[i];

        // path: ['conflation', id, 'meta', year, pathKey]
        let v = get(state, ['graph', 'conflation', id, 'meta', year, col], null)

        if (v && v.value) {
          v = v.value
        }

        acc[id] = acc[id] || {}
        acc[id][col] = v
      }

      return acc
    }, {})
  },
  // Because we create a new object EVERY time the above function executes,
  //   we MUST make sure it executes ONLY when the data returned will be different.
  (newArgs, lastArgs) => {
    const [newState, newIds, newYear, newCols] = newArgs
    const [lastState, lastIds, lastYear, lastCols] = lastArgs

    const newColsEmpty = (!Array.isArray(newCols) || newCols.length === 0)
    const lastColsEmpty = (!Array.isArray(lastCols) || lastCols.length === 0)

    const newIdsEmpty = (!Array.isArray(newIds) || newIds.length === 0)
    const lastIdsEmpty = (!Array.isArray(lastIds) || lastIds.length === 0)

    // These return null.
    // This condtion will pass if BOTH idsArgs are empty, or BOTH colsArgs are empty
    if (
      (newColsEmpty && lastColsEmpty) ||
      (!newYear && !lastYear) ||
      (newIdsEmpty && lastIdsEmpty)
    ) {
      return true
    }

    // This condtion will pass if one idsArgs is empty and the other is not. Same with cols.
    if (
      newYear !== lastYear ||
      newColsEmpty !== lastColsEmpty ||
      newIdsEmpty !== lastIdsEmpty
    ) {
      return false
    }

    // We know at this point that both the ids and cols are non-empty
    //   and are respectively the same length

    const _newCols = newCols && uniq(newCols).sort()
    const _lastCols = lastCols && uniq(lastCols).sort()

    if (!deepEqual(_newCols, _lastCols)) {
      return false
    }

    const _newIds = newIds && uniq(newIds).sort();
    const _lastIds = lastIds && uniq(lastIds).sort()

    if (!deepEqual(_newIds, _lastIds)) {
      return false
    }

    // We know that the ids, year, and cols have not changed.
    //   We need to determine whether the data in the falcor graph
    //   changed for the requested ids, year, and cols.
    for (let i = 0; i < _newIds.length; ++i) {
      const id = _newIds[i]

      const _newMeta = get(newState, ['graph', 'conflation', id, 'meta', newYear])
      const _lastMeta = get(lastState, ['graph', 'conflation', id, 'meta', lastYear])

      for (let j = 0; j < _newCols.length; ++j) {
        const col = _newCols[j]

        if (get(_newMeta, [col]) !== get(_lastMeta, [col])) {
          return false
        }
      }
    }

    return true
  })
