import React, {Component} from 'react';
import {connect} from 'react-redux';
import {reduxFalcor} from 'utils/redux-falcor';

class ShpDownloaderModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.props.measureIds.forEach(f =>
            this.state[f] = false
        );

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        let missingMeasures = this.props.measureIds
            .filter(f => {
                let tmpListToCheck = [];
                this.props.peaks.forEach(peak => {
                    tmpListToCheck.push(...Object.keys(this.state).filter(f => f.includes('_' + peak)))
                })
                tmpListToCheck.push(...Object.keys(this.state).filter(f => this.props.measureListRaw.includes(f)))
                return !tmpListToCheck.includes(f)
            })
        if (e.target.name.includes('all_')) {
            let toCheck = e.target.name.split('all_')[1];
            console.log('to check', toCheck)
            let tmpListToCheck = [];
            if (toCheck === 'all') {
                let tmpObj = {};
                this.props.peaks.forEach(peak => {
                    tmpListToCheck.push(...Object.keys(this.state)/*.filter(f => f.includes('_' + peak))*/
                    )
                });

                tmpListToCheck.push(...Object.keys(this.state).filter(f => this.props.measureListRaw.includes(f)))
                tmpListToCheck.map(f => tmpObj[f] = e.target.checked);
                this.setState({...this.state, ...tmpObj})
            } else if (this.props.peaks.includes(toCheck)) {
                // all measures with this peak
                let tmpObj = {};
                tmpListToCheck = Object.keys(this.state).filter(f => {
                    let tmpF = f.split('_')
                    return tmpF[tmpF.length-1] === toCheck
                });
                tmpListToCheck.map(f => tmpObj[f] = e.target.checked);
                this.setState({...this.state, ...tmpObj})
            } else if (this.props.measureListRaw.includes(toCheck) || missingMeasures.includes(toCheck)) {
                // this measure with all peaks
                let tmpObj = {};
                tmpListToCheck = Object.keys(this.state).filter(f => {
                    let boolCheck = false;
                    this.props.peaks.forEach(p => boolCheck = boolCheck || f.includes(toCheck + '_' + p) || f === toCheck);
                    return boolCheck;
                });
                tmpListToCheck.map(f => tmpObj[f] = e.target.checked);
                this.setState({...this.state, ...tmpObj})
            }
        } else if (e.target.name === 'full_measures') {
            let tmpObj = {},
                tmpListToCheck = [];

            tmpListToCheck.push(...missingMeasures.filter(mlr => Object.keys(this.state).includes(mlr)));
            tmpListToCheck.push(...this.props.measureListRaw.filter(mlr => Object.keys(this.state).includes(mlr)));
            tmpListToCheck.map(f => tmpObj[f] = e.target.checked);

            this.setState({...this.state, ...tmpObj})
        } else {
            this.setState({[e.target.name]: e.target.checked})
        }
    }

    render() {
        console.log('this.state',this.props.type)
        let missingMeasures = this.props.measureIds
            .filter(f => {
                let tmpListToCheck = [];
                this.props.peaks.forEach(peak => {
                    tmpListToCheck.push(...Object.keys(this.state).filter(f => f.includes('_' + peak)))
                })
                tmpListToCheck.push(...Object.keys(this.state).filter(f => this.props.measureListRaw.includes(f)))
                return !tmpListToCheck.includes(f)
            })

        return (
            <div aria-labelledby="mySmallModalLabel" className="modal fade bd-example-modal-sm show" role="dialog"
                 id={'closeMe'+this.props.type}
                 tabIndex="1" style={{'display': 'none', backgroundColor: '#c4bfbf7a'}} aria-hidden="true">
                <div className="modal-dialog modal-centered" style={{marginLeft: '20vw'}}>
                    <div className="modal-content" style={{backgroundColor: '#242730', width: '61vw', height:'90vh'}}>
                        <div className="modal-header"><h6 className="modal-title" style={{color: '#efefef'}}>Select
                            Measures</h6>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"
                                    onClick={(e) => {
                                        console.log('cancel button', e.target.closest('#closeMe'+this.props.type).style.display = 'none')
                                    }}>
                                <span aria-hidden="true"> ×</span></button>
                        </div>
                        <div className="modal-body" style={{maxWidth: '100%', maxHeight: '83%'}}>
                            <div className='table EpNgF'>
                                <table>
                                    <thead>
                                    <th></th>
                                    <th> All_Measures</th>
                                    {this.props.measureListRaw.map(f => <th> {f} </th>)}
                                    {missingMeasures.map(f => <th> {f} </th>)}
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th> All Peaks</th>
                                        <th>
                                            <input className='form-check-input'
                                                   type='checkbox'
                                                   name={'all' + '_' + 'all'}
                                                   onChange={this.handleChange}
                                                   style={{marginLeft: '50%', position: 'relative'}}
                                            />
                                        </th>
                                        {this.props.measureListRaw.map(f =>
                                            <td>

                                                <input className='form-check-input'
                                                       type='checkbox'
                                                       name={'all' + '_' + f}
                                                       onChange={this.handleChange}
                                                       style={{marginLeft: '50%', position: 'relative'}}
                                                />

                                            </td>
                                        )}
                                        {missingMeasures.map(f =>
                                            <td>

                                                <input className='form-check-input'
                                                       type='checkbox'
                                                       name={'all' + '_' + f}
                                                       onChange={this.handleChange}
                                                       style={{marginLeft: '50%', position: 'relative'}}
                                                />

                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <th> Full Measure</th>
                                        <td>

                                            <input className='form-check-input'
                                                   type='checkbox'
                                                   name={'full_measures'}
                                                   onChange={this.handleChange}
                                                   style={{marginLeft: '50%', position: 'relative'}}
                                            />

                                        </td>
                                        {this.props.measureListRaw.map(f =>
                                            <td>

                                                <input className='form-check-input'
                                                       type='checkbox'
                                                       name={f}
                                                       disabled={!this.props.measureIds.includes(f)}
                                                       checked={this.state[f]}
                                                       onChange={this.handleChange}
                                                       style={{marginLeft: '50%', position: 'relative'}}
                                                />

                                            </td>
                                        )}

                                        {missingMeasures.map(f =>
                                            <td>

                                                <input className='form-check-input'
                                                       type='checkbox'
                                                       name={f}
                                                       disabled={!this.props.measureIds.includes(f)}
                                                       checked={this.state[f]}
                                                       onChange={this.handleChange}
                                                       style={{marginLeft: '50%', position: 'relative'}}
                                                />

                                            </td>
                                        )}
                                    </tr>
                                    {this.props.peaks.map(peak =>
                                        <tr>
                                            <th> {peak.charAt(0).toUpperCase() + peak.slice(1)} Peak</th>
                                            <td>

                                                <input className='form-check-input'
                                                       type='checkbox'
                                                       name={'all' + '_' + peak}
                                                       onChange={this.handleChange}
                                                       style={{marginLeft: '50%', position: 'relative'}}
                                                />

                                            </td>
                                            {this.props.measureListRaw.map(f =>
                                                <td>

                                                    <input className='form-check-input'
                                                           type='checkbox'
                                                           name={f + '_' + peak}
                                                           disabled={!this.props.measureIds.includes(f + '_' + peak)}
                                                           checked={this.state[f + '_' + peak]}
                                                           onChange={this.handleChange}
                                                           style={{marginLeft: '50%', position: 'relative'}}
                                                    />

                                                </td>
                                            )}

                                            {missingMeasures.map(f =>
                                                <td>

                                                    <input className='form-check-input'
                                                           type='checkbox'
                                                           name={f + '_' + peak}
                                                           disabled={!this.props.measureIds.includes(f + '_' + peak)}
                                                           checked={this.state[f + '_' + peak]}
                                                           onChange={this.handleChange}
                                                           style={{marginLeft: '50%', position: 'relative'}}
                                                    />

                                                </td>
                                            )}
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            <button
                                id='ShpdownloadBtn'
                                style={{
                                    backgroundColor: '#29323c',
                                    color: '#efefef',
                                    padding: 10,
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontWeight: 400,
                                    float: 'right'
                                }}
                                data-loading-text="<i class='fa fa-spinner fa-spin '></i> Downloading..."
                                onClick=
                                    {
                                        () => this.props.onSubmit(this.props.activeTmcs, Object.keys(this.state).filter(f => this.state[f]), this.props.type)
                                    }

                            >Download {this.props.type}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(reduxFalcor(ShpDownloaderModal));