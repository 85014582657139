import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';

import pick from 'lodash.pick';
import deepEqual from 'deep-equal';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import ATRICalculator from 'utils/calculators/ATRICalculator';

import ATRIFreeFlow from './components/ATRIFreeFlow';
import ATRIHourlyMeanSpeeds from './components/ATRIHourlyMeanSpeeds';
import ATRIHourlyCongestionLineChart from './components/ATRIHourlyCongestionLineChart';
import ATRI from './components/ATRI';
import ATRIBibliography from './components/ATRIBibliography';

class ATRIBreakdown extends Component {
  state = { loading: false };
  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, dataSource, falcor }
    } = this;

    return tmc && year
      ? falcor.get(
          queryTmcAttributes(tmc, ATRICalculator.requiredTmcAttributes, year),
          queryNPMRDSDataForTmc(tmc, year, dataSource),
          queryMeasureRules(measure)
        )
      : Promise.resolve();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['year', 'dataSource', 'tmc', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });
      nextProps.falcor
        .get(
          queryTmcAttributes(
            nextProps.tmc,
            ATRICalculator.requiredTmcAttributes,
            nextProps.year
          ),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { atriCalculator } = this.props;

    return (
      <div>
        <p style={{ color: 'red', width: '62%' }}>
          Please Note: Elsewhere on this site, AVAIL offers this measure for
          specific vehicle types (all vehicle, passenger vehicles, single axle
          trucks, combination trucks, and all freight trucks). At this time,
          this page supports <i>only</i> the all vehicle calculation.
        </p>
        <blockquote
          style={{
            width: '38%',
            marginLeft: 20,
            backgroundColor: '#F2F4F8',
            padding: 12
          }}
        >
          The “total freight congestion value” is calculated using a formula
          that measures the impact of congestion on average commercial truck
          speeds in each study area. This process assesses freight demand on
          each roadway segment in a monitored location by hour of the day. Truck
          speeds on each segment are collected and assigned to one of 24
          one-hour time slots resulting in an average truck speed for each
          segment during each hour of the day. The “total freight congestion
          value” does not directly represent hours lost, or financial costs due
          to this delay, but is simply a means by which the researchers analyze
          and compare the relative level of severity at each individual
          location.
          <sup>1</sup>
        </blockquote>
        <h5 style={{ marginTop: 50 }}>ATRI Free-Flow Speed Calculation</h5>
        <ATRIFreeFlow data={atriCalculator} />
        <h5 style={{ marginTop: 50 }}>Speeds By Hour Analysis</h5>
        <ATRIHourlyMeanSpeeds data={atriCalculator} />
        <h5 style={{ marginTop: 50 }}>Hourly Congestion Values</h5>
        <ATRIHourlyCongestionLineChart data={atriCalculator} />
        <h5 style={{ marginTop: 50 }}>Total Congestion Value</h5>
        <ATRI data={atriCalculator} />
        <hr />
        <h5 style={{ marginTop: 50 }}>Bibliography</h5>
        <ATRIBibliography />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tmc, year, binMinutes, measure, dataSource } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);
  // const freeFlowMeasureSpec = getMeasureSpec(state, 'freeflow');

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const atriCalculator = new ATRICalculator({
    tmc,
    year,
    npmrdsData,
    tmcAttributes,
    binMinutes,
    measureSpec,
    dataSource
    // freeFlowMeasureSpec
  });

  return {
    atriCalculator
  };
};

export default connect(mapStateToProps)(reduxFalcor(ATRIBreakdown));
