import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxFalcor } from "utils/redux-falcor";

import { measureInfoSelector } from "store/falcorGraphSelectors";

// Falcor Route:
//   `pm3.measureInfo.[{keys:measureIds}]['fullname', 'definition', 'equation', 'source']`,
class MeasureInfoBox extends Component {
  static defaultProps = {
    excludeFullNameInInfoBox: true,
  };

  fetchFalcorDeps() {
    const {
      props: { falcor, layer },
    } = this;

    return falcor.get([
          "pm3",
          "measureInfo",
          this.props.measure,
          ["fullname", "definition", "equation", "source"],
        ])
      ;
  }

  componentDidUpdate() {
    this.fetchFalcorDeps();
  }

  render() {
    console.log("measure info box");
    const {
      measureInfo: {
        fullname = "",
        definition = "",
        equation = null,
        peaks = null,
      } = {},
      excludeFullNameInInfoBox,
    } = this.props;

    const H6 = {
      color: "#efefef",
      margin: "0.5rem 0 0.1rem 0",
    };
    const DD = {
      margin: 0,
    };

    return definition ? (
      <div style={{ padding: "0 7px 7px 7px" }}>
        MEasure: {this.props.measure}
        <dl style={{ margin: 0 }}>
          {!excludeFullNameInInfoBox && <dt>Measure</dt>}
          {!excludeFullNameInInfoBox && <dd style={DD}>{fullname}</dd>}

          {definition && (
            <dt>
              <h6 style={H6}>Definition</h6>
            </dt>
          )}
          {definition && <dd style={DD}>{definition}</dd>}

          {equation && (
            <dt>
              <h6 style={H6}>Equation</h6>
            </dt>
          )}
          {equation && <dd style={DD}>{equation}</dd>}

          {peaks && <dt>Measure Defined Peaks or time periods</dt>}
          {peaks && <dd style={DD}>{peaks}</dd>}
        </dl>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  measureInfo: measureInfoSelector(state, ownProps.measure),
});

export default connect(mapStateToProps)(reduxFalcor(MeasureInfoBox));
