import React from "react"

import AvlModal from "components/AvlStuff/AvlModal"

// import get from "lodash.get"
// import styled from "styled-components"

import ColorRangeSelector from "pages/auth/Routes/analysis/components/Sidebar/ColorRangeSelector"

export default class ColorSelectorModal extends React.Component {
  render() {
    return (
      <AvlModal show={ this.props.show }
        onHide={ this.props.onHide }
        usePositioned={ true }>
        <div style={ { width: "350px" } }>
          <ColorRangeSelector
            colorRange={ this.props.colorRange }
            selectColorRange={ this.props.setColorRange }
            defaultColorRange={ this.props.defaultColorRange }/>
        </div>
      </AvlModal>
    )
  }
}
