// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import { precisionRound } from 'utils/calculators/MathUtils';

const formatData = peakTravelTimesSorted => {
  if (!(Array.isArray(peakTravelTimesSorted) && peakTravelTimesSorted.length)) {
    return null;
  }

  const x = [];
  const y = [];
  for (let i = 0; i < peakTravelTimesSorted.length; ++i) {
    const { peak, avgTT } = peakTravelTimesSorted[i];

    if (!peak) {
      continue;
    }

    x.push(peak);
    y.push(precisionRound(avgTT));
  }

  return { x, y };
};

const getViz = (
  peakTravelTimesSorted,
  freeFlowTravelTime,
  tt95PercentilesByPeak
) => {
  const { x, y } = formatData(peakTravelTimesSorted);
  const ffTT = precisionRound(freeFlowTravelTime);

  var data = [
    {
      type: 'violin',
      hoveron: 'violins+points',
      x,
      y,
      points: 'none',
      box: {
        visible: true
      },
      line: {
        color: 'green'
      },
      meanline: {
        visible: true
      }
    }
  ];

  const annotations = [
    {
      text: `Free-Flow (${ffTT} sec)`,
      xref: 'paper',
      x: 1,
      yref: 'y',
      y: ffTT
    }
  ];

  const shapes = [
    {
      type: 'line',
      xref: 'paper',
      x0: 0,
      x1: 1,
      yref: 'y',
      y0: ffTT,
      y1: ffTT
    }
  ];
  var layout = {
    title: 'Travel Times Distribution By Peak',
    yaxis: {
      title: 'Travel Time (sec)',
      zeroline: false
    },
    annotations,
    shapes
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return <Plot data={data} layout={layout} config={config} />;
};

const PlanningTimeIndexViolinPlot = ({ data }) => {
  const { peakTravelTimesSorted, freeFlowTravelTime } = data;

  return peakTravelTimesSorted ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        {getViz(peakTravelTimesSorted, freeFlowTravelTime)}
      </div>
    </div>
  ) : null;
};

export default PlanningTimeIndexViolinPlot;
