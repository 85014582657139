import { matchPath } from 'react-router';

import queryString from 'query-string';

const urlRoot = '/geomeasure-visualizer';

export const paths = [
  `${urlRoot}`,
  `${urlRoot}/geolevel/:geolevel/geoid/:geoid`,
  `${urlRoot}/version/:encodedVersionId`,
  `${urlRoot}/version/:encodedVersionId/measure/:measure`,
  `${urlRoot}/geolevel/:geolevel/geoid/:geoid/version/:encodedVersionId`,
  `${urlRoot}/geolevel/:geolevel/geoid/:geoid/version/:encodedVersionId/measure/:measure`
];

// No paths match, returns null.
//   If paths match, returns at least an empty object
export const parseUrl = ({ pathname, search }) => {
  const matches = paths
    .map(path =>
      matchPath(`${pathname}`, {
        path,
        exact: false,
        strict: false
      })
    )
    .filter(m => m);

  if (!matches.length) {
    return null;
  }

  const params = matches.reduce((acc, { params }) => {
    Object.keys(params || {}).forEach(k => {
      const v = params[k];
      if (v !== undefined) {
        acc[k] = v;
      }
    });
    return acc;
  }, {});

  const queryParams = queryString.parse(search);
  if (queryParams && queryParams.focusedTmc) {
    queryParams.focusedTmc = decodeURIComponent(queryParams.focusedTmc);
  }

  const parsedParams = Object.assign({}, queryParams, params);

  if (parsedParams.encodedVersionId) {
    parsedParams.pm3VersionId = decodeURIComponent(
      parsedParams.encodedVersionId
    );
  }

  return parsedParams;
};

export const createUrl = params => {
  const { geolevel, geoid, pm3VersionId, measure, focusedTmc } = params || {};

  const search = focusedTmc
    ? `?focusedTmc=${encodeURIComponent(focusedTmc)}`
    : '';

  const encodedVersionId = pm3VersionId && encodeURIComponent(pm3VersionId);

  const hasCompleteGeoInfo = geolevel && geoid;

  if (hasCompleteGeoInfo && encodedVersionId) {
    const url = `${urlRoot}/geolevel/${geolevel}/geoid/${geoid}/version/${encodedVersionId}`;

    return measure ? `${url}/measure/${measure}${search}` : `${url}${search}`;
  }

  if (hasCompleteGeoInfo) {
    return `${urlRoot}/geolevel/${geolevel}/geoid/${geoid}${search}`;
  }

  if (encodedVersionId) {
    const url = `${urlRoot}/version/${encodedVersionId}`;

    return measure ? `${url}/measure/${measure}` : url;
  }

  return urlRoot;
};
