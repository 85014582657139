// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';

import { precisionRound } from 'utils/calculators/MathUtils';

const hourLabels = [...Array(24)].map(
  (_, hr) => `${hr % 12 || 12} ${hr < 12 ? 'am' : 'pm'}`
);

const formatData = (hourlyAvgSpeed, atriFreeFlowSpeed) => {
  if (!(Array.isArray(hourlyAvgSpeed) && hourlyAvgSpeed.length)) {
    return null;
  }

  const x = hourLabels.slice();
  const y = hourlyAvgSpeed.map(s => precisionRound(s, 3));
  const text = hourlyAvgSpeed.map(
    s => `${precisionRound(Math.max(atriFreeFlowSpeed - s, 0), 3)} mph below ff`
  );

  return { x, y, text };
};

const getViz = ({ hourlyAvgSpeed, atriFreeFlowSpeed }) => {
  const { x, y, text } = formatData(hourlyAvgSpeed, atriFreeFlowSpeed) || {};

  const data = [
    {
      name: 'Free-Flow Speed',
      x: x.slice(),
      y: x.map(() => atriFreeFlowSpeed),
      type: 'scatter',
      line: { width: 3, shape: 'hv' }
    },
    {
      name: 'Hourly Avg Speed',
      x,
      y,
      text,
      type: 'scatter',
      line: { width: 3, shape: 'hv' },
      fill: 'tonexty'
    }
  ];

  const layout = {
    title: 'Deviation from Free Flow',
    xaxis: {
      title: 'Hour of Day'
    },
    yaxis: {
      title: 'Avg Speed (mph)'
    }
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return (
    <Plot
      style={{ width: '62%', height: 600 }}
      data={data}
      layout={layout}
      config={config}
    />
  );
};

const ATRIHourlyMeanSpeedsLineChart = ({ data }) => {
  return data.hourlyAvgSpeed ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        {getViz(data)}
      </div>
    </div>
  ) : null;
};

export default ATRIHourlyMeanSpeedsLineChart;
