import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';

import pick from 'lodash.pick';
import deepEqual from 'deep-equal';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import TravelTimeIndexCalculator from 'utils/calculators/TravelTimeIndexCalculator';

import TravelTimeIndexViolinPlot from './components/TravelTimeIndexViolinPlot';
import TravelTimeIndexAvgTTsByBinByDow from './components/TravelTimeIndexAvgTTsByBinByDow';
import TravelTimeIndexByPeak from './components/TravelTimeIndexByPeak';

class TravelTimeIndexBreakdown extends Component {
  state = { loading: false };

  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, falcor }
    } = this;

    return tmc && year
      ? falcor.get(
          queryTmcAttributes(
            tmc,
            TravelTimeIndexCalculator.requiredTmcAttributes,
            year
          ),
          queryNPMRDSDataForTmc(tmc, year),
          queryMeasureRules(measure)
        )
      : Promise.resolve();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['year', 'dataSource', 'tmc', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });
      nextProps.falcor
        .get(
          queryTmcAttributes(
            nextProps.tmc,
            TravelTimeIndexCalculator.requiredTmcAttributes,
            nextProps.year
          ),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { travelTimeIndexCalculator } = this.props;

    return (
      <div>
        <TravelTimeIndexViolinPlot data={travelTimeIndexCalculator} />
        <TravelTimeIndexAvgTTsByBinByDow data={travelTimeIndexCalculator} />
        <h5>Travel Time Index</h5>
        <TravelTimeIndexByPeak data={travelTimeIndexCalculator} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tmc, year, binMinutes, measure, dataSource } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const travelTimeIndexCalculator = new TravelTimeIndexCalculator({
    tmc,
    year,
    dataSource,
    npmrdsData,
    tmcAttributes,
    binMinutes,
    measureSpec
  });

  return {
    travelTimeIndexCalculator
  };
};

export default connect(mapStateToProps)(reduxFalcor(TravelTimeIndexBreakdown));
