import React from "react"

import AvlModal from "components/AvlStuff/AvlModal"

import get from "lodash.get"
import { saveSvgAsPng } from "save-svg-as-png"
import * as d3 from "d3-selection"
import styled from "styled-components"

export default class SaveImageModal extends React.Component {
  state = {
    title: this.props.title.replace(/\s/g, "_")
  }
  componentDidUpdate(oldProps) {
    if (oldProps.title !== this.props.title) {
      this.setState({ title: this.props.title.replace(/\s/g, "_") });
    }
  }
  saveImage() {
    const svg = d3.select(`div#${ this.props.id } svg`).node();
    return new Promise((resolve, reject) => {
      if (!svg) return reject();
      saveSvgAsPng(svg, this.state.title, { backgroundColor: "#ddd" })
        .then(resolve)
    })
  }
  render() {
    return (
      <AvlModal show={ this.props.show }
        onHide={ this.props.onHide }
        actions={ [
          { label: "Save Image",
            action: () => this.saveImage(),
            type: "success"
          }
        ]	}>
        <div>
          <ImageModalHeader>Save Graph as...</ImageModalHeader>
          <div className="input-group">
            <input type="text" className="form-control"
              onChange={ e => this.setState({ title: e.target.value }) }
              value={ get(this.state, "title", "") }/>
            <div className="input-group-append">
              <span className="input-group-text">.png</span>
            </div>
          </div>
        </div>
      </AvlModal>
    )
  }
}

const ImageModalHeader = styled.h5`
	color: ${ props => props.theme.textColor };
`
