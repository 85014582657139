import React from "react"
import get from "lodash.get"
import { AvlMap } from "modules/avl-map/src"
import { MAPBOX_TOKEN } from "store/config"
// import { RisLayerFactory } from "./layers/RISLayer"
import { MacroLayerFactory } from "./layers/Replica"
import { baseMaps } from './map-styles/basemaps'

const Map = () => {
  return (
    <div className='h-screen  h-full flex-1 flex flex-col text-white'>
      <AvlMap 
        accessToken={ MAPBOX_TOKEN }
        mapOptions={{
          zoom: 6.6,
          styles: baseMaps
        }}
        layers={[
          MacroLayerFactory()
        ]}
        customTheme={{
          menuBg: 'bg-npmrds-600',
          bg: 'bg-npmrds-600',
          sidebarBg: 'bg-npmrds-800',
          accent1: 'bg-npmrds-600',
          accent2: 'bg-cool-gray-800',
          accent3: 'bg-cool-gray-700',
          accent4: 'bg-cool-gray-500',
          menuText: 'text-npmrds-100',
          
          inputBg: 'bg-npmrds-800 hover:bg-cool-gray-700',
          inputBgFocus: 'bg-cool-gray-700',
          itemText: 'text-npmrds-100 hover:text-white text-sm',
          menuBgActive: 'bg-transparent',
          
          input: 'bg-npmrds-600 w-full text-npmrds-100 text-sm p-2'
          
        }}
        sidebar={{
          title: "NPMRDS",
          tabs: ["layers", "styles"],
          open: true
        }}/>
    </div>
  )
}


const AdminMapPage = {
  icon: 'fa-map',
  class: "fa",
  path: '/replica',
  exact: true,
  mainNav: false,
  menuSettings: {
    display: 'none',
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-mini',
    style: 'color-style-default'
  },
  name: 'Map',
  authLevel: 0,
  component: Map
} 

export default AdminMapPage;
