import React from 'react';

import { precisionRound } from 'utils/calculators/MathUtils';

const ATRI = ({ data: { atri } }) =>
  atri !== null ? (
    <div>
      <blockquote
        style={{
          width: '38%',
          marginLeft: 20,
          backgroundColor: '#F2F4F8',
          padding: 12
        }}
      >
        4. Develop Total Freight Congestion Value. The sum of all 24 “hourly
        freight congestion values” represents the “total freight congestion
        value” – which determines the congestion index ranking of a particular
        location. Using this methodology, the location with the highest rank
        therefore has the highest level of truck-significant congestion. Figure
        1 displays the steps and equations for this methodology.
        <sup>1</sup>
      </blockquote>
      <p>Summing the Hourly Congestion Values yields</p>
      <p>
        <b>ATRI Total Congestion:</b>
        {`\u00a0${precisionRound(atri, 3)}`}
      </p>
    </div>
  ) : null;

export default ATRI;
