import React from "react"
import { connect } from "react-redux"

import mapboxgl from "mapbox-gl"

import {
  falcorGraph,
  falcorChunkerNiceWithUpdate
} from "store/falcorGraph"
import { reduxFalcor, UPDATE as REDUX_UPDATE } from 'utils/redux-falcor'

import MapLayer from "components/AvlMap/MapLayer"

import {
  ConflationStyle as MainStyle,
  ConflationSource as MainSource
} from './conflation.style.js'

import { register, unregister } from "components/AvlMap/ReduxMiddleware"

import deepequal from "deep-equal"
import get from "lodash.get"
import styled from "styled-components"

import * as d3scale from "d3-scale"

import ItemSelector from "components/common/item-selector/item-selector"
import { TabSelector, Tab } from "components/AvlStuff/TabSelector"

import AverageVolumeGraph from "components/hds_graphs/AverageVolumeGraph"
import AverageClassGraph from "components/hds_graphs/AverageClassGraph"
import AverageSpeedGraph from "components/hds_graphs/AverageSpeedGraph"

import { ScalableLoading } from "components/loading/loadingPage"

import { getColorRange } from "constants/color-ranges";
const DEFAULT_COLOR_RANGE = getColorRange(5, "RdYlGn");

const MainLayerId = "conflation-counts"

class CountsLayer extends MapLayer {
  onAdd(map) {
    register(this, REDUX_UPDATE, ["graph"]);

    return falcorGraph.get(["geo", 36, "geoLevels"])
      .then(res => {
        this.filters.mpo.domain = get(res, ["json", "geo", 36, "geoLevels"], [])
          .filter(d => (d.geolevel === "MPO") && (d.geoid.slice(0, 2) === '36'))
          .map(d => ({
            name: d.geoname,
            value: d.geoid,
            bb: d.bounding_box
          }))
          .sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        this.filters.ua.domain = get(res, ["json", "geo", 36, "geoLevels"], [])
          .filter(d => (d.geolevel === "UA") && (d.states.includes("36")))
          .map(d => ({
            name: d.geoname,
            value: d.geoid,
            bb: d.bounding_box
          }))
      })
      .then(() => this.loadFromLocalStorage())
      .then(() => this.fetchData())
      .then(() => this.setBounds());
  }
  onRemove(map) {
    unregister(this);
  }
  loadFromLocalStorage() {
    if (window.localStorage) {
      const mpos = localStorage.getItem("default-mpos");
      if (mpos) {
        this.filters.mpo.value = JSON.parse(mpos);
      }
      const uas = localStorage.getItem("default-uas");
      if (uas) {
        this.filters.ua.value = JSON.parse(uas);
      }
    }
  }
  saveToLocalStorage() {
    if (window.localStorage) {
      const mpos = get(this.filters, ["mpo", "value"], []);
      if (mpos.length) {
        localStorage.setItem("default-mpos", JSON.stringify(mpos));
      }

      const uas = get(this.filters, ["ua", "value"], []);
      if (uas.length) {
        localStorage.setItem("default-uas", JSON.stringify(uas));
      }
    }
  }
  receiveMessage(action, data) {
    this.falcorCache = data;
  }
  fetchData() {
    this.saveToLocalStorage();
    return [
      { type: "mpo", geoids: this.filters.mpo.value },
      { type: "ua", geoids: this.filters.ua.value }
    ].reduce((a, { type, geoids }) =>
      a.then(() => geoids.length &&
        falcorChunkerNiceWithUpdate(
          ["ris", "stations", type, geoids, this.year]
        )
      )
    , Promise.resolve())
    .then(() => {
      [
        { type: "mpo", geoids: this.filters.mpo.value },
        { type: "ua", geoids: this.filters.ua.value }
      ].forEach(({ type, geoids }) => {
        for (const geoid of geoids) {
          const stationData = get(this.falcorCache, ["ris", "stations", type, geoid, this.year, "value"], {});
          for (const stationId in stationData) {
            get(stationData, stationId, []).forEach(risId => {
              this.risToStationLookup[risId] = stationId;
            })
          }
        }
      })
    })
    .then(() => {
      if (!this.filters.measure.value) return;

      const stationIds = this.getAllStationIds();
      if (!stationIds.length) return;

      const args = this.filters.measure.value.split("-").slice(1);

      return falcorChunkerNiceWithUpdate(
        ["hds", ...args, stationIds, "latestYear"]
      );
    })
  }
  render(map) {
    const risIds = this.getAllRisIds();

console.log("??????????", this.zoomToBounds, this.bounds)
    if (this.zoomToBounds) {
      map.fitBounds(this.bounds, { duration: 2000, padding: 50 });
      this.zoomToBounds = false;
    }
    const data = this.processMeasure(),
      domain = Object.values(data).filter(d => d !== null),
      scale = this.getScale(domain);

    const colors = Object.keys(data).reduce((a, c) => {
      a[c] = data[c] === null ? "#000" : scale(data[c]);
      return a;
    }, {})

    const filtered = risIds.filter(id => !(id in colors));

    // map.setFilter(MainLayerId, [
    //   "boolean",
    //   ["in", ["get", this.getRisId()], ["literal", risIds]]
    // ]);

    map.setPaintProperty(MainLayerId, "line-color", [
      "case",
      ["has", ["to-string", ["get", this.getRisId()]], ["literal", colors]],
        ["get", ["to-string", ["get", this.getRisId()]], ["literal", colors]],
      ["in", ["get", this.getRisId()], ["literal", filtered]], "#ccc",
      "#666"
    ])
  }

  setBounds() {
    const mpos = this.filters.mpo.value,
      uas = this.filters.ua.value;

    const _bounds = [
      ...this.filters.mpo.domain.reduce((a, c) => {
        if (mpos.includes(c.value)) {
          a.push(c.bb);
        }
        return a;
      }, []),
      ...this.filters.ua.domain.reduce((a, c) => {
        if (uas.includes(c.value)) {
          a.push(c.bb);
        }
        return a;
      }, [])
    ].reduce((a, c) => a.extend(c), new mapboxgl.LngLatBounds());

    const bounds = _bounds.isEmpty() ? [] : _bounds.toArray();

    if (!deepequal(bounds, this.bounds)) {
      this.zoomToBounds = Boolean(bounds.length);
      this.bounds = bounds;
    }
  }

  getScale(domain) {
    this.legend.domain = domain;
    return d3scale.scaleQuantile()
      .domain(domain)
      .range(this.legend.range);
  }

  getAllStationIds() {
    const mpos = this.filters.mpo.value,
      uas = this.filters.ua.value;

    const getStationIds = (geo_type, geoid) => {
      const data = get(this.falcorCache, ["ris", "stations", geo_type, geoid, this.year, "value"], {});
      return Object.keys(data);
    }
    return [...new Set([
      ...mpos.reduce((a, c) => { a.push(...getStationIds("mpo", c)); return a; }, []),
      ...uas.reduce((a, c) => { a.push(...getStationIds("ua", c)); return a; }, [])
    ])]
  }
  getAllRisIds() {
    const mpos = this.filters.mpo.value,
      uas = this.filters.ua.value;

    const getRisIds = (geo_type, geoid) => {
      const data = get(this.falcorCache, ["ris", "stations", geo_type, geoid, this.year, "value"], {})
      return Object.values(data)
        .reduce((a, c) => { a.push(...c); return a; }, [])
    }

    return [...new Set([
      ...mpos.reduce((a, c) => { a.push(...getRisIds("mpo", c)); return a; }, []),
      ...uas.reduce((a, c) => { a.push(...getRisIds("ua", c)); return a; }, [])
    ])]
  }
  getAllRisIdsForStations() {
    const mpos = this.filters.mpo.value,
      uas = this.filters.ua.value;

    const data = mpos.reduce((a, c) => {
      const data = get(this.falcorCache, ["ris", "stations", "mpo", c, this.year, "value"], {});
      for (const k in data) {
        a[k] = [...data[k]];
      }
      return a;
    }, {})
    return uas.reduce((a, c) => {
      const data = get(this.falcorCache, ["ris", "stations", "ua", c, this.year, "value"], {});
      for (const k in data) {
        a[k] = [...data[k]];
      }
      return a;
    }, data);
  }

  processMeasure() {
    const measure = this.filters.measure.value;

    if (!measure) return {};

    const stations = this.getAllRisIdsForStations(),
      args = measure.split("-").slice(1);

    const stationValues = Object.keys(stations)
      .reduce((a, c) => {
        const value = get(this.falcorCache, ["hds", ...args, c, "latestYear"], null);
        a[c] = value;
        return a;
      }, {})

    return Object.keys(stations)
      .reduce((a, c) => {
        stations[c].forEach(risId => {
          a[risId] = stationValues[c];
        })
        return a;
      }, {})
  }

	getFilterValueName(filterName) {
		const filter = get(this, ["filters", filterName], {}),
			domain = get(filter, "domain", []),
			value = get(filter, "value", null);
		return domain.reduce((a, c) =>
			c.value === value ? c.name : a
		, null)
	}


  getRisId() {
    return "ris17id";
  }
}

export default (options = {}) =>
  new CountsLayer ("Conflation Counts Layer", {
    version: 2.0,

    active: false,
    ...options,

    falcorCache: {},
    risToStationLookup: {},
    zoomToBounds: false,
    bounds: [],
    year: 2017,

    filters: {
      mpo: {
        name: "MPO",
        type: "multi",
        value: [],
        domain: [],
        onChange: function() {
          this.setBounds();
        }
      },
      ua: {
        name: "Urban Area",
        type: "multi",
        value: [],
        domain: [],
        onChange: function() {
          this.setBounds();
        }
      },
      measure: {
        name: "Measure",
        type: "single",
        value: null,
        domain: [
          { name: "Latest Average Weekday Volume Year", value: "latest-average-volume" },
          { name: "Latest Average Weekday Speed Year", value: "latest-average-speed" },
          { name: "Latest Average Weekday Class Year", value: "latest-average-class" },
          // { name: "Latest Short Count Volume Year", value: "latest-short-volume" },
          // { name: "Latest Short Count Speed Year", value: "latest-short-speed" },
          // { name: "Latest Short Count Class Year", value: "latest-short-class" }
        ],
        onChange: function(oldValue, newValue) {
          this.doAction(["updateModal", "station", { measure: newValue }]);
        }
      }
    },

    popover: {
      layers: ['conflation-counts'],
      dataFunc: function(feature) {
        const y = this.year,
          id = get(feature, ["properties", this.getRisId()], null);

        if (!id) return [];

        return falcorChunkerNiceWithUpdate([
          "ris", id, "meta", this.year,
          ["gis_id", "beg_mp", "road_name",
            "begin_description", "end_description"
          ]
        ])
        .then(() => {
          const data = get(this.falcorCache, ["ris", id, "meta", y], {}),
            pData = [];

          let dd1, dd2;
          (dd1 = get(data, "road_name")) && pData.push(dd1);

          dd1 = get(data, "begin_description");
          dd2 = get(data, "end_description");
          (dd1 && dd2) && pData.push([`${ dd1 } to ${ dd2 }`]);

          dd1 = get(data, "gis_id");
          dd2 = get(data, "beg_mp");
          (dd1 && dd2) && pData.push(["RIS ID", `${ dd1 }-${ dd2 }`]);

          dd1 = get(this.risToStationLookup, id, null);
          (dd1 && pData.push(["Station ID", dd1])) || pData.push(["No station data"]);

          return pData;
        })
      }
    },

    onClick: {
      layers: [MainLayerId],
      dataFunc: function(features, point, lngLat, layer) {
        const measure = this.filters.measure.value,
          activeStations = features.reduce((a, c) => {
            const ris = get(c, ["properties", this.getRisId()], null),
              stationId = get(this.risToStationLookup, ris, null);
            stationId && !a.includes(stationId) && a.push(stationId);
            return a;
          }, []);

        if (activeStations.length && !this.modals.station.show) {
          this.doAction(["toggleModal", "station", { activeStations, measure }]);
        }
        else if (activeStations.length && this.modals.station.show) {
          this.doAction(["updateModal", "station", { activeStations, measure }]);
        }
        else if (!activeStations.length && this.modals.station.show) {
          this.doAction(["toggleModal", "station", { activeStations, measure }]);
        }
      }
    },
    modals: {
      station: {
        title: "Station Modal",
        comp: StationModal,
        show: false,
        position: "bottom-right",
        startSize: [1200, 650]
      }
    },
    legend: {
      title: ({ layer }) => <>{ layer.getFilterValueName("measure") }</>,
      type: "quantile",
      types: ["quantile", "quantize"],
      domain: [],
      range: DEFAULT_COLOR_RANGE,
      active: true
    },
    onHover: {
      layers: ['conflation-counts']
    },
    sources: [MainSource],
    layers: [{
      ...MainStyle,
      id: MainLayerId,
      // filter: ["boolean", false]
    }]
  })

class BaseStationModal extends React.Component {
  static defaultProps = {
    measure: "volume"
  }
  MOUNTED = false;
  state = {
    currentTab: 0,
    loading: false
  }
  componentDidMount() {
    this.MOUNTED = true;
    this.setCurrentTab();
  }
  componentWillUnmount() {
    this.MOUNTED = false;
  }
  componentDidUpdate(oldProps) {
    if (!deepequal(oldProps.activeStations, this.props.activeStations)) {
      this.fetchFalcorDeps();
    }
    if (oldProps.measure !== this.props.measure) {
      this.setCurrentTab();
    }
  }
  setCurrentTab() {
    if (!this.props.measure) return;

    switch (this.props.measure.split("-")[2]) {
      case "volume":
        this.setState({ currentTab: 0 });
        break;
      case "speed":
        this.setState({ currentTab: 1 });
        break;
      case "class":
        this.setState({ currentTab: 2 });
        break;
    }
  }
  fetchFalcorDeps() {
    if (!this.props.activeStations.length) return Promise.resolve();

    this.setState({ loading: true });

    const measures = ["volume", "class", "speed"];

    return this.props.falcor.get(
        ["hds", "average", measures, this.props.activeStations, "length"]
      )
      .then(res => {
        const requests = this.props.activeStations.reduce((a, c) => {
          measures.forEach(measure => {
            const length = get(res, ["json", "hds", "average", measure, c, "length"], 0)
            if (length) {
              a.push(
                ["hds", "average", measure, c, "byIndex",
                  { from: 0, to: length - 1 },
                  ["count_id", "count_data", "month", "year", "data_type"]
                ]
              )
            }
          })
          return a;
        }, [])
        return requests.length && this.props.falcor.get(...requests);
      })
      .then(() => this.MOUNTED && this.setState({ loading: false }))
  }
  render() {
    return (
      <TabSelector currentTab={ this.state.currentTab }>
        <Tab name="Volume">
          { this.state.loading ? <LoadingDiv /> :
            <CountsSelector
              countsData={
                this.props.countsData.filter(t => t.type === "average-volume")
              }>
              <AverageVolumeGraph />
            </CountsSelector>
          }
        </Tab>
        <Tab name="Speed">
          { this.state.loading ? <LoadingDiv /> :
            <CountsSelector
              countsData={
                this.props.countsData.filter(t => t.type === "average-speed")
              }>
              <AverageSpeedGraph />
            </CountsSelector>
          }
        </Tab>
        <Tab name="Class">
          { this.state.loading ? <LoadingDiv /> :
            <CountsSelector
              countsData={
                this.props.countsData.filter(t => t.type === "average-class")
              }>
              <AverageClassGraph />
            </CountsSelector>
          }
        </Tab>
      </TabSelector>
    )
  }
}
const mapStateToProps = (state, props) => ({
  countsData: getCountsData(state, props)
})
const StationModal = connect(mapStateToProps, null)(reduxFalcor(BaseStationModal))

const LoadingDiv = () =>
  <div style={ {
      width: "100%",
      height: "400px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    } }>
    <ScalableLoading />
  </div>

const getCountsData = (state, props) => {
  return props.activeStations.reduce((a, c) => {
    ["volume", "class", "speed"].forEach(measure => {
      const length = get(state, ["graph", "hds", "average", measure, c, "length"], 0);
      for (let l = 0; l < length; ++l) {
        const id = get(state, ["graph", "hds", "average", measure, c, "byIndex", l, "value", 4], null),
          data = get(state, ["graph", "hds", "average", measure, "byId", id], null);
        if (data) {
          a.push({ ...data, type: `average-${ measure }` });
        }
      }
    })
    return a;
  }, [])
}

const MONTHS = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
}

const StyledText = styled.div`
  font-size: 2rem;
  font-weight: bold;
  padding-top: 10px;
`

class CountsSelector extends React.Component {
  static defaultProps = {
    countsData: []
  }
  state = {
    activeCount: null
  }
  componentDidMount() {
    const options = this.getOptions();
    if (options.length) {
      this.setState({ activeCount: options[0] })
    }
  }
  componentDidUpdate(oldProps) {
    if (!oldProps.countsData.length && this.props.countsData.length) {
      const options = this.getOptions();
      this.setState({ activeCount: options[0] });
    }
  }
  getOptions() {
    return this.props.countsData
      .sort((a, b) => a.year === b.year ? +b.month - +a.month : +b.year - +a.year)
      .map(d => ({ name: `${ MONTHS[d.month] } ${ d.year }`, value: d.count_id }));
  }
  render() {
    return (
      <div>
        { !this.props.countsData.length ? null :
          <div style={ { display: "flex", alignItems: "center" } }>
            <div style={ { marginRight: "10px" } }>Date</div>
            <div style={ { width: "200px" } }>
              <ItemSelector
                selectedItems={ this.state.activeCount }
                placeholder={ "Select a Date..." }
                options={ this.getOptions() }
                multiSelect={ false }
                searchable={ false }
                displayOption={ d => d.name }
                getOptionValue={ d => d }
                onChange={ d => this.setState({ activeCount: d }) }/>
            </div>
          </div>
        }

        { !this.props.countsData.length ?
            <StyledText>No Data Available</StyledText>
          : !this.state.activeCount ?
            <StyledText>Select a Date...</StyledText>
          : React.Children.map(this.props.children,
            child => React.cloneElement(child, { countId: this.state.activeCount.value })
          )
        }
      </div>
    )
  }
}
