import reducer from './reducer';

import assertNoNameCollisions from './assertNoNameCollisions';

import * as crossSliceActionCreators from './actionCreators';
import * as dataSelectionActionCreators from './dataSelection/actionCreators';
import * as userInteractionActionCreators from './userInteraction/actionCreators';
import * as controlledComponentsActionCreators from './controlledComponents/actionCreators';

import * as crossSliceSelectors from './selectors';
import * as dataSelectionSelectors from './dataSelection/selectors';
import * as userInteractionSelectors from './userInteraction/selectors';
import * as controlledComponentsSelectors from './controlledComponents/selectors';

import { geomeasureVisualizer as sliceId } from './registry';

assertNoNameCollisions([
  crossSliceActionCreators,
  dataSelectionActionCreators,
  userInteractionActionCreators,
  controlledComponentsActionCreators,

  crossSliceSelectors,
  dataSelectionSelectors,
  userInteractionSelectors,
  controlledComponentsSelectors,

  { sliceId }
]);

export * from './actionCreators';
export * from './dataSelection/actionCreators';
export * from './userInteraction/actionCreators';
export * from './controlledComponents/actionCreators';

export * from './selectors';
export * from './dataSelection/selectors';
export * from './userInteraction/selectors';
export * from './controlledComponents/selectors';

export { geomeasureVisualizer as sliceId } from './registry';

export default reducer;
