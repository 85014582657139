// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';

import ATRIHourlyMeanSpeedsLineChart from './ATRIHourlyMeanSpeedsLineChart';

const ATRIHourlyMeanSpeeds = ({ data }) => {
  const { speedsByHour } = data;

  return speedsByHour ? (
    <div style={{ width: '100%', color: '#333' }}>
      <blockquote
        style={{
          width: '38%',
          marginLeft: 20,
          backgroundColor: '#F2F4F8',
          padding: 12
        }}
      >
        2. Determine Average Truck Speed and the Deviation from Free Flow. The
        second step determines the average truck speed and then subtracts it
        from the free flow speed to calculate the MPH below free flow.
        <sup>1</sup>
      </blockquote>
      <p>
        The average travel speed is computed for each hour of the day across the
        entire year.
      </p>
      <ATRIHourlyMeanSpeedsLineChart data={data} />
    </div>
  ) : null;
};

export default ATRIHourlyMeanSpeeds;
