import React from "react"

import * as d3array from "d3-array"
import * as d3selection from "d3-selection"
import * as d3scale from "d3-scale"
import * as d3axis from "d3-axis"
import * as d3transition from "d3-transition"

import styled from "styled-components"

// import get from "lodash.get"
import throttle from "lodash.throttle"

import deepequal from "deep-equal"

import {
	getResolutionFormat,
	// getResolutionLabel
} from "../utils/resolutionFormats"

import COLOR_RANGES from "constants/color-ranges"
const COLOR_RANGE = COLOR_RANGES[5].reduce((a, c) => c.name === "RdYlGn" ? c.colors : a).slice()

class TmcGridGraph extends React.Component {
	static defaultProps = {
		resolution: "5-minutes",
		hoverData: d => [
			["TMC", d.tmc],
			["Value", d.value],
			["Resolution", d.resolution]
		],
		data: [],
		reverseTMCs: false,
		colorScale: null,
		highlightedTmcs: [],
		hoverTmc: () => {}
	}
	constructor(props) {
		super(props);
		this.state = {
			tmcGridGraph: d3TmcGridGraph(),
			width: null,
			height: null,
			hovering: false
		}
		this.div = React.createRef();
		this.throttled = throttle(this.resize.bind(this), 50);
	}
	componentDidMount() {
		this.resize();
		this.updateHighlight();
	}
	componentWillUnmount() {
		cancelAnimationFrame(this.animation);
	}
	componentDidUpdate(oldProps) {
		if (!deepequal(this.props.data, oldProps.data) ||
				(this.props.reverseTMCs !== oldProps.reverseTMCs) ||
				!deepequal(this.props.colorRange, oldProps.colorRange)) {
			this.updateGraph();
		}
		if (!deepequal(this.props.highlightedTmcs, oldProps.highlightedTmcs)) {
			this.updateHighlight();
		}
	}
	resize() {
		const div = this.div.current;
		if (!div) return;

		const width = div.scrollWidth,
			height = div.scrollHeight;

		if ((width !== this.state.width) || (height !== this.state.height)) {
			this.setState({ width, height });
			this.updateGraph(true);
		}
		this.animation = requestAnimationFrame(this.throttled)
	}
	updateHighlight() {
		const div = this.div.current;
		if (!div) return;

		const { tmcGridGraph } = this.state,
			{ highlightedTmcs } = this.props;

		tmcGridGraph
			.highlightedTmcs(highlightedTmcs, d3selection.select(div))
	}
	updateGraph(resizing = false) {
		const div = this.div.current;
		if (!div) return;

		const width = div.scrollWidth,
			height = div.scrollHeight;

		const { tmcGridGraph } = this.state,
			{ data,
				reverseTMCs,
				resolution,
				hoverData,
				colorScale,
				// highlightedTmcs,
				showDirection,
				colorRange = COLOR_RANGE
			} = this.props;

		tmcGridGraph
			.margin({ left: showDirection ? 40 : 10 })
			.resizing(resizing)
			.hoverData(hoverData)
			.resolution(resolution)
			.reverseTMCs(reverseTMCs)
			.data(data)
			.showDirection(showDirection)
			.width(width)
			.height(height)
			.colorScale(colorScale)
			.colorRange(colorRange)
			.onTmcEnter(tmc => this.props.hoverTmc(tmc))
			.onGraphLeave(tmc => this.props.hoverTmc(null));

		d3selection.select(div)
			.call(tmcGridGraph);
	}
	render() {
		return (
			<div ref={ this.div }
				style={ { width: "100%", height: "100%", position: "relative" } }
				onMouseEnter={ () => this.setState({ hovering: true }) }
				onMouseLeave={ () => this.setState({ hovering: false }) }>
				<svg style={ { width: "100%", height: "100%", display: "block" } }/>
			</div>
		)
	}
}
export default TmcGridGraph;
// //
// const DirectionWidget = ({ hovering, direction, animation }) =>
// 	<DirectionContainer>
// 		{ !hovering ? null :
// 			<div style={ {
// 				animation: `fadeOut ${ 0.75 * 3 }s linear`,
// 				opacity: 0,
// 				height: "100%"
// 			} }>
// 				<Widget style={ {
// 						animation: `${ animation } 0.75s linear 3`,
// 						top: direction == 1 ? "calc(100% - 30px)" : "0%"
// 					} }>
// 					{
// 						[0, 1, 2].map(i =>
// 							<WidgetIcon key={ i }
// 								style={ {
// 									transform: `translateY(${ -i * 20 * direction }px)`,
// 									opacity: 1 - i * .33
// 								} }
// 								className="fa fa-2x fa-car"/>
// 						)
// 					}
// 				</Widget>
// 			</div>
// 		}
// 	</DirectionContainer>

// const DirectionContainer = styled.div`
// 	position: absolute;
// 	top: 0px;
// 	left: 0px;
// 	bottom: 0px;
// 	display: block;
// 	width: 40px;
// 	overflow: hidden;
// 	pointer-events: none;
// 	z-index: 400;
// `
// const ArrowPointer = styled.div`
// 	height: 100%;
// 	position: absolute;
// 	top: 0px;
// 	left: 10px;
// `
// const Widget = styled.div`
// 	position: relative;
// 	height: 100%;
// `
// const WidgetIcon = styled.span`
// 	position: absolute;
// 	left: 5px;
// 	color: currentColor;
// `

const WEEKDAYS = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday"
]
const WEEKDAYS_MAP = {
	"sunday": 0,
	"monday": 1,
	"tuesday": 2,
	"wednesday": 3,
	"thursday": 4,
	"friday": 5,
	"saturday": 6
}

function d3TmcGridGraph() {
	let data = [],
		width = 0,
		height = 0,
		resolution = "5-minutes",
		resolutionDomain = [0, 287],
		div = null,
		hoverComp = HoverComp(),
		hoverData = d => [],
		colorRange = COLOR_RANGE,
		_colorScale = {},
		margin = {
			top: 5,
			right: 10,
			bottom: 20,
			left: 10
		},
		resizing = false,
		highlightedTmcs = [],

		xScale = d3scale.scaleBand(),
		heightScale = d3scale.scaleLinear(),

		tmcPositionScale = d3scale.scaleOrdinal(),
		tmcHeightScale = d3scale.scaleOrdinal(),

		showDirection = false,
		reverseTMCs = false,

		onTmcEnter = null,
		onTmcLeave = null,
		onGraphLeave = null;

	function graph(selection) {
		if (!selection.size()) return;
		div = selection;

		selection.on("mousemove", mousemove)

		const adjustedHeight = height - margin.top - margin.bottom,
			adjustedWidth = width - margin.left - margin.right;

		if (reverseTMCs) {
			data = [...data].reverse();
		}

		const colorScale = d3scale.scaleQuantize()
				.domain(d3array.extent(data.reduce((a, c) => { a.push(...c.data.map(d => d.value)); return a; }, [])))
				.range(colorRange);

		xScale = d3scale.scaleBand()
			.domain(resolutionDomain)
			.range([0, adjustedWidth])

		heightScale = d3scale.scaleLinear()
			.domain([0, d3array.sum(data, d => d.length)])
			.range([0, adjustedHeight])

		if (_colorScale.domain) {
			colorScale.domain(_colorScale.domain);
		}

		const svg = selection.select("svg");

		let renderGroup = svg.select("g.render-group")
		if (!renderGroup.size()) {
			renderGroup = svg.append("g")
				.attr("class", "render-group")
		}
		renderGroup.style("transform", `translate(${ margin.left }px, ${ margin.top }px)`)
			.on("mouseleave", onGraphLeave);

		let bg = renderGroup.select("rect.bg");
		if (!bg.size()) {
			bg = renderGroup.append("rect")
				.attr("class", "bg")
		}
		bg.attr("width", adjustedWidth)
			.attr("height", adjustedHeight)
			.attr("fill", data.length ? "#000" : "none");

		tmcPositionScale = d3scale.scaleOrdinal()
			.domain(data.map(d => d.tmc));
		tmcHeightScale = d3scale.scaleOrdinal()
			.domain(data.map(d => d.tmc));

		let current = 0;
		const tmcPositionScaleRange = [],
			tmcHeightScaleRange = [];

		const tmcGroups = renderGroup.selectAll(".tmc-group")
			.data(data, d => d.tmc)
			.join("g")
			.attr("class", d => `tmc-group tmc-group-${ d.tmc }`)
			.on("mouseenter", d => onTmcEnter && onTmcEnter(d.tmc))
			.on("mouseleave", d => onTmcLeave && onTmcLeave(d.tmc))
			.each(function(d, i) {
				const rects = d3selection.select(this)
					.style("transform", `translate(0px, ${ heightScale(current) }px`)
					.selectAll("rect.grid-rect")
						.data(d.data);

				rects.enter()
					.append("rect")
						.attr("class", d => `grid-rect grid-rect-${ d.resolution }`)
						.attr("height", heightScale(d.length))
						.attr("fill", d => colorScale(d.value))
						.attr("x", 0)
						.attr("width", 0)
						.on("mouseenter", mouseenter)
						.on("mouseleave", mouseleave)
						.transition(d3transition.transition().duration(2000))
							.attr("x", d => xScale(d.resolution))
							.attr("width", xScale.bandwidth())

				if (resizing) {
					rects
						.attr("fill", d => colorScale(d.value))
						.attr("height", heightScale(d.length))
						.attr("x", d => xScale(d.resolution))
						.attr("width", xScale.bandwidth())
				}
				else {
					rects.transition(d3transition.transition().duration(2000))
						.attr("fill", d => colorScale(d.value))
						.attr("height", heightScale(d.length))
						.attr("x", d => xScale(d.resolution))
						.attr("width", xScale.bandwidth())
				}

				rects.exit()
					.transition(d3transition.transition().duration(2000))
					.attr("x", 0)
					.attr("width", 0)
					.remove();

				tmcPositionScaleRange.push(heightScale(current));
				tmcHeightScaleRange.push(heightScale(d.length));
				current += d.length;
			});

		tmcGroups.exit()
			.remove();

		tmcPositionScale.range(tmcPositionScaleRange);
		tmcHeightScale.range(tmcHeightScaleRange);

		let xAxis = svg.select("g.x.axis");
		if (!xAxis.size()) {
			xAxis = svg.append("g")
				.attr("class", "x axis")
		}
		const axis = d3axis.axisBottom(xScale)
			.tickFormat(getResolutionFormat(resolution));
		if (resolution !== "weekday") {
			const linear = d3scale.scaleLinear()
				.domain(d3array.extent(resolutionDomain))
				.range([0, width])
			let ticks = [...new Set(linear.ticks().map(t => Math.trunc(t)))].sort();
			if (linear(ticks[0]) === 0) {
				ticks = ticks.slice(1)
			}
			axis.tickValues(ticks)
		}
		xAxis.style("transform", `translate(${ margin.left }px, ${ height - margin.bottom }px`)
			.call(axis);

		let directionGroup = svg.select("g.direction-group")
		if (!directionGroup.size()) {
			directionGroup = svg.append("g")
				.attr("class", "direction-group");
		}
		directionGroup
			.attr("stroke-linecap", "round")
			.attr("stroke-width", 4)
			.attr("stroke", "currentColor")
			.style("transform",
				`translate(${ margin.left * 0.5 }px, ${ reverseTMCs ? margin.top + (height - margin.bottom) : margin.top }px) scaleY(${ reverseTMCs ? -1 : 1 })`
			);

		directionGroup
			.selectAll("line.body")
			.data(showDirection ? [reverseTMCs] : [])
			.join("line")
				.attr("class", "body")
				.attr("x1", 0)
				.attr("y1", 0)
				.attr("x2", 0)
				.attr("y2", (height - margin.bottom))

		directionGroup
			.selectAll("line.part1")
			.data(showDirection ? [reverseTMCs] : [])
			.join("line")
				.attr("class", "part1")
				.attr("x1", 0)
				.attr("y1", (height - margin.bottom))
				.attr("x2", 10)
				.attr("y2", (height - margin.bottom - 10))

		directionGroup
			.selectAll("line.part2")
			.data(showDirection ? [reverseTMCs] : [])
			.join("line")
				.attr("class", "part2")
				.attr("x1", 0)
				.attr("y1", (height - margin.bottom))
				.attr("x2", -10)
				.attr("y2", (height - margin.bottom - 10))
	}
	graph.colorRange = function(cr) {
		if (!arguments.length) {
			return colorRange;
		}
		colorRange = cr;
		return graph;
	}
	graph.reverseTMCs = function(r) {
		if (!arguments.length) {
			return reverseTMCs;
		}
		reverseTMCs = r;
		return graph;
	}
	graph.showDirection = function(s) {
		if (!arguments.length) {
			return showDirection;
		}
		showDirection = s;
		return graph;
	}
	graph.margin = function(m) {
		if (!arguments.length) {
			return margin;
		}
		let temp = {
			top: 5,
			right: 10,
			bottom: 20,
			left: 10
		}
		if (typeof m === "number") {
			temp.top = m;
			temp.right = m;
			temp.bottom = m;
			temp.left = m;
		}
		else if (typeof m === "object") {
			temp = {
				...temp,
				...m
			}
		}
		margin = temp;
		return graph;
	}
	graph.onGraphLeave = function(f) {
		if (!arguments.length) {
			return onGraphLeave;
		}
		onGraphLeave = f;
		return graph;
	}
	graph.onTmcEnter = function(f) {
		if (!arguments.length) {
			return onTmcEnter;
		}
		onTmcEnter = f;
		return graph;
	}
	graph.onTmcLeave = function(f) {
		if (!arguments.length) {
			return onTmcLeave;
		}
		onTmcLeave = f;
		return graph;
	}
	graph.highlightedTmcs = function(tmcs, selection) {
		if (!arguments.length) {
			return highlightedTmcs;
		}
		highlightedTmcs = tmcs;

		selection.select("g.render-group")
			.selectAll("rect.tmc-highlight")
				.data(highlightedTmcs, d => d)
					.join("rect")
						.attr("class", "tmc-highlight")
						.attr("x", 0)
						.attr("width", width - margin.left - margin.right)
						.attr("y", d => tmcPositionScale(d))
						.attr("height", d => tmcHeightScale(d))
						.attr("fill", "none")
						.attr("stroke", "#000")
						.style("pointer-events", "none")
		return graph;
	}
	graph.resizing = function(r) {
		if (!arguments.length) {
			return resizing;
		}
		resizing = r;
		return graph;
	}
	graph.colorScale = function(r) {
		if (!arguments.length) {
			return _colorScale;
		}
		_colorScale = {
			...r
		};
		return graph;
	}
	graph.hoverData = function(r) {
		if (!arguments.length) {
			return hoverData;
		}
		hoverData = r;
		return graph;
	}
	graph.resolution = function(r) {
		if (!arguments.length) {
			return resolution;
		}
		resolution = r;
		return graph;
	}
	graph.data = function(d) {
		if (!arguments.length) {
			return data;
		}
		data = d;
		resolutionDomain = getResolutionDomain();
		return graph;
	}
	graph.width = function(w) {
		if (!arguments.length) {
			return width;
		}
		width = w;
		return graph;
	}
	graph.height = function(h) {
		if (!arguments.length) {
			return height;
		}
		height = h;
		return graph;
	}
	return graph;

	function getResolutionDomain() {
		let accessor = d => +d.resolution;
		if (resolution === "weekday") {
			accessor = d => WEEKDAYS_MAP[d.resolution];
		}
		const values = []
		data.forEach(({ data }) => {
			values.push(...d3array.extent(data, accessor))
		})
		const extent = d3array.extent(values);
		let domain = d3array.range(extent[0], extent[1] + 1);
		if (resolution === "weekday") {
			domain = domain.map(r => WEEKDAYS[r]);
		}
		return domain;
	}

	function mouseenter(d) {
		div.select(".render-group")
			.append("rect")
				.attr("class", `resolution-highlight-${ d.resolution }`)
				.attr("x", xScale(d.resolution))
				.attr("y", 0)
				.attr("width", xScale.bandwidth())
				.attr("height", height - margin.top - margin.bottom)
				.attr("fill", "none")
				.attr("stroke", "#000")
				.style("pointer-events", "none")

		hoverComp
			.data(hoverData(d))
			.show(true)(div);
	}
	function mousemove(d) {
		const pos = d3selection.mouse(this);
		hoverComp.pos(pos)(div);
	}
	function mouseleave(d) {
		div.select(".render-group")
			.select(`rect.resolution-highlight-${ d.resolution }`)
				.remove();

		hoverComp.show(false)(div);
	}
}

function HoverComp() {
	let data = [],
		pos = [0, 0],
		show = false;
	function hover(selection) {
		if (!selection.size()) return;

		const node = selection.node(),
			nodeWidth = node.scrollWidth,
			nodeHeight = node.scrollHeight;

		let hoverComp = selection.select("div.hover-comp");
		if (!hoverComp.size()) {
			hoverComp = selection.append("div")
				.attr("class", "hover-comp")
				.style("position", "absolute")
				.style("background-color", "#fff")
				.style("pointer-events", "none")
				.style("padding", "7px 10px 10px 10px")
				.style("border-radius", "4px")
				.style("z-index", 500);
		}

		hoverComp.style("display", show ? "block" : "none")
		if (!show) return;

		let table = hoverComp.select("table.hover-table tbody");
		if (!table.size()) {
			table = hoverComp.append("table")
				.attr("class", "hover-table")
				.append("tbody");
		}

		table.selectAll("tr")
			.data(data)
			.join("tr")
			.each(function(d) {
				d3selection.select(this)
					.selectAll("td")
					.data(d)
					.join("td")
						.attr("colspan", d.length === 1 ? 2 : 1)
						.style("padding", "0px 5px")
						.style("text-align", (d, i) => i === 1 ? "right" : "left")
						.style("font-weight", (d, i) => i === 0 ? "bold" : "normal")
						.text(d => d)
			})

		const [x, y] = pos,

			comp = hoverComp.node(),
			compWidth = comp.scrollWidth,
			compHeight = comp.scrollHeight;

		const left = x + 10 + compWidth > nodeWidth ? x - 10 - compWidth : x + 10,
			top = y + 10 + compHeight > nodeHeight ? nodeHeight - compHeight : y + 10;

		hoverComp
			.style("left", `${ left }px`)
			.style("top", `${ top }px`);
	}
	hover.data = function(d) {
		if (!arguments.length) {
			return data;
		}
		data = d;
		return hover;
	}
	hover.pos = function(p) {
		if (!arguments.length) {
			return pos;
		}
		pos = p;
		return hover
	}
	hover.show = function(s) {
		if (!arguments.length) {
			return show;
		}
		show = s;
		return hover;
	}
	return hover;
}
