import React from "react"

import styled from "styled-components"

import { FolderContainer } from "./ControlBox"
import FolderIcon from "pages/auth/MyStuff/components/FolderIcon"

import { getColorRange } from "constants/color-ranges"
const GREENS = getColorRange(9, "Greens")
const REDS = getColorRange(9, "Reds")

const StyledCheckbox = styled.div`
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
  border-radius: 3px;
  transition: background-color 0.15s;
  background-color: ${ props => props.checked ? GREENS[4] : "#dfdfdf" };
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${ props => props.checked ? GREENS[5] : "#cfcfcf" };
  }
  > span.fa {
    color: #fff;
  }
`

export class Checkbox extends React.Component {
  static defaultProps = {
    checked: false,
    onChange: () => {},
    width: 20,
    height: 20
  }
  render() {
    const {
      onChange,
      ...rest
    } = this.props;
    return (
      <StyledCheckbox { ...rest }
        onClick={ onChange }
        className="checkmark">
        { !this.props.checked ? null :
          <span className="fa fa-check"/>
        }
      </StyledCheckbox>
    )
  }
}

const ClearBoxDiv = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${ REDS[5] };
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  line-height: 20px;
  font-size: 18px;
  :hover {
    background-color: ${ REDS[6] };
  }
`

export const ClearBox = ({ ...props }) =>
  <ClearBoxDiv { ...props }>
    <span className="fa fa-times"/>
  </ClearBoxDiv>

export const DropdownList = styled.div`
  ${ props => props.theme.scrollBarLight };
  max-height: 500px;
  overflow: auto;
  position: absolute;
  top: calc(100% + 2px);
  left: 0px;
  background-color: #f8f8f8;
  white-space: nowrap;
  padding: 10px;
  box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.25);
`
export const Dropdown = styled.div`
  position: relative;
  color: #ccc;
  cursor: not-allowed;
  border: 2px solid transparent;
  padding: 3px 20px;
  border-radius: 4px;
  transition: color 0.15s, border-color 0.15s;

  &.active {
    color: currentColor;
    cursor: pointer;
  }
  &.active:hover {
    border: 2px solid currentColor;
  }

  > ${ DropdownList } {
    display: none;
  }
  &.active:hover > ${ DropdownList } {
    display: block;
  }
`
export const LinkDiv = styled.div`
  border-radius: 4px;
  transition: color 0.15s, border-color 0.15s;
  border: 2px solid transparent;
  &.active:hover {
    border: 2px solid currentColor;
  }
  > a,
  > a:hover {
    color: #ccc;
    padding: 3px 20px;
    transition: color 0.15s;
    cursor: not-allowed;
    display: block;
    text-decoration: none;
  }
  &.active > a {
    color: currentColor;
    cursor: pointer;
  }
  > a:focus {
    box-shadow: none;
  }
`
export const FlexDiv = styled.div`
  padding: 10px;
  display: flex;
  position: relative;
  > * {
    margin-right: 5px;
  }
  > *:last-child {
    margin-right: 0px;
  }
`
export const DeleteButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 3px 20px;
  border: 2px solid transparent;
  border-radius: 4px;
  color: #ccc;
  cursor: not-allowed;
  transition: color 0.15s, border-color 0.15s;

  &.active {
    color: currentColor;
  }
  &.active:hover {
    border-color: currentColor;
    cursor: pointer;
  }
`

export class ActionMenu extends React.Component {
  static defaultProps = {
    disableDelete: false
  }
  render() {
    const { active, folders, disableDelete } = this.props;
    return (
      <FlexDiv style={ {
        backgroundColor:'#f8f8f8',
        margin: "20px 0px",
        boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
        borderRadius: "4px"
      } }>

        <Dropdown className={ active && folders.length && "active" }>
          <span className="fa fa-folder"/>
          <span className="ml-2">Add to Folder</span>
          <DropdownList>
            {
              folders.map(f =>
                <FolderContainer key={ f.id }
                  onClick={ e => this.props.addToFolder(f) }>
                  <FolderIcon { ...f } size={ 1.5 }/>
                  <span className="ml-1">{ f.name }</span>
                </FolderContainer>
              )
            }
          </DropdownList>
        </Dropdown>

        { this.props.children }

        <DeleteButton className={ active && !disableDelete && "active" }
          onClick={ e => active && !disableDelete && this.props.showDelete()}>
          <span className="fa fa-trash"/>
          <span className="ml-2">Delete Selected</span>
        </DeleteButton>

      </FlexDiv>
    )
  }
}
