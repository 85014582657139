import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';

import pick from 'lodash.pick';
import deepEqual from 'deep-equal';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import PlanningTimeIndexCalculator from 'utils/calculators/PlanningTimeIndexCalculator';

import PlanningTimeIndexViolinPlot from './components/PlanningTimeIndexViolinPlot';
import PlanningTimeIndexByPeakAggSums from './components/PlanningTimeIndexByPeakAggSums';
import PlanningTimeIndex95PercentilesByBinByDow from './components/PlanningTimeIndex95PercentilesByBinByDow';
import PlanningTimeIndexByPeak from './components/PlanningTimeIndexByPeak';

class PlanningTimeIndexBreakdown extends Component {
  state = { loading: false };

  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, falcor }
    } = this;

    return tmc && year
      ? falcor.get(
          queryTmcAttributes(
            tmc,
            PlanningTimeIndexCalculator.requiredTmcAttributes,
            year
          ),
          queryNPMRDSDataForTmc(tmc, year),
          queryMeasureRules(measure)
        )
      : Promise.resolve();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsRequiringUpdate = [
      'year',
      'binMinutes',
      'metric',
      'dataSource',
      'tmc',
      'measure'
    ];

    return (
      this.state.loading !== nextState.loading ||
      !deepEqual(
        pick(this.props, propsRequiringUpdate),
        pick(nextProps, propsRequiringUpdate)
      ) ||
      !deepEqual(
        this.props.planningTimeIndexCalculator.ptiByPeak,
        nextProps.planningTimeIndexCalculator.ptiByPeak
      )
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['year', 'dataSource', 'tmc', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });

      nextProps.falcor
        .get(
          queryTmcAttributes(
            nextProps.tmc,
            PlanningTimeIndexCalculator.requiredTmcAttributes,
            nextProps.year
          ),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { planningTimeIndexCalculator } = this.props;

    return (
      <div>
        <PlanningTimeIndexViolinPlot data={planningTimeIndexCalculator} />
        <PlanningTimeIndexByPeakAggSums data={planningTimeIndexCalculator} />
        <PlanningTimeIndex95PercentilesByBinByDow
          data={planningTimeIndexCalculator}
        />
        <h5>Planning Time Index</h5>
        <PlanningTimeIndexByPeak data={planningTimeIndexCalculator} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { tmc, year, binMinutes, measure, dataSource } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const planningTimeIndexCalculator = new PlanningTimeIndexCalculator({
    tmc,
    year,
    npmrdsData,
    tmcAttributes,
    binMinutes,
    measureSpec
  });

  return {
    planningTimeIndexCalculator
  };
};

export default connect(mapStateToProps)(
  reduxFalcor(PlanningTimeIndexBreakdown)
);
