import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { reduxFalcor } from 'utils/redux-falcor';

import get from "lodash.get"

import styled from "styled-components"

import AvlModal from "components/AvlStuff/AvlModal"

import DeleteModal from "./components/DeleteModal"

// import { Input } from "components/common/styled-components"
import ItemSelector from 'components/common/item-selector/item-selector';

import {
  replace
} from "./analysis/reports/store";

import FolderIcon from "pages/auth/MyStuff/components/FolderIcon"
import { getFoldersFromState } from "pages/auth/MyStuff"

import { foldersFalcorRequest } from "pages/auth/MyStuff"

import {
  ControlBox,
  FolderContainer,
  DropLeft
} from "./components/ControlBox"

import {
  Checkbox,
  ClearBox,
  // Dropdown,
  // DropdownList,
  // LinkDiv,
  ActionMenu
} from "./components/Parts"

export const TemplateControlBox = ({ template, openModal, onDelete=null, addToFolder=null, folders=[] }) =>
  <ControlBox>
    <div className="control-box">
      <ul>
        <li>
          <div onClick={ () => openModal("View", template) }>
            <span className="fa fa-eye"/>
            View
          </div>
        </li>
        <li>
          <div onClick={ () => openModal("Edit", template) }>
            <span className="fa fa-pencil"/>
            Edit
          </div>
        </li>
        { (addToFolder === null) || (!folders.length) ? null :
          <li>
            <DropLeft>
              <span className="fa fa-folder"/>
              Add to Folder
              <div className="dropdown-list">
                {
                  folders
                    .filter(({ stuff }) => stuff.reduce((a, c) => a && c.stuff_id != template.id, true))
                    .map(f =>
                      <FolderContainer key={ f.id }
                        onClick={ e => addToFolder(f.id) }>
                        <FolderIcon { ...f } size={ 1.5 }/>
                        { f.name }
                      </FolderContainer>
                    )
                }
              </div>
            </DropLeft>
          </li>
        }
        { onDelete === null ? null :
          <li>
            <a onClick={ onDelete }>
              <span className="fa fa-trash"/>
              Delete
            </a>
          </li>
        }
      </ul>
    </div>
  </ControlBox>

class TemplatesView extends Component {
  state = {
    filter: "",
    openModalAs: null,
    template: {},
    showDeleteModal: false,
    toDelete: {},
    selectedTemplates: new Map()
  };

  fetchFalcorDeps() {
    return this.props.falcor
      .get(
        ["routes", "length"],
        ["templates", "length"],
        ["folders", "length"],
        ['hds', 'continuous', 'stations', 'length']
      )
      .then(res => {
        const requests = [],
          templates = get(res, 'json.templates.length', 0),
          routes = get(res, 'json.routes.length', 0),
          folders = get(res, 'json.routes.length'),
          stations = get(res, 'json.hds.continuous.stations.length', 0);

        if (templates) {
          requests.push(
            ["templates", "byIndex", { from: 0, to: templates - 1 }, ['id', 'type', 'owner', 'title', 'description', 'routes', 'stations', 'default_type', 'created_at', 'updated_at']]
          )
        }
        if (routes) {
          requests.push(
            ["routes", "byIndex", { from: 0, to: routes - 1 }, ['id', 'name']]
          )
        }
        if (folders) {
          requests.push(foldersFalcorRequest(folders));
        }
        if (stations) {
          requests.push(
            ['hds', 'continuous', 'stations', 'byIndex', { from: 0, to: stations - 1 },
              ['stationId', 'muni', 'data_type']
            ]
          )
        }
        return requests.length && this.props.falcor.get(...requests)
          .then(res => console.log("RES:", res));
      })
  }

  filter(filter) {
    this.setState({ filter });
  }

  openModal(openModalAs, template) {
    this.setState({ openModalAs, template });
  }
  closeModal() {
    this.setState({ openModalAs: null, template: {} });
  }

  checkTemplate(template) {
    const map = this.state.selectedTemplates;
    if (map.has(template.id)) {
      map.delete(template.id);
    }
    else {
      map.set(template.id, template);
    }
    this.setState({
      selectedTemplates: new Map(map)
    })
  }

  asTable() {
    const { filter } = this.state,
      data = this.props.templates
        .filter(({ title }) => !filter || title.toLowerCase().includes(filter.toLowerCase()))
        .sort((a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf())

    return (
      <table className="table table-sm table-hover">
        <thead>
          <tr>
            <th style={ { height: "28px" } }>Template Name</th>
            <th style={ { height: "28px", width: "50px" } }>
              { !this.state.selectedTemplates.size ? null :
                <ClearBox onClick={ e => this.setState({ selectedTemplates: new Map() })}/>
              }
            </th>
            <th style={ { width: "50px" } }/>
          </tr>
        </thead>
        <tbody>
          { data.map(({ id, title, description, routes, stations, defaultType, updatedAt }) => {
              return (
                <tr key={`report_list_elem_${id}`}
                  style={ { backgroundColor: defaultType !== 'none' ? "#efefef" : "transparent" } }>
                  <td>
                    <b>{ title }</b>
                    <br />
                    <i>Updated: { new Date(updatedAt).toLocaleString() }</i>
                    { description === "" ? null :
                      <>
                        <br />
                        <i>{ description }</i>
                      </>
                    }
                    { defaultType === "none" ? null :
                      <>
                        <br />
                        <i>Default: { defaultType }</i>
                      </>
                    }
                  </td>
                  <td style={ { width: "50px" } }>
                    <Checkbox checked={ this.state.selectedTemplates.has(id) }
                      onChange={ e => this.checkTemplate({ id, title, defaultType }) }/>
                  </td>
                  <td style={ { width: "50px" } }>
                    <TemplateControlBox
                      template={ { id, title, description, routes, stations } }
                      openModal={ (...args) => this.openModal(...args) }
                      onDelete={ defaultType === "none" ? e => this.showDeleteModal({ id: [id], title }) : null }
                      addToFolder={ fid => this.addToFolder(fid, id) }
                      folders={ this.props.folders }/>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  };
  showDeleteModal(toDelete) {
    this.setState({ showDeleteModal: true, toDelete });
  }
  hideDeleteModal() {
    this.setState({ showDeleteModal: false, toDelete: {} });
  }
  deleteTemplate() {
    const { id } = this.state.toDelete;
    if (id === undefined) return;

    const map = this.state.selectedTemplates;
    id.forEach(k => map.delete(k));
    this.setState({ selectedTemplates: new Map(map) });
    return this.props.falcor.call(
      ["templates", "delete"],
      id, [], []
    )
  }

  addToFolder(folderId, templateId) {
    this.props.falcor.call(
      ["folders", "add"],
      [folderId, templateId, "template"]
    ).then(() => {})
  }
  addTemplatesToFolder(folder) {
    return [...this.state.selectedTemplates.keys()].reduce((a, c) =>
      a.then(() => !folder.has("template", c) && this.addToFolder(folder.id, c))
    , Promise.resolve())
  }
  selectedContainsDefaultType() {
    return [...this.state.selectedTemplates.values()].reduce((a, c) =>
      a || (c.defaultType !== "none")
    , false)
  }

  render() {
    const selected = this.state.selectedTemplates,
      ids = [...selected.keys()],
      titles = [...selected.values()].map(t => t.title),
      message = [
        `Delete template${ selected.size > 1 ? "s" : "" }?`,
        ...titles
      ];

    return (
      <div className='container' style={ { marginTop: "30px", marginBottom: "50px" } }>
        <div className="clearfix">
          <h1 className='fill-green dot pad1 contain fa fa-file-text icon-white inline'></h1>
          <h3 className='inline' style={{paddingLeft: '6px', marginTop: 10, verticalAlign: 'middle' }}>Templates</h3>
          <Link to={'/reports'} style={{lineHeight: 1.5}} className="btn btn-success ml-1 br float-right">Reports</Link>
        </div>
        <div style={ {
            display: "flex"
          } }>
          <input type="text" value={ this.state.filter }
            className="form-control form-control-sm"
            placeholder="search..."
            onChange={ e => this.filter(e.target.value) }/>
        </div>

        <ActionMenu active={ selected.size }
          folders={ this.props.folders.filter(f => ids.reduce((a, c) => a || !f.has("template", c), false)) }
          addToFolder={ folder => this.addTemplatesToFolder(folder) }
          showDelete={ e => this.showDeleteModal({ id: ids, message }) }
          disableDelete={ this.selectedContainsDefaultType() }/>

        <div style={ {
          backgroundColor:'#fefefe',
          padding: "15px",
          boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
          borderRadius: "4px"
        } }>
          { this.asTable() }
        </div>

        <RouteSelectModal openModalAs={ this.state.openModalAs }
          template={ this.state.template }
          routes={ this.props.routes }
          onHide={ e => this.closeModal() }
          stations={ this.props.stations }/>

        <DeleteModal show={ this.state.showDeleteModal }
          onHide={ this.hideDeleteModal.bind(this) }
          type="template"
          name={ this.state.toDelete.title }
          message={ this.state.toDelete.message }
          onDelete={ this.deleteTemplate.bind(this) }/>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  routes: getRoutesFromState(state),
  templates: getTemplatesFromState(state),
  folders: getFoldersFromState(state),
  stations: getStationsFromState(state)
});
const getStationsFromState = state => {
  const length = get(state, 'graph.hds.continuous.stations.length', 0),
    stationRefs = [],
    stations = [];

  for (let i = 0; i <= length; ++i) {
    const ref = get(state, ['graph', 'hds', 'continuous', 'stations', 'byIndex', i, 'value'], null);
    if (ref) {
      stationRefs.push(ref);
    }
  }
  return stationRefs.reduce((a, c) => {
    const station = get(state, ['graph', ...c], null);
    if (station) {
      a.push({ ...station });
    }
    return a;
  }, []);
}
const getRoutesFromState = state => {
  const length = get(state, 'graph.routes.length', 0),
    ids = [];
  for (let i = 0; i < length; ++i) {
    const id = get(state, `graph.routes.byIndex[${ i }].value[2]`, null);
    if (id !== null) {
      ids.push(id);
    }
  }
  return ids.map(id => ({
    id,
    name: get(state, `graph.routes.byId[${ id }].name`, "")
  }))
  .filter(({ name }) => Boolean(name));
}
export const getTemplatesFromState = state => {
  const { yearsWithData } = state.report,
    mostRecent = Math.max(...yearsWithData);

  const length = get(state, 'graph.templates.length', 0),
    ids = [];
  for (let i = 0; i < length; ++i) {
    const id = get(state, `graph.templates.byIndex[${ i }].value[2]`, null);
    if (id !== null) {
      ids.push(id);
    }
  }
  return ids.map(id => ({
    id,
    title: get(state, `graph.templates.byId[${ id }].title`, ""),
    description: get(state, `graph.templates.byId[${ id }].description`, ""),
    routes: get(state, `graph.templates.byId[${ id }].routes`, 0),
    stations: get(state, `graph.templates.byId[${ id }].stations`, 0),
    defaultType: get(state, `graph.templates.byId[${ id }].default_type`, null),
    updatedAt: get(state, `graph.templates.byId[${ id }].updated_at`, null)
  }))
  .map(({ title, description, ...rest }) => ({
    ...rest,
    title: replace(title, mostRecent, "TITLE"),
    description: replace(description, mostRecent, "DESCRIPTION")
  }))
}
export default {
	icon: 'fa-cog',
	path: '/templates',
	name: 'Templates',
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  class: 'fa',
	mainNav: true,
  auth: true,
  exact: true,
  component: connect(
    mapStateToProps,
    null
  )(reduxFalcor(TemplatesView))
}

const Header = styled.div`
  color: ${ props => props.textColorHl };
  font-size: 1.5rem;
`
const Text = styled.div`
  color: ${ props => props.textColor };
  font-size: 0.9rem;
`
export class RouteSelectModal extends React.Component {
  state = {
    routes: [],
    stations: []
  }
  componentDidUpdate(oldProps) {
    if (oldProps.template.id !== this.props.template.id) {
      const routes = new Array(this.props.template.routes).fill(null),
        stations = new Array(this.props.template.stations).fill(null);
      this.setState({ routes, stations });
    }
  }
  selectRoute(id, index) {
    const routes = [...this.state.routes];
    routes[index] = id;
    this.setState({ routes });
  }
  selectStation(id, index) {
    const stations = [...this.state.stations];
    stations[index] = id;
    this.setState({ stations });
  }
  makeUrl() {
    const { openModalAs, template } = this.props,
      { id,
        routes,
        stations
      } = template;

    const routesSelected = this.state.routes
            .reduce((a, c) => a + (c === null ? 0 : 1), 0),
      numRoutesRequired = routes - routesSelected;

    const stationsSelected = this.state.stations
            .reduce((a, c) => a + (c === null ? 0 : 1), 0),
      numStationsRequired = stations - stationsSelected;

    if (routes && stations && !numRoutesRequired && !numStationsRequired) {

    }
    else if (routes && !numRoutesRequired) {
      return `/template${ openModalAs === "View" ? "/view" : "" }/` +
        `${ id }/route/${ this.state.routes.join("_") }`;
    }
    else if (stations && !numStationsRequired) {
      return `/template${ openModalAs === "View" ? "/view" : "" }/` +
        `${ id }/station/${ this.state.stations.join("_") }`;
    }
    return null;
  }
  render() {
    const { openModalAs, template, onHide } = this.props;

    if (openModalAs === null) return null;

    const {
      id,
      title,
      description,
      routes,
      stations
    } = template;

    const selectors = [];
    for (let i = 0; i < routes; ++i) {
      selectors.push(
        <div key={ i }
          style={ { marginBottom: "5px" } }>
          <ItemSelector
            multiSelect={ false }
            searchable={ true }
            selectedItems={ this.props.routes.reduce((a, c) => c.id === this.state.routes[i] ? c : a, null) }
            options={ [...this.props.routes] }
            displayOption={ d => d.name }
            getOptionValue={ d => d.id }
            onChange={ id => this.selectRoute(id, i) }/>
        </div>
      )
    }
    for (let i = 0; i < stations; ++i) {
      selectors.push(
        <div key={ i }
          style={ { marginBottom: "5px" } }>
          <ItemSelector
            multiSelect={ false }
            searchable={ true }
            selectedItems={ this.props.stations.reduce((a, c) => c.stationId === this.state.stations[i] ? c : a, null) }
            options={ [...this.props.stations] }
            displayOption={ d =>
              `${ d.stationId } (${ d.muni }) (${ d.data_type.split(",").map(s => s[0]).join(", ")})`
            }
            getOptionValue={ d => d.stationId }
            onChange={ id => this.selectStation(id, i) }/>
        </div>
      )
    }

    const url = this.makeUrl();

    const routesSelected = this.state.routes
            .reduce((a, c) => a + (c === null ? 0 : 1), 0),
      numRoutesRequired = routes - routesSelected;

    const stationsSelected = this.state.stations
            .reduce((a, c) => a + (c === null ? 0 : 1), 0),
      numStationsRequired = stations - stationsSelected;

    return (
      <AvlModal show={ Boolean(openModalAs) }
        onHide={ onHide }
        actions={ [
          { label: openModalAs,
            type: "success",
            disabled: url === null,
            url }
        ] }>
        <div style={ { minWidth: "400px" } }>
          <Header>{ title }</Header>
          <Text style={ { marginBottom: "10px" } }><i>{ description }</i></Text>
          { !numRoutesRequired ? null :
            <Text>{ `Select ${ numRoutesRequired } more route${ numRoutesRequired === 1 ? "" : "s" }...` }</Text>
          }
          { !numStationsRequired ? null :
            <Text>{ `Select ${ numStationsRequired } more station${ numStationsRequired === 1 ? "" : "s" }...` }</Text>
          }
          { selectors }
        </div>
      </AvlModal>
    )
  }
}
