import React from 'react';

import { precisionRound, numbersComparator } from 'utils/calculators/MathUtils';

import {
  HIDE_OUTLIERS,
  HIDE_EXTREME_OUTLIERS,
  SHOW_OUTLIERS,
  outlierInclusionLabels
} from 'utils/calculators/OutlierHandlingUtils';

const outlierFiltersSubset = [
  HIDE_OUTLIERS,
  HIDE_EXTREME_OUTLIERS,
  SHOW_OUTLIERS
];

const getViz = data => {
  const { percentilesByPeakByOutlierFilter, ttrByPeakByOutlierFilter } = data;

  if (!ttrByPeakByOutlierFilter) {
    return null;
  }

  const requiredPercentiles = [
    ...outlierFiltersSubset.reduce((acc, outlierFilter) => {
      const percentilesByPeak = percentilesByPeakByOutlierFilter[outlierFilter];

      const peaks = Object.keys(percentilesByPeak || {});

      for (let i = 0; i < peaks.length; ++i) {
        const peak = peaks[i];

        const ttPercentiles =
          percentilesByPeak[peak] && percentilesByPeak[peak].tt;

        if (ttPercentiles) {
          Object.keys(ttPercentiles).forEach(pctl => acc.add(pctl));
        }
      }

      return acc;
    }, new Set())
  ].sort(numbersComparator);

  return (
    <div>
      <style>{`
        table, th, td { border: 1px solid black; border-collapse: collapse; }
        th { background-color: #EEEEEE; } 
      `}</style>
      <table>
        <tbody>
          <tr>
            <th>Outlier Filter</th>
            <th>Percentiles By Peak</th>
          </tr>

          {outlierFiltersSubset.map((outlierFilter, n) => {
            const percentilesByPeak =
              percentilesByPeakByOutlierFilter[outlierFilter];
            const ttrByPeak = ttrByPeakByOutlierFilter[outlierFilter];

            if (!ttrByPeak) {
              return null;
            }

            const peaks = Object.keys(ttrByPeak);

            return (
              <tr key={`ttr_${n}`}>
                <td>{outlierInclusionLabels[outlierFilter]}</td>
                <td>
                  <table>
                    <tbody>
                      <tr>
                        <th>Peak</th>
                        {requiredPercentiles.map((pctl, i) => (
                          <th
                            key={`ttr_peak_${i}`}
                          >{`${pctl} %tile travel time`}</th>
                        ))}
                        <th>Travel Time Reliabilty</th>
                      </tr>
                      {peaks.map((peak, i) => {
                        const ttPercentiles =
                          percentilesByPeak[peak] && percentilesByPeak[peak].tt;

                        return (
                          <tr key={`ttr_by_filter_${i}`}>
                            <td>{peak}</td>
                            {requiredPercentiles.map((pctl, j) => (
                              <td key={`ttr_by_filter_${i}_pctl_${j}`}>
                                {(ttPercentiles &&
                                  precisionRound(ttPercentiles[pctl], 2)) ||
                                  null}
                              </td>
                            ))}
                            <td>
                              <b>{precisionRound(ttrByPeak[peak], 2)}</b>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TTRByPeakByOutlierFilter = ({ data }) => {
  return (
    <div>
      <div style={{ width: '100%', height: 600, color: '#333' }}>
        <h6>Percentiles By Peak</h6>
        {getViz(data)}
      </div>
    </div>
  );
};

export default TTRByPeakByOutlierFilter;
