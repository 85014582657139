import React from "react";

import { useParams } from "react-router-dom";
import { Select, TopNav, useFalcor } from "@availabs/avl-components";
// import LineGraph from 'src/avl-graph/LineGraph'
import PM3List from "./components/pm3List";

import get from "lodash.get";

const pm3Controller = (Component) => ({ ...props }) => {
  const { falcor, falcorCache } = useFalcor();
  const [geoid, setGeoid] = React.useState(36);
  const [geoLevel, setGeoLevel] = React.useState("STATE");
  const [years, setYears] = React.useState([]);
  const [year, setYear] = React.useState(null);
  const params = useParams();

  React.useEffect(() => {
    const { geoid } = params;
    if (geoid) setGeoid(geoid);
  }, [params]);

  React.useEffect(() => {
    const pm3VersionsRoute = [
      "pm3",
      "versionedCalculations",
      "geoLevelVersionsMetadata",
    ];
    falcor.get(pm3VersionsRoute).then((res) => {
      const metadata = get(res.json, pm3VersionsRoute, {});
      console.log('metadata versions', metadata)

      const versionsByYear = Object.keys(metadata).reduce((acc, versionId) => {
        const { year, is_authoritative } = metadata[versionId];
        if (year && is_authoritative) acc.push({ year, versionId });
        return acc;
      }, []);
      setYear(versionsByYear[versionsByYear.length - 1].year);
      setYears(versionsByYear);
      console.log(years, year);
    });
  }, [falcor, setYear, setYears]);

  React.useEffect(() => {
    if (!geoid || years.length === 0) return;

    //setLoading(true);

    return falcor.get(
      ["geoAttributes", `${geoLevel}|${geoid}`, years.map((d) => d.year)],
      [
        "pm3",
        "versionedCalculations",
        "version",
        years.map((d) => d.versionId),
        "geolevel",
        `${geoLevel}|${geoid}`,
      ]
    );
  }, [falcor, geoid, geoLevel, years]);

  const pm3Data = React.useMemo(() => {
    return years.reduce((out, d) => {
      out[d.year] = get(
        falcorCache,
        [
          "pm3",
          "versionedCalculations",
          "version",
          d.versionId,
          "geolevel",
          `${geoLevel}|${geoid}`,
          "value",
        ],
        {}
      );

      return out;
    }, {});
  }, [years, geoLevel, geoid, falcorCache]);

  return (
    <Component
      {...props}
      years={years}
      year={year}
      setYear={setYear}
      data={pm3Data}
    />
  );
};

const measures = {
  lottr_interstate: {
    name: "Level of Travel Time Reliablity - Interstate",
    trend: 1,
  },
  lottr_noninterstate: {
    name: "Level of Travel Time Reliablity - Non-Interstate",
    trend: 1,
  },
  tttr_interstate: {
    name: "Truck Travel Time Reliablity - Interstate",
    trend: 0,
  },
  phed: { name: "Peak Hours of Excesive Delay", trend: 0 },
};

const PM3 = ({ years, year, setYear, data }) => (
  <div className="max-w-3xl w-full mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pt-4">
    <section aria-labelledby="profile-overview-title">
      <div className="rounded-lg bg-white shadow">
        <h2 className="sr-only" id="profile-overview-title">
          Profile Overview
        </h2>
        <div className="bg-white p-4">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="sm:flex sm:space-x-5">
              <div className="text-center sm:mt-0 sm:pt-1 sm:text-left">
                <p className="text-sm font-medium text-gray-600">
                  MAP-21 PM3 Measures
                </p>
                <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                  New York State
                </p>
                {/*<p className="text-sm font-medium text-gray-600">state</p>*/}
              </div>
            </div>
            <div className="mt-5 flex justify-center sm:mt-0"></div>
          </div>
        </div>
        <div
          className={`border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-${
            Object.keys(data).length
          } sm:divide-y-0 sm:divide-x`}
        >
          {Object.keys(data).map((y) => {
            return (
              <div
                key={y}
                className="px-6 py-3 text-sm font-medium text-center"
              >
                <span className="text-gray-600"> {y}</span>
                <div className="flex">
                  <div className="flex-1">
                    <div className="text-gray-400 text-xs">Interstate</div>
                    <div className="text-gray-900 ">
                      {get(
                        data,
                        `[${y}]['interstate_miles']`,
                        ""
                      ).toLocaleString()}{" "}
                      mi
                    </div>
                    <span className="text-gray-400 text-xs">
                      {get(
                        data,
                        `[${y}]['interstate_tmcs']`,
                        ""
                      ).toLocaleString()}{" "}
                      TMCs
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="text-gray-400 text-xs">Non-Interstate</div>
                    <div className="text-gray-900 ">
                      {get(
                        data,
                        `[${y}]['noninterstate_miles']`,
                        ""
                      ).toLocaleString()}{" "}
                      mi
                    </div>
                    <span className="text-gray-400 text-xs">
                      {get(
                        data,
                        `[${y}]['noninterstate_tmcs']`,
                        ""
                      ).toLocaleString()}{" "}
                      TMCs
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
    {Object.keys(measures).map((m) => (
      <div
        key={m}
        className={`border-t border-gray-200 bg-white grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-${
          Object.keys(data).length
        } sm:divide-y-0 sm:divide-x`}
      >
        <div
          className={`px-6 py-3 text-sm font-medium text-center col-span-${
            Object.keys(data).length
          }`}
        >
          {measures[m].name}
        </div>
        {Object.keys(data).map((y) => {
          const val = (
            ((get(data, `[${y}][${m}]`, 0) - get(data, `[${y - 1}][${m}]`, 0)) /
              get(data, `[${y}][${m}]`, 1)) *
            100
          ).toFixed(1);
          return (
            <div key={y} className="px-6 py-3 text-sm font-medium text-center">
              <div className="text-gray-900 text-2xl">
                {get(data, `[${y}][${m}]`, "").toLocaleString()}
                {get(data, `[${y - 1}][${m}]`, false) ? (
                  <span
                    className={`text-sm pl-1 text-${
                      val > 0
                        ? measures[m].trend
                          ? "green"
                          : "red"
                        : measures[m].trend
                        ? "red"
                        : "green"
                    }-300`}
                  >
                    {val}%
                  </span>
                ) : (
                  ""
                )}
              </div>
              <span className="text-gray-400"> {y}</span>
            </div>
          );
        })}
      </div>
    ))}
    <section aria-labelledby="profile-overview-title ">
      <div className="bg-white shadow my-4">
        <PM3List />
      </div>
    </section>
  </div>
);

const config = {
  path: "/map21",
  name: "PM3 Measures",
  icon: "fa-chart-bar",
  exact: true,
  mainNav: true,
  authLevel: 0,
  component: pm3Controller(PM3),
  class: "fa",
  menuSettings: {
    image: "none",
    scheme: "color-scheme-dark",
    position: "menu-position-side",
    layout: "menu-layout-compact",
    style: "color-style-default",
  },
};

export default config;
