import deepEqual from 'deep-equal';
import clone from 'clone';

import getTMCColorsFromData from 'utils/getTMCColorsFromData';

// import pick from 'lodash.pick';

// import validURLParams from '../validURLParams';

import { getMeasure } from '../dataSelection';
import { getHoveredTmc, getFocusedTmc } from '../userInteraction';

// ------------------------------------
// Constants
// ------------------------------------
import {
  SET_TABLE_PAGE,
  SET_TABLE_PAGE_SIZE,
  SET_TABLE_SORTED,
  SET_TABLE_FILTERED,
  COPY_MEASURE_TABLE_STATE
} from './actionCreators';

const measureChanged = context =>
  getMeasure(context.state) !== getMeasure(context.nextState);

const focusedTmcChanged = context =>
  getFocusedTmc(context.state) !== getFocusedTmc(context.nextState);

const hoveredTmcChanged = context =>
  getHoveredTmc(context.state) !== getHoveredTmc(context.nextState);

const handleMeasureChange = (state, context) => {
  if (!measureChanged(context)) {
    return state;
  }

  const prevMeasure = getMeasure(context.state);
  const nextMeasure = getMeasure(context.nextState);

  const newState = { ...state };

  const measureSortIdx = newState.tableSorted
    ? newState.tableSorted.findIndex(({ id }) => id === prevMeasure)
    : -1;

  if (measureSortIdx > -1) {
    newState.tableSorted = newState.tableSorted.slice();
    newState.tableSorted[measureSortIdx] = {
      id: nextMeasure,
      desc: newState.tableSorted[measureSortIdx].desc
    };
  }

  return newState;
};

// Measures where higher numbers are better.
const descendingMeasuresRE = /^freeflow_speed|^pct_bins_reporting|^speed_/;

const handleFocusedTmcChange = (state, context) => {
  if (!focusedTmcChanged(context)) {
    return state;
  }

  const newState = { ...state };

  const newFocusedTmc = getFocusedTmc(context.nextState);

  newState.tableFollowingFocusedTmc = !!newFocusedTmc;

  if (!newFocusedTmc) {
    return newState;
  }

  const { tmcTableRowNums, tablePageSize } = newState;

  const focusedTmcIdx = tmcTableRowNums && tmcTableRowNums[newFocusedTmc];

  if (focusedTmcIdx !== -1 && tablePageSize) {
    newState.tablePage = Math.floor(focusedTmcIdx / tablePageSize);
  }

  return newState;
};

const handleHoveredTmcChange = (state, context) => {
  if (!hoveredTmcChanged(context)) {
    return state;
  }

  const newState = { ...state };

  const hoveredTmc = getHoveredTmc(context.nextState);

  if (hoveredTmc) {
    const { tmcTableRowNums, tablePageSize } = newState;

    const hoveredTmcIdx = tmcTableRowNums && tmcTableRowNums[hoveredTmc];

    if (hoveredTmcIdx !== -1 && tablePageSize) {
      newState.hoveredTmcTablePage = Math.floor(hoveredTmcIdx / tablePageSize);
    }
  } else {
    newState.hoveredTmcTablePage = null;
  }

  return newState;
};

const handleContextChanges = (state, context) => {
  let newState = state;

  newState = handleMeasureChange(newState, context);
  newState = handleFocusedTmcChange(newState, context);
  newState = handleHoveredTmcChange(newState, context);

  return newState;
};

// -------------------------------------
// Initial State
// -------------------------------------

const initialState = {
  tableFiltered: [],
  // Initialized in reducer function using measure from context.
  tableSorted: null,
  tableSortedData: null,
  tableDataDomain: null,
  tablePage: null,
  tablePageSize: null,
  tmcTableRowNums: null,
  tableFollowingFocusedTmc: false,
  tmcColors: null
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [SET_TABLE_PAGE]: (state = initialState, action) => {
    if (state.tablePage === action.payload) {
      return state;
    }

    const newState = { ...state };
    newState.tablePage = action.payload;
    newState.tableFollowingFocusedTmc = false;

    return newState;
  },

  [SET_TABLE_PAGE_SIZE]: (state = initialState, action) => {
    const { pageSize, pageIndex } = action.payload;
    if (state.tablePageSize === pageSize) {
      return state;
    }
    const newState = { ...state };

    newState.tablePageSize = pageSize;
    newState.tablePage = pageIndex;

    if (newState.tableFollowingFocusedTmc && newState.focusedTmc) {
      const { tmcTableRowNums, focusedTmc, tablePageSize } = newState;
      const focusedTmcIdx = tmcTableRowNums && tmcTableRowNums[focusedTmc];

      if (focusedTmcIdx !== -1) {
        newState.tablePage = Math.floor(focusedTmcIdx / tablePageSize);
      }
    }
    return newState;
  },

  [SET_TABLE_SORTED]: (state = initialState, action) => {
    if (deepEqual(state.tableSorted, action.payload)) {
      return state;
    }
    const newState = { ...state };
    newState.tableSorted = clone(action.payload);
    newState.tableFollowingFocusedTmc = false;
    return newState;
  },

  [SET_TABLE_FILTERED]: (state = initialState, action) => {
    if (deepEqual(state.tableFiltered, action.payload)) {
      return state;
    }
    const newState = { ...state };
    newState.tableFiltered = clone(action.payload);
    newState.tableFollowingFocusedTmc = false;
    return newState;
  },

  [COPY_MEASURE_TABLE_STATE]: (state = initialState, action, nextContext) => {
    const measure = getMeasure(nextContext);

    const hoveredTmc = getHoveredTmc(nextContext);
    const focusedTmc = getFocusedTmc(nextContext);

    const { sortedData, pageSize } = action.payload;

    const oldSortedData = state.tableSortedData;
    const oldPageSize = state.tablePageSize;

    let sortedDataChanged = true;
    if (Array.isArray(oldSortedData) && Array.isArray(sortedData)) {
      if (
        // Quickest
        oldSortedData.length === sortedData.length &&
        // Quick: In case the measure changed, but the sort order did not.
        deepEqual(
          Object.keys(oldSortedData[0] || {}).sort(),
          Object.keys(sortedData[0] || {}).sort()
        ) &&
        // Minimal "deep" comparison
        oldSortedData.every(
          ({ tmc, [measure]: m }, i) =>
            sortedData[i].tmc === tmc && sortedData[i][measure] === m
        )
      ) {
        sortedDataChanged = false;
      }
    }

    const pageSizeChanged = pageSize !== oldPageSize;

    // The table data did not change. No update to the state.
    if (!(sortedDataChanged || pageSizeChanged)) {
      return state;
    }

    const newState = { ...state };

    // If the table data changed (including the sorted order)
    //   update the appropriate store properties.
    if (sortedDataChanged) {
      // WARNING: Just a shallow copy.
      // CONSIDER: Make deep copy.
      newState.tableSortedData = sortedData && sortedData.slice();

      // Recompute the measure "domain"
      newState.tableDataDomain =
        newState.tableSortedData &&
        newState.tableSortedData.reduce(
          (acc, { [measure]: val }) => {
            const [min, max] = acc;
            if (+val <= min) {
              acc[0] = +val;
            }

            if (+val > max) {
              acc[1] = +val;
            }

            return acc;
          },
          [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY]
        );

      // Transform data structure to { [tmc]: measureVal } for getTMCColorsFromData
      const d =
        newState.tableSortedData &&
        newState.tableSortedData.reduce((acc, { tmc, [measure]: v }) => {
          acc[tmc] = v;
          return acc;
        }, {});

      const measureColorOrder =
        measure && measure.match(descendingMeasuresRE) ? 'DESC' : 'ASC';

      newState.tmcColors =
        d &&
        getTMCColorsFromData({
          data: d,
          domain: newState.tableDataDomain,
          order: measureColorOrder
        });
    }

    if (pageSizeChanged) {
      newState.tablePageSize = pageSize;
    }

    const { tableSortedData, tablePageSize } = newState;

    // Cache the row number of each TMC.
    //   Makes dynamically setting table page based on hover/focus O(1).
    newState.tmcTableRowNums =
      tableSortedData &&
      tableSortedData.reduce(
        (acc, { tmc }, i) => Object.assign(acc, { [tmc]: i }),
        {}
      );

    // If the table is currently keeping the focusedTmc's page open,
    //   we need to set the tablePage accordingly.
    if (newState.tableFollowingFocusedTmc && newState.tmcTableRowNums) {
      const focusedTmcIdx = newState.tmcTableRowNums[focusedTmc];

      // If the focusedTmc is in the tableData, set the tablePage
      //   so that the focusedTmc is still visible.
      if (focusedTmcIdx > -1 && tablePageSize) {
        newState.tablePage = Math.floor(focusedTmcIdx / tablePageSize);
      }
    }

    if (hoveredTmc && newState.tmcTableRowNums) {
      const hoveredTmcIdx = newState.tmcTableRowNums[hoveredTmc];

      // If the hoveredTmc is in the tableData, set the tablePage
      //   so that the hoveredTmc is still visible.
      if (hoveredTmcIdx !== -1 && tablePageSize) {
        newState.hoveredTmcTablePage = Math.floor(
          hoveredTmcIdx / tablePageSize
        );
      }
    } else {
      newState.hoveredTmcTablePage = null;
    }

    return newState;
  }
};

export default function reducer(state = initialState, action, context) {
  if (state === initialState) {
    const nextMeasure = getMeasure(context.nextState);
    state.tableSorted = [{ id: nextMeasure, desc: true }];
  }

  const newState = handleContextChanges(state, context);

  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(newState, action, context.nextState) : newState;
}

// const yearChanged = context =>
// getYear(context.state) !== getYear(context.nextState);

// const geographyChanged = context =>
// getGeoInfo(context.state) !== getGeoInfo(context.nextState);

// export const parseInitializationParams = params => {
// const relevantParams = pick(params, validURLParams);

// const parsedParams = Object.keys(relevantParams).reduce((acc, k) => {
// const p = relevantParams[k];
// let v = typeof p === 'string' ? decodeURIComponent(p) : p;

// if (k === 'tableFiltered' || k === 'tableSorted' || k === 'geoInfo') {
// try {
// v = typeof v === 'string' ? JSON.parse(v) : v;
// } catch (e) {
// console.error(
// `ERROR parsing initialization parameter: ${k} --- The unparsible value was ${v}`
// );
// }
// }

// acc[k] = v;
// return acc;
// }, {});

// return parsedParams;
// };

// [INITIALIZE_STORE]: (state = initialState, { payload: params }, context) => {
// const parsedParams = parseInitializationParams(params);

// const changedParams = new Set(
// Object.keys(parsedParams).filter(
// k => !deepEqual(parsedParams[k], state[k])
// )
// );

// if (!changedParams.size) {
// return state;
// }

// const newState = { ...state, ...parsedParams };

// // reset to default
// newState.tableFollowingFocusedTmc = true;

// newState.hoveredTmc = null;
// newState.hoveredTmcTablePage = null;

// // Do the changed state params invalidate the selectedTmcs & tableData
// if (
// geographyChanged(context) ||
// yearChanged(context) ||
// measureChanged(context)
// ) {
// // State concerning tableData is invalidated.
// newState.tablePage = 0;
// newState.selectedTmcs = null;
// newState.tableSortedData = null;
// newState.tmcTableRowNums = null;
// }

// return newState;
// }
