export const mb_tmc_metadata_2016 = {
  url: 'mapbox://am3081.cb35nvd7',
  layer: 'tmc_metadata_2016_20200424T103917'
};

export const mb_tmc_metadata_2017 = {
  url: 'mapbox://am3081.1qxr2b2o',
  layer: 'tmc_metadata_2017_20200615T081026'
};

export const mb_tmc_metadata_2018 = {
  url: 'mapbox://am3081.bhzou68l',
  layer: 'tmc_metadata_2018_20200615T081155'
};

export const mb_tmc_metadata_2019 = {
  url: 'mapbox://am3081.chpo7c8c',
  layer: 'tmc_metadata_2019_20200615T081315'
};
export const mb_tmc_metadata_2020 = {
  url: 'mapbox://am3081.af1bur5y',
  layer: 'tmc_metadata_2020_20200615T081451'
}


