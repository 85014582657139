import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';

import get from 'lodash.get';

import { getGeoAttributes2 } from 'store/falcorGraphSelectors';

import GeoLevelPm3VersionsMetadataParser from 'utils/pm3/GeoLevelPm3VersionsMetadataParser';

import { navigateToGeoMeasureVisualizer as navigateToGeoMeasureVisualizerActionCreator } from '../GeoMeasureVisualizer/store';

import { STATE } from './constants';

import Header from './components/Header';
import GeoLevelNavBar from './components/GeoLevelNavBar';
import Pm3MeasuresTable, {
  measureAccessors
} from './components/Pm3MeasuresTable';

import falcorFetcher from './utils/falcorFetcher';
import getEnrichedGeoLevelPm3MeasuresByVersion from './utils/getEnrichedGeoLevelPm3MeasuresByVersion';
import getGeoInfoArr from './utils/getGeoInfoArr';

import { paths, parseUrl } from './utils/urlUtils';
import {
  updatePm3ViewUrl as updatePm3ViewUrlActionCreator,
  pm3VersionSelected as pm3VersionSelectedActionCreator,
  yearSelected as yearSelectedActionCreator
} from './actionCreators';

class Pm3Visualizer extends Component {
  state = {
    loading: false
  };

  fetchFalcorDeps() {
    const setState = this.setState.bind(this);
    const { pm3VersionSelected } = this.props;

    setState({ loading: true });

    return falcorFetcher(this.props, this.state)
      .then(({ newPm3VersionId }) => {
        pm3VersionSelected(newPm3VersionId);
        setState({ loading: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { props: curProps, state: curState } = this;

    const {
      geolevel,
      stateCode,
      selectedPm3VersionId,
      pm3VersionSelected
    } = curProps;

    const { loading } = curState;

    const {
      geolevel: prevSelectedGeoLevel,
      stateCode: prevStateCode,
      selectedPm3VersionId: prevSelectedPm3VersionId
    } = prevProps;

    const { loading: prevLoading } = prevState;

    if (
      prevSelectedGeoLevel === geolevel &&
      prevStateCode === stateCode &&
      prevSelectedPm3VersionId === selectedPm3VersionId
    ) {
      // FIXME: I believe this is unnecessary, but pm3VersionSelected
      //        SHOULD not trigger unnecessary page changes.
      return prevLoading === true && loading === false
        ? pm3VersionSelected(selectedPm3VersionId)
        : null;
    }

    const setState = this.setState.bind(this);

    setState({ loading: true });

    return falcorFetcher(curProps, curState).then(({ newPm3VersionId }) => {
      pm3VersionSelected(newPm3VersionId);
      setState({ loading: false });
    });
  }

  // clean up the URL if incoherent.
  componentDidMount() {
    const {
      stateCode,
      geolevel,
      selectedPm3VersionId,
      updatePm3ViewUrl
    } = this.props;

    return updatePm3ViewUrl({
      stateCode,
      geolevel,
      pm3VersionId: selectedPm3VersionId
    });
  }

  render() {
    const {
      props: {
        year,
        stateCode,
        geolevel,
        selectedPm3VersionId,
        availablePm3VersionIds,
        calculatedYears,
        stateName,
        geoLevelPm3MeasuresByVersion,
        pm3VersionSelected,
        yearSelected,
        updatePm3ViewUrl,
        navigateToGeoMeasureVisualizer,
        getAuthoritativePm3VersionIdForYear
      },
      state: { loading }
    } = this;

    const geoLevelPm3Measures = get(
      geoLevelPm3MeasuresByVersion,
      selectedPm3VersionId,
      null
    );

    const data = geoLevelPm3Measures
      ? geoLevelPm3Measures.filter(d =>
          measureAccessors.some(a => d[a] !== null && d[a] !== undefined)
        )
      : null;

    return (
      <div>
        <div
          className="container"
          style={{ marginTop: '30px', marginBottom: '50px' }}
        >
          <div className="row">
            <div className="col-12">
              <Header
                {...{
                  stateName,
                  stateCode,
                  geolevel,
                  selectedPm3VersionId,
                  availablePm3VersionIds,
                  calculatedYears,
                  year,
                  getAuthoritativePm3VersionIdForYear,
                  pm3VersionSelected,
                  yearSelected,
                  updatePm3ViewUrl
                }}
              />
              <GeoLevelNavBar
                {...{
                  year,
                  stateCode,
                  geolevel,
                  selectedPm3VersionId,
                  updatePm3ViewUrl
                }}
              />
              <div
                style={{
                  backgroundColor: '#fefefe',
                  padding: 15,
                  marginTop: 20,
                  boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'
                }}
              >
                {Array.isArray(data) && data.length ? (
                  <Pm3MeasuresTable
                    {...{
                      data,
                      stateCode,
                      geolevel,
                      selectedPm3VersionId,
                      updatePm3ViewUrl,
                      navigateToGeoMeasureVisualizer
                    }}
                  />
                ) : (
                  <span>{loading ? '...loading' : 'No data'}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // TODO: Figure out why props.location and props.match get out of sync with history.location.
  //       history.location stays in sync with the address bar and the desired state of the page
  const parsedUrl = parseUrl(ownProps.history.location);

  const { geolevel = STATE, stateCode = null, encodedVersionId = null } =
    parsedUrl || {};

  const selectedPm3VersionId =
    encodedVersionId && decodeURIComponent(encodedVersionId);

  const versionsMetadata = new GeoLevelPm3VersionsMetadataParser(state);

  if (!versionsMetadata.isInitialized) {
    return { geolevel, stateCode, selectedPm3VersionId };
  }

  const { calculatedYears } = versionsMetadata;

  const year = versionsMetadata.getYearForVersion(selectedPm3VersionId);

  const availablePm3VersionIds = versionsMetadata.getAvailableVersionIdsForYear(
    year
  );

  const { geoname: stateName = null } =
    stateCode && Array.isArray(calculatedYears)
      ? getGeoAttributes2(state, STATE, stateCode, calculatedYears[0]) || {}
      : {};

  const geoInfoArr = getGeoInfoArr(state, geolevel, stateCode);

  const geoLevelPm3MeasuresByVersion = getEnrichedGeoLevelPm3MeasuresByVersion(
    state,
    geoInfoArr,
    versionsMetadata
  );

  const getAuthoritativePm3VersionIdForYear = versionsMetadata.getAuthoritativeVersionForYear.bind(
    versionsMetadata
  );

  const props = {
    year,
    selectedPm3VersionId,
    availablePm3VersionIds,
    calculatedYears,
    geolevel,
    stateCode,
    stateName,
    geoInfoArr,
    geoLevelPm3MeasuresByVersion,
    getAuthoritativePm3VersionIdForYear
  };

  return props;
};

const mapDispatchToProps = {
  updatePm3ViewUrl: updatePm3ViewUrlActionCreator,
  pm3VersionSelected: pm3VersionSelectedActionCreator,
  yearSelected: yearSelectedActionCreator,
  navigateToGeoMeasureVisualizer: navigateToGeoMeasureVisualizerActionCreator
};

const baseConfig = {
  icon: 'fa-chart-bar',
  name: 'PM3 Measures old',
  exact: true,
  strict: true,
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  class: 'fa',
  authLevel: 0,
  component: connect(
    mapStateToProps,
    mapDispatchToProps
  )(reduxFalcor(Pm3Visualizer))
};

export default paths.map((path, i) => ({ ...baseConfig, path, mainNav: false }));
