import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';
import pick from 'lodash.pick';
import deepEqual from 'deep-equal';
import TrackVisibility from 'react-on-screen';

import {
  queryMeasureRules,
  queryNPMRDSDataForTmc,
  queryTmcAttributes
} from 'store/falcorGraphQueryBuilders';

import {
  npmrdsDataSelector,
  getTmcAttributes,
  getMeasureSpec
} from 'store/falcorGraphSelectors';

import { precisionRound } from 'utils/calculators/MathUtils';

import SummaryStatsCalculator from 'utils/calculators/SummaryStatsCalculator';

import DataCompleteness from '../../components/DataCompleteness';
import AvgsByBinHistogram from '../../components/AvgsByBinHistogram';
import TmcAvgsByDayCalendar from '../../components/TmcAvgsByDayCalendar';
import AvgsByBinTable from '../../components/AvgsByBinTable';
import StatsByBinByDowContour from '../../components/StatsByBinByDowContour';

// Extract the calendar metric from the  { tt, speed, dataCompleteness } objects.
const calendarMetric2Key = {
  SPEED: 'speed',
  TRAVEL_TIME: 'tt',
  DATA_COMPLETENESS: 'dataCompleteness'
};

const calendarMetric2Name = {
  SPEED: 'Daily Avg Speed (mph)',
  TRAVEL_TIME: 'Daily Avg Travel Time (sec)',
  DATA_COMPLETENESS: 'Daily Data Completentess (% bins reporting)'
};

const pickCalendarMetricFromDailyAvgs = (data, calendarMetric) =>
  data &&
  Object.keys(data).reduce((peakAcc, peak) => {
    peakAcc[peak] = Object.keys(data[peak]).reduce((datesAcc, dateStr) => {
      datesAcc[dateStr] = precisionRound(
        data[peak][dateStr][calendarMetric2Key[calendarMetric]],
        3
      );
      return datesAcc;
    }, {});
    return peakAcc;
  }, {});

class ComponentHider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.isVisible ? true : false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isVisible && !this.state.show) {
      this.setState({ show: true });
    }
  }

  render() {
    return this.state.show ? this.props.component : <div>Loading...</div>;
  }
}

const renderOnlyOnScroll = component => (
  <TrackVisibility offset={100}>
    <ComponentHider component={component} />
  </TrackVisibility>
);

class SummaryStatsBreakdown extends Component {
  state = { loading: false };

  fetchFalcorDeps() {
    const {
      props: { tmc, year, measure, dataSource, falcor }
    } = this;

    const setState = this.setState.bind(this);

    setState({ loading: true });
    return tmc && year
      ? falcor
          .get(
            queryTmcAttributes(tmc, ['length'], year),
            queryNPMRDSDataForTmc(tmc, year, dataSource),
            queryMeasureRules(measure)
          )
          .then(() => setState({ loading: false }))
      : Promise.resolve();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsRequiringUpdate = [
      'year',
      'binMinutes',
      'metric',
      'dataSource',
      'tmc',
      'measure',
      'calendarMetric'
    ];

    return (
      this.state.loading !== nextState.loading ||
      !deepEqual(
        pick(this.props, propsRequiringUpdate),
        pick(nextProps, propsRequiringUpdate)
      )
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const propsRequiringFetch = ['year', 'dataSource', 'tmc', 'measure'];

    if (
      !deepEqual(
        pick(this.props, propsRequiringFetch),
        pick(nextProps, propsRequiringFetch)
      )
    ) {
      const setState = this.setState.bind(this);

      setState({ loading: true });
      nextProps.falcor
        .get(
          queryTmcAttributes(nextProps.tmc, ['tmcLength'], nextProps.year),
          queryNPMRDSDataForTmc(
            nextProps.tmc,
            nextProps.year,
            nextProps.dataSource
          ),
          queryMeasureRules(nextProps.measure)
        )
        .then(() => setState({ loading: false }));
    }
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return <span>Loading...</span>;
    }
    const {
      summaryStatsCalculator,
      calendarMetric,
      setCalendarMetric
    } = this.props;

    const {
      dailyAvgs,
      dailyAvgsByPeak,
      metric,
      peaks,
      year
    } = summaryStatsCalculator;

    const metricName = metric === 'SPEED' ? 'Speed' : 'Travel Time';

    const calendarData = pickCalendarMetricFromDailyAvgs(
      dailyAvgs,
      calendarMetric
    );

    const calendarDataByPeak = pickCalendarMetricFromDailyAvgs(
      dailyAvgsByPeak,
      calendarMetric
    );

    return (
      <div>
        <h5>Data Completeness</h5>
        {renderOnlyOnScroll(<DataCompleteness data={summaryStatsCalculator} />)}
        {renderOnlyOnScroll(
          <AvgsByBinHistogram
            data={summaryStatsCalculator}
            doPeakBreakdown={false}
          />
        )}
        {peaks && peaks.some(p => !/total/i.test(p))
          ? renderOnlyOnScroll(
              <div>
                <AvgsByBinHistogram
                  data={summaryStatsCalculator}
                  doPeakBreakdown={true}
                />
              </div>
            )
          : null}
        <div>
          <br />
          <h5>Calendar Chart Metric</h5>
          <div onChange={e => setCalendarMetric(e.target.value)}>
            <input
              type="radio"
              value={'DATA_COMPLETENESS'}
              defaultChecked={calendarMetric === 'DATA_COMPLETENESS'}
              name="calendarMetric"
            />
            Data Completeness
            <input
              type="radio"
              value={metric}
              defaultChecked={calendarMetric !== 'DATA_COMPLETENESS'}
              name="calendarMetric"
            />
            {metric === 'SPEED' ? 'Average Speed' : 'Average Travel Time'}
          </div>
        </div>
        <h5>{`Total ${calendarMetric2Name[calendarMetric]}`}</h5>
        {calendarData ? (
          <TmcAvgsByDayCalendar calendarData={calendarData.TOTAL} year={year} />
        ) : null}
        {calendarDataByPeak
          ? Object.keys(calendarDataByPeak).map(peak =>
              renderOnlyOnScroll(
                <div key={`calendarDataByPeak_${peak}`}>
                  <h5>{`${peak} ${calendarMetric2Name[calendarMetric]}`}</h5>
                  <TmcAvgsByDayCalendar
                    calendarData={calendarDataByPeak[peak]}
                    year={year}
                  />
                </div>
              )
            )
          : null}
        <h5>{metricName}s By Bin By Day of Week Analysis</h5>{' '}
        renderOnlyOnScroll(
        <StatsByBinByDowContour data={summaryStatsCalculator} />)
        <h5>Binned Data for Year</h5>{' '}
        {renderOnlyOnScroll(<AvgsByBinTable data={summaryStatsCalculator} />)}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    tmc,
    year,
    binMinutes,
    measure,
    dataSource,
    metric,
    calendarMetric
  } = ownProps;

  const measureSpec = getMeasureSpec(state, measure);

  const npmrdsData = npmrdsDataSelector(state, tmc, year, dataSource);

  const tmcAttributes = getTmcAttributes(state, tmc, year);

  const summaryStatsCalculator = new SummaryStatsCalculator({
    tmc,
    year,
    dataSource,
    npmrdsData,
    tmcAttributes,
    binMinutes,
    metric,
    measureSpec
  });

  return {
    year,
    binMinutes,
    dataSource,
    metric,
    tmcAttributes,
    summaryStatsCalculator,
    calendarMetric: calendarMetric || metric
  };
};

export default connect(mapStateToProps)(reduxFalcor(SummaryStatsBreakdown));
