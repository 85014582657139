import React, {Component} from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import AvlMap from "components/AvlMap";
import { falcorGraph } from "store/falcorGraph"
import TableBox from "../../../components/light-admin/tables/TableBox";
import styled from "styled-components";
import {reduxFalcor} from "utils/redux-falcor";
import get from 'lodash.get'
import _ from 'lodash'
import flatten from "lodash.flatten";
import { Link } from 'react-router-dom'

import SearchableDropDown from "./components/searchableDropDown";
import IncidentsStackedBarchart from "./components/incidentsStackedBarchart";
import IncidentsTable from "./components/incidentsTable";
import IncidentsHeroStats from "./components/incidentsHeroStats";
import incidentLayer from "./components/incidentLayer";
import GridGraph from "./components/GridGraph";
import TmcGridGraph from './components/TmcGridGraph.comp'
import COLOR_RANGES from "constants/color-ranges"
import {ATTRIBUTES, ATTRIBUTES_TO_DISPLAY, format, format_with_time, format_month, lastDate, catColors} from "./components/constants"

const DROPDOWN = styled.div`
                  select,button {
                    color: #3E4B5B;
                    border: none;
                    background: none;
                    font-size: 0.81rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    white-space: nowrap;
                    letter-spacing: 2px;
                    padding: 0px;
                   }
`;
const QUANTILE_RANGE = COLOR_RANGES[5].reduce((a, c) => c.name === "RdYlBu" ? c.colors : a).slice().reverse()
const date = '';//'2019-01-27';
let chain = [];
let chainForFalcor = [];
let TMCToIdMapping = {};
let epoch = 9;
class Incidents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            span: get(this.props, `location.oldState.span`, '1 Week'),
            spanDates: get(this.props, `location.oldState.spanDates`, []),
            startDate: get(this.props, `location.oldState.startDate`, ''),
            allGeo: get(this.props, `location.oldState.allGeo`, []),
            geo: get(this.props, `location.oldState.geo`, ''),
            allIncidents: get(this.props, `location.oldState.allIncidents`, []),

            allMeta: get(this.props, `location.oldState.allMeta`, {}),
            category: get(this.props, `location.oldState.category`, 'all'),
            type: get(this.props, `location.oldState.type`, 'all'),
            facility: get(this.props, `location.oldState.facility`, 'all'),

            dataStatus: get(this.props, `location.oldState.dataStatus`, 'Loading...'),

            activeChain: 0
        };
    }

    fetchFalcorDeps(){
        return this.props.match.params.incidentId ?
            this.props.falcor.get(
                ['incidents', this.props.match.params.incidentId, ATTRIBUTES],
                ['conflation','byEventId', this.props.match.params.incidentId, ['id', 'tmc19id', 'ris19id', 'geom']]
            ).then(res => {
                if (!get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}.tmc19id`, null) ||
                    !get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}.id`, null)) return Promise.resolve()
                console.log(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.congestion_data.value`, null))
                let originalTmcId = get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}.tmc19id`, '');
                let originalId = get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}.id`, null);
                let date = get(this.props, `incidentInfo.${this.props.match.params.incidentId}.open_time`, '').split(' ')[0];
                let amount = 2
                let //date = '2019-06-27',
                    year = +date.split('-')[0];
                chain = [];
                chainForFalcor = [];
                let congestionData = get(this.props, `incidentInfo.${this.props.match.params.incidentId}.congestion_data.value`, null);
                if (congestionData){
                    Object.keys(congestionData)
                        .forEach(branch => {
                            chainForFalcor.push(...congestionData[branch].tmcArray)
                            chain.push(congestionData[branch].tmcArray)
                        })

                }
            })
            : this.props.falcor.get(
            ['geo', [36], 'geoLevels'],
            ['incidents', 'meta']
        )
            .then((res) => {
                // geo
                let geo = get(res, `json.geo`);

                const geographies = flatten([36].map(s => geo[s].geoLevels));

                let geos = geographies.map(geo => ({
                    name: `${geo.geoname.toUpperCase()} ${geo.geolevel}`,
                    geolevel: geo.geolevel,
                    value: geo.geoid,
                    bounding_box: geo.bounding_box
                }));

                this.setState({allGeo: geos});

                // meta
                let meta = get(res, `json.incidents.meta`);
                this.setState({allMeta: meta})
            })
    }

    componentDidMount() {
        this.loadSpanDates();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.geo !== this.state.geo || prevState.span !== this.state.span){
            this.loadSpanDates();
        }

        if (prevState.geo !== this.state.geo || prevState.span !== this.state.span || prevState.startDate !== this.state.startDate){
            this.setState({allIncidents:[], dataStatus: 'Loading...'});
            this.loadDataFromFalcor();
        }

    }

    loadSpanDates (){
        if(!this.state.span) return null;
        const date = new Date();

        if (this.state.span === '1 Day'){
            let i = moment().subtract(0, 'days').calendar(null, format);
            let days = 1;
            let spanDates = [];

            spanDates.push(i);
            while(i !== lastDate){
                i = moment().subtract(days, 'days').calendar(null, format);
                spanDates.push(i);
                days += 1;
            }
            spanDates.includes(this.state.startDate) ?
                this.setState({spanDates: spanDates}) :
                this.setState({startDate: spanDates[0], spanDates: spanDates});
        }else if (this.state.span === '1 Week'){
            let i = moment().day('Sunday');
            let days = 7;
            let allSundays = [];
            while (i.isSameOrAfter(lastDate)){
                allSundays.push(i.calendar(null, format));
                i = moment().day('Sunday').subtract(days, 'days');
                days += 7;
            }
            this.setState({startDate: allSundays[0], spanDates: allSundays})
        }else if (this.state.span === '1 Month'){

            let i = moment().subtract(0, 'months');
            let months = 1;
            let allMonths = [];

            while (i.isSameOrAfter(lastDate)){
                allMonths.push(i.calendar(null, format_month));
                i = moment().subtract(months, 'months');
                months += 1;
            }
            this.setState({startDate: allMonths[0], spanDates: allMonths})
        }
    }

    loadDataFromFalcor() {
        if (!this.state.span || !this.state.geo || !this.state.startDate) return null;
        let currGeo = this.state.allGeo.filter(g => g.name === this.state.geo)[0].value;
        let fromDate, toDate;
        if (this.state.span === '1 Day'){
            fromDate = this.state.startDate + ' 00:00:00';
            toDate = this.state.startDate + ' 23:59:59';
        }else if (this.state.span === '1 Week'){
            fromDate = this.state.startDate + ' 00:00:00';
            toDate = moment(fromDate).add({d:6, h:23, m:59, s:59}).calendar(null, format_with_time)
        }else if (this.state.span === '1 Month'){
            fromDate = moment(this.state.startDate).calendar(null, format_with_time);
            toDate = moment(fromDate).add({M:1}).subtract({s:1}).calendar(null, format_with_time)
        }

        return this.props.falcor
            .get(['incidents', 'byGeom',currGeo.toString(), 'fromDate', fromDate, 'toDate', toDate, 'length'])
            .then(d => {
                let length = get(d, `json.incidents.byGeom.${currGeo}.fromDate.${fromDate}.toDate.${toDate}.length`, null);
                if (!length) return Promise.resolve();
                let requests = [],
                    chunk = 300;
                for (let i = 0; i <= length; i +=chunk){
                    requests.push(['incidents', 'byGeom',currGeo.toString(),
                        'fromDate', fromDate, 'toDate', toDate,
                        'byIndex', {from:i, to:Math.min(i+chunk, length)}, ATTRIBUTES])
                }

                return this.props.falcor.get(...requests)
            })
            .then(d => {
                let allIncidents = get(d, `json.incidents.byGeom.${currGeo}.fromDate.${fromDate}.toDate.${toDate}.byIndex`, {});
                this.setState({allIncidents: allIncidents});
                if (Object.keys(allIncidents).length === 0) this.setState({dataStatus: 'No Data'});
            })
    }

    loadHeader(id) {
        return (
            <div className="clearfix">
                <h1 className='fill-red dot pad1 contain fa fa-chain-broken icon-white inline'></h1>
                <h3 className='inline' style={{paddingLeft: '6px', marginTop: 10, verticalAlign: 'middle' }}> {id ? `Incident ${id}` : `Incidents`} </h3>
                {id ?
                    <span style={{float: 'right', marginTop: 20,  verticalAlign: 'bottom'}}>
                    <Link className='link-target' to={{
                        pathname:'/incidents',
                        oldState: get(this.props, `location.oldState`, {})
                    }} > go back </Link>
                </span> : null}
            </div>
        )
    }

    renderGeoSelection(){
        let data = [];
        this.state.allGeo.forEach((sd,sd_i) => data.push({value: sd.name, label: sd.name}));
        return (
            <DROPDOWN>
                <SearchableDropDown
                    data={data}
                    placeholder={'Select a Geo'}
                    value={data.filter(f => f.value === this.state.geo)[0]}
                    onChange={(value) => {
                        this.setState({geo:value})
                    }}
                />
                {/*<select
                    value={this.state.geo}
                    id = 'geo'
                    onChange={(e) => {
                        this.setState({geo:e.target.value})
                    }}
                >
                    <option key={0} value=''> -- Select Geo -- </option>
                    {this.state.allGeo.map((sd,sd_i) => <option key={sd_i+1} value={sd.name}> {sd.name} </option>)}
                </select>*/}
            </DROPDOWN>
        )
    }

    renderSpanSelection() {
        let data = [];
        ['1 Day', '1 Week', '1 Month'].forEach(sd => data.push({value: sd, label: sd}));
        return (
            <DROPDOWN>
                <SearchableDropDown
                    data={data}
                    placeholder={'Select a Span'}
                    value={data.filter(f => f.value === this.state.span)[0]}
                    onChange={(value) => {
                        this.setState({span:value})
                    }}
                />
                {/*<select
                    value={this.state.span}
                    id = 'span'
                    onChange={(e) => {
                        this.setState({span:e.target.value})
                    }}
                >
                    <option key={0} value=''> -- Select Span -- </option>
                    <option key={1} value='1 Day'> 1 Day </option>
                    <option key={2} value='1 Week'> 1 Week </option>
                    <option key={3} value='1 Month'> 1 Month </option>
                </select>*/}
            </DROPDOWN>
        )
    }

    renderDateSelection(){
        let data = [];
        this.state.spanDates
            .forEach((sd,sd_i) => data.push({value: sd, label: sd}));

        return (
            <DROPDOWN>
                <SearchableDropDown
                    data={data}
                    placeholder={'Select a Date'}
                    value={data.filter(f => f.value === this.state.startDate)[0]}
                    onChange={(value) => {
                        this.setState({startDate:value})
                    }}
                />
                {/*<select
                    value={this.state.startDate}
                    id = 'spanDates'
                    onChange={(e) => this.setState({startDate:e.target.value})}
                >
                    <option key={0} value=''> -- Select Dates -- </option>
                    {this.state.spanDates.map((sd,sd_i) => <option key={sd_i+1} value={sd}> {sd} </option>)}
                </select>*/}
            </DROPDOWN>
        )
    }

    renderCategorySelection(){
        let data = [{value:'all', label:'All Categories'}];
        Object.keys(this.state.allMeta).forEach((sd,sd_i) => {
            data.push({value:sd, label:sd})
        });
        return (
                <label for={'cat'}>
                    <DROPDOWN>
                        <SearchableDropDown
                            id={'cat'}
                            data={data}
                            placeholder={'Select a Category'}
                            value={data.filter(f => f.value === this.state.category)[0]}
                            onChange={(value) => {
                                this.setState({category:value})
                            }}
                        />
                        {/*<select
                        value={this.state.category}
                        id = 'category'
                        onChange={(e) => {
                            this.setState({category:e.target.value})
                        }}
                    >
                        <input type="text" className="input" placeholder="Search..." />
                        <option key={0} value=''> -- Select Category -- </option>
                        <option key={1} value='all'> all categories </option>
                        {Object.keys(this.state.allMeta).map((sd,sd_i) => <option key={sd_i+2} value={sd}> {sd} </option>)}
                    </select>*/}
                    </DROPDOWN>
                </label>
        )
    }

    renderTypeSelection(){
        let allValues = [],
            data = [{value:'all', label:'All Types'}];
        if (this.state.category === 'all'){
            Object.values(this.state.allMeta)
                .forEach(valueArray => {
                    valueArray.forEach(val => allValues.push(val))
                })
        }

        if (this.state.allMeta[this.state.category] && this.state.category !== 'all'){
            this.state.allMeta[this.state.category].forEach((sd,sd_i) => data.push({value:sd, label:sd}))
        }else if (this.state.category === 'all'){
            allValues.forEach((sd,sd_i) => data.push({value:sd, label:sd}))
        }

        return (
            <DROPDOWN>
                <SearchableDropDown
                    data={data}
                    placeholder={'Select a Type'}
                    value={data.filter(f => f.value === this.state.type)[0]}
                    onChange={(value) => {
                        this.setState({type:value})
                    }}
                />
                {/*<select
                    value={this.state.type}
                    id = 'type'
                    onChange={(e) => {
                        this.setState({type:e.target.value})
                    }}
                >
                    <option key={0} value=''> -- Select Type -- </option>
                    <option key={1} value='all'> all types </option>
                    {this.state.allMeta[this.state.category] && this.state.category !== 'all' ?
                        this.state.allMeta[this.state.category].map((sd,sd_i) => <option key={sd_i+2} value={sd}> {sd} </option>)
                        : this.state.category === 'all' ?
                            allValues
                                .map((sd,sd_i) => <option key={sd_i+2} value={sd}> {sd} </option>)
                            : null}
                </select>*/}
            </DROPDOWN>
        )
    }

    renderFaciliySelection(){
        let data = [{value: 'all', label: 'All Facilities'}];
        Object.values(this.state.allIncidents)
            .forEach((sd,sd_i) => data.push({value: sd.facility, label: sd.facility}))
        return (
            <DROPDOWN style={{maxWidth: '200px'}}>
                <SearchableDropDown
                    data={data}
                    placeholder={'Select a Facility'}
                    value={data.filter(f => f.value === this.state.facility)[0]}
                    onChange={(value) => {
                        this.setState({facility:value})
                    }}
                />
                {/*<select
                    value={this.state.facility}
                    id = 'facility'
                    onChange={(e) => this.setState({facility:e.target.value})}
                >
                    <option key={0} value=''> -- Select Facility -- </option>
                    <option key={1} value='all'> -- All Facilities -- </option>
                    {Object.values(this.state.allIncidents).map((sd,sd_i) => <option key={sd_i+2} value={sd.facility}> {sd.facility} </option>)}
                </select>*/}
            </DROPDOWN>
        )
    }

    selectionMenu(selections) {
        return (
            <div style={ {
                display: "flex",
                justifyContent: 'space-evenly'
            } }>
                {selections.map(s =>
                    <div>
                        <div className='element-box' style={{marginBottom: 0}}>
                            {s}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderMain () {
        return (
            <div className='container' style={ { marginTop: "30px", marginBottom: "50px" } }>
                {this.loadHeader()}

                <div style={{backgroundColor:'rgba(255,255,255,1)', padding: 10, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    {this.selectionMenu([this.renderGeoSelection(), this.renderSpanSelection(), this.renderDateSelection()])}
                </div>

                <div style={{backgroundColor:'rgba(255,255,255,1)', padding: 10, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    {this.selectionMenu([this.renderCategorySelection(), this.renderTypeSelection(), this.renderFaciliySelection()])}
                </div>

                <div style={{
                    backgroundColor:'rgba(255,255,255,0)', padding: 0, marginTop: 20, //boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'
                }}>
                    {<IncidentsHeroStats data={Object.values(this.state.allIncidents)} {...this.state} />}
                </div>

                <div style={{backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    {<IncidentsStackedBarchart data={Object.values(this.state.allIncidents)} {...this.state} leftTick={'# of Events'}/>}
                </div>

                <div style={{backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    {<IncidentsStackedBarchart data={Object.values(this.state.allIncidents)} {...this.state} dataKey={'VehicleDelay'} leftTick={'Total Hours of Delay'} />}
                </div>

                <div style={{backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    { <IncidentsTable data={this.state.allIncidents} {...this.state} /> }
                </div>

                <div style={{backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    { <IncidentsTable data={this.state.allIncidents} {...this.state} groupByFacility={true}/> }
                </div>
            </div>
        );
    }

    setHeight (h, boolHeader = true){
        let header = boolHeader ? 30 : 0;
        this.setState({tmcGridHeight: h.reduce((a,c) => c ? a + c + header: a, 0)})
    }
    renderIncident (data) {
        let incidentInfoDisplayCols = [
            "event_category",
            "event_type",
            "open_time",
            "close_time",
            "duration",
            "facility",
        ];
        let incidentConflationInfoCols = ['id', 'tmc19id', 'ris19id'];
        let s = {
            position: 'relative',
            width: '49%',
            height: '500px',
            backgroundColor:'rgba(255,255,255,1)',
            boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
            flex: '0 0 auto',
            boxSizing: 'border-box'
        }
        let incidentEpoch = moment(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.open_time`, ''))
            .format('H:m').split(':');
        incidentEpoch = Math.floor(((parseInt(incidentEpoch[0])*60) + parseInt(incidentEpoch[1]))/5);

        return (
            <div className='container' style={ { marginTop: "30px", marginBottom: "50px" } }>
                {this.loadHeader(this.props.match.params.incidentId)}

                <div style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <div style={s}>
                        <div className='table-responsive'>
                            <table className='table table-lightborder'>
                                <tbody>
                                {
                                    incidentInfoDisplayCols.map((col, col_i) =>
                                        <tr key={col_i}>
                                            <td>{col.split('_').map(f => f.toUpperCase()).join(' ')}</td>
                                            <td>
                                                {get(this.props, `incidentInfo.${this.props.match.params.incidentId}.${col}`, '')}
                                            </td>
                                        </tr>)
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div style={s}>
                        <AvlMap
                            sidebar={false}
                            mapactions={false}
                            zoom={17}
                            layers={[
                                incidentLayer
                            ]}
                            layerProps={ {
                                ['Incident']: {
                                    ...this.state,
                                    incidentId: this.props.match.params.incidentId,
                                    incidentConflationInfo: get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}`, ''),
                                    incidentInfo: get(this.props, `incidentInfo.${this.props.match.params.incidentId}`, ''),
                                    chainForFalcor:
                                        chain[this.state.activeChain] && chain[this.state.activeChain].length > 0 ?
                                            chain[this.state.activeChain] : chainForFalcor,
                                    fullChain: chainForFalcor
                                }
                            } }
                        />
                    </div>
                </div>

                <div style={{
                    backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'
                }}>
                    {get(this.props, `incidentInfo.${this.props.match.params.incidentId}.description`, '')}
                </div>

                <div style={{backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)'}}>
                    <div className='table-responsive'>
                        <table className='table table-lightborder'>
                            <tbody>
                            {
                                incidentConflationInfoCols.map((col, col_i) =>
                                    <tr key={col_i}>
                                        <td>{col.split('_').map(f => f.toUpperCase()).join(' ')}</td>
                                        <td>
                                            {get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}.${col}`, '')}
                                        </td>
                                    </tr>)
                            }
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="os-tabs-w mx-6">
                    <div className="os-tabs-controls" style={{marginBottom: '1px'}}>
                        <ul className="nav nav-tabs upper" style={{backgroundColor:'#fefefe', padding: 15, marginTop: 20, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
                            width: "100%"
                        }}>
                            {chain.length > 0 ?
                                chain.map((subchain, subchainI) =>
                                    <li className="nav-item">
                                        <a aria-expanded="true"
                                           className={subchainI === this.state.activeChain ? "nav-link active" : "nav-link" }
                                           onClick={() => {this.setState({activeChain: subchainI})}}
                                           data-toggle="tab" href="#"> Branch {subchainI + 1}</a>
                                    </li>
                                ) : <li className="nav-item"><a aria-expanded="false" className="nav-link" data-toggle="tab"
                                                                href="#tab_overview">
                                    <i className='fa fa-spinner fa-spin '></i> Loading...</a></li>
                            }


                        </ul>
                    </div>
                </div>

                {chain.length > 0 ?
                    chain.map((subchain, subchainI) =>
                    <div style={{backgroundColor:'#fefefe', padding: 15, boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
                        width: "100%", height: this.state.tmcGridHeight + 570, display: subchainI === this.state.activeChain ? 'block' : 'none'
                    }}>
                        <div style={{width: "100%", height: "250px"}}>
                            <TmcGridGraph
                                setHeight={this.setHeight.bind(this)}
                                highlightedTmcs={[]}
                                setHighlightedTmcs={(e) => {/*console.warn(e, 'setHighlightedTmcs not implemented')*/}}
                                showSaveImageModal={(e) => {/*console.warn('showSaveImageModal not implemented', e)*/}}
                                saveImage={false}
                                type={'TMC Grid Graph Custom'}
                                title='TMC Grid Graph'
                                id={'graph-comp-0'}
                                state={{displayData: ['speed', 'travelTime']}}
                                layout={{x: 0, y: 0, w: 4, h: 8}}
                                incidents={[
                                    {
                                        incidentEpoch: incidentEpoch,
                                        tmc: get(this.props, `incidentConflationInfo.${this.props.match.params.incidentId}.tmc19id`, '')
                                    }
                                ]}
                                TMCToIdMapping={TMCToIdMapping}
                                routes={[{
                                    tmcArray:subchain,
                                    data: {speed: [], travelTime: []},
                                    compId:'comp-1',
                                    color:'#999999',
                                    settings:{
                                        startDate:
                                            moment(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.open_time`, ''))
                                                .format('YYYYMMDD'),
                                        endDate:
                                            moment(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.close_time`, ''))
                                                .format('YYYYMMDD'),
                                        yearlyStartDate:
                                            moment(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.open_time`, ''))
                                                .subtract(1,'year').format('YYYYMMDD'),
                                        yearlyEndDate:
                                            moment(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.open_time`, ''))
                                                .format('YYYYMMDD'),
                                        yearlyDay:
                                            moment().weekday(moment(get(this.props, `incidentInfo.${this.props.match.params.incidentId}.open_time`, '')).day())
                                                .format('dddd').toLowerCase(),
                                        year: "advanced",
                                        month: "advanced",
                                        startTime: "00:00",
                                        endTime: "23:59",
                                        resolution: "5-minutes",
                                        weekdays: {sunday: true, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true},
                                        overrides: {},
                                        amPeak: true,
                                        offPeak: true,
                                        pmPeak: true,
                                        dataColumn: "travel_time_all",
                                        compTitle: "",
                                        //routeId: 1302,
                                    }
                                }]}
                            />

                        </div>
                    </div>
                ) : null}
            </div>
        );
    }

    render() {
        return this.props.match.params.incidentId ?
            this.renderIncident(chainForFalcor)
            : this.renderMain();
    }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
    return {
        incidentInfo: get(state, `graph.incidents`, {}),
        incidentConflationInfo: get(state, `graph.conflation.byEventId`, {}),
        graph: get(state, `graph`, null),
    };
};

export default [
    {
        icon: 'os-icon-git-commit',
        path: '/incidents2',
        exact: true,
        mainNav: false,
        menuSettings: {
            image: 'none',
            scheme: 'color-scheme-dark',
            position: 'menu-position-side',
            layout: 'menu-layout-compact',
            style: 'color-style-default'
        },
        name: 'Incidents',
        auth: true,
        component: connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(Incidents))
    },
    {
        icon: 'os-icon-git-commit',
        path: '/incidents2/:incidentId',
        exact: true,
        mainNav: false,
        menuSettings: {
            image: 'none',
            scheme: 'color-scheme-dark',
            position: 'menu-position-side',
            layout: 'menu-layout-compact',
            style: 'color-style-default'
        },
        name: 'Incident Info',
        auth: true,
        component: connect(mapStateToProps, mapDispatchToProps)(reduxFalcor(Incidents))
    }
]