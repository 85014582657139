import React from "react"

import styled from "styled-components"

import Modal from "components/AvlStuff/AvlModal"

export default class DeleteModal extends React.Component {
	static defaultProps = {
		type: "route",
		message: []
	}
	makeMessage() {
		return this.props.message.length ?
			this.props.message.slice(1).map((m, i) =>
				<div key={ i }>{ m }</div>
			)
		:
			`Are you sure you wish to delete this ${ this.props.type }?`
	}
	render() {
		return (
			<Modal show={ this.props.show }
				closeLabel="Cancel"
				onHide={ this.props.onHide }
				actions={ [
					{ label: "Delete",
						action: this.props.onDelete,
						type: "success"
					}
				] }>
				<div style={ { minWidth: "400px" } }>
					<H4>{ this.props.message[0] || this.props.name }</H4>
					{ this.makeMessage() }
				</div>
			</Modal>
		)
	}
}

const H4 = styled.h4`
  color: ${ props => props.theme.textColor };
`
