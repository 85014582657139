import get from 'lodash.get';

import { queryPM3MeasuresGeoLevelVersionsMetadata } from '../../store/falcorGraphQueryBuilders';

import { getPM3MeasuresGeoLevelVersionsMetadata } from '../../store/falcorGraphSelectors';

import Pm3VersionsMetadataParser from './Pm3VersionsMetadataParser';

const _ = { get };

const versionsMetadataFalcorPath = queryPM3MeasuresGeoLevelVersionsMetadata();

export default class TmcLevelPm3VersionsMetadataParser extends Pm3VersionsMetadataParser {
  constructor(param) {
    super(
      getPM3MeasuresGeoLevelVersionsMetadata(param) ||
        // Handle Falcor Response as param
        _.get(param, versionsMetadataFalcorPath, null) ||
        _.get(param, ['json'].concat(versionsMetadataFalcorPath), null)
    );
  }
}
