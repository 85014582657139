import React from 'react';

import { precisionRound } from 'utils/calculators/MathUtils';

const TravelTimeIndexByPeak = ({ data: { ttiByPeak } }) =>
  ttiByPeak ? (
    <dl>
      {Object.keys(ttiByPeak).map(peak => (
        <div>
          <dt>{peak}</dt>
          <dd>{precisionRound(ttiByPeak[peak], 3)}</dd>
        </div>
      ))}
    </dl>
  ) : null;

export default TravelTimeIndexByPeak;
