import {push, replace} from 'connected-react-router';

import GeoLevelPm3VersionsMetadataParser from 'utils/pm3/GeoLevelPm3VersionsMetadataParser';

import {createUrl, parseUrl} from './utils/urlUtils';

import getNewPm3VersionId from './utils/getNewPm3VersionId';

export const updatePm3ViewUrl = (updateParams, options) => (
  dispatch,
  getState
) => {
  // TODO: This logic belongs in a state chart.
  if (!updateParams) {
    return Promise.resolve();
  }

  const {routerMethod = replace} = options || {};

  const state = getState();

  const {
    router: {location}
  } = state;

  // If null, we aren't on a valid /pm3 view path.
  const parsedLocation = parseUrl(location);
  const curUrlParams = parsedLocation || {};

  const versionsMetadata = new GeoLevelPm3VersionsMetadataParser(state);

  const newUrlParams = {...curUrlParams, ...updateParams};

  const {year, pm3VersionId} = updateParams;

  if (versionsMetadata.isInitialized) {
    newUrlParams.pm3VersionId = getNewPm3VersionId(
      pm3VersionId,
      versionsMetadata,
      year
    );
  }

  const curUrl = createUrl(curUrlParams);
  const newUrl = createUrl(newUrlParams);

  // console.log({ curUrlParams, newUrlParams, curUrl, newUrl });

  return !parsedLocation || curUrl !== newUrl
    ? new Promise(resolve => {
      dispatch(routerMethod(newUrl));
      resolve();
    })
    : Promise.resolve();
};

export const navigateToPm3View = updateParams =>
  updatePm3ViewUrl(updateParams, {routerMethod: push});

export const pm3VersionSelected = pm3VersionId =>
  updatePm3ViewUrl({pm3VersionId}, {routerMethod: replace});

export const yearSelected = year =>
  updatePm3ViewUrl({year}, {routerMethod: replace});
