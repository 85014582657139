import React from 'react'
import moment from "moment";
import {ResponsiveBar} from "@nivo/bar";
import {format_MM_DD} from "./constants"
import {fnum} from "../../../../components/common/utils";

class incidentsStackedBarchart extends React.Component {
    formatTime(time, format){
        let minutes = `${time.toFixed(2)} Minutes`,
            hours = time/60 > 1 ? `${(time/60).toFixed(2)} Hours` : `${(time/60).toFixed(2)} Hour`,
            days = time/(60*24) > 1 ? `${(time/(60*24)).toFixed(2)} Days` : `${(time/(60*24)).toFixed(2)} Day`;
        if (!time) return '';
        if (format === 'hours'){
            return hours
        }
        if (time < 60){
            return minutes
        }else if (time/60 < 24){
            return hours
        }else{
            return days
        }
    }
    getDateRange(firstDate = moment(this.state.startDate).startOf('month').calendar(null, format_MM_DD),
                 lastDate = moment(this.state.startDate).endOf('month').calendar(null, format_MM_DD), days = 1, inc = 1) {
        let res = [];
        while (lastDate.isSameOrAfter(firstDate)) {
            res.push(firstDate.calendar(null, format_MM_DD));
            firstDate = firstDate.add(inc, 'days');
        }
        return res;
    }

    render() {
        let data = this.props.data;
        if (!data) return null;

        let processedData = {},
            dayX = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            weekRange = [moment(this.props.startDate).startOf('week'), moment(this.props.startDate).endOf('week')],
            monthRange = [moment(this.props.startDate).startOf('month'), moment(this.props.startDate).endOf('month')];

        let currentX =
            this.props.span === '1 Day' ? dayX
                : this.props.span === '1 Week' ?
                this.getDateRange(weekRange[0], weekRange[1])
                : this.props.span === '1 Month' ?
                    this.getDateRange(monthRange[0], monthRange[1])
                    : this.getDateRange(weekRange[0], weekRange[1]);

        currentX.forEach(c => processedData[c] = {X: c});

        data
            .filter(d => (this.props.category === 'all' ? true : d.event_category === this.props.category) &&
                (this.props.type === 'all' ? true : d.event_type === this.props.type) &&
                (this.props.facility === 'all' ? true : d.facility === this.props.facility)
            )
            .forEach(i => {
                if (i.eventid) {
                    let key =
                        this.props.span === '1 Day' ? moment(i.open_time).hour()
                            : this.props.span === '1 Week' ? moment(i.open_time).calendar(null, format_MM_DD)
                            : this.props.span === '1 Month' ? moment(i.open_time).calendar(null, format_MM_DD)
                                : moment(i.open_time).calendar(null, format_MM_DD);
                    let toAdd;

                    if (this.props.dataKey === 'VehicleDelay' && i.congestion_data){
                        let maxDelay, maxvDelay;
                        maxDelay = Math.max(Object.values(i.congestion_data)
                            .map(cd => cd.delay));
                        maxvDelay = Math.max(Object.values(i.congestion_data)
                            .map(cd => cd.VehicleDelay));

                        toAdd = this.formatTime(Math.abs(maxvDelay), 'hours').split(' ')[0];
                    }else if(!this.props.dataKey){
                        toAdd = 1;
                    }

                    if (processedData[key]) {
                        processedData[key][i.event_category] ? processedData[key][i.event_category] += toAdd
                            : processedData[key][i.event_category] = toAdd;

                        processedData[key][i.event_type] ? processedData[key][i.event_type] += toAdd
                            : processedData[key][i.event_type] = toAdd;
                    }

                }
            });

        return (
            <div style={{height: `${300}px`}}>
                <ResponsiveBar
                    data={Object.values(processedData)}
                    keys={this.props.category === 'all' && this.props.type === 'all' ?
                        ['accident', 'construction', 'other'] :
                        this.props.category === 'all' && this.props.type !== 'all' ?
                            [this.props.type]
                            : this.props.allMeta[this.props.category]
                    }
                    title={"title"}
                    indexBy="X"
                    //colorBy={'id'}
                    //colors={ (d) => catColors[d.id] }
                    colors={{scheme: 'nivo'}}
                    enableLabel={false}
                    tooltipFormat={this.props.format}
                    margin={{
                        "top": 25,
                        "right": 0,
                        "bottom": 40,
                        "left": 50
                    }}
                    axisBottom={{
                        "orient": "bottom",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "legend": undefined,
                        "legendPosition": "middle",
                        "legendOffset": 40,
                        "tickRotation": 50
                    }}
                    axisLeft={{
                        "orient": "left",
                        "tickSize": 5,
                        "tickPadding": 5,
                        "tickRotation": 0,
                        "legend": this.props.leftTick,
                        "legendPosition": "middle",
                        "legendOffset": -40,
                        //"format": fnum
                    }}
                    tooltip={
                        d => (
                            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                <div style={{
                                    display: "inline-block",
                                    width: "21px",
                                    height: "21px",
                                    backgroundColor: d.color
                                }}/>
                                <div style={{display: "inline-block"}}>
                                    <span style={{paddingLeft: "5px"}}>{d.id}</span>
                                    <span style={{paddingLeft: "5px"}}>{d.value}</span>
                                </div>
                            </div>
                        )
                    }
                    theme={{
                        "axis": {
                            "legendFontSize": "18px"
                        }
                    }}/>
            </div>
        )
    }
}

incidentsStackedBarchart.defaultProps = {
    data: {}
};

export default incidentsStackedBarchart