import get from 'lodash.get';

import { getVersionedTmcBoundingBox as falcorGetVersionedTmcBoundingBox } from 'store/falcorGraphSelectors';

import registry, { userInteraction } from '../registry';

// Cross-slice selectors are fine since state update transaction is complete.
import { getSelectedPm3Version } from '../dataSelection';

const reduxStorePath = registry[userInteraction];

const _ = { get };

const getSliceState = state => {
  return _.get(state, reduxStorePath, {});
};

export const getSelectedTmcs = state => {
  const { selectedTmcs } = getSliceState(state);

  return Array.isArray(selectedTmcs) && selectedTmcs.length
    ? selectedTmcs
    : null;
};

export const getFocusedTmc = state => {
  const { focusedTmc } = getSliceState(state);
  return focusedTmc || null;
};

export const getHoveredTmc = state => {
  const { hoveredTmc } = getSliceState(state);

  return hoveredTmc || null;
};

export const getFocusedTmcBoundingBox = state => {
  const selectedPm3VersionId = getSelectedPm3Version(state);

  const { focusedTmc } = getSliceState(state);

  const bb = falcorGetVersionedTmcBoundingBox(
    state,
    selectedPm3VersionId,
    focusedTmc
  );

  return bb;
};
