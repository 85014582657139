import DmsCard from "./dms-card"
import { DmsCreate, DmsEdit } from "./dms-create"
import DmsTable from "./dms-table"
import DmsHeader from "./dms-header"
import DmsContent from "./dms-content"
import DmsManager from "./dms-manager"

const Comps = {
  "dms-card": DmsCard,
  "dms-create": DmsCreate,
  "dms-edit": DmsEdit,
  "dms-table": DmsTable,
  "dms-header": DmsHeader,
  "dms-content": DmsContent,
  "dms-manager": DmsManager
}
export default Comps;
