import React from "react"
import { connect } from "react-redux"
import { reduxFalcor } from 'utils/redux-falcor'

import MapLayer from "components/AvlMap/MapLayer"

import { falcorGraph, falcorChunkerNice } from "store/falcorGraph"

import get from "lodash.get"
import styled from "styled-components"

import {
  ConflationSource,
  ConflationStyle
} from './conflation.style.js'

import StationModal from "../components/StationModal"

const CLASS_STATIONS = "#fff",
  VOLUME_STATIONS = "#f90",
  UPDATE_STATIONS = 'chartreuse';

const Block = styled.div`
  width: 20px;
  height: 20px;
  border: 4px solid #000;
  border-radius: 10px;
`

const stations2020 = [
    "020011",
    "150578",
    "180395",
    "410131",
    "520076",
    "630001",
    "730041",
    "840008",
    "910108",
]

const StationTypes = ({ layer }) =>
  <div>
    <table>
      <thead>
        <tr>
          <th colSpan={ 2 }/>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><Block style={ { borderColor: VOLUME_STATIONS } }/></td>
          <td>Volume Stations</td>
        </tr>
        <tr>
          <td><Block style={ { borderColor: CLASS_STATIONS } }/></td>
          <td>Class Stations</td>
        </tr>
      </tbody>
    </table>
  </div>

class ContinuousLayer extends MapLayer {
  onAdd(map) {
    return falcorGraph.get(['hds', 'continuous', 'stations', 'features'])
      .then(res => {
        const features = get(res, ["json", "hds", "continuous", "stations", "features"], []),
          stations = {
            type: "FeatureCollection",
            features
          },
          volumeStations = {
            type: "FeatureCollection",
            features: features.filter(({ properties }) => properties.dataType.includes("VOLUME"))
          },
          classStations = {
            type: "FeatureCollection",
            features: features.filter(({ properties }) => properties.dataType.includes("CLASS"))
          },
          updatedStations = {
            type: "FeatureCollection",
            features: features.filter(({ properties }) => stations2020.includes(properties.stationId))
          }
        console.log('features', features)
        this.stationFeatures = features;

        map.getSource("continuous-stations-source").setData(stations);
        map.getSource("volume-stations-source").setData(volumeStations);
        map.getSource("class-stations-source").setData(classStations);
        map.getSource("updated-stations-source").setData(updatedStations);
      })
      .then(() => {
        return new Promise(resolve => {
          map.loadImage("img/star.png", (error, image) => {
            !error && map.addImage("star", image);
            resolve();
          })
        })
      })
  }
  toggleStation(station) {
    if (this.selectedStations.has(station.properties.stationId)) {
      this.selectedStations.delete(station.properties.stationId);
    }
    else {
      this.selectedStations.set(station.properties.stationId, station);
    }
    if (this.modals.station.show) {
      this.updateModal("updateModal");
    }
    this.map && this.render(this.map);
  }
  render(map) {
    const sIds = [...this.selectedStations.values()]
      .map(s => s.properties.stationId);
    map.setPaintProperty("continuous-stations", "circle-color", [
      "case",
      ["boolean", ["feature-state", "hover"], false], "#0b0",
      ["in", ["get", "stationId"], ["literal", sIds]], "#d4af37",
      "#08b"
    ]);
  }
  updateModal(action) {
    this.doAction([action, "station",
      { stations: [...this.selectedStations.values()],
        allStations: [...this.stationFeatures],
        toggleStation: station => this.toggleStation(station)
      }
    ]);
  }
}

export default (props = {}) =>
  new ContinuousLayer("Continuous Counts", {
    ...props,

    selectedStations: new Map(),
    stationFeatures: [],

    onHover: {
      layers: ["continuous-stations"]
    },
    popover: {
      layers: ["continuous-stations"],
      dataFunc: function({ properties }) {
        return [
          properties.muni,
          ["Station ID", properties.stationId],
          ...JSON.parse(properties.dataType)
            .sort((a, b) => a < b ? -1 : 1)
            .map((d, i) => i === 0 ? ["Data Types", d] : [d])
        ]
      }
    },
    onClick: {
      layers: ["continuous-stations"],
      dataFunc: function(features, point, coords, layer) {
        const station = get(features, [0], {}),
          stationId = get(station, ["properties", "stationId"], null);

        if (!stationId) return;

        if (!this.modals.station.show) {
          if (!this.selectedStations.has(stationId)) {
            this.toggleStation(station);
          }
          this.updateModal("toggleModal");
        }
        else {
          this.toggleStation(station);
        }
      }
    },
    modals: {
      station: {
        title: "Continuous Counts Stations",
        comp: StationModal,
        show: false,
        position: "top",
        startSize: [1000, 800]
      }
    },

    infoBoxes: {
      types: {
        title: "Station Types",
        comp: StationTypes,
        show: true
      }
    },

    sources: [
      ConflationSource,
      { id: "continuous-stations-source",
        source: { type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      },
      { id: "volume-stations-source",
        source: { type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      },
      { id: "class-stations-source",
        source: { type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      },
      { id: "updated-stations-source",
        source: { type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      }
    ],
    layers: [
      ConflationStyle,
      { id: "continuous-stations",
        type: "circle",
        source: "continuous-stations-source",
        paint: {
          "circle-color": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "#0b0",
            "#08b"
          ],
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 1,
            6, 2,
            10, 15
          ]
        }
      },
      { id: "volume-stations",
        type: "circle",
        source: "volume-stations-source",
        paint: {
          "circle-opacity": 0,
          "circle-stroke-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 1,
            6, 2,
            10, 4
          ],
          "circle-stroke-color": VOLUME_STATIONS,
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 3,
            6, 4,
            10, 17
          ]
        }
      },
      { id: "updated-stations",
        type: "circle",
        source: "updated-stations-source",
        paint: {
          "circle-opacity": 0,
          "circle-stroke-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 1,
            6, 2,
            10, 4
          ],
          "circle-stroke-color": UPDATE_STATIONS,
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 4,
            6, 5,
            10, 19
          ]
        }
      },
      { id: "class-stations",
        type: "circle",
        source: "class-stations-source",
        paint: {
          "circle-opacity": 0,
          "circle-stroke-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 1,
            6, 2,
            10, 4
          ],
          "circle-stroke-color": CLASS_STATIONS,
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0, 0,
            6, 1,
            10, 13
          ]
        }
      }
    ]
  })
