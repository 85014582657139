import { matchPath } from 'react-router';

import queryString from 'query-string';

import { DEFAULT_GEOLEVEL } from '../constants';

const urlRoot = '/pm3';

export const paths = [
  `${urlRoot}`,
  `${urlRoot}/state/:stateCode`,
  `${urlRoot}/state/:stateCode/geolevel/:geolevel`,
  `${urlRoot}/state/:stateCode/version/:encodedVersionId`,
  `${urlRoot}/state/:stateCode/geolevel/:geolevel/version/:encodedVersionId`,
  `${urlRoot}/version/:encodedVersionId`
];

export const parseUrl = ({ pathname, search }) => {
  const matches = paths
    .map(path =>
      matchPath(`${pathname}`, {
        path,
        exact: false,
        strict: false
      })
    )
    .filter(m => m);

  if (!matches.length) {
    return null;
  }

  const params = matches.reduce((acc, { params }) => {
    Object.keys(params || {}).forEach(k => {
      const v = params[k];
      if (v !== undefined) {
        acc[k] = v;
      }
    });
    return acc;
  }, {});

  const queryParams = queryString.parse(search);
  const parsedParams = Object.assign({}, queryParams, params);

  if (parsedParams.encodedVersionId) {
    parsedParams.pm3VersionId = decodeURIComponent(
      parsedParams.encodedVersionId
    );
  }

  return parsedParams;
};

export const createUrl = ({ stateCode, geolevel, pm3VersionId }) => {
  const encodedVersionId = pm3VersionId && encodeURIComponent(pm3VersionId);

  if (stateCode && geolevel && encodedVersionId) {
    return `/pm3/state/${stateCode}/geolevel/${geolevel}/version/${encodedVersionId}`;
  }

  if (stateCode && geolevel) {
    return `/pm3/state/${stateCode}/geolevel/${geolevel}`;
  }

  if (stateCode && encodedVersionId) {
    return `/pm3/state/${stateCode}/geolevel/${DEFAULT_GEOLEVEL}/version/${encodedVersionId}`;
  }

  if (stateCode) {
    return `/pm3/state/${stateCode}/geolevel/${DEFAULT_GEOLEVEL}`;
  }

  if (encodedVersionId) {
    return `/pm3/version/${encodedVersionId}`;
  }

  return '/pm3';
};
