import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';
import get from 'lodash.get';
import RangeSlider from 'components/common/range-slider/range-slider'


class TopMeasures extends Component {
  constructor(props) {
    super(props)
    this.state = {
     topFilter: this.props.layer.filters.num_bottlenecks.value,
        range : this.props.minRange ? [this.props.minRange['lowestMin'],this.props.minRange['highestMin']] : [0,1]
    }
      this.updateTop = this.updateTop.bind(this)
      this.filterOnMin = this.filterOnMin.bind(this)
  }
  
  updateTop (values) {
    this.props.layer.update(values[1])
  }
  filterOnMin (values){
      this.props.layer.updateDataQuality(values[1])
  }
  fetchFalcorDeps ( ) {
      return this.props.falcor.get(
          ['tmc', this.props.tmcData.map(f => f.tmc), 'meta', this.props.activePM3Year, ['firstname','roadname']]
      ).then(fname => {
          //console.log('fname', fname.json.tmc['120+05835'].meta[this.props.activePM3Year].firstname)
          return fname
          }
      )
  }

  render() {
      //console.log('props',this.props.tmcDataFNames['120+05835'].meta[this.props.activePM3Year].firstname)
      let tmcData = this.props.tmcData.filter(d => !isNaN(d.value) && d.value < Infinity )
      // congestion data => incident epochs -> % epoch reporting
      // tmcmeta vars -> falcor get['tmc', tmcs, 'meta', activePM3Year, tmcMetaVars] -> firstname
      let graphData = tmcData.map((d,i) => {return {x0: i, x1: (i+1), y: d.value, count: d.value}})
      let graphDataMin = tmcData.map((d,i) => {return {x0: i, x1: (i+1), y: d.min, count: d.min}})

      console.log('TopMeasures render')
      return (
          <div style={{marginTop: 10}}>
              <h3>Top TMC's</h3>
              <RangeSlider
                  range={[0,tmcData.length-1]}
                  value0={0}
                  value1={this.props.num_bottlenecks}
                  histogram={graphData}
                  isEnlarged={true}
                  showInput={true}
                  onChange={this.updateTop}
              />
              <h4>Data Quality</h4>
              <RangeSlider
                  range={this.state.range}
                  value0={0}
                  value1={this.props.dataQuality_bottlenecks}
                  histogram={graphDataMin}
                  isEnlarged={true}
                  showInput={true}
                  onChange={this.filterOnMin}
              />

              <table className='table'>
                  <tbody>
                  {
                      tmcData
                          .filter( d => d.min > this.props.dataQuality_bottlenecks)
                          .filter((d,i) => i < this.props.num_bottlenecks)
                          .map((d,i) => {
                                  try {
                                      return (
                                          <tr key={i}>
                                              <td key={i + '-1'}>
                                                  <div>{this.props.tmcDataFNames[d.tmc].meta[this.props.activePM3Year].roadname}</div>
                                                  <div>
                                                      <div>{this.props.tmcDataFNames[d.tmc].meta[this.props.activePM3Year].firstname ?
                                                          this.props.tmcDataFNames[d.tmc].meta[this.props.activePM3Year].firstname + ' |'
                                                          : this.props.tmcDataFNames[d.tmc].meta[this.props.activePM3Year].firstname} {d.tmc}</div>
                                                  </div>
                                              </td>

                                              <td key={i + '-2'}>
                                                  <div >{d.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
                                              </td>

                                              <td key={i + '-3'}>
                                                  <div >{(d.min * 100).toFixed(0) + '%'}</div>
                                              </td>
                                          </tr>
                                      )
                                  } catch (e) {
                                      return null
                                  }
                              }
                          )
                  }
                  </tbody>
              </table>
          </div>
      );
  }
}

const mapStateToProps = state => ({
    tmcDataFNames : get(state.graph,'tmc')
});

export default connect(mapStateToProps)(reduxFalcor(TopMeasures));
