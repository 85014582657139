import Pm3VersionsMetadataParser from 'utils/pm3/Pm3VersionsMetadataParser';

const getNewPm3VersionId = (pm3VersionId, pm3VersionsMetadataParser, year) => {
  if (!pm3VersionsMetadataParser) {
    return null;
  }

  if (pm3VersionsMetadataParser.isValidVersion(pm3VersionId)) {
    return pm3VersionId;
  }

  const _year =
    year ||
    (pm3VersionId &&
      Pm3VersionsMetadataParser.extractYearFromPm3VersionId(pm3VersionId));

  return _year
    ? pm3VersionsMetadataParser.getAuthoritativeVersionForYear(_year)
    : pm3VersionsMetadataParser.latestFullYearAuthoritativeFhwaVersion;
};

export default getNewPm3VersionId;
