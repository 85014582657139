import React from "react"
// import { connect } from "react-redux"
// import { reduxFalcor } from "utils/redux-falcor"

import mapboxgl from "mapbox-gl"

import { falcorGraph } from "store/falcorGraph"

import get from 'lodash.get'
import flatten from 'lodash.flatten'

import * as d3scale from "d3-scale"

import MapLayer from "components/AvlMap/MapLayer"
import { register, unregister } from "components/AvlMap/ReduxMiddleware"
import { UPDATE as REDUX_UPDATE } from "utils/redux-falcor"

import { falcorChunkerNiceWithUpdate } from "store/falcorGraph"

import { getColorRange } from "constants/color-ranges"

import {
  ConflationSource,
  ConflationStyle
} from './conflation.style.js'

import MeasureInfoBox from 'components/information/MeasureInfoBox'
import DataDownloader from "../components/DataDownloader"

import { iPromise } from "components/AvlStuff/iPromise"

const RISID_YEAR_MAP = {
  2019: "ris19id",
  2018: "ris18id",
  2017: "ris17id",
  2016: "ris16id"
}
const TMCID_YEAR_MAP = {
  2019: "tmc19id",
  2018: "tmc17id",
  2017: "tmc17id",
  2016: "tmc17id"
}
const OSMID_YEAR_MAP = {
  2019: "osmid",
  2018: "osmid",
  2017: "osmid",
  2016: "osmid"
}
const NETWORK_MAP = {
  ris: RISID_YEAR_MAP,
  tmc: TMCID_YEAR_MAP,
  osm: OSMID_YEAR_MAP
}

class NewLayer extends MapLayer {
  onAdd(map) {
    register(this, REDUX_UPDATE, ["graph"]);

    this.updateLegend();

    this.updateSubMeasures(this.filters.measure.value);

    return falcorGraph.get(['pm3', 'measureIds'])
      .then(res => {
        const mIds = get(res, ["json", "pm3", "measureIds"], []);
        this.allMeasures = [...mIds];

        return falcorGraph.get(
          ['geo', '36', 'geoLevels'],
          ['pm3', 'measureInfo', mIds,
            ['fullname', 'definition', 'equation', 'source']
          ]
        )
        .then(res => {
          const mInfo = get(res, ["json", "pm3", "measureInfo"], {});

          this.filters.measure.domain = mIds
            .filter(m => !m.includes("_"))
            .map(id => ({
              name: get(mInfo, [id, "fullname"], id),
              value: id
            }));

          this.filters.measure.domain.push(
            { name: "Percentile Speed",
              value: "speed" },
            { name: "RIS Attributes",
              value: "RIS" },
            { name: "TMC Attributes",
              value: "TMC" }
          )
          this.risAttributes = mIds.filter(m => /^RIS_/.test(m))
            .map(id => ({
              name: get(mInfo, [id, "fullname"], id),
              value: id.replace("RIS_", "")
            }));
          this.tmcAttributes = mIds.filter(m => /^TMC_/.test(m))
            .map(id => ({
              name: get(mInfo, [id, "fullname"], id),
              value: id.replace("TMC_", "")
            }));

          this.filters.geography.domain = flatten(get(res, ["json", "geo", '36', "geoLevels"], []))
            //.filter(({ geolevel }) => geolevel !== "STATE")
            .map(geo => ({
              name: `${ geo.geolevel === "STATE" ? geo.geoname.toUpperCase() : geo.geoname } ${ geo.geolevel === "COUNTY" ? "County" : geo.geolevel === "STATE" ? "State" : geo.geolevel }`,
              geolevel: geo.geolevel,
              value: geo.geoid,
              bounds: geo.bounding_box
            }));
        })
      })
      .then(() => {
        // this.loadFromLocalStorage();
        // this.zoomToGeography();
        // this.doAction(["fetchLayerData"]);
        this.doAction(["updateFilter", "geography", this.loadFromLocalStorage()])
      })
  }
  onRemove(map) {
    unregister(this);
  }
  receiveMessage(action, data) {
    this.falcorCache = data;
  }

  loadFromLocalStorageOld() {
    this.filters.geography.value = window.localStorage ?
      JSON.parse(window.localStorage.getItem("macro-view-geographies") || "[]")
      : [];
  }
  loadFromLocalStorage() {
    return window.localStorage ?
      JSON.parse(window.localStorage.getItem("macro-view-geographies") || "[]")
      : [];
  }
  saveToLocalStorage(geographies = this.filters.geography.value) {
    if (window.localStorage) {
      if (geographies.length) {
        window.localStorage.setItem("macro-view-geographies", JSON.stringify(geographies));
      }
      else {
        window.localStorage.removeItem("macro-view-geographies")
      }
    }
  }

  getBounds(geographies = this.filters.geography.value) {
    return this.filters.geography.domain
      .filter(d => geographies.includes(d.value))
      .reduce((a, c) => a.extend(c.bounds), new mapboxgl.LngLatBounds())
  }
  zoomToGeography(geographies = this.filters.geography.value) {
    if (!this.map) return;

    const bounds = this.getBounds(geographies);

    if (bounds.isEmpty()) return;

    const options = {
      padding: {
        top: 25,
        right: 200,
        bottom: 25,
        left: 200
      },
      bearing: 0,
      pitch: 0,
      duration: 2000
    }

    options.offset = [
      (options.padding.left - options.padding.right) * 0.5,
      (options.padding.top - options.padding.bottom) * 0.5
    ];

    const tr = this.map.transform,
      nw = tr.project(bounds.getNorthWest()),
      se = tr.project(bounds.getSouthEast()),
      size = se.sub(nw);

    const scaleX = (tr.width - (options.padding.left + options.padding.right)) / size.x,
      scaleY = (tr.height - (options.padding.top + options.padding.bottom)) / size.y;

    options.center = tr.unproject(nw.add(se).div(2));
    options.zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), tr.maxZoom);

    this.map.easeTo(options);
  }

  updateLegend() {
    if (this.filters.compareYear.value === "none") {
      switch (this.filters.measure.value) {
        case 'lottr':
          this.legend.type = 'threshold';
          this.legend.domain = [1.1, 1.25, 1.5, 1.75, 2];
          this.legend.range = getColorRange(6, "RdYlBu").reverse();
          this.legend.format = ",.2~f";
          break;
        case 'tttr':
          this.legend.type = 'threshold';
          this.legend.domain = [1.1, 1.25, 1.5, 1.75, 2];
          this.legend.range = getColorRange(6, "RdYlBu").reverse();
          this.legend.format = ",.2~f";
          break;
        default:
          this.legend.type = "quantile";
          this.legend.range = getColorRange(6, "Reds");
          this.legend.format = ",d";
          break;
      }
    }
    else {
      this.legend.type = "threshold";
      this.legend.domain = [-.30, -.20, -.10, 0, .10, .20, .30];
      this.legend.range = getColorRange(8, "RdYlGn");
      this.legend.format = ",.0%";
    }
  }

  onFilterFetch(filterName, oldValue, newValue) {
    const canFetch = this.allMeasures.includes(this.getMeasure());

    switch (filterName) {
      case "geography":
      case "network":
      case "year":
      case "compareYear":
      case "fetchData":
        this.filters.fetchData.disabled = true;
        return this.fetchData();
      case "measure":
        this.filters.fetchData.disabled = true;
        return canFetch ? this.fetchData() : Promise.resolve(false);
      default:
        this.filters.fetchData.disabled = !canFetch;
        return Promise.resolve(false);
    }
  }
  updateSubMeasures(measure = this.filters.measure.value) {
    const {
      // fetchData,
      peakSelector,
      freeflow,
      risAADT,
      perMiles,
      vehicleHours,
      attributes,
      percentiles,
      trafficType
    } = this.filters;

    const mInfo = get(this.falcorCache, ["pm3", "measureInfo"], {});

    peakSelector.active = false;
    peakSelector.domain = [];
    trafficType.active = false;
    trafficType.value = ''

    freeflow.active = false;
    risAADT.active = false;
    perMiles.active = false;
    vehicleHours.active = false;
    percentiles.active = false;

    attributes.active = false;
    attributes.domain = [];

    switch (measure) {
      case "emissions":
        peakSelector.active = true;
        peakSelector.domain = [
          { name: "No Peak", value: "none" },
          { name: "AM Peak", value: "am" },
          { name: "Off Peak", value: "off" },
          { name: "PM Peak", value: "pm" },
          { name: "Overnight", value: "overnight" },
          { name: "Weekend", value: "weekend" }
        ]
        risAADT.active = true;
        break;
      case "RIS":
        attributes.active = true;
        attributes.domain = [...this.risAttributes];
        break;
      case "TMC":
        attributes.active = true;
        attributes.domain = [...this.tmcAttributes];
        break;
      case "lottr":
        peakSelector.active = true;
        peakSelector.domain = [
          { name: "No Peak", value: "none" },
          { name: "AM Peak", value: "am" },
          { name: "Off Peak", value: "off" },
          { name: "PM Peak", value: "pm" },
          { name: "Weekend", value: "weekend" }
        ]
        break;
      case "tttr":
        peakSelector.active = true;
        peakSelector.domain = [
          { name: "No Peak", value: "none" },
          { name: "AM Peak", value: "am" },
          { name: "Off Peak", value: "off" },
          { name: "PM Peak", value: "pm" },
          { name: "Overnight", value: "overnight" },
          { name: "Weekend", value: "weekend" }
        ]
        break;
      case "phed":
        peakSelector.active = true;
        peakSelector.domain = [
          { name: "No Peak", value: "none" },
          { name: "AM Peak", value: "am" },
          { name: "PM Peak", value: "pm" }
        ]
        freeflow.active = true;
        risAADT.active = true;
        perMiles.active = true;
        vehicleHours.active = true;
        trafficType.active = true;
        break;
      case "ted":
        freeflow.active = true;
        risAADT.active = true;
        perMiles.active = true;
        vehicleHours.active = true;
        trafficType.active = true;
        break;
      case "pti":
      case "tti":
        peakSelector.active = true;
        peakSelector.domain = [
          { name: "No Peak", value: "none" },
          { name: "AM Peak", value: "am" },
          { name: "PM Peak", value: "pm" }
        ]
        break;
      case "speed":
        peakSelector.active = true;
        peakSelector.domain = [
          { name: "No Peak", value: "total" },
          { name: "AM Peak", value: "am" },
          { name: "Off Peak", value: "off" },
          { name: "PM Peak", value: "pm" },
          { name: "Overnight", value: "overnight" },
          { name: "Weekend", value: "weekend" }
        ]
        percentiles.active = true;
        break;
      default:
        break;
    }

    if (!peakSelector.domain.reduce((a, c) => a || (c.value === peakSelector.value), false)) {
      peakSelector.value = measure === "speed" ? "total" : "none";
    }
    if ((measure !== "phed") && (measure !== "ted")) {
      freeflow.value = false;
      perMiles.value = false;
      vehicleHours.value = false;
    }
    if ((measure !== "phed") && (measure !== "ted") && (measure !== "emissions")) {
      risAADT.value = false;
    }

    percentiles.value = null;
    attributes.value = null;
  }
  getMeasure() {
    const {
      measure,
      peakSelector,
      freeflow,
      risAADT,
      perMiles,
      vehicleHours,
      attributes,
      percentiles,
      trafficType,
    } = this.filters;
    return [
      measure.value,
      trafficType.value,
      freeflow.value && "freeflow",
      risAADT.value && "ris",
      perMiles.value && "per_mi",
      vehicleHours.value && "vhrs",
      (measure.value === "speed") && percentiles.value,
      (peakSelector.value !== "none") && peakSelector.value,
      attributes.value
    ].filter(Boolean).join("_")
  }
  getMeasureName() {
    return get(this.falcorCache, ["pm3", "measureInfo", this.getMeasure(), "fullname"], "Measure");
  }

  getNetworkId() {
    const { network, year } = this.filters,
      n = network.value,
      y = year.value;
    return get(NETWORK_MAP, [n, y], "none");
  }

  fetchRequestsForGeography() {
    const year = +this.filters.year.value,
      geoids = this.filters.geography.value,
      filtered = this.filters.geography.domain
        .filter(({ value }) => geoids.includes(value));

    switch (this.filters.network.value) {
      case "ris":
        return filtered.reduce((a, c) => {
          a.push(["ris", c.geolevel.toLowerCase(), c.value, year]);
          a.push(["geo", c.geolevel.toLowerCase(), c.value, "geometry"]);
          return a;
        }, [])
      case "tmc":
        return filtered.reduce((a, c) => {
          a.push(['tmc', 'identification',
            'type', c.geolevel, 'geoid', c.value, 'year', year]
          )
          a.push(["geo", c.geolevel.toLowerCase(), c.value, "geometry"]);
          return a;
        }, [])
    }
  }
  getSelectionForGeography() {
    const year = +this.filters.year.value,
      geoids = this.filters.geography.value,
      filtered = this.filters.geography.domain
        .filter(({ value }) => geoids.includes(value));

    switch (this.filters.network.value) {
      case "ris":
        return [...filtered.reduce((a, c) => {
          get(this.falcorCache,
            ["ris", c.geolevel.toLowerCase(), c.value, year, "value"]
          , []).forEach(d => a.add(d))
          return a;
        }, new Set())]
      case "tmc":
        return [...filtered.reduce((a, c) => {
          get(this.falcorCache,
            ['tmc', 'identification',
              'type', c.geolevel,
              'geoid', c.value,
              'year', year, 'value']
          , []).forEach(d => a.add(d))
          return a;
        }, new Set())]
    }
  }

  getGeomRequest(selection) {
// DO NOT CHANGE THESE...THEY ARE UED ELSEWHERE AND MUST STAY AS IS!!!
    switch (this.filters.network.value) {
      case "ris":
        return ["ris", selection, "meta", this.filters.year.value, "geom"]
      case "tmc":
        return ['tmc', selection, 'year', this.filters.year.value, 'geometries']
    }
    return [];
  }
  getMetaRequest(selection) {
    switch (this.filters.network.value) {
      case "ris":
        return [
          "ris", selection, "meta", this.filters.year.value,
          ["gis_id", "beg_mp", "road_name",
            "begin_description", "end_description"
          ]
        ]
      case "tmc":
        return [
          "tmc", selection, "meta", this.filters.year.value,
          ["roadname", "firstname"]
        ]
    }
    return null;
  }

  fetchData() {
    return falcorChunkerNiceWithUpdate(
      ...this.fetchRequestsForGeography(),
      { callback: (curr, total) => {
          this.LoadingIndicator({ progress: curr / total });
        }
      })
      .then(() => {
        const selection = this.getSelectionForGeography();
        return selection.length && falcorChunkerNiceWithUpdate(
          ["conflation",
            this.filters.network.value,
            selection,
            "data",
            [this.filters.year.value,
              this.filters.compareYear.value].filter(y => y !== "none"),
            this.getMeasure()
          ], this.getGeomRequest(selection),
          this.getMetaRequest(selection),
          { callback: (curr, total) => {
              this.LoadingIndicator({ progress: curr / total });
            }
          }
        )
      })
  }
  render(map) {
    const { network, year, compareYear } = this.filters,

      n = network.value,
      y = year.value,
      cy = compareYear.value,
      m = this.getMeasure(),

      nId = get(NETWORK_MAP, [n, y]),

      selection = this.getSelectionForGeography(),

      toNaN = v => v === null ? NaN : +v,
      getValue = id => {
        const v = toNaN(get(this.falcorCache, ["conflation", n, id, "data", y, m], null));
        if (cy === "none") {
          return v;
        }
        const c = toNaN(get(this.falcorCache, ["conflation", n, id, "data", cy, m], null));
        return ((v - c) / c);
      },

      domain = [],
      data = selection.reduce((a, c) => {
        const v = getValue(c);
        if (!isNaN(v)) {
          domain.push(v)
          a.push({
            id: c,
            value: v
          })
        }
        return a;
      }, []),

      scale = this.getColorScale(domain),

      colors = data.reduce((a, c) => {
        a[c.id] = scale(c.value);
        return a;
      }, {});
    console.log('MEASURE', m)

    const geoids = this.filters.geography.value,
      filtered = this.filters.geography.domain
        .filter(({ value }) => geoids.includes(value));

    const collection = {
      type: "FeatureCollection",
      features: filtered.map(f => ({
        type: "Feature",
        properties: { geoid: f.value },
        geometry: get(this.falcorCache, ["geo", f.geolevel.toLowerCase(), f.value, "geometry", "value"], null)
      })).filter(f => Boolean(f.geometry))
    }
    map.getSource("geo-boundaries-source").setData(collection);

    map.setFilter("conflation", [
      "case",
      ["has", ["to-string", ["get", nId]], ["literal", colors]],
      true,
      false
    ])
    map.setPaintProperty("conflation", "line-color",
      ["get", ["to-string", ["get", nId]], ["literal", colors]]
    )
  }
  getColorScale(domain) {
    return (this.legend.type === "quantile" ?
            (this.legend.domain = domain, d3scale.scaleQuantile())
            : d3scale.scaleThreshold()
          )
          .domain(this.legend.domain)
          .range(this.legend.range);
  }
}

export default (props = {}) =>
  new NewLayer("Conflation 2.0", {
    version: 2.0,

    active: true,

    falcorCache: {},
    risAttributes: [],
    tmcAttributes: [],
    allMeasures: [],

    sources: [
      ConflationSource,
      { id: "geo-boundaries-source",
        source: {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        }
      }
    ],
    layers: [
      { ...ConflationStyle,
        filter: ["boolean", false]
      },
      { id: "geo-boundaries",
        type: "line",
        source: "geo-boundaries-source",
        paint: {
          "line-color": "#fff"
        }
      }
    ],

    ...props,

    onHover: {
      layers: ['conflation'/*, 'bottlenecks'*/],
      filterFunc: function(features, point, latlng, layer) {
        if (layer === "bottlenecks") return false;

        const key = this.getNetworkId(),
          value = get(features, [0, "properties", key], "none"),
          dir = get(features, [0, "properties", "dir"], "none");
        return ["all", ["in", key, value], ["in", "dir", dir]];
      }
    },

    legend: {
      active: true,
      legendSelector: false,
      title: ({ layer }) => <React.Fragment>{ layer.getMeasureName() }</React.Fragment>,
      domain: []
    },

    filters: {
      geography: {
        name: 'Geography',
        type: 'multi',
        domain: [],
        value: [],
        onChange: function(oldValue, newValue, domain) {
          this.zoomToGeography();
          this.saveToLocalStorage();
        },
        dispatchMessage: true,
        dispatchFunc: function() {
          const filter = this.filters.geography;
          return filter.domain.filter(f => filter.value.includes(f.value));
        }
      },
      network: {
        name: "Network",
        type: "single",
        value: "tmc",
        domain: [
          { name: "TMC", value: "tmc" },
          { name: "RIS", value: "ris" },
          // { name: "OSM", value: "osm" }
        ]
      },
      year: {
        name: 'Year',
        type: 'single',
        domain: [2016, 2017, 2018, 2019],
        value: 2019,
        dispatchMessage: true
      },
      compareYear: {
        name: 'Compare Year',
        type: 'single',
        domain: ["none", 2016, 2017, 2018, 2019],
        value: "none",
        onChange: function(oldValue, newValue) {
          this.updateLegend();
        }
      },
      measure: {
        name: 'Performance Measure',
        type: 'single',
        domain: [],
        value: 'lottr',
        onChange: function(oldValue, newValue, domain) {
          this.updateSubMeasures();
          this.updateLegend();
        }
      },

      fetchData: {
        type: "fetch",
        name: "Fetch Data",
        disabled: true
      },

      freeflow: {
        name: "Use Freeflow",
        type: "checkbox",
        value: false,
        active: false
      },
      risAADT: {
        name: "Use RIS AADT",
        type: "checkbox",
        value: false,
        active: false
      },
      perMiles: {
        name: "Show Per Mile",
        type: "checkbox",
        value: false,
        active: false
      },
      vehicleHours: {
        name: "Show Vehicle Hours",
        type: "checkbox",
        value: false,
        active: false
      },
      percentiles: {
        name: "Percentile",
        type: "single",
        domain: [
          { name: "5th Percentile", value: "5pctl" },
          { name: "20th Percentile", value: "20pctl" },
          { name: "25th Percentile", value: "25pctl" },
          { name: "50th Percentile", value: "50pctl" },
          { name: "75th Percentile", value: "75pctl" },
          { name: "80th Percentile", value: "80pctl" },
          { name: "85th Percentile", value: "85pctl" },
          { name: "95th Percentile", value: "95pctl" }
        ],
        value: null,
        active: false
      },
      trafficType: {
        name: "Traffic Type",
        type: "single",
        domain: [
          { name: "All Traffic", value: "" },
          { name: "All Trucks", value: "truck" },
          { name: "Single Unit Trucks", value: "singl" },
          { name: "Combination Trucks", value: "combi" },
        ],
        value: '',
        active: false
      },
      peakSelector: {
        name: "Peak Selector",
        type: "single",
        domain: [],
        value: null,
        active: false
      },
      attributes: {
        name: "Attributes",
        type: "single",
        domain: [],
        value: null,
        active: false
      }
    },

    popover: {
      layers: ["conflation"],
      noSticky: true,
      dataFunc: function(feature, features, layer) {

        const p = feature.properties,
          n = this.filters.network.value,
          y = this.filters.year.value,

          data = [];

        switch (n) {
          case "ris": {
            const nId = RISID_YEAR_MAP[y],
              id = p[nId],
              d = get(this.falcorCache, [n, id, "meta", y], {});
            let dd1, dd2;
            (dd1 = get(d, "road_name")) && data.push(dd1);

            dd1 = get(d, "begin_description");
            dd2 = get(d, "end_description");
            (dd1 && dd2) && data.push([`${ dd1 } to ${ dd2 }`]);

            dd1 = get(d, "gis_id");
            dd2 = get(d, "beg_mp");
            (dd1 && dd2) && data.push(["RIS ID", `${ dd1 }-${ dd2 }`]);
            break;
          }
          case "tmc": {
            const nId = TMCID_YEAR_MAP[y],
              id = p[nId],
              d = get(this.falcorCache, [n, id, "meta", y], {});
            let dd;
            (dd = get(d, "roadname")) && data.push(dd);
            (dd = get(d, "firstname")) && data.push([dd]);
            data.push(["TMC", id]);
            break;
          }
        }
        return data;

        // const data = [];
        //
        // switch (this.filters.network.value) {
        //   case "ris":
        //     return [
        //       "ris", selection, "meta", this.filters.year.value,
        //       ["gis_id", "beg_mp", "road_name",
        //         "begin_description", "end_description"
        //       ]
        //     ]
        //   case "tmc":
        //     return [
        //       "tmc", selection, "meta", this.filters.year.value,
        //       ["roadname", "firstname"]
        //     ]
        // }

        // const data = Object.keys(feature.properties)
        //   .filter(k => /tmc|ris|dir/g.test(k))
        //   .filter(k => !k.includes("idx"))
        //   .map(k => [k, feature.properties[k]]);
        //
        // if (this.filters.network.value === 'ris') {
        //   const risId = get(feature, ["properties", this.getNetworkId()], null),
        //     year = this.filters.year.value,
        //     id = get(this.falcorCache, ['ris', risId, 'meta', year, 'gis_id'], false);
        //   if (id) {
        //     data.unshift(id);
        //   }
        // }
        // // const promise = new Promise(r => setTimeout(r, 25, ["TEST", "DATA"]));
        // // data.push(["TEST", iPromise(p)]);
        // // data.push(["AN", <div>Example</div>])
        // return data;
      }
    },

    mapActions: {
      resetView: {
        Icon: () => <span className="fa fa-2x fa-home"/>,
        tooltip: "Reset View",
        action: function() {
          this.zoomToGeography();
        }
      }
    },

    infoBoxes: {
      measureInfo: {
        title: "Measure Info",
        comp: ({ layer }) => <MeasureInfoBox measure={ layer.getMeasure() }/>,
        show: true
      },
      dataDownloader: {
        title: "Download Data",
        comp: ({ layer }) => (
          <DataDownloader layer={ layer }
            network={ layer.filters.network.value }
            allMeasures={ layer.allMeasures }
            measures={ layer.filters.measure.domain }
            measure={ layer.getMeasure() }
            risAttributes={ layer.risAttributes }
            tmcAttributes={ layer.tmcAttributes }
            year={ layer.filters.year.value }
            compareYear={ layer.filters.compareYear.value }
            loading={ layer.loading }
            geoids={ layer.filters.geography.value }
          />
        ),
        show: true
      }
    }
  })
