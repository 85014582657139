// https://plot.ly/javascript/webgl-vs-svg/

import React from 'react';
import Plot from 'react-plotly.js';
import * as ss from 'simple-statistics';

import { precisionRound } from 'utils/calculators/MathUtils';

const formatData = peakTravelTimesSorted => {
  if (!(Array.isArray(peakTravelTimesSorted) && peakTravelTimesSorted.length)) {
    return null;
  }

  const sortedTimesByPeak = peakTravelTimesSorted.reduce(
    (acc, { avgTT, peak }) => {
      acc[peak] = acc[peak] || [];
      acc[peak].push(avgTT);
      return acc;
    },
    {}
  );

  const byPeakAggSums = {};
  let ct = 1;
  for (let i = 0; i < peakTravelTimesSorted.length; i += ct) {
    ct = 1;
    const { peak, avgTT } = peakTravelTimesSorted[i];
    const curTT = precisionRound(avgTT);

    if (!peak) {
      continue;
    }

    while (true) {
      const { avgTT: peekedTT, peak: peekedPeak } =
        peakTravelTimesSorted[i + ct] || {};
      if (
        !peekedTT ||
        peak !== peekedPeak ||
        curTT !== precisionRound(peekedTT)
      ) {
        break;
      }
      ++ct;
    }

    if (!byPeakAggSums[peak]) {
      byPeakAggSums[peak] = {
        x: [precisionRound(avgTT)],
        y: [ct],
        text: [
          `${precisionRound(
            ss.quantileRankSorted(sortedTimesByPeak[peak], curTT) * 100,
            3
          )} %tile`
        ]
      };
    } else {
      const { x, y, text } = byPeakAggSums[peak];
      x.push(precisionRound(avgTT));
      y.push(y[y.length - 1] + ct);
      text.push(
        `${precisionRound(
          ss.quantileRankSorted(sortedTimesByPeak[peak], curTT) * 100,
          3
        )} %tile`
      );
    }
  }

  return byPeakAggSums;
};

const getViz = (
  peakTravelTimesSorted,
  freeFlowTravelTime,
  tt95PercentilesByPeak
) => {
  const byPeakAggSums = formatData(peakTravelTimesSorted);
  const ffTT = precisionRound(freeFlowTravelTime);

  const data = Object.keys(byPeakAggSums).map((peak, i) => {
    const { x, y, text } = byPeakAggSums[peak];

    return {
      x,
      y,
      text,
      name: peak,
      type: 'scatter',
      line: { width: 1, shape: 'hv' }
    };
  });

  let yshift = 0;
  const annotations = [
    {
      text: `Free-Flow`,
      xref: 'x',
      x: ffTT,
      yref: 'paper',
      y: 1
    }
  ].concat(
    Object.keys(tt95PercentilesByPeak).map(peak => ({
      text: `${peak} 95%tile`,
      xref: 'x',
      x: tt95PercentilesByPeak[peak],
      yref: 'paper',
      y: 1,
      yshift: (yshift -= 15)
    }))
  );

  const shapes = [
    {
      type: 'line',
      xref: 'x',
      x0: ffTT,
      x1: ffTT,
      yref: 'paper',
      y0: 0,
      y1: 1
    }
  ].concat(
    Object.keys(tt95PercentilesByPeak).map(peak => ({
      type: 'line',
      xref: 'x',
      x0: tt95PercentilesByPeak[peak],
      x1: tt95PercentilesByPeak[peak],
      yref: 'paper',
      y0: 0,
      y1: 1
    }))
  );

  const layout = {
    title: 'Travel Times By Peak Percentile Breakdown',
    xaxis: {
      title: 'Travel Time'
    },
    yaxis: {
      title: 'Aggregated Count of Instances'
    },
    annotations,
    shapes
  };

  const config = {
    modeBarButtonsToRemove: ['sendDataToCloud']
  };

  return <Plot data={data} layout={layout} config={config} />;
};

const PlanningTimeIndexByPeakAggSums = ({ data }) => {
  const {
    peakTravelTimesSorted,
    freeFlowTravelTime,
    tt95PercentilesByPeak
  } = data;

  return peakTravelTimesSorted && tt95PercentilesByPeak ? (
    <div>
      <div style={{ width: '100%', color: '#333', marginTop: 50 }}>
        {getViz(
          peakTravelTimesSorted,
          freeFlowTravelTime,
          tt95PercentilesByPeak
        )}
      </div>
    </div>
  ) : null;
};

export default PlanningTimeIndexByPeakAggSums;
