import React from 'react';

import AvlMap from "components/AvlMap"

import Pm3Layer from "./layers/npmrds/pm3"

import { RouteLayerFactory } from "pages/auth/NAT/layers/routeLayer"
import EBRFactory from "components/AvlMap/layers/ebr_buildings_new"

Pm3Layer.active = true;

const SidebarHeader = () =>
  <div style={ { paddingLeft: "50px" } }><h4 style={{color: '#efefef'}}>Macro View</h4></div>

class Macro extends React.Component {
  constructor(props) {
    super(props);
    Pm3Layer.user = props.user;
    this.RouteLayer = RouteLayerFactory({
      active: false,
      redirectOnSave: false,
      history: props.history
    })
    this.EBRLayer = EBRFactory({ active: false });
  }
  render() {
    return (
      <div style={{ height: "100vh" }}>
        <AvlMap layers={ [
            this.RouteLayer,
            this.EBRLayer,
            Pm3Layer,
          ] }
          header={ SidebarHeader }
          
          style={ 'mapbox://styles/am3081/cjqqukuqs29222sqwaabcjy29' }
        />
      </div>
    )
  }
}

export default {
	icon: 'fa-map',
  class: "fa",
	path: '/macro',
	exact: true,
	mainNav: true,
  menuSettings: {
    display: 'none',
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-mini',
    style: 'color-style-default'
  },
  name: 'Macro',
	authLevel: 0,
	component: Macro
}
