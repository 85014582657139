import React, { Suspense, Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import theme from 'components/common/themes/dark';



import Layout from './layouts/Layout';
import Routes from './routes';
import { Messages } from "@availabs/avl-components"

import './App.css'

class App extends Component {

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="all-wrapper solid-bg-all">
          <Suspense fallback={<div>Loading...</div>}>
            <BrowserRouter>
              <Switch>
                {Routes.map((route, i) => {
                  return (
                    <Layout
                      {...route} { ...this.props }
                      breadcrumbs={route.breadcrumbs}
                      key={i}
                      menuSettings={route.menuSettings ? route.menuSettings : {}}
                      menus={Routes}
                      routes={route.routes}
                    />
                  );
                })}
              </Switch>
            </BrowserRouter>
          </Suspense>

          <Messages />
        </div>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  router: state.router
});

// const mapDispatchToProps = { auth };

export default connect(
  mapStateToProps,
  // mapDispatchToProps
)(App);
