import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxFalcor } from 'utils/redux-falcor';
import { Link } from 'react-router-dom'
import {
  queryNumRoutesForUser,
  queryBasicRoutesInfo
} from 'store/falcorGraphQueryBuilders';

import { foldersFalcorRequest } from "pages/auth/MyStuff"

import DeleteModal from "./components/DeleteModal"

import { getRoutesInfoArr } from 'store/falcorGraphSelectors';

import get from "lodash.get";
// import styled from "styled-components"

import { getFoldersFromState } from "pages/auth/MyStuff"
import { getTemplatesFromState } from "./templates"

import {
  ControlBox,
  FolderContainer,
  DropLeft
} from "./components/ControlBox"
import FolderIcon from "pages/auth/MyStuff/components/FolderIcon"

import {
  Checkbox,
  ClearBox,
  Dropdown,
  DropdownList,
  LinkDiv,
  ActionMenu
} from "./components/Parts"

class RouteActionMenu extends React.Component {
  static defaultProps = {
    routesInfo: {}
  }
  render() {
    const routes = this.props.selectedRoutes,
      active = Boolean(routes.size),
      ids = [...routes.keys()],
      names = [...routes.values()],
      message = [
        `Delete route${ routes.length > 1 ? "s" : "" }?`,
        ...names
      ],
      folders = this.props.folders.filter(f => ids.reduce((a, c) => a || !f.has("collection", c), false)),
      templates = this.props.templates.filter(t => t.routes === routes.size);

    return (
      <ActionMenu active={ active }
        folders={ folders }
        addToFolder={ this.props.addToFolder }
        showDelete={ e => this.props.showDelete({ id: ids, message }) }>

        <LinkDiv className={ active && "active" }>
            <Link
              to={ active ? `/report/new?routeId=${ ids.join("_") }` : "#" }>
              <span className="fa fa-file-text"/>
              <span className="ml-2">Open in New Report</span>
            </Link>
        </LinkDiv>

        <Dropdown className={ templates.length && "active" }>
          <span className="fa fa-folder"/>
          <span className="ml-2">Open in Template</span>
          <DropdownList>
            {
              templates.map(t =>
                <LinkDiv key={ t.id }
                  className={ templates.length && "active" }>
                  <Link style={ { padding: "0px 20px" } }
                    to={ `/template/${ t.id }/route/${ ids.join("_") }` }>
                    { t.title }
                  </Link>
                </LinkDiv>
              )
            }
          </DropdownList>
        </Dropdown>

      </ActionMenu>
    )
  }
}

export const RouteControlBox = ({ id, name, showDelete=null, folders=[], addToFolder=null }) =>
  <ControlBox>
    <div className="control-box">
      <ul>
        <li>
          <Link to={`/template/single-route/route/${id}`}>
            <span className="fa fa-eye"/>
            View in Report
          </Link>
        </li>
        <li>
          <Link to={`/routes/edit/${id}`}>
            <span className="fa fa-pencil"/>
            Edit
          </Link>
        </li>
        { (addToFolder === null) || (!folders.length) ? null :
          <li>
            <DropLeft>
              <span className="fa fa-folder"/>
              Add to Folder
              <div className="dropdown-list">
                {
                  folders
                    .filter(({ stuff }) => stuff.reduce((a, c) => a && c.stuff_id != id, true))
                    .map(f =>
                      <FolderContainer key={ f.id }
                        onClick={ e => addToFolder(f.id) }>
                        <FolderIcon { ...f } size={ 1.5 }/>
                        { f.name }
                      </FolderContainer>
                    )
                }
              </div>
            </DropLeft>
          </li>
        }
        { showDelete === null ? null :
          <li>
            <a onClick={ showDelete }>
              <span className="fa fa-trash"/>
              Delete
            </a>
          </li>
        }
      </ul>
    </div>
  </ControlBox>

class RoutesView extends Component {
  state = {
    filter: "",
    showDeleteModal: false,
    toDelete: {},
    selectedRoutes: new Map(),
    loading: false
  };

  fetchFalcorDeps() {
    return this.props.falcor
      .get(
        queryNumRoutesForUser(),
        ["folders", "length"],
        ["templates", "length"]
      )
      .then(res => {
        const routes = get(res, ["json", "routes", "length"], 0),
          folders = get(res, ["json", "folders", "length"], 0),
          templates = get(res, ["json", "templates", "length"], 0),
          requests = [];

        if (routes) {
          requests.push(queryBasicRoutesInfo(routes));
        }
        if (folders) {
          requests.push(foldersFalcorRequest(folders))
        }
        if (templates) {
          requests.push(["templates", "byIndex", { from: 0, to: templates - 1 }, ['id', 'type', 'owner', 'title', 'description', 'routes', 'stations', 'default_type', 'created_at', 'updated_at']])
        }
        if (requests.length) {
          return this.props.falcor.get(...requests)
        }
      })
  }

  checkRoute(id, name) {
    const map = this.state.selectedRoutes;
    if (map.has(id)) {
      map.delete(id);
    }
    else {
      map.set(id, name);
    }
    this.setState({
      selectedRoutes: new Map(map)
    })
  }

  asTable (routesInfo) {
    const routeIds = Object.keys(routesInfo);

     const { filter } = this.state;
      const data = routeIds
          .reduce((a, id) => {
            const { name, type, updatedAt } = routesInfo[id];
            if (!filter || (filter && name.toLowerCase().includes(filter.toLowerCase()))) {
              a.push({ id, name, type, sort: new Date(updatedAt).valueOf() })
            }
            return a;
          }, []).sort((a, b) => b.sort - a.sort)

    return (
      <table className='table table-sm table-hover'
        style={ { margin: 0 } }>
        <thead>
          <tr>
            <th style={ { height: "28px" } }>Route Name</th>
            <th style={ { height: "28px", width: "50px" } }>
              { !this.state.selectedRoutes.size ? null :
                <ClearBox onClick={ e => this.setState({ selectedRoutes: new Map() })}/>
              }
            </th>
            <th style={ { width: "50px" } }/>
          </tr>
        </thead>
        <tbody>
          {data
            .map(({id, name, type }, i) => {

            return (
              <tr key={ id }>
                <td>
                  <b>{ name }</b><br/>
                  <small><i>{ type }</i></small>
                </td>
                <td style={ { width: "50px" } }>
                  <Checkbox checked={ this.state.selectedRoutes.has(id) }
                    onChange={ e => this.checkRoute(id, name) }/>
                </td>
                <td style={ { width: "50px" } }>
                  <RouteControlBox id={ id } name={ name }
                    showDelete={ e => this.showDeleteModal({ id: [id], name }) }
                    addToFolder={ fid => this.addToFolder(fid, id) }
                    folders={ this.props.folders }/>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  filter(filter) {
    this.setState({ filter });
  }

  showDeleteModal(toDelete) {
    this.setState({ showDeleteModal: true, toDelete });
  }
  hideDeleteModal() {
    this.setState({ showDeleteModal: false, toDelete: {} });
  }
  deleteRoute() {
    const { id } = this.state.toDelete;
    if (id === undefined) return;

    const map = this.state.selectedRoutes;
    id.forEach(k => map.delete(k));
    this.setState({ selectedRoutes: new Map(map) });

    return this.props.falcor.call(
      ["routes", "delete"], id, [], []
    )
  }

  addToFolder(folderId, collId) {
    return this.props.falcor.call(
      ["folders", "add"], [folderId, collId, "collection"]
    ).then(() => {})
  }
  addRoutesToFolder(folder) {
    return [...this.state.selectedRoutes.keys()].reduce((a, c) =>
      a.then(() => !folder.has("collection", c) && this.addToFolder(folder.id, c))
    , Promise.resolve())
  }

  render() {
    const { routesInfo } = this.props;
console.log("templates:", this.props.templates)
    return (
      <div className='container'
        style={ {
          marginTop: "30px",
          marginBottom: "50px",
          position: "relative"
        } }>
        <span>
          <h1 className='fill-yellow dot pad1 contain fa fa-long-arrow-right icon-white inline'></h1>
          <h3 className='inline' style={{paddingLeft: '6px', marginTop: "10px", verticalAlign: 'middle' }}> Routes </h3>
        </span>
        <div>

          <div style={ { display: "flex" } }>
            <input type="text"
              className="form-control"
              placeholder="search..."
              onChange={ e => this.filter(e.target.value) }
            />
            <Link to={'/routes/create'} style={{lineHeight: 1.5}} className="btn splashFill ml-1 br">New Route</Link>
          </div>


        </div>

        <RouteActionMenu
          selectedRoutes={ this.state.selectedRoutes }
          folders={ this.props.folders }
          addToFolder={ folder => this.addRoutesToFolder(folder) }
          templates={ this.props.templates }
          showDelete={ args => this.showDeleteModal(args) }/>

        <div style={{
            backgroundColor:'#fefefe',
            padding: "15px",
            boxShadow: '0 0 30px 6px rgba(31,51,73,.1)',
            borderRadius: "4px"
          }}>
          { this.asTable(routesInfo) }
        </div>

        <DeleteModal show={ this.state.showDeleteModal }
          onHide={ this.hideDeleteModal.bind(this) }
          type="route"
          name={ this.state.toDelete.name }
          message={ this.state.toDelete.message }
          onDelete={ this.deleteRoute.bind(this) }>
        </DeleteModal>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  routesInfo: getRoutesInfoArr(state),
  folders: getFoldersFromState(state),
  templates: getTemplatesFromState(state)
    .filter(({ stations }) => stations === 0)
});

export default {
	icon: 'fa-road',
	path: '/routes',
	name: 'Routes',
  exact: true,
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  },
  class: 'fa',
	mainNav: true,
  auth: true,
  component: connect(
    mapStateToProps,
    null
  )(reduxFalcor(RoutesView))
}
