import React from 'react'
import moment from "moment";
import {BASE_DATA_TYPES} from "../../../../components/tmc_graphs/utils/dataTypes";

class IncidentsHeroStats extends React.Component {
    formatTime(time, format){
        let minutes = `${time.toFixed(2)} Minutes`,
            hours = time/60 > 1 ? `${(time/60).toFixed(2)} Hours` : `${(time/60).toFixed(2)} Hour`,
            days = time/(60*24) > 1 ? `${(time/(60*24)).toFixed(2)} Days` : `${(time/(60*24)).toFixed(2)} Day`;
        if (!time) return '';
        if (format === 'hours'){
            return hours
        }
        if (time < 60){
            return minutes
        }else if (time/60 < 24){
            return hours
        }else{
            return days
        }
    }
    render() {
        if (!this.props.data) return null;
        let data = this.props.data;
        let numEvents = null,
            avgClearTime = [null, null],
            totalHoursOfDelay = null;
        let travelTimeType = BASE_DATA_TYPES.filter(f => f.key === 'travelTime').pop();
        data
            .filter(d => (this.props.category ==='all' ? true : d.event_category === this.props.category) &&
                (this.props.type ==='all' ? true : d.event_type === this.props.type) &&
                (this.props.facility ==='all' ? true : d.facility === this.props.facility)
            )
            .forEach(i => {
                if (i.eventid) {
                    numEvents = numEvents === null ? 1 : numEvents + 1;
                    let baseDuration = moment(i.open_time);
                    let duration = moment(i.close_time);
                    if (i.congestion_data){
                        let maxDelay, maxvDelay;
                        maxDelay = Math.max(...Object.values(i.congestion_data)
                            .map(cd => cd.delay));
                        maxvDelay = Math.max(...Object.values(i.congestion_data)
                            .map(cd => cd.VehicleDelay));
                        totalHoursOfDelay += parseFloat(maxvDelay);
                    }
                    let time = duration.diff(baseDuration, 'minutes');
                    if (time){
                        avgClearTime[0] = avgClearTime[0] === null ? time : avgClearTime[0] + time;
                        avgClearTime[1] = avgClearTime[1] === null ? 1 : avgClearTime[1] + 1;

                    }else{
                        // todo: data without close_time, but with duration
                    }
                }
            });

        return (
            <div style={ {
                display: "flex",
                justifyContent: 'space-evenly'
            } } className='row'>
                <div className='col-sm-4'>
                    <div className='element-box' style={{marginBottom: 0, padding:0}}>
                        <div className='el-tablo small centered trend-in-corner padded bold-label'>
                            <div className='value' style={{fontSize:'1rem'}}># of Events</div>
                            <div className='label'>{numEvents ? numEvents : this.props.dataStatus}</div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='element-box' style={{marginBottom: 0, padding:0}}>
                        <div className='el-tablo small centered trend-in-corner padded bold-label'>
                            <div className='value' style={{fontSize:'1rem'}}>Avg Clear time</div>
                            <div className='label'>{avgClearTime[0]/avgClearTime[1] ? `${this.formatTime(avgClearTime[0]/avgClearTime[1])}` : this.props.dataStatus}</div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='element-box' style={{marginBottom: 0, padding:0}}>
                        <div className='el-tablo small centered trend-in-corner padded bold-label'>
                            <div className='value' style={{fontSize:'1rem'}}>Total Hours of Delay</div>
                            <div className='label'>{totalHoursOfDelay ?
                                this.formatTime(Math.abs(totalHoursOfDelay), 'hours')
                                : this.props.dataStatus}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default IncidentsHeroStats