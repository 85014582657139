import React from 'react';

const NHTSController = Component =>
  ({ ...props }) => {
    return (
      <Component 
        { ...props } 
        reports={[
          {name:"Albany"},
          {name:"Binghamton"},
          {name:"Buffalo"},
          {name:"Dutchess"},
          {name:"Elmira"},
          {name:"Glens Falls"},
          {name:"Ithaca"},
          {name:"Kingston"},
          {name:"Manhattan"},
          {name:"Nassau-Suffolk"},
          {name:"Newburgh"},
          {name:"NYC - 5 Counties"},
          {name:"NYMTC"},
          {name:"Putnam-Rockland-Westchester"},
          {name:"Rochester"},
          {name:"Small Rural"},
          {name:"Small Urban"},
          {name:"Statewide"},
          {name:"Syracuse"},
          {name:"Utica-Rome"},
          {name:"Watertown"}
        ]}
      />
    )
  }



const NHTS = ({reports}) => {
  return(
    <div className="max-w-3xl w-full mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 pt-4 pb-6">
      <section aria-labelledby="profile-overview-title">
        <div className="rounded-lg bg-white shadow">
          <h2 className="sr-only" id="profile-overview-title">Profile Overview</h2>
          <div className="bg-white p-4">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-5">
                
                <div className="text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-sm font-medium text-gray-600">National Household Travel Survey</p>
                  <p className="text-xl font-bold text-gray-900 sm:text-2xl">NHTS MPO Reports</p>
                  {/*<p className="text-sm font-medium text-gray-600">state</p>*/}
                </div>
              </div>
              <div className="mt-5 flex justify-center sm:mt-0">
              
              </div>
            </div>
          </div>
          </div>
        </section>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                     <th scope="col" className="relative px-6 py-3  text-right">
                        <span >Download</span>
                    </th>
                      
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {reports.map((d,i) => (
                      <tr key={i}>
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">{d.name}</td>
                        
                        <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                          <a href={`https://lor.availabs.org/npmrds/nhts/${d.name}.zip`} className="text-indigo-600 hover:text-indigo-900">
                            Download
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const config = {
  path: "/nhts",
  name: 'NHTS',
  icon: 'fa-address-book',
  exact: true,
  mainNav: true,
  authLevel: 0,
  component: NHTSController(NHTS),
  class: 'fa',
  menuSettings: {
    image: 'none',
    scheme: 'color-scheme-dark',
    position: 'menu-position-side',
    layout: 'menu-layout-compact',
    style: 'color-style-default'
  }
}

export default config;
